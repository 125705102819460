/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { TranslationChunksConfig, TranslationResources } from '@spartacus/core';
import { en } from './en/index';
import { de } from './de/index';
import { fr } from './fr/index';
import { nl } from './nl/index';
import { it } from './it/index';
import {translationChunksConfig} from "@spartacus/assets";

export const mtRegistrationTranslations: TranslationResources = {
  en, de, fr, nl, it
};


export const mtRegistrationTranslationChunksConfig: TranslationChunksConfig =
  {
    ...translationChunksConfig,
    mtUserRegistration: ['mtUserRegistrationForm'],
    mtOrgRegistration: ['mtOrgRegistrationForm'],
    mtRegistrationConf: ['mtRegistrationConfForm'],
    mtUserAccount: ['loginForm', 'miniLogin']
  };
