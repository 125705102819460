<ng-container *ngIf="isUserLoggedIn$ | async; else notUserLoggedIn_info">
  <label *ngIf="getFormattedListPrice() && !pricesMatch()" >
    <span class="mt-product-standard-price-label">{{ 'mtProductItemtList.labels.standardPrice' | cxTranslate }}:
      &nbsp;</span>
    <span class="mt-product-standard-price">{{ getFormattedListPrice() }}</span>
  </label>
</ng-container>

<ng-container *ngIf="!(isUserLoggedIn$ | async) && !showLoginInPDP">
  <label *ngIf="(getFormattedListPrice() && !pricesMatch()) || !getFormattedListPrice() || (getFormattedListPrice() && pricesMatch())" >
  </label>
</ng-container>

<ng-container *ngIf="isUserLoggedIn$ | async;">
  <label *ngIf="!getFormattedListPrice() || (getFormattedListPrice() && pricesMatch())" >
  </label>
</ng-container>


<ng-template #popContent>
  <div class="pop-content-text">
    <div [innerHTML]=" 'mtProductItemtList.labels.displayText' | cxTranslate "></div>
    <div class="span-popup-text" [innerHTML]=" 'mtProductItemtList.labels.spanText' | cxTranslate "></div>
    <div class="span-popup-text" [innerHTML]=" 'mtProductItemtList.labels.spanText2' | cxTranslate "></div>
    <div class="span-popup-text" [innerHTML]=" 'mtProductItemtList.labels.spanText3' | cxTranslate: {currentCountry: currentCountry, currentLanguageIso: currentLanguageIso}"></div>
  </div>
</ng-template>
<div
  [ngbPopover]="popContent"
  [openDelay]="300"
  [closeDelay]="500"
  triggers="manual"
  #p="ngbPopover"
  [popoverClass]="'mt-login-tooltip'"
></div>


<ng-template #notUserLoggedIn_info>
  <div class="mt-product-info-login-price" *ngIf="showLoginInPDP">
    <span class="infoLoginPrice" (click)="openPopover(p)">
      <span class="fas fa-info-circle"></span>
    </span>
    <span
      [outerHTML]=" 'mtProductItemtList.labels.infoLoginPrice' | cxTranslate: {currentCountry: currentCountry, currentLanguageIso: currentLanguageIso}">
    </span>
  </div>
</ng-template>
