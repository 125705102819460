import { mtProductATP } from "./mt-product-atp.i18n";

export const mtProductItemtList = {
  labels: {
    quotationAction: 'Get a Quote',
    orderAction: 'Add to Cart',
    requestProductInfoAction: 'Request Info',
    noResults: 'Products Found ({{noFoundResults}})',
    searchResults: 'Search Results ({{noFoundResults}})',
    yourPrice: 'Your Price',
    standardPrice: 'Standard Price',
    infoLoginPrice: ' <a style="color: #004494 !important;" href="/shop/{{currentCountry}}/{{currentLanguageIso}}/login">Login</a><span>&nbsp;for benefits or chat with our experts.</span>',
    filterButton:'Filter',
    filterClear:'Clear Filters',
    showResultsFound: 'Show Results ({{noFoundResults}})',
    displayText:'As a registered user you can benefit from:',
    spanText:'- business account-specific pricing when registering your company',
    spanText2:'- paying by invoice when registering your company',
    spanText3:'- access to <a href="/shop/{{currentCountry}}/{{currentLanguageIso}}/login">mymt.com </a><span> account with useful features, like personal library, newsfeed or order overview</span>'
  }
}

export const mtProductNoItemsFound = {
  texts: {
    noResultsLabel: 'No search results',
    searchTips: 'Search tips',
    hints: {
      hint1: 'Make sure all words are spelled correctly',
      hint2: 'Try different keywords',
      hint3: 'Try more general keywords',
      hint4: 'Try fewer keywords'
    }
  }
}

export const mtItemsPerPage = {
  items: {
    label: 'View {{pageSize}} per page'
  }
}

export const mtProductListResource = {
  mtProductItemtList,
  mtProductNoItemsFound,
  mtItemsPerPage,
  mtProductATP
}
