/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {CmsConfig, provideConfig, provideDefaultConfig} from '@spartacus/core';
import { MtCartTotalsComponent } from './mt-cart-totals.component';
import {MtCartSharedModule} from "../cart-shared";

@NgModule({
  imports: [CommonModule, MtCartSharedModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CartTotalsComponent: {
          component: MtCartTotalsComponent,
        },
      },
    }),
  ],
  declarations: [MtCartTotalsComponent],
  exports: [MtCartTotalsComponent],
})
export class MtCartTotalsModule {}
