<div class="mt-product-list-wrapper">
  <ng-container *ngIf="model$ | async as model; else loading">

    <ng-container *ngIf="model.isActive">
      <ng-container *ngIf="(model.productSearchPage?.pagination?.totalResults ?? 0) > 0">
        <div>
          <mt-active-facets [category]="category" class="displayFacetsInScreen"></mt-active-facets>
        </div>

        <div class="mt-row-float">
          <div class="mt-row-float__left" *ngIf="isCategoryPage">
            <span class="mt-no-results">{{'mtProductItemtList.labels.noResults' | cxTranslate: {noFoundResults:
              model.productSearchPage?.pagination?.totalResults ?? 0} }}</span>
          </div>
          <div class="mt-row-float__right">
            <div class="productfilter_filter-toggle" (click)="openFilterPopup()">
              <div class="headerFilter">
              <div class="filterSymbol">
                <div class="elipse-top-left"></div>
                <div class="vector-top"></div>
                <div class="elipse-bottom-right"></div>
                <div class="vector-bottom"></div>
              </div>
              <span class="filterLabel">
                {{'mtProductItemtList.labels.filterButton' | cxTranslate}}</span></div></div>
              <div class="mt-row-float__lefts" *ngIf="isCategoryPage">
                <span class="mt-no-results">{{'mtProductItemtList.labels.searchResults' | cxTranslate: {noFoundResults:
                  model.productSearchPage?.pagination?.totalResults ?? 0} }}</span>
              </div>
            <label class="mt-sort-container" *ngIf="isCategoryPage">
              <cx-sorting [selectedOption]="model.productSearchPage?.pagination?.sort"
                [sortOptions]="model.productSearchPage?.sorts" (sortListEvent)="sortList($event)"
                [ariaLabel]="'productList.sortResults' | cxTranslate" ariaControls="product-results-list"
                placeholder="{{ 'productList.sortBy' | cxTranslate }}"></cx-sorting>
            </label>
            <ng-container *ngIf="(model.productSearchPage?.pagination?.totalResults ?? 0) > 5">
              <mt-items-per-page (changeItemsPerPageNo)="onChangeItemsPerPageNo($event)"
                [totalNumberOfItems]="model.productSearchPage?.pagination?.totalResults ?? 0"
                [currentPageSize]="currentPageSize">
              </mt-items-per-page>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="(model.productSearchPage?.pagination?.totalResults ?? 0) > 0; else noResults">
        <div id="product-results-list" class="mt-product-list">
          <ng-container>
            <mt-product-list-item *ngFor="let product of model?.productSearchPage?.products" [product]="product"
              class="cx-product-search-list"></mt-product-list-item>
          </ng-container>
          <div class="mt-pagination-container">
            <nav class="pagination">
              <mt-pagination [pagination]="model.productSearchPage?.pagination" [defaultPage]="0"
                (viewPageEvent)="onChangePage($event)"></mt-pagination>
            </nav>
          </div>
        </div>
      </ng-container>

      <ng-template #noResults>
        <mt-product-list-no-results></mt-product-list-no-results>
      </ng-template>

    </ng-container>

  </ng-container>
<ng-template #loading>
  <div class="skeleton skeleton__productList">
    <div class="skeleton__productFiltering__product" *ngFor="let i of [1, 2, 3, 4, 5]">
      <div class="skeleton__productFiltering__image"></div>
      <div class="skeleton__productFiltering__text">
        <div class="skeleton__productFiltering__text__top">
          <p class="skeleton__text"></p>
        </div>
        <div class="skeleton__productFiltering__text__mid">
          <div class="skeleton__productFiltering__description">
            <p class="skeleton__text"></p>
            <p class="skeleton__text"></p>
            <p class="skeleton__text"></p>
          </div>
        </div>
        <div class="skeleton__productFiltering__text__bottom">
          <div class="skeleton__productFiltering__text__cta">
            <p class="skeleton__text"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
</div>
