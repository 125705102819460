<ng-container *ngIf="isLoggedIn$ | async">
<div class="cx-modal-container" [cxFocus]="focusConfig" (esc)="dismissModal('Escape pressed')">

  <div class="cx-modal-content">

    <!-- Modal Header -->

    <div class="cx-dialog-header modal-header">
        <div class="cx-dialog-title modal-title">
          {{ 'mtUserAccountAndLogin.deactivateAccount.label' | cxTranslate }}
        </div>
        <button type="button" class="close"
                (click)="dismissModal('Cross click')">
          <span aria-hidden="true">
            <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
          </span>
        </button>
      </div>

      <!-- Modal Body -->
      <div class="cx-dialog-body modal-body">
        <div class="cx-dialog-row">
          {{ 'mtUserAccountAndLogin.deactivateAccount.popup' | cxTranslate }}
        </div>
        <!-- Actions -->
        <div class="cx-dialog-buttons">
          <button class="btn btn-primary" (click)="closeAccount()">
            {{ 'mtUserAccountAndLogin.deactivateAccount.label' | cxTranslate }}
          </button>
        </div>
        <div class="cx-dialog-buttons">
          <button class="btn btn-secondary" (click)="dismissModal('Cancel')">
            {{ 'mtUserAccountAndLogin.deactivateAccount.cancel' | cxTranslate }}
          </button>
        </div>
      </div>

  </div>

</div>
</ng-container>

