import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit } from '@angular/core';
import { FacetValue, WindowRef } from '@spartacus/core';
import { FacetComponent } from '@spartacus/storefront';
import { MtFacetService } from '../../services/mt-facet.service';

@Component({
  selector: 'mt-facet',
  templateUrl: './mt-facet.component.html',
  styleUrls: ['./mt-facet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MtFacetComponent extends FacetComponent implements OnInit {

  private toggle: boolean = true;


  @Input()
  category?: string;

  constructor(
    protected override facetService: MtFacetService,
    protected override elementRef: ElementRef<HTMLElement>,
    protected override cd: ChangeDetectorRef,
    protected winRef: WindowRef,
  ) {
    super(facetService, elementRef, cd);
  }

  ngOnInit(): void {
    this.facetService.setupCategoryPage(this.category);
  }

  override get isExpanded(): boolean {
    return this.toggle;
  }

  override toggleGroup(event: UIEvent) {
    super.toggleGroup(event);
    this.toggle = !this.toggle;
  }

  onSelectFacet(facetValue: FacetValue) {
    //console.log(`onSelectFacet_facet = ${facetValue.name} = ${JSON.stringify(facetValue.query)} - ${facetValue.selected}`)
    console.log(`onSelectFacet_facet = ${facetValue.name} = ${facetValue.selected}`)
    if (this.winRef.nativeWindow) {
      this.winRef.nativeWindow.location.hash =
        this.facetService.selectFacet(facetValue, this.winRef.nativeWindow.location.href);
    }
  }

  filterEmptyValues(values: FacetValue[] | undefined) {
    if (values === undefined)
      return []
    return values.filter(it => it.count ?? 0 > 0)
  }
}
