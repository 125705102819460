
export const mtProductATP = {
  labels: {
    quoteonly: 'No availability information available.',
    availability: '<b>Availability:</b><br>',
    contactUsLastRowMessage: '<a href="{{link}}" target="_blank">Contact us</a> for the remaining quantity',
    contactUsMessage: '<a href="{{link}}" target="_blank">Contact us</a> for availability',
  }
}

