import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {MtSoldToSelectionOccService} from "../../core/mt-soldto-selection-occ.service";
import {MtSoldToService} from "../../core/mt-sold-to.service";

@Injectable()
export class MtSoldtoSelectionComponentService {
  constructor(private mtSoldToService: MtSoldToService) {}

  getSoldToList(): Observable<any> {
    return this.mtSoldToService.getSoldToList();
  }


}



