import { Injectable } from "@angular/core";
import { AddedToCartDialogEventListener } from "@spartacus/cart/base/components";
import { CartUiEventAddToCart } from "@spartacus/cart/base/root";
import { LAUNCH_CALLER } from "@spartacus/storefront";
import { take } from "rxjs/operators";


@Injectable({
  providedIn: 'root',
})
export class MtAddedToCartDialogEventListener extends AddedToCartDialogEventListener {

  protected override openModal(event: CartUiEventAddToCart): void {

    const dialog = this.launchDialogService.openDialog(
      LAUNCH_CALLER.ADDED_TO_CART,
      undefined,
      undefined,
      event
    );

    if (dialog) {
      dialog.pipe(take(1)).subscribe();
    }
  }

}
