export const mtProductDetailsTab = {
  texts: {
    browseProductFamily: 'Browse by Product Family',
    productSpecificationsTitle: 'Product Specifications',
    functionalSpecificationsTitle: 'Logistical Specifications',
    featuresHeadline:'Features',
    descHeadline:'Description',
    plantData: {
      countryOfOrigin: 'Country of origin',
      customsTariffNumber: 'Customs tariff number',
      hazardousMaterialNumber: 'Hazardous material number'
    }
  }
}

export const mtProductInfo = {
  labels: {
    showMore: 'Show More'
  }
}

export const mtProductRecommendations = {
  title: 'Frequently purchased together with'
}

export const mtProductDetailsResource = {
  mtProductDetailsTab, mtProductRecommendations, mtProductInfo
}
