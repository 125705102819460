/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  Address,
  CommandService, GlobalMessageService, GlobalMessageType,
  OccEndpointsService,
  StateWithUser, UserActions,
  UserAddressConnector,
  UserAddressService,
  UserIdService
} from "@spartacus/core";
import { throwError } from 'rxjs';
import {catchError, take} from 'rxjs/operators';
import {ActiveCartFacade} from "@spartacus/cart/base/root";


@Injectable({
  providedIn: 'root',
})
export class MtUserAddressService extends UserAddressService {


  constructor(store: Store<StateWithUser>,
      userIdService: UserIdService,
      userAddressConnector: UserAddressConnector,
      command: CommandService,
      protected http: HttpClient,
      protected occEndpoints: OccEndpointsService,
      protected activeCartFacade: ActiveCartFacade,
      protected globalMessageService : GlobalMessageService){
    super(store, userIdService, userAddressConnector, command);
  }

  loadAddressesWithAddressType(addressType: MtAddressType): void {
    this.userIdService.takeUserId().subscribe((userId) => {
      this.store.dispatch(new UserActions.LoadUserAddresses(userId + '|' + addressType.toString()));
    });
  }

  loadAddressesWithAddressTypeAndCartId(addressType: MtAddressType, cartId: string | undefined): void {
    this.userIdService.takeUserId().subscribe((userId) => {
      this.store.dispatch(new UserActions.LoadUserAddresses(userId + '|' + addressType.toString() + '|' + cartId) );
    });
  }

  private updateUserAddressWithCartEndpoint(addressId : string, address : Address, userId : string, cartId : string)
  {
    if(address.addressType == MtAddressType.SHIP_TO)
    {
      return this.occEndpoints.buildUrl('editDeliveryAddress', {
        urlParams: { userId, cartId, addressId},
      });
    }
    else{
      return this.occEndpoints.buildUrl('editBillingAddress', {
        urlParams: { userId, cartId, addressId},
      });
    }
  }

  override updateUserAddress(addressId : string, address: Address) {
    this.activeCartFacade.getActiveCartId().pipe(take(1)).subscribe(
      cartId => {
        this.userIdService.takeUserId().pipe(take(1)).subscribe(
          userId => {
            const endpoint = this.updateUserAddressWithCartEndpoint(addressId, address, userId, cartId)
            this.http.patch(endpoint,address)
              .pipe(catchError((error: any) => throwError(error)))
              .pipe(take(1)).subscribe()

            this.globalMessageService.add(
              { key: 'addressForm.userAddressUpdateSuccess' },
              GlobalMessageType.MSG_TYPE_CONFIRMATION
            );
            this.loadAddressesWithAddressTypeAndCartId(address.addressType || MtAddressType.SHIP_TO, cartId);
          }
        )
      }
    )
  }

  /**
   * Sets user address as default
   * @param addressId a user address ID
   * override to accommodate MT specific parameter, addressType
   */
  override setAddressAsDefault(addressId: string): void {
    // this.userIdService.takeUserId().subscribe((userId) => {
    //   this.store.dispatch(
    //     new UserActions.UpdateUserAddress({
    //       userId,
    //       addressId,
    //       address: { defaultAddress: true, addressType: MtAddressType.SHIP_TO }
    //     })
    //   );
    // });
    this.userIdService.takeUserId().subscribe(userId => {

      const url = this.occEndpoints.buildUrl('setDefaultDeliveryAddress', {
        urlParams: { userId, addressId },
      });

      this.http
        .put(url,{})
        .pipe(catchError((error: any) => throwError(error))).subscribe(result => console.log("changed default address"));

    });



  }
  //TODO: Adrian: param required
  // override setAddressAsDefault(addressId: string, pAddressType: MtAddressType): void {
  //           // addressType: pAddressType }

}

export enum MtAddressType {
  SHIP_TO = "SHIP_TO",
  BILL_TO = "BILL_TO",
}

