import { Injectable } from '@angular/core';
import {AuthStorageService, AuthToken} from "@spartacus/core";
import {Observable} from "rxjs";

/**
 * Storage service for AuthToken. Used as a storage for angular-oauth2-oidc library.
 */
@Injectable({
  providedIn: 'root',
})
export class MtAuthStorageService extends AuthStorageService {
  /**
   * Extracted keys that are not `JSON.stringify` from reading the angular-oauth2-oidc source code
   */
  protected static  readonly mtNonStringifiedOAuthLibKeys = [
    'PKCE_verifier',
    'access_token',
    'refresh_token',
    'expires_at',
    'access_token_stored_at',
    'id_token',
    'id_token_expires_at',
    'id_token_stored_at',
    'session_state',
    'nonce',
    'remember_me'
  ];



  protected override decode(key: string, value: any) {
    if (MtAuthStorageService.mtNonStringifiedOAuthLibKeys.includes(key)) {
      return value;
    }
    return JSON.stringify(value);
  }

  protected override encode(key: string, value: any) {
    if (MtAuthStorageService.mtNonStringifiedOAuthLibKeys.includes(key)) {
      return value;
    } else {
      try {
        return JSON.parse(value);
      } catch {
        return value;
      }
    }
  }

}
