import { createFeatureSelector, createSelector, MemoizedSelector } from "@ngrx/store";
import { MtNavigationNode } from "../../model/mt-navigation-node.model";
import { HomepageNavigationState, NavigationState, NAVIGATION_FEATURE, StateWithNavigation } from "../navigation-state";
import * as fromNavigationState from "../reducers/navigation.reducer";

export const getNavigationState: MemoizedSelector<
  StateWithNavigation,
  NavigationState
> = createFeatureSelector<NavigationState>(NAVIGATION_FEATURE);

export const getHomepageNavigationState: MemoizedSelector<
  StateWithNavigation,
  HomepageNavigationState
> = createSelector(getNavigationState, (state: NavigationState) => state.homepageState);

export const getCurrentNodeSelector: MemoizedSelector<
  StateWithNavigation,
  MtNavigationNode> = createSelector(
    getHomepageNavigationState,
    fromNavigationState.getSelectedNode
  );

export const getChildrenForSelectedNodeSelector: MemoizedSelector<StateWithNavigation, MtNavigationNode[]> = createSelector(
  getHomepageNavigationState,
  fromNavigationState.getChildrenForSelectedNode
);

export const getParentForSelectedNodeSelector: MemoizedSelector<StateWithNavigation, MtNavigationNode> = createSelector(
  getHomepageNavigationState,
  fromNavigationState.getParentForSelectedNode
);

