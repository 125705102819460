import { Component, OnInit } from '@angular/core';
import { CheckoutProgressMobileTopComponent } from '@spartacus/checkout/base/components';

@Component({
  selector: 'cx-checkout-progress-mobile-top',
  templateUrl: './mt-checkout-progress-mobile-top.component.html',
  styleUrls: ['./mt-checkout-progress-mobile-top.component.scss']
})
export class MtCheckoutProgressMobileTopComponent extends CheckoutProgressMobileTopComponent {

}
