
export const mtMiniCartMenu = {
  count: '{{count}}',
  labels: {
    goToShopHome: 'Visit Online Store',
    gotToCart: 'View Cart'
  }
}

export const mtMiniCart = {
  mtMiniCartMenu,
}
