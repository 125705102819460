/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Component, Input } from '@angular/core';
import { OrderSummaryComponent } from "@spartacus/cart/base/components";
import { Observable } from 'rxjs';
import { MtPriceService } from 'src/app/spartacus/custom-module/product/product-price';
import {Cart} from "@spartacus/cart/base/root";

@Component({
  selector: 'mt-order-summary',
  templateUrl: './mt-order-summary.component.html',
  styleUrls: ['./mt-order-summary.component.scss'],
})
export class MtOrderSummaryComponent extends OrderSummaryComponent {
  @Input() isCheckout: boolean = true;

  constructor(
    private mtPriceService: MtPriceService
  ) {
    super();
  }

  getTotalStandardPrice(): Observable<string | undefined> {
    const getTotalStandardPrice: number | undefined =
      (this.cart.subTotal?.value ?? 0) + (this.cart.productDiscounts?.value ?? 0);
    return this.mtPriceService.getFormattedPrice(getTotalStandardPrice, 1);
  }

  getFormattedTotalPriceWithTax(cart: Cart){
    const getTotalPriceWithTax: number | undefined = 
      Math.max((cart.totalPriceWithTax?.value ?? 0) - (cart.appliedVouchers?.[0].value ?? 0), 0);
    return this.mtPriceService.getFormattedPrice(getTotalPriceWithTax ?? 0, 1, true);
  }

  getFormattedProductDiscounts(cart: Cart){
    return this.mtPriceService.getFormattedPrice(cart.productDiscounts?.value ?? 0, 1, true)
  }

  getFormattedVoucherDiscount(cart: Cart){
    return this.mtPriceService.getFormattedPrice(cart.appliedVouchers?.[0].value ?? 0, 1, true)
  }

}
