import { Injectable } from '@angular/core';
import {BaseSiteService, EventService, Language, LanguageService, SiteContext} from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageService } from '../../util/local-storage/local-storage.service';
import { AemAdapterSiteContextConfig } from '../aem-adapter-site-context/aem-adapter-site-context.config';
import { CountryBasesiteConvertor } from '../country-site-context/country-basesite.convertor';
import {MtLangAndCountryLoaded} from "../../tms/events/mt-core-events";

@Injectable({
  providedIn: 'root'
})
export class LanguageisoContextService implements SiteContext<string>  {

  protected buildKey(country: string, language: string): string {
    return `${country}_${language}`;
  }

  private _mapLanguages(languages: Language[]): string[] {
    return [...new Set(languages.map((language: Language) => language.isocode ?? 'en'))];
  }

  constructor(
    protected config: AemAdapterSiteContextConfig,
    protected countryBasesiteConvertor: CountryBasesiteConvertor,
    protected baseSiteService: BaseSiteService,
    protected languageService: LanguageService,
    protected localStorageService: LocalStorageService,
    protected eventService: EventService,
  ) { }

  getAll(): Observable<string[]> {
    return this.languageService.getAll().pipe(
      map((languages: Language[]) => this._mapLanguages(languages))
    );
  }

  getActive(): Observable<string> {
    if (this.localStorageService.getItem("currentLanguageIso") !== undefined) {
      return of(this.localStorageService.getItem("currentLanguageIso") || 'en');
    } else {
      return this.languageService.getActive().pipe(
        map((language: string) => (language || 'en').split("_")[0])
      );
    }
  }

  setActive(isocode: string): void {
    this.baseSiteService.getActive().subscribe(
      (baseSiteUid: string) => {
        const country = this.countryBasesiteConvertor.baseSiteIdToCountry(baseSiteUid);
        const key = this.buildKey(country ?? this.config.defaultCountry, isocode ?? this.config.defaultLanguage);
        const localeConfig = (this.config.aemSiteContext && this.config.aemSiteContext[key]);

        if (localeConfig) {
          this.localStorageService.setItem('currentLanguageIso', isocode);
          this.languageService.setActive(localeConfig.language);

          let eventData = {
            lang: localeConfig.language,
            countryIsoCode: isocode
          }

          this.eventService.dispatch(eventData, MtLangAndCountryLoaded);
        }
      }
    );
  }
}
