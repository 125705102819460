import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MiniCartModule } from '@spartacus/cart/base/components/mini-cart';
import { I18nConfig, I18nModule, provideConfig, UrlModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { mtCartChunkConfig, mtCartTranslations } from '../assets/translations';
import { MtMiniCartComponent } from './components/mt-mini-cart/mt-mini-cart.component';



@NgModule({
  declarations: [
    MtMiniCartComponent
  ],
  imports: [
    CommonModule,
    MiniCartModule,
    IconModule,
    I18nModule,
    UrlModule,
    RouterModule
  ],
  exports: [
    MtMiniCartComponent
  ],
  providers: [
    provideConfig(<I18nConfig>{
      i18n: {
        resources: mtCartTranslations,
        chunks: mtCartChunkConfig,
      }
    }),
  ]
})
export class MtMiniCartModule { }
