/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { APP_BASE_HREF, isPlatformServer } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { RouterStateSnapshot, UrlTree } from '@angular/router';
import {
  CmsActivatedRouteSnapshot, CmsService, ProtectedRoutesGuard, RoutingConfigService, RoutingService, WindowRef
} from '@spartacus/core';
import { CmsPageGuard, CmsPageGuardService } from "@spartacus/storefront";
import { Observable, of } from 'rxjs';
import { PREFIX_APP_BASE_HREF } from "src/app/app.provider";

@Injectable({
  providedIn: 'root',
})
export class MtCmsPageGuard extends CmsPageGuard {


  constructor(routingService: RoutingService, cmsService: CmsService, protectedRoutesGuard: ProtectedRoutesGuard, service: CmsPageGuardService, routingConfig: RoutingConfigService, protected winRef: WindowRef,
    @Inject(PREFIX_APP_BASE_HREF) private baseHref: string,
    @Inject(APP_BASE_HREF) private appBaseHref: string,
    @Inject(PLATFORM_ID) protected platformId: any) {
    super(routingService, cmsService, protectedRoutesGuard, service, routingConfig);
  }

  /**
   * Prevents default behaviour if were not on the storefront (e.g. AEM page)
   */
  override canActivate(
    route: CmsActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {

    console.log(`MtCmsPageGuard = ${this.baseHref} = ${this.winRef?.location.pathname} - ${JSON.stringify(state.toString())} <///> ${this.appBaseHref}`)
    if (isPlatformServer(this.platformId) || (this.winRef?.location.pathname?.startsWith(this.baseHref) && this.appBaseHref.startsWith(this.baseHref))) {
      return super.canActivate(route, state);
    }
    return of(false);
  }

}
