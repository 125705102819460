import { Component, OnInit } from '@angular/core';
import { CheckoutProgressMobileBottomComponent } from '@spartacus/checkout/base/components';

@Component({
  selector: 'cx-checkout-progress-mobile-bottom',
  templateUrl: './mt-checkout-progress-mobile-bottom.component.html',
  styleUrls: ['./mt-checkout-progress-mobile-bottom.component.scss']
})
export class MtCheckoutProgressMobileBottomComponent extends CheckoutProgressMobileBottomComponent {

}
