import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { CheckoutStepService, CheckoutStepsSetGuard } from '@spartacus/checkout/base/components';
import { CheckoutDeliveryAddressFacade, CheckoutDeliveryModesFacade, CheckoutPaymentFacade, CheckoutStep } from '@spartacus/checkout/base/root';
import { RoutingConfigService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MtCheckoutBillingAddressService } from '../../core/facade/mt-checkout-billing-address.service';
import { MtCheckoutDeliveryModeService } from '../../core/facade/mt-checkout-delivery-mode.service';

@Injectable({
  providedIn: 'root'
})
export class MtCheckoutStepsSetGuard extends CheckoutStepsSetGuard implements CanActivate {

  constructor(
    checkoutStepService: CheckoutStepService,
    routingConfigService: RoutingConfigService,
    checkoutDeliveryAddressFacade: CheckoutDeliveryAddressFacade,
    checkoutPaymentFacade: CheckoutPaymentFacade,
    checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    router: Router,
    protected checkoutDeliveryMethodService: MtCheckoutDeliveryModeService,
    protected checkoutBillingAddressService: MtCheckoutBillingAddressService,
  ) {
    super(
      checkoutStepService,
      routingConfigService,
      checkoutDeliveryAddressFacade,
      checkoutPaymentFacade,
      checkoutDeliveryModesFacade,
      router
    );
    console.log("MtCheckoutStepsSetGuard loaded!");
  }


  protected override isDeliveryModeSet(
    step: CheckoutStep
  ): Observable<boolean | UrlTree> {
    return this.checkoutDeliveryMethodService.getDeliveryMethod().pipe(
      map((deliveryMethod) => (deliveryMethod !== undefined ? true : this.getUrl(step.routeName)))
    );
  }


  protected override isPaymentDetailsSet(
    step: CheckoutStep
  ): Observable<boolean | UrlTree> {
    /*
    return this.checkoutBillingAddressService.getBillingAddressState().pipe(
      filter((state) => !state.loading),
      map((state) => state.data),
      map((billingAddress) =>
        billingAddress && Object.keys(billingAddress).length !== 0
          ? true
          : this.getUrl(step.routeName)
      )
    );
    */

    return of(true);
  }

}
