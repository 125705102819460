/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  AuthService,
  CmsConfig, ConfigModule,
  GlobalMessageService, I18nConfig,
  I18nModule,
  NotAuthGuard, provideConfig,
  provideDefaultConfig,
  UrlModule, UserAuthModule,
  WindowRef,
} from '@spartacus/core';
import {
  FormErrorsModule,
  SpinnerModule,
  PasswordVisibilityToggleModule,
} from '@spartacus/storefront';
import { MtLoginFormComponentService } from './mt-login-form-component.service';
import { MtLoginFormComponent } from './mt-login-form.component';
import {translations} from "@spartacus/assets";
import {mtUserAccountTranslations, mtUserAccountTranslationChunksConfig} from "../../assets/translations/translations";
import {
  deTranslationOverwrites,
  enTranslationOverwrites,
  frTranslationOverwrites,
  nlTranslationOverwrites,
  itTranslationOverwrites
} from "../../../../../spartacus-configuration.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    FormErrorsModule,
    SpinnerModule,
    PasswordVisibilityToggleModule,
    UserAuthModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        ReturningCustomerLoginComponent: {
          component: MtLoginFormComponent,
          guards: [NotAuthGuard],
        },
      },
    }),
  ],
  providers: [
    MtLoginFormComponentService,
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        ReturningCustomerLoginComponent: {
          component: MtLoginFormComponent,
          guards: [NotAuthGuard],
          providers: [
            {
              provide: MtLoginFormComponentService,
              useClass: MtLoginFormComponentService,
              deps: [AuthService, GlobalMessageService, WindowRef],
            },
          ],
        },
      },
    }),

    provideConfig({
      i18n: { resources: translations },
    }),
    provideConfig( < I18nConfig > {
      i18n: {
        resources: mtUserAccountTranslations,
        chunks: mtUserAccountTranslationChunksConfig
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: {
          en: enTranslationOverwrites,
          de: deTranslationOverwrites,
          fr: frTranslationOverwrites,
          nl: nlTranslationOverwrites,
          it: itTranslationOverwrites
        },
      },
    }),


  ],
  declarations: [MtLoginFormComponent],
  exports: [MtLoginFormComponent],

})
export class MtLoginFormModule {}
