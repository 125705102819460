import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { OccConfig, provideConfig } from "@spartacus/core";
import { MtDeliveryMethodAdapter } from "./mt-delivery-method.adapter";
import { DELIVERY_METHOD_NORMALIZER, MtProductDocumentConverter } from "./mt-delivery-method.converter";



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    provideConfig(<OccConfig>{
      backend: {
        occ: {
          endpoints: {
            deliveryMethod:
              '/users/${userId}/carts/${cartId}/deliverymethod',
            validateVoucher:
              '/users/${userId}/carts/${cartId}/vouchers/validateVoucher',
            removeVoucher:
              '/users/${userId}/carts/${cartId}/vouchers/removeVoucher',
          }
        }
      },
    }),
    {
      provide: MtDeliveryMethodAdapter,
      useClass: MtDeliveryMethodAdapter
    },
    {
      provide: DELIVERY_METHOD_NORMALIZER,
      useExisting: MtProductDocumentConverter,
      multi: true
    }
  ]
})
export class MtOccCheckoutModule { }
