import { NgModule } from '@angular/core';
// import { cartBaseTranslationChunksConfig, cartBaseTranslations } from "@spartacus/cart/base/assets";
import {
  ADD_TO_CART_FEATURE,
  CartBaseRootModule,
  CART_BASE_FEATURE,
  MINI_CART_FEATURE,
} from "@spartacus/cart/base/root";
import { CmsConfig, I18nConfig, provideConfig } from "@spartacus/core";
import {
  cartBaseTranslationChunksConfig,
  cartBaseTranslations
} from "../../custom-module/cart/base/assets/translations/translations";

@NgModule({
  declarations: [],
  imports: [
    CartBaseRootModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
    featureModules: {
      [CART_BASE_FEATURE]: {
        module: () =>
          //because we have custom cart logic we also need to lazy load custom cart module
          import('src/app/spartacus/custom-module/cart/base/mt-cart-base.module').then((m) => m.MtCartBaseModule),
      },
    }
  }),
  provideConfig(<CmsConfig>{
    featureModules: {
      [MINI_CART_FEATURE]: {
        module: () =>
          import('@spartacus/cart/base/components/mini-cart').then((m) => m.MiniCartModule),
      },
    }
  }),
  provideConfig(<CmsConfig>{
    featureModules: {
      [ADD_TO_CART_FEATURE]: {
        module: () =>
          import('@spartacus/cart/base/components/add-to-cart').then((m) => m.AddToCartModule),
      },
    }
  }),
  provideConfig(<I18nConfig>{
    i18n: {
      resources: cartBaseTranslations,
      chunks: cartBaseTranslationChunksConfig,
    },
  })
  ]
})
export class CartBaseFeatureModule { }
