import { Injectable } from "@angular/core";
import { AuthService, CurrencyService, LanguageService, Product } from "@spartacus/core";
import { UserAccountFacade } from "@spartacus/user/account/root";
import { combineLatest, Observable, of } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { MtSoldToService } from "../../../user/account/core/mt-sold-to.service";
import { LocalStorageService } from "../../../util/local-storage/local-storage.service";
import { MtPriceModel } from "../../model/mt-price.model";
import { MtProduct } from "../../model/mt-product.model";
import { MtOccItemPriceRequestModel, MtOccPriceRequestModel } from "../../model/occ/mt-occ-price.model";
import { MtPriceConnector } from "../../product-data-binding/mt-price.connector";


export interface PriceRequest {
  productID: string;
  campaignId?: string;
  unitCode?: string;
}


@Injectable({ providedIn: 'root' })
export class MtPriceService {

  GUEST_USER = "anonymous";

  private getSoldTo(): string {
    let soldTo: string = "";
    if (this.soldToService.isCurrentSoldToSelected()) {
      soldTo = this.soldToService.getCurrentSoldTo() ?? "";
    }
    return soldTo;
  }

  constructor(
    protected soldToService: MtSoldToService,
    protected languageService: LanguageService,
    protected localStorageService: LocalStorageService,

    protected currencyService: CurrencyService,
    protected userAccountFacade: UserAccountFacade,
    protected authService: AuthService,
    protected priceConnector: MtPriceConnector
  ) {
  }

  getPrices(priceRequest: PriceRequest[]): Observable<MtPriceModel[]> {

    if (priceRequest === undefined || priceRequest.length === 0) {
      return of([]);
    }

    // filter price requests and eliminates the ones with unitCode empty
    const filteredPriceRequest =
      priceRequest.filter((priceRequest: PriceRequest) => priceRequest.productID !== undefined && priceRequest.productID.trim() !== "");
    if (filteredPriceRequest === undefined || filteredPriceRequest.length === 0) {
      return of([]);
    }

    const response: Observable<MtPriceModel[]> =
      combineLatest([
        this.currencyService.getActive(),
        //this.languageService.getActive()
        this.userAccountFacade.get() ?? {},
        of(this.getSoldTo())
      ]).pipe(
        take(1),
        map(([activeCurrency, currentUser, currentSoldTo]) => {
          //map(([activeCurrency, activeLanguage]) => {
          this.soldToService.isCurrentSoldToSelected
          const occPriceRequest = {
            currency: activeCurrency,
            customerId: currentUser?.customerId ?? this.GUEST_USER,
            //customerId: this.GUEST_USER + activeLanguage,
            authenticated: currentUser?.customerId !== undefined,
            //isAuthenticated: false,
            soldTo: currentSoldTo,
            items: filteredPriceRequest.map(item => {
              return {
                campaignId: item.campaignId,
                productID: item.productID,
                unitCode: item.unitCode
              } as MtOccItemPriceRequestModel;
            })
          } as MtOccPriceRequestModel;

          return occPriceRequest as MtOccPriceRequestModel;
        }),
        switchMap((occPriceRequest: MtOccPriceRequestModel) => this.priceConnector.getPrice(occPriceRequest))
      );

    return response;
  }


  public buildProductPriceRequest(products: Product[]): Observable<MtPriceModel[]> {
    const pricesRequest: PriceRequest[] = products.map((product) => {
      const priceRequest = {
        productID: product.code,
        campaignId: '',
        unitCode: ''
      } as PriceRequest;
      return priceRequest;
    }) ?? [];

    const mtPrices$: Observable<MtPriceModel[]> =
      this.getPrices(pricesRequest) ?? of([]);

    return mtPrices$;
  }


  public buildProductCodePriceRequest(productCodes: string[]): Observable<MtPriceModel[]> {
    const pricesRequest: PriceRequest[] = productCodes.map((productCode) => {
      const priceRequest = {
        productID: productCode,
        campaignId: '',
        unitCode: ''
      } as PriceRequest;
      return priceRequest;
    }) ?? [];

    const mtPrices$: Observable<MtPriceModel[]> =
      this.getPrices(pricesRequest) ?? of([]);

    return mtPrices$;
  }


  public setupPriceForProduct(products: Product[], mtPrices: MtPriceModel[]): Product[] | undefined {
    const mtProducts: Product[] | undefined =
      products?.map((product: Product) => {
        const mtProduct: MtProduct = {
          ...product
        } as MtProduct;
        const mtPrice = mtPrices.filter(price => price.productCode !== undefined && product.code !== undefined && price.productCode.trim() === product.code?.trim())[0] ?? {
          productCode: product.code,
          listPrice: 0,
          customerPrice: 0,
          formattedListPrice: 'N/A',
          formattedCustomerPrice: 'N/A',
          currencyIso: '',
          value: 0,
          formattedValue: 'N/A'
        };
        mtProduct.price = mtPrice;
        return mtProduct;
      });

    return mtProducts;
  }

  public getFormattedPrice(price: number, quantity: number = 1, displayIfZero = false): Observable<string | undefined> {
    return this.currencyService.getActive().pipe(
      map((currencyIso: string) => {
        if (price === 0 && !displayIfZero) {
          return '';
        } else {
          const totalPrice: any = (Math.trunc(price * quantity * 100) / 100).toFixed(2);
          let currentCountry = this.localStorageService.getItem('currentCountry') ?? 'us'; // Default to 'en-US' if not available
          if (currentCountry === "be") currentCountry = 'fr';
          else if (currentCountry === "nl") currentCountry = 'en';
          if(currentCountry === "ca"){
            const formattedPrice = Number(totalPrice).toLocaleString('en', {style: 'currency', currency: 'CAD'}).replace(/^(\D+)/, 'CAD ');
            return `${formattedPrice}`;
          }else{
            const formattedPrice = Number(totalPrice).toLocaleString(currentCountry, { style: "currency", currency: currencyIso });
            return `${formattedPrice}`;
          }
        }
      })
    );
  }


}
