/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const mtRegistrationConfForm = {

  labels:{
    confirmationTopLabel: 'Bevestiging',

    thankYou: 'Bedankt voor je aanmelding!',
    emailSent: 'Er is een e-mail naar uw inbox verzonden om uw registratie te bevestigen.',
    pleaseClick: 'Klik binnen 24 uur op de bevestigingslink in uw e-mail',
    onceConfirm: 'Zodra u uw registratie heeft bevestigd, wordt uw account geactiveerd en kunt u inloggen.',
    contact: 'Als je de e-mail niet ontvangt, neem dan ',
    contactLink: 'contact op met de klantenservice'
  },

};

export const mtRegistrationConf = {
  mtRegistrationConfForm
};
