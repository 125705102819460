import { Injectable } from "@angular/core";
import { Breadcrumb, ConverterService, Facet, FacetValue, Occ, OccProductSearchPageNormalizer, ProductSearchPage } from "@spartacus/core";
import { MtProductListComponentService } from "../product-list/components";


@Injectable({ providedIn: 'root' })
export class MtProductSearchPageNormalizer extends OccProductSearchPageNormalizer {

  private setupBreadcrumbs(target: ProductSearchPage): ProductSearchPage {

    if (target.breadcrumbs === undefined) {
      target.breadcrumbs = [];
    }

    const existingBreadcumbs: string[] =
      target.breadcrumbs
        .filter((breadcrumb: Breadcrumb) => (breadcrumb.facetCode != "allCategories" && breadcrumb.facetValueName !== undefined))
        .map((breadcrumb: Breadcrumb) => breadcrumb.facetValueName ?? '') ?? [];

    const buildBreadcrumbs: Breadcrumb[] | undefined =
      target.facets?.flatMap(
        (facet: Facet) => {
          const breadcrumbsFacet =
            facet.values?.filter((value: FacetValue) => (value.count !== undefined && value.count > 0 && value.selected === true))
              .filter((value: FacetValue) => !existingBreadcumbs.includes(value.name ?? '####'))
              .flatMap((value: FacetValue) => {
                return {
                  facetCode: `__buildin_${value.name?.toLowerCase().trim().replace(/\s+/gi, '-')}`,
                  facetName: facet.name,
                  facetValueCode: value.name,
                  facetValueName: value.name,
                  removeQuery: {
                    query: value.query?.query,
                    url: value.query?.url
                  }
                } as Breadcrumb
              });
          return breadcrumbsFacet ?? [{}];
        }
      );

    if (buildBreadcrumbs !== undefined && buildBreadcrumbs.length > 0) {
      buildBreadcrumbs.forEach((breadcrumb: Breadcrumb) => target.breadcrumbs?.push(breadcrumb));
    }

    return target;
  }


  constructor(
    private mtProductListComponentService: MtProductListComponentService,
    protected converterService1: ConverterService) {
    super(converterService1);
  }

  override convert(
    source: Occ.ProductSearchPage,
    target: ProductSearchPage = {}
  ): ProductSearchPage {

    const productSearchPageResults =
      super.convert(source, target);

    this.setupBreadcrumbs(productSearchPageResults);

    //console.log('MtProductSearchPageNormalizer_total_no_products: ' + productSearchPageResults.pagination?.totalResults ?? 0);
    //console.log(`ProductSearchPage_taget = ${JSON.stringify(target)}`);

    this.mtProductListComponentService.changeNoFoundItems.emit(
      {
        noTotalItemsFound: productSearchPageResults.pagination?.totalResults ?? 0,
        isActive: (productSearchPageResults.pagination?.pageSize ?? 1) > 1
      }
    );

    return productSearchPageResults;
  }

  protected override normalizeUselessFacets(target: ProductSearchPage): void {
    if (target.facets) {
      target.facets = target.facets.filter((facet) => {
        return (!facet.hasOwnProperty('visible') || facet.visible)
          && facet.values
          && facet.values.find((value) => {
            return ((value.count ?? 0) > 0);
          });
      });
    }
  }

  /*
    protected override normalizeFacetValues(target: ProductSearchPage): void {
    }
  */
}
