import {Injectable} from "@angular/core";
import {HttpClient, HttpContext, HttpErrorResponse} from "@angular/common/http";
import {
  CMS_COMPONENT_NORMALIZER,
  CmsBannerComponent,
  ConverterService,
  OCC_HTTP_TOKEN,
  OccEndpointsService
} from "@spartacus/core";
import {CmsComponent} from "@spartacus/core/src/model/cms.model";
import {Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {MtBannerComponent} from "../../homepage/components/mt-banner-component/mt-banner.component";

@Injectable()
export class MtProductZoovuBannerAdaptor {

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) {
  }

  getZoovuBanner<T extends CmsBannerComponent | null>(productCode: string): Observable<T | null> {
    const context = new HttpContext().set(OCC_HTTP_TOKEN, {
      sendUserIdAsHeader: true,
    });

    const url =
      this.occEndpoints.buildUrl('productZoovuBanner', {
        urlParams: {
          productCode: productCode
        }
      });

    //return this.http.get(url, { context }).pipe(
    return this.http.get(url).pipe(
      catchError((response: HttpErrorResponse) => {
        console.error(
          `Product Atp cannot be obtained: ${JSON.stringify(response)}`)
        return of({});
      }),
      this.converter.pipeable<any, T | null>(CMS_COMPONENT_NORMALIZER)
    )
  }

}
