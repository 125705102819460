import { Injectable } from '@angular/core';
import { BaseSite, BaseSiteService, CurrencyService } from '@spartacus/core';
import { MtSoldToModel } from '../../user/account/core/mt-sold-to.model';

@Injectable({
  providedIn: 'root'
})
export class CurrencyContextService {

  private _setupCurrencyFromBaseStore(baseSite: BaseSite) {
    this.currencyService.setActive(baseSite.baseStore?.defaultCurrency?.isocode || 'EUR');
  }

  constructor(
    protected currencyService: CurrencyService,
    protected baseSiteService: BaseSiteService,
  ) { }


  public setupCurrency(baseSite: BaseSite, isUserLoggedIn: boolean, isCurrentSoldToSelected: boolean) {
    if (isUserLoggedIn === false || isCurrentSoldToSelected === false) {
      this._setupCurrencyFromBaseStore(baseSite);
    }
  }


  public setupCurrencyFromSoldTo(soldTo: MtSoldToModel) {
    if (soldTo !== undefined && soldTo.currency !== undefined) {
      this.currencyService.setActive(soldTo.currency);
    } else {
      this.baseSiteService.get().subscribe((baseSite?: BaseSite) => {
        if (baseSite) {
          this._setupCurrencyFromBaseStore(baseSite);
        } else {
          console.warn('The BaseSite could not be detected.')
        }
      });
    }
  }


}
