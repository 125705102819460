import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ActiveCartFacade, DeliveryMode } from '@spartacus/cart/base/root';
import { CheckoutConfigService, CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutDeliveryModesService } from '@spartacus/checkout/base/core';
import { CheckoutDeliveryAddressFacade, CheckoutDeliveryModesFacade } from '@spartacus/checkout/base/root';
import { BaseSiteService } from "@spartacus/core";
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, take } from 'rxjs/operators';
import { MtActiveCartService } from 'src/app/spartacus/custom-module/cart/base/core/facade/mt-active-cart.service';
import { CountryContextService } from 'src/app/spartacus/custom-module/core/country-site-context/country-context.service';
import { MtCustomFormValidators } from "../../../../util/validators/mt-custom-form-validators";
import { MtCheckoutDeliveryAddressService } from '../../../core/facade/mt-checkout-delivery-address.service';
import { MtDeliveryMethodModel } from '../../../model/mt-delivery-method.model';
import { CheckoutDeliveryMethodComponentService } from './checkout-delivery-method-component.service';
import { CheckoutDeliveryMethodComponentValidators } from './checkout-delivery-method-component.validators';

@Component({
  selector: 'mt-checkout-delivery-method',
  templateUrl: './checkout-delivery-method.component.html',
  styleUrls: ['./checkout-delivery-method.component.scss'],

  providers: [
    {
      provide: ActiveCartFacade,
      useClass: MtActiveCartService
    },
    {
      provide: CheckoutDeliveryAddressFacade,
      useClass: MtCheckoutDeliveryAddressService,
    },
    {
      provide: CheckoutDeliveryModesFacade,
      useClass: CheckoutDeliveryModesService,
    }
  ]
})
export class CheckoutDeliveryMethodComponent implements OnInit, OnDestroy {

  @ViewChild('topAnchor') topAnchor!: ElementRef;
  protected subscriptions = new Subscription();
  protected busy$ = new BehaviorSubject(false);

  supportedDeliveryModes$: Observable<DeliveryMode[]>;

  mode: UntypedFormGroup = this.fb.group({
    deliveryModeId: ['', Validators.required],
    collectNumber: [''],
    deliverAsFastAsPossible: ['true', Validators.required],
    deliveryConsolidated: ['false', Validators.required],
    deliveryDate: [new Date(), [Validators.required, MtCustomFormValidators.dateNotWeekend,
    MtCustomFormValidators.dateMinimum(this.minDeliveryDate)]],
    deliveryPaymentId: ['prepaid', Validators.required],
  }, {
    validators: [
      CheckoutDeliveryMethodComponentValidators.deliveryDateRequire(
        'deliverAsFastAsPossible',
        'deliveryDate'
      ),
    ]
  });

  isUpdating$: Observable<boolean> = combineLatest([
    this.busy$,
    this.checkoutDeliveryModesFacade
      .getSelectedDeliveryModeState()
      .pipe(map((state) => state.loading)),
  ]).pipe(
    map(([busy, loading]) => busy || loading),
    distinctUntilChanged()
  );

  deliveryMethod$ =
    this.checkoutDeliveryMethodComponentService.deliveryMethod$

  get deliveryModeInvalid(): boolean {
    return !(this.mode.valid && this.mode.value);
  }

  get showDeliveryDate(): boolean {
    return this.mode.get('deliverAsFastAsPossible')?.value === 'false';
  }

  get showDeliveryPayment$(): Observable<boolean> {
    return this.activeCountry.getActive().pipe(
      map((country: string) => country === "us")
    )
  }

  get showCollectNumber(): boolean {
    return this.mode.get('deliveryPaymentId')?.value === 'collect';
  }


  get showconsolidatedDeliveryInfoText(): boolean {
    return this.mode.get('deliveryConsolidated')?.value === 'true';
  }

  get minDeliveryDate(): string {
    const today = new Date()
    const tomorrow = new Date()
    tomorrow.setDate(today.getDate() + 1)
    return tomorrow.toISOString().split('T')[0];
  }

  constructor(
    protected fb: UntypedFormBuilder,
    protected checkoutConfigService: CheckoutConfigService,
    protected activatedRoute: ActivatedRoute,
    protected checkoutStepService: CheckoutStepService,
    protected checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    protected baseSiteService: BaseSiteService,
    protected checkoutDeliveryMethodComponentService: CheckoutDeliveryMethodComponentService,
    protected activeCountry: CountryContextService
  ) {
    this.supportedDeliveryModes$ = this.checkoutDeliveryModesFacade
      .getSupportedDeliveryModes()
      .pipe(
        filter((deliveryModes) => !!deliveryModes?.length),
        distinctUntilChanged((current, previous) => {
          return JSON.stringify(current) === JSON.stringify(previous);
        })
      );
  }

  ngAfterViewInit() {
    if (this.topAnchor) {
      this.scrollToTop();
    }
  }

  initData(defaultDeliveryMethod: string | undefined) {
    this.subscriptions.add(
      this.deliveryMethod$.subscribe((deliveryMethod: MtDeliveryMethodModel) => {
        this.mode.get('deliveryModeId')?.setValue(deliveryMethod.deliveryModeCode ?? defaultDeliveryMethod);
        this.mode.get('collectNumber')?.setValue(deliveryMethod.collectNumber);
        this.mode.get('deliverAsFastAsPossible')?.setValue((deliveryMethod.isDeliverAsFastAsPossible) ? 'true' : 'false');
        this.mode.get('deliveryConsolidated')?.setValue((deliveryMethod.isDeliveryConsolidated) ? 'true' : 'false');
        this.mode.get('deliveryDate')?.setValue(deliveryMethod.deliveryDate?.split('T')?.[0]);
        this.mode.get('deliveryPaymentId')?.setValue(deliveryMethod.deliveryPaymentCode);
      })
    );
  }

  defaultDeliveryMode$: Observable<string | undefined> = this.baseSiteService.getActive().pipe(mergeMap(
    baseSiteId => this.baseSiteService.get(baseSiteId).pipe(mergeMap(
      baseSiteModel =>
        this.supportedDeliveryModes$.pipe(mergeMap(
          deliveryModes => {
            const defaultDeliveryModeCode = (baseSiteModel as any)?.baseStore?.defaultDeliveryMode?.code
            if (deliveryModes.filter(deliveryMode => deliveryMode.code === defaultDeliveryModeCode).length)
              return of(defaultDeliveryModeCode as string | undefined)
            else return of(undefined)
          }
        ))
    ))
  ))

  ngOnInit(): void {

    this.defaultDeliveryMode$.pipe(take(1)).subscribe(
      defaultDeliveryMethod => {
        this.initData(defaultDeliveryMethod)
      }
    )

    //only validate the delivery payment details if they are displayed to the user
    this.showDeliveryPayment$.pipe(take(1)).subscribe(
      showDeliveryPayment => {
        if (showDeliveryPayment) {
          this.mode.addValidators([
            CheckoutDeliveryMethodComponentValidators.collectNumberRequire(
              'deliveryPaymentId',
              'collectNumber'
            ),
            CheckoutDeliveryMethodComponentValidators.collectNumberValidate(
              'deliveryModeId',
              'collectNumber',
              'deliveryPaymentId'
            ),
          ])
        }
      }
    )

    this.mode.get('deliveryDate')?.markAsTouched()
  }

  next(): void {
    console.log('NEXT')
    this.checkoutStepService.next(this.activatedRoute);
  }

  back(): void {
    this.checkoutStepService.back(this.activatedRoute);
  }

  saveDeliveryMethod(): void {
    if (this.mode.valid && this.mode.value) {
      this.busy$.next(true);
      const deliveryMethod: MtDeliveryMethodModel = {
        deliveryModeCode: this.mode.get('deliveryModeId')?.value,
        isDeliverAsFastAsPossible: (this.mode.get('deliverAsFastAsPossible')?.value === 'true') ? true : false,
        isDeliveryConsolidated: (this.mode.get('deliveryConsolidated')?.value === 'true') ? true : false,
        deliveryDate: (this.mode.get('deliverAsFastAsPossible')?.value === 'false') ? this.mode.get('deliveryDate')?.value + 'T00:00:00+0000' : '',
        deliveryPaymentCode: this.mode.get('deliveryPaymentId')?.value,
        collectNumber: this.mode.get('collectNumber')?.value,
      };
      this.checkoutDeliveryMethodComponentService
        .saveDeliveryMethod(deliveryMethod)
        .subscribe({
          complete: () => {
            this.onSuccess();
            this.next();
          },
          error: (err) => {
            this.onError(err);
          }
        });
    }
  }

  protected onSuccess(): void {
    this.busy$.next(false);
  }

  protected onError(err?: any): void {
    console.log(`saveDeliveryMethod_Error: ${err}`)
    this.busy$.next(false);
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
