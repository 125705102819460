import { DOCUMENT } from '@angular/common';
import {AfterViewInit, Component, ElementRef, Inject, ViewContainerRef} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActiveCartFacade, Cart } from '@spartacus/cart/base/root';
import { CheckoutPlaceOrderComponent } from '@spartacus/checkout/base/components';
import { EventService, RoutingService } from '@spartacus/core';
import { OrderFacade } from '@spartacus/order/root';
import { LaunchDialogService } from '@spartacus/storefront';
import {Observable, Subscription} from 'rxjs';
import { take } from "rxjs/operators";
import { MtPriceService } from 'src/app/spartacus/custom-module/product/product-price';
import { LanguageisoContextService } from "../../../../core/languageiso-site-context/languageiso-context.service";
import { MtCheckoutPaymentService } from '../../../core/facade/mt-checkout-payment.service';
import { MtCheckoutPlaceOrderFacade } from '../../../root/facade/mt-checkout-place-order.facade';
import {MtCmsUtilService} from "../../../../cms-structure/services/mt-cms-util-service";

@Component({
  selector: 'mt-place-order',
  templateUrl: './mt-place-order.component.html',
  styleUrls: ['./mt-place-order.component.scss']
})
export class MtPlaceOrderComponent extends CheckoutPlaceOrderComponent implements AfterViewInit{

  cart$ =
    this.activeCartFacade.getActive();
  language$ = this.languageService.getActive();
  protected subscriptions: Subscription = new Subscription();

  constructor(
    protected override orderFacade: OrderFacade,
    protected override routingService: RoutingService,
    protected override fb: UntypedFormBuilder,
    protected override launchDialogService: LaunchDialogService,
    protected override vcr: ViewContainerRef,
    protected activeCartFacade: ActiveCartFacade,
    protected mtCheckoutPlaceOrderFacade: MtCheckoutPlaceOrderFacade,
    protected mtCheckoutPaymentService: MtCheckoutPaymentService,
    protected eventService: EventService,
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
    protected mtCmsUtilService: MtCmsUtilService,
    private languageService: LanguageisoContextService,
    private mtPriceService: MtPriceService
  ) {
    super(orderFacade, routingService, fb, launchDialogService, vcr);
  }

  payPlaceOrder(cart: Cart) {
    this.mtCheckoutPlaceOrderFacade.getPaymetricPerform().subscribe(
      (redirectUrl: string) => {
        if (redirectUrl.startsWith("http")) {
          this.redirectToPaymetric(redirectUrl);
        } else {
          this.mtCheckoutPaymentService.updatePaymentError(true);
        }
      },
      (error) => {
        this.mtCheckoutPaymentService.updatePaymentError(true);
      }
    )
  }

  placeOrder(cart: Cart) {
    console.log('placeOrder')
    this.orderFacade.placeOrder(true).pipe(take(1)).subscribe(
      order => {
        this.routingService.go({ cxRoute: 'mtOrderConfirmation', params: order })
      }
    )
  }

  redirectToPaymetric(redirectUrl: string) {
    this.document.location.href = redirectUrl;
  }

  getTotalStandardPrice(cart: Cart): Observable<string | undefined> {
    const getTotalStandardPrice: number | undefined =
      (cart.subTotal?.value ?? 0) + (cart.productDiscounts?.value ?? 0);
    return this.mtPriceService.getFormattedPrice(getTotalStandardPrice, 1);
  }

  getFormattedPrice(price: number | undefined){
    return this.mtPriceService.getFormattedPrice(price ?? 0)
  }

  ngAfterViewInit(): void {
    this.modifyStyleForCheckoutAlert();

    this.subscriptions.add(this.mtCmsUtilService.elementRendered$.subscribe(() =>{
      this.modifyStyleForCheckoutAlert();
    }))
  }

  private modifyStyleForCheckoutAlert() {
    const alertParagraphs = this.document.querySelectorAll('.checkout-alert-paragraph:not([hidden])');
    if (alertParagraphs.length > 0) {
      const height = alertParagraphs[0].clientHeight;
      this.elementRef.nativeElement.classList.add('has-checkout-alert');

      const placeOrderSummary = this.elementRef.nativeElement.querySelector('.mt-place-order-summary');
      if (placeOrderSummary && window.matchMedia('(max-width: 768px)').matches) {
        placeOrderSummary.style.bottom = `${height + 73}px`;
      }
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.subscriptions.unsubscribe()
  }
}
