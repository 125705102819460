<div class="mt-minicart-container">
  <button (click)="toggleMenu()" id="mt-minicart-button">

    <div class="mt-mini-cart-icon-container">
      <cx-icon [type]="iconTypes.CART"></cx-icon>
    </div>

    <span class="count" *ngIf="(quantity$ | async)! > 0">
      {{ 'mtMiniCartMenu.count' | cxTranslate: { count: quantity$ | async } }}
    </span>
  </button>

  <ng-container *ngIf="showMenu">
    <div class="minicart-menu" *ngIf="isSpartacusContext" tabindex="-1" (focusout)="focusOut($event)" #minicart
      [attr.init]="minicart.focus()">
      <ul>
        <li>
          <a id="go-to-cart-button-for-spa" routerLink="/cart" (click)="toggleMenu()">
            {{ 'mtMiniCartMenu.labels.gotToCart' | cxTranslate }}
          </a>
        </li>
        <li>
          <a id="go-to-shop-button-for-spa" routerLink="/home" (click)="toggleMenu()">
            {{ 'mtMiniCartMenu.labels.goToShopHome' | cxTranslate }}
          </a>
        </li>
      </ul>
    </div>
    <div class="minicart-menu" *ngIf="!isSpartacusContext" tabindex="-1" (focusout)="focusOut($event)" #minicart
      [attr.init]="minicart.focus()">
      <ul>
        <li>
          <a id="go-to-cart-button-for-aem" href="{{ getURL({ cxRoute: 'cart' }) }}" (click)="toggleMenu()">
            {{ 'mtMiniCartMenu.labels.gotToCart' | cxTranslate }}
          </a>
        </li>
        <li>
          <a id="go-to-shop-button-for-aem" href="{{ getURL({ cxRoute: 'home' }) }}" (click)="toggleMenu()">
            {{ 'mtMiniCartMenu.labels.goToShopHome' | cxTranslate }}
          </a>
        </li>
      </ul>
    </div>
  </ng-container>


</div>
