
export const mtMiniCartMenu = {
  count: '{{count}}',
  labels: {
    goToShopHome: 'Visita il negozio online',
    gotToCart: 'Visualizza carrello',
  }
}

export const mtMiniCart = {
  mtMiniCartMenu
}
