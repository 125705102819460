/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {Address, ADDRESS_NORMALIZER, ADDRESS_SERIALIZER, Occ, OccUserAddressAdapter} from "@spartacus/core";


@Injectable()
export class MtOccUserAddressAdapter extends OccUserAddressAdapter {


  override loadAll(userId: string): Observable<Address[]> {
    console.log('MtOccUserAddressAdapter.loadAll, userId: ' + userId);
    if(userId.includes('|'))
    {
      let firstSeparatorIndex = userId.indexOf('|');
      let secondSeparatorIndex = userId.indexOf('|', firstSeparatorIndex + 1);

      let compUserId = userId.substring(0, firstSeparatorIndex);
      let compAddressType = secondSeparatorIndex === -1 ? userId.substring(firstSeparatorIndex + 1, userId.length) : userId.substring(firstSeparatorIndex + 1, secondSeparatorIndex);
      let cartId = secondSeparatorIndex === -1 ?
        null :
        userId.substring(secondSeparatorIndex + 1, userId.length);
      let url = "";
      if(cartId != undefined){
        url = this.occEndpoints.buildUrl('addressesWithAddressTypeAndCartId', {
          urlParams: { compUserId, compAddressType, cartId },
        });
      }else {
        url = this.occEndpoints.buildUrl('addressesWithAddressTypeAndCartId', {
          urlParams: { compUserId, compAddressType, cartId },
        });
      }

      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });

      return this.http.get<Occ.AddressList>(url, { headers }).pipe(
        catchError((error: any) => throwError(error)),
        map((addressList) => addressList.addresses ?? []),
        this.converter.pipeableMany(ADDRESS_NORMALIZER)
      );

    } else
    {
      return super.loadAll(userId);
    }

  }

  override delete(userId: string, addressId: string): Observable<{}> {
    if(addressId.includes('|')){
      let compAddressType = addressId.substring(addressId.indexOf('|') + 1, addressId.length);
      addressId = addressId.substring(0,addressId.indexOf('|'));
      let endpoint : string = compAddressType === 'BILL_TO' ? 'deleteBillingAddress' : 'deleteDeliveryAddress';
      let url = this.occEndpoints.buildUrl(endpoint, {
        urlParams: { userId, addressId },
      });
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });

      return this.http
        .delete(url, { headers })
        .pipe(catchError((error: any) => throwError(error)));
    }else {
      return super.delete(userId, addressId)
    }
  }





  /*
  add(userId: string, address: Address): Observable<{}> {
    const url = this.occEndpoints.buildUrl('addresses', {
      urlParams: { userId },
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    address = this.converter.convert(address, ADDRESS_SERIALIZER);

    return this.http
      .post(url, address, { headers })
      .pipe(catchError((error: any) => throwError(error)));
  }

  update(userId: string, addressId: string, address: Address): Observable<{}> {
    const url = this.occEndpoints.buildUrl('addressDetail', {
      urlParams: { userId, addressId },
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    address = this.converter.convert(address, ADDRESS_SERIALIZER);

    return this.http
      .patch(url, address, { headers })
      .pipe(catchError((error: any) => throwError(error)));
  }

  verify(userId: string, address: Address): Observable<AddressValidation> {
    const url = this.occEndpoints.buildUrl('addressVerification', {
      urlParams: { userId },
    });
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }
    address = this.converter.convert(address, ADDRESS_SERIALIZER);

    return this.http.post<AddressValidation>(url, address, { headers }).pipe(
      catchError((error: any) => throwError(error)),
      this.converter.pipeable(ADDRESS_VALIDATION_NORMALIZER)
    );
  }

  delete(userId: string, addressId: string): Observable<{}> {
    const url = this.occEndpoints.buildUrl('addressDetail', {
      urlParams: { userId, addressId },
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http
      .delete(url, { headers })
      .pipe(catchError((error: any) => throwError(error)));
  }

   */
}
