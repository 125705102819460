import { HttpClient, HttpContext, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConverterService, OccEndpointsService, OCC_HTTP_TOKEN } from "@spartacus/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { MtDeliveryMethodModel } from "../model/mt-delivery-method.model";
import { DELIVERY_METHOD_NORMALIZER } from "./mt-delivery-method.converter";
import { MtOccDeliveryMethodModel } from "./mt-occ-delivery-method.model";

@Injectable()
export class MtDeliveryMethodAdapter {

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) { }


  getDeliveryMethod(
    userId: string,
    cartId: string): Observable<MtDeliveryMethodModel> {

    const context = new HttpContext().set(OCC_HTTP_TOKEN, {
      sendUserIdAsHeader: true,
    });

    const url =
      this.occEndpoints.buildUrl('deliveryMethod', {
        urlParams: {
          userId: userId,
          cartId: cartId
        }
      });

    return this.http.get(url).pipe(
      catchError((response: HttpErrorResponse) => {
        console.error(
          `Delivery Method cannot be retrieved: ${JSON.stringify(response)}`)
        return of({});
      }),
      this.converter.pipeable(DELIVERY_METHOD_NORMALIZER)
    )
  }


  saveDeliveryMethod(
    userId: string,
    cartId: string,
    deliveryMethod: MtDeliveryMethodModel
  ): Observable<any> {

    const context = new HttpContext().set(OCC_HTTP_TOKEN, {
      sendUserIdAsHeader: true,
    });

    const url =
      this.occEndpoints.buildUrl('deliveryMethod', {
        urlParams: {
          userId: userId,
          cartId: cartId
        }
      });

    const request: MtOccDeliveryMethodModel = {
      deliveryMode: {
        code: deliveryMethod.deliveryModeCode,
        description: '',
        name: ''
      },
      deliverAsFastAsPossible: deliveryMethod.isDeliverAsFastAsPossible,
      deliveryConsolidated: deliveryMethod.isDeliveryConsolidated,
      deliveryDate: deliveryMethod.deliveryDate,
      deliveryPayment: {
        code: deliveryMethod.deliveryPaymentCode,
        description: '',
        name: ''
      },
      collectNumber: deliveryMethod.collectNumber
    };

    return this.http.post(url, request, { context }).pipe(
      catchError((response: HttpErrorResponse) => {
        console.error(
          `Delivery Method cannot be saved: ${JSON.stringify(response)}`)
        return of({});
      }),
      //this.converter.pipeable(DELIVERY_METHOD_NORMALIZER)
    )
  }

}
