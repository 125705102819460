import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfigModule, I18nConfig, I18nModule, provideConfig, RoutesConfig, RoutingConfig, UrlModule } from '@spartacus/core';
import { ActiveFacetsModule, AtMessageModule, CmsParagraphModule, FacetListModule, FacetModule, FacetService, IconModule, KeyboardFocusModule, LaunchRenderStrategy, MediaModule, PaginationModule, ProductFacetService, ProductListModule, SortingModule, SpinnerModule } from '@spartacus/storefront';
import { mtProductChunkConfig, mtProductTranslations } from '../assets/translations/translations';
import { ProductAddToCartModule } from '../product-add-to-cart';
import { MtProductAtpModule } from "../product-availability/components/mt-product-atp";
import { ProductDataBindingModule } from '../product-data-binding/product-data-binding.module';
import { ProductPriceModule } from '../product-price';
import { ProductRequestQuoteModule } from '../product-request-quote';
import { MtProductListComponentService } from './components';
import { MtActiveFacetsComponent } from './components/mt-active-facets/mt-active-facets.component';
import { MtFacetListComponent } from './components/mt-facet-list/mt-facet-list.component';
import { MtFacetComponent } from './components/mt-facet/mt-facet.component';
import { MtItemsPerPageComponent } from './components/mt-items-per-page/mt-items-per-page.component';
import { MtPaginationComponent } from './components/mt-pagination/mt-pagination.component';
import { MtProductListItemComponent } from './components/mt-product-list-item/mt-product-list-item.component';
import { MtProductListNoResultsComponent } from './components/mt-product-list-no-results/mt-product-list-no-results.component';
import { MtProductListComponent } from './components/mt-product-list/mt-product-list.component';
import { MtFacetService } from './services/mt-facet.service';
import { MtInlineRootRenderStrategy } from './services/mt-inline-root-render.strategy';
import { MtProductFacetService } from './services/mt-product-facet.service';
import { SearchParamsParserFromURLStrategy } from './strategies/search-params-parser-from-url.strategy';
import { SearchParamsParserStrategy } from './strategies/search-params-parser.strategy';
import { MtPlpComponent } from './components/mt-plp/mt-plp.component';


@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    //OutletRefModule,
    MediaModule,
    ProductListModule,
    PaginationModule,

    ActiveFacetsModule,
    FacetModule,
    FacetListModule,
    SortingModule,

    I18nModule,
    SpinnerModule,
    UrlModule,
    IconModule,
    KeyboardFocusModule,
    CmsParagraphModule,

    AtMessageModule,

    ProductPriceModule,

    ConfigModule.withConfig({
      pagination: {
        rangeCount: 5,
        addStart: true,
        addEnd: true,
        addNext: true,
        addPrevious: true
      }
    }),
    ProductAddToCartModule,
    ProductRequestQuoteModule,
    ProductDataBindingModule,
    MtProductAtpModule,
  ],

  providers: [
    MtProductListComponentService,
    {
      provide: LaunchRenderStrategy,
      useExisting: MtInlineRootRenderStrategy,
      multi: true,
    },
    {
      provide: SearchParamsParserStrategy,
      useExisting: SearchParamsParserFromURLStrategy,
      multi: true
    },
    {
      provide: ProductFacetService,
      useExisting: MtProductFacetService,
      multi: false
    },
    {
      provide: FacetService,
      useExisting: MtFacetService,
      multi: false
    },

    provideConfig(<RoutingConfig>{
      routing: {
        routes: {
          search: { paths: ['search/:query'], },
        } as RoutesConfig
      }
    }),


    provideConfig(<I18nConfig>{
      i18n: {
        resources: mtProductTranslations,
        chunks: mtProductChunkConfig,
      }
    }),

    //{ provide: LocationStrategy, useClass: HashLocationStrategy },

  ],

  declarations: [
    MtProductListItemComponent,
    MtProductListComponent,
    MtProductListNoResultsComponent,
    MtItemsPerPageComponent,
    MtFacetListComponent,
    MtFacetComponent,
    MtActiveFacetsComponent,
    MtPaginationComponent,
    MtPlpComponent,
  ],
  exports: [
    MtProductListComponent,
    MtFacetListComponent,
    MtActiveFacetsComponent,
    MtPlpComponent
  ]
})
export class MtProductListComponentsModule {
}
