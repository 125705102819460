import { Component, Input, ViewChild, ElementRef, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ActiveCartFacade, Cart, PaymentType } from '@spartacus/cart/base/root';
import { CheckoutPaymentTypeComponent } from '@spartacus/checkout/b2b/components';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutDeliveryAddressFacade } from '@spartacus/checkout/base/root';
import {Address, GlobalMessageService, GlobalMessageType, isNotUndefined, TranslationService} from '@spartacus/core';
import { User, UserAccountFacade } from '@spartacus/user/account/root';
import {combineLatest, Observable, of, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, map, take, tap} from 'rxjs/operators';
import { MtActiveCartService } from 'src/app/spartacus/custom-module/cart/base/core/facade/mt-active-cart.service';
import { MtOrganisationRegistrationStatusEnum } from 'src/app/spartacus/custom-module/user/account/core/mt-sold-to.service';
import { MtAddressType } from "../../../../user/account/services/mt-user-address.service";
import { MtCheckoutBillingAddressService } from '../../../core/facade/mt-checkout-billing-address.service';
import { MtOrderSimulationResponseError } from '../../../model/mt-order-simulation-response-error.model';
import { MtUser } from '../../../model/mt-user.model';
import { MtCheckoutPaymentTypeFacade } from '../../../root/facade/mt-checkout-payment.facade';
import { MtCheckoutDeliveryAddressComponent } from '../checkout-delivery-address/mt-checkout-delivery-address.component';
import {MtCustomFormValidators} from "../../../../util/validators/mt-custom-form-validators";


@Component({
  selector: 'mt-checkout-payment-details',
  templateUrl: './mt-checkout-payment-details.component.html',
  styleUrls: ['./mt-checkout-payment-details.component.scss'],

  providers: [
    {
      provide: ActiveCartFacade,
      useClass: MtActiveCartService
    },
  ]
})
export class MtCheckoutPaymentDetailsComponent extends CheckoutPaymentTypeComponent implements OnInit{

  isFullyRegisteredUser: boolean = false;
  isPONumberMandatory: boolean = false;
  showPoNumberErrorMessage: boolean = false;
  isGuestUser: boolean = false;
  purchaseOrderNumber: string = '';
  phoneNumber: string = '';
  phoneNumberTranslated = '';
  checkoutPaymentDetailsForm!: FormGroup;
  cart$: Observable<Cart> = of({} as Cart);
  hasValidationErrors: boolean = false;
  showBillingAddress: boolean | undefined;
  displayNewAddressButton: boolean | undefined;
  hasAddresses: boolean = false;
  sameAsDeliveryAddress: boolean = true;
  displayPaymentInfo: boolean = true;
  PHONE_NUMBER_LABEL = "checkoutPaymentDetails.phoneNumber";
  protected subscriptions: Subscription = new Subscription();

  @Input() addAddressForm: boolean = false;

  @ViewChild(MtCheckoutDeliveryAddressComponent) checkoutDeliveryAddressComponent!: MtCheckoutDeliveryAddressComponent;
  @ViewChild('topAnchor') topAnchor!: ElementRef;

  constructor(
    protected mtCheckoutPaymentTypeFacade: MtCheckoutPaymentTypeFacade,
    checkoutStepService: CheckoutStepService,
    activatedRoute: ActivatedRoute,
    userAccountFacade: UserAccountFacade,
    protected activeCartFacade: ActiveCartFacade,
    protected formBuilder: FormBuilder,
    protected globalMessageService: GlobalMessageService,
    protected mtCheckoutBillingAddressService: MtCheckoutBillingAddressService,
    protected translationService: TranslationService,
    ) {

    super(mtCheckoutPaymentTypeFacade, checkoutStepService, activatedRoute);

    userAccountFacade.get().subscribe(currentUser => {
      if (currentUser && currentUser.uid != "") {
        this.isGuestUser = false;
      } else {
        this.isGuestUser = true;
      }

      if (!this.isGuestUser && ((currentUser as User).companyRegistrationStatus === MtOrganisationRegistrationStatusEnum.SINGLE_SOLDTO_ACCOUNT ||
        (currentUser as User).companyRegistrationStatus === MtOrganisationRegistrationStatusEnum.MULTIPLE_SOLDTO_ACCOUNTS)) {
        this.isFullyRegisteredUser = true;
      }
      this.cart$ = this.activeCartFacade.getActive();
      this.cart$.subscribe(cart => {
        if (cart.purchaseOrderNumber) {
          this.purchaseOrderNumber = sessionStorage.getItem('poNumber') || '';
        }
        if (cart.user) {
          this.phoneNumber = (cart.user as MtUser).phoneNumber;
        }
        if (cart.paymentType?.code) {
          this.selectPaymentType(cart.paymentType.code);
        }
      });
    });
  }

  ngOnInit() {
    this.subscriptions.add(combineLatest([this.translationService.translate(this.PHONE_NUMBER_LABEL)]).subscribe(
      ([phoneLabel]) =>{
        if(this.isGuestUser ){
          this.checkoutPaymentDetailsForm = this.formBuilder.group({
            poNumber: new FormControl(this.purchaseOrderNumber),
            telephone: new FormControl('', [Validators.required, MtCustomFormValidators.onlyNumbers(this.phoneNumber), MtCustomFormValidators.lengthBetween(7, 30, phoneLabel)]),
          });
        }else {
          this.checkoutPaymentDetailsForm = this.formBuilder.group({
            poNumber: new FormControl(this.purchaseOrderNumber)
          });
        }
        const storedPoNumber = sessionStorage.getItem('poNumber');

        if (storedPoNumber) {
          this.purchaseOrderNumber = storedPoNumber;
          this.checkoutPaymentDetailsForm.get('poNumber')?.setValue(this.purchaseOrderNumber);
        }

      }
    ))


  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }


  ngAfterViewInit() {
    if (this.topAnchor) {
      this.scrollToTop();
    }
  }

  override paymentTypes$: Observable<PaymentType[]> =
            this.checkoutPaymentTypeFacade.getPaymentTypes()
                .pipe(map(
                  (paymentTypes: PaymentType[]) => {
                    return paymentTypes.sort((a,b) => (a.code && b.code) ? a.code.localeCompare(b.code) : 0);
                  }
                ));

  override typeSelected$: Observable<PaymentType> = combineLatest([
    this.checkoutPaymentTypeFacade.getSelectedPaymentTypeState().pipe(
      filter((state) => !state.loading),
      map((state) => state.data)
    ),
    this.paymentTypes$,
  ]).pipe(
    map(
      ([selectedPaymentType, availablePaymentTypes]: [
        PaymentType | undefined,
        PaymentType[]
      ]) => {
        if (
          selectedPaymentType &&
          availablePaymentTypes.find((availablePaymentType) => {
            return availablePaymentType.code === selectedPaymentType.code;
          })
        ) {
          return selectedPaymentType;
        }
        if (availablePaymentTypes.length) {
          this.busy$.next(true);
          let selectedPaymentTypeCode: PaymentType = availablePaymentTypes[0];
          for (let paymentType of availablePaymentTypes) {
            if ((paymentType.code as string) == 'ACCOUNT' && this.isFullyRegisteredUser) {
              selectedPaymentTypeCode = paymentType;
              this.isPONumberMandatory = true;
            } else if ((paymentType.code as string) == 'CARD' && !this.isFullyRegisteredUser) {
              selectedPaymentTypeCode = paymentType;
            }
          }

          this.mtCheckoutPaymentTypeFacade
            .setPaymentType(selectedPaymentTypeCode.code as string)
            .subscribe({
              complete: () => this.onSuccess(),
              error: () => this.onError(),
            });

          return selectedPaymentTypeCode;
        }
        return undefined;
      }
    ),
    filter(isNotUndefined),
    distinctUntilChanged(),
    tap((selected) => {
      this.typeSelected = selected?.code;
      //this.checkoutStepService.resetSteps();
      // this.checkoutStepService.disableEnableStep(
      //   CheckoutStepType.PAYMENT_DETAILS,
      //   selected?.code === B2BPaymentTypeEnum.ACCOUNT_PAYMENT
      // );
    })
  );

  override changeType(code: string | undefined): void {
    if (code) {
      this.busy$.next(true);
      this.typeSelected = code;

      this.mtCheckoutPaymentTypeFacade
        .setPaymentType(code, this.purchaseOrderNumber, this.phoneNumber)
        .subscribe({
          complete: () => this.onSuccess(),
          error: () => this.onError(),
        });
    }

  }

  selectPaymentType(code: string): void {
    this.typeSelected = code;

    if (code === 'ACCOUNT') {
      this.isPONumberMandatory = true;
    } else {
      this.isPONumberMandatory = false;
      this.showPoNumberErrorMessage = false;
    }

    if (this.isFullyRegisteredUser && code === 'ACCOUNT'){
      this.displayNewAddressButton = false;
    } else {
      this.displayNewAddressButton = true;
    }

    this.busy$.next(true);

    this.mtCheckoutPaymentTypeFacade
      .setPaymentType(code, this.purchaseOrderNumber, this.phoneNumber)
      .pipe(take(1)).subscribe({
        complete: () => this.onSuccess(),
        error: () => this.onError(),
      });

  }

  validateOrder(): void {
    if (!this.typeSelected) {
      return;
    }

    this.busy$.next(true);

    this.cart$.pipe(take(1)).subscribe(cart => {
      if (this.isGuestUser && !this.sameAsDeliveryAddress) {
        if (this.checkoutDeliveryAddressComponent) {
          let address = this.checkoutDeliveryAddressComponent.guestAddressForm.addressForm.value as Address;
          address.addressType = MtAddressType.BILL_TO;
          address.id="";
          address.defaultAddress=true;
          address.titleCode = cart.deliveryAddress?.titleCode;
          address.firstName = cart.deliveryAddress?.firstName;
          address.lastName = cart.deliveryAddress?.lastName;
          address.email = cart.deliveryAddress?.email;

          this.mtCheckoutBillingAddressService.createBillingAddress(address);
        }
      }

      if (!this.isFullyRegisteredUser && (!this.hasAddresses && this.sameAsDeliveryAddress)) {
        this.saveSameBillingAddressAsDeliveryAddress(cart);
      }

      this.saveCartAndValidateOrder();

    });

  }

  saveSameBillingAddressAsDeliveryAddress(cart: Cart): void {
    if (cart.deliveryAddress) {
      let billingAddress = JSON.parse(JSON.stringify(cart.deliveryAddress)) as Address;
      billingAddress.addressType = MtAddressType.BILL_TO;
      billingAddress.id = "";
      billingAddress.defaultAddress = true;

      this.mtCheckoutBillingAddressService.createBillingAddress(billingAddress);
    }
  }

  saveCartAndValidateOrder(): void {
    this.mtCheckoutPaymentTypeFacade
    .setPaymentType(this.typeSelected ?? 'CARD', this.purchaseOrderNumber, this.phoneNumber)
    .subscribe({
      complete: () => this.callValidateOrderService(),
      error: () => this.onError(),
    });
  }

  callValidateOrderService(): void {
    this.mtCheckoutPaymentTypeFacade.getOrderSimulation().subscribe(orderSimulationResponse => {
      if ('errors' in orderSimulationResponse) {
        this.hasValidationErrors = true;
        for (const item of (orderSimulationResponse as MtOrderSimulationResponseError).errors) {
          this.globalMessageService.add(item.message,GlobalMessageType.MSG_TYPE_ERROR);
        }
      } else {
        this.hasValidationErrors = false;
      }

      if (!this.hasValidationErrors) {
        this.busy$.next(false);
        this.checkoutStepService.next(this.activatedRoute);
      } else {
        this.busy$.next(false);
      }
    });


  }

  updatePoNumber(poNumber: string) {
    this.purchaseOrderNumber = poNumber;
    sessionStorage.setItem('poNumber', poNumber);
    if (!this.purchaseOrderNumber && this.isPONumberMandatory) {
      this.showPoNumberErrorMessage = true;
    } else {
      this.showPoNumberErrorMessage = false;
    }
  }

  updatePhoneNumber(phoneNumber: string) {
    this.phoneNumber = phoneNumber;
  }

  checkHasAddresses(valueEmitted: boolean): void {
    this.hasAddresses = valueEmitted;
  }

  setSameAsDeliveryAddress(valueEmitted: boolean): void {
    this.sameAsDeliveryAddress = valueEmitted;
  }

  newAddressOpened(valueEmitted: boolean): void {
    this.displayPaymentInfo = valueEmitted;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  protected readonly MtAddressType = MtAddressType;
}
