import { UntypedFormGroup } from "@angular/forms";
import { isEmpty } from "@spartacus/cart/base/core";

export function controlsConditionalRequire(
  formGroup: UntypedFormGroup,
  firstControlName: string,
  secondControlName: string,
  expectedValue: string,
  errorName: string
): void {
  const firstControl = formGroup.controls[firstControlName];
  const secondControl = formGroup.controls[secondControlName];

  if (firstControl.value !== expectedValue) {
    secondControl.setErrors(
      null
    );
    return;
  }

  if (secondControl.errors && !secondControl.errors[errorName]) {
    return;
  }

  secondControl.setErrors(
    ((firstControl.value === expectedValue) && (isEmpty(secondControl.value))) ? { [errorName]: true } : null
  );
}


export function controlsConditionalValidate(
  formGroup: UntypedFormGroup,
  firstControlName: string,
  secondControlName: string,
  thirdControlName: string,
  expectedValue: string,
  errorName: string
): void {
  const firstControl = formGroup.controls[firstControlName];
  const secondControl = formGroup.controls[secondControlName];
  const thirdControl = formGroup.controls[thirdControlName];

  if (thirdControl.value !== expectedValue) {
    secondControl.setErrors(
      null
    );
    return;
  }

  if (secondControl.errors && !secondControl.errors[errorName]) {
    return;
  }

  let valid = true;
  // Check if provider id FedEX
  if (firstControl.value?.startsWith("4")) {
    const regex = /^\d{9}$/g;
    valid = (secondControl.value?.length === 9) && (regex.test(secondControl.value));
  } else if (firstControl.value?.startsWith("2") || firstControl.value?.startsWith("3")) {
    // Check if provider id UPS
    valid = secondControl.value?.length === 6;
  } else if (isEmpty(secondControl.value)) {
    valid = false;
  }

  secondControl.setErrors(
    (!valid) ? { [errorName]: true } : null
  );
}


export class CheckoutDeliveryMethodComponentValidators {

  static collectNumberRequire(deliveliryPaymentCodeControlName: string, collectorNumberControlName: string): any {
    const validator = (formGroup: UntypedFormGroup) =>
      controlsConditionalRequire(
        formGroup,
        deliveliryPaymentCodeControlName,
        collectorNumberControlName,
        'collect',
        'required'
      );

    return validator;
  }

  static collectNumberValidate(deliveliryMethodCodeControlName: string, collectorNumberControlName: string, deliveliryPaymentCodeControlName: string): any {
    const validator = (formGroup: UntypedFormGroup) =>
      controlsConditionalValidate(
        formGroup,
        deliveliryMethodCodeControlName,
        collectorNumberControlName,
        deliveliryPaymentCodeControlName,
        'collect',
        'validate'
      );

    return validator;
  }

  static deliveryDateRequire(deliveryDateCodeControlName: string, deliveryDateControlName: string): any {
    const validator = (formGroup: UntypedFormGroup) =>
      controlsConditionalRequire(
        formGroup,
        deliveryDateCodeControlName,
        deliveryDateControlName,
        'false',
        'required'
      );

    return validator;
  }

}
