<ng-container *ngIf="data$ | async as data; else loading">

  <div>
    <a href="{{getUrlLinkLocalized(data)}}" target="_blank">
      <cx-media [container]="getImage(data)"></cx-media>
      <span>{{data.headline}}</span>
    </a>
  </div>

</ng-container>

<ng-template #loading>
  <div class="cx-spinner"><cx-spinner></cx-spinner></div>
</ng-template>
