<ng-container (esc)="closeOptions()">

  <div class="mt-item-per-page-container" [cxFocus]="focusConfig" (esc)="closeOptions()">

    <span class="mt-dropdown-container" (click)="onToggle()"><span
        class="mt-dropdown-text">{{'mtItemsPerPage.items.label' | cxTranslate: {pageSize: selectedItem.value}
        }}</span><span class="mt-dropdown-icon" [class.mt-dropdown-icon-open]="toggleIsOpen">
        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.5 0.75L6 5.25L1.5 0.75" stroke="#004494" stroke-width="1.5" />
        </svg>
      </span>
    </span>

    <div class="mt-dropdown-options-container-wrapper" [hidden]="!toggleIsOpen">
      <ul class="mt-dropdown-options-container">
        <li class="mt-dropdown-option" *ngFor="let item of items" (click)="onSelectItem(item)">
          <div class="mt-dropdown-option-text">{{'mtItemsPerPage.items.label' |
            cxTranslate: {pageSize: item.value} }}
          </div>
        </li>
      </ul>
    </div>

  </div>

</ng-container>