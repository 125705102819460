/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";


@Component({
  selector: 'mt-newsletter-subscriptions',
  templateUrl: './mt-newsletter-subscriptions.component.html',
  styleUrls: ['./mt-newsletter-subscriptions.component.scss']
})
export class MtNewsletterSubscriptionsComponent {

};
