import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Address, backOff, ConverterService, isJaloError, normalizeHttpError, OccEndpointsService } from '@spartacus/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class MtOccCheckoutBillingAddressAdapter
{
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) {}


  public setAddress(
    userId: string,
    cartId: string,
    addressId: string
  ): Observable<unknown> {
    return this.http
      .put<unknown>(
        this.getSetBillingAddressEndpoint(userId, cartId, addressId),
        {}
      )
      .pipe(
        catchError((error) => throwError(normalizeHttpError(error))),
        backOff({
          shouldRetry: isJaloError,
        })
      );
  }

  public createAddress(
    userId: string,
    cartId: string,
    address: Address
  ): Observable<unknown> {
    return this.http
      .post<unknown>(
        this.createBillingAddressEndpoint(userId, cartId),
        address,
        {}
      )
      .pipe(
        catchError((error) => throwError(normalizeHttpError(error))),
        backOff({
          shouldRetry: isJaloError,
        })
      );
  }

  protected getSetBillingAddressEndpoint(
    userId: string,
    cartId: string,
    addressId?: string
  ): string {
    return this.occEndpoints.buildUrl('setBillingAddress', {
      urlParams: { userId, cartId },
      queryParams: { addressId },
    });
  }

  protected createBillingAddressEndpoint(
    userId: string,
    cartId: string
  ): string {
    return this.occEndpoints.buildUrl('createBillingAddress', {
      urlParams: { userId, cartId }
    });
  }

  public setBillingAddressAsDefault(userId: string, addressId: string | undefined): void {
    const url = this.occEndpoints.buildUrl('setDefaultBillingAddress', {
      urlParams: { userId, addressId },
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.http
      .put(url, { headers })
      .pipe(catchError((error: any) => throwError(error))).subscribe(result => console.log("Set billing address as default"));
  }
}
