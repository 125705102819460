import { Cart } from "@spartacus/cart/base/root";
import { CheckoutPaymentTypeFacade } from "@spartacus/checkout/b2b/root";
import { Observable } from "rxjs";
import { MtOrderSimulationResponseError } from "../../model/mt-order-simulation-response-error.model";

export abstract class MtCheckoutPaymentTypeFacade extends CheckoutPaymentTypeFacade {

    /**
   * Set payment type to cart
   */
    abstract override setPaymentType(
      paymentTypeCode: string,
      purchaseOrderNumber?: string,
      phoneNumber?: string
    ): Observable<unknown>;

    abstract getOrderSimulation(): Observable<MtOrderSimulationResponseError | Cart>;
}
