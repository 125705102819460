<h1 class="cart-page-title">
  {{ 'cartDetails.cartPageTitle' | cxTranslate }}
</h1>
<mt-cart-validation-warnings></mt-cart-validation-warnings>
<ng-container *ngIf="cart$ | async as cart">
  <ng-container *ngIf="entries$ | async as entries">
    <div *ngIf="cart.totalItems! > 0" class="cart-details-wrapper">

      <cx-promotions
        [promotions]="
          (cart.appliedOrderPromotions || []).concat(
            cart.potentialOrderPromotions || []
          )
        "
      ></cx-promotions>

      <mt-cart-item-list
        [items]="entries"
        [cartIsLoading]="!(cartLoaded$ | async)"
        [promotionLocation]="promotionLocation"
        [options]="{
          isSaveForLater: false,
          optionalBtn: saveForLaterBtn
        }"
      ></mt-cart-item-list>

      <div class="form_btn-action-wrapper">
        <a [routerLink]="{ cxRoute: 'home' } | cxUrl">
        <button id="continueShopping" class="btn-secondary btn-action btn-large" type="submit">
          <span> {{ 'mtProductCart.labels.actions.continueShopping' |cxTranslate }}</span>
        </button>
        </a>
      </div>

    </div>
  </ng-container>
</ng-container>

<ng-template let-ctx #saveForLaterBtn>
  <button
    *ngIf="selectiveCartEnabled"
    class="link cx-action-link cx-sfl-btn"
    [disabled]="ctx.loading"
    (click)="saveForLater(ctx.item)"
    type="button"
  >
    {{ 'saveForLaterItems.saveForLater' | cxTranslate }}
  </button>
</ng-template>
