
export const mtActiveFacets = {
  labels: {
    clearAllActiveFacets: 'Tout effacer'
  }
}

export const mtFacets = {
  mtActiveFacets
}
