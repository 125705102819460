import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Breadcrumb, WindowRef } from '@spartacus/core';
import { ActiveFacetsComponent, FacetList } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { MtFacetService } from '../../services/mt-facet.service';

@Component({
  selector: 'mt-active-facets',
  templateUrl: './mt-active-facets.component.html',
  styleUrls: ['./mt-active-facets.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MtActiveFacetsComponent extends ActiveFacetsComponent implements OnInit {

  override facetList$: Observable<FacetList> = this.facetService.facetList$;

  @Input()
  category?: string;

  constructor(
    protected override facetService: MtFacetService,
    protected winRef: WindowRef,
  ) {
    super(facetService);
  }

  ngOnInit(): void {
    this.facetService.setupCategoryPage(this.category);
  }

  onClearAll() {
    console.log("clear all")
    if (this.winRef.nativeWindow) {
      this.winRef.nativeWindow.location.hash =
        this.facetService.clearAllFacets(this.winRef.nativeWindow.location.href);
    }
  }

  onClearFacet(breadcrumb: Breadcrumb) {
    //console.log(`Clear facet: ${JSON.stringify(breadcrumb)}`)
    if (this.winRef.nativeWindow) {
      this.winRef.nativeWindow.location.hash =
        this.facetService.clearFacet(breadcrumb, this.winRef.nativeWindow.location.href);
    }
  }

}
