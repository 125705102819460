import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, OnDestroy, OnInit, PLATFORM_ID } from "@angular/core";
import { AuthRedirectService, BaseSite, BaseSiteService } from "@spartacus/core";
import { LaunchDialogService, LAUNCH_CALLER } from "@spartacus/storefront";
import { combineLatest, EMPTY, Observable, Subscription } from "rxjs";
import { distinctUntilChanged, take } from "rxjs/operators";
import { MtSoldToService } from "../../user/account/core/mt-sold-to.service";
import { MtAuthService } from "../../user/auth/mt-auth-service";
import { LocalStorageService } from "../../util/local-storage/local-storage.service";
import { CurrencyContextService } from "../currency-site-context/currency-context.service";


@Injectable({ providedIn: 'root' })
export class MtCountryChangeListener implements OnDestroy, OnInit {

  dialog: Observable<any> = EMPTY;
  subscription: Subscription = new Subscription();

  constructor(
    protected currencyContextService: CurrencyContextService,
    protected baseSiteService: BaseSiteService,
    protected authService: MtAuthService,
    protected soldToService: MtSoldToService,
    protected launchDialogService: LaunchDialogService,
    protected authRedirectService: AuthRedirectService,
    protected localStorageService: LocalStorageService,
    @Inject(PLATFORM_ID) protected platformId: any,
  ) {
    // Setup currency
    this.subscription.add(
      combineLatest([
        this.baseSiteService.get(),
        this.authService.isUserLoggedIn()
      ]).subscribe(([baseSite, isUserLoggedIn]) => {
        console.log(`BaseSiteChanged: ${baseSite?.uid} - ${baseSite?.baseStore?.defaultCurrency?.isocode} - ${isUserLoggedIn}`);
        if (baseSite) {
          this.currencyContextService.setupCurrency(baseSite, isUserLoggedIn, this.soldToService.isCurrentSoldToSelected());
        } else {
          console.warn('The Base Site could not be found.')
        }
      })
    );

    if (isPlatformBrowser(this.platformId)) {
      // Logout customer if the country is changed.
      this.subscription.add(
        this.baseSiteService.get()
          .pipe(
            distinctUntilChanged((baseSitePrev, baseSiteCurr) => {
              console.log(`BaseSiteChanged_ baseSitePrev?.uid === baseSiteCurr?.uid = ${baseSitePrev?.uid === baseSiteCurr?.uid}`);
              return baseSitePrev?.uid === baseSiteCurr?.uid
            })
          ).subscribe((baseSite?: BaseSite) => {
            const currentBaseSiteUid = this.localStorageService.getItem('currentBaseSiteUid');
            console.log(`BaseSiteChanged_ONLY: ${baseSite?.uid} - ${currentBaseSiteUid}`);

            if (currentBaseSiteUid !== baseSite?.uid) {
              this.authService.isUserLoggedIn().pipe(take(1)).subscribe((isUserLoggedIn: boolean) => {
                if (isUserLoggedIn) {
                  this.authRedirectService.saveCurrentNavigationUrl();

                  // Logout customer if the country is changed.
                  console.log("BaseSiteChanged_ONLY: LOGOUT")
                  this.authService.logout();

                  const dialogData = {
                    rememberMe: true,
                    doRefresh: undefined,
                    logoutCountryChanged: true
                  };

                  this.dialog = this.launchDialogService.openDialog(LAUNCH_CALLER.SESSION_LOGOUT, undefined, undefined, dialogData) || EMPTY;

                  if (this.dialog != EMPTY) {
                    this.dialog.pipe(take(1)).subscribe(() => { this.dialog = EMPTY });
                  }
                }
              });
            }

            this.localStorageService.setItem('currentBaseSiteUid', baseSite?.uid ?? '');
          })
      );
    }
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
