<div *ngIf="(activeStepIndex$ | async) !== undefined">
  <div *ngIf="cart$ | async as cart">
    <div class="cx-media">
      <div class="cx-list-media" *ngIf="cart?.totalItems && cart?.subTotal">

      </div>
      <div *ngFor="let step of steps$ | async; let i = index">
        <div class="cx-list-media" *ngIf="i < activeStepIndex">
          <div>{{ step.name | cxTranslate }}</div>
        </div>
        <div class="cx-list-media is-active" *ngIf="i === activeStepIndex">
          <div> {{ step.name | cxTranslate }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
