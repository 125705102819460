/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const mtOrgRegistrationForm = {

  fields: {
    company: {
      label: 'Company name*',
      placeholder: 'Required field',
    },
    company2: {
      label: 'Company name 2',
      placeholder: '',
    },
    company3: {
      label: 'Company name 3',
      placeholder: '',
    },
    country: {
      label: 'Country*',
      placeholder: 'Required field',
      hint: 'This is your country. If you need to register your account with a different country, please switch to the corresponding METTLER TOLEDO website using the country selection near the top of this page.',
    },
    addressLine1: {
      label: 'Address 1*',
      placeholder: 'Required field',
    },
    addressLine2: {
      label: 'Address 2',
      placeholder: '',
    },
    addressLine3: {
      label: 'Address 3',
      placeholder: '',
    },
    postalCode: {
      label: 'Postal code',
      placeholder: 'Required field',
    },
    state: {
      label: 'State',
      placeholder: 'Required field',
    },
    city: {
      label: 'City*',
      placeholder: 'Required field',
    },
    phoneNumber: {
      label: 'Phone number*',
      placeholder: 'Required field',
    },
    poNumber: {
      label: 'PO Number',
      placeholder: '',
    },
    poCountry: {
      label: 'PO Country*',
      placeholder: '',
    },
    poState: {
      label: 'PO State',
      placeholder: '',
    },
    poPostalCode: {
      label: 'PO Postal Code*',
      placeholder: ''
    }
  },

  labels:{
    companyRegistration: 'Company registration',
    registerLabel1: 'Congratulations, you have successfully created your user. You can now register your company.',
    registerLabel2: 'Register your company with us to take advantage of our extended digital offering.',
    laterLink: 'I will register my company later.',
    saveAsShippingAddress: 'Save as shipping address',
    saveAsBillingAddress: 'Save as billing address',
    addPoBillingAddress: 'Add PO Billing Address',
    saveAsDefault: 'Save as default',
    cancel: 'Cancel',
    save: 'Save',
  },

  formSubmitButtonLabel: 'Register Company',
};

export const mtOrgRegistration = {
  mtOrgRegistrationForm,
};
