/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const mtUserAccountAndLogin = {

  loginForm: {

    labels: {
      loginTitle: 'Login',
      signIn: 'Login',
      register: 'Registrarsi',
      lookingCustomerPortal: 'Cerchi l\'accesso al Portale Clienti?',
      clickHereLink: 'Clicca qui',
      forgotPassword: 'Ripristina la password',
      keepMeSignedIn: 'Resta loggato',
    },

    fields: {
      emailAddress: {
        label: 'Indirizzo email',
        placeholder: 'Campo obbligatorio',
      },
      password: {
        label: 'Password',
        placeholder: 'Campo obbligatorio',
      },
    },

    messages: {
      youCanLoginAccountActivated: "Il tuo account è stato attivato. Ora puoi effettuare l'accesso",
      youCanLoginAccountReactivated: "Il tuo account è stato riattivato. Ora puoi effettuare l'accesso"
    }

  },

  login: {
    labels: {
      myMTComLoginLink: 'myMT.com login',
      myMTComLoginDescription: 'Gestisci il tuo profilo online e personalizza la tua esperienza mt.com',
      customerPortalLoginLink: 'Accesso al portale clienti',
      customerPortalLoginDescription: 'Sfoglia un portfolio personalizzato, accedi ai preventivi e gestisci i tuoi dispositivi installati sulla nostra piattaforma digitale estesa.',

      myAccountLink: 'Il mio account',
      logoutLink: 'Logout',
      customerPortalLink: 'Portale clienti'
    }
  },

  logout: {
    sessionDialog: {
      title: 'Disconnessione della sessione',
      message: 'La sessione è scaduta.',
      logoutCountryChangedMessage: 'Ti stai disconnettendo perché il paese è stato cambiato.',
      button: 'OK'
    }
  },

  forgottenPassword: {
    resetPassword: 'Ripristina la password',
    emailAddress: {
      label: 'Indirizzo email',
      placeholder: 'Inserisci l\'indirizzo e-mail',
    },
    chooseNewPassword: {
      label: 'Scelta password',
      placeholder: 'Immettere una nuova password',
    },
    confirmNewPassword: {
      label: 'Ri-inserimento password',
      placeholder: 'Conferma la nuova password',
    },
    actions: {
      submit: 'Invia',
      cancel: 'Torna al login',
      resendEmail: 'Invia di nuovo l\'e-mail',
      saveNewPassword: 'Salvare'
    },
    passwordResetEmailSent:
      'Grazie per la tua richiesta! Un\'e-mail per la reimpostazione della password è stata inviata a {{email}}. Il link nell\'e-mail è valido per 30 minuti. Si prega di controllare la cartella spam.',

    passwordResetSuccess: 'La password è stata reimpostata. Effettua il login per accedere al tuo account.',
  },

  updatePasswordForm: {
    title: 'Modificare la password',
    oldPassword: {
      label: 'Vecchia password',
      placeholder: 'Campo obbligatorio',
    },
    newPassword: {
      label: 'Nuova password',
      placeholder: 'Campo obbligatorio',
    },
    confirmPassword: {
      label: 'Ri-inserimento password',
      placeholder: 'Campo obbligatorio',
    },
    actions: {
      save: "Salvare"
    },
    passwordUpdateSuccess: 'La tua password è stata cambiata.',
  },

  updateProfileForm: {
    personalData: 'Dati personali',
    none: '',
    title: 'Titolo*',
    firstName: {
      label: 'Nome*',
      placeholder: 'Campo obbligatorio',
    },
    lastName: {
      label: 'Cognome*',
      placeholder: 'Campo obbligatorio',
    },
    customerId: 'Indirizzo email*',
    actions: {
      save: "Salvare",
    },
  },

  deactivateAccount: {
    label: 'Disattivare l\'account',
    buttonLabel: 'Disattivare l\'account',
    popup: 'Sei sicuro di voler disattivare il tuo account? Continuando, non avrai più un account attivo su mtcom. Anche il tuo account condiviso sul Portale Clienti verrà disattivato.',
    cancel: 'Annulla',
  },

  reactivateAccount: {
    infoBox1: 'Il tuo account è inattivo. Per favore, ',
    infoBox2: 'clicchi qui',
    infoBox3: ' per riattivare il suo account',
    title: 'Riattivare l\'account',
    email: {
      label: 'Indirizzo email',
    },
    submit: 'Invia',
    backToLogin: 'Torna al login',
    successMessage: 'Ti abbiamo inviato un\'e-mail con un link per attivare il tuo account. Non sarai in grado di accedere fino a quando non avrai attivato il tuo account.',
  },

  soldtoSelection: {
    selectAccount: 'I tuoi account aziendali',
    mtNumber: 'Numero di conto MT',
    accountSelected: 'Conto selezionato',
    otherAccounts:'Altri conti disponibili',
    pickAccount:'Scegli di cambiare account per la tua sessione.',
    noAccounts:'Nessun altro account disponibile',
  },

  myAccount: {
    tiles: {
      myAccount: 'Il mio account',
      myProfile: 'Il mio profilo',
      newsFeed: 'Feed Personalizzato',
      personalLibrary: 'Libreria personale',
      billingAddresses: 'Indirizzi di fatturazione',
      shippingAddresses: 'Indirizzi di spedizione',
      myOrders: 'Cronologia ordini eShop',
      newsletterSubscriptions: 'Iscrizione alla Newsletter',
      productRegister: 'Registrate il vostro prodotto',
    },
    newsletterParagraph: "Queste impostazioni email sono destinate esclusivamente alle iscrizioni autonome alla Community Newsletter. Anche senza iscrizioni attive, potresti comunque ricevere email da noi in base alla tua interazione e alle tue attività di opt-in.",

    news: {
      viewMore: 'Vedi tutti'
    }
  },

  ordersRedirect: {
    confirmation: 'Conferma',
    dialogInfo: 'Verrete reindirizzate al Portale clienti METTLER TOLEDO.',
    redirect: 'OK',
  },

};

export const mtUserAccount = {
  mtUserAccountAndLogin,
};
