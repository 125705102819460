import { Injectable } from '@angular/core';
import { ActiveCartService, isEmail } from "@spartacus/cart/base/core";
import { ActiveCartFacade, Cart, MultiCartFacade } from '@spartacus/cart/base/root';
import {
  OCC_CART_ID_CURRENT,
  OCC_USER_ID_ANONYMOUS,
  OCC_USER_ID_GUEST,
  UserIdService
} from '@spartacus/core';
import { pairwise, take, withLatestFrom } from 'rxjs/operators';
import { MtSoldToService, noActiveSoldTo } from "../../../../user/account/core/mt-sold-to.service";
import { MtCartService } from "../../../services/mt-cart.service";


@Injectable({ providedIn: 'root' })
export class MtActiveCartService extends ActiveCartService implements ActiveCartFacade {

  constructor(
    protected override multiCartFacade: MultiCartFacade,
    protected override userIdService: UserIdService,
    protected mtCartService: MtCartService,
    protected soldToService: MtSoldToService,
  ) {
    super(multiCartFacade, userIdService);
    this.detectSoldToChange();
  }

  protected detectSoldToChange() {
    this.subscription.add(
      this.soldToService.activeSoldToId.pipe(pairwise(), withLatestFrom(this.activeCartId$)).subscribe(
        ([[previousSoldToId, soldToId], cartId]) => {
          //console.log(`MtActiveCartService - detectSoldToChange|[[previousSoldToId, soldToId], cartId] = ${previousSoldToId} - ${soldToId} - ${cartId}`)
          if (previousSoldToId !== soldToId) {
            if (previousSoldToId === noActiveSoldTo && cartId !== OCC_CART_ID_CURRENT) {
              this.userIdService.getUserId().pipe(take(1), withLatestFrom(this.getActive())).subscribe(([userId, cart]) => {
                if (cart.entries?.length && cart.user?.uid === OCC_USER_ID_ANONYMOUS) {
                  //create a new cart for user and set it to active (will override old cart)
                  this.multiCartFacade.createCart({ userId: userId, oldCartId: cartId, extraData: { active: true } })
                }
                //TODO: maybe clean/delete the other cart?
              })
            } else {
              this.userIdService.getUserId().pipe(take(1)).subscribe((userId) => {
                if (userId !== OCC_USER_ID_ANONYMOUS) {
                  this.mtCartService.loadCartFromBackend(soldToId);
                }
              })
            }
          }
        }
      )
    )
  }

  protected override detectUserChange() {
    //this.subscription.add(this.userAccountFacade.get().pipe(tap((user) => console.log(user))).subscribe());
  }

  protected override isCartUserGuest(cart: Cart): boolean {
    //console.log(`cart user: ${cart.user?.uid} - ${cart.user?.name}`)

    const isCartGuest = super.isCartUserGuest(cart);
    const cartUserUid = cart.user?.uid;
    const cartUserName = cart.user?.name;

    return Boolean(
      isCartGuest ||
      cartUserUid === undefined ||
      (cartUserUid === OCC_USER_ID_ANONYMOUS || cartUserName === OCC_USER_ID_GUEST) ||
      (cartUserUid.startsWith("g-")) ||
      isEmail(cartUserUid?.split('|').slice(1).join('|'))
    );

  }

}
