/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const mtRegistrationConfForm = {

  labels:{
    confirmationTopLabel: 'Confirmation',

    thankYou: 'Merci pour votre entregistrement!',
    emailSent: 'Nous vous avons envoyé un email pour confirmer votre enregistrement.',
    pleaseClick: 'Veuillez cliquer sur le lien dans les 24 heures : Confirmer l\'inscription',
    onceConfirm: 'Après avoir confirmé l\'enregistrement, votre utilisateur sera activé et vous pourrez vous connecter.',
    contact: 'Si vous ne recevez pas l\'e-mail, ',
    contactLink: 'veuillez contacter le service client'
  },

};

export const mtRegistrationConf = {
  mtRegistrationConfForm
};
