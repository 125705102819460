import { ChangeDetectionStrategy, Component, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { EventService, LanguageService, RoutingService } from '@spartacus/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CountryContextService } from 'src/app/spartacus/custom-module/core/country-site-context/country-context.service';
import { MtProduct, MT_CPV_STATUS } from '../../../model/mt-product.model';
import { QuotationUIEvent } from '../../listeners/quotation-ui.event';
import { APP_BASE_HREF } from '@angular/common';
import MtSeoUtils from 'src/app/spartacus/custom-module/util/mt-seo-utils/mt-seo-utils.module';

@Component({
  selector: 'mt-request-quotation-button',
  templateUrl: './mt-request-quotation-button.component.html',
  styleUrls: ['./mt-request-quotation-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MtRequestQuotationButtonComponent implements OnInit {

  langauge$: Observable<string | null | undefined> =
    this.languageService.getActive().pipe(map((language: string) => language.split('_')[0]));

  country$: Observable<string | null | undefined> =
    this.countryService.getActive();

  type$: Observable<string> = of("quote");

  protected createQuotationUIEvent() {
    const newEvent = new QuotationUIEvent();
    newEvent.productCode = this.product.code ?? '';
    newEvent.quantity = 1;
    return newEvent;
  }

  @Input() product: MtProduct = {} as MtProduct;

  productName$: Observable<string | null | undefined> =
    of(this.product.name);

  design: string = "";
  @Input("isSecondaryDesign")
  set isSecondaryDesign(_isSecondaryDesign: boolean) {
    this.design = _isSecondaryDesign ? "secondary" : "";
  }


  constructor(private eventService: EventService,
    private languageService: LanguageService,
    private countryService: CountryContextService,
    protected el: ElementRef,
    protected routingService: RoutingService,
    @Inject(APP_BASE_HREF) protected baseHref: string) { }

  ngOnInit(): void { }

  protected getProductUrl(): string {
    if (!this.product) {
      return "";
    }
    const url = this.routingService.getUrl({
          cxRoute: 'product',
          params: {
            code: this.product?.code,
            seoNormalizedName: MtSeoUtils.normalizeString(this.product?.name)
          },
        });
    return this.baseHref.replace(/\/*$/, "") + url;
  }

  requestQuotation() {
    this.eventService.dispatch(
      this.createQuotationUIEvent()
    );
  }

  public get getMtCPVStatusType(): typeof MT_CPV_STATUS {
    return MT_CPV_STATUS;
  }

}
