export const mtProductDetailsTab = {
  texts: {
    browseProductFamily: 'Zoeken op productfamilie',
    productSpecificationsTitle: 'Productspecificaties',
    functionalSpecificationsTitle: 'Logistieke specificaties',
    featuresHeadline:'Kenmerken',
    descHeadline:'Omschrijving',
    plantData: {
      countryOfOrigin: 'Country of origin',
      customsTariffNumber: 'Customs tariff number',
      hazardousMaterialNumber: 'Hazardous material number'
    }
  }
}

export const mtProductInfo = {
  labels: {
    showMore: 'Meer tonen'
  }
}

export const mtProductRecommendations = {
  title: 'Vaak samen gekocht met'
}

export const mtProductDetailsResource = {
  mtProductDetailsTab, mtProductRecommendations, mtProductInfo
}
