import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { LocalStorage } from './local-storage.storage';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService implements Storage {

  private storage: Storage;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.storage = new LocalStorage();

    //AppComponent.isBrowser.subscribe(isBrowser => {
    if (isPlatformBrowser(platformId)) {
      this.storage = localStorage;
    };
    //});
  }


  [name: string]: any;

  length: number = 0;

  clear(): void {
    this.storage.clear();
  }

  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  key(index: number): string | null {
    return this.storage.key(index);
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  setItem(key: string, value: string): void {
    this.storage.setItem(key, value);
  }

}
