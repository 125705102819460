import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MtCatalogsAdapter } from "./mt-catalogs.adapter";


@Injectable({ providedIn: 'root' })
export class MtCatalogsConnector {

  constructor(protected dsCatalogsAdapter: MtCatalogsAdapter) { }

  getCategoryNames(categoryCodes: string): Observable<Object> {
    return this.dsCatalogsAdapter.getCategoryNames(categoryCodes);
  }

}