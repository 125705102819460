/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {OccEndpointsService} from "@spartacus/core";
import {MtSoldToService} from "../../../../user/account/core/mt-sold-to.service";


@Injectable({ providedIn: 'root' })
export class MtSiteContextInterceptor implements HttpInterceptor {

  constructor(
    protected mtSoldToService: MtSoldToService,
    protected occEndpoints: OccEndpointsService
  ) {
    // this.activeSoldTo = mtSoldToService.getCurrentSoldTo() ?? '';
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (request.url.includes(this.occEndpoints.getBaseUrl())) {
    // if (request.url.includes('cart')) {
      request = request.clone({
        setParams: {
          soldTo: this.mtSoldToService.getCurrentSoldTo() ?? 'default'
        },
      });
    }

    return next.handle(request);
  }

}
