<a #topAnchor></a>
<div class="cx-review">
  <!-- TITLE: Order summary -->
  <h2 class="cx-review-title d-lg-block d-xl-block">
    {{ 'checkoutReview.review.title' | cxTranslate }}
  </h2>

  <span *ngIf="isRedirectToPaymetricError | async" class="payment-error">
    <p>
      {{ "checkoutReview.paymentError" | cxTranslate }}
    </p>
  </span>
  <span *ngIf="isPaymentError" class="payment-error">
    <p>
      {{ "checkoutReview.paymentError" | cxTranslate }}
    </p>
  </span>

  <ng-container *ngIf="cart$ | async as cart">
    <ng-container *ngIf="deliveryMethod$ | async as deliveryMethod">
      <div class="cx-review-summary">
        <div>
          <span class="mt-order-review-label">{{'checkoutReview.review.labels.deliveryAddress' | cxTranslate}}: </span>
          <span>{{formatAddress(cart.deliveryAddress)}}</span>
        </div>
        <div>
          <span class="mt-order-review-label">{{'checkoutReview.review.labels.deliveryOption' | cxTranslate}}: </span>
          <span>{{ cart.deliveryMode?.name || ('checkoutReview.review.labels.empty' | cxTranslate) }}</span>
        </div>
        <div>
          <span class="mt-order-review-label">{{'checkoutReview.review.labels.requestedDeliveryDate' | cxTranslate}}:
          </span>
          {{ formatDeliveryDate(deliveryMethod) | async }}
        </div>
        <div>
          <span class="mt-order-review-label">{{'checkoutReview.review.labels.consolidatedDelivery.title' |
            cxTranslate}}:
          </span>
          <span>{{ formatConsolidatedDelivery(deliveryMethod) | async }}</span>
        </div>
        <div *ngIf="(country$ | async)?.toUpperCase() === 'US'">
          <span class="mt-order-review-label">{{'checkoutReview.review.labels.deliveryPayment.title' | cxTranslate}}:
          </span>
          <span>{{ formatDeliveryPayment(deliveryMethod) | async }}</span>
        </div>
        <div>
          <span class="mt-order-review-label">{{'checkoutReview.review.labels.billingAddress' | cxTranslate}}: </span>
          <span>{{formatAddress(cart.billingAddress)}}</span>
        </div>
        <div>
          <span class="mt-order-review-label">{{'checkoutReview.review.labels.paymentType' | cxTranslate}}: </span>
          <span *ngIf="cart.paymentType?.code === 'CARD'">
            <span>{{ "checkoutPaymentDetails.paymentType_CREDIT" | cxTranslate }}</span></span>
          <span *ngIf="cart.paymentType?.code !== 'CARD'">
            <span>{{ "checkoutPaymentDetails.paymentType_ACCOUNT" | cxTranslate }}</span></span>
        </div>
        <div>
          <span class="mt-order-review-label">{{'checkoutReview.review.labels.purchaseOrderNumber.title' |
            cxTranslate}}:
          </span>
          <span>{{ formatPurchaseOrderNumber(activeCart$ | async) | async }}</span>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <hr />

  <!-- TITLE: Order summary -->
  <h2 class="cx-review-title d-lg-block d-xl-block">
    {{ 'checkoutReview.orderItems.title' | cxTranslate }}
  </h2>

  <div class="cx-review-cart-item" *ngIf="entries$ | async as entries">
    <div *ngFor="let item of entries">
      <div class="item-container">
        <!-- Item Image -->
        <div class="cx-image-container">
          <div>
            <a *ngIf="item.product?.images && item.product?.images?.hasOwnProperty('PRIMARY')"
              [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl" class="mt-product-image-container"
              style="display: flex; width: 72px;">
              <cx-media class="mt-product-image" [container]="getImage(item.product)" format="thumbnail"
                [alt]="item.product?.name || item.product?.summary || ''"></cx-media>
            </a>
            <a *ngIf="!item.product?.images?.hasOwnProperty('PRIMARY')"
              [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl" class="mt-product-image-container">
              <span class="mt-product-no-image" style="display: flex; width: 72px;">
                <svg viewBox="0 0 515 515">
                  <style type="text/css">
                    .st0 {
                      fill: #FFFFFF;
                    }

                    .st1 {
                      fill: #D3D6DB;
                    }
                  </style>
                  <rect y="0" class="st0" width="515" height="515" />
                  <path class="st1"
                    d="M352.6,198.7h-31.9v15.6h31.9V198.7z M279.3,293.6c-5.9,5.2-14.8,8.1-23,8.1c-22.2,0-39.3-17.8-39.3-40 c0-1.5,0-3.7,0-5.2L279.3,293.6z M296.3,261.7c0,2.2,0,5.2,0,5.9l-64.4-37.8c7.4-5.2,15.6-8.9,24.4-8.9 C278.6,221.7,296.3,240.2,296.3,261.7z M129.7,204.7v120c0,8.9,5.9,15.6,15.6,15.6h214.1l-65.9-37.8c-9.6,8.9-23,14.8-37.8,14.8 c-30.4,0-54.8-25.9-54.8-55.6c0-5.2,0-9.6,1.5-13.3L129.7,204.7z M115.6,170.6L406,341.7l5.2,2.2l-2.2,5.9l-2.2,2.2l-2.2,5.9 l-5.9-3.7L108.9,183.2l-5.9-3.7l3.7-5.2l1.5-2.2l3.7-5.9L115.6,170.6z M206.7,181.7l-1.5-9.6c-1.5-2.2-2.2-3.7-5.2-3.7h-15.6h-14.8 c-2.2,0-3.7,1.5-5.2,3.7l-2.2,9.6h-1.5h-11.9l68.1,40c9.6-9.6,24.4-15.6,39.3-15.6c31.9,0,55.6,25.2,55.6,55.6 c0,5.2-1.5,9.6-2.2,14.8l74.1,43.7V198.7c0-9.6-7.4-17-15.6-17h-15.6c-5.2-1.5-8.9-5.2-11.1-8.9l-2.2-3.7 c-3.7-5.9-11.9-11.1-18.5-11.1h-45.2c-7.4,0-15.6,5.2-19.3,11.1l-3.7,5.2c-2.2,2.2-5.9,5.9-9.6,7.4h-23h-11.1H206.7L206.7,181.7z" />
                </svg>
              </span>
            </a>
          </div>
        </div>
        <!-- Item Information -->
        <div class="cx-info">
          <div class="cx-info-container">
            <!-- Item Description -->
            <div class="description-container">
              <div *ngIf="item.product?.name" class="cx-name">
                <a class="cx-link" [routerLink]="
                { cxRoute: 'product', params: item.product } | cxUrl
              ">
                  {{ item.product?.name }}
                </a>
              </div>

              <div *ngIf="item.product?.code" class="cx-code">
                {{ 'checkoutReview.orderItems.labels.materialID' | cxTranslate }}: <span class="item-code">{{ item.product?.code }}</span>
              </div>

              <div *ngIf="item.product?.code" class="cx-code">
                {{ 'checkoutReview.orderItems.labels.quantity' | cxTranslate }}: {{ item.quantity }}
              </div>

              <ng-container *ngIf="loggedInUser$ | async; guestUser">
                <div *ngIf="item.product?.code" class="cx-code">
                  {{ 'checkoutReview.orderItems.labels.itemStandardPrice' | cxTranslate }}:
                  {{ getPrice(convertToMtProduct(item.product)).formattedListPrice }}
                </div>

                <div *ngIf="item.product?.code" class="cx-code">
                  {{ 'checkoutReview.orderItems.labels.itemNetPrice' | cxTranslate }}: {{
                  getPrice(convertToMtProduct(item.product)).formattedCustomerPrice }}
                </div>
              </ng-container>

              <ng-template #guestUser>
                <div *ngIf="item.product?.code" class="cx-code">
                  {{ 'checkoutReview.orderItems.labels.itemNetPrice' | cxTranslate }}: {{
                  getPrice(convertToMtProduct(item.product)).formattedListPrice }}
                </div>
              </ng-template>

            </div>
            <!-- Item Price -->
            <div *ngIf="item.totalPrice" class="price-container">
              <div class="cx-label" class="d-block total-price-label">
                {{ 'checkoutReview.orderItems.labels.totalNetPrice' | cxTranslate }}
              </div>
              <div *ngIf="item.basePrice" class="cx-value">
                {{ getTotalPrice(item.totalPrice) }}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="place-order-summary-place-holder"></div>
  <div class="cx-checkout-btns">
    <button class="btn-action backBtn" (click)="back()">
      {{ 'checkoutReview.back' | cxTranslate }}
    </button>
  </div>

</div>
