import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, Price, provideConfig } from '@spartacus/core';
import { MtCartApplyVoucherComponent } from './mt-cart-voucher.component';
import { MtCartSharedModule } from "../cart-shared";
import { FormErrorsModule, IconModule, KeyboardFocusModule, NgSelectA11yModule } from '@spartacus/storefront';
import { I18nModule } from '@spartacus/core';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    MtCartSharedModule,
    I18nModule,
    FormErrorsModule,
    FormsModule,
    IconModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CartApplyVoucherComponent: {
          component: MtCartApplyVoucherComponent,
        },
      },
    }),
  ],
  declarations: [MtCartApplyVoucherComponent],
  exports: [MtCartApplyVoucherComponent],
})
export class MtCartVoucherModule {}
