<br><br>
<div>
  <label class="form_field_wrapper">
    <h2 class="text--blue-color">
      {{ 'mtUserAccountAndLogin.myAccount.tiles.newsFeed' | cxTranslate }}
    </h2>
  </label>
</div>

<div>
  <exp-mt-personal-news-feed mode="preview" maxItems="3"></exp-mt-personal-news-feed>
</div>

<div align="center">
  <a routerLink="/account/newsFeed">
      {{ 'mtUserAccountAndLogin.myAccount.news.viewMore' | cxTranslate }}
  </a>
</div>

<br><br>
