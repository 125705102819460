import { CxEvent } from "@spartacus/core";


export class CartUiEventAddToCartMultipleProductsEvent extends CxEvent {

  static override readonly type = "CartUiEventAddToCartMultipleProductsEvent";

  products?: [{ productId: string, quantity: number }];

}
