import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UrlModule } from '@spartacus/core';
import { MediaModule, SpinnerModule } from '@spartacus/storefront';
import { MtCategoryItemComponent } from './mt-category-item.component';



@NgModule({
  declarations: [
    MtCategoryItemComponent
  ],
  imports: [
    CommonModule,
    UrlModule,
    RouterModule,
    SpinnerModule,
    MediaModule
  ],
  exports: [
    MtCategoryItemComponent
  ]
})
export class MtCategoryItemComponentModule { }
