import { mtFacets } from "./mt-facets.i18n";
import { mtProductCartResource } from './mt-product-addcart.i18n';
import { mtProductDetailsResource, mtProductInfo, mtProductRecommendations } from "./mt-product-details.i18n";
import { mtProductListResource } from "./mt-product-list.i18n";


export const de = {
  mtFacets,
  mtProductListResource,
  mtProductDetailsResource,
  mtProductCartResource,
  mtProductRecommendations,
  mtProductInfo
}
