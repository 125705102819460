/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const mtUserAccountAndLogin = {

  loginForm: {

    labels: {
      loginTitle: 'Login',
      signIn: 'Anmelden',
      register: 'Registrieren',
      lookingCustomerPortal: 'Interessiert am Kundenportal Login?',
      clickHereLink: 'Klicken Sie hier',
      forgotPassword: 'Passwort zurücksetzen',
      keepMeSignedIn: 'Eingeloggt bleiben',
    },

    fields: {
      emailAddress: {
        label: 'E-Mail Adresse',
        placeholder: 'Pflichtfeld',
      },
      password: {
        label: 'Passwort',
        placeholder: 'Pflichtfeld',
      },
    },

    messages: {
      youCanLoginAccountActivated: "Your account has been activated. Now you can log in.",
      youCanLoginAccountReactivated: "Your account has been re-activated. You can now login"
    }

  },

  login: {
    labels: {
      myMTComLoginLink: 'myMT.com login',
      myMTComLoginDescription: 'Verwalten Sie Ihr Online Profil und passen Sie Ihr mt.com Erlebnis an',
      customerPortalLoginLink: 'Kundenportal Login',
      customerPortalLoginDescription: 'Ihre Transaktionsplattform mit einem Kundenportfolio, Zugang zu Angeboten und Ihren installierten Geräten',

      myAccountLink: 'Mein Konto',
      logoutLink: 'Abmelden',
      customerPortalLink: 'Kundenportal'
    }
  },

  logout: {
    sessionDialog: {
      title: 'Abmeldung Sitzung',
      message: 'Ihre Sitzung ist abgelaufen.',
      logoutCountryChangedMessage: 'Sie sind abgemeldet, weil das Land geändert wurde.',
      button: 'OK'
    }
  },

  forgottenPassword: {
    resetPassword: 'Passwort zurücksetzen',
    emailAddress: {
      label: 'E-Mail Adresse',
      placeholder: 'E-Mail Adresse',
    },
    chooseNewPassword: {
      label: 'Wählen Sie ein Passwort',
      placeholder: '',
    },
    confirmNewPassword: {
      label: 'Passwort erneut eingeben',
      placeholder: '',
    },
    actions: {
      submit: 'Passwort zurücksetzen',
      cancel: 'Zurück zum Login',
      resendEmail: 'E-Mail erneut senden',
      saveNewPassword: 'Speichern'
    },
    passwordResetEmailSent:
      'Vielen Dank für Ihre Anfrage! Eine E-Mail zur Zurücksetzung des Passworts wurde an {{email}} gesendet. Der Link in der E-Mail ist für 30 Minuten gültig. Sie haben keine E-Mail erhalten? Bitte überprüfen Sie den Spam Ordner',

    passwordResetSuccess: 'Dein Passwort wurde zurück gesetzt. Bitte melden Sie sich an, um auf Ihr Konto zuzugreifen.',
  },

  updatePasswordForm: {
    title: 'Passwort ändern',
    oldPassword: {
      label: 'Aktuelles Passwort',
      placeholder: 'Pflichtfeld',
    },
    newPassword: {
      label: 'Neues Passwort',
      placeholder: 'Pflichtfeld',
    },
    confirmPassword: {
      label: 'Neues Passwort Bestätigen',
      placeholder: 'Pflichtfeld',
    },
    actions: {
      save: "Speichern"
    },
    passwordUpdateSuccess: 'Das Passwort wurde geändert',
  },

  updateProfileForm: {
    personalData: 'Profilinformationen',
    none: '',
    title: 'Anrede*',
    firstName: {
      label: 'Vorname*',
      placeholder: 'Pflichtfeld',
    },
    lastName: {
      label: 'Nachname*',
      placeholder: 'Pflichtfeld',
    },
    customerId: 'E-Mail Adresse*',
    actions: {
      save: "Speichern",
    },
  },

  deactivateAccount: {
    label: 'Benutzerkonto deaktivieren',
    buttonLabel: 'Benutzerkonto deaktivieren',
    popup: 'Möchten Sie Ihr Konto wirklich deaktivieren? Wenn Sie fortfahren, haben Sie kein aktives mt.com-Konto mehr. Ihr gemeinsames Kundenportal Konto wird ebenfalls deaktiviert.',
    cancel: 'Abbrechen',
  },

  reactivateAccount: {
    infoBox1: 'Ihr Konto ist inaktive. Bitte ',
    infoBox2: 'klicken Sie hier',
    infoBox3: ', um Ihr Konto wieder zu aktivieren.',
    title: 'Benutzerkonto reaktivieren',
    email: {
      label: 'E-Mail Adresse',
    },
    submit: 'Bestätigen',
    backToLogin: 'Zurück zum Login',
    successMessage: 'Wir haben Ihnen eine E-Mail mit einem Link zur Aktivierung Ihres Kontos gesendet. Sie können sich erst anmelden, wenn Sie Ihr Konto aktiviert haben.',
  },

  soldtoSelection: {
    selectAccount: 'Ihre Geschäftskonten',
    mtNumber: 'MT Konto Nummer:',
    accountSelected:'Ausgewähltes Geschäftskonto',
    otherAccounts:'Weitere verfügbare Geschäftskonten',
    pickAccount:'Wählen Sie ein anderes Geschäftskonto für Ihre Sitzung.',
    noAccounts:'Es sind keine weiteren Geschäftskonten verfügbar.',
  },

  myAccount: {
    tiles: {
      myAccount: 'Mein Konto',
      myProfile: 'Mein Profil',
      newsFeed: 'Persönlicher Feed',
      personalLibrary: 'Persönliche Bibliothek',
      billingAddresses: 'Rechnungsadressen',
      shippingAddresses: 'Lieferadressen',
      myOrders: 'Meine Bestellungen',
      newsletterSubscriptions: 'Newsletter Abonnement',
      productRegister: 'Produkt Registrierung',
    },

    news: {
      viewMore: 'Alle anzeigen'
    }
  },

  ordersRedirect: {
    confirmation: 'Bestätigung',
    dialogInfo: 'Sie werden zum METTLER TOLEDO Kundenportal weitergeleitet.',
    redirect: 'OK',
  },

};

export const mtUserAccount = {
  mtUserAccountAndLogin,
};
