import {OAuthService, TokenResponse} from "angular-oauth2-oidc";
import {Injectable} from "@angular/core";
import {HttpHeaders} from "@angular/common/http";


@Injectable({
  providedIn: 'root',
})
export class MtOAuthService extends OAuthService {

  public fetchTokenMtSpecificUsingPasswordFlow(
    userName: string,
    password: string,
    rememberMe: boolean,
    basesite: string,
    headers: HttpHeaders = new HttpHeaders()
  ): Promise<TokenResponse> {
    const parameters = {
      username: userName,
      password: password,
      remember_me: rememberMe || false,
      basesite: basesite
    };
    return this.fetchTokenUsingGrant('ds-ws', parameters, headers);
  }

  public override refreshToken(): Promise<TokenResponse> {
    const parameters = {
      refresh: true,
    };
    return this.fetchTokenUsingGrant('ds-ws', parameters);
  }

}
