import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CartNotEmptyGuard } from '@spartacus/checkout/base/components';
import { Address, CmsConfig, I18nModule, Price, provideConfig, UrlModule } from '@spartacus/core';
import { IconModule, MediaModule } from '@spartacus/storefront';
import { ProductPriceModule } from 'src/app/spartacus/custom-module/product/product-price';
import { MtCheckoutReviewSubmitComponent } from './mt-checkout-review-submit.component';



@NgModule({
  declarations: [
    MtCheckoutReviewSubmitComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    UrlModule,
    RouterModule,
    IconModule,
    MediaModule,
    ProductPriceModule
  ],
  exports: [
    MtCheckoutReviewSubmitComponent
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutReviewOrder: {
          component: MtCheckoutReviewSubmitComponent,
          guards: [CartNotEmptyGuard],
        },
      },
    }),
  ]
})
export class MtCheckoutReviewSubmitModule { }

declare module '@spartacus/cart/base/root' {
  interface Cart {
    billingAddress?: Address;
    surcharge?: Price;
  }
}
