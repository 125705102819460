/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const checkout = {
  checkout: {
    backToCart: 'Torna al carrello',
    continue: 'Prosegui'
  },
  checkoutProgress: {
    label: 'Avanzamento del checkout',
    deliveryAddress: 'Indirizzo di consegna',
    deliveryMode: 'Modalità di consegna',
    paymentDetails: 'Pagamento',
    reviewOrder: 'Rivedi l\'ordine',
  },
  cardActions: {
    setAsDefault: 'Imposta come predefinito',
    remove: 'Rimuovi',
    edit: 'Modifica'
  },
  checkoutAddress: {
    newBillingAddress: 'Nuovo indirizzo di fatturazione',
    newDeliveryAddress: 'Nuovo indirizzo di consegna',
    editDeliveryAddress: 'Modificare indirizzo di consegna',
    editBillingAddress: 'Modificare l\'indirizzo di fatturazione',
    deliveryAddress: 'Indirizzo di consegna',
    billingAddress: 'Indirizzo di fatturazione',
    selectYourDeliveryAddress: 'Seleziona il tuo indirizzo di consegna',
    defaultDeliveryAddress: 'Indirizzo predefinito',
    addNewAddress: 'Nuovo indirizzo',
    shipToThisAddress: 'Spedisci all\'indirizzo',
    deliveryAddressSelected: 'Indirizzo di consegna selezionato',
    sameAsDeliveryAddress: 'Uguale all\'indirizzo di consegna',
    guestDeliveryAddress: {
      title: {
        perosnalInfo: 'Informazioni personali',
        address: 'Indirizzo di consegna',
      },
      form: {
        titleCode: {
          label: 'Titolo*',
          placeholder: 'Campo obbligatorio',
        },
        firstName: {
          label: 'Nome*',
          placeholder: 'Campo obbligatorio',
        },
        lastName: {
          label: 'Cognome*',
          placeholder: 'Campo obbligatorio',
        },
        email: {
          label: 'Indirizzo email*',
          placeholder: 'Campo obbligatorio',
        },
        company1: {
          label: 'Nome dell\'azienda*',
          placeholder: 'Campo obbligatorio',
        },
        company2: {
          label: 'Nome dell\'azienda 2',
          placeholder: '',
        },
        company3: {
          label: 'Nome dell\'azienda 3',
          placeholder: '',
        },
        poNumber:{
          label: "Ufficio postale numero",
          placeholder: ""
        },
        country: {
          label: 'Paese*',
          placeholder: 'Campo obbligatorio',
          hint: 'Questo è il tuo paese. Se hai bisogno di registrare il tuo account con un paese diverso, ti preghiamo di passare al sito web corrispondente di METTLER TOLEDO utilizzando la selezione del paese in alto a questa pagina.',
        },
        addressLine1: {
          label: 'Indirizzo 1*',
          placeholder: 'Campo obbligatorio',
        },
        addressLine2: {
          label: 'Indirizzo 2',
          placeholder: '',
        },
        addressLine3: {
          label: 'Indirizzo 3',
          placeholder: '',
        },
        postalCode: {
          label: 'Codice Postale',
          placeholder: 'Campo obbligatorio',
        },
        state: {
          label: 'Provincia*',
          placeholder: 'Campo obbligatorio',
        },
        city: {
          label: 'Citta*',
          placeholder: 'Campo obbligatorio',
        },
      },
      actions: {
        backToCart: 'Torna al carrello',
        continue: 'Prosegui'
      }
    }
  },
  checkoutMode: {
    button: {
      back: 'Indietro',
      continue: 'Prosegui'
    },
    deliveryMethod: {
      title: 'Modalità di consegna',
      controls: {
        label: 'Selezionare'
      }
    },
    requestDeliveryDate: {
      title: 'Richiedi la data di consegna',
      controls: {
        optionDeliveryAsFastAsPosible: 'Consegna il prima possibile',
        optionDeliveryOnSpecificDate: 'Consegna in una data specifica',
        selectDate: 'Seleziona una data*'
      }
    },
    consolidatedDelivery: {
      title: 'Consegna consolidata',
      controls: {
        optionYes: 'Sì',
        optionNo: 'No',
        infoText: 'Faremo ogni tentativo per consolidare la tua consegna, se possibile.',
      }
    },
    deliveryPayment: {
      title: 'Pagamento della consegna',
      controls: {
        optionPrepaid: 'Pagamento prepagato',
        optionCollect: 'Collect',
        collectNumber: 'Collect Number*'
      },
      formErrors: {
        required: 'Campo obbligatorio',
        validate: 'Collect # is not valid'
      }
    }
  },
  checkoutReview: {
    review: {
      title: 'Riepilogo dell\'ordine',
      labels: {
        deliveryAddress: 'Indirizzo di consegna',
        deliveryOption: 'Opzione di consegna',
        requestedDeliveryDate: 'Data di consegna richiesta',
        deliverAsFastAsPossible: 'Consegna il prima possibile',
        consolidatedDelivery: {
          title: 'Consegna consolidata',
          yes: 'Sì',
          no: 'No',
        },
        deliveryPayment: {
          title: 'Pagamento della consegna',
          collect: 'Collect',
          prepaid: 'Prepaid'
        },
        billingAddress: 'Indirizzo di fatturazione',
        paymentType: 'Tipo di pagamento',
        purchaseOrderNumber: {
          title: 'Numero dell\'ordine'
        },
        empty: 'Non specificato'
      }
    },
    orderItems: {
      title: 'Prodotti',
      labels: {
        materialID: 'N. di materiale',
        quantity: 'Qtà.',
        itemStandardPrice: 'Prezzo standard dell\'articolo',
        itemNetPrice: 'Prezzo netto dell\'articolo',
        totalNetPrice: 'Prezzo netto totale'
      }
    },
    orderSummary: {
      title: 'Riepilogo dell\'ordine',
      quantity: 'Quantità',
      total: 'Totale',
      discounts: 'Sconti:',
      surchargeFreight: 'Spedizione',
      yourNetPrice: 'Il tuo prezzo netto',
      salesTax: 'IVA & Imposte',
      infoMessage: 'Le spese finali di spedizione e gestione saranno disponibili nella fattura dell\'ordine.',
    },
    confirmThatRead: 'Confermo di aver letto e accetto',
    termsAndConditions: 'le condizioni generali di vendita.*',
    confirmThatRead2: '',
    placeOrder: 'Effettua l\'ordine',
    payPlaceOrder: 'Paga ed effettua l\'ordine',
    editDeliveryAddressDetails:
      'Modifica i dettagli dell\'indirizzo di consegna',
    editPaymentDetails: 'Modifica i dettagli di pagamento',
    editPaymentType: 'Modifica metodo di pagamento',
    editDeliveryMode: 'Modifica modalità di consegna',
    orderInProcess: 'L\'ordine è in corso. Attendere, per favore',
    paymentError: 'C\'è un problema con l\'autorizzazione al pagamento. Si prega di riprovare o contattare il servizio clienti.',
    back: 'Indietro'
  },
  checkoutOrderConfirmation: {
    title: 'Grazie per il vostro ordine!',
    info1: 'Il tuo numero d\'ordine è <b>{{orderCode}}</b>',
    info2: 'Una conferma d\'ordine ti verrà inviata via email.',
    btnLabel: 'Torna al negozio online',
    confirmationOfOrder: 'Conferma dell\'ordine:',
    thankYou: 'Grazie per il vostro ordine!',
    invoiceHasBeenSentByEmail:
      'È stata inviata una fattura via email. Dovresti riceverla presto.',
    orderItems: 'Ordinare gli articoli',
    orderPlacedSuccessfully: 'Ordine effettuato con successo',
    createAccount: 'Creare un account?',
    createAccountForNext:
      'Crea un account per <{{email}}> per un checkout più veloce alla tua prossima visita.',
  },
  checkoutPaymentDetails: {
    paymentDetails: 'Dettagli di pagamento',
    phoneNumber: 'Numero di telefono',
    poNumber: 'Numero dell\'ordine',
    validateOrder: 'Convalidare l\'ordine',
    backButton: 'Indietro',
    cardPaymentInfo: 'Dopo aver effettuato l\'ordine, verrai reindirizzato al sito di pagamento per autorizzare la tua carta di credito.',
    poNumberInfo: 'Se non hai un numero d\'ordine d\'acquisto, puoi utilizzare questo campo come etichetta per il tuo riferimento personale dell\'ordine.',
    paymentType_CARD: 'Pagamento con carta di credito',
    paymentType_CREDIT:'Pagamento con carta di credito',
    paymentType_ACCOUNT: 'Pagamento della fattura',
    required: 'Campo obbligatorio',
    requiredPoNumber: 'Per favore, inserisca un numero di ordine d\'acquisto'
  }
};
