import { NgModule } from '@angular/core';
import { BaseStorefrontModule } from "@spartacus/storefront";
import { SpartacusConfigurationModule } from './spartacus-configuration.module';
import { SpartacusFeaturesModule } from './spartacus-features.module';


@NgModule({
  declarations: [],
  imports: [

    SpartacusFeaturesModule,
    SpartacusConfigurationModule,
    BaseStorefrontModule
  ],
  exports: [BaseStorefrontModule]
})
export class SpartacusModule { }

import { OrganizationUserRegistration } from '@spartacus/organization/user-registration/root';
import '@spartacus/storefront';
import { User } from '@spartacus/user/account/root';
import { Benefit } from "./custom-module/product/model/mt-product.model";
import { MtOrganisationRegistrationStatusEnum } from "./custom-module/user/account/core/mt-sold-to.service";
import {MtAddressType} from "./custom-module/user/account/services/mt-user-address.service";




declare module '@spartacus/organization/user-registration/root' {
  interface OrganizationUserRegistration {
    country?: string;
    password?: string;
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    company?: string;
    department?: string;
    jobTitle?: string;
    postalCode?: string;
    region?: string;
    city?: string;
    phoneNumber?: string;
  }
}


declare module '@spartacus/user/account/root' {
  interface User {
    companyRegistrationStatus? : MtOrganisationRegistrationStatusEnum;
  }
}


declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    SESSION_LOGOUT = 'SESSION_LOGOUT',
    REDIRECT_TO_ORDERS = "REDIRECT_TO_ORDERS",
    DEACTIVATE_ACCOUNT = "DEACTIVATE_ACCOUNT",
    REMOVE_ADDRESS = "REMOVE_ADDRESS"
  }
}

declare module '@spartacus/core' {
  interface AuthToken {
    remember_me : boolean;
  }
}

declare module '@spartacus/core' {
  interface ClientToken {
    remember_me : boolean;
  }
}

declare module 'angular-oauth2-oidc' {
  interface TokenResponse {
    remember_me : boolean;
  }
}

declare module '@spartacus/core' {
  interface AuthConfig {
    sessionLogoutInSec? : number;
  }
}

declare module '@spartacus/core' {
  interface Product {
    benefits? : Benefit[];
    esbu ?: string;
    buOwner ?: string;
    generalItemCategoryGroup ?: string;
  }
  interface BaseStore {
    atpEnabled?: boolean;
    vatDescription?: String;
    vatRate?: number;
  }
}

declare module '@spartacus/cart/base/root' {
  interface CartModification {
    index?: number;
  }
}

declare module '@spartacus/core' {
  interface Address {
    addressType?: MtAddressType;
    business?: boolean;
    companyName2?: string;
    companyName3?: string;
    line3?: string;
    poBillingAddress?: boolean;
    poNumber?: string;
    businessId?: string;
    billingAddress?: boolean;
 }
}

declare module '@spartacus/storefront/shared/components/card' {
  interface Card {
    addressId?: string;
    selectedAddressId?: string;
  }
}


