/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import {Component, ElementRef, Input} from '@angular/core';
import {mtDefaultProductQuantity} from "../../../../product/product-availability/services/mt-product-atp.service";
import {CartItemContextSource, CartItemListRowComponent} from "@spartacus/cart/base/components";
import {ActiveCartFacade, CartItemContext, OrderEntry} from "@spartacus/cart/base/root";
import {map} from "rxjs/operators";
import {MtProduct} from "../../../../product/model/mt-product.model";
import {Observable, of} from "rxjs";
import {LaunchDialogService} from "@spartacus/storefront";
import {AuthService, EventService, Price, ProductService} from "@spartacus/core";
import {Router} from "@angular/router";
import {MtPriceService} from "../../../../product/product-price";

interface MtOrderEntry extends OrderEntry{
  standardPrice?: Price;
}

@Component({
  selector: '[mt-cart-item-list-row], mt-cart-item-list-row',
  templateUrl: './mt-cart-item-list-row.component.html',
  styleUrls: ['./mt-cart-item-list-row.component.scss'],
  providers: [
    CartItemContextSource,
    { provide: CartItemContext, useExisting: CartItemContextSource },
  ],
})
export class MtCartItemListRowComponent extends CartItemListRowComponent {

  product$: Observable<MtProduct | undefined> = new Observable();
  isUserLoggedIn$: Observable<boolean> = of(false);
  maxQuantity: number = 999;

  @Input() override item : MtOrderEntry = super.item as MtOrderEntry

  ngOnInit(): void {
    if (this.item.product?.code != undefined){
      this.product$ =
        this.productService.get(
          this.item.product.code
        ).pipe(map(product => product as MtProduct));
    }
    this.isUserLoggedIn$ = this.authService.isUserLoggedIn();
  }

  constructor(
    override cartItemContextSource: CartItemContextSource,
    private productService: ProductService,
    private authService: AuthService,
    private mtPriceService : MtPriceService
  ) {
    super(cartItemContextSource);
  }

  enterPressed($event : Event)
  {
    const value = parseInt((<HTMLInputElement>$event.target).value)
    this.quantityControl.setValue(value)
    this.quantityControl.markAsDirty()
  }

  getFormattedPrice(price: number | undefined){
    return this.mtPriceService.getFormattedPrice(price ?? 0)
  }
}
