import { Injectable } from "@angular/core";
import { CmsNavigationEntry, CmsNavigationNode, CmsService } from "@spartacus/core";
import { from, Observable, of } from "rxjs";
import { map, mergeMap, reduce, take } from "rxjs/operators";
import { KEY_ROOT, MtNavigationNode } from "../model/mt-navigation-node.model";

class InnerNavigationNode {
  constructor(
    public mtNode: MtNavigationNode,
    public node: CmsNavigationNode,
    public entries: CmsNavigationEntry[] | undefined
  ) { }
};

@Injectable({ providedIn: 'root' })
export class ConvertNavigationNodesService {

  private buildCurrentNode(mtNode: MtNavigationNode, node: CmsNavigationNode, entries: CmsNavigationEntry[] | undefined): Observable<Map<string, MtNavigationNode>> {
    if (!entries || entries.length <= 0 || !entries[0].itemId) {
      return of(new Map<string, MtNavigationNode>([[(mtNode.isRoot) ? KEY_ROOT : mtNode.uid, mtNode]]));
    } else {
      // Take into consideration only first entry
      const entryUid: string = entries[0].itemId;

      return this.cmsService.getComponentData(entryUid).pipe(
        map(
          (entry: any) => {
            mtNode.title = entry?.headline;
            mtNode.url = entry?.urlLinkLocalized;
            mtNode.image = entry?.media;
            return new Map<string, MtNavigationNode>([[(mtNode.isRoot) ? KEY_ROOT : mtNode.uid, mtNode]]);
          }
        )
      );
    }
  }


  private loadNavigationNode(acc: InnerNavigationNode[], node: CmsNavigationNode, parentUid: string = '', isRoot: boolean = false) {

    const mtNode: MtNavigationNode = {
      isRoot: isRoot,
      parentUid: parentUid,
      uid: node.uid ?? '',
      children: node.children?.map(child => child.uid ?? '') ?? []
    };

    // We will consider only MtBannerComponents
    const entries: CmsNavigationEntry[] | undefined =
      node.entries?.filter(item => item.itemType === 'MTBannerComponent');

    if (!entries || entries.length <= 0 || !entries[0].itemId) {
      mtNode.title = node.title ?? (isRoot) ? 'ROOT' : 'Unknown';
    }

    acc.push({
      mtNode: mtNode,
      node: node,
      entries: entries
    });
    node.children?.forEach((child: CmsNavigationNode) => this.loadNavigationNode(acc, child, (mtNode.isRoot) ? KEY_ROOT : mtNode.uid));
  }


  constructor(protected cmsService: CmsService) { }

  convertNavigationNode(data: CmsNavigationNode): Observable<Map<string, MtNavigationNode>> {
    if (!data || !data.uid) {
      return of();
    }

    // Build all nodes
    const acc: InnerNavigationNode[] = [];
    this.loadNavigationNode(acc, data, undefined, true);

    return from(acc).pipe(
      mergeMap((innerNode) => this.buildCurrentNode(innerNode.mtNode, innerNode.node, innerNode.entries)),
      take(acc.length),
      reduce((acc, value) => new Map([...Array.from(acc.entries()), ...Array.from(value.entries())])),
    );

  }

}
