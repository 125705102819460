import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { backOff, isJaloError, normalizeHttpError, OccEndpointsService } from '@spartacus/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable()
export class MtOccCheckoutPlaceOrderAdapter {

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {}

  public getPaymetricPerform(
    userId: string,
    cartId: string
  ): Observable<any> {
    return this.http
      .get<string>(
        this.getPaymetricPerformEndpoint(userId, cartId),
        {}
      )
      .pipe(
        catchError((error) => throwError(normalizeHttpError(error))),
        backOff({
          shouldRetry: isJaloError,
        })
      );
  }

  protected getPaymetricPerformEndpoint(
    userId: string,
    cartId: string,
  ): string {
    return this.occEndpoints.buildUrl('placeOrderCardPayment', {
      urlParams: { userId, cartId }
    });
  }

}
