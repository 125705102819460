
export const mtProductCart = {
  titles: {
    loading: "Mise à jour du panier...",
    addcart: "Article(s) ajouté(s) à votre panier",
    updatecart: "Cet article était déjà dans votre panier. La quantité a été mise à jour.",
    multipleprod: "Articles ajouté à votre panier."
  },
  labels: {
    price: "Prix",
    total: "Total",
    actions: {
      continueShopping: "Continuer vos achats",
      goToCart: "Aller au panier"
    }
  }
}

export const mtProductCartResource = {
  mtProductCart
}
