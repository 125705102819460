
export const mtMiniCartMenu = {
  count: '{{count}}',
  labels: {
    goToShopHome: 'Online-Shop besuchen',
    gotToCart: 'Warenkorb anzeigen',
  }
}

export const mtMiniCart = {
  mtMiniCartMenu
}
