/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const cart = {
  cartDetails: {
    id: 'ID',
    proceedToCheckout: 'Cassa',
    cartName: 'Numero del carrello {{code}}',
    cartPageTitle: 'Il vostro carrello',
    loginForCheckout: 'Accedi per il checkout',
    registerForCheckout: 'Registrarsi',
    checkoutAsAGuest: 'Check-out degli ospiti',
    invoice: 'Fattura (Solo per gli utenti con account Business registrato)',
  },
  cartItems: {
    id: 'N. di materiale',
    description: 'Descrizione',
    item: 'Articolo',
    itemPrice: 'Prezzo dell\'articolo',
    quantity: 'Qtà',
    quantityTitle:
      'La quantità rappresenta il numero totale di questo articolo nel tuo carrello.',
    total: 'Totale',
    actions: 'Azioni',
    cartTotal: 'Totale carrello ({{count}} item)',
    cartTotal_other: 'Totale carrello ({{count}} items)',
    itemRemoved: 'L\'articolo selezionato è stato rimosso. Il totale del carrello è stato aggiornato',
    caption: 'Contenuto del carrello',
    itemStandardPrice: 'Prezzo standard dell\'articolo',
    itemNetPrice: 'Prezzo netto dell\'articolo',
  },
  orderCost: {
    orderSummary: 'Riepilogo dell\'ordine',
    subtotal: 'Totale parziale al netto degli sconti',
    shipping: 'Consegna:',
    estimatedShipping: 'Spedizione stimata:',
    discount: 'Hai risparmiato:',
    salesTax: 'IVA:',
    grossTax: 'L\'importo totale dell\'ordine non include l\'imposta di',
    grossIncludeTax: 'L\'importo totale dell\'ordine include l\'imposta di',
    total: 'Totale',
    toBeDetermined: 'Da determinare',
  },
  voucher: {
    coupon: 'Hai un coupon?',
    coupon_other: 'Codici coupon',
    couponLabel: 'Inserisci qui un codice promozionale',
    apply: 'Applicare',
    placeholder: 'Codice promozionale',
    applyVoucherSuccess: 'è stato applicato.',
    removeVoucherSuccess: 'è stato rimosso.',
    anchorLabel: 'Inserisci o rimuovi il tuo codice coupon',
    vouchersApplied: 'Coupon applicati',
    availableCoupons: 'Coupon disponibili',
    availableCouponsLabel: 'Puoi aggiungere questi coupon a questo ordine.',
    enterVoucherCode: 'Inserisci il tuo codice voucher',
    lessThanTreshold: 'Il valore minimo dell\'ordine applicabile è {{value}}.',
    valueDeducted: 'Lo sconto del buono è stato detratto. L\'importo finale sarà presentato nel riepilogo dell\'ordine',
    voucherCode: 'Il tuo codice voucher',
    voucherCodeNotecognized: 'Codice voucher non riconosciuto. Si prega di controllare e riprovare.',
    anotherCampaign: 'Non puoi utilizzare il codice promozionale quando partecipi a un\'altra campagna.',
    voucherAlreadyUsed: 'In precedenza veniva utilizzato il codice voucher.',
    voucherGreater: 'Il valore dell\'ordine è inferiore al vantaggio del voucher. Perderai la differenza.',
    productSpecificCampaign: 'Nessun prodotto proviene dalla campagna di voucher specifici per prodotto.',
    voucherBenefit: 'Sconto sui buoni sconto',
  },
  saveForLaterItems: {
    itemTotal: 'Salvato per dopo ({{count}} item)',
    itemTotal_other: 'Salvato per dopo ({{count}} items)',
    cartTitle: 'Carretto',
    saveForLater: 'Salva per dopo',
    moveToCart: 'Sposta al carrello',
    stock: 'Magazzino',
    forceInStock: 'In magazzino',
  },
  clearCart: {
    clearCart: 'Svuota carrello',
    clearingCart: 'Svuotare il carrello…',
    cartClearedSuccessfully: 'Il carrello attivo è stato cancellato correttamente.',
    areYouSureToClearCart: 'Sei sicuro di voler svuotare il carrello?',
    allItemsWillBeRemoved: 'Tutti gli articoli nel tuo carrello attivo verranno rimossi',
  },
  validation: {
    cartEntriesChangeDuringCheckout:
      'Durante il checkout abbiamo riscontrato problemi con le tue voci. Ti preghiamo di rivedere il tuo carrello',
    cartEntryRemoved:
      '{{name}} è stato rimosso dal carrello a causa della mancanza di disponibilità.',
    productOutOfStock:
      '{{name}} è stato rimosso dal carrello a causa di scorte insufficienti.',
    lowStock: 'La quantità si è ridotta a {{quantity}} a causa di scorte insufficienti',
    reviewConfiguration:
      'Risolvete prima i problemi nella configurazione per l\'inserimento nel carrello.',
    pricingError:
      'La tariffa del configuratore non è attualmente disponibile. Non è possibile effettuare il checkout o richiedere un preventivo. Riprova più tardi',
    unresolvableIssues:
      'La configurazione del prodotto richiede ulteriori voci nel backend. Di conseguenza, non è possibile procedere. Si prega di contattare il supporto',
    inProgress: 'Elaborazione',
  },
  orderSummary: {
    infoMessage: 'Le spese finali di spedizione e gestione saranno disponibili nella fattura dell\'ordine.',
    additionalInfo: 'In questo passaggio verrai reindirizzato al sito Paymetric per autorizzare la tua carta di credito.',
    standardPrice: 'Prezzo standard',
    discounts: 'Sconti',
    yourNetPrice: 'Il tuo prezzo netto',
    quantity: 'Quantità:',
    voucherBenefit: 'Sconto sui buoni sconto'
  },
  guestMessages: {
    pleaseLogin: 'Effettua l\'accesso al tuo account online per usufruire di servizi aggiuntivi.',
    createAccount: 'Crea un account online e registra la tua azienda per visualizzare i prezzi specifici per gli account aziendali e pagare tramite fattura.',
    guestCreditCardInfo: 'Come ospite, è possibile pagare tramite carta di credito. Si prega di notare che l\'esenzione fiscale non si applicherà quando si ordina come ospite.'
  }
};
