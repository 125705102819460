import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MtProductAtpModel } from "../model/mt-product-atp.model";
import { ProductAtpAdapter } from "./product-atp.adapter";


@Injectable({ providedIn: 'root' })
export class ProductAtpConnector {

  constructor(protected productAtpAdapter: ProductAtpAdapter) { }

  getProductAtp(productCode: string, quantity: number): Observable<MtProductAtpModel> {
    return this.productAtpAdapter.getProductAtp(productCode, quantity);
  }

}