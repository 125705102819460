
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  ElementRef
} from '@angular/core';
import {
  UntypedFormBuilder, UntypedFormGroup, Validators
} from '@angular/forms';
import {
  Address, Country,
  GlobalMessageService,
  RoutingService,
  TranslationService,
  UserAddressService,
  UserService
} from '@spartacus/core';
import { AddressFormComponent, LaunchDialogService } from "@spartacus/storefront";
import { UserProfileFacade } from "@spartacus/user/profile/root";
import { combineLatest, of } from "rxjs";
import { map, mergeMap, take } from 'rxjs/operators';
import { CountryContextService } from "../../../../core/country-site-context/country-context.service";
import { MtCustomFormValidators } from "../../../../util/validators/mt-custom-form-validators";
import {MtAddressType} from "../../../../user/account/services/mt-user-address.service";

@Component({
  selector: 'mt-checkout-guest-delivery-address-form',
  templateUrl: './checkout-guest-delivery-address-form.component.html',
  styleUrls: ['./checkout-guest-delivery-address-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CheckoutGuestDeliveryAddressFormComponent extends AddressFormComponent {

  @ViewChild('topAnchor') topAnchor!: ElementRef;
  countryDefaultIsocode: string = "US";

  @Input() currentAddress?: Address;
  @Input() isBillingAddress: boolean = false;

  poBillingAddressCheckbox: boolean = false;

  isStateMandatory = false
  isPostalCodeMandatory = true

  private firstNameFieldTranslated: string = "First Name"
  private lastNameFieldTranslated: string = "Last Name"
  private emailFieldTranslated: string = "Email Address"
  private company1FieldTranslated: string = "Company name"
  private company2FieldTranslated: string = "Company name 2"
  private company3FieldTranslated: string = "Company name 3"
  private addressLine1FieldTranslated: string = "Address 1"
  private addressLine2FieldTranslated: string = "Address 2"
  private addressLine3FieldTranslated: string = "Address 3"
  private postalCodeFieldTranslated: string = "Postal code"
  private cityFieldTranslated: string = "City"

  constructor(
    protected override fb: UntypedFormBuilder,
    protected override userService: UserService,
    protected override userAddressService: UserAddressService,
    protected override globalMessageService: GlobalMessageService,
    protected override translation: TranslationService,
    protected override launchDialogService: LaunchDialogService,
    private userProfile: UserProfileFacade,
    private countryContextService: CountryContextService,
    protected translationService: TranslationService,
    protected router: RoutingService) {
    super(fb, userService, userAddressService, globalMessageService, translation, launchDialogService);

    let firstNameFieldLabel$ = this.translationService.translate("checkoutAddress.guestDeliveryAddress.form.firstName.label")
    let lastNameFieldLabel$ = this.translationService.translate("checkoutAddress.guestDeliveryAddress.form.lastName.label")
    let emailFieldLabel$ = this.translationService.translate("checkoutAddress.guestDeliveryAddress.form.email.label")
    let company1FieldLabel$ = this.translationService.translate("checkoutAddress.guestDeliveryAddress.form.company1.label")
    let company2FieldLabel$ = this.translationService.translate("checkoutAddress.guestDeliveryAddress.form.company2.label")
    let company3FieldLabel$ = this.translationService.translate("checkoutAddress.guestDeliveryAddress.form.company3.label")
    let addressLine1FieldLabel$ = this.translationService.translate("mtOrgRegistrationForm.fields.addressLine1.label")
    let addressLine2FieldLabel$ = this.translationService.translate("mtOrgRegistrationForm.fields.addressLine2.label")
    let addressLine3FieldLabel$ = this.translationService.translate("mtOrgRegistrationForm.fields.addressLine3.label")
    let postalCodeFieldLabel$ = this.translationService.translate("mtOrgRegistrationForm.fields.postalCode.label")
    let cityFieldLabel$ = this.translationService.translate("mtOrgRegistrationForm.fields.city.label")

    combineLatest([firstNameFieldLabel$, lastNameFieldLabel$, emailFieldLabel$, company1FieldLabel$,
      company2FieldLabel$, company3FieldLabel$, addressLine1FieldLabel$, addressLine2FieldLabel$,
      addressLine3FieldLabel$, postalCodeFieldLabel$, cityFieldLabel$, countryContextService.getActive()])
      .pipe(take(1)).subscribe(it => {
        this.firstNameFieldTranslated = this.ignoreLastAsteriskIfExists(it[0])
        this.lastNameFieldTranslated = this.ignoreLastAsteriskIfExists(it[1])
        this.emailFieldTranslated = this.ignoreLastAsteriskIfExists(it[2])
        this.company1FieldTranslated = this.ignoreLastAsteriskIfExists(it[3])
        this.company2FieldTranslated = this.ignoreLastAsteriskIfExists(it[4])
        this.company3FieldTranslated = this.ignoreLastAsteriskIfExists(it[5])
        this.addressLine1FieldTranslated = this.ignoreLastAsteriskIfExists(it[6])
        this.addressLine2FieldTranslated = this.ignoreLastAsteriskIfExists(it[7])
        this.addressLine3FieldTranslated = this.ignoreLastAsteriskIfExists(it[8])
        this.postalCodeFieldTranslated = this.ignoreLastAsteriskIfExists(it[9])
        this.cityFieldTranslated = this.ignoreLastAsteriskIfExists(it[10])
        this.countryDefaultIsocode = it[11]
        this.addressForm = this.buildForm()
      }
      )
  }

  ngAfterViewInit() {
    if (this.topAnchor) {
      this.scrollToTop();
    }
  }

  private buildForm() {
    return this.fb.group({
      addressType: ['SHIP_TO'],
      addressId: [this.currentAddress?.id ?? ''],

      // personal information
      titleCode: [this.currentAddress?.titleCode ?? undefined, Validators.required],
      firstName: [this.currentAddress?.firstName ?? '', [Validators.required,
      MtCustomFormValidators.maxLength(35, this.firstNameFieldTranslated)]],
      lastName: [this.currentAddress?.lastName ?? '', [Validators.required,
      MtCustomFormValidators.maxLength(35, this.lastNameFieldTranslated)]],
      email: [this.currentAddress?.email ?? '', [Validators.required, MtCustomFormValidators.emailValidator,
      MtCustomFormValidators.maxLength(241, this.emailFieldTranslated)]],

      // company address
      companyName: [this.currentAddress?.companyName ?? '', [Validators.required,
      MtCustomFormValidators.maxLength(35, this.company1FieldTranslated)]],
      companyName2: [this.currentAddress?.companyName2 ?? '',
      MtCustomFormValidators.maxLength(35, this.company2FieldTranslated)],
      companyName3: [this.currentAddress?.companyName3 ?? '',
      MtCustomFormValidators.maxLength(35, this.company3FieldTranslated)],
      country: this.fb.group({
        isocode: [this.currentAddress?.country?.isocode ?? null, Validators.required],
      }),
      line1: [this.currentAddress?.line1 ?? '', [Validators.required,
      MtCustomFormValidators.maxLength(35, this.addressLine1FieldTranslated)]],
      line2: [this.currentAddress?.line2 ?? '',
      MtCustomFormValidators.maxLength(35, this.addressLine2FieldTranslated)],
      line3: [this.currentAddress?.line3 ?? '',
      MtCustomFormValidators.maxLength(35, this.addressLine3FieldTranslated)],
      town: [this.currentAddress?.town ?? '', [Validators.required,
      MtCustomFormValidators.maxLength(40, this.cityFieldTranslated)]],
      region: this.fb.group({
        isocode: [this.currentAddress?.region?.isocode ?? null],
        isocodeShort: [this.currentAddress?.region?.isocode ?? null],
      }),
     poBillingAddress: this.poBillingAddressCheckbox,
      poNumber: ['']
    });
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.addressForm = this.buildForm()
    this.setPostalCodeValidation(this.addressForm, this.currentAddress?.country?.isocode? this.currentAddress?.country?.isocode:'');
    this.countryContextService.getActive().pipe(take(1)).subscribe(
      country => {
        this.countryDefaultIsocode = country
        this.selectedCountry$.next(this.countryDefaultIsocode);
        this.addressForm.get('country')?.valueChanges.subscribe(
          it =>{
             if (it?.isocode === 'IE') {
               this.addressForm.get('postalCode')?.setValidators(null)
               this.addressForm.get('postalCode')?.updateValueAndValidity()
               this.setIsPostalCodeMandatory();
             } else {
               this.addressForm.get('postalCode')?.setValidators([Validators.required,
                 MtCustomFormValidators.postalCodeValidation(it?.isocode ?? country, country)])
               this.addressForm.get('postalCode')?.updateValueAndValidity()
               this.setIsPostalCodeMandatory();
             }
          }
        )
      }
    );
    this.setIsPostalCodeMandatory();
    this.userAddressService.loadDeliveryCountries();
    this.countries$ = this.userAddressService.getDeliveryCountries().pipe(mergeMap(
      (countries) => {
        this.setIsStateMandatory(countries)
        countries = this.setDefaultCountryFirst(countries)
        return of(countries)
      }
    ));
  }

  private setPostalCodeValidation(addressForm:UntypedFormGroup, countryIsocode:string){
    if (countryIsocode != 'IE'){
     addressForm.addControl('postalCode', this.fb.control(
         this.currentAddress?.postalCode ?? '',
         [
           Validators.required,
           MtCustomFormValidators.maxLength(10, this.postalCodeFieldTranslated),
           MtCustomFormValidators.postalCodeValidation(countryIsocode)
         ]
       ));
    }else{
       addressForm.addControl('postalCode', this.fb.control(
         this.currentAddress?.postalCode ?? '', []
       ));
    }
  }
  private setDefaultCountryFirst(countries: Country[]) {
    const defaultCountryIndex = countries.findIndex(country =>
      country.isocode?.toUpperCase() === this.countryDefaultIsocode.toUpperCase())
    if (defaultCountryIndex !== -1) {
      const defaultCountry = countries.splice(defaultCountryIndex, 1)[0]
      return [defaultCountry, ...countries]
    }
    return countries
  }

  private setIsStateMandatory(countries: Country[]) {
    let currentCountry: {
      isocode?: string;
      name?: string;
      stateRegionObligatory?: boolean;
    } | undefined
      = countries?.filter(it => it.isocode?.toUpperCase() === this.countryDefaultIsocode.toUpperCase())[0]
    if (currentCountry?.stateRegionObligatory) {
      this.isStateMandatory = true
      this.addressForm.get('region')?.get('isocode')?.addValidators(Validators.required)
    }
  }

  private setIsPostalCodeMandatory() {
    let selectedCountryIsoCode : string  = this.addressForm.get('country')?.value.isocode
    if (selectedCountryIsoCode === 'IE' ) {
      this.isPostalCodeMandatory = false
    }else{
      this.isPostalCodeMandatory = true
    }
  }

  override back(): void {
    this.router.go("cart");
  }

  override verifyAddress(): void {

    if (this.addressForm.valid) {

      if (this.addressForm.get('region')?.value.isocode) {
        this.regions$.pipe(take(1)).subscribe((regions) => {
          const obj = regions.find(
            (region) =>
              region.isocode ===
              this.addressForm.controls['region'].value.isocode
          );
          Object.assign(this.addressForm.value.region, {
            isocodeShort: obj?.isocodeShort,
          });
        });
      }

      this.submitAddress.emit(this.addressForm.value);

    } else {
      this.addressForm.markAllAsTouched();
    }
  }


  togglePOBillingAddress(): void {
    this.poBillingAddressCheckbox = !this.poBillingAddressCheckbox;

    this.addressForm['controls']['poBillingAddress'].setValue(
      this.poBillingAddressCheckbox
    );
    if (!this.addressForm.value.poBillingAddress) {
      // @ts-ignore
      this.addressForm.get('line1').setValidators("");
    }
  }

  isPOBillingAddress() {
    return this.poBillingAddressCheckbox;
  }

  isAddressFieldReadOnly(): boolean {
    if (this.addressData !== undefined && this.addressData !== null) {
      if (this.addressData.business !== undefined && this.addressData.business !== null) {
        return this.addressData.business;
      }
    }
    return false;
  }

  override getTitles() {
    return this.userProfile.getTitles().pipe(
      map(titles =>
        titles.filter(title =>
          (title.code === 'mr' || title.code === 'ms')
        )
      )
    )
  }

  private ignoreLastAsteriskIfExists(input: string): string {
    return input[input.length - 1] === '*' ? input.slice(0, input.length - 1) : input
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

}

