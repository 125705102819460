import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {AuthService, BaseSiteService} from '@spartacus/core';
import { LocalStorageService } from 'src/app/spartacus/custom-module/util/local-storage/local-storage.service';
import { MtProduct } from '../../../model/mt-product.model';
import { MtAbstratPriceComponent } from '../../common/mt-abstract-price.component';
import { MtPriceService } from '../../services/mt-price.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';

@Component({
  selector: 'mt-standard-price',
  templateUrl: './mt-standard-price.component.html',
  styleUrls: ['./mt-standard-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MtStandardPriceComponent extends MtAbstratPriceComponent implements OnInit, OnDestroy {

  @Input() product: MtProduct = {} as MtProduct;
  @Input() showLoginInPDP: boolean = false;

  constructor(
    protected override authService: AuthService,
    protected override priceService: MtPriceService,
    protected localStorageService: LocalStorageService,
    protected override baseSiteService: BaseSiteService
  ) {
    super(authService, priceService, baseSiteService);
  }

  ngOnInit(): void {
    this.onInit(this.product);
  }

  ngOnDestroy(): void {
    super.onDestroy()
  }

  get currentCountry(): string {
    return this.localStorageService.getItem('currentCountry') ?? 'us';
  }

  get currentLanguageIso(): string {
    return this.localStorageService.getItem('currentLanguageIso') ?? 'en';
  }

  openPopover(popover : NgbPopover): void {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
  }
}
