import { TranslationChunksConfig, TranslationResources } from "@spartacus/core";
import { de } from "./de";
import { en } from "./en";
import { fr } from "./fr";
import { nl } from "./nl";
import { it } from "./it";


export const mtCartTranslations: TranslationResources = {
  en, de, fr, nl, it
}


export const mtCartChunkConfig: TranslationChunksConfig = {
  mtMiniCart: ['mtMiniCartMenu'],
}

