import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FocusConfig } from '@spartacus/storefront';
import { ItemPerPageModel } from './mt-items-per-page.model';

@Component({
  selector: 'mt-items-per-page',
  templateUrl: './mt-items-per-page.component.html',
  styleUrls: ['./mt-items-per-page.component.scss']
})
export class MtItemsPerPageComponent implements OnInit {

  private _items: ItemPerPageModel[] = [];
  private _selectedItem: ItemPerPageModel | undefined;
  private _pageSize: number = 25;
  private _totalNumberOfItems: number = 0;

  toggleIsOpen: boolean = false;

  @Input()
  set totalNumberOfItems(totalNumberOfItems: number) {
    console.log(`set totalNumberOfItems = ${totalNumberOfItems}`);
    this._totalNumberOfItems = totalNumberOfItems ?? 0;
    this.setupItems();
  }

  @Input()
  set currentPageSize(pageSize: number) {
    console.log(`current page size: ${pageSize}`);
    this._pageSize = pageSize ?? 25;
    this.setupItems();
  }

  @Output()
  changeItemsPerPageNo: EventEmitter<number> = new EventEmitter();

  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    //autofocus: 'button',
    focusOnEscape: true,
    disableMouseFocus: true,
  };

  /*
  @HostListener('click', ['$event'])
  handleClick(event: UIEvent): void {
    console.log("click")
    if ((event.target as any).tagName === this.el.nativeElement.tagName) {
      this.closeOptions();
    }
  }
  */

  private setupItems() {
    const totalNumberOfItems = this._totalNumberOfItems ?? 0;

    console.log(`totalNumberOfItems = ${totalNumberOfItems}`);

    this._items = [];
    if (totalNumberOfItems > 5) {
      this.items.push(new ItemPerPageModel(5));
    }
    if (totalNumberOfItems > 25) {
      this.items.push(new ItemPerPageModel(25));
    }
    if (totalNumberOfItems > 50) {
      this.items.push(new ItemPerPageModel(50));
    }

    if (this._pageSize < 25) {
      this._selectedItem = this.items[0];
    } else if (this._pageSize < 50) {
      this._selectedItem = this.items[1];
    } else {
      this._selectedItem = this.items[2];
    }

    //this.onSelectItem(this.selectedItem);
  }

  constructor(
    protected el: ElementRef
  ) {
  }

  ngOnInit(): void {
  }

  public get items(): ItemPerPageModel[] {
    return this._items;
  }

  public get selectedItem(): ItemPerPageModel {
    return this._selectedItem || this.items[0];
  }

  onToggle() {
    this.toggleIsOpen = !this.toggleIsOpen;
    return this.toggleIsOpen;
  }

  onSelectItem(currentItem: ItemPerPageModel) {
    this._selectedItem = currentItem;
    this.closeOptions();
    this.changeItemsPerPageNo.emit(this.selectedItem.value);
  }

  closeOptions() {
    this.toggleIsOpen = false;
  }

}
