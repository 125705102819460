/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const mtUserRegistrationForm = {

  fields: {

    titleCode: {
      label: 'Anrede*',
      placeholder: 'Pflichtfeld',
    },
    firstName: {
      label: 'Vorname*',
      placeholder: 'Pflichtfeld',
    },
    lastName: {
      label: 'Nachname*',
      placeholder: 'Pflichtfeld',
    },
    email: {
      label: 'E-Mail Adresse*',
      placeholder: 'Pflichtfeld',
    },
    country: {
      label: 'Land*',
      placeholder: 'Pflichtfeld',
      hint: 'Dies ist Ihr Land. Falls Sie Ihren Account für ein anderes Land registrieren möchten, wechseln Sie bitte zur Website des entsprechenden Landes. Sie können das Land wechseln indem Sie auf die Flagge am rechten oberen Rand der Seite klicken.',
    },
    password: {
      label: 'Passwort*',
      placeholder: 'Pflichtfeld',
    },
    confirmPassword: {
      label: 'Passwort wiederholen*',
      placeholder: 'Pflichtfeld',
    }
  },

  labels:{
    createAccount: 'Neuen Benutzer erstellen',

    goToLoginLabel: 'Sie haben bereits ein Kundenkonto bei uns?',
    goToLoginLink: 'Anmelden',

    registerLabel: 'Registrieren Sie Ihre Firma und profitieren Sie von unseren erweiterten digitalen Angeboten.',
    registerUser: 'Ich möchte nur einen neuen Benutzer erstellen',
    registerCompany: 'Ich möchte einen neuen Benutzer erstellen und zusätzlich meine Firma registrieren',

    confirm1: 'Ich bestätige, dass ich die ',
    confirm2: ' von Mein mt.com und die ',
    confirm3: ' gelesen und verstanden habe und stimme diesen Bedingungen zu.*',
    termsOfUse: 'Nutzungsbedingungen',
    privacyStatement: 'Datenschutzbestimmungen',

    registerLabel1: 'Registrieren Sie Ihre Firma',
    registerLabel2: ' um von unserem erweiterten digitalen Angebot zu profitieren',
    registerInProgressLabel: 'Wir arbeiten daran, Ihren Benutzer mit Ihrem Unternehmenskonto zu verbinden. Sie werden benachrichtigt, sobald der Vorgang abgeschlossen ist.',

    selectedBusinessAccount: 'Ausgewähltes Geschäftskonto',

    multipleLinkedProfile: 'Ihr Benutzerprofil ist mit mehreren Unternehmenskonten verknüpft.',
    changeSelection: 'Sie können Ihre Auswahl jederzeit für Ihre nächste Transaktion in "Mein Profil" ändern.',
  },


  formSubmitButtonLabel: 'Benutzer erstellen',
  registerButtonLabel: 'Login/Register',
  httpHandlers: {
    conflict: '<div class="email-conflict-message"> Ihre Email Adresse ist bereits mit einem Kundenkonto verbunden. Sie können sich <a href="/shop/{{currentCountry}}/{{currentLanguageIso}}/login">anmelden</a> oder Ihr Passwort <a href="/shop/{{currentCountry}}/{{currentLanguageIso}}/login/forgot-password">zurücksetzen</a> </div>',
    wrongCountry: 'Anscheinend haben sie ein Konto in einem anderen Land. Bitte ändern Sie das Land um sich anzumelden oder erstellen Sie ein Benutzerkonto für dieses Land.',
    invalidUsernameOrPassword: 'Ihr Benutzername oder Passwort ist falsch oder Ihr Konto ist noch nicht aktiviert. Bitte überprüfen Sie Ihren Posteingang oder versuchen Sie es erneut.',
    unauthorized: 'Sie sind nicht berechtigt, diese Aktion auszuführen. Wenden Sie sich an Ihren Administrator, wenn Sie der Meinung sind, dass dies ein Fehler ist.', //@todo missed translation
    errorMessage: 'Fehler bei der Anmeldung. Bitte wenden Sie sich an den Kundendienst', // TODO: translations and e-mail addresses to be provided per country
  },
};

export const mtUserRegistration = {
  mtUserRegistrationForm,
};
