import { Component, OnInit } from '@angular/core';
import {EventService, GlobalMessageService, RoutingService, WindowRef} from '@spartacus/core';
import {Observable, throwError} from 'rxjs';
import {catchError, distinctUntilChanged, first, last, map} from 'rxjs/operators';
import {Order, OrderFacade, OrderHistoryFacade} from "@spartacus/order/root";
import {ActionFields, MtPurchaseEvent, ProductFoRGTM, Purchase} from "../../../../tms/events/mt-purchase-event";
import {Store} from "@ngrx/store";
import {ActivatedRoute} from "@angular/router";
import {Location} from "@angular/common";


@Component({
  selector: 'app-mt-order-confirmation',
  templateUrl: './mt-order-confirmation.component.html',
  styleUrls: ['./mt-order-confirmation.component.scss']
})
export class MtOrderConfirmationComponent implements OnInit {

  orderCodeFromUrlPath$: Observable<string> =
    this.routingService.getRouterState().pipe(
      map((routingData) => routingData.state.params['orderCode']),
      distinctUntilChanged()
    );

  orderCode: string | undefined;

  isPaymetricResponse: boolean = false;

  order$: Observable<Order> = this.orderHistoryFacade
    .getOrderDetails();

  constructor(
    //protected orderFacade: OrderFacade,
    protected orderHistoryFacade: OrderHistoryFacade,
    protected globalMessageService: GlobalMessageService,
    protected routingService: RoutingService,
    protected eventService: EventService,
    protected orderFacade: OrderFacade,
    protected store: Store,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    protected winRef: WindowRef
  ) {
  }

  ngOnInit(): void {
    let placedOrder$ = this.orderFacade.getOrderDetails();

    this.activatedRoute.queryParams.subscribe(params => {
      this.isPaymetricResponse = params['paymetricResponse'];
    });

    placedOrder$.pipe(first()).subscribe(order => {
      if(order?.code == undefined && !this.isPaymetricResponse){
        this.goShopHome();
      } else {
        this.orderCodeFromUrlPath$.subscribe((orderCode: string) => {
          if(orderCode != undefined) {
            this.orderCode = orderCode;
            this.orderHistoryFacade.loadOrderDetails(orderCode);
          }
        })

        this.order$ = this.orderHistoryFacade
          .getOrderDetails();

        this.order$.pipe(catchError((error) => {
            return throwError(error);
          })
        ).subscribe(order => {
          if(order?.code != undefined) {
            this.orderCode = order?.code;
            this.dispatchMtPurchaseEventEvent(order);
            this.removeQueryParameter();
          }
        });

        this.store.pipe().subscribe((value: any) => {
          if(value?.order?.orderDetail?.error){
            this.orderHistoryFacade.clearOrderDetails();
            this.goShopHome();
          }
        })

      }
    })

  }

  goShopHome() {
    this.routingService.go(['home']);
  }

  removeQueryParameter() {
    const pathWithoutQueryParams = this.location.path().split('?')[0];
    this.location.replaceState(pathWithoutQueryParams);
    this.isPaymetricResponse = false;
  }
  
  dispatchMtPurchaseEventEvent(order: Order){
    let productsArr: ProductFoRGTM[] = [];
    order.entries?.forEach((item) => {
      productsArr.push({
          price: item.basePrice?.value,
          name: item.product?.name,
          category: item.product?.categories?.[0].name,
          quantity: item.quantity
        } as ProductFoRGTM
      );
    })

    const eventData = {
      ecommerce: {
        purchase: {
          actionField: {
            currencyCode: order?.orgCustomer?.currency?.isocode,
            id: order?.code,
            affiliation: order.site,
            revenue: order.totalPrice?.value,
            tax: order.totalTax?.value,
            shipping: order.deliveryCost?.value,
          } as ActionFields,
          products: productsArr,

        } as Purchase,
      },
      event: 'purchase',
      eventAction: 'Purchase',
      eventCategory: 'Ecommerce',
      eventValue: 1
    }

    this.eventService.dispatch(eventData, MtPurchaseEvent);
    this.dispatchMtPageEvent(eventData);
  }

  dispatchMtPageEvent(eventData: Object) {
    if (this.winRef.nativeWindow) {
      if (JSON.stringify(eventData) !== JSON.stringify(this.winRef.nativeWindow.mt_page_data)) {
        this.winRef.nativeWindow.mt_page_data = eventData;
        console.log("MT-PAGE-DATA-EVENT", this.winRef.nativeWindow.mt_page_data);
        this.winRef.nativeWindow.dispatchEvent(new CustomEvent("mt-page-data-event",
          {
            bubbles: true,
            detail: {
              source: "orderConfirmationPage",
              data: this.winRef.nativeWindow.mt_page_data
            }
          }
        ));
      }
    }
  }

}
