import { Injectable } from "@angular/core";
import { ActiveCartFacade } from "@spartacus/cart/base/root";
import { Command, CommandService, CommandStrategy, EventService, Query, QueryService, UserIdService } from "@spartacus/core";
import { combineLatest, Observable } from "rxjs";
import { map, switchMap, take, tap } from "rxjs/operators";
import { MtDeliveryMethodModel } from "../../model/mt-delivery-method.model";
import { MtDeliveryMethodConnector } from "../../occ/mt-delivery-method.connector";
import { CheckoutDeliveryMethodQueryReloadEvent, CheckoutDeliveryMethodQueryResetEvent } from "../events/mt-checkout.events";


@Injectable({ providedIn: 'root' })
export class MtCheckoutDeliveryModeService {

  protected checkoutDeliveryMethodPreconditions(): Observable<[string, string]> {
    return combineLatest([
      this.userIdService.takeUserId(),
      this.activeCartFacade.takeActiveCartId()
    ]).pipe(
      take(1),
      map(([userId, cartId]) => {
        if (
          !userId ||
          !cartId
        ) {
          throw new Error('Checkout delivery method conditions not met');
        }
        return [userId, cartId];
      })
    );
  }

  protected checkoutDeliveryMethodQuery: Query<MtDeliveryMethodModel | undefined> =
    this.queryService.create<MtDeliveryMethodModel | undefined>(
      () => this.checkoutDeliveryMethodPreconditions().pipe(
        switchMap(([userId, cartId]) =>
          this.deliveryMethodConnector.getDeliveryMethod(userId, cartId)
        )
      ),
      {
        reloadOn: [CheckoutDeliveryMethodQueryReloadEvent],
        resetOn: [CheckoutDeliveryMethodQueryResetEvent]
      }
    );

  protected checkoutDeliveryMethodCommand: Command<MtDeliveryMethodModel, unknown> =
    this.commandService.create<MtDeliveryMethodModel>(
      (deliveryMethod) =>
        this.checkoutDeliveryMethodPreconditions().pipe(
          switchMap(([userId, cartId]) => {
            return this.deliveryMethodConnector.saveDeliveryMethod(userId, cartId, deliveryMethod).pipe(
              tap(
                () => this.eventService.dispatch({}, CheckoutDeliveryMethodQueryReloadEvent)
              )
            )
          })
        ),
      {
        strategy: CommandStrategy.CancelPrevious
      }
    );

  constructor(
    protected activeCartFacade: ActiveCartFacade,
    protected userIdService: UserIdService,
    protected queryService: QueryService,
    protected eventService: EventService,
    protected commandService: CommandService,
    protected deliveryMethodConnector: MtDeliveryMethodConnector
  ) { }

  getDeliveryMethod(): Observable<MtDeliveryMethodModel | undefined> {
    return this.checkoutDeliveryMethodQuery.get();
  }

  saveDeliveryMethod(
    deliveryMethod: MtDeliveryMethodModel
  ): Observable<unknown> {
    return this.checkoutDeliveryMethodCommand.execute(deliveryMethod);
  }

}
