<ng-container *ngIf="component$ | async as data; else noCmsComponentFound">
  <span [innerHTML]="data.content"></span>
</ng-container>

<ng-template #noCmsComponentFound>
  <div class="keywordsearch_main emptysearch">
    <div class="keywordsearch kws_main_body" style="width: 100%;">

      <div loading=" lazy" class="no-results-icon" style="display: block;">
        <svg width="45" height="45" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.6667 14.6667H15.6133L15.24 14.3067C16.5467 12.7867 17.3333 10.8133 17.3333 8.66667C17.3333 3.88 13.4533 0 8.66667 0C3.88 0 0 3.88 0 8.66667C0 13.4533 3.88 17.3333 8.66667 17.3333C10.8133 17.3333 12.7867 16.5467 14.3067 15.24L14.6667 15.6133V16.6667L21.3333 23.32L23.32 21.3333L16.6667 14.6667ZM8.66667 14.6667C5.34667 14.6667 2.66667 11.9867 2.66667 8.66667C2.66667 5.34667 5.34667 2.66667 8.66667 2.66667C11.9867 2.66667 14.6667 5.34667 14.6667 8.66667C14.6667 11.9867 11.9867 14.6667 8.66667 14.6667Z"
            fill="#666666" />
        </svg>
      </div>

      <div class="no-results-tools" style="display: block;">
        <div class="no-results-message">
          <div class="no-results-label"> {{ 'mtProductNoItemsFound.texts.noResultsLabel' | cxTranslate }} </div>
        </div>
        <div class="search-resources">
          <div class="search-tips">
            <div class="searchtips text parbase">
              <h2>{{ 'mtProductNoItemsFound.texts.searchTips' | cxTranslate }}</h2>
              <ul>
                <li>{{ 'mtProductNoItemsFound.texts.hints.hint1' | cxTranslate }}</li>
                <li>{{ 'mtProductNoItemsFound.texts.hints.hint2' | cxTranslate }}</li>
                <li>{{ 'mtProductNoItemsFound.texts.hints.hint3' | cxTranslate }}</li>
                <li>{{ 'mtProductNoItemsFound.texts.hints.hint4' | cxTranslate }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</ng-template>
