import { Injectable } from '@angular/core';
import { Cart, PaymentType } from '@spartacus/cart/base/root';
import { CheckoutPaymentTypeConnector } from '@spartacus/checkout/b2b/core';
import { Observable } from 'rxjs';
import { MtOrderSimulationResponseError } from '../../../model/mt-order-simulation-response-error.model';
import { MtCheckoutPaymentTypeAdapter } from './mt-checkout-payment-type.adapter';

@Injectable()
export class MtCheckoutPaymentTypeConnector extends CheckoutPaymentTypeConnector {
  constructor(protected override adapter: MtCheckoutPaymentTypeAdapter) {
    super(adapter);
  }

  override getPaymentTypes(): Observable<PaymentType[]> {
    return this.adapter.getPaymentTypes();
  }

  override setPaymentType(
    userId: string,
    cartId: string,
    typeCode: string,
    purchaseOrderNumber?: string,
    phoneNumber?: string
  ): Observable<unknown> {
    return this.adapter.setPaymentType(
      userId,
      cartId,
      typeCode,
      purchaseOrderNumber,
      phoneNumber
    );
  }

  getOrderSimulation(userId: string, cartId: string): Observable<MtOrderSimulationResponseError | Cart> {
    return this.adapter.getOrderSimulation(userId, cartId);
  }
}
