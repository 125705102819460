import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CartNotEmptyGuard } from '@spartacus/checkout/base/components';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { DatePickerModule, FormErrorsModule, NgSelectA11yModule, SpinnerModule } from '@spartacus/storefront';
import { MtOccCheckoutModule } from '../../../occ/mt-occ-checkout.module';
import { CheckoutDeliveryMethodComponentService } from './checkout-delivery-method-component.service';
import { CheckoutDeliveryMethodComponent } from './checkout-delivery-method.component';
import {CheckoutDatePickerModule} from "../checkout-date-picker/checkout-date-picker.module";



@NgModule({
  declarations: [
    CheckoutDeliveryMethodComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        I18nModule,
        SpinnerModule,
        FormsModule,
        NgSelectModule,
        NgSelectA11yModule,
        FormErrorsModule,
        DatePickerModule,
        MtOccCheckoutModule,
        CheckoutDatePickerModule
    ],
  exports: [
    CheckoutDeliveryMethodComponent
  ],
  providers: [
    CheckoutDeliveryMethodComponentService,

    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutDeliveryMode: {
          component: CheckoutDeliveryMethodComponent,
          guards: [CartNotEmptyGuard],
        },
      }
    }),
  ]
})
export class MtCheckoutDeliveryMethodModule { }
