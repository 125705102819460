import { mtProductATP } from "./mt-product-atp.i18n";

export const mtProductItemtList = {
  labels: {
    quotationAction: 'Richiedere Offerta',
    orderAction: 'Aggiungi al carrello',
    requestProductInfoAction: 'Richiedere informazioni',
    noResults: 'Prodotti trovati ({{noFoundResults}})',
    searchResults: 'Risultati della ricerca ({{noFoundResults}})',
    yourPrice: 'Il suo prezzo',
    standardPrice: 'Prezzo standard',
    infoLoginPrice: ' <span class="fas fa-info-circle"></span> <a style="color: #004494 !important " href="/shop/{{currentCountry}}/{{currentLanguageIso}}/login">Accedi</a><span>&nbsp per vedere il prezzo.</span>',
    filterButton:'Filtro',
    filterClear:'Filtri chiari',
    showResultsFound: 'Mostrare i risultati ({{noFoundResults}})',
    displayText:'Come utente registrato potrai beneficiare di:',
    spanText:'- Prezzi specifici per l\'account aziendale al momento della registrazione della tua azienda',
    spanText2:'- pagamento tramite fattura al momento della registrazione della vostra azienda',
    spanText3:'- accesso all\'account <a href="/shop/{{currentCountry}}/{{currentLanguageIso}}/login">mymt.com </a><span> con funzioni utili, come libreria personale, newsfeed o panoramica degli ordini< /intervallo>'
  }
}

export const mtProductNoItemsFound = {
  texts: {
    noResultsLabel: 'Nessun risultato di ricerca',
    searchTips: 'Suggerimenti per la ricerca',
    hints: {
      hint1: 'Assicurati che tutte le parole siano scritte correttamente',
      hint2: 'Prova parole chiave diverse',
      hint3: 'Prova parole chiave più generiche',
      hint4: 'Prova con meno parole chiave'
    }
  }
}

export const mtItemsPerPage = {
  items: {
    label: 'Vista {{pageSize}} per pagina'
  }
}

export const mtProductListResource = {
  mtProductItemtList,
  mtProductNoItemsFound,
  mtItemsPerPage,
  mtProductATP
}
