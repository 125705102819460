<ng-container *ngIf="showResetPasswordForm$ | async; then resetPasswordForm; else showMessage"></ng-container>

<ng-template #resetPasswordForm>
  <cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>
  <form (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="form-errors-wrapper">
      <cx-form-errors class="form-errors-content" [control]="form.get('userEmail')"></cx-form-errors>
    </div>
    <div class="form-content-wrapper">
      <h1 class="text--blue-color">
        {{ 'mtUserAccountAndLogin.forgottenPassword.resetPassword' | cxTranslate }}
      </h1>
      <label>
        <span class="label-content">{{
          'mtUserAccountAndLogin.forgottenPassword.emailAddress.label' | cxTranslate
          }}</span>
        <input required="true" type="email" class="form-control" placeholder="{{
          'mtUserAccountAndLogin.forgottenPassword.emailAddress.placeholder' | cxTranslate
        }}" formControlName="userEmail" />
        <!--
      <cx-form-errors [control]="form.get('userEmail')"></cx-form-errors>
      -->
      </label>
  
      <button class="btn-primary btn-large" [disabled]="form.disabled">
        {{ 'mtUserAccountAndLogin.forgottenPassword.actions.submit' | cxTranslate }}
      </button>
      <!-- <a class="btn-large btn-secondary" [routerLink]="{ cxRoute: 'login' } | cxUrl">{{
        'mtUserAccountAndLogin.forgottenPassword.actions.cancel' | cxTranslate
        }}</a> -->
      <button class="btn-secondary btn-large" type="button">
        <a [routerLink]="{ cxRoute: 'login' } | cxUrl">
          <span>{{ 'mtUserAccountAndLogin.forgottenPassword.actions.cancel' | cxTranslate }}</span>
        </a>
      </button>
    </div>
  </form>
</ng-template>

<ng-template #showMessage>
  <div class="mt-global-message">
  <div class="success-message-wrapper alert alert-success">
    <span class="alert-icon"></span>
      <span>{{ 'mtUserAccountAndLogin.forgottenPassword.passwordResetEmailSent' | cxTranslate : {email: userEMail$ | async }
        }}
      <div> <span>
        <a [routerLink]="{ cxRoute: 'forgotPassword' } | cxUrl" (click)="onResendEmail()">
          {{ 'mtUserAccountAndLogin.forgottenPassword.actions.resendEmail' | cxTranslate }}
        </a>
      </span></div>
      </span>
   <br>
   
  </div>
</div>
</ng-template>