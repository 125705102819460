<div
  *ngIf="content"
  class="cx-card"
  [tabindex]="border ? 0 : -1"
  [attr.role]="content.role ?? null"
  [attr.aria-label]="
    content.label ? (content.label | cxTranslate: { number: this.index }) : null
  "
  [class.cx-card-border]="border"
  [class.cx-card-fit-to-container]="fitToContainer"
  [class.selected]="isChecked()"
>



  <div class="form_field_wrapper"
       (click)="send();$event.preventDefault();"
  >
  <input type="radio" name="deliveryAddress" value="user" id="deliveryAddress-{{cardAddressId()}}"
             formControlName="deliveryAddress" [checked]="isChecked()"/>
<!--      <span class="radio-circle-wrapper">-->
              <span class="radio-circle"></span>
<!--      </span>-->
    <label class="radio-container account-info" for="deliveryAddress-{{cardAddressId()}}">
      <div *ngIf="content.textBold">
        <span class="radio-container_field-label  account-info-field account-info-field--bold">
          {{ content.textBold }}
        </span>
      </div>
      <span class="radio-container_field-label account-info-field">
        <div *ngFor="let line of content.text">
          <div *ngIf="!truncateText; else truncate">
            {{ line }}
          </div>
          <ng-template #truncate>
            <cx-truncate-text-popover
              [charactersLimit]="charactersLimit"
              [content]="line"
            ></cx-truncate-text-popover>
          </ng-template>
        </div>
        </span>
        <!-- Card Body -->
      <div class="card-body cx-card-body" [class.cx-card-delete]="editMode">
        <!-- Card Content -->
        <div class="cx-card-container">
          <!-- Default icon-->
          <span *ngIf="isDefault">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8" cy="8" r="8" fill="#6BB847"/>
              <path d="M4 7.7998L6.66667 10.7998L12 4.7998" stroke="white" stroke-width="2"/>
            </svg>
            {{ content.title }}
          </span>
          <!-- Actions -->
          <div *ngIf="content.actions && !editMode" class="cx-card-actions">
            <div *ngFor="let action of content.actions">
              <div>
                <ng-container *ngIf="isCardAction(action)" [ngSwitch]="action.event">
                  <a href=""
                    type="button"
                    *ngSwitchCase="'delete'"
                    (click)="delete();$event.preventDefault();"
                  >
                    {{ action.name }}
                  </a>
                  <a href=""
                    type="button"
                    *ngSwitchCase="'default'"
                    [hidden]="isDefault"
                    (click)="setDefault();$event.preventDefault();"
                  >
                    {{ action.name }}
                  </a>
                  <a href=""
                    type="button"
                    *ngSwitchCase="'edit'"
                    (click)="edit();$event.preventDefault();"
                  >
                    {{ action.name }}
                  </a>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </label>
  </div>


</div>

