import { Injectable } from "@angular/core";
import { Converter, Occ } from "@spartacus/core";
import { MtPriceModel } from "../model/mt-price.model";
import { MtProduct, MT_CPV_STATUS, PlantData, TechnicalAttribute } from "../model/mt-product.model";
import MtSeoUtils from "../../util/mt-seo-utils/mt-seo-utils.module";

@Injectable({ providedIn: 'root' })
export class MtProductConverter implements Converter<Occ.Product, MtProduct> {

  private setupTechincalAttributes(source: any, target: MtProduct) {
    if (source.technicalAttributes !== undefined) {
      target.technicalAttributes =
        source.technicalAttributes
          .map((sourceTechnicalAttribute: any) => {
            const technicalAttribute = {
              code: sourceTechnicalAttribute?.code,
              name: sourceTechnicalAttribute?.name,
              values: sourceTechnicalAttribute?.values?.map((srcValue: string) => srcValue)
            } as TechnicalAttribute;
            return technicalAttribute;
          });
    }
  }

  private setupPlantData(source: any, target: MtProduct) {
    if (source.plantData !== undefined) {
      const plantData = {
        countryOfOrigin: source.plantData?.countryOfOrigin,
        customsTariffNumber: source.plantData?.customsTariffNumber,
        hazardousMaterialNumber: source.plantData?.hazardousMaterialNumber
      } as PlantData;
      target.plantData = plantData;
    }
  }

  convert(source: any, target?: MtProduct | undefined): MtProduct {

    if (target === undefined) {
      target = {} as MtProduct;
    }

    //console.log(`source_Product = ${JSON.stringify(source)}`)

    target.price = {
      productCode: "",
      listPrice: 0,
      listPriceWithVatValue: 0,
      customerPrice: 0,
      customerPriceWithVatValue: 0,
      formattedListPrice: "",
      formattedListPriceWithVatValue: "",
      formattedCustomerPrice: "",
      formattedCustomerPriceWithVatValue: "",
      vatDescription: ""
    } as MtPriceModel;


    if (source.technicalAttributes !== undefined) {
      this.setupTechincalAttributes(source, target);
    } else {
      target.technicalAttributes = [];
    }

    if (source.plantData !== undefined) {
      this.setupPlantData(source, target);
    } else {
      target.plantData = {} as PlantData;
    }
    //console.log("converter: " + source.cpv);
    if (source.cpv !== undefined) {
      target.cpv = source.cpv;
    } else {
      target.cpv = {} as MT_CPV_STATUS;
    }

    if (source.buOwner !== undefined) {
      target.buOwner = source.buOwner;
    }

    if (source.esbu !== undefined) {
      target.esbu = source.esbu;
    }

    if (source.generalItemCategoryGroup !== undefined) {
      target.generalItemCategoryGroup = source.generalItemCategoryGroup;
    }

    target.seoNormalizedName = MtSeoUtils.normalizeString(target?.name);

    return target;
  }

}
