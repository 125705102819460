import { Injectable } from "@angular/core";
import { ProductSearchPage, RoutingService } from "@spartacus/core";
import { FacetList, ProductFacetService, ProductListComponentService } from "@spartacus/storefront";
import { Observable } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { MtProductListComponentService } from "../components/mt-product-list/mt-product-list-component.service";

@Injectable({
  providedIn: 'root',
})
export class MtProductFacetService extends ProductFacetService {

  protected override readonly searchResult$: Observable<ProductSearchPage> =
    this.mtProductListComponentService.model$.pipe(
      map(mtSearchModel => mtSearchModel.productSearchPage as ProductSearchPage),
      filter(searchModel => searchModel !== undefined)
    );

  override readonly facetList$: Observable<FacetList> = this.searchResult$.pipe(
    tap(_ => console.log(`this.categoryPage_facetList = ${this.categoryPage}`)),
    map(
      (result: ProductSearchPage) =>
      ({
        facets: result.facets,
        activeFacets: (result.breadcrumbs?.filter(facet => (facet.facetCode !== "allCategories" || facet.facetValueCode !== this.categoryPage))),
      } as FacetList)
    )
  );


  private _categoryPage: string | undefined;


  constructor(
    protected override routing: RoutingService,
    protected override productListComponentService: ProductListComponentService,

    protected mtProductListComponentService: MtProductListComponentService
  ) {
    super(routing, {} as ProductListComponentService);
  }

  public get categoryPage(): string | undefined {
    return this._categoryPage;
  }

  setupCategoryPageCode(categoryCode: string | undefined) {
    this._categoryPage = categoryCode;
    this.mtProductListComponentService.setupCategoryPage(categoryCode);
  }

}
