import {DIALOG_TYPE, LayoutConfig} from "@spartacus/storefront";
import {MtRemoveAddressComponent} from "./mt-remove-address.component";


export const mtRemoveAddressLayoutConfig: LayoutConfig = {
  launch: {
    REMOVE_ADDRESS: {
      inlineRoot: true,
      component: MtRemoveAddressComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};
