/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CmsConfig, ConfigModule,
  FeaturesConfigModule,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import { PromotionsModule } from '@spartacus/storefront';
import { MtCartSharedModule } from '../cart-shared/mt-cart-shared.module';
import { MtCartDetailsComponent } from './mt-cart-details.component';
import {CartCouponModule, CartValidationWarningsModule} from "@spartacus/cart/base/components";
import {CartValidationGuard} from "@spartacus/cart/base/core";
import {MtCartValidationWarningsModule} from "../validation/cart-warnings/mt-cart-validation-warnings.module";

@NgModule({
  imports: [
    MtCartSharedModule,
    CommonModule,
    CartCouponModule,
    RouterModule,
    I18nModule,
    UrlModule,
    PromotionsModule,
    FeaturesConfigModule,
    MtCartValidationWarningsModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CartComponent: {
          component: MtCartDetailsComponent,
        },
      },
    }),
  ],
  providers: [],
  declarations: [MtCartDetailsComponent],
  exports: [MtCartDetailsComponent],
})
export class MtCartDetailsModule {}
