export class LocalStorage implements Storage {
 /*TODO: in SSR we at least need to store key value pairs in memory,
    otherwise, custom service that try to access items always get null and may return to default values
    see: https://mettlertoledo.atlassian.net/browse/ECO-18647
  */
  keyValueMap: Map<string, string> = new Map();

  [name: string]: any;

  readonly length: number = 0;

  clear(): void {
  }

  getItem(key: string): string | null {
    return this.keyValueMap.get(key) ?? null
  }

  key(index: number): string | null {
    return null;
  }

  removeItem(key: string): void {
    this.keyValueMap.delete(key);
  }

  setItem(key: string, value: string): void {
    this.keyValueMap.set(key, value)
  }
}
