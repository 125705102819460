export const pageNotFoundTranslations = {
    labels: {
        backToShop: "Retour à la page d'accueil de la boutique en ligne",
        contactUs: 'Contactez-nous',
        pageNotFound: 'Page non trouvée',
    }
}

export const mtPageNotFoundTranslations = {
    pageNotFoundTranslations
}