/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const mtUserRegistrationForm = {

  fields: {

    titleCode: {
      label: 'Title*',
      placeholder: 'Required field',
    },
    firstName: {
      label: 'First Name*',
      placeholder: 'Required field',
    },
    lastName: {
      label: 'Last Name*',
      placeholder: 'Required field',
    },
    email: {
      label: 'Email Address*',
      placeholder: 'Required field',
    },
    country: {
      label: 'Country*',
      placeholder: 'Required field',
      hint: 'This is your country. If you need to register your account with a different country, please switch to the corresponding METTLER TOLEDO website using the country selection near the top of this page.',
    },
    password: {
      label: 'Password*',
      placeholder: 'Required field',
    },
    confirmPassword: {
      label: 'Retype Password*',
      placeholder: 'Required field',
    }
  },

  labels: {
    createAccount: 'Create account',

    goToLoginLabel: 'Already have an account with us?',
    goToLoginLink: 'Login',

    registerLabel: 'Register your company with us to take advantage of our extended digital offering.',
    registerUser: 'I only want to create a user account',
    registerCompany: 'I want to create a user account and register my company',

    confirm1: 'I confirm that I have read and understood the ',
    confirm2: ' of My mt.com and the ',
    confirm3: ' and agree to these terms.*',
    termsOfUse: 'Terms of Use',
    privacyStatement: 'Privacy Statement',

    registerLabel1: 'Register your company',
    registerLabel2: ' with us to take advantage of our extended digital offering.',
    registerInProgressLabel: 'We are working on connecting your user with your company account. You will be notified once the process is complete.',

    selectedBusinessAccount: 'Selected Business Account:',

    multipleLinkedProfile: 'Your user-profile is linked to multiple Business Accounts.',
    changeSelection: 'You can change your selection at any time for your next transaction in “My Profile”',
  },

  formSubmitButtonLabel: 'Create Account',
  registerButtonLabel: 'Login/Register',
  httpHandlers: {
    conflict: '<div class="email-conflict-message"> Your email address is already associated with an account. Please try to <a href="/shop/{{currentCountry}}/{{currentLanguageIso}}/login">login</a> or <a href="/shop/{{currentCountry}}/{{currentLanguageIso}}/login/forgot-password">reset</a> your password.</div>',
    wrongCountry: 'It appears that you have an account in a different country. Please change the country to login or try to create an account in this one.',
    invalidUsernameOrPassword: 'Your username or password is incorrect, or your account is not yet activated. Please check your inbox or try again.',
    unauthorized: 'You are not authorized to perform this action. Please contact your administrator if you think this is a mistake.',
    errorMessage: 'Login failed. Please contact Customer Service',
  },
};

export const mtUserRegistration = {
  mtUserRegistrationForm,
};
