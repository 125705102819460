<div class="cx-modal-container" [cxFocus]="focusConfig" (esc)="dismissModal('Escape pressed')">

  <div class="cx-modal-content">
    <div class="cx-dialog-header modal-header">
      <div class="cx-dialog-title modal-title">
        Delete address
        <!-- TODO translate
        {{ 'mtUserAccountAndLogin.logout.sessionDialog.title' | cxTranslate }}
        -->

      </div>
      <button type="button" class="close" aria-label="Close modal" (click)="dismissModal('Cross click')">
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </button>
    </div>

    <div class="cx-dialog-body modal-body">
      <div class="cx-dialog-row">
        Do you want to delete this address?
        <!-- TODO translate
          {{ 'mtUserAccountAndLogin.logout.sessionDialog.title' | cxTranslate }}
        -->
      </div>
      <div class="cx-dialog-row">
        <div class="cx-dialog-buttons">
          <button class="btn btn-primary btn-block btn-large" type="button" autofocus (click)="confirmAddressRemoval()">
            <span>
              Yes
              <!-- TODO translate
                {{ 'mtUserAccountAndLogin.logout.sessionDialog.title' | cxTranslate }}
              -->
            </span>
          </button>
        </div>
      </div>

      <div class="cx-dialog-row">
        <div class="cx-dialog-buttons">
          <button class="btn btn-primary btn-block btn-large btn-no" type="button" autofocus
            (click)="dismissModal('Proceed to login click')">
            <span>
              No
              <!-- TODO translate
                {{ 'mtUserAccountAndLogin.logout.sessionDialog.title' | cxTranslate }}
              -->
            </span>
          </button>
        </div>
      </div>


    </div>

  </div>

</div>
