import { EventEmitter, Inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { CurrencyService, LanguageService, ProductSearchPage, ProductSearchService, resolveApplicable, WindowRef } from '@spartacus/core';
import { ContextService, SearchCriteria } from '@spartacus/storefront';
import { BehaviorSubject, fromEvent, merge, Observable, Subscription, using } from 'rxjs';
import { FromEventTarget } from 'rxjs/internal/observable/fromEvent';
import { debounceTime, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { MtPriceModel } from '../../../model/mt-price.model';
import { MtProduct } from '../../../model/mt-product.model';
import { MtPriceService } from '../../../product-price';
import { SearchParamsParserStrategy } from '../../strategies/search-params-parser.strategy';
import { MtChangeNoFoundItemsEvent } from './mt-change-no-found-items.event';
import { MtProductSearchPageModel } from './mt-product-search-page.model';

@Injectable({ providedIn: 'root' })
export class MtProductListComponentService implements OnInit, OnDestroy {

  private categoriesFilter: string = "BUY_ONLINE#PAGE_CATEGORY";
  private hashValueOnInit: string = '';
  private _categoryCode: string | undefined;
  private _isCategoryPage: boolean = false;

  protected getStrategy(): SearchParamsParserStrategy | undefined {
    return resolveApplicable(this.searchParamsParserStrategies, []);
  }


  protected getPriceService(): MtPriceService | undefined {
    return this.priceService;
  }


  protected search(criteria: SearchCriteria): void {
    const currentPage = criteria.currentPage;
    const pageSize = criteria.pageSize;
    const sort = criteria.sortCode;
    this.productSearchService.search(
      criteria.query,

      Object.assign(
        {},
        currentPage && { currentPage },
        pageSize && { pageSize },
        sort && { sort }
      )
    );
  }

  protected searchFromHRef() {
    const href = this.winRef.nativeWindow?.location.href;

    const buildSearchCriteria =
      this.getStrategy();

    if (buildSearchCriteria) {
      const searchCriteria =
        buildSearchCriteria.buildSearchCriteria(this.categoriesFilter, href, this.categoryCode);
      if (searchCriteria) {

        // Emit event to hide "No Results component"
        this.changeNoFoundItems.emit({ noTotalItemsFound: -1, isActive: (searchCriteria.pageSize ?? 1) > 1 });

        this.search(searchCriteria);
      } else {
        this.productSearchService.clearResults();
      }
    } else {
      this.productSearchService.clearResults();
    }
  }


  /**
   * Build product prices for entire page.
   */
  protected buildProductPriced(productSearchPageModel: MtProductSearchPageModel): Observable<MtPriceModel[]> {
    return this.priceService.buildProductPriceRequest((productSearchPageModel.productSearchPage?.products as MtProduct[]) ?? [{} as MtProduct]);
  }


  protected searchByPath$: Observable<any> =
    merge(
      //fromEvent(this.winRef.nativeWindow ?? {} as FromEventTarget<Event>, 'DOMContentLoaded'),
      fromEvent(this.winRef.nativeWindow ?? {} as FromEventTarget<Event>, 'hashchange')
    ).pipe(
      debounceTime(0),
      tap((_) => {
        //Skip the already searched hash from on Init
        if(this.hashValueOnInit != this.winRef?.nativeWindow?.location?.hash ?? ''){
          this.searchFromHRef();
        }
        //Clean the value to not skip it a second time.
        this.hashValueOnInit = 'invalid';
      })
    );


  protected searchResults$: Observable<MtProductSearchPageModel> =
    this.productSearchService
      .getResults()
      .pipe(
        tap((searchResult) => {
          console.log(`facets no. = ${searchResult.facets?.length}`)
        }),
        filter((searchResult) => Object.keys(searchResult).length > 0),
        map(searchModel => {
          if ((searchModel.pagination?.pageSize ?? 1) === 1) {
            // Search is not active, the query is done only to find the number of results.
            return {
              pagination: searchModel.pagination
            } as ProductSearchPage
          }
          return searchModel;
        }),
        map((searchResult: ProductSearchPage) => new MtProductSearchPageModel((searchResult.pagination?.pageSize || 0) > 1, this.setupSortOptions(searchResult)))
      );


  private setupSortOptions(searchResult: ProductSearchPage): ProductSearchPage {
    return {
      ...searchResult,
      sorts: searchResult.sorts?.filter(it => (it.code !== 'relevance' && it.code !== 'price-asc' && it.code !== 'price-desc'))
    };
  }

  readonly changeNoFoundItems: EventEmitter<MtChangeNoFoundItemsEvent> = new EventEmitter();

  readonly model$: Observable<MtProductSearchPageModel> = using(
    () => this.searchByPath$.subscribe(),
    () => this.searchResults$
  ).pipe(shareReplay({ bufferSize: 1, refCount: true }));

  readonly prices$: BehaviorSubject<MtPriceModel[] | undefined> =
    new BehaviorSubject<MtPriceModel[] | undefined>(undefined);

  subscription = new Subscription();


  constructor(
    protected productSearchService: ProductSearchService,
    protected currencyService: CurrencyService,
    protected languageService: LanguageService,
    protected contextService: ContextService,
    protected winRef: WindowRef,
    protected priceService: MtPriceService,
    @Inject(SearchParamsParserStrategy)
    protected searchParamsParserStrategies: SearchParamsParserStrategy[]
  ) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.model$.pipe(
        //filter(model => model.isActive),
        tap((_) => {
          this.prices$.next(undefined);
        }),
        switchMap((mtProductSearchPageModel) => this.buildProductPriced(mtProductSearchPageModel))
      ).subscribe((prices: MtPriceModel[]) => this.prices$.next(prices))
    );
    if(this.winRef.nativeWindow !== undefined ){
      this.hashValueOnInit = this.winRef.nativeWindow.location.hash;
      this.searchFromHRef();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public get categoryCode(): string | undefined {
    return this._categoryCode;
  }

  setupCategoryPage(categoryCode: string | undefined) {
    this._isCategoryPage = categoryCode !== undefined;
    this._categoryCode = categoryCode;
  }

  public get isCategoryPage(): boolean {
    return this._isCategoryPage;
  }

  changePage(pageNumber: number) {
    const newHashtag =
      this.getStrategy()?.buildHashtagForPageNumber(pageNumber, this.categoriesFilter, this.winRef.nativeWindow?.location.href, this.categoryCode);
    if (newHashtag && this.winRef.nativeWindow !== undefined) {
      this.winRef.nativeWindow.location.hash = newHashtag;
    } else {
      console.error(`The page number ${pageNumber} could not be build for current context (${this.winRef.nativeWindow?.location.href}).`)
    }
  }

  getCurrentPage(): number {
    return this.getStrategy()?.getCurrentPage(this.categoriesFilter, this.winRef.nativeWindow?.location.href, this.categoryCode) ?? 0;
  }

  getCurrentPageSize(): number {
    return this.getStrategy()?.getCurrentPageSize(this.categoriesFilter, this.winRef.nativeWindow?.location.href, this.categoryCode) ?? 5;
  }

  changeItemsPerPage(itemsPerPageNo: number) {
    const newHashtag =
      this.getStrategy()?.buildHashtagForItemsPerPageNumber(itemsPerPageNo, this.categoriesFilter, this.winRef.nativeWindow?.location.href, this.categoryCode);
    if (newHashtag && this.winRef.nativeWindow !== undefined) {
      this.winRef.nativeWindow.location.hash = newHashtag;
    } else {
      console.error(`The page size ${itemsPerPageNo} could not be build for current context (${this.winRef.nativeWindow?.location.href} - categoryCode = ${this.categoryCode}).`)
    }
  }

  sortBy(sortCode: string) {
    const newHashtag =
      this.getStrategy()?.buildHashtagForSortBy(sortCode, this.categoriesFilter, this.winRef.nativeWindow?.location.href, this.categoryCode);
    if (newHashtag && this.winRef.nativeWindow !== undefined) {
      this.winRef.nativeWindow.location.hash = newHashtag;
    } else {
      console.error(`Could not sort by ${sortCode}.`);
    }
  }

}
