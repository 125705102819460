import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@spartacus/core';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { MtPriceModel } from '../../../model/mt-price.model';
import { MtProduct, MT_CPV_STATUS } from '../../../model/mt-product.model';
import { MtPriceService } from '../../services/mt-price.service';

@Component({
  selector: 'mt-selected-price',
  templateUrl: './mt-selected-price.component.html',
  styleUrls: ['./mt-selected-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MtSelectedPriceComponent implements OnInit, OnDestroy {

  @Input() product: MtProduct = {} as MtProduct;
  @Input() class: string = 'mt-selected-price';
  @Input() prices?: MtPriceModel[];

  currentQuantity$: BehaviorSubject<number> = new BehaviorSubject(1);
  price$: BehaviorSubject<string | undefined> = new BehaviorSubject(undefined as string | undefined);

  subscription: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private priceService: MtPriceService
  ) { }

  ngOnInit(): void {
    const priceModel$: Observable<MtPriceModel[]> =
      (this.prices !== undefined) ? of(this.prices) : this.priceService.buildProductPriceRequest([this.product]);

    this.subscription.add(
      combineLatest([
        this.authService.isUserLoggedIn(),
        priceModel$,
        this.currentQuantity$
      ]).pipe(
        mergeMap(([isUserLoggedIn, prices, quantity]) =>
          this.priceService.getFormattedPrice(this.getSelectedPrice(isUserLoggedIn, prices), quantity)
        )
      ).subscribe((priceText?: string) => this.price$.next(priceText))
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @Input()
  public set quantity(quantity: number) {
    this.currentQuantity$.next(quantity);
  }

  public get showPrice(): boolean {
    return this.product.cpv === MT_CPV_STATUS.SALES || this.product.cpv === MT_CPV_STATUS.SALESANDQUOTE;
  }

  private getSelectedPrice(isUserLoggedIn: boolean, prices: MtPriceModel[]): number {
    //console.log(`this.product.price = ${JSON.stringify(prices)} - ${isUserLoggedIn} - ${prices[0].listPrice} - ${prices[0].customerPrice}`)
    const productIndex =
      prices.findIndex((price: MtPriceModel) => price.productCode === this.product.code);

    if (productIndex >= 0) {
      if (isUserLoggedIn) return prices[productIndex].customerPrice;
      else return prices[productIndex].listPrice;
    } else {
      return -1;
    }
  }

}
