<div>
  <a *ngIf="hasChildren()"
    [routerLink]="{ cxRoute: 'categoryPage', params: { selectedNavigationNode: escapeURLParam() } } | cxUrl"
    [queryParams]="{code: navigationNode?.uid}">
    <cx-media [container]="navigationNode?.image" class="mt-banner-media"></cx-media>
    <span>{{navigationNode?.title}}</span>
  </a>

  <a *ngIf="!hasChildren()" href="{{navigationNode?.url}}">
    <cx-media [container]="navigationNode?.image" class="mt-banner-media"></cx-media>
    <span>{{navigationNode?.title}}</span>
  </a>

  <ng-template #loading>
    <div class="cx-spinner"><cx-spinner></cx-spinner></div>
  </ng-template>

</div>