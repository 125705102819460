/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const mtUserRegistrationForm = {

  fields: {

    titleCode: {
      label: 'Titre*',
      placeholder: 'Champ obligatoire',
    },
    firstName: {
      label: 'Prénom*',
      placeholder: 'Champ obligatoire',
    },
    lastName: {
      label: 'Nom*',
      placeholder: 'Champ obligatoire',
    },
    email: {
      label: 'E-Mail*',
      placeholder: 'Champ obligatoire',
    },
    country: {
      label: 'Pays*',
      placeholder: 'Champ obligatoire',
      hint: 'C\'est votre pays. Si vous souhaitez enregistrer votre compte pour un autre pays, veuillez vous rendre sur le site Web de ce pays. Vous pouvez changer de pays en cliquant sur le drapeau en haut à droite de la page.',
    },
    password: {
      label: 'Choisir un mot de passe*',
      placeholder: 'Champ obligatoire',
    },
    confirmPassword: {
      label: 'Confirmer votre mot de passe*',
      placeholder: 'Champ obligatoire',
    }
  },

  labels:{
    createAccount: 'Enregistrement Nouvel Utilisateur',

    goToLoginLabel: 'Vous avez déjà un compte chez nous ?',
    goToLoginLink: 'S\'enregistrer ici',

    registerLabel: 'Enregistrez votre entreprise avec nous pour profiter de notre offre numérique étendue.',
    registerUser: 'Je souhaite uniquement créer un compte utilisateur',
    registerCompany: 'Je veux créer un compte utilisateur et enregistrer mon entreprise',

    confirm1: 'Je confirme que j\'ai lu et compris les ',
    confirm2: ' sur My mt.com et les ',
    confirm3: '  et je confirme mon accord concernant ces termes.*',
    termsOfUse: 'Utilisation',
    privacyStatement: 'Termes légaux',

    registerLabel1: 'Enregistrez votre entreprise',
    registerLabel2: ' pour profiter de notre offre digitale élargie',
    registerInProgressLabel: 'Nous travaillons sur la connexion de votre utilisateur avec votre compte d\'entreprise. Vous serez averti une fois le processus terminé.',

    selectedBusinessAccount: 'Compte professionnel sélectionné',

    multipleLinkedProfile: 'Votre profil d\'utilisateur est lié à plusieurs comptes d\'entreprise."',
    changeSelection: 'Vous pouvez modifier votre sélection à tout moment pour votre prochaine transaction dans "Mon profil"',
  },

  formSubmitButtonLabel: 'Créer un utilisateur',
  registerButtonLabel: 'Login/Register',
  httpHandlers: {
    conflict: '<div class="email-conflict-message"> Votre adresse e-mail est déjà associée à un compte. Veuillez-vous <a href="/shop/{{currentCountry}}/{{currentLanguageIso}}/login">connecter</a> ou <a href="/shop/{{currentCountry}}/{{currentLanguageIso}}/login/forgot-password">réinitialiser</a> votre mot de passe</div>',
    wrongCountry: 'Il semble que vous ayez un compte dans un autre pays. Veuillez changer le pays pour vous connecter ou créer un compte pour ce pays.',
    invalidUsernameOrPassword: 'Votre nom d\'utilisateur ou votre mot de passe est incorrect ou votre compte n\'est pas encore activé. Veuillez vérifier votre boîte de réception ou réessayer.',
    unauthorized: 'Vous n\'êtes pas autorisé à effectuer cette action. Contactez votre administrateur si vous pensez qu\'il s\'agit d\'une erreur.', //@todo missed translation
    errorMessage: 'Échec de la connexion. Veuillez contacter le service client',
  },
};

export const mtUserRegistration = {
  mtUserRegistrationForm,
};
