<ng-container *ngIf="!(isLoading$ | async); else loading">
  <mt-quantity-picker [quantity]="quantity" (quantityChange)="onQuantityChanged($event)"></mt-quantity-picker>
</ng-container>

<ng-template #loading>
  <div class="cx-dialog-body modal-body">
    <div class="cx-dialog-row">
      <div class="cx-dialog-item col-sm-12 col-md-6 cx-spinner"><cx-spinner></cx-spinner></div>
    </div>
  </div>
</ng-template>