<!-- Item Start Outlet -->
<ng-template [cxOutlet]="CartOutlets.LIST_ITEM">


  <!-- Image-->
  <td class="mt-cart-page-thumbnail-container" role="cell">
    <div class="mt-cart-page-thumbnail">
      <!-- Item Image -->
      <a [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl">
        <cx-media [container]="item.product?.images?.['PRIMARY']" format="thumbnail"></cx-media>
      </a>
    </div>
  </td>

  <!-- Product description, Quantity & ATP, Price:-->
  <td class="mt-cart-page-content-container" role="cell">
    <div class="mt-cart-page-content-wrapper">
      <div>
        <tr>
          <ng-template [cxOutlet]="CartOutlets.ITEM_CONFIGURATOR_ISSUES"></ng-template>
          <ng-container *cxFeatureLevel="'4.2'">
            <cx-cart-item-validation-warning [code]="item.product?.code"></cx-cart-item-validation-warning>
          </ng-container>
          <div class="cx-table-item-container">
            <a [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl" tabindex="0">
            </a>
            <div class="cx-info">
              <div *ngIf="item.product?.name" class="cx-name">
                <a class="cx-link" [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl">{{
                  item.product?.name }}</a>
              </div>
              <div *ngIf="item.product?.code" class="cx-code">
                {{ 'cartItems.id' | cxTranslate }} {{ item.product?.code }}
              </div>
            </div>
          </div>
        </tr>

        <tr class="quantity-container">
          <table>
            <tr>
              <td role="cell" class="cx-quantity">
                <div class="cx-value">
                  <cx-item-counter [control]="quantityControl" [allowZero]="true" [max]="maxQuantity"
                                   (keyup.enter)="enterPressed($event)">
                  </cx-item-counter>
                </div>
              </td>
              <td role="cell">
                <div *ngIf="item.product?.code" class="mt-product-atp-wrapper">
                  <mt-product-atp [product]="item.product" [quantity]="item.quantity ?? 0"></mt-product-atp>
                </div>
              </td>
            </tr>
          </table>
        </tr>

        <tr>
          <table>
            <tr>
              <td role="cell">
                <!-- Item Price -->
                <div *ngIf="!(isUserLoggedIn$ | async) && item.standardPrice" class="cx-price">
                  {{ 'cartItems.itemStandardPrice' | cxTranslate }}
                  <div *ngIf="item.standardPrice" class="cx-value">
                    {{ getFormattedPrice(item.standardPrice.value) | async }}
                  </div>
                </div>
                <ng-container *ngIf="isUserLoggedIn$ | async">
                  <div *ngIf="item && item.standardPrice?.value !== item.basePrice?.value" class="cx-price">
                    {{ 'cartItems.itemStandardPrice' | cxTranslate }}
                    <div *ngIf="item.standardPrice" class="cx-value">
                      {{ getFormattedPrice(item.standardPrice.value) | async }}
                    </div>
                  </div>
                  <div *ngIf="item.basePrice" class="cx-price">
                    {{ 'cartItems.itemNetPrice' | cxTranslate }}
                    <div *ngIf="item.basePrice" class="cx-value">
                      {{ getFormattedPrice(item.basePrice.value) | async }}
                    </div>
                  </div>
                </ng-container>
              </td>
              <!--          TODO: MT price components to be implemented instead of the ones above -->
              <!--          <td role="cell">-->
              <!--              <ng-container *ngIf="product$ | async as product">-->
              <!--                  <mt-standard-price [product]="product"></mt-standard-price>-->
              <!--                  <mt-customer-price [product]="product"></mt-customer-price>-->
              <!--              </ng-container>-->
              <!--          </td>-->
            </tr>
          </table>
        </tr>
      </div>
      <!-- Totals:-->
      <div class="mt-cart-page-total-container">
        <!--<ng-template #total>-->
        <tr>
          <div>
            <td role="cell" *ngIf="item.totalPrice" class="cx-total">
              <div class="cx-mobile-header">
                {{ 'cartItems.total' | cxTranslate }}
              </div>
              <div class="cx-value">{{ item.totalPrice.formattedValue }}</div>
            </td>
          </div>
        </tr>
        <!--</ng-template>-->

        <tr>
          <button *ngIf="!readonly" (click)="removeItem()" [cxAtMessage]="'cartItems.itemRemoved' | cxTranslate"
            [disabled]="quantityControl.disabled" attr.aria-label="{{ 'addToCart.removeFromCart' | cxTranslate }}"
            class="link cx-action-link cx-remove-btn">
            <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14 1H10.5L9.5 0H4.5L3.5 1H0V3H14M1 16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H11C11.5304 18 12.0391 17.7893 12.4142 17.4142C12.7893 17.0391 13 16.5304 13 16V4H1V16Z"
                fill="#666666" />
            </svg>
          </button>
        </tr>
      </div>
    </div>
  </td>





</ng-template>
