/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const checkout = {
  checkout: {
    backToCart: 'Terug naar winkelwagen',
    continue: 'Doorgaan'
  },
  checkoutProgress: {
    label: 'Kassa',
    deliveryAddress: 'Afleveradres',
    deliveryMode: 'Leveringsoptie',
    paymentDetails: 'Betaling',
    reviewOrder: 'Bestelling bekijken',
  },
  cardActions: {
    setAsDefault: 'Instellen als standaard',
    remove: 'Verwijderen',
    edit: 'Bewerking'
  },
  checkoutAddress: {
    newBillingAddress: 'Nieuw factuuradres',
    newDeliveryAddress: 'Nieuw Afleveradres',
    editDeliveryAddress: 'Verander adres',
    editBillingAddress: 'Verander adres',
    deliveryAddress: 'Afleveradres',
    billingAddress: 'Factuuradres',
    selectYourDeliveryAddress: 'Select your Delivery Address',
    defaultDeliveryAddress: 'Standaard adres',
    addNewAddress: 'Nieuw adres',
    shipToThisAddress: 'Ship to this address',
    deliveryAddressSelected: 'Delivery address selected',
    sameAsDeliveryAddress: 'Hetzelfde als afleveradres',
    guestDeliveryAddress: {
      title: {
        perosnalInfo: 'Persoonlijke Informatie',
        address: 'Afleveradres',
      },
      form: {
        titleCode: {
          label: 'Titel*',
          placeholder: 'Verplicht veld',
        },
        firstName: {
          label: 'Voornaam*',
          placeholder: 'Verplicht veld',
        },
        lastName: {
          label: 'Achternaam*',
          placeholder: 'Verplicht veld',
        },
        email: {
          label: 'E-mailadres*',
          placeholder: 'Verplicht veld',
        },
        company1: {
          label: 'Bedrijfsnaam*',
          placeholder: 'Verplicht veld',
        },
        company2: {
          label: 'Bedrijfsnaam 2',
          placeholder: '',
        },
        company3: {
          label: 'Bedrijfsnaam 3',
          placeholder: '',
        },
        poNumber: {
          label: "Postbus",
          placeholder: ""
        },
        country: {
          label: 'Land*',
          placeholder: 'Verplicht veld',
          hint: 'This is your country. If you need to register your account with a different country, please switch to the corresponding METTLER TOLEDO website using the country selection near the top of this page.',
        },
        addressLine1: {
          label: 'Adres 1*',
          placeholder: 'Verplicht veld',
        },
        addressLine2: {
          label: 'Adres 2',
          placeholder: '',
        },
        addressLine3: {
          label: 'Adres 3',
          placeholder: '',
        },
        postalCode: {
          label: 'Postcode*',
          placeholder: 'Verplicht veld',
        },
        state: {
          label: 'Provincie*',
          placeholder: 'Verplicht veld',
        },
        city: {
          label: 'Plaats*',
          placeholder: 'Verplicht veld',
        },
      },
      actions: {
        backToCart: 'Terug naar winkelwagen',
        continue: 'Doorgaan'
      }
    }
  },
  checkoutMode: {
    button: {
      back: 'Terug',
      continue: 'Doorgaan'
    },
    deliveryMethod: {
      title: 'Leveringsoptie',
      controls: {
        label: 'Selecteren'
      }
    },
    requestDeliveryDate: {
      title: 'Aangevraagde Bezorgdatum',
      controls: {
        optionDeliveryAsFastAsPosible: 'Levering zo snel mogelijk',
        optionDeliveryOnSpecificDate: 'Levering op een specifieke datum',
        selectDate: 'Selecteer een datum*'
      }
    },
    consolidatedDelivery: {
      title: 'Geconsolideerde Levering',
      controls: {
        optionYes: 'Ja',
        optionNo: 'Nee',
        infoText: 'We zullen elke poging om uw bestelling te consolideren te maken, indien mogelijk.',
      }
    },
    deliveryPayment: {
      title: 'Betaling levering',
      controls: {
        optionPrepaid: 'Prepaid',
        optionCollect: 'Vooruitbetaling',
        collectNumber: 'Collect Number*'
      },
      formErrors: {
        required: 'Required field',
        validate: 'Collect # is not valid'
      }
    }
  },
  checkoutReview: {
    review: {
      title: 'Samenvatting',
      labels: {
        deliveryAddress: 'Afleveradres',
        deliveryOption: 'Leveringsoptie',
        requestedDeliveryDate: 'Aangevraagde Bezorgdatum',
        deliverAsFastAsPossible: 'Levering zo snel mogelijk',
        consolidatedDelivery: {
          title: 'Geconsolideerde Levering',
          yes: 'Ja',
          no: 'Nee',
        },
        deliveryPayment: {
          title: 'Betaling levering',
          collect: 'Vooruitbetaling',
          prepaid: 'Prepaid'
        },
        billingAddress: 'Factuuradres',
        paymentType: 'Type betaling',
        purchaseOrderNumber: {
          title: 'Referentienummer'
        },
        empty: 'Niet gespecificeerd'
      }
    },
    orderItems: {
      title: 'Producten',
      labels: {
        materialID: 'Materiaal nummer',
        quantity: 'Hoeveelheid',
        itemStandardPrice: 'Artikel standaard prijs',
        itemNetPrice: 'Artikel netto prijs',
        totalNetPrice: 'Totale nettoprijs'
      }
    },
    orderSummary: {
      title: 'Samenvatting',
      quantity: 'Aantal:',
      total: 'Totaalprijs',
      discounts: 'Korting:',
      surchargeFreight: 'Verzending:',
      yourNetPrice: 'Uw Nettoprijs:',
      salesTax: 'Btw',
      infoMessage: 'Final shipping & handling charges will be available in the order invoice.',
    },
    confirmThatRead: 'Ik ga akkoord met de',
    termsAndConditions: 'Terms and Conditions.*',
    confirmThatRead2: '',
    placeOrder: 'Bestelling plaatsen',
    payPlaceOrder: 'Betalen en bestellen',
    editDeliveryAddressDetails:
      'Edit delivery address details, opens Delivery Address page',
    editPaymentDetails: 'Edit payment details, opens Payment Details page',
    editPaymentType: 'Edit payment method, opens Method of Payment page',
    editDeliveryMode: 'Edit delivery mode, opens Delivery Mode page',
    orderInProcess: 'Order is in process. Please wait.',
    paymentError: 'Er is een probleem met uw betalingsmachtiging. Probeer het opnieuw of neem contact op met de klantenservice.',
    back: 'Terug'
  },
  checkoutOrderConfirmation: {
    title: 'Bedankt voor uw bestelling!',
    info1: 'Uw bestelnummer is <b>{{orderCode}}</b>.',
    info2: 'Er wordt een orderbevestiging naar u gemaild.',
    btnLabel: 'Terug naar de winkel',
    confirmationOfOrder: 'Confirmation of Order:',
    thankYou: 'Bedankt voor uw bestelling!',
    invoiceHasBeenSentByEmail:
      'An invoice has been sent by email. You should receive it soon.',
    orderItems: 'Order Items',
    orderPlacedSuccessfully: 'Order placed successfully',
    createAccount: 'Create an account?',
    createAccountForNext:
      'Create an account for <{{email}}> for a faster checkout on your next visit.',
  },
  checkoutPaymentDetails: {
    paymentDetails: 'Type betaling',
    phoneNumber: 'Direct telefoonnummer',
    poNumber: 'Referentienummer',
    validateOrder: 'Bestelling validieren',
    backButton: 'Terug',
    cardPaymentInfo: 'Na het plaatsen van de bestelling wordt u doorgestuurd naar de betaalsite om uw creditcard te autoriseren.',
    poNumberInfo: 'Indien u geen PO # hebt, kunt u dit veld vrij gebruiken door bijv. een eigen referentie in te geven.',
    paymentType_CARD: 'Credit card',
    paymentType_CREDIT:'Credit card betaling',
    paymentType_ACCOUNT: 'Betaling op rekening',
    required: 'Verplicht veld',
    requiredPoNumber: 'Please enter a purchase order number'
  }
};
