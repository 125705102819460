import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsComponent, Config, I18nModule, provideConfig, UrlModule } from '@spartacus/core';
import { CategoryNavigationModule, NavigationModule } from '@spartacus/storefront';
import { MtCategoriesNavigationComponent } from './mt-categories-navigation.component';


@NgModule({
  declarations: [
    MtCategoriesNavigationComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    NavigationModule,
    CategoryNavigationModule,
    I18nModule
  ],
  providers: [
    provideConfig(<CmsComponent>{
      cmsComponents: {
        CategoryNavigationComponent: {
          component: MtCategoriesNavigationComponent,
        },
      },
    } as Config)
  ],
  exports: [
    MtCategoriesNavigationComponent
  ]
})
export class MtCategoriesNavigationComponentModule { }
