import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OccEndpointsService } from '@spartacus/core';

@Injectable()
export class MtReactivateAccountOccService {

  constructor(private http: HttpClient,
              private occEndpoints: OccEndpointsService) { }

  reactivateAccount(email: string): Observable<any> {
    const endpoint = this.getEndpoint(email);
    return this.http.put(endpoint, null);
  }

  protected getEndpoint(email: string): string {
    return this.occEndpoints.buildUrl(`/orgUsers/anonymous/requestActivate?email=${email}`);
  }
}
