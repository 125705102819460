import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { AuthService, CmsService, ProtectedRoutesService, SemanticPathService, WindowRef } from "@spartacus/core";
import { LogoutGuard } from "@spartacus/storefront";
import { Subscription } from 'rxjs';
import { CountryContextService } from "../../core/country-site-context/country-context.service";
import { LanguageisoContextService } from "../../core/languageiso-site-context/languageiso-context.service";



@Injectable({
  providedIn: 'root',
})

export class MtLogoutGuard extends LogoutGuard {
  protected currentCountry: string = "us";
  protected currentLanguage: string = "en";
  protected subscriptions: Subscription = new Subscription();
  constructor(
    protected override auth: AuthService,
    protected override cms: CmsService,
    protected override semanticPathService: SemanticPathService,
    protected override protectedRoutes: ProtectedRoutesService,
    protected override router: Router,
    protected countryContextService: CountryContextService,
    protected languageIsoContextService: LanguageisoContextService,
    protected windowRef: WindowRef
  ) {
    super(auth, cms, semanticPathService, protectedRoutes, router);
    this.subscriptions.add(
      countryContextService.getActive().subscribe(currentCountry => {
        this.currentCountry = currentCountry
      }))
    this.subscriptions.add(
      languageIsoContextService.getActive().subscribe(currentLanguage => {
        this.currentLanguage = currentLanguage
      }))
      sessionStorage.setItem('poNumber',"");
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  protected override getRedirectUrl(): UrlTree {
    if (this.protectedRoutes.shouldProtect) {
      const cxRoute = 'login';
      return this.router.parseUrl(this.semanticPathService.get(cxRoute) ?? '');
    } else {
      this.windowRef.document.location.href = "/" + this.currentCountry + "/" + this.currentLanguage + '/home';
      return this.router.parseUrl(this.semanticPathService.get("") ?? '');
    }
  }
}
