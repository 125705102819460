import {mtProductATP} from "./mt-product-atp.i18n";

export const mtProductItemtList = {
  labels: {
    quotationAction: 'Vraag een offerte aan',
    orderAction: 'Voeg toe aan winkelwagen',
    requestProductInfoAction: "Vraag informatie aan",
    noResults: 'Producten gevonden ({{noFoundResults}})',
    searchResults: 'Zoekresultaten ({{noFoundResults}})',
    yourPrice: 'Jouw prijs',
    standardPrice: 'Standaard prijs',
    infoLoginPrice: '<a style="color: #004494 !important;" href="/shop/{{currentCountry}}/{{currentLanguageIso}}/login">Log in</a><span>&nbsp;voor meer gebruiksgemak of chat met één van onze experts.</span>',
    filterButton:'Filter',
    filterClear:'Clear Filters',
    showResultsFound: 'Show Results ({{noFoundResults}})',
    displayText:'Als geregistreerde gebruiker profiteert u van:',
    spanText:'- Organisatiespecifieke prijzen(indien u uw organisatie registreert)',
    spanText2:'- Betaling op factuur (indien u uw organisatie registreert)',
    spanText3:'- Toegang tot uw  <a href="/shop/{{currentCountry}}/{{currentLanguageIso}}/login">mymt.com </a> account met handige functies zoals uw persoonlijke bibliotheek, nieuws en uw orderhistorie'
  }
}

export const mtProductNoItemsFound = {
  texts: {
    noResultsLabel: 'Geen resultaten gevonden',
    searchTips: 'Zoektips',
    hints: {
      hint1: 'Zorg ervoor dat alle woorden correct zijn gespeld',
      hint2: 'Probeer verschillende zoekwoorden',
      hint3: 'Probeer algemenere zoekwoorden',
      hint4: 'Probeer minder zoekwoorden'
    }
  }
}

export const mtItemsPerPage = {
  items: {
    label: 'Toon {{pageSize}} per pagina'
  }
}

export const mtProductListResource = {
  mtProductItemtList,
  mtProductNoItemsFound,
  mtItemsPerPage,
  mtProductATP
}
