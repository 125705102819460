import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class LanguageisoSiteContextModule {
  static forRoot(): ModuleWithProviders<LanguageisoSiteContextModule> {
    return {
      ngModule: LanguageisoSiteContextModule,
      providers: [
      ],
    };
  }
}
