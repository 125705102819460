import { Media } from "@spartacus/storefront";


export const KEY_ROOT = 'ROOT_NAV_NODE';

export interface MtNavigationNode {
  isRoot: boolean;
  parentUid?: string;
  children: string[];
  uid: string;
  title?: string;
  image?: Media;
  url?: string;
}
