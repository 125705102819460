import { CxEvent } from "@spartacus/core";

/**
 * Event triggered when a quotation is requested by user.
 */
export class QuotationUIEvent extends CxEvent {
  static override readonly type = "QuotationUIEvent";
  productCode: string;
  quantity: number;

  constructor() {
    super();
    this.productCode = "";
    this.quantity = 0;
  }

}
