/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActiveCartFacade, Cart } from '@spartacus/cart/base/root';
import { Observable } from 'rxjs';
import {CartTotalsComponent} from "@spartacus/cart/base/components";

@Component({
  selector: 'mt-cart-totals',
  templateUrl: './mt-cart-totals.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MtCartTotalsComponent extends CartTotalsComponent {
}
