/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  AuthGuard,
  CmsConfig, ConfigModule, I18nConfig,
  I18nModule, provideConfig,
  provideDefaultConfig,
  UrlModule,
} from '@spartacus/core';
import {PageSlotModule, PaginationModule} from '@spartacus/storefront';
import { MtSoldToSelectionComponent } from './mt-soldto-selection.component';
import {translations} from "@spartacus/assets";
import {mtUserAccountTranslationChunksConfig, mtUserAccountTranslations} from "../../assets/translations/translations";
import {
  deTranslationOverwrites,
  enTranslationOverwrites,
  frTranslationOverwrites,
  nlTranslationOverwrites,
  itTranslationOverwrites
} from "../../../../../spartacus-configuration.module";
import {MtSoldtoSelectionComponentService} from "./mt-soldto-selection-component.service";
import {MtSoldToSelectionOccService} from "../../core/mt-soldto-selection-occ.service";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    PageSlotModule,
    I18nModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        OrganisationSoldToSelectionComponent: {
          component: MtSoldToSelectionComponent,
          guards: [AuthGuard],
        },
      },
    }),
    PaginationModule,
    ConfigModule.withConfig({
      pagination: {
        rangeCount: 5,
        addStart: true,
        addEnd: true,
        addNext: true,
        addPrevious: true
      }
    }),
  ],
  providers: [
    MtSoldtoSelectionComponentService,
    MtSoldToSelectionOccService,
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        OrganisationSoldToSelectionComponent: {
          component: MtSoldToSelectionComponent,
          guards: [AuthGuard],
        },
      },
    }),

    provideConfig({
      i18n: { resources: translations },
    }),
    provideConfig( < I18nConfig > {
      i18n: {
        resources: mtUserAccountTranslations,
        chunks: mtUserAccountTranslationChunksConfig
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: {
          en: enTranslationOverwrites,
          de: deTranslationOverwrites,
          fr: frTranslationOverwrites,
          nl: nlTranslationOverwrites,
          it: itTranslationOverwrites
        },
      },
    }),


  ],
  declarations: [MtSoldToSelectionComponent],
  exports: [MtSoldToSelectionComponent],
})
export class MtSoldtoSelectionModule {}
