/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import {
  MT_PASSWORD_PATTERN,
  POSTAL_CODE_REGEXES
} from "./mt-regex-pattern";
import {EMAIL_PATTERN} from "@spartacus/core";
import { MtForbiddenCharactersOccService } from '../core/mt-forbidden-characters-occ.service';

export class MtCustomFormValidators {

  /**
   * Checks control's value with predefined email regexp
   *
   * NOTE: Use it as a control validator
   *
   * @static
   * @param {AbstractControl} control
   * @returns {(ValidationErrors | null)} Uses 'cxInvalidEmail' validator error
   * @memberof MtCustomFormValidators
   */
  static emailValidator(control: AbstractControl) : ValidationErrors | null {
    const email = control.value as string | null;
    return email ? ((!email.length || email.match(EMAIL_PATTERN))
      ? null
      : { cxInvalidEmail: true }) : null;
  }

  /**
   * Checks control's value with predefined password regexp
   *
   * NOTE: Use it as a control validator
   *
   * @static
   * @param {AbstractControl} control
   * @returns {(ValidationErrors | null)} Uses 'cxInvalidPassword' validator error
   * @memberof MtCustomFormValidators
   */
  static passwordValidator(control: AbstractControl): ValidationErrors | null {
    const password = control.value as string | null;

    return password ? ((!password.length || password.match(MT_PASSWORD_PATTERN))
      ? null
      : { cxInvalidPassword: true }) : null;
  }

  static confirmPasswordMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control || !control.parent) {
      return null;}
      let confirmPassword = control.parent.get('passwordconf');
      let password = control.parent.get('password');
      if (!confirmPassword || !password) {
        return null; }
        if(!confirmPassword.value) {
              return null; }
      if (!confirmPassword.value && !password.value) {
        return null; }
      return confirmPassword.value === password.value
        ? null
        : { cxPasswordsMustMatch: true };
  };

  static passwordMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control || !control.parent) {
      return null; }
      let password = control.parent.get('password');
      let confirmPassword = control.parent.get('passwordconf');
      if (!password || !confirmPassword) {
        return null; }
        if(!password.value) {
              return null; }
      if (!password.value && !confirmPassword.value) {
        return null; }

      return password.value === confirmPassword.value
        ? null
        : { cxPasswordsMustMatch: true };
  };

  /**
   * Checks control's value with predefined no special characters regexp
   *
   * NOTE: Returns a control validator function which must then be used as a control validator
   *
   * @static
   * @returns {(ValidatorFn)} Uses 'mtNoSpecialChars' validator error
   * @param {string} fieldName Name of the field to be displayed in error message
   * @param {MtForbiddenCharactersOccService} forbiddenCharactersService using this occ service we will be fetching forbidden characters from hybris
   * @memberof MtCustomFormValidators
   */
  static noSpecialCharactersRegPage(fieldName: string, length: number, forbiddenCharactersService: MtForbiddenCharactersOccService): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const input = control.value as string | null;
      if (!input) {
        return null;
      }
    let error: ValidationErrors | null = null;
      forbiddenCharactersService.getForbiddenCharacters().subscribe(
        (forbiddenChars: string) => {
          const hasSpecialCharacters = forbiddenChars.split('').some(char => input.includes(char));
          if (input.length > length) {
            error = { ...(error ?? {}), maxlength: { fieldName: fieldName, maxLength: length } };
          }
          if (hasSpecialCharacters && error) {
            error = {
              ...error,
              mtNoSpecialChars: { fieldName: fieldName, forbiddenChars: forbiddenChars }
            };
          } else if (hasSpecialCharacters) {
            error = { mtNoSpecialChars: { fieldName: fieldName, forbiddenChars: forbiddenChars } };
          }
          if (error) {
          control.setErrors(error);
          } else {
            control.setErrors(null);
          }
        }
      );
      return null;
    };
  }

static noSpecialCharactersName(fieldName: string, length: number, forbiddenCharactersService: MtForbiddenCharactersOccService): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const input = control.value as string | null;
    if (!input) {
      return null;
    }
    let error: ValidationErrors | null = null;
    forbiddenCharactersService.getForbiddenCharacters().subscribe(
      (forbiddenChars: string) => {
        const hasSpecialCharacters = forbiddenChars.split('').some(char => input.includes(char));
        if (input.length > length) {
          error = { ...(error ?? {}), namesLength: { fieldName: fieldName, maxLength: length } };
        }
        if (hasSpecialCharacters && error) {
          error = {
            ...error,
            mtNoSpecialChars: { fieldName: fieldName, forbiddenChars: forbiddenChars }
          };
        } else if (hasSpecialCharacters) {
          error = { mtNoSpecialChars: { fieldName: fieldName, forbiddenChars: forbiddenChars } };
        }
        if (error) {
          control.setErrors(error);
        } else {
          control.setErrors(null);
        }
      }
    );
      return null;
    };
 }

  static onlyNumbers(fieldName : string): ValidatorFn
  {
    return function (control: AbstractControl): ValidationErrors | null {
      const input = control.value as string | null;
      return input ? (input.match(/^\d+$/) ? null :
        { onlyNumbers: {fieldName : fieldName}}) : null;
    }
  }

  /**
   * Checks control's value for maximum length
   *
   * NOTE: Returns a control validator function which must then be used as a control validator
   *
   * @static
   * @returns {(ValidatorFn)} Uses 'maxlength' validator error
   * @param length Maximum length of value
   * @param {string} fieldName Name of the field to be displayed in error message
   * @memberof MtCustomFormValidators
   */
  static maxLength(length : number, fieldName : string): ValidatorFn
  {
    return function (control: AbstractControl): ValidationErrors | null {
      const input = control.value as string | null;
      return input ? (input.length <= length ? null :
        { maxlength: {fieldName : fieldName, maxLength : length}}): null;
    }
  }

  static namesLength(length : number, fieldName : string): ValidatorFn
  {
    return function (control: AbstractControl): ValidationErrors | null {
      const input = control.value as string | null;
      return input ? (input.length <= length ? null :
        { namesLength: {fieldName : fieldName, maxLength : length}}): null;
    }
  }

  static maxlengthEmail(minLength: number, maxLength: number, fieldName: string): ValidatorFn {
    return function (control: AbstractControl): ValidationErrors | null {
      const input = control.value as string | null;
      return input ? ((input.length >= minLength && input.length <= maxLength) ? null :
        { maxlengthEmail: { fieldName: fieldName, minLength: minLength, maxLength: maxLength } }) : null;
    }
  }

  static lengthBetween(minLength: number, maxLength: number, fieldName: string): ValidatorFn {
    return function (control: AbstractControl): ValidationErrors | null {
      const input = control.value as string | null;
      return input ? ((input.length >= minLength && input.length <= maxLength) ? null :
        { lengthBetween: { fieldName: fieldName, minLength: minLength, maxLength: maxLength } }) : null;
    }
  }

  static dateNotWeekend(control: AbstractControl): ValidationErrors | null {
    const dateString = control.value as string | null;
    if(dateString===null)
      return null;
    const date = new Date(dateString);
    const weekday = date.toLocaleDateString('en-US',{weekday: "long", timeZone: 'UTC'});
    if(weekday === "Saturday" || weekday === "Sunday")
      return { mtOnWeekends: true };
    return null;
  }

  static dateMinimum(minDate : string): ValidatorFn
  {
    return function (control: AbstractControl): ValidationErrors | null {
      const dateString = control.value as string | null;
      if(dateString === null)
        return null
      if(dateString < minDate)
        return {min : {min : minDate}}
      return null;
    }
  }

  static postalCodeValidation(country : string, fallbackCountry : string = country): ValidatorFn
  {
    country = country.toUpperCase()
    fallbackCountry = fallbackCountry.toUpperCase()
    const postalCodePatterns : { [key: string]: string} =
      {
        US: '"#####" or "#####-####"',
        DE: '"#####"',
        BE: '"####"',
        NL: '"#### ZZ"',
        CH: '"####"',
        CA: '"A1A 1A1"',
        GB: '"Z# #ZZ" or "ZAA #ZZ" or "ZZ#A #ZZ"',
        LI: '"####"'
      }
    return function (control: AbstractControl): ValidationErrors | null {
      const value = control.value
      if(value === null || value.length === 0)
        return null
      const regex = country in POSTAL_CODE_REGEXES
        ? POSTAL_CODE_REGEXES[country]
        : POSTAL_CODE_REGEXES[fallbackCountry]
      if(regex.test(value))
        return null
      const pattern = country in postalCodePatterns
        ? postalCodePatterns[country]
        : postalCodePatterns[fallbackCountry]
      return {postalCodeInvalid : {pattern : pattern}}
    }
  }
}
