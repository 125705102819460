import {Injectable, NgModule} from '@angular/core';
import {Observable} from "rxjs";
import { MtReactivateAccountOccService } from './mt-reactivate-account-occ.service';

@Injectable({
  providedIn: 'root',
})
@NgModule({})
export class MtReactivateAccountService {

  constructor(private occ: MtReactivateAccountOccService) {}

  public userReactivateAccount(email: string): Observable<any> {
    return this.occ.reactivateAccount(email);
  }
}



