import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule, provideDefaultConfig, UrlModule } from '@spartacus/core';
import {
  IconModule,
  KeyboardFocusModule,
} from '@spartacus/storefront';



import {MtRemoveAddressComponent} from "./mt-remove-address.component";
import {mtRemoveAddressLayoutConfig} from "./mt-remove-address.layout.config";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    IconModule,
    I18nModule,
    KeyboardFocusModule,
  ],
  providers: [provideDefaultConfig(mtRemoveAddressLayoutConfig)],
  declarations: [MtRemoveAddressComponent],
  exports: [MtRemoveAddressComponent],
})
export class RemoveAddressDialogModule {
}
