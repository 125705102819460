import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AddedToCartDialogEventListener, AddedToCartDialogModule } from '@spartacus/cart/base/components';
import { CartEventModule } from '@spartacus/cart/base/core';
import { ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { DIALOG_TYPE, IconModule, KeyboardFocusModule, MediaModule, SpinnerModule } from '@spartacus/storefront';
import { ProductPriceModule } from '../product-price';
import { ProductQuantityPickerModule } from '../product-quantity-picker';
import { MtAddedToCartButtonComponent } from './components/mt-added-to-cart-button/mt-added-to-cart-button.component';
import { MtAddedToCartDialogComponent } from './components/mt-added-to-cart-dialog/mt-added-to-cart-dialog.component';
import { MtAddedToCartDialogEventListener } from './listeners/mt-added-to-cart-dialog-event.listener';
import { MtCartUiEventAddToCartMultipleProductsListener } from './listeners/mt-cart-ui-event-add-to-cart-multiple-products.listener';


declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    ADDED_TO_CART_MULTIPLE_PRODUCTS = "ADDED_TO_CART_MULTIPLE_PRODUCTS"
  }
}

@NgModule({
  declarations: [
    MtAddedToCartDialogComponent,
    MtAddedToCartButtonComponent
  ],
  imports: [
    CommonModule,
    SpinnerModule,
    IconModule,
    I18nModule,
    UrlModule,
    MediaModule,
    RouterModule,
    KeyboardFocusModule,
    AddedToCartDialogModule,
    CartEventModule,
    ProductQuantityPickerModule,
    ProductPriceModule,

    ConfigModule.withConfig({
      launch: {
        ADDED_TO_CART: {
          inlineRoot: true,
          component: MtAddedToCartDialogComponent,
          dialogType: DIALOG_TYPE.POPOVER_CENTER_BACKDROP,
        },
        ADDED_TO_CART_MULTIPLE_PRODUCTS: {
          inlineRoot: true,
          component: MtAddedToCartDialogComponent,
          dialogType: DIALOG_TYPE.POPOVER_CENTER_BACKDROP,
        }
      }
    }),

  ],

  exports: [
    MtAddedToCartButtonComponent
  ],

  providers: [
    {
      provide: AddedToCartDialogEventListener,
      useExisting: MtAddedToCartDialogEventListener
    }
  ]

})
export class ProductAddToCartModule {

  constructor(_: MtCartUiEventAddToCartMultipleProductsListener) { }

}
