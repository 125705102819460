import { APP_BASE_HREF, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { SemanticPathService, UrlCommands, WindowRef } from '@spartacus/core';
import { PREFIX_APP_BASE_HREF } from 'src/app/app.provider';
import { LocalStorageService } from '../../util/local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class MtUrlService {

  constructor(
    @Inject(PREFIX_APP_BASE_HREF) private baseHref: string,
    @Inject(APP_BASE_HREF) private appBaseHref: string,
    @Inject(PLATFORM_ID) protected platformId: any,
    protected winRef: WindowRef,
    protected localStorageService: LocalStorageService,
    protected semanticPathService: SemanticPathService
  ) { }

  buildURLWithAEMContext(commands: UrlCommands): string {
    const pathComponents: any[] =
      this.semanticPathService.transform(commands);

    const path =
      pathComponents.join("/").replace("//", "");

    return `${this.baseHref}/${this.localStorageService.getItem('currentCountry')}/${this.localStorageService.getItem('currentLanguageIso')}/${path}`;
  }

  isSpartacusContext(): boolean {
    if (isPlatformServer(this.platformId) || (this.winRef?.location.pathname?.startsWith(this.baseHref) && this.appBaseHref.startsWith(this.baseHref))) {
      return true;
    }
    return false;
  }

}
