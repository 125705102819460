/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const mtUserRegistrationForm = {

  fields: {

    titleCode: {
      label: 'Titolo*',
      placeholder: 'Campo obbligatorio',
    },
    firstName: {
      label: 'Nome*',
      placeholder: 'Campo obbligatorio',
    },
    lastName: {
      label: 'Cognome*',
      placeholder: 'Campo obbligatorio',
    },
    email: {
      label: 'Email aziendale*',
      placeholder: 'Campo obbligatorio',
    },
    country: {
      label: 'Paese*',
      placeholder: 'Campo obbligatorio',
      hint: 'Questo è il tuo paese. Se hai bisogno di registrare il tuo account con un paese diverso, ti preghiamo di passare al sito web corrispondente di METTLER TOLEDO utilizzando la selezione del paese in alto a questa pagina.',
    },
    password: {
      label: 'Password*',
      placeholder: 'Campo obbligatorio',
    },
    confirmPassword: {
      label: 'Riscriva la password*',
      placeholder: 'Campo obbligatorio',
    }
  },

  labels: {
    createAccount: 'Creare un account',

    goToLoginLabel: 'Hai già un account con noi?',
    goToLoginLink: 'Login',

    registerLabel: 'Registra la tua azienda con noi per usufruire della nostra offerta digitale estesa.',
    registerUser: 'Voglio soltanto creare un account utente',
    registerCompany: 'Voglio creare un account utente e registrare la mia azienda',

    confirm1: 'Confermo di aver letto e compreso i termini e le condizioni ',
    confirm2: ' di mymt.com ',
    confirm3: ' e accetto questi termini.*',
    termsOfUse: 'Termini d\'uso',
    privacyStatement: 'Dichiarazione sulla privacy',

    registerLabel1: 'Registra la tua azienda',
    registerLabel2: ' con noi per usufruire della nostra offerta digitale estesa.',
    registerInProgressLabel: 'Stiamo lavorando per collegare il suo utente al suo account aziendale. Sarà informato una volta completato il processo',

    selectedBusinessAccount: 'Conto aziendale selezionato:',

    multipleLinkedProfile: 'Il tuo profilo utente è collegato a più account aziendali.',
    changeSelection: 'Puoi cambiare la tua selezione in qualsiasi momento per la tua prossima transazione in "Il mio profilo"',
  },

  formSubmitButtonLabel: 'Creare un account',
  registerButtonLabel: 'Login/Registrazione',
  httpHandlers: {
    conflict: '<div class="email-conflict-message"> Il tuo indirizzo email è già associato a un account. Per favore, prova effettuare il <a href="/shop/{{currentCountry}}/{{currentLanguageIso}}/login">a login</a>o a <a href="/shop/{{currentCountry}}/{{currentLanguageIso}}/login/forgot-password">reimpostare</a> la tua password</div>',
    wrongCountry: 'Sembra che tu abbia un account in un paese diverso. Per favore, cambia il paese per accedere o prova a creare un account in questo.',
    invalidUsernameOrPassword: 'La tua username o password non è corretta, oppure il tuo account non è ancora attivato. Controlla la tua casella di posta o riprova.',
    unauthorized: 'Non sei autorizzato a eseguire questa azione. Si prega di contattare l\'amministratore se pensi che si tratti di un errore.',
    errorMessage: 'Accesso fallito. Si prega di contattare il Servizio Clienti',
  },
};

export const mtUserRegistration = {
  mtUserRegistrationForm,
};
