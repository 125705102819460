import { Config } from "@spartacus/core";

export abstract class CountryMapBaseSiteIdContextConfig extends Config {

  countries?: {
    [country: string]: string
  };
  defaultCountry: string = '';

  sites?: {
    [baseSiteId: string]: string
  };
  defaultSite: string = '';
}

