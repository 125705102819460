/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { AfterViewInit, Component } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { LaunchDialogService, LAUNCH_CALLER } from "@spartacus/storefront";
import { take } from "rxjs/operators";

@Component({
  selector: 'mt-account-tiles-component',
  templateUrl: './mt-account-tiles.component.html',
  styleUrls: ['./mt-account-tiles.component.scss']
})


export class MtAccountTilesComponent implements AfterViewInit {

  registerProductURL: string = '';

  constructor(
    protected launchDialogService: LaunchDialogService,
    protected windowRef: WindowRef
  ) { }

  getElementsByHref(str: string, tag: string): any {
    return Array.prototype.slice.call(this.windowRef.document.getElementsByTagName(tag))
      .filter(
        el => el.href.includes(str)
      );
  }

  ngAfterViewInit(): void {
    var links = this.getElementsByHref('prodreg', 'a');
    for (var i = 0, max = links.length; i < max; i++) {
      if (links[i].href.includes('prodreg') && links[i].href.includes('mt.com')) {
        this.registerProductURL = links[i].href;
        break;
      }
    }
  }

  ordersRedirectDialog() {
    const dialog = this.launchDialogService.openDialog(
      LAUNCH_CALLER.REDIRECT_TO_ORDERS,
      undefined,
      undefined
    )

    if (dialog) {
      dialog.pipe(take(1)).subscribe();
    }
  }

}
