/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const mtRegistrationConfForm = {

  labels:{
    confirmationTopLabel: 'Conferma',

    thankYou: 'Grazie per la tua registrazione!',
    emailSent: 'È stata inviata una e-mail alla tua casella di posta per confermare la tua registrazione.',
    pleaseClick: 'Fare clic sul link di conferma registrazione nella tua email entro 24 ore',
    onceConfirm: 'Una volta confermata la tua registrazione, il tuo account verrà attivato e potrai effettuare l\'accesso.',
    contact: 'Se non ricevete l\'email, vi preghiamo di ',
    contactLink: 'contattare il servizio clienti.'
  },

};

export const mtRegistrationConf = {
  mtRegistrationConfForm
};
