/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from "@angular/router";
import { GlobalMessageService, GlobalMessageType } from "@spartacus/core";
import {BehaviorSubject, Observable} from 'rxjs';
import { MtLoginFormComponentService } from './mt-login-form-component.service';


@Component({
  selector: 'mt-login-form',
  templateUrl: './mt-login-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./mt-login-form.component.scss']
})
export class MtLoginFormComponent implements AfterViewInit, OnInit, AfterViewChecked {

  constructor(protected service: MtLoginFormComponentService,
    protected activatedRoute: ActivatedRoute,
    protected globalMessageService: GlobalMessageService,
              private changeDetectorRef: ChangeDetectorRef,
              private el: ElementRef) { }

  form: UntypedFormGroup = this.service.form;
  isUpdating$: Observable<boolean> = this.service.isUpdating$;
  registrationConfirmed: string | null = '';
  accountReactivated: string | null = '';
  protected autoFilledByBrowser$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // @HostBinding('class.user-form') style = true;

  onSubmit(): void {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const redirectToRoute = params['redirectToRoute'];
      this.service.isAccountInactive = false;
      this.service.login(redirectToRoute);
    })
  }

  ngOnInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewChecked() {
    setTimeout(() => {
      const autofillPasswordInputs = this.el.nativeElement.querySelectorAll("input[type=password]:-webkit-autofill");
      this.autoFilledByBrowser$.next(autofillPasswordInputs.length > 0);
    }, 200);
  }


  ngAfterViewInit(): void {

    Object.keys(this.form.controls).forEach((key) => {
      const control = this.form.get(key);
      if (control?.value) {
        control.markAsTouched();
      }
    });

    this.registrationConfirmed = this.activatedRoute.snapshot.queryParamMap.get('registrationConfirmed');
    if (this.registrationConfirmed === 'true') {
      this.globalMessageService.add(
        {
          key: 'mtUserAccountAndLogin.loginForm.messages.youCanLoginAccountActivated',
        },
        GlobalMessageType.MSG_TYPE_CONFIRMATION
      );
    }

    this.accountReactivated = this.activatedRoute.snapshot.queryParamMap.get('accountReactivated');
    if (this.accountReactivated === 'true') {
      this.globalMessageService.add(
        {
          key: 'mtUserAccountAndLogin.loginForm.messages.youCanLoginAccountReactivated',
        },
        GlobalMessageType.MSG_TYPE_CONFIRMATION
      );
    }

  }

  isAccountInactive() {
    return this.service.isAccountInactive;
  }



}
