import { Injectable } from "@angular/core";
import { BaseSite, BaseSiteService, SiteContext } from "@spartacus/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { LocalStorageService } from "../../util/local-storage/local-storage.service";
import { CountryBasesiteConvertor } from "./country-basesite.convertor";


@Injectable({ providedIn: "root" })
export class CountryContextService implements SiteContext<string> {

  constructor(
    protected baseSiteService: BaseSiteService,
    protected converter: CountryBasesiteConvertor,
    protected localStorageService: LocalStorageService
  ) { }

  getAll(): Observable<string[]> {
    return this.baseSiteService
      .getAll()
      .pipe(
        map(sites => sites.map((site: BaseSite) => this.converter.baseSiteIdToCountry(site.uid ?? '')))
      );
  }

  getActive(): Observable<string> {
    if (this.localStorageService.getItem("currentCountry") !== undefined) {
      this.setActive(this.localStorageService.getItem("currentCountry") || 'us');
      return of(this.localStorageService.getItem("currentCountry") || 'us');
    } else {
      return this.baseSiteService.getActive().pipe(map(siteUid => this.converter.baseSiteIdToCountry(siteUid)));
    }
  }

  setActive(isocode: string): void {
    //TODO: we should not rely on localStorage or abstraction, instead implement sth similar to MtSoldToService where we can also observe changes
    this.localStorageService.setItem('currentCountry', isocode);
    this.baseSiteService.setActive(this.converter.countryToBaseSiteId(isocode));
  }

}
