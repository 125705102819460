<div *ngIf="soldToList$ | async as soldToList" formGroupName="soldTo" class="select-account-form">
  <div *ngIf="soldToList.length > 0">
    <label class="form_field_wrapper">
      <h2 class="text--blue-color">
        {{ 'mtUserAccountAndLogin.soldtoSelection.selectAccount' | cxTranslate }}
      </h2>
    </label>

    <div *ngFor="let soldToItem of soldToList; let isFirst = first; let i = index">
      <div *ngIf="isInCurrentPage(i)" class="account-info-wrapper"
        [class.selected]="soldToIs(soldToItem.businessPartnerId)">
        <div class="form_field_wrapper" (click)="soldToClick(soldToItem)">
          <label class="radio-container account-info" for="soldTo-{{ soldToItem.businessPartnerId }}">
            <span *ngIf="soldToIs(soldToItem.businessPartnerId)" class="radio-container_field-label selected-account-label">
              {{ 'mtUserAccountAndLogin.soldtoSelection.accountSelected' | cxTranslate }}
            </span>
            <span class="radio-container_field-label  account-info-field account-info-field--bold">
              <!-- {{ 'mtUserRegistrationForm.labels.registerUser' | cxTranslate }}-->
              {{ soldToItem.companyData.name }}
            </span>
            <span class="radio-container_field-label account-info-field">
              {{ soldToItem.companyData.address }}
              {{ soldToItem.companyData.address2 }}
              {{ soldToItem.companyData.address3 }}
              {{ soldToItem.companyData.city }}, {{ soldToItem.companyData.region }},
              {{ soldToItem.companyData.country }}
            </span>
            <span class="radio-container_field-label">
              <span class="d-inline account-info-field account-info-field--bold">{{
                'mtUserAccountAndLogin.soldtoSelection.mtNumber' | cxTranslate }} </span>
              <span class="d-inline account-info-field ">{{ soldToItem.businessPartnerId }}</span>
            </span>
            <input type="radio" name="userComp" value="user" id="soldTo-{{ soldToItem.businessPartnerId }}"
              formControlName="userComp" [checked]="soldToIs(soldToItem.businessPartnerId)"/>
            <span class="radio-circle-wrapper">
              <span class="radio-circle"></span>
            </span>
          </label>
        </div>
      </div>
      <div *ngIf="soldToList.length > 1">
        <div *ngIf="isFirst">
          <div *ngIf="soldToIs(soldToItem.businessPartnerId)">
            <div class="other-availabel-label" [ngClass]="{'hide-label': !isFirst}">{{ 'mtUserAccountAndLogin.soldtoSelection.otherAccounts' | cxTranslate }}</div>
            <div class="pick-account-label" [ngClass]="{'hide-label': !isFirst}">{{ 'mtUserAccountAndLogin.soldtoSelection.pickAccount' | cxTranslate }}</div>
          </div>
        </div>
      </div>
      <div *ngIf="soldToList.length == 1">
            <div class="other-availabel-label">{{ 'mtUserAccountAndLogin.soldtoSelection.noAccounts' | cxTranslate }}</div>
      </div>
    </div>
  </div>

  

  <cx-pagination [pagination]="pagination" queryParam="page" [defaultPage]="0"></cx-pagination>
  <hr class="mt-separator">
</div>
