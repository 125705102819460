export const pageNotFoundTranslations = {
    labels: {
        backToShop: 'Zurück zur Onlineshop Homepage',
        contactUs: 'Kontaktieren Sie uns',
        pageNotFound: 'Seite nicht gefunden',
    }
}

export const mtPageNotFoundTranslations = {
    pageNotFoundTranslations
}