import { BehaviorSubject, Observable } from "rxjs";
import { MtProduct } from "../../../model/mt-product.model";


export class MtCartRowModel {
  price: number = 0;
  quantity = 0;
  numberOfEntriesBeforeAdd = -1;
  productCode: string = "";
  product$: Observable<MtProduct | undefined> = new Observable();

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  hasErrors$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  errorMessage: any;
}

