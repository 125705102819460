import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MtVoucherAdapter } from "./mt-voucher.adapter";
import { MtVoucherModel } from "../model/mt-voucher.model";


@Injectable({ providedIn: 'root' })
export class MtVoucherConnector {

  constructor(protected voucherAdapter: MtVoucherAdapter) { }

  validateVoucher(voucherCode: string, cartId: string, userId: string): Observable<MtVoucherModel> {
    return this.voucherAdapter.validateVoucher(voucherCode, cartId, userId);
  }

  removeVoucher(cartId: string, userId: string): Observable<any> {
    return this.voucherAdapter.removeVoucher(cartId, userId);
  }

}