import { Injectable } from "@angular/core";
import { Priority } from "@spartacus/core";
import { SearchCriteria } from "@spartacus/storefront";
import { SearchParamsParserStrategy } from "./search-params-parser.strategy";


/**
 * Build search criteria using an URL.
 */
@Injectable({ providedIn: 'root' })
export class SearchParamsParserFromURLStrategy implements SearchParamsParserStrategy {

  private ANCHOR = '@_anchor_@';
  private EQUAL = '@_equal_@';
  private GREATER = '@_greater_@';
  private LESS = '@_less_@';
  private DEGREE = '@_degree_@';
  private PROC = '@_proc_@';

  private P_QUERY = "stq";
  private P_CURRENT_PAGE = "stp";
  private P_PAGE_SIZE = "stps";
  private P_CATEGORY = "cat";
  private P_FILTER = "filter";
  private P_SORTBY = "sort";

  private DEFAULT_SORT_TYPE_relevance = "relevance";
  private DEFAULT_SORT_TYPE_NAME = "name-asc";
  protected readonly ALLCATEGORIES = `:allCategories:`;


  private getDefaultSortType(isCategoryPage: Boolean) {
    if (isCategoryPage) {
      return this.DEFAULT_SORT_TYPE_NAME;
    } else {
      return this.DEFAULT_SORT_TYPE_relevance;
    }
  }


  protected encodeSearchFilterSpecCharacter(token: string) {
    // Solve '&', '=', '<', '>' character issue
    return token.replace(/\&/gi, this.ANCHOR).replace(/\=/gi, this.EQUAL).replace(/\>/gi, this.GREATER).replace(/\</gi, this.LESS).replace(/\°/gi, this.DEGREE).replace(/\%/gi, this.PROC);
    //return token.replace(/\&/gi, this.ANCHOR).replace(/\=/gi, this.EQUAL);
  }

  protected decodeSearchFilterSpecCharacter(token: string) {
    // Solve '&', '=', '<', '>' character issue
    const anchorRegExp = new RegExp(this.ANCHOR, 'gi');
    const equalRegExp = new RegExp(this.EQUAL, 'gi');
    const greaterRegExp = new RegExp(this.GREATER, 'gi');
    const lessRegExp = new RegExp(this.LESS, 'gi');
    const degreeRegExp = new RegExp(this.DEGREE, 'gi');
    const procRegExp = new RegExp(this.PROC, 'gi');
    //return token.replace(anchorRegExp, '&').replace(equalRegExp, '=').replace(greaterRegExp, '>').replace(lessRegExp, '<');
    return token.replace(anchorRegExp, '&').replace(equalRegExp, '=').replace(procRegExp, '%').replace(greaterRegExp, '>').replace(lessRegExp, '<').replace(degreeRegExp, '°');//.replace(degreeRegExp, '%C2%B0');
    //return token.replace(anchorRegExp, '&').replace(equalRegExp, '=');
  }


  protected fillURLParsedParam(param: string, searchParams: URLParsedParams, skipParseFilters: boolean) {
    const entry = param?.split("=") ?? [];

    if (entry.length > 1) {
      switch (entry[0]) {
        case this.P_QUERY:
          if (!searchParams.isCategoryPage) {
            searchParams.searchCriteria.query = `${entry[1]}:${this.getDefaultSortType(searchParams.isCategoryPage)}`;
          }
          break;
        case this.P_CURRENT_PAGE:
          searchParams.searchCriteria.currentPage = Number.parseInt(entry[1]) - 1;
          break;
        case this.P_PAGE_SIZE:
          searchParams.searchCriteria.pageSize = Number.parseInt(entry[1]);
          break;
        case this.P_CATEGORY:
          if (!searchParams.isCategoryPage) {
            searchParams.category = entry[1]
          }
          break;
        case this.P_SORTBY:
          searchParams.searchCriteria.sortCode = entry[1];
          break;
        default:
          if (skipParseFilters === false) {
            // unsecape filter parameter
            const filterURLParam =
              entry[0];
            //decodeURI(entry[0]);

            // Check if parameter is a filter
            const regex = new RegExp(`${this.P_FILTER}\\[(.+)\\]`);
            if (regex.test(filterURLParam) === true) {
              const [, filter] = regex.exec(filterURLParam) || [];
              if (searchParams.searchCriteria.query === undefined) {
                searchParams.searchCriteria.query = `*:${this.getDefaultSortType(searchParams.isCategoryPage)}`
              }
              //searchParams.searchCriteria.query = `${searchParams.searchCriteria.query}:${filter}:${encodeURI(entry[1]).replace(/\+/gi, "%2B").replace(/%20/gi, "+")}`;
              //searchParams.searchCriteria.query = `${searchParams.searchCriteria.query}:${filter}:${encodeURI(entry[1]).replace(/\+/gi, "%2B")}`;

              // Orig
              // searchParams.searchCriteria.query = `${searchParams.searchCriteria.query}:${filter}:${(entry[1])}`;  .replace('%', '%25')
              //searchParams.searchCriteria.query = `${searchParams.searchCriteria.query}:${this.decodeSearchFilterSpecCharacter(filter)}:${(encodeURI(this.decodeSearchFilterSpecCharacter(entry[1])).replace('%', '%25'))}`;
              searchParams.searchCriteria.query = `${searchParams.searchCriteria.query}:${this.decodeSearchFilterSpecCharacter(filter)}:${(this.decodeSearchFilterSpecCharacter(entry[1]))}`;

              //console.log(`entry[1] = ${entry[1]} `)
              //console.log(`searchParams.searchCriteria.query = ${searchParams.searchCriteria.query} `)
            }
          }
          break;
      }
    }
  }

  protected fillURLParsedParams(params: string[], searchParams: URLParsedParams, skipParseFilters: boolean) {
    params.forEach(param => this.fillURLParsedParam(param, searchParams, skipParseFilters));
  }

  protected parseParamsFromURL(categoryPageCode: string | undefined, categoryFilter: string[], href: string, skipParseFilters: boolean = false): URLParsedParams {
    const searchParams = new URLParsedParams();
    if (categoryFilter !== undefined && href !== undefined) {
      // get schema
      let url = href.split('://');
      searchParams.schema = url[0];

      // get hash parameters
      const indexOfHash = url[1].indexOf('#')
      const hash = url[1].substring(indexOfHash + 1);

      // get components
      searchParams.components = url[1].substring(0, indexOfHash).split('/');

      // Set category page code
      searchParams.categoryPageCode = categoryPageCode;

      // Checkd if it's category page
      if (categoryPageCode !== undefined) {
        searchParams.isCategoryPage = true;
        searchParams.loadPage = true;
      }

      url = hash.split('&');
      //console.log(`url = ${url} - hash = ${hash} - href = ${href}`)
      const sortCodeStart = "sort="
      const sortCode = url.filter(it => it.startsWith(sortCodeStart))[0]?.split(sortCodeStart)[1] ?? this.getDefaultSortType(searchParams.isCategoryPage);
      searchParams.searchCriteria = {
        query: (searchParams.isCategoryPage) ? `:${sortCode}${this.ALLCATEGORIES}${searchParams.categoryPageCode}` : "*",
        currentPage: 0,
        pageSize: 5,
        sortCode: ""
      };
      this.fillURLParsedParams(url, searchParams, skipParseFilters);

      // Checks if it's a search page
      if (categoryFilter.indexOf(searchParams.category) >= 0) {
        searchParams.isSearchCategory = true;
        searchParams.loadPage = true;
      }
    }

    return searchParams;
  }

  protected parseSearchQuery(searchParams: URLParsedParams): string {
    const query: string =
      searchParams.searchCriteria.query || `*:${this.getDefaultSortType(searchParams.isCategoryPage)}`;
    let newQuery = ""
    const arQueryParams: string[] =
      query.split(":");
    if (searchParams.isSearchCategory) {
      newQuery += `${this.P_QUERY}=${arQueryParams[0]}`;
    }
    let iCrs = 2;
    while (iCrs < arQueryParams.length) {
      // Skip category page code
      if (!searchParams.isCategoryPage || !(arQueryParams[iCrs] === "allCategories" && arQueryParams[iCrs + 1] === searchParams.categoryPageCode)) {
        if (searchParams.isSearchCategory || iCrs > 2) {
          //newQuery = `${newQuery}&${this.P_FILTER}[${arQueryParams[iCrs]}]=${arQueryParams[iCrs + 1]}`;
          newQuery = `${newQuery}&${this.P_FILTER}[${this.encodeSearchFilterSpecCharacter(arQueryParams[iCrs])}]=${this.encodeSearchFilterSpecCharacter(arQueryParams[iCrs + 1])}`;
        } else {
          newQuery = `${this.P_FILTER}[${this.encodeSearchFilterSpecCharacter(arQueryParams[iCrs])}]=${this.encodeSearchFilterSpecCharacter(arQueryParams[iCrs + 1])}`;
        }
      }

      iCrs += 2;
    }
    return newQuery;
  }

  protected buildHashTag(searchParams: URLParsedParams): string {
    let newHashtag: string = "";
    let separator = "";

    if (searchParams.searchCriteria.query) {
      newHashtag += this.parseSearchQuery(searchParams);
      separator = "&";
    }

    if (searchParams.searchCriteria.currentPage !== undefined) {
      const currentPageNo =
        searchParams.searchCriteria.currentPage + 1;
      console.log(`currentPage = ${currentPageNo} `)
      newHashtag += `${separator}${this.P_CURRENT_PAGE}=${currentPageNo}`;
      separator = "&";
    }

    if (searchParams.searchCriteria.pageSize) {
      newHashtag += `${separator}${this.P_PAGE_SIZE}=${searchParams.searchCriteria.pageSize}`;
      separator = "&";
    }

    if (searchParams.searchCriteria.sortCode) {
      newHashtag += `${separator}${this.P_SORTBY}=${searchParams.searchCriteria.sortCode}`;
      separator = "&";
    }

    if (searchParams.isSearchCategory && searchParams.category) {
      newHashtag += `${separator}${this.P_CATEGORY}=${searchParams.category}`;
      separator = "&";
    }

    return newHashtag;
  }

  protected extractHref(param: any): string {
    return decodeURIComponent(param ?? "");
    //return param ?? "";
  }

  protected extractParameters(_params: any[]): { categoryPageCode: string, categoriesFilter: string[], href: string } {
    return {
      categoriesFilter: _params[0].split("#"),
      href: this.extractHref(_params[1]),
      categoryPageCode: _params[2]
    };
  }

  protected buildSearchParams(_params: any[], skipParseFilters: boolean = false): URLParsedParams {
    const { categoriesFilter, href, categoryPageCode } =
      this.extractParameters(_params);
    const params =
      this.parseParamsFromURL(categoryPageCode, categoriesFilter, href, skipParseFilters);

    return params;
  }


  hasMatch(): boolean {
    return true;
  }

  getPriority(): Priority {
    return Priority.HIGH;
  }

  buildSearchCriteria(..._params: any[]): SearchCriteria | undefined {
    const params =
      this.buildSearchParams(_params);
    if (params.loadPage) {
      return params.searchCriteria;
    } else {
      return {
        ...params.searchCriteria,
        currentPage: 0,
        pageSize: 1
      } as SearchCriteria;
    }
  }

  buildHashtagForQueryAndFacet(query: string, ..._params: any[]): string {
    const params =
      this.buildSearchParams(_params);

    // change query
    params.searchCriteria.currentPage = 0;
    //params.searchCriteria.query = query;
    params.searchCriteria.query = decodeURIComponent(query.replace(/\+/gi, ' '))
      .replace(/%2B/gi, '+');//.replace(/%/gi, '%25');

    // build the hashtag
    const newHashtag =
      this.buildHashTag(params);

    return newHashtag;
  }

  buildHashtagForClearAllFacets(..._params: any[]): string {
    const params =
      this.buildSearchParams(_params, true);

    // change query
    params.searchCriteria.currentPage = 0;

    // build the hashtag
    const newHashtag =
      this.buildHashTag(params);

    return newHashtag;
  }

  buildHashtagForPageNumber(pageNumber: number, ..._params: any[]): string {
    const params =
      this.buildSearchParams(_params);

    // change the page number
    params.searchCriteria.currentPage = pageNumber;

    // build the hashtag
    const newHashtag =
      this.buildHashTag(params);

    return newHashtag;
  }

  buildHashtagForItemsPerPageNumber(itemsPerPageNo: number, ..._params: any[]): string {
    const params =
      this.buildSearchParams(_params);

    // change items per page
    params.searchCriteria.currentPage = 0;
    params.searchCriteria.pageSize = itemsPerPageNo;

    // build the hashtag
    const newHashtag =
      this.buildHashTag(params);

    return newHashtag;
  }

  buildHashtagForSortBy(sortByCode: string, ..._params: any[]): string {
    const params =
      this.buildSearchParams(_params);

    // set sort by criteria
    params.searchCriteria.sortCode = sortByCode;

    // build the hashtag
    const newHashtag =
      this.buildHashTag(params);

    return newHashtag;
  }


  getCurrentPage(..._params: any[]): number {
    const params =
      this.buildSearchParams(_params);
    return params.searchCriteria?.currentPage ?? 0;
  }


  getCurrentPageSize(..._params: any[]): number {
    const params =
      this.buildSearchParams(_params);
    return params.searchCriteria?.pageSize ?? 5;
  }


}


class URLParsedParams {
  schema: string = "";
  components: string[] = [];
  searchCriteria: SearchCriteria = {};
  category: string = "";
  categoryPageCode?: string;
  isSearchCategory: boolean = false;
  isCategoryPage: boolean = false;
  loadPage: boolean = false;
}
