<div class="user-form">
  <h1 class="text--blue-color">
    {{ 'mtUserAccountAndLogin.forgottenPassword.resetPassword' | cxTranslate }}
  </h1>

  <cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>

  <form *ngIf="token$ | async as token" (ngSubmit)="onSubmit(token)" [formGroup]="form">

    <label>
      <span class="label-content">{{
        'mtUserAccountAndLogin.forgottenPassword.emailAddress.label' | cxTranslate
        }}</span>
      <input required="true" class="form-control" type="email" value="{{userEMail$ | async}}"
        placeholder="{{ 'mtUserAccountAndLogin.forgottenPassword.emailAddress.placeholder' | cxTranslate }}"
        [attr.aria-label]="'mtUserAccountAndLogin.forgottenPassword.emailAddress.placeholder' | cxTranslate" readonly />
    </label>

    <label>
      <span class="label-content">{{
        'mtUserAccountAndLogin.forgottenPassword.chooseNewPassword.label' | cxTranslate
        }}</span>
      <input required="true" class="form-control" type="password"
        placeholder="{{ 'mtUserAccountAndLogin.forgottenPassword.chooseNewPassword.placeholder' | cxTranslate }}"
        formControlName="password"
        [attr.aria-label]="'mtUserAccountAndLogin.forgottenPassword.chooseNewPassword.placeholder' | cxTranslate"
        cxPasswordVisibilitySwitch />
      <cx-form-errors [control]="form.get('password')"></cx-form-errors>
    </label>

    <label>
      <span class="label-content">{{
        'mtUserAccountAndLogin.forgottenPassword.confirmNewPassword.label' | cxTranslate
        }}</span>
      <input required="true" class="form-control" type="password"
        placeholder="{{ 'mtUserAccountAndLogin.forgottenPassword.confirmNewPassword.placeholder' | cxTranslate }}"
        formControlName="passwordConfirm"
        [attr.aria-label]="'mtUserAccountAndLogin.forgottenPassword.confirmNewPassword.placeholder' | cxTranslate"
        cxPasswordVisibilitySwitch />
      <cx-form-errors [control]="form.get('passwordConfirm')"></cx-form-errors>
    </label>

    <div class="btn-wrapper">
      <button class="btn-primary btn-large" [disabled]="form.disabled">
        {{ 'mtUserAccountAndLogin.forgottenPassword.actions.saveNewPassword' | cxTranslate }}
      </button>
    </div>
  </form>
</div>