/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { TranslationService } from "@spartacus/core";
import { BehaviorSubject, Observable, of, Subscription } from "rxjs";
import { mergeMap, debounceTime, map } from "rxjs/operators";
import { CountryContextService } from "../../../../core/country-site-context/country-context.service";
import { LanguageisoContextService } from "../../../../core/languageiso-site-context/languageiso-context.service";
import { mtProductATP } from "../../../assets/translations/en/mt-product-atp.i18n";
import { MtProductAtpModel } from "../../../model/mt-product-atp.model";
import { MT_CPV_STATUS } from "../../../model/mt-product.model";
import { mtDefaultProductQuantity, MtProductAtpService } from "../../services/mt-product-atp.service";
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';

@Component({
  selector: 'mt-product-atp',
  templateUrl: './mt-product-atp.component.html',
  styleUrls: ['./mt-product-atp.component.scss']
})
export class MtProductAtpComponent implements OnInit, OnChanges, OnDestroy {

  @Input() product: any;
  @Input() quantity: number = mtDefaultProductQuantity;
  @Input() showClickForDetails: boolean = false;
  protected productAtpData?: MtProductAtpModel;
  protected textToDisplay: string | undefined = '';
  protected iconClass: string = '';
  protected subscription = new Subscription();
  protected isLoading$ = new BehaviorSubject(false);

  protected quoteonly: string = '';
  protected availability: string = '';
  protected contactUsLastRowMessage: string = '';
  protected contactUsMessage: string = '';

  contactUsUrl$ = this.countryService.getActive().pipe(mergeMap(
    country => this.languageService.getActive().pipe(mergeMap(
      language => of(`/${country}/${language}/home/site_content/contact_us.html`)
    )
    )
  ))

  constructor(
    protected mtProductAtpService: MtProductAtpService,
    protected translationService: TranslationService,
    protected countryService: CountryContextService,
    protected languageService: LanguageisoContextService
  ) {

    this.subscription.add(
      this.translationService.translate(
        'mtProductATP.labels.quoteonly'
      ).subscribe(
        (text) => (
          this.quoteonly = text
        )
      )
    );

    this.subscription.add(
      this.translationService.translate(
        'mtProductATP.labels.availability'
      ).subscribe(
        (text) => (
          this.availability = text
        )
      )
    )

    this.contactUsUrl$.subscribe(
      link => {
        //console.log("contactUsLink: ",link)
        this.subscription.add(
          this.translationService.translate(
            'mtProductATP.labels.contactUsLastRowMessage',
            { link: link }
          ).subscribe(
            (text) => (
              this.contactUsLastRowMessage = text
            )
          )
        )

        this.subscription.add(
          this.translationService.translate(
            'mtProductATP.labels.contactUsMessage',
            { link: link }
          ).subscribe(
            (text) => (
              this.contactUsMessage = text
            )
          )
        )
      }
    )

  }

  ngOnChanges(): void {
  }

  ngOnInit(): void {
    this.callATP();
    this.subscription.add(
      this.mtProductAtpService.getCurrentQty$.pipe(
        map((qty: any) => qty),
        debounceTime(500)
      )
      .subscribe(qty =>{
        this.quantity=qty;
        this.callATP();
      })
    );
  }

  private productHasPrice() {
    return Boolean(this.product.price?.listPrice || this.product.price?.customerPrice);
  }

  triggerPoppver(popover : NgbPopover): void {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
  }

  callATP(): void {
    this.subscription.add(
      this.mtProductAtpService.getATPEnabled().subscribe(
        (result) => {
          if (result) {

            if (this.product.cpv !== MT_CPV_STATUS.QUOTE) {
              if (!this.productHasPrice() && this.product.cpv === MT_CPV_STATUS.SALESANDQUOTE) {
                this.changeStockClass("stock-out-always");
                this.textToDisplay = this.contactUsMessage;
              } else {
                this.isLoading$.next(true);
                this.mtProductAtpService.getProductAtp(this.product.code, this.quantity)
                  .subscribe(
                    {
                      next: (mtProductAtpModel) => {
                        this.productAtpData = mtProductAtpModel;
                        this.processResponse();
                        this.isLoading$.next(false);
                      },
                      complete: () => this.isLoading$.next(false),
                      error: () => this.isLoading$.next(false),
                    }
                  )
              }
            } else {
              this.changeStockClass("stock-out-always");
              this.textToDisplay = this.availability + this.quoteonly;
            }
          }
        })
    );
  }

  processResponse(): void {
    let response = this.productAtpData;

    this.changeStockClass("");
    // $('.icon-atp').addClass('visibility-visible').removeClass('visibility-hidden');
    var isContactUsLastRow = response?.contactUsLastRowFlag;
    var rowsToDispayArray = response?.rowsToDispay;
    var textToDisplay = response?.textToDisplay;
    var standardLeadTimeMessageDisplay = response?.standardLeadTimeMessage;
    var inStockFlag = response?.inStock;
    var zeroStockMessage = response?.zeroStockMessage;
    var incorrectResponse = response?.incorrectResponse;

    // var contactUsMessage = $('#contactUsMessage').text();
    var contactUsLink = this.contactUsMessage;// "<a href =>contactUsMessage</a>" ; // '<a onclick="eco3.atpcheck.contactUsLoadModalForAtp(' +productCode+ ','+ qty + ');" href="#" class="contact-us-flag">' + contactUsMessage + '</a>';
    var contactUsLastRowMessage = this.contactUsLastRowMessage;
    // "contactUsLastRowMessage"; // $('#contactUsLastRowMessage').text();
    var contactUsLastRowLink = this.contactUsLastRowMessage;
    // '<a onclick="eco3.atpcheck.contactUsLoadModalForAtp(' +productCode+ ','+ qty + ');" href="#" class="contact-us-flag">' + contactUsLastRowMessage + '</a>';
    // var popup = $container.find('.content');
    var popupContent: string | undefined = '';
    var sltMsg = false;

    if (zeroStockMessage != null && zeroStockMessage.length > 0) {
      popupContent += zeroStockMessage + '<br/>';
      this.changeStockClass("stock-out");
    }

    if (rowsToDispayArray) {
      //for (var i in rowsToDispayArray) {
      for (var i = 0, max = rowsToDispayArray.length; i < max; i++) {
        var textRow = rowsToDispayArray[i];
        if (textRow) {
          if (inStockFlag) {
            if (standardLeadTimeMessageDisplay != null) {
              popupContent += textRow + '<br/>' + standardLeadTimeMessageDisplay;
              sltMsg = true;
            } else {
              popupContent += textRow + '<br/>';
            }
            this.changeStockClass("stock-in");
          } else {

            if (isContactUsLastRow && i == rowsToDispayArray.length - 1) {
              if (standardLeadTimeMessageDisplay != null) {
                popupContent += contactUsLastRowLink + '<br/>' + standardLeadTimeMessageDisplay;
                sltMsg = true;
              } else {
                popupContent += contactUsLastRowLink + '<br/>';
              }
              // if( !(this.iconClass === "stock-in-partially") && !(this.iconClass === "stock-out")) {
              this.changeStockClass("stock-in-partially");
              // }
            } else {

              if (textRow == "zero") {
                popupContent += contactUsLink + '<br/>';
                this.changeStockClass("stock-out");
              } else {
                popupContent += textRow + '<br/>';
              }
              // if(!$container.hasClass("stock-in-partially") && !$container.hasClass("stock-out")) {
              //   $container.addClass("stock-in-partially").removeClass("stock-in").removeClass("stock-out");
              this.changeStockClass("stock-in-partially");
              // }
            }
          }
        }

      }
      if (standardLeadTimeMessageDisplay != null && inStockFlag == false && sltMsg == false) {
        popupContent += standardLeadTimeMessageDisplay;
      }
    } else {
      popupContent = textToDisplay;

      if (zeroStockMessage != null && zeroStockMessage.length > 0) {
        popupContent = zeroStockMessage + '<br/>';
        popupContent += contactUsLink;
      }

      if (inStockFlag && standardLeadTimeMessageDisplay != null) {
        popupContent += '<br/>' + standardLeadTimeMessageDisplay;
        this.changeStockClass("stock-in");
      } else {
        this.changeStockClass("stock-in");
        if (response?.contactUsFlag) {
          this.changeStockClass("stock-out");
        }

        if (incorrectResponse) {
          this.changeStockClass("incorrect-atp-response");
        }
      }
      contactUsLink = "<div class=\"text-center\">" + contactUsLink + "</div>";
    }
    if (response?.stockOutAlways) {
      this.changeStockClass("stock-out-always");
    }
    if (response?.stockInForLead) {
      this.changeStockClass("stock-in-for-lead");
    }
    // mtd case when quantity is in stock but not fully available.
    if (standardLeadTimeMessageDisplay) {
      popupContent = standardLeadTimeMessageDisplay;
      this.changeStockClass("stock-in-partially");
    }

    // if(popupContent && !$container.hasClass("stock-in-text")){
    if (popupContent && (this.iconClass != ("stock-in-text"))) {
      // popupContent = $('#availabilityTitle').val() +popupContent
      popupContent = popupContent
    }

    if (contactUsLink && (this.iconClass != "stock-in-text")) {
      // contactUsLink = $('#availabilityTitle').val() + contactUsLink
      contactUsLink = contactUsLink;
    }

    // popup.html(popupContent ? popupContent : contactUsLink);
    this.textToDisplay = popupContent ? popupContent : contactUsLink;
    // $container.addClass("atp-loaded");

  }

  changeStockClass(newClass: string) {
    this.iconClass = newClass;
  }

  public get getMtCPVStatusType(): typeof MT_CPV_STATUS {
    return MT_CPV_STATUS;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public get getAtpEnabled(): Observable<boolean> {
    return this.mtProductAtpService.getATPEnabled();
  }

  protected readonly mtProductATP = mtProductATP;
}
