import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { EventService } from "@spartacus/core";
import { LaunchDialogService, LAUNCH_CALLER } from "@spartacus/storefront";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { QuotationUIEvent } from "./quotation-ui.event";

/**
 * Listen for quotation request events.
 */
@Injectable({
  providedIn: "root"
})
export class MtRequestQuotationListener implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();


  private closeModal(reason?: any): void {
    this.launchDialogService.closeDialog(reason);
  }

  private openDialog(event: QuotationUIEvent) {
    const dialog =
      this.launchDialogService.openDialog(
        LAUNCH_CALLER.REQUEST_QUOTATION.toString(),
        undefined,
        undefined,
        event
      );

    if (dialog) {
      dialog.pipe(take(1)).subscribe();
    }
  }

  private requestQuote() {
    this.subscription.add(
      this.eventService.get(QuotationUIEvent).subscribe(event => {
        this.openDialog(event);
      })
    );
  }

  constructor(
    private eventService: EventService,
    private launchDialogService: LaunchDialogService
  ) {
    this.requestQuote();
  }

  ngOnInit(): void {

  }


  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }


}