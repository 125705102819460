<ng-container *ngIf="facetList$ | async as facetList">
  <div class="mt-active-facets-container mobile-filter-selected">
    <a *ngFor="let facet of facetList?.activeFacets" [cxFocus]="{ key: getFocusKey(facetList, facet) }" role="button"
      (keydown.space)="removeFilterWithSpacebar($event)" [attr.aria-label]="
      'productList.activeFilter' | cxTranslate: { filter: facet.facetValueName }
    " (click)="onClearFacet(facet)" class="mt-active-facet-item">
      <span>{{ facet.facetValueName }}</span>
      <cx-icon aria-hidden="true" [type]="closeIcon" class="removeFacet"></cx-icon>
    </a>

    <a *ngIf="(facetList?.activeFacets?.length ?? 0) > 0" style="cursor: pointer;" class="mt-clear-all-facets"
      role="button" (keydown.space)="removeFilterWithSpacebar($event)" (click)="onClearAll()">
      <span class="ClearAllInMobile">{{ 'mtActiveFacets.labels.clearAllActiveFacets' | cxTranslate }}</span>
    </a>
  </div>
</ng-container>