import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  AuthActions, AuthConfigService, AuthMultisiteIsolationService, AuthRedirectService,
  AuthService, AuthStorageService, BaseSiteService,
  OAuthLibWrapperService,
  OCC_USER_ID_CURRENT, RoutingService,
  StateWithClientAuth,
  UserIdService, WindowRef
} from "@spartacus/core";
import { Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { MtSoldToService } from "../account/core/mt-sold-to.service";
import { MtOAuthService } from "../oauth/mt-oauth-service";

@Injectable({
  providedIn: 'root',
})
export class MtAuthService extends AuthService {


  constructor(
    protected override store: Store<StateWithClientAuth>,
    protected override userIdService: UserIdService,
    protected override oAuthLibWrapperService: OAuthLibWrapperService,
    protected override authStorageService: AuthStorageService,
    protected override authRedirectService: AuthRedirectService,
    protected override routingService: RoutingService,
    protected mtOAuthService: MtOAuthService,
    protected authConfigService: AuthConfigService,
    protected baseSiteService: BaseSiteService,
    protected winRef: WindowRef,
    protected mtSoldToService: MtSoldToService,
    protected override authMultisiteIsolationService?: AuthMultisiteIsolationService,
  ) {
    super(store, userIdService, oAuthLibWrapperService, authStorageService, authRedirectService, routingService, authMultisiteIsolationService);
    this.initialize();
  }

  protected initialize() {
    const isSSR = !this.winRef.isBrowser();
    this.mtOAuthService.configure({
      tokenEndpoint: this.authConfigService.getTokenEndpoint(),
      loginUrl: this.authConfigService.getLoginUrl(),
      clientId: this.authConfigService.getClientId(),
      dummyClientSecret: this.authConfigService.getClientSecret(),
      revocationEndpoint: this.authConfigService.getRevokeEndpoint(),
      logoutUrl: this.authConfigService.getLogoutUrl(),
      userinfoEndpoint: this.authConfigService.getUserinfoEndpoint(),
      issuer:
        this.authConfigService.getOAuthLibConfig()?.issuer ??
        this.authConfigService.getBaseUrl(),
      redirectUri:
        this.authConfigService.getOAuthLibConfig()?.redirectUri ??
        (!isSSR
          ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.winRef.nativeWindow!.location.origin
          : ''),
      ...this.authConfigService.getOAuthLibConfig(),
    });
  }


  async loginWithCredentialsAndRememberMe(userId: string, password: string, rememberMe: boolean, redirectToRoute?: string): Promise<void> {
    let uid = userId;
    let baseSiteId: string = "";
    this.baseSiteService.getActive().subscribe((data) => { baseSiteId = data });
    try {
      await this.mtOAuthService.fetchTokenMtSpecificUsingPasswordFlow(uid, password, rememberMe, baseSiteId);

      // OCC specific user id handling. Customize when implementing different backend
      this.userIdService.setUserId(OCC_USER_ID_CURRENT);

      this.store.dispatch(new AuthActions.Login());

      if (redirectToRoute !== undefined) {
        console.log(`Login redirectToRoute: ${redirectToRoute}`);
        this.authRedirectService.setRedirectUrl(redirectToRoute);
      } else {
        console.log(`Login redirect to account`);
        this.authRedirectService.setRedirectUrl('account');
      }
      this.authRedirectService.redirect();

    } catch (error) {
      console.log(error);
    }
  }

  isLoggedInWithRememberMe(): Observable<boolean> {
    return this.authStorageService.getToken().pipe(
      map((userToken) => userToken?.remember_me),
      distinctUntilChanged()
    );
  }


  override coreLogout(): Promise<void> {
    this.setLogoutProgress(true);
    this.userIdService.clearUserId();
    this.mtSoldToService.clearSoldToSelection();
    return new Promise((resolve) => {
      this.oAuthLibWrapperService.revokeAndLogout().finally(() => {
        this.store.dispatch(new AuthActions.Logout());
        this.setLogoutProgress(false);
        resolve();
      });
    });
  }
}
