
export const main = {
  menu: {
    label: 'Hauptmenü'
  }
}

export const homepage = {
  main
}
