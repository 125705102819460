import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthService, Product, UrlCommands } from '@spartacus/core';
import { ProductListItemComponent, ProductListItemContext, ProductListItemContextSource } from '@spartacus/storefront';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MtUrlService } from 'src/app/spartacus/custom-module/core/aem-adapter-site-context/mt-url.service';
import { MtPriceModel } from '../../../model/mt-price.model';
import { MtProduct, MT_CPV_STATUS, TechnicalAttribute } from '../../../model/mt-product.model';
import { mtDefaultProductQuantity } from '../../../product-availability/services/mt-product-atp.service';
import { MtPriceService } from '../../../product-price';
import { MtProductListComponentService } from '../mt-product-list/mt-product-list-component.service';

@Component({
  selector: 'mt-product-list-item',
  templateUrl: './mt-product-list-item.component.html',
  styleUrls: ['./mt-product-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    ProductListItemContextSource,
    {
      provide: ProductListItemContext,
      useExisting: ProductListItemContextSource,
    },
  ],
})
export class MtProductListItemComponent extends ProductListItemComponent implements OnInit {

  protected addTechnicalAttribute(index: number, techincalAttributes: TechnicalAttribute[], mtProduct: MtProduct) {
    if (mtProduct.technicalAttributes !== undefined && mtProduct.technicalAttributes.length > index) {
      techincalAttributes.push({
        code: mtProduct.technicalAttributes[index]?.code ?? '',
        name: mtProduct.technicalAttributes[index]?.name ?? '',
        values: mtProduct.technicalAttributes[index]?.values ?? [''],
      });
    } else {
      techincalAttributes.push({
        code: '',
        name: '',
        values: [''],
      });
    }
  }


  isUserLoggedIn$: Observable<boolean> = of(false);

  readonly product$: BehaviorSubject<MtProduct | undefined> =
    new BehaviorSubject<MtProduct | undefined>(undefined);


  readonly prices$: Observable<MtPriceModel[] | undefined> =
    this.productListComponentService.prices$.pipe(
      tap((mtPrices: MtPriceModel[] | undefined) => {
        const products =
          this.priceService.setupPriceForProduct([this.product ?? {}], mtPrices ?? [{} as MtPriceModel]);
        if (products) {
          this.product$.next(products[0] as MtProduct);
        }
      })
    );


  constructor(
    protected override productListItemContextSource: ProductListItemContextSource,
    protected authService: AuthService,
    protected mtUrlService: MtUrlService,
    protected productListComponentService: MtProductListComponentService,
    protected priceService: MtPriceService,
  ) {
    super(productListItemContextSource);
  }

  ngOnInit(): void {
    this.isUserLoggedIn$ = this.authService.isUserLoggedIn();
    this.product$.next(this.product);
  }

  getImage(product: Product): any {
    return product.images;
  }

  getProductDetailsURL(commands: UrlCommands): string {
    return this.mtUrlService.buildURLWithAEMContext(commands);
  }

  getTechnicalAttributes(): TechnicalAttribute[] {
    const mtProduct = this.product as MtProduct;
    const techincalAttributes: TechnicalAttribute[] = [];

    this.addTechnicalAttribute(0, techincalAttributes, mtProduct);
    this.addTechnicalAttribute(1, techincalAttributes, mtProduct);
    this.addTechnicalAttribute(2, techincalAttributes, mtProduct);

    return techincalAttributes;
  }

  public get getMtCPVStatusType(): typeof MT_CPV_STATUS {
    return MT_CPV_STATUS;
  }

  protected readonly mtDefaultProductQuantity = mtDefaultProductQuantity;
  protected readonly Boolean = Boolean;
}
