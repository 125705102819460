import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class MtCheckoutPaymentService {

  private _paymentError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  paymentError = this._paymentError.asObservable();


  getPaymentError() : Observable<boolean> {
   return this._paymentError.asObservable();
  }


  updatePaymentError(value: boolean) {
    this._paymentError.next(value);
  }

}
