/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { CartConfigService, isEmpty } from '@spartacus/cart/base/core';
import {
  ActiveCartFacade,
  Cart,
  SelectiveCartFacade,
} from '@spartacus/cart/base/root';
import { combineLatest, Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {CartPageLayoutHandler} from "@spartacus/cart/base/components";

@Injectable({
  providedIn: 'root',
})
export class MtCartPageLayoutHandler extends CartPageLayoutHandler {

  /**
   *
   * @param slots$
   * @param pageTemplate
   * @param section
   *  Template name case causing issues;  the rest of the code is OOTB
   */
  override handle(
    slots$: Observable<string[]>,
    pageTemplate?: string,
    section?: string
  ) {
    if ( (pageTemplate?.toLowerCase() === 'CartPageTemplate'.toLowerCase()) && !section) {
      return combineLatest([
        slots$,
        this.activeCartService.getActive(),
        this.cartConfig.isSelectiveCartEnabled()
          ? this.selectiveCartService.getCart().pipe(startWith(null))
          : of({} as Cart),
        this.activeCartService.getLoading(),
      ]).pipe(
        map(([slots, cart, selectiveCart, loadingCart]) => {
          const exclude = (arr: string[], args: string[]) =>
            arr.filter((item) => args.every((arg) => arg !== item));
          return isEmpty(cart) && loadingCart
            ? exclude(slots, [
              'TopContent',
              'CenterRightContentSlot',
              'EmptyCartMiddleContent',
            ])
            : cart.totalItems
              ? exclude(slots, ['EmptyCartMiddleContent'])
              : selectiveCart?.totalItems
                ? exclude(slots, [
                  'EmptyCartMiddleContent',
                  'CenterRightContentSlot',
                ])
                : exclude(slots, ['TopContent', 'CenterRightContentSlot']);
        })
      );
    }
    return slots$;
  }

}
