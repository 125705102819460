import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ConfigModule, I18nConfig, I18nModule, provideConfig } from '@spartacus/core';
import { CmsPageGuard, LayoutConfig, PageLayoutComponent } from '@spartacus/storefront';
import { mtHomepageChunkConfig, mtHomepageTranslations } from './assets/translations/translations';
import { MtBannerComponentModule } from './components/mt-banner-component/mt-banner-component.module';
import { MtCategoriesNavigationComponentModule } from './components/mt-categories-navigation-component/mt-categories-navigation-component.module';
import { MtCategoriesNavigationComponentService } from './components/mt-categories-navigation-component/mt-categories-navigation-component.service';
import { MtCategoryContentComponentModule } from './components/mt-category-content-component/mt-category-content-component.module';
import { ConvertNavigationNodesService } from './services/convert-navigation-nodes.service';
import { MtNavigationService } from './services/mt-navigation.service';
import { effects } from './store/effects';
import { NAVIGATION_FEATURE } from './store/navigation-state';
import { reducerProvider, reducerToken } from './store/reducers';
import { PageNotFoundErrorPageComponent } from '../page-not-found/components/page-not-found-error-page/page-not-found-error-page.component';

export const shopHomePageLayoutConfig: LayoutConfig = {
  layoutSlots: {
    ShopHomePageTemplate: {
      pageFold:'Section1',
      slots: [
        'Section1',
        'Section2A',
        'Section2B',
        'Section2C',
        'Section3',
        'Section4',
        'Section5'
      ],
    },
  }
}

@NgModule({
  declarations: [
    PageNotFoundErrorPageComponent,
  ],
  imports: [
    MtCategoriesNavigationComponentModule,
    MtCategoryContentComponentModule,
    MtBannerComponentModule,
    CommonModule,
    I18nModule,

    RouterModule.forChild([
      {
        path: 'home/**',
        data: {
          pageLabel: '/home'
        },
        component: PageLayoutComponent,
        canActivate: [CmsPageGuard]
      },
      {
        path: 'register-route',
        redirectTo: '/login/register'
      }
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          home: {
            paths: ['/home'],
            protected: false
          },
          notFound: {
            paths: ['/page-not-found-error-page'],
            protected: false
          },

          categoryPage: {
            paths: ['home/:selectedNavigationNode']
          },
          homePage: {
            paths: ['home']
          }
        },
      }
    }),
    StoreModule.forFeature(NAVIGATION_FEATURE, reducerToken),
    EffectsModule.forFeature(effects)
  ],
  providers: [
    MtCategoriesNavigationComponentService,
    MtNavigationService,
    ConvertNavigationNodesService,
    reducerProvider,
    provideConfig(shopHomePageLayoutConfig),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: mtHomepageTranslations,
        chunks: mtHomepageChunkConfig,
      }
    })
  ]
})
export class HomepageModule { }
