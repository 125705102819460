import {Component, ElementRef, HostBinding, Input, OnInit, Renderer2} from '@angular/core';
import {MtFacetService} from "../../services/mt-facet.service";

@Component({
  selector: 'mt-plp',
  templateUrl: './mt-plp.component.html',
  styleUrls: ['./mt-plp.component.scss']
})
export class MtPlpComponent implements OnInit {

  @Input()
  category?: string;

  @Input()
  prefilter?: string;

  constructor(
    protected facetService: MtFacetService,
    private renderer: Renderer2, private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    console.log('MtPlp initialized with category:', this.category);
    console.log('MtPlp initialized with prefilter:', this.prefilter);
    this.facetService.setupCategoryPage(this.category);
    this.renderer.setStyle(this.elementRef.nativeElement, 'width', '100%');
  }

}
