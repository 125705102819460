import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, NgModule, PLATFORM_ID } from '@angular/core';
import { Observable, ReplaySubject } from "rxjs";
import { CurrencyContextService } from '../../../core/currency-site-context/currency-context.service';
import { LocalStorageService } from "../../../util/local-storage/local-storage.service";
import { MtSoldToModel } from './mt-sold-to.model';
import { MtSoldToSelectionOccService } from "./mt-soldto-selection-occ.service";

export const noActiveSoldTo = 'no-active-soldTo';

@Injectable({
  providedIn: 'root',
})
@NgModule({})
export class MtSoldToService {


  get activeSoldToId(): Observable<string> {
    return this._activeSoldToId;
  }

  private _activeSoldToId: Observable<string> = new ReplaySubject<string>(2);

  constructor(
    private currencyContextService: CurrencyContextService,
    private occ: MtSoldToSelectionOccService,
    @Inject(PLATFORM_ID) protected platformId: any,
    protected localStorageService: LocalStorageService) {
    (this._activeSoldToId as ReplaySubject<string>).next(this.getCurrentSoldTo() ?? noActiveSoldTo);
  }


  public getSoldToList(): Observable<MtSoldToModel[]> {
    return this.occ.loadSoldToList();
  }


  public isCurrentSoldToSelected(): boolean {
    if (this.getCurrentSoldTo() && this.getCurrentSoldTo() != noActiveSoldTo) {
      return true;
    }
    else {
      return false;
    }
  }

  public setCurrentSoldTo(soldTo: MtSoldToModel) {
    if (soldTo.businessPartnerId == this.getCurrentSoldTo()) {
      return;
    }
    this.currencyContextService.setupCurrencyFromSoldTo(soldTo); // setup currency
    //TODO: temporary implementation, to be replaced with https://help.sap.com/docs/SAP_COMMERCE_COMPOSABLE_STOREFRONT/eaef8c61b6d9477daf75bff9ac1b7eb4/783909436fcc4b42909fac4cbceef2eb.html
    if (isPlatformBrowser(this.platformId)) {
      this.localStorageService.setItem('soldTo', soldTo.businessPartnerId);
    }
    (this._activeSoldToId as ReplaySubject<string>).next(soldTo.businessPartnerId);
  }


  public getCurrentSoldTo() {
    if (isPlatformBrowser(this.platformId)) {
      return this.localStorageService.getItem('soldTo'); //TODO: temporary implementation
    } else {
      return '';
    }
  }

  clearSoldToSelection() {
    (this._activeSoldToId as ReplaySubject<string>).next(noActiveSoldTo)
    this.localStorageService.removeItem('soldTo');//TODO: temporary solution, will be handled on session management story
  }
}

export enum MtOrganisationRegistrationStatusEnum {
  NOT_REGISTERED = "NOT_REGISTERED",
  REGISTRATION_NOT_CONFIRMED = "REGISTRATION_NOT_CONFIRMED",
  SINGLE_SOLDTO_ACCOUNT = "SINGLE_SOLDTO_ACCOUNT",
  MULTIPLE_SOLDTO_ACCOUNTS = "MULTIPLE_SOLDTO_ACCOUNTS"
}



