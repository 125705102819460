/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";


@Component({
  selector: 'mt-news-feed-small-component',
  templateUrl: './mt-news-feed-small.component.html',
  styleUrls: ['./mt-news-feed-small.component.scss']
})
export class MtNewsFeedSmallComponent {

};
