import { Component, Input, OnInit } from '@angular/core';
import { MtNavigationNode } from '../../model/mt-navigation-node.model';
import { MtCategoriesNavigationComponentService } from '../mt-categories-navigation-component/mt-categories-navigation-component.service';

@Component({
  selector: 'mt-category-item',
  templateUrl: './mt-category-item.component.html',
  styleUrls: ['./mt-category-item.component.scss']
})
export class MtCategoryItemComponent implements OnInit {

  @Input() navigationNode?: MtNavigationNode;

  constructor(protected mtCategoryNavigationComponentService: MtCategoriesNavigationComponentService,) { }

  ngOnInit(): void {
  }

  hasChildren(): boolean {
    return (this.navigationNode?.children?.length || 0) > 0;
  }

  escapeURLParam() {
    return this.navigationNode?.title?.toLowerCase().trim().replace(/\s+/gi, '-')
  }

}
