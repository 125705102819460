import { Component } from '@angular/core';
import {PaginationBuilder, PaginationComponent, PaginationItem} from '@spartacus/storefront';
import {ActivatedRoute} from "@angular/router";
import {WindowRef} from "@spartacus/core";

@Component({
  selector: 'mt-pagination',
  templateUrl: './mt-pagination.component.html',
  styleUrls: ['./mt-pagination.component.scss']
})
export class MtPaginationComponent extends PaginationComponent {

  constructor(
    paginationBuilder: PaginationBuilder,
    activatedRoute: ActivatedRoute,
    private winRef: WindowRef
  ) {
    super(paginationBuilder, activatedRoute);
  }


  override pageChange(page: PaginationItem) {
    if(page.number !== undefined) {
      super.pageChange(page);
      this.winRef?.nativeWindow?.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth',
      });
    }
  }

}
