import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FocusConfig, ICON_TYPE, LaunchDialogService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import {RoutingService} from "@spartacus/core";
import {filter} from "rxjs/operators";

@Component({
  selector: 'mt-orders-redirect-dialog',
  templateUrl: './mt-orders-redirect-dialog.component.html',
  styleUrls: ['./mt-orders-redirect-dialog.component.scss']
})
export class MtOrdersRedirectDialogComponent implements OnInit, OnDestroy {

  iconTypes = ICON_TYPE;

  form: UntypedFormGroup = new UntypedFormGroup({});

  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: 'button',
    focusOnEscape: true,
  };

  protected subscription = new Subscription();

  constructor(
    private routingService: RoutingService,
    protected launchDialogService: LaunchDialogService,
    protected el: ElementRef,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.launchDialogService.data$.subscribe( )
    );
    this.subscription.add(
      this.routingService
        .getRouterState()
        .pipe(filter((state) => !!state.nextState))
        .subscribe(() => this.dismissModal('dismiss'))
    );
  }

  dismissModal(reason?: any): void {
    this.launchDialogService.closeDialog(reason);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
