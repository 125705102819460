/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  GlobalMessageService,
  GlobalMessageType,
  HttpErrorModel,
  RoutingService
} from '@spartacus/core';
import { CustomFormValidators } from '@spartacus/storefront';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MtReactivateAccountService } from '../../core/mt-reactivate-account.service';
import {MtLoginFormComponentService} from "../login-form";
import {MtCustomFormValidators} from "../../../../util/validators/mt-custom-form-validators";

@Injectable()
export class MtReactivateAccountComponentService {
  constructor(
    private mtReactivateAccount: MtReactivateAccountService,
    protected routingService: RoutingService,
    protected globalMessage: GlobalMessageService,
    protected mtLoginFormComponentService: MtLoginFormComponentService,
  ) { }

  protected busy$ = new BehaviorSubject(false);

  isUpdating$ = this.busy$.pipe(
    tap((state) => (state === true ? this.form.disable() : this.form.enable()))
  );

  form: UntypedFormGroup = new UntypedFormGroup(
    {
      userId: new UntypedFormControl('', [
        Validators.required,
        MtCustomFormValidators.emailValidator,
      ]),
    },
  );

  /**
   * Reactivates the user account for the given e-mail address
   *
   */
  reactivateAccount( ): void {

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.busy$.next(true);

    const email = (this.form.get('userId') as UntypedFormControl).value;

    this.mtReactivateAccount.userReactivateAccount(email).subscribe({
      next: () => this.onSuccess(),
      error: (error: unknown) => this.onError(error),
    });
  }

  protected onSuccess(): void {
    this.mtLoginFormComponentService.isAccountInactive = false;
    this.globalMessage.add(
      { key: 'mtUserAccountAndLogin.reactivateAccount.successMessage' },
      GlobalMessageType.MSG_TYPE_CONFIRMATION
    );
    this.busy$.next(false);
    this.form.reset();
    this.redirect();
  }

  protected onError(error: unknown): void {
    this.busy$.next(false);
    if (error instanceof HttpErrorModel) {
      (error.details ?? []).forEach((err) => {
        if (err.message) {
          this.globalMessage.add(
            { raw: err.message },
            GlobalMessageType.MSG_TYPE_ERROR
          );
        }
      });
    }
  }

  /**
   * Redirects the user to the login page.
   */
  protected redirect() {
    this.routingService.go({ cxRoute: 'login' });
  }
}
