<cx-spinner class="overlay" *ngIf="isUpdating$ | async"></cx-spinner>

<div *ngIf="isAccountInactive()">
 
  <div class="form__highlight org-info login-form login-form__inactive-account global-alert-info alert alert-bar">
    <ul class="notification form__info">
      <li class="text-left alert-icon"><span>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2552_2294)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM11 7V5H13V7H11ZM11 19V9H13V19H11Z" fill="#004494"/>
          </g>
          <defs>
            <clipPath id="clip0_2552_2294">
              <rect width="24" height="24" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </span></li>
      <li class="text-left alert-label"><span>
        <span>
          {{ 'mtUserAccountAndLogin.reactivateAccount.infoBox1' | cxTranslate }}
        </span>
        <a routerLink="/login/reactivateAccount">
          {{ 'mtUserAccountAndLogin.reactivateAccount.infoBox2' | cxTranslate }}
        </a>
        <span>
          {{ 'mtUserAccountAndLogin.reactivateAccount.infoBox3' | cxTranslate }}
        </span>
      </span></li>
    </ul>
  </div>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()" class="login-form">
  <h1 class="text--blue-color">
    <span>{{ 'mtUserAccountAndLogin.loginForm.labels.loginTitle' | cxTranslate }}</span>
  </h1>

  <div class="form_field_wrapper login-form__field">
    <label class="field_label">
      <span>{{
        'mtUserAccountAndLogin.loginForm.fields.emailAddress.label' | cxTranslate
        }}</span>
      <input required="true" class="form-control" type="email" name="email" placeholder="{{
            'mtUserAccountAndLogin.loginForm.fields.emailAddress.placeholder' | cxTranslate
          }}" formControlName="userId" />
      <cx-form-errors [control]="form.get('userId')"></cx-form-errors>
    </label>
  </div>

  <div class="form_field_wrapper login-form__field">
    <label class="field_label">
      <span>{{'mtUserAccountAndLogin.loginForm.fields.password.label' | cxTranslate}}</span>
      <input required="true" type="password" class="form-control"
        placeholder="{{ 'mtUserAccountAndLogin.loginForm.fields.password.placeholder' | cxTranslate }}"
        formControlName="password"
        [attr.aria-label]="'mtUserAccountAndLogin.loginForm.fields.password.placeholder' | cxTranslate"
        cxPasswordVisibilitySwitch />
      <cx-form-errors [control]="form.get('password')"></cx-form-errors>
    </label>
  </div>

  <div class="form_btn-action-wrapper login-btn">
    <button class="btn-primary btn-large login-form__button" type="submit" [disabled]="(!form.valid && !(autoFilledByBrowser$ | async)) || ((autoFilledByBrowser$ | async) && form.touched && !form.valid)">
      <span>{{ 'mtUserAccountAndLogin.loginForm.labels.signIn' | cxTranslate }}</span>
    </button>
  </div>

  <div class="form_field_wrapper form_field_keep_sined_in">
    <label class="checkmark-container" for="keepMeSignedIn">
      {{ 'mtUserAccountAndLogin.loginForm.labels.keepMeSignedIn' | cxTranslate }}
      <input type="checkbox" id="keepMeSignedIn" name="keepMeSignedIn" formControlName="keepMeSignedIn"
        value="keepMeSignedIn">
      <span class="checkmark"></span>
      <cx-form-errors [control]="form.get('keepMeSignedIn')"></cx-form-errors>
    </label>
  </div>


  <a class="login-form__forgot-password" [routerLink]="{ cxRoute: 'forgotPassword' } | cxUrl">
    {{ 'mtUserAccountAndLogin.loginForm.labels.forgotPassword' | cxTranslate }}
  </a>

  <div class="form_btn-action-wrapper">
    <button class="btn-secondary btn-large login-form__button" type="button">
      <a [routerLink]="{ cxRoute: 'register' } | cxUrl">
        <span>{{ 'mtUserAccountAndLogin.loginForm.labels.register' | cxTranslate }}</span>
      </a>
    </button>
  </div>

  <p class="form__info form_btn-action-wrapper">
    {{ 'mtUserAccountAndLogin.loginForm.labels.lookingCustomerPortal' | cxTranslate }}
    <a routerLink="/login/customerPortal">
      {{ 'mtUserAccountAndLogin.loginForm.labels.clickHereLink' | cxTranslate }}
    </a>
  </p>

</form>
