import {Component, Input, OnInit} from '@angular/core';
import { CmsBannerComponent, CmsService, SemanticPathService } from '@spartacus/core';
import { BannerComponent, CmsComponentData } from '@spartacus/storefront';
import {Observable} from "rxjs";

@Component({
  selector: 'mt-banner',
  templateUrl: './mt-banner.component.html',
  styleUrls: ['./mt-banner.component.scss']
})
export class MtBannerComponent extends BannerComponent implements OnInit {

  @Input() data: any;

  constructor(
    protected override component: CmsComponentData<CmsBannerComponent>,
    protected override urlService: SemanticPathService,
    protected override cmsService: CmsService) {
    super(component, urlService, cmsService);
  }

  ngOnInit(): void {
    if(this.data != null){
      this.data$ = this.data as Observable<CmsBannerComponent>;
    }
  }

  getUrlLinkLocalized(data: any): String {
    return data.urlLinkLocalized;
  }

}
