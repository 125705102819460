import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OccConfig, PRODUCT_NORMALIZER, PRODUCT_SEARCH_PAGE_NORMALIZER, provideConfig } from '@spartacus/core';
import { MtPriceAdapter } from './mt-price.adapter';
import { MtPriveConverter, PRICE_NORMALIZER } from './mt-price.converter';
import { MtProductDocumentAdapter } from './mt-product-document.adapter';
import { MtProductDocumentConverter, PRODUCT_DOCUMENT_NORMALIZER } from './mt-product-document.converter';
import { MtProductSearchPageNormalizer } from './mt-product-search-page-normalizer';
import { MtProductConverter } from './mt-product.converter';
import { PriceAdapter } from './price.adapter';
import { ProductAtpAdapter } from './product-atp.adapter';
import { ProductAtpConverter, PRODUCT_ATP_NORMALIZER } from './product-atp.converter';
import {MtProductZoovuBannerAdaptor} from "./mt-product-zoovu-banner-adaptor";


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    provideConfig(<OccConfig>{
      backend: {
        occ: {
          endpoints: {
            productSearch:
              //'products/search?fields=FULL',
              'products/search?fields=products(code,name,description,summary,configurable,configuratorType,multidimensional,price(FULL),images(DEFAULT),stock(FULL),averageRating,variantOptions,availability,technicalAttributes,cpv,esbu,generalItemCategoryGroup),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery',
            getprice:
              '${language}/price/getprice',
            productAtp:
              'atp/${productCode}',
            documents:
              'products/${productCode}/documents',
            parentDsCategory:
              'products/${productCode}/parentDsCategory',
            productZoovuBanner:
              'products/${productCode}/zoovuBanner'
          }
        }
      },
    }),
    {
      provide: PriceAdapter,
      useExisting: MtPriceAdapter
    },
    {
      provide: PRICE_NORMALIZER,
      useExisting: MtPriveConverter,
      multi: true
    },
    {
      provide: PRODUCT_NORMALIZER,
      useExisting: MtProductConverter,
      multi: true
    }, {
      provide: PRODUCT_SEARCH_PAGE_NORMALIZER,
      useExisting: MtProductSearchPageNormalizer,
      multi: true
    },
    {
      provide: ProductAtpAdapter,
      useClass: ProductAtpAdapter
    },
    {
      provide: MtProductZoovuBannerAdaptor,
      useClass: MtProductZoovuBannerAdaptor
    },
    {
      provide: PRODUCT_ATP_NORMALIZER,
      useExisting: ProductAtpConverter,
      multi: true
    },
    {
      provide: MtProductDocumentAdapter,
      useClass: MtProductDocumentAdapter
    },
    {
      provide: PRODUCT_DOCUMENT_NORMALIZER,
      useExisting: MtProductDocumentConverter,
      multi: true
    }
  ]
})
export class ProductDataBindingModule { }
