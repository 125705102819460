import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

function bootstrap() {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
};


 if (document.readyState === 'complete') {
   bootstrap();
 } else {
   document.addEventListener('DOMContentLoaded', bootstrap);
 }
 
 function addAbtastyScript() {
  //if abtastyScript script with id "abtastyAddedScript" alreday exist remove first
  var abtastyScript = document.getElementById('abtastyAddedScript');
  if (abtastyScript != null) {
    document.head.removeChild(abtastyScript);
  }

  //add abtastyScript dynaically by indentify the country from url & add dynamic script src
  const siteURL = location.href;
  const siteURLParts = siteURL.split('/');
  if(siteURLParts[3] == "shop"){
    const script = document.createElement('script');
    script.setAttribute('id', 'abtastyAddedScript');
    script.type="text/javascript";
    if(siteURLParts[4] == "cn"){
      script.src = "https://try.abtastychina.cn/658c21f1e8f15439877f91528ea52005.js";
    }
    else{
      script.src = "https://try.abtasty.com/658c21f1e8f15439877f91528ea52005.js";
    }
    document.head.appendChild(script);
  }   
}
// call addAbtastyScript
addAbtastyScript();