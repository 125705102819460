<div class="plp-wrapper">
  <div class="ds_productFiltering__facets">
    <mt-facet-list [category]="category" >
    </mt-facet-list>
  </div>

  <div class="ds_productFiltering__list">
    <mt-product-list [category]="category" [prefilter]="prefilter" ></mt-product-list>
  </div>
</div>
