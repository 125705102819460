
export const mtProductCart = {
  titles: {
    loading: "Winkelwagen bijwerken...",
    addcart: "Artikel(en) toegevoegd aan uw winkelwagen",
    updatecart: "Dit artikel zat al in je winkelwagen. De hoeveelheid is bijgewerkt.",
    multipleprod: "Artikelen toegevoegd aan uw winkelwagen."
  },
  labels: {
    price: "Prijs",
    total: "Totaal",
    actions: {
      continueShopping: "Doorgaan met winkelen",
      goToCart: "Ga naar winkelwagen"
    }
  }
}

export const mtProductCartResource = {
  mtProductCart
}
