
export const mtProductCart = {
  titles: {
    loading: "Updating Cart...",
    addcart: "Item(s) added to your cart",
    updatecart: "This item was already in your cart. The quantity has been updated.",
    multipleprod: "Items were successfully added to your cart."
  },
  labels: {
    price: "Price",
    total: "Total",
    actions: {
      continueShopping: "Continue Shopping",
      goToCart: "Go to Cart"
    }
  }
}

export const mtProductCartResource = {
  mtProductCart
}

