
export const mtProductCart = {
  titles: {
    loading: "Warenkorb wird aktualisiert...",
    addcart: "Artikel zu Ihrem Warenkorb hinzugefügt",
    updatecart: "Dieser Artikel war bereits in Ihrem Einkaufswagen. Die Menge wurde aktualisiert.",
    multipleprod: "Die Artikel wurden Ihrem Warenkorb hinzugefügt."
  },
  labels: {
    price: "Preis",
    total: "Total",
    actions: {
      continueShopping: "Weiter einkaufen",
      goToCart: "Zum Einkaufswagen"
    }
  }
}

export const mtProductCartResource = {
  mtProductCart
}
