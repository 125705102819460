import { Applicable, Priority } from "@spartacus/core";
import { SearchCriteria } from "@spartacus/storefront";

/**
 * Build search criteria strategy.
 */
export abstract class SearchParamsParserStrategy implements Applicable {

  abstract hasMatch(): boolean;

  abstract getPriority(): Priority;

  /**
   * Build search criteria.
   */
  abstract buildSearchCriteria(..._params: any[]): SearchCriteria | undefined;

  abstract buildHashtagForQueryAndFacet(query: string, ..._params: any[]): string;

  abstract buildHashtagForClearAllFacets(..._params: any[]): string;

  abstract buildHashtagForPageNumber(pageNumber: number, ..._params: any[]): string;

  abstract buildHashtagForItemsPerPageNumber(itemsPerPageNo: number, ..._params: any[]): string;

  abstract buildHashtagForSortBy(sortByCode: string, ..._params: any[]): string;

  abstract getCurrentPage(..._params: any[]): number;

  abstract getCurrentPageSize(..._params: any[]): number;

}
