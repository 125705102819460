import { NgModule } from '@angular/core';
import { translationChunksConfig, translations } from "@spartacus/assets";
import { CartValidationWarningsComponent, OrderSummaryComponent } from "@spartacus/cart/base/components";
import { ActiveCartFacade } from "@spartacus/cart/base/root";
import { CheckoutStepsSetGuard } from '@spartacus/checkout/base/components';
import {
  AuthHttpHeaderService,
  AuthService,
  AuthStorageService,
  FeaturesConfig,
  I18nConfig,
  OccConfig,
  provideConfig, RouteLoadStrategy, RoutingConfig,
  SiteContextConfig, UserAddressAdapter, UserAddressService
} from "@spartacus/core";
import { defaultB2bOccConfig } from "@spartacus/setup";
import { CmsPageGuard, defaultCmsContentProviders, layoutConfig, mediaConfig } from "@spartacus/storefront";
import { OAuthService } from "angular-oauth2-oidc";
import { environment } from 'src/environments/environment';
import { MtActiveCartService } from "./custom-module/cart/base/core/facade/mt-active-cart.service";
import { MtOrderSummaryComponent } from "./custom-module/cart/components/cart-shared";
import {
  MtCartValidationWarningsComponent
} from "./custom-module/cart/components/validation/cart-warnings/mt-cart-validation-warnings.component";
import { MtCheckoutStepsSetGuard } from './custom-module/checkout/base/guards/mt-checkout-steps-set.guard';
import { MtCmsPageGuard } from './custom-module/cms-structure/guards/mt-cms-page.guard';
import { MtUnauthorizedRequestHandler } from "./custom-module/core/http-interceptors";
import { aemSiteContextConfig, countryMapBaseSiteIdContextConfig } from './custom-module/core/mt-common-site-context/mt-common-site-context.config';
import { MtOccUserAddressAdapter } from "./custom-module/user/account/occ/mt-occ-user-address.adapter";
import { MtUserAddressService } from "./custom-module/user/account/services/mt-user-address.service";
import { MtAuthHttpHeaderService } from "./custom-module/user/auth/mt-auth-http-header.service";
import { MtAuthService } from "./custom-module/user/auth/mt-auth-service";
import { MtAuthStorageService } from "./custom-module/user/auth/mt-auth-storage.service";
import { mtAuthConfig } from "./custom-module/user/auth/user-auth/mt-auth-config";
import { MtOAuthService } from "./custom-module/user/oauth/mt-oauth-service";

const fallbackLang: any = {
  de_DE: ['de'],
  en_US: ['en'],
  en_GB: ['en'],
  fr_FR: ['fr'],
  nl_NL: ['nl'],
  ca_EN: ['en'],
  it_IT: ['it'],
  default: ['en'],
};

export const enTranslationOverwrites = {
  common: {
    formErrors: {
      required: 'Required field',
      cxInvalidEmail: 'The input is not a valid email address.',
      cxInvalidPassword: 'The password must be more than 6 and less than 25 characters long.',
      cxPasswordsMustMatch: 'The password and its confirm are not the same.',
      mtNoSpecialChars: 'Field {{fieldName}} cannot contain following characters: {{forbiddenChars}}',
      namesLength:'{{fieldName}} should be between 1 and {{maxLength}} characters.',
      maxlength: '{{fieldName}} cannot be shorter than 1 and longer than {{maxLength}} characters.',
      maxlengthEmail: 'Please enter valid email address. {{fieldName}} cannot be shorter than {{minLength}} and longer than {{maxLength}} characters.',
      onlyNumbers: 'Enter value in numbers.',
      lengthBetween: '{{fieldName}} should be between {{minLength}} and {{maxLength}} characters.',
      pattern: 'Invalid Field',
      postalCodeInvalid: 'The Postal Code/PO Box Postal code must be in the format {{pattern}}.',
      date: {
        required: 'Required field',
        min: 'Delivery Date cannot be in the past.',
        max: 'Date cannot be after {{max}}',
        pattern: 'Use dateformat yyyy-mm-dd',
        invalid: 'Use a valid date',
        mtOnWeekends: 'Delivery on weekends not possible. Please select another date.',
      },
    },
    httpHandlers: {
      badRequestOldPasswordIncorrect: 'Current password is incorrect'
    }
  },
  address:{
    addressForm:{
      userAddressUpdateSuccess: "The address has been saved. You can review it in the last checkout step.",
      userAddressAddSuccess:"Address has been added successfully"
    }
  },
  user: {
    authMessages: {
      signedOutSuccessfully: 'You successfully logged out',
    },
  },
  httpHandlers: {
    badRequestOldPasswordIncorrect: 'Current password is incorrect'
  },
  product: {
    productList: {
      sortBy: 'Sort By',
      details:'Click for Details'
    },
    productSummary: {
      id: 'Material #:',
      title: "{{title}}"
    },
    TabPanelContainer: {
      tabs: {
        ProductDetailsTabComponent: "Specifications",
        ProductDocumentsTabComponent: "Documentation",
        accesoriesProductReferences: "Accessories",
        consumablesProductReferences: "Consumables"
      }
    }
  }
};

export const deTranslationOverwrites = {
  common: {
    formErrors: {
      required: 'Pflichtfeld',
      cxInvalidEmail: 'Die Eingabe ist keine gültige E-Mail-Adresse.',
      cxInvalidPassword: 'Das Passwort muss länger als 6 und kürzer als 25 Zeichen sein.',
      cxPasswordsMustMatch: 'Das Passwort und die Bestätigung sind nicht gleich.',
      mtNoSpecialChars: 'Feld {{fieldName}} darf folgende Zeichen nicht enthalten: {{forbiddenChars}}',
      namesLength:'{{fieldName}} sollte zwischen 1 und {{maxLength}} Zeichen lang sein.',
      maxlength:'{{fieldName}} darf nicht kürzer als 1 und nicht länger als {{maxLength}} Zeichen sein.',
      maxlengthEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein. Die {{fieldName}} darf nicht kürzer als {{minLength}} und nicht länger als {{maxLength}} Zeichen sein.',
      onlyNumbers: 'Geben Sie den Wert in Zahlen ein.',
      lengthBetween: 'Die {{fieldName}} muss zwischen {{minLength}} und {{maxLength}} Zeichen lang sein.',
      postalCodeInvalid: 'Die Postleitzahl muss das Format {{pattern}} haben.',
      date: {
        required: 'Pflichtfeld',
        min: 'Das Lieferdatum darf nicht in der Vergangenheit liegen.',
        max: 'Date cannot be after {{max}}',
        pattern: 'Use dateformat yyyy-mm-dd',
        invalid: 'Use a valid date',
        mtOnWeekends: 'Lieferung am Wochenende nicht möglich. Bitte wählen Sie ein anderes Datum aus.',
      },
    },
    httpHandlers: {
      badRequestOldPasswordIncorrect: 'Das aktuelle Passwort ist falsch',
    }
  },
  address:{
    addressForm:{
      userAddressUpdateSuccess: "Die Adresse wurde gespeichert. Sie können sie im letzten Schritt der Kaufabwicklung überprüfen.",
      userAddressAddSuccess:"Adresse wurde erfolgreich hinzugefügt"
    }
  },
  user: {
    authMessages: {
      signedOutSuccessfully: 'Sie haben sich erfolgreich abgemeldet',
    },
  },
  product: {
    productList: {
      sortBy: 'Sortiert nach',
      showMore: 'Zeige mehr',
      details:'Klicken für Details'
    },
    productSummary: {
      id: 'Material #:',
      title: "{{title}}"
    },
    TabPanelContainer: {
      tabs: {
        ProductDetailsTabComponent: "Spezifikationen",
        ProductDocumentsTabComponent: "Dokumentation",
        accesoriesProductReferences: "Zubehör",
        consumablesProductReferences: "Verbrauchsmaterial"
      }
    }
  }
};

export const frTranslationOverwrites = {
  common: {
    formErrors: {
      required: 'Champ obligatoire',
      cxInvalidEmail: "L'entrée n'est pas une adresse e-mail valide.",
      cxInvalidPassword: 'Le mot de passe doit être plus long que 6 et moins de 25 caractères.',
      cxPasswordsMustMatch: 'Le mot de passe et sa confirmation ne sont pas identiques.',
      mtNoSpecialChars: 'Champ {{fieldName}} ne peut pas contenir les caractères suivants: {{forbiddenChars}}',
      namesLength:'Le {{fieldName}} doit être entre 1 et {{maxLength}} caractères.',
      maxlength: '{{fieldName}} ne peut pas être plus courte que 1 et plus longue que {{maxLength}} caractères.',
      maxlengthEmail: "Veuillez saisir une adresse e-mail valide. L'{{fieldName}}  ne peut pas être plus courte que {{minLength}} et plus longue que {{maxLength}} caractères.",
      onlyNumbers: 'Entrez la valeur en chiffres.',
      lengthBetween: 'Le {{fieldName}} doit comporter entre {{minLength}} et {{maxLength}} caractères.',
      postalCodeInvalid: 'Le code postal doit être dans le format {{pattern}}.',
      date: {
        required: 'Champ obligatoire',
        min: 'La date de livraison ne peut pas être antérieure.',
        max: 'Date cannot be after {{max}}',
        pattern: 'Use dateformat yyyy-mm-dd',
        invalid: 'Use a valid date',
        mtOnWeekends: 'Livraison le week-end impossible. Veuillez sélectionner une autre date.',
      },
    },
    httpHandlers: {
      badRequestOldPasswordIncorrect: 'Ce mot de passe est incorrect',
    }
  },
  address:{
    addressForm:{
      userAddressUpdateSuccess: "L'adresse a été enregistrée. Vous pouvez la consulter lors de la dernière étape de la commande.",
      userAddressAddSuccess:"L’adresse a été ajoutée avec succès"
    }
  },
  user: {
    authMessages: {
      signedOutSuccessfully: 'Vous vous êtes déconnecté avec succès',
    },
  },
  product: {
    productList: {
      sortBy: 'Trier par',
      showMore: 'Afficher plus',
      details:'Cliquez pour plus de détails'
    },
    productSummary: {
      id: 'Material #:',
      title: "{{title}}"
    },
    TabPanelContainer: {
      tabs: {
        ProductDetailsTabComponent: "Spécifications",
        ProductDocumentsTabComponent: "Documentation",
        accesoriesProductReferences: "Accessories",
        consumablesProductReferences: "Consommables"
      }
    }
  }
};

export const nlTranslationOverwrites = {
  common: {
    formErrors: {
      required: 'Verplicht veld',
      cxInvalidEmail: 'De invoer is geen geldig e-mailadres',
      cxInvalidPassword: 'Het wachtwoord moet langer zijn dan 6 en korter dan 25 tekens.',
      cxPasswordsMustMatch: 'Het wachtwoord en de bevestiging komen niet overeen.',
      mtNoSpecialChars: 'Veld {{fieldName}} mag de volgende tekens niet bevatten: {{forbiddenChars}}',
      namesLength:'{{fieldName}} moet tussen 1 en {{maxLength}} tekens lang zijn.',
      maxlength: '{{fieldName}} kan niet korter zijn dan 1 en niet langer dan {{maxLength}} tekens.',
      maxlengthEmail: 'Voer een geldig e-mailadres in. {{fieldName}} kan niet korter zijn dan {{minLength}} en niet langer dan {{maxLength}} tekens.',
      onlyNumbers: 'Voer de waarde in cijfers in.',
      lengthBetween: 'Het {{fieldName}} moet tussen de {{minLength}} en {{maxLength}} tekens lang zijn.',
      postalCodeInvalid: 'De postcode moet het formaat {{pattern}} hebben.',
      date: {
        required: 'Verplicht veld',
        min: 'De leverdatum mag niet in het verleden liggen.',
        max: 'Date cannot be after {{max}}',
        pattern: 'Use dateformat yyyy-mm-dd',
        invalid: 'Use a valid date',
        mtOnWeekends: 'Levering in het weekend niet mogelijk. Selecteer een andere datum.',
      },
    },
    httpHandlers: {
      badRequestOldPasswordIncorrect: 'Huidige wachtwoord is onjuist',
    }
  },
  address:{
    addressForm:{
      userAddressUpdateSuccess: "Het adres is opgeslagen. Je kunt het bekijken in de laatste stap van het afrekenen.",
      userAddressAddSuccess:"het adres is succesvol toegevoegd"
    }
  },
  user: {
    authMessages: {
      signedOutSuccessfully: 'U bent succesvol uitgelogd',
    },
  },
  product: {
    productList: {
      sortBy: 'Sorteer op',
      showMore: 'Meer weergeven',
      details:'Klik hier voor meer details'
    },
    productSummary: {
      id: 'Material #:',
      title: "{{title}}"
    },
    TabPanelContainer: {
      tabs: {
        ProductDetailsTabComponent: "Specificaties",
        ProductDocumentsTabComponent: "Documentatie",
        accesoriesProductReferences: "Accessoires",
        consumablesProductReferences: "Verbruiksartikelen"
      }
    }
  }
};

export const itTranslationOverwrites = {
  common: {
    formErrors: {
      required: 'Campo obbligatorio',
      cxInvalidEmail: 'Il valore inserito non è un indirizzo email valido.',
      cxInvalidPassword: 'La password deve contenere più di 6 e meno di 25 caratteri.',
      cxPasswordsMustMatch: 'Le password inserite non corrispondono.',
      mtNoSpecialChars: 'Il campo {{fieldName}} non può contenere i seguenti caratteri: {{forbiddenChars}}',
      namesLength:'{{fieldName}} deve contenere da 1 a {{maxLength}} caratteri.',
      maxlength: '{{fieldName}} non può essere più corto di 1 e più lungo di {{maxLength}} caratteri.',
      maxlengthEmail: 'Inserisci indirizzo email valido. {{fieldName}} non può essere più corto di {{minLength}} e più lungo di {{maxLength}} caratteri.',
      onlyNumbers: 'Immettere il valore in numeri..',
      lengthBetween: '{{fieldName}} deve essere compreso tra {{minLength}} e {{maxLength}} caratteri.',
      pattern: 'Campo non valido',
      postalCodeInvalid: 'Il codice postale e il codice postale della casella postale devono essere nel formato {{pattern}}.',
      date: {
        required: 'Campo obbligatorio',
        min: 'La data di consegna non può essere nel passato.',
        max: 'La data non può essere successiva a {{max}}',
        pattern: 'Utilizza il formato data aaaa-mm-gg',
        invalid: 'Utilizza una data valida',
        mtOnWeekends: 'La consegna nei fine settimana non è possibile. Seleziona un\'altra data.',
      },
    },
    httpHandlers: {
      badRequestOldPasswordIncorrect: 'Password corrente non è corretta'
    }
  },
  address:{
    addressForm:{
      userAddressUpdateSuccess: "L'indirizzo è stato salvato. Puoi rivederlo nell'ultimo passaggio del checkout.",
      userAddressAddSuccess:"L'indirizzo è stato aggiunto con successo"
    }
  },
  user: {
    authMessages: {
      signedOutSuccessfully: 'Ti sei disconnesso con successo',
    },
  },
  httpHandlers: {
    badRequestOldPasswordIncorrect: 'Password corrente non è corretta'
  },
  product: {
    productList: {
      sortBy: 'Ordina per',
      details:'Clicca per vedere i dettagli'
    },
    productSummary: {
      id: 'N. di materiale:',
      title: "{{title}}"
    },
    TabPanelContainer: {
      tabs: {
        ProductDetailsTabComponent: "Specifiche",
        ProductDocumentsTabComponent: "Documentazione",
        accesoriesProductReferences: "Accessori",
        consumablesProductReferences: "Consumabili"
      }
    }
  }
};

@NgModule({
  declarations: [],
  imports: [
  ],
  providers: [
    { provide: OAuthService, useClass: MtOAuthService },
    { provide: AuthService, useClass: MtAuthService },
    { provide: AuthHttpHeaderService, useClass: MtAuthHttpHeaderService },
    { provide: AuthStorageService, useClass: MtAuthStorageService },
    { provide: MtUnauthorizedRequestHandler, useClass: MtUnauthorizedRequestHandler },
    { provide: CmsPageGuard, useClass: MtCmsPageGuard },
    { provide: CartValidationWarningsComponent, useClass: MtCartValidationWarningsComponent },
    { provide: OrderSummaryComponent, useClass: MtOrderSummaryComponent },
    { provide: UserAddressService, useClass: MtUserAddressService },
    { provide: UserAddressAdapter, useClass: MtOccUserAddressAdapter },
    {
      provide: CheckoutStepsSetGuard, useClass: MtCheckoutStepsSetGuard,
    },
    { provide: ActiveCartFacade, useClass: MtActiveCartService },

    provideConfig(layoutConfig), provideConfig(mediaConfig), ...defaultCmsContentProviders, provideConfig(<OccConfig>{
      backend: {
        occ: {
          baseUrl: environment.occBaseUrl
        }
      },
    }),

    provideConfig(mtAuthConfig),

    provideConfig(<RoutingConfig>{
      routing: { loadStrategy: RouteLoadStrategy.ONCE },
    }),

    provideConfig(aemSiteContextConfig),

    provideConfig(countryMapBaseSiteIdContextConfig),

    provideConfig(<SiteContextConfig>{
      context: {
        urlParameters: ['country', 'languageIso'],
        baseSite: ['SPARTACUS_MTMO_US_CP_Site', 'SPARTACUS_MT_D_Site', 'SPARTACUS_MT_BE_Site', 'SPARTACUS_MT_NL_Site', 'SPARTACUS_MT_CA_Site', 'SPARTACUS_MT_UK_Site', 'SPARTACUS_MT_CH_Site'],
        currency: ['USD', 'EUR', 'CAD', 'GBP', 'CHF'],
        language: ['en_US', 'en_GB', 'de_DE', 'fr_FR', 'nl_NL', 'it_IT'],
        languageIso: ['en', 'fr', 'de', 'nl', 'it'],
        country: ['us', 'de', 'be', 'nl', 'ca', 'gb', 'ch']
      },
    }),

    provideConfig(<FeaturesConfig>{
      features: {
        level: '5.1'
      }
    }),

    provideConfig(defaultB2bOccConfig),

    provideConfig(<I18nConfig>{
      i18n: {
        resources: {
          en: translations, //TODO: we need to add de & fr
        },
        chunks: translationChunksConfig,
        fallbackLang,
        debug: false
      },
    }),

    { provide: MtUnauthorizedRequestHandler, useClass: MtUnauthorizedRequestHandler },

  ]
})
export class SpartacusConfigurationModule { }
