import { Config } from "@spartacus/core";

export abstract class AemAdapterSiteContextConfig extends Config {
  aemSiteContext?: {
    [keyAemSiteContext: string]: {
      country?: string;
      language: string;
      currency: string;
    }
  };

  defaultLanguageIso?: {
    [country: string]: string
  };

  defaultCountry?: string;
  defaultLanguage?: string;
  defaultCurrency?: string;
}
