
export const main = {
  menu: {
    label: 'Main Menu'
  }
}

export const homepage = {
  main
}
