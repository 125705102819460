import { APP_BASE_HREF, isPlatformBrowser, PlatformLocation } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { ApplicationRef, DoBootstrap, Inject, Injector, NgModule, PLATFORM_ID } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from "@angular/router";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { ConfigModule, SiteContextConfig, SiteContextParamsService, SiteContextUrlSerializer, WindowRef } from "@spartacus/core";
import { AppComponent } from './app.component';
import { appBaseHrefFactory, PREFIX_APP_BASE_HREF } from "./app.provider";
import { MtMiniCartComponent, MtMiniCartModule } from "./spartacus/custom-module/cart/mini-cart";
import {
  AemAdapterSiteContextModule
} from "./spartacus/custom-module/core/aem-adapter-site-context/aem-adapter-site-context.module";
import {
  AemAdapterSiteContextService
} from "./spartacus/custom-module/core/aem-adapter-site-context/aem-adapter-site-context.service";
import {
  CountrySiteContextModule
} from "./spartacus/custom-module/core/country-site-context/country-site-context.module";
import { MtHttpInterceptorsModule } from "./spartacus/custom-module/core/mt-http-interceptors.module";
import { HomepageModule } from "./spartacus/custom-module/homepage/homepage.module";
import { MtProductAtpModule } from "./spartacus/custom-module/product/product-availability/components/mt-product-atp";
import {
  MtFacetListComponent
} from "./spartacus/custom-module/product/product-list/components/mt-facet-list/mt-facet-list.component";
import { MtProductListComponent } from "./spartacus/custom-module/product/product-list/components/mt-product-list";
import {
  MtProductListComponentsModule
} from "./spartacus/custom-module/product/product-list/mt-product-list-components.module";

import { MtCartDetailsModule } from "./spartacus/custom-module/cart/components/cart-details/mt-cart-details.module";
import {
  MtCartProceedToCheckoutModule
} from "./spartacus/custom-module/cart/components/cart-proceed-to-checkout/mt-cart-proceed-to-checkout.module";
import { MtCartSharedModule } from "./spartacus/custom-module/cart/components/cart-shared";
import { MtCartTotalsModule } from "./spartacus/custom-module/cart/components/cart-totals/mt-cart-totals.module";
import { MtCartBaseComponentsModule } from "./spartacus/custom-module/cart/components/mt-cart-base-components.module";
import {
  CartValidationComponentsModule
} from "./spartacus/custom-module/cart/components/validation/cart-validation-components.module";
import { MtCartModule } from "./spartacus/custom-module/cart/mt-cart.module";
import {
  MtCheckoutDeliveryAddressModule
} from "./spartacus/custom-module/checkout/base/components/checkout-delivery-address/mt-checkout-delivery-address.module";
import { MtCheckoutDeliveryMethodModule } from "./spartacus/custom-module/checkout/base/components/checkout-delivery-method/mt-checkout-delivery-method.module";
import { MtCheckoutPaymentDetailsModule } from "./spartacus/custom-module/checkout/base/components/checkout-payment-details/mt-checkout-payment-details.module";
import { MtCheckoutReviewSubmitModule } from "./spartacus/custom-module/checkout/base/components/mt-checkout-review-submit/mt-checkout-review-submit.module";
import { MtOrderConfirmationModule } from "./spartacus/custom-module/checkout/base/components/mt-order-confirmation/mt-order-confirmation.module";
import { MtPlaceOrderModule } from "./spartacus/custom-module/checkout/base/components/mt-place-order/mt-place-order.module";
import { MtCheckoutRootModule } from "./spartacus/custom-module/checkout/root/mt-checkout-root.module";
import { MtParagraphModule } from "./spartacus/custom-module/cms-structure/mt-paragraph/mt-paragraph.module";
import { LanguageisoSiteContextModule } from "./spartacus/custom-module/core/languageiso-site-context/languageiso-site-context.module";
import { MtCommonSiteContextModule } from "./spartacus/custom-module/core/mt-common-site-context/mt-common-site-context.module";
import {
  MtSiteContextOccModule
} from "./spartacus/custom-module/core/occ/adapters/site-context/mt-site-context-occ.module";
import {
  MtUserRegistrationFeatureModule
} from "./spartacus/custom-module/organisation/user-registration/mt-user-registration-feature.module";
import { MtZoovuModule } from "./spartacus/custom-module/product/product-details/components/mt-zoovu/mt-zoovu.module";
import {
  MtProductDetailsFeatureModule
} from "./spartacus/custom-module/product/product-details/mt-product-details-feature.module";
//import { SwiftypeSeoModule } from "./spartacus/custom-module/product/swiftype-seo";
import { MtProductRouteModule } from "./spartacus/custom-module/routing/mt-product-route.module";
import { MtSeoModule } from "./spartacus/custom-module/seo/mt-seo.module";
import { MtTmsModule } from "./spartacus/custom-module/tms/mt-tms.module";
import {
  MtAddressFormModule
} from "./spartacus/custom-module/user/account/components/address-form/mt-address-form.module";
import {
  MtDeactivateAccountModalModule
} from "./spartacus/custom-module/user/account/components/deactivate-account-modal/mt-deactivate-account-modal.module";
import {
  MtDeactivateAccountModule
} from "./spartacus/custom-module/user/account/components/deactivate-account/mt-deactivate-account.module";
import { MtForgotPasswordModule } from "./spartacus/custom-module/user/account/components/forgot-password";
import { MtLoginComponent, MtLoginModule } from "./spartacus/custom-module/user/account/components/login";
import { MtLoginFormModule } from "./spartacus/custom-module/user/account/components/login-form";
import {
  MtAccountMenuLinksComponent,
  MtAccountMenuLinksModule
} from "./spartacus/custom-module/user/account/components/menu-links";
import { MtNewsFeedLargeModule } from "./spartacus/custom-module/user/account/components/news-feed-large";
import { MtNewsFeedSmallModule } from "./spartacus/custom-module/user/account/components/news-feed-small";
import {
  MtNewsletterSubscriptionsModule
} from "./spartacus/custom-module/user/account/components/newsletter-subscriptions";
import {
  MtOrdersRedirectDialogComponent
} from "./spartacus/custom-module/user/account/components/orders-redirect-dialog/mt-orders-redirect-dialog.component";
import {
  MtOrdersRedirectDialogModule
} from "./spartacus/custom-module/user/account/components/orders-redirect-dialog/mt-orders-redirect-dialog.module";
import { MtOrgInfoModule } from "./spartacus/custom-module/user/account/components/org-info";
import { MtPersonalLibraryModule } from "./spartacus/custom-module/user/account/components/personal-library";
import { MtReactivateAccountModule } from "./spartacus/custom-module/user/account/components/reactivate-account";
import { MtResetPasswordModule } from "./spartacus/custom-module/user/account/components/reset-password";
import {
  MtSoldtoSelectionModule
} from "./spartacus/custom-module/user/account/components/soldto-selection/mt-soldto-selection.module";
import { MtAccountTilesModule } from "./spartacus/custom-module/user/account/components/tiles";
import { MtUpdatePasswordModule } from "./spartacus/custom-module/user/account/components/update-password";
import { MtUpdateProfileModule } from "./spartacus/custom-module/user/account/components/update-profile";
import { MtLogoutModule } from "./spartacus/custom-module/user/mt-logout/mt-logout.module";
import { MtUserModule } from "./spartacus/custom-module/user/mt-user.module";
import { LocalStorageModule } from "./spartacus/custom-module/util/local-storage/local-storage.module";
import { LocalStorageService } from "./spartacus/custom-module/util/local-storage/local-storage.service";
import { SpartacusModule } from './spartacus/spartacus.module';
import {MtPlpComponent} from "./spartacus/custom-module/product/product-list/components/mt-plp/mt-plp.component";
import {MtCmsUtilService} from "./spartacus/custom-module/cms-structure/services/mt-cms-util-service";
import { PageNotFoundErrorPageModule } from "./spartacus/custom-module/page-not-found/components/page-not-found-error-page/page-not-found-error-page.module";


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    //MtRoutingModule,
    //MtRoutingModule,
    RouterModule.forRoot([]),
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    CountrySiteContextModule.forRoot(),
    LanguageisoSiteContextModule.forRoot(),
    MtCommonSiteContextModule.forRoot(),
    AemAdapterSiteContextModule.forRoot(),
    SpartacusModule,
    MtUserRegistrationFeatureModule,
    MtLoginModule,
    MtLoginFormModule,
    MtHttpInterceptorsModule,
    MtProductListComponentsModule,
    MtProductDetailsFeatureModule,
    MtOrgInfoModule,
    MtAccountTilesModule,
    MtNewsFeedSmallModule,
    MtNewsFeedLargeModule,
    MtPersonalLibraryModule,
    MtDeactivateAccountModule,
    MtDeactivateAccountModalModule,
    MtUpdatePasswordModule,
    MtResetPasswordModule,
    MtForgotPasswordModule,
    MtSoldtoSelectionModule,
    MtUserModule,
    MtUpdateProfileModule,
    MtAccountMenuLinksModule,
    MtOrdersRedirectDialogModule,
    MtReactivateAccountModule,
    MtNewsletterSubscriptionsModule,
    HomepageModule,
    MtProductAtpModule,
    MtTmsModule,
    ConfigModule.withConfig({
      skipLinks: []
    }),
    NgbModule,
    MtSiteContextOccModule,
    MtMiniCartModule,
    MtCartTotalsModule,
    MtCartModule,
    MtCartBaseComponentsModule,
    CartValidationComponentsModule,
    MtCartSharedModule,
    MtCartDetailsModule,
    MtCartProceedToCheckoutModule,
    MtCheckoutRootModule,
    MtCheckoutDeliveryAddressModule,
    MtCheckoutPaymentDetailsModule,
    MtAddressFormModule,
    MtCheckoutDeliveryMethodModule,
    //SwiftypeSeoModule,
    MtSeoModule,
    LocalStorageModule,
    MtPlaceOrderModule,
    MtCheckoutReviewSubmitModule,
    MtOrderConfirmationModule,
    MtParagraphModule,
    MtLogoutModule,
    MtZoovuModule,
    PageNotFoundErrorPageModule,


    ConfigModule.withConfig({
      routing: {
        internal: [
          '/**', // internal

          //'!/us/en/home/site_content/contact_us.html' // for external routes
          '!/*/*/home/site_content/**', // for external routes
          '!/*/*/home/perm-lp/**',
          '!/int/**',
          '!/favicon.ico'
        ]
      }
    }),
    MtProductRouteModule
  ],

  providers: [{
    provide: PREFIX_APP_BASE_HREF,
    useValue: '/shop'
  },
  {
    provide: APP_BASE_HREF,
    useFactory: appBaseHrefFactory,
    deps: [PREFIX_APP_BASE_HREF, SiteContextConfig, SiteContextParamsService, PlatformLocation, SiteContextUrlSerializer, AemAdapterSiteContextService, LocalStorageService, PLATFORM_ID]
  },
  {
    provide: MtCmsUtilService,
    useFactory: () => new MtCmsUtilService()
  }],

  //bootstrap: [AppComponent]
})
export class AppModule implements DoBootstrap {

  constructor(
    injector: Injector,
    @Inject(PLATFORM_ID) protected platformId: any,
    protected windowRef: WindowRef) {
    if (isPlatformBrowser(this.platformId)) {


      customElements.define(
        'exp-mt-login-component',
        createCustomElement(MtLoginComponent, { injector })
      );


      customElements.define(
        'exp-mt-product-list-component',
        createCustomElement(MtProductListComponent, { injector })
      );

      customElements.define(
        'exp-mt-facet-list-component',
        createCustomElement(MtFacetListComponent, { injector })
      );

      customElements.define(
        'exp-mt-orders-redirect-dialog',
        createCustomElement(MtOrdersRedirectDialogComponent, { injector })
      );

      customElements.define(
        'exp-mt-account-menu-links-component',
        createCustomElement(MtAccountMenuLinksComponent, { injector })
      );

      customElements.define(
        'exp-mt-mini-cart',
        createCustomElement(MtMiniCartComponent, { injector })
      );

      // TODO: Remove this after ETeam change the name of the component
      customElements.define(
        'ext-mini-cart',
        createCustomElement(MtMiniCartComponent, { injector })
      );

      customElements.define(
        'exp-mt-plp-component',
        createCustomElement(MtPlpComponent, { injector })
      );
    }
  }

  ngDoBootstrap(appRef: ApplicationRef): void {
    if (this.windowRef.document.getElementById('standalone')) {
      appRef.bootstrap(AppComponent);
    }
  }

}
