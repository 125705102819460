import { Product } from "@spartacus/core";
import { MtProductDocument } from "./mt-product-document.model";
import {MtPriceModel} from "./mt-price.model";

export interface MtProduct extends Product {
  technicalAttributes: TechnicalAttribute[];
  plantData: PlantData;
  cpv: MT_CPV_STATUS;
  tagLine: string;
  documents: MtProductDocument[];
  price?: MtPriceModel;
  seoNormalizedName?: string;
  generalItemCategoryGroup?: string;
  badgeIcons?: BadgeIcon[];
}

export interface BadgeIcon{
  altText : string;
  format : string;
  galleryIndex : number;
  url : string;
}

export interface TechnicalAttribute {
  code: string;
  name: string;
  values: string[];
}

export interface Benefit {
  number: number;
  headline: string;
  text: string;
  //TODO: image?
}

export enum MT_CPV_STATUS {
  SALES = 'SALES',
  QUOTE = 'QUOTE',
  SALESANDQUOTE = 'SALESANDQUOTE'
}

export interface PlantData {
  countryOfOrigin: string;
  customsTariffNumber: number;
  hazardousMaterialNumber: number;
}

