/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { NgModule } from '@angular/core';
import { HttpErrorHandler } from '@spartacus/core';
import { MtUnauthorizedRequestHandler } from "./http-interceptors";
import { MtOrganizationUserRegistrationConflictHandler } from './http-interceptors/conflict/mt-organization-user-registration-conflict.handler';
import { MtBadRequestHandler } from './http-interceptors/unauthorized/mt-bad-request.handler';


@NgModule({
  providers: [
    {
      provide: HttpErrorHandler,
      useExisting: MtUnauthorizedRequestHandler,
      multi: true,
    },
    {
      provide: HttpErrorHandler,
      useExisting: MtOrganizationUserRegistrationConflictHandler,
      multi: true,
    },
    {
      provide: HttpErrorHandler,
      useExisting: MtBadRequestHandler,
      multi: true,
    }
  ],

})
export class MtHttpInterceptorsModule { }
