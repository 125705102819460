/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Action } from '@ngrx/store';
import { CmsNavigationNode } from '@spartacus/core';
import { MtNavigationNode } from '../../model/mt-navigation-node.model';


export const LOAD_NAVIGATION_NODES = '[Navigation] Load navigation nodes';
export const GET_CURRENT_NAVIGATION_NODES = '[Navigation] Get current navigation nodes';
export const SUCCESS_LOAD_NAVIGATION_NODES = '[Navigation] Success load navigation nodes';
export const ERROR_NAVIGATION_NODES = '[Navigation] Error navigation nodes';
export const NAVIGAT_TO_NODE = '[Navigation] Navigate to node';


export class NavigateToNode implements Action {
  readonly type = NAVIGAT_TO_NODE;
  constructor(
    public payload: { nodeCode: string; }
  ) { }
}

export class LoadNavigationNodes implements Action {
  readonly type = LOAD_NAVIGATION_NODES;
  constructor(public payload: { cmsNodes: CmsNavigationNode, selectedNodeCode?: string }) { }
}

export class SuccessLoadNavigationNodes implements Action {
  readonly type = SUCCESS_LOAD_NAVIGATION_NODES;
  constructor(public payload: { mtNodesMap: Map<string, MtNavigationNode>, selectedNodeCode?: string }) { }
}

export class GetCurrentNavigationNodes implements Action {
  readonly type = GET_CURRENT_NAVIGATION_NODES;
  constructor() { }
}

export class ErrorNavigationNodes implements Action {
  readonly type = ERROR_NAVIGATION_NODES;
  constructor(public payload: { error: any }) { }
}

// action types
export type NavigationActions =
  | NavigateToNode
  | LoadNavigationNodes
  | SuccessLoadNavigationNodes
  | GetCurrentNavigationNodes
  | ErrorNavigationNodes;
