import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MtLogoutGuard } from './mt-logout.guard';
import { LogoutGuard, LogoutModule, PageLayoutComponent, PageLayoutModule } from '@spartacus/storefront';
import { MtCmsPageGuard } from '../../cms-structure/guards/mt-cms-page.guard';

@NgModule({
  providers:[
  { provide: LogoutGuard, useExisting: MtLogoutGuard },
  ],
  imports: [
    LogoutModule,
    PageLayoutModule,
    RouterModule.forChild([
      {
        // @ts-ignore
        path: null,
        canActivate: [MtLogoutGuard, MtCmsPageGuard],
        component: PageLayoutComponent,
        data: { cxRoute: 'logout' },
      },

    ]),
  ],
})

export class MtLogoutModule { }