export const mtProductDetailsTab = {
  texts: {
    browseProductFamily: 'Produktfamilie durchsuchen',
    productSpecificationsTitle: 'Produktspezifikationen',
    functionalSpecificationsTitle: 'Logistische Spezifikationen',
    featuresHeadline:'Eigenschaften',
    descHeadline:'Beschreibung',
    plantData: {
      countryOfOrigin: 'Country of origin',
      customsTariffNumber: 'Customs tariff number',
      hazardousMaterialNumber: 'Hazardous material number'
    }
  }
}

export const mtProductInfo = {
  labels: {
    showMore: 'Mehr anzeigen'
  }
}

export const mtProductRecommendations = {
  title: 'Oft zusammen gekauft mit'
}

export const mtProductDetailsResource = {
  mtProductDetailsTab, mtProductRecommendations, mtProductInfo
}
