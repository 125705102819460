/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const cart = {
  cartDetails: {
    id: 'ID',
    proceedToCheckout: 'Bestellung',
    cartName: 'Cart #{{code}}',
    cartPageTitle: 'Ihr Warenkorb',
    loginForCheckout: 'Zum Bestellen einloggen',
    registerForCheckout: 'Registrieren',
    checkoutAsAGuest: 'Als Gast bestellen',
    invoice: 'Rechnung (nur für Benutzer mit registriertem Geschäftskonto)',
  },
  cartItems: {
    id: 'Artikelnummer',
    description: 'Description',
    item: 'Item',
    itemPrice: 'Item price',
    quantity: 'Menge',
    quantityTitle:
      'The quantity represents the total number of this item in your cart.',
    total: 'Gesamt',
    actions: 'Actions',
    cartTotal: 'Cart total ({{count}} item)',
    cartTotal_other: 'Cart total ({{count}} items)',
    itemRemoved: 'Selected item has been removed. Cart total has been updated.',
    caption: 'Shopping cart contents.',
    itemStandardPrice: 'Artikel Standardpreis',
    itemNetPrice: 'Artikel Nettopreis',
  },
  orderCost: {
    orderSummary: 'Bestellungsübersicht',
    subtotal: 'Subtotal after discounts:',
    shipping: 'Shipping:',
    estimatedShipping: 'Estimated shipping:',
    discount: 'You saved:',
    salesTax: 'Sales Tax:',
    grossTax: 'The order total does not include tax of',
    grossIncludeTax: 'The order total includes tax of',
    total: 'Gesamt',
    toBeDetermined: 'TBD',
  },
  voucher: {
    coupon: 'Have a coupon?',
    coupon_other: 'Coupon codes',
    couponLabel: 'Enter a promo code here',
    apply: 'Verifizieren',
    placeholder: 'Promo code',
    applyVoucherSuccess: '{{voucherCode}} has been applied.',
    removeVoucherSuccess: '{{voucherCode}} has been removed.',
    anchorLabel: 'Enter or remove your coupon code',
    vouchersApplied: 'Applied coupons',
    availableCoupons: 'Available coupons',
    availableCouponsLabel: 'You can add these coupons to this order.',
    enterVoucherCode: 'Geben Sie Ihren Gutscheincode ein',
    lessThanTreshold: 'Der gültige Mindestbestellwert beträgt {{value}}.',
    valueDeducted: 'Der Gutscheinrabatt wurde abgezogen. Der endgültige Betrag wird in der Bestellübersicht angezeigt',
    voucherCode: 'Ihr Gutscheincode',
    voucherCodeNotecognized: 'Gutscheincode nicht erkannt. Bitte überprüfen Sie den Code und versuchen Sie es erneut.',
    anotherCampaign: 'Sie können den Gutscheincode nicht verwenden, wenn Sie an einer anderen Aktion teilnehmen.',
    voucherAlreadyUsed: 'Der Gutscheincode wurde bereits verwendet.',
    voucherGreater: 'Ihr Bestellwert ist geringer als Ihr Gutscheinwert. Sie werden den Unterschied verlieren.',
    productSpecificCampaign: 'Kein Produkt ist Teil der produktspezifischen Gutscheinaktion.',
    voucherBenefit: 'Gutscheinrabatt',
  },
  saveForLaterItems: {
    itemTotal: 'Saved for later ({{count}} item)',
    itemTotal_other: 'Saved for later ({{count}} items)',
    cartTitle: 'Cart',
    saveForLater: 'Save For Later',
    moveToCart: 'Move To Cart',
    stock: 'Stock',
    forceInStock: 'In Stock',
  },
  clearCart: {
    clearCart: 'Clear Cart',
    clearingCart: 'Clearing Cart...',
    cartClearedSuccessfully: 'Active cart cleared successfully.',
    areYouSureToClearCart: 'Are you sure you want to clear this cart?',
    allItemsWillBeRemoved: 'All items in your active cart will be removed.',
  },
  validation: {
    cartEntriesChangeDuringCheckout:
      'During checkout we found problems with your entries. Please review your cart.',
    cartEntryRemoved:
      '{{name}} was removed from the cart due to being out of stock.',
    productOutOfStock:
      '{{name}} has been removed from the cart due to insufficient stock.',
    lowStock: 'Quantity has reduced to {{quantity}} due to insufficient stock.',
    reviewConfiguration:
      'Resolve the issues in the configuration for cart entry first.',
    pricingError:
      'Configurator pricing is currently not available. Checkout/quote submission is not possible. Please try again later.',
    unresolvableIssues:
      'The product configuration requires additional entries in the back end. As a result, you cannot proceed. Please contact support.',
    inProgress: 'In Bearbeitung',
  },
  orderSummary: {
    infoMessage: 'Die endgültigen Versand- und Bearbeitungsgebühren sind in der Bestellrechnung aufgeführt.',
    standardPrice: 'Standardpreis:',
    discounts: 'Rabatte:',
    yourNetPrice: 'Ihr Nettopreis:',
    quantity: 'Anzahl:',
    voucherBenefit: 'Gutscheinrabatt:',
    additionalInfo: 'Im nächsten Schritt werden Sie zur Paymetric-Website weitergeleitet, um Ihre Kreditkarte zu autorisieren',
  },
  guestMessages: {
    pleaseLogin: 'Bitte melden Sie sich an, wenn Sie ein Online-Konto bei uns haben, um auf zusätzliche Dienste zuzugreifen.',
    createAccount: 'Erstellen Sie ein Online-Konto und registrieren Sie Ihr Unternehmen, um die für das Geschäftskonto spezifischen Preise anzuzeigen und per Rechnung zu bezahlen.',
    guestCreditCardInfo: 'Als Gast können Sie per Kreditkarte bezahlen. Bitte beachten Sie, dass die Steuerbefreiung nicht gilt, wenn Sie als Gast bestellen.'
  }
};
