/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  ConfigModule,
  CmsConfig,
  I18nModule,
  UrlModule,
  NotAuthGuard, provideConfig, I18nConfig,
} from '@spartacus/core';
import {
  FormErrorsModule,
  SpinnerModule,
  NgSelectA11yModule,
} from '@spartacus/storefront';
import { MtRegistrationConfComponent } from './mt-registration-conf.component';
import {
  mtRegistrationTranslationChunksConfig,
  mtRegistrationTranslations
} from "../../assets/translations/translations";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    SpinnerModule,
    FormErrorsModule,
    NgSelectModule,
    NgSelectA11yModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        OrganizationRegistrationConfirmationComponent: {
          component: MtRegistrationConfComponent,
          guards: [NotAuthGuard],
        },
      },
    }),
  ],
  declarations: [MtRegistrationConfComponent],
  exports: [MtRegistrationConfComponent],
  providers: [
    provideConfig( < I18nConfig > {
      i18n: {
        resources: mtRegistrationTranslations,
        chunks: mtRegistrationTranslationChunksConfig,
      },
    })
  ]
})
export class MtRegistrationConfModule {}
