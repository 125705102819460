import { HttpClient, HttpContext, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConverterService, OccEndpointsService, OCC_HTTP_TOKEN } from "@spartacus/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { MtProductDocument } from "../model/mt-product-document.model";
import { PRODUCT_DOCUMENT_NORMALIZER } from "./mt-product-document.converter";

@Injectable()
export class MtProductDocumentAdapter {

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) {}


  getProductDocuments(productCode: string): Observable<MtProductDocument[]> {
    const context = new HttpContext().set(OCC_HTTP_TOKEN, {
      sendUserIdAsHeader: true,
    });

    const url =
      this.occEndpoints.buildUrl('documents', {
        urlParams: {
          productCode: productCode
        }
      });

      return this.http.get(url).pipe(
        catchError((response: HttpErrorResponse) => {
          console.error(
            `Product documents cannot be retrieved: ${JSON.stringify(response)}`)
          return of({});
        }),
        this.converter.pipeable(PRODUCT_DOCUMENT_NORMALIZER)
      )

  }

}
