<div class="cx-modal-container" [cxFocus]="focusConfig" (esc)="dismissModal('Escape pressed')">

  <div class="cx-modal-content">

    <!-- Modal Header -->
    <ng-container>
      <div class="cx-dialog-header modal-header">
        <div class="cx-dialog-title modal-title">
          {{ 'mtUserAccountAndLogin.ordersRedirect.confirmation' | cxTranslate }}
        </div>
        <button type="button" class="close"
          (click)="dismissModal('Cross click')">
          <span aria-hidden="true">
            <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
          </span>
        </button>
      </div>

      <!-- Modal Body -->
      <div class="cx-dialog-body modal-body">
        <div class="cx-dialog-row">
          {{ 'mtUserAccountAndLogin.ordersRedirect.dialogInfo' | cxTranslate }}
          <!-- Actions -->
          <div class="cx-dialog-buttons">
            <a routerLink="login/customerPortal" target="_blank" (click)="dismissModal('Cross click')" class="btn btn-primary" autofocus>
              {{ 'mtUserAccountAndLogin.ordersRedirect.redirect' | cxTranslate }}
            </a>
          </div>
        </div>
      </div>
    </ng-container>

  </div>

</div>
