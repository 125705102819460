/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const mtUserAccountAndLogin = {

  loginForm: {

    labels: {
      loginTitle: 'Login',
      signIn: 'Login',
      register: 'Register',
      lookingCustomerPortal: 'Looking for Customer Portal Login?',
      clickHereLink: 'Click here',
      forgotPassword: 'Reset password',
      keepMeSignedIn: 'Keep me signed in',
    },

    fields: {
      emailAddress: {
        label: 'Email Address',
        placeholder: 'Required field',
      },
      password: {
        label: 'Password',
        placeholder: 'Required field',
      },
    },

    messages: {
      youCanLoginAccountActivated: "Your account has been activated. Now you can log in.",
      youCanLoginAccountReactivated: "Your account has been re-activated. You can now login"
    }

  },

  login: {
    labels: {
      myMTComLoginLink: 'myMT.com login',
      myMTComLoginDescription: 'Manage your online profile and customize your mt.com experience',
      customerPortalLoginLink: 'Customer Portal Login',
      customerPortalLoginDescription: 'Browse a custom portfolio, access quotes, and manage your installed devices on our extended digital platform.',

      myAccountLink: 'My Account',
      logoutLink: 'Logout',
      customerPortalLink: 'Customer Portal'
    }
  },

  logout: {
    sessionDialog: {
      title: 'Session Logout',
      message: 'Your Session expired.',
      logoutCountryChangedMessage: 'You are logout because the country was changed.',
      button: 'OK'
    }
  },

  forgottenPassword: {
    resetPassword: 'Reset password',
    emailAddress: {
      label: 'Email Address',
      placeholder: 'Enter email',
    },
    chooseNewPassword: {
      label: 'Choose Password',
      placeholder: 'Enter new password',
    },
    confirmNewPassword: {
      label: 'Retype Password',
      placeholder: 'Confirm new password',
    },
    actions: {
      submit: 'Submit',
      cancel: 'Back to Login',
      resendEmail: 'Resend email',
      saveNewPassword: 'Save'
    },
    passwordResetEmailSent:
      'Thank you for your request! An email for the password reset was sent to {{email}}. The link in the email is valid for 30 minutes. Email not received? Please check your spam folder.',

    passwordResetSuccess: 'Your password has been reset. Please log in to access your account.',
  },

  updatePasswordForm: {
    title: 'Change Password',
    oldPassword: {
      label: 'Old Password',
      placeholder: 'Required field',
    },
    newPassword: {
      label: 'New Password',
      placeholder: 'Required field',
    },
    confirmPassword: {
      label: 'Retype Password',
      placeholder: 'Required field',
    },
    actions: {
      save: "Save"
    },
    passwordUpdateSuccess: 'Your password has been changed',
  },

  updateProfileForm: {
    personalData: 'Personal Data',
    none: '',
    title: 'Title*',
    firstName: {
      label: 'First Name*',
      placeholder: 'Required field',
    },
    lastName: {
      label: 'Last Name*',
      placeholder: 'Required field',
    },
    customerId: 'Email Address*',
    actions: {
      save: "Save",
    },
  },

  deactivateAccount: {
    label: 'Deactivate Account',
    buttonLabel: 'Deactivate Account',
    popup: 'Are you sure you want to deactivate your account? By continuing, you will no longer have an active mt.com account. Your shared Customer Portal account will be deactivated as well.',
    cancel: 'Cancel',
  },

  reactivateAccount: {
    infoBox1: 'Your account is inactive. Please ',
    infoBox2: 'click here',
    infoBox3: ' to re-activate your account.',
    title: 'Re-activate account',
    email: {
      label: 'Email Address',
    },
    submit: 'Submit',
    backToLogin: 'Back to login',
    successMessage: 'We have sent an email to you with a link to activate your account. You will not be able to log in until you have activated your account.',
  },

  soldtoSelection: {
    selectAccount: 'Your Business Accounts',
    mtNumber: 'MT account number:',
    accountSelected: 'Account selected',
    otherAccounts:'Other available accounts',
    pickAccount:'Pick to change account for your session.',
    noAccounts:'No other accounts available.',
  },

  myAccount: {
    tiles: {
      myAccount: 'My Account',
      myProfile: 'My Profile',
      newsFeed: 'News Feed',
      personalLibrary: 'Personal Library',
      billingAddresses: 'Billing Addresses',
      shippingAddresses: 'Shipping Addresses',
      myOrders: 'My Orders',
      newsletterSubscriptions: 'Newsletter Subscriptions',
      productRegister: 'Register Your Product',
    },
    newsletterParagraph: "These email settings are for self-registered Community Newsletter subscriptions only. Even with no subscriptions active, you may still receive emails from us based on your interaction and opt-in activity.",

    news: {
      viewMore: 'View all'
    }
  },

  ordersRedirect: {
    confirmation: 'Confirmation',
    dialogInfo: 'You will be redirected to the METTLER TOLEDO Customer Portal.',
    redirect: 'OK',
  },

};

export const mtUserAccount = {
  mtUserAccountAndLogin,
};
