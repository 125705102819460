import {AfterViewInit, Component, HostBinding, OnInit, Optional} from '@angular/core';
import { Router } from '@angular/router';
import {CmsParagraphComponent, CmsService, ContentSlotData, FeatureConfigService, Page} from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import {BehaviorSubject} from "rxjs";
import {User, UserAccountFacade} from "@spartacus/user/account/root";
import {MtOrganisationRegistrationStatusEnum} from "../../user/account/core/mt-sold-to.service";
import {MtCmsUtilService} from "../services/mt-cms-util-service";

@Component({
  selector: 'mt-paragraph',
  templateUrl: './mt-paragraph.component.html',
  styleUrls: ['./mt-paragraph.component.scss']
})
export class MtParagraphComponent implements OnInit, AfterViewInit {

  protected CHECKOUT_SIDE_CONTENT_SLOT_UID: string = "SideContent";
  protected currentSlotUid: string = "";
  protected isCheckoutAlert: boolean = false;

  protected showComponent$ = new BehaviorSubject(true);

  constructor(
    public component: CmsComponentData<CmsParagraphComponent>,
    protected router: Router,
    protected cmsService: CmsService,
    protected userAccountFacade: UserAccountFacade,
    protected mtCmsUtilService: MtCmsUtilService,
    @Optional() protected featureConfigService?: FeatureConfigService
  ) { }

  ngAfterViewInit(): void {
    this.mtCmsUtilService.notifyElementRendered();
  }

  ngOnInit(): void {
    const componentUid = this.component?.uid;

    this.validateMessagesForCheckout(componentUid);
  }

  private validateMessagesForCheckout(componentUid: string | undefined) {
    this.cmsService.getCurrentPage().subscribe((page: Page) => {
      if (page.template !== "multiStepCheckoutSummaryPageTemplate") {
        return;
      }

      const slots = page?.slots || {};
      for (const slotKey in slots) {
        const slot: ContentSlotData = slots[slotKey];
        if (slot.components?.some(component => component.uid === componentUid)) {
          this.currentSlotUid = slotKey;
          break;
        }
      }

      if (page?.pageId !== 'CheckoutReviewOrder') {
        return;
      }


      if (this.currentSlotUid !== this.CHECKOUT_SIDE_CONTENT_SLOT_UID) {
        return;
      }

      this.isCheckoutAlert = true;

      this.userAccountFacade.get().subscribe(currentUser => {
        if (this.isGuestCustomer(currentUser)) {
          if (componentUid !== 'guestQuoteHelpParagraphComponent') {
            this.showComponent$.next(false);
            return;
          }
        }

        if (this.isFullyRegisteredCustomer(currentUser)) {
          if (componentUid !== 'sapRegisteredQuoteHelpParagraphComponent') {
            this.showComponent$.next(false);
            return;
          }
        }


        if (((currentUser as User).companyRegistrationStatus === MtOrganisationRegistrationStatusEnum.REGISTRATION_NOT_CONFIRMED)) {
          if (componentUid !== 'hybrisNewSAPQuoteHelpParagraphComponent') {
            this.showComponent$.next(false);
            return;
          }
        }

        if (((currentUser as User).companyRegistrationStatus === MtOrganisationRegistrationStatusEnum.NOT_REGISTERED)) {
          if (componentUid !== 'hybrisOnlyQuoteHelpParagraphComponent') {
            this.showComponent$.next(false);
            return;
          }
        }
      })

    });
  }

  private isGuestCustomer(currentUser: User | undefined) {
    return !(currentUser && currentUser.uid != "");
  }

  private isFullyRegisteredCustomer(currentUser: User | undefined) {
    return (currentUser as User).companyRegistrationStatus === MtOrganisationRegistrationStatusEnum.SINGLE_SOLDTO_ACCOUNT ||
      (currentUser as User).companyRegistrationStatus === MtOrganisationRegistrationStatusEnum.MULTIPLE_SOLDTO_ACCOUNTS;
  }
}
