import {CxEvent} from "@spartacus/core";
import {ProductEnvelope} from "./mt-add-to-cart-event";

export class MtProceedToCheckoutEvent extends CxEvent{
  static override readonly type = "ProceedToCheckout";

  checkout ?: checkoutEnvelop;
  event = "ProceedToCheckout";

}

export interface checkoutEnvelop{

  cc ?: string;
  products ?: ProductEnvelope[]

}
