
export const mtMiniCartMenu = {
  count: '{{count}}',
  labels: {
    goToShopHome: 'Bezoek online winkel',
    gotToCart: 'Bekijk winkelwagen'
  }
}

export const mtMiniCart = {
  mtMiniCartMenu
}
