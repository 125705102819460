import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PageNotFoundErrorPageComponent } from './page-not-found-error-page.component';
import { CmsConfig, ConfigModule, I18nConfig, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { mtPageNotFoundTranslationChunksConfig, mtPageNotFoundTranslations } from '../../assets/translations/translations';



@NgModule({
  declarations: [],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        PageNotFoundErrorPageComponent: {
          component: PageNotFoundErrorPageComponent,
        },
      },
    }),
    
    provideConfig(<I18nConfig>{
      i18n: {
        resources: mtPageNotFoundTranslations,
        chunks: mtPageNotFoundTranslationChunksConfig,
      },
    })
  ],
  imports: [
    CommonModule,
    I18nModule,
    RouterModule.forChild([
      {
        path: 'page-not-found-error-page',
        component: PageNotFoundErrorPageComponent,
      }
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          pagenotfound: {
            paths: ['/page-not-found-error-page'],
            protected: false
          },
        }
      }
    }),
  ]
})
export class PageNotFoundErrorPageModule { }
