/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  AuthRedirectService, GlobalMessageService,
  GlobalMessageType,
  WindowRef
} from '@spartacus/core';
import { UserAccountFacade } from "@spartacus/user/account/root";
import { BehaviorSubject, from } from 'rxjs';
import { tap, withLatestFrom } from 'rxjs/operators';
import { MtCustomFormValidators } from "../../../../util/validators/mt-custom-form-validators";
import { MtAuthService } from "../../../auth/mt-auth-service";


@Injectable()
export class MtLoginFormComponentService {
  constructor(
    protected auth: MtAuthService,
    protected globalMessage: GlobalMessageService,
    protected winRef: WindowRef,
    private userAccountFacade: UserAccountFacade,
    protected authRedirectService: AuthRedirectService,
  ) { }

  protected busy$ = new BehaviorSubject(false);

  /**
   * Info about user that is trying to login, but is inactive.
   * Based on this, the login form will show the link to reactivate account.
   */
  public isAccountInactive: boolean = false;

  isUpdating$ = this.busy$.pipe(
    tap((state) => {
      const userId = this.winRef.nativeWindow?.history?.state?.['newUid'];
      if (userId) {
        this.form.patchValue({ userId });
      }
      state === true ? this.form.disable() : this.form.enable();
    })
  );

  form: UntypedFormGroup = new UntypedFormGroup({
    userId: new UntypedFormControl('', [
      Validators.required,
      MtCustomFormValidators.emailValidator,
    ]),
    keepMeSignedIn: new UntypedFormControl(''),
    password: new UntypedFormControl('', [Validators.required, MtCustomFormValidators.passwordValidator],
    ),

  });

  login(redirectToRoute?: string) {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.busy$.next(true);

    from(
      this.auth.loginWithCredentialsAndRememberMe(
        // TODO: consider dropping toLowerCase as this should not be part of the UI,
        // as it's too opinionated and doesn't work with other AUTH services
        this.form.value.userId.toLowerCase(),
        this.form.value.password,
        this.form.value.keepMeSignedIn,
        redirectToRoute
      )
    )
      .pipe(
        withLatestFrom(this.auth.isUserLoggedIn()),
        tap(([_, isLoggedIn]) => this.onSuccess(isLoggedIn))
      )
      .subscribe();
  }

  protected onSuccess(isLoggedIn: boolean): void {
    if (isLoggedIn) {
      // We want to remove error messages on successful login (primary the bad
      // username/password combination)
      this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
      this.form.reset();
    }

    this.busy$.next(false);
  }
}
