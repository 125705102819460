/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  AuthConfigService,
  CmsConfig, ConfigModule,
  GlobalMessageService,
  I18nModule,
  NotAuthGuard,
  provideDefaultConfig,
  RoutingService, UrlModule,
} from '@spartacus/core';
import {
  FormErrorsModule,
  SpinnerModule,
  PasswordVisibilityToggleModule,
} from '@spartacus/storefront';
import { UserPasswordFacade } from '@spartacus/user/profile/root';
import { MtReactivateAccountOccService } from '../../core/mt-reactivate-account-occ.service';
import { MtReactivateAccountComponentService } from './mt-reactivate-account-component.service';
import { MtReactivateAccountComponent } from './mt-reactivate-account.component';
import { MtReactivateAccountService } from '../../core/mt-reactivate-account.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    I18nModule,
    FormErrorsModule,
    SpinnerModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        ReactivateAccountComponent: {
          component: MtReactivateAccountComponent,
          guards: [NotAuthGuard],
        },
      },
    }),
    UrlModule,
  ],
  providers: [
    MtReactivateAccountComponentService,
    MtReactivateAccountOccService,
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        ReactivateAccountComponent: {
          component: MtReactivateAccountComponent,
          guards: [NotAuthGuard],
          providers: [
            {
              provide: MtReactivateAccountComponentService,
              useClass: MtReactivateAccountComponentService,
              deps: [
                MtReactivateAccountService,
                RoutingService,
                GlobalMessageService,
                AuthConfigService,
              ],
            },
          ],
        },
      },
    }),
  ],
  declarations: [MtReactivateAccountComponent],
})
export class MtReactivateAccountModule {}
