import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MtNavigationNode } from '../../model/mt-navigation-node.model';
import { MtCategoriesNavigationComponentService } from '../mt-categories-navigation-component/mt-categories-navigation-component.service';

@Component({
  selector: 'mt-category-content',
  templateUrl: './mt-category-content.component.html',
  styleUrls: ['./mt-category-content.component.scss']
})
export class MtCategoryContentComponent implements OnInit {

  constructor(protected mtCategoryNavigationComponentService: MtCategoriesNavigationComponentService) { }

  ngOnInit(): void {
  }

  getChildrenForSelectedNode(): Observable<MtNavigationNode[]> {
    return this.mtCategoryNavigationComponentService.getChildrenForSelectedNode();
  }

}
