/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";


@Component({
  selector: 'mt-personal-library-component',
  templateUrl: './mt-personal-library.component.html',
  styleUrls: ['./mt-personal-library.scss']
})
export class MtPersonalLibraryComponent {

  constructor(protected activatedRoute: ActivatedRoute){
  }

};
