import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { EventService } from "@spartacus/core";
import { LaunchDialogService, LAUNCH_CALLER } from "@spartacus/storefront";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { CartUiEventAddToCartMultipleProductsEvent } from "../events/mt-cart-ui-event-add-to-cart-multiple-products.event";

/**
 * Listen for add to cart events (multiple products).
 */
@Injectable({
  providedIn: "root"
})
export class MtCartUiEventAddToCartMultipleProductsListener implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();


  private closeModal(reason?: any): void {
    this.launchDialogService.closeDialog(reason);
  }

  private openDialog(event: CartUiEventAddToCartMultipleProductsEvent) {
    const dialog =
      this.launchDialogService.openDialog(
        LAUNCH_CALLER.ADDED_TO_CART_MULTIPLE_PRODUCTS.toString(),
        undefined,
        undefined,
        event
      );

    if (dialog) {
      dialog.pipe(take(1)).subscribe();
    }
  }


  constructor(
    private eventService: EventService,
    private launchDialogService: LaunchDialogService
  ) {
    this.subscription.add(
      this.eventService.get(CartUiEventAddToCartMultipleProductsEvent).subscribe(event => {
        this.openDialog(event);
      })
    );
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }


}
