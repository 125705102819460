/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const mtRegistrationConfForm = {

  labels:{
    confirmationTopLabel: 'Confirmation',

    thankYou: 'Thank you for your registration!',
    emailSent: 'An E-Mail has been sent to your inbox to confirm your registration.',
    pleaseClick: 'Please click on the Confirm Registration link in your email within 24 hours',
    onceConfirm: 'Once you confirm your registration, your account will be activated and you can login.',
    contact: 'If you do not receive the email, please ',
    contactLink: 'contact Customer Service.'
  },

};

export const mtRegistrationConf = {
  mtRegistrationConfForm
};
