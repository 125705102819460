<h1 class="text--blue-color mt-registration-conf">
{{ 'mtRegistrationConfForm.labels.confirmationTopLabel' | cxTranslate }}
</h1>

<div class="form__highlight mt-registration-conf">

  <h3 class="text--black-color">
    {{ 'mtRegistrationConfForm.labels.thankYou' | cxTranslate }}
  </h3>
  <p class="form__info">
    {{ 'mtRegistrationConfForm.labels.emailSent' | cxTranslate }}
  </p>
  <p class="text--black-color bold--text">
    {{ 'mtRegistrationConfForm.labels.pleaseClick' | cxTranslate }}
  </p>
  <p class="form__info">
    {{ 'mtRegistrationConfForm.labels.onceConfirm' | cxTranslate }}
  </p>

  <br>

  <p class="form__info">
        {{ 'mtRegistrationConfForm.labels.contact' | cxTranslate }}
    <a href="{{getURL('/home/site_content/contact_us.html')}}">{{ 'mtRegistrationConfForm.labels.contactLink' | cxTranslate }}</a>
  </p>

</div>
