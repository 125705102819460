<div *ngIf="order$ | async as order">
  <div class="mt-thank-you-body" *ngIf="order?.code != undefined">
    <span class="mt-thank-you-title" [innerHTML]=" 'checkoutOrderConfirmation.title' | cxTranslate">
    </span>
    <span class="mt-thank-you-info"
      [innerHTML]=" 'checkoutOrderConfirmation.info1' | cxTranslate: {orderCode: orderCode} "></span>
    <span class="mt-thank-you-info">{{ 'checkoutOrderConfirmation.info2' | cxTranslate }}</span>

    <div class="button-container">
      <button (click)="goShopHome()" class="btn btn-primary btn-block"
        [cxAtMessage]="'checkoutOrderConfirmation.btnLabel' | cxTranslate">
        {{ 'checkoutOrderConfirmation.btnLabel' | cxTranslate }}
      </button>
    </div>

  </div>
</div>
