import {HttpClient, HttpContext, HttpErrorResponse, HttpHeaders, HttpParams} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConverterService, LanguageService, OccEndpointsService, OCC_HTTP_TOKEN } from "@spartacus/core";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { MtPriceModel } from "../model/mt-price.model";
import { MtOccPriceRequestModel, MtOccPriceResponseModel } from "../model/occ/mt-occ-price.model";
import { PRICE_NORMALIZER } from "./mt-price.converter";
import { PriceAdapter } from "./price.adapter";


@Injectable({ providedIn: 'root' })
export class MtPriceAdapter extends PriceAdapter {

  private buildUrl(language: string): string {
    const getPriceUrl =
      this.occEndpoints.buildUrl('getprice', {
        urlParams: {
          language: language
        }
      });
    return getPriceUrl;
  }

  constructor(
    protected languageService: LanguageService,
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) {
    super();
  }

  getPrice(priceRequest: MtOccPriceRequestModel): Observable<MtPriceModel[]> {
    const context = new HttpContext().set(OCC_HTTP_TOKEN, {
      sendUserIdAsHeader: true,
    });
    
    const prices$: Observable<MtPriceModel[]> =
      this.languageService.getActive().pipe(
        map((language: string) => this.buildUrl(language))
      ).pipe(
        switchMap((url: string) => {

          const params = new HttpParams().set('priceRequest', JSON.stringify(priceRequest));
          let headers = new HttpHeaders();
          headers.append('Content-Type', 'application/json');
          return this.http.get(url, {headers, params}).pipe(
            catchError((response: HttpErrorResponse) => {
              console.error(`Price cannot be obtained: ${JSON.stringify(response)}`)
              return of([]);
            }),
            map((items: any) => {
              const response = {
                currency: priceRequest.currency,
                customerId: priceRequest.customerId,
                isAuthenticated: priceRequest.authenticated,
                items: items
              } as MtOccPriceResponseModel;
              return response;
            }),
            this.converter.pipeable(PRICE_NORMALIZER)
          )
        })
      );

    return prices$;
  }

}

