import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {AuthService, BaseSiteService} from '@spartacus/core';
import { MtProduct } from '../../../model/mt-product.model';
import { MtAbstratPriceComponent } from '../../common/mt-abstract-price.component';
import { MtPriceService } from '../../services/mt-price.service';

@Component({
  selector: 'mt-customer-price',
  templateUrl: './mt-customer-price.component.html',
  styleUrls: ['./mt-customer-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MtCustomerPriceComponent extends MtAbstratPriceComponent implements OnInit, OnDestroy {

  @Input() product?: MtProduct = {} as MtProduct;

  constructor(
    protected override authService: AuthService,
    protected override priceService: MtPriceService,
    protected override baseSiteService: BaseSiteService
  ) {
    super(authService, priceService, baseSiteService);
  }

  ngOnDestroy(): void {
    super.onDestroy()
  }

  ngOnInit(): void {
    super.onInit(this.product ?? {} as MtProduct);
  }

}
