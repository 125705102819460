import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { WindowRef } from '@spartacus/core';

@Component({
  selector: 'mt-aem-exp-mt-form',
  templateUrl: './aem-exp-mt-form.component.html',
  styleUrls: ['./aem-exp-mt-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AemExpMtFormComponent implements OnInit, AfterViewInit {

  private dExpMtForm: any;

  private _productName: string | null | undefined;
  private _language: string | null | undefined;
  private _country: string | null | undefined;
  private _path: string | null | undefined;
  private _bu: string | null | undefined;
  private _type: string | null | undefined;
  private _design: string | null | undefined;
  private _leadProductType: string | null | undefined;


  @ViewChild("extMtFormContainer", { static: true }) extMtFormContainer?: ElementRef;

  constructor(
    protected windowRef: WindowRef
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    this.dExpMtForm =
      //document.createElement("exp-mt-personal-news-feed");
      this.windowRef.document.createElement("exp-mt-form");

    if (this._productName) {
      this.dExpMtForm.setAttribute("name", this._productName);
    }

    if (this._language) {
      this.dExpMtForm.setAttribute("lang", this._language);
    }

    if (this._country) {
      this.dExpMtForm.setAttribute("country", this._country);
    }

    if (this._path) {
      this.dExpMtForm.setAttribute("path", this._path);
    }

    if (this._bu) {
      this.dExpMtForm.setAttribute("bu", this._bu);
    }

    if (this._type) {
      this.dExpMtForm.setAttribute("type", this._type);
    }

    if (this._design) {
      this.dExpMtForm.setAttribute("design", this._design);
    }

    if (this._leadProductType) {
      this.dExpMtForm.setAttribute("leadProductType", this._leadProductType);
    }

    this.extMtFormContainer?.nativeElement.appendChild(this.dExpMtForm);
  }

  @Input()
  set productName(_productName: string | null | undefined) {
    this._productName = _productName;
    if (this.dExpMtForm) {
      this.dExpMtForm.setAttribute("name", _productName);
    }
  }

  @Input()
  set language(_language: string | null | undefined) {
    this._language = _language;
    if (this.dExpMtForm) {
      this.dExpMtForm.setAttribute("lang", _language);
    }
  }

  @Input()
  set country(_country: string | null | undefined) {
    this._country = _country;
    if (this.dExpMtForm) {
      this.dExpMtForm.setAttribute("country", _country);
    }
  }

  @Input()
  set path(_path: string | null | undefined) {
    this._path = _path;
    if (this.dExpMtForm) {
      this.dExpMtForm.setAttribute("path", _path);
    }
  }

  @Input()
  set bu(_bu: string | null | undefined) {
    this._bu = _bu;
    if (this.dExpMtForm) {
      this.dExpMtForm.setAttribute("bu", _bu);
    }
  }

  @Input()
  set type(_type: string | null | undefined) {
    this._type = _type;
    if (this.dExpMtForm) {
      this.dExpMtForm.setAttribute("type", _type);
    }
  }

  @Input()
  set design(_design: string | null | undefined) {
    this._design = _design;
    if (this.dExpMtForm) {
      this.dExpMtForm.setAttribute("design", _design);
    }
  }

  @Input()
  set leadProductType(_leadProductType: string | null | undefined) {
    this._leadProductType = _leadProductType;
    if (this.dExpMtForm) {
      this.dExpMtForm.setAttribute("leadProductType", _leadProductType);
    }
  }

}
