/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { TranslationChunksConfig, TranslationResources } from '@spartacus/core';
import { en } from './en';
import { de } from "./de";
import { fr } from "./fr";
import { nl } from "./nl";
import { it } from "./it";

export const mtCheckoutTranslations: TranslationResources = {
  en, de, fr, nl, it
};

export const mtCheckoutTranslationChunksConfig: TranslationChunksConfig = {
  checkout: [
    'checkout',
    'checkoutProgress',
    'checkoutAddress',
    'checkoutMode',
    'checkoutReview',
    'checkoutOrderConfirmation',
    'checkoutPaymentDetails',
    'cardActions'
  ],
};
