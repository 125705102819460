
<!--<h2 class="cx-checkout-title d-none d-lg-block d-xl-block">-->
<a #topAnchor></a>
<ng-container *ngIf="cards$ | async as cards">
  <div class="checkout-delivery-address-container"
    [ngClass]="{'checkout-delivery-address-container--center': !(isGuestCheckoutAsObservable | async)}">
    <ng-container *ngIf="!(isUpdating$ | async); else loading">
      <div role="status" [attr.aria-label]="'common.loaded' | cxTranslate"></div>
      <ng-container *ngIf="
            !addressFormOpened;
            then editOrShowExistingWrapper;
            else newAddressFormOrGuestAddressForm
          ">
      </ng-container>


      <ng-template #newAddressFormOrGuestAddressForm>
        <ng-container *ngIf="(isGuestCheckoutAsObservable | async); then guestAddressForm; else newAddressForm">
        </ng-container>
      </ng-template>

      <ng-template #guestAddressForm>
        <ng-container *ngIf="addressType === MtAddressType.SHIP_TO">
          <ng-container *ngIf="guestAddress$ | async as address; else guestEmptyAddressForm">
            <mt-checkout-guest-delivery-address-form [showTitleCode]="true" (backToAddress)="hideNewAddressForm(false)"
              [currentAddress]="address" (submitAddress)="addAddress($event)"></mt-checkout-guest-delivery-address-form>
          </ng-container>
          <ng-template #guestEmptyAddressForm>
            <mt-checkout-guest-delivery-address-form [showTitleCode]="true" (backToAddress)="hideNewAddressForm(false)"
              (submitAddress)="addAddress($event)"></mt-checkout-guest-delivery-address-form>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="addressType === MtAddressType.BILL_TO">
          <h2 class="text--blue-color">
            {{ 'checkoutAddress.billingAddress' | cxTranslate }}
          </h2>
          <div class="form-group">
            <div class="form-check">
              <label>
                <input
                  type="checkbox"
                  class="form-check-input"
                  [checked]="sameAsDeliveryAddressCheckbox"
                  (change)="toggleSameAsDeliveryAddress()"
                />
                <span class="form-check-label-checkbox">
                    {{ 'checkoutAddress.sameAsDeliveryAddress' | cxTranslate }}
                </span>
              </label>
            </div>
            <div *ngIf="showGuestUserNewAddressForm">
              <div *ngIf="guestAddress$ | async as address; else guestEmptyAddressForm">
                <mt-checkout-guest-delivery-address-form [showTitleCode]="false" (backToAddress)="hideNewAddressForm(false)"
                   (submitAddress)="addAddress($event)" [currentAddress]="address" [isBillingAddress]="true"></mt-checkout-guest-delivery-address-form>
              </div>
              <ng-template #guestEmptyAddressForm>
                <mt-checkout-guest-delivery-address-form [showTitleCode]="false" (backToAddress)="hideNewAddressForm(false)"
                     (submitAddress)="addAddress($event)" [isBillingAddress]="true"></mt-checkout-guest-delivery-address-form>
              </ng-template>
            </div>
          </div>

        </ng-container>
      </ng-template>

      <ng-template #editOrShowExistingWrapper>
        <ng-container *ngIf="!showEditAddressForm; then showAddressesOrGuestForm else editAddressForm">
        </ng-container>
      </ng-template>

      <ng-template #showAddressesOrGuestForm>
        <ng-container *ngIf="(isGuestCheckoutAsObservable | async); then guestAddressForm; else showExistingAddresses">
        </ng-container>
      </ng-template>

      <ng-template #showExistingAddresses>
        <div class="existing-address-wrapper">
          <div class="existing-address-wrapper existing-address-wrapper address-form-wrapper--inner">
            <h2 class="text--blue-color">
              <div *ngIf="addressType === MtAddressType.BILL_TO">
                {{ 'checkoutAddress.billingAddress' | cxTranslate }}
              </div>
              <div *ngIf="addressType === MtAddressType.SHIP_TO">
                {{ 'checkoutAddress.deliveryAddress' | cxTranslate }}
              </div>
            </h2>
          </div>
        </div>
        <div class="cx-checkout-btns" *ngIf="displayNewAddressButton">
          <div class="form_btn-action-wrapper">
            <button class="btn btn-primary btn-block new-address-btn" (click)="showNewAddressForm()">
                  {{ 'checkoutAddress.addNewAddress' | cxTranslate }}
            </button>
          </div>
        </div>

        <!-- same as delivery address checkbox if cards is empty-->
        <ng-container *ngIf="addressType === MtAddressType.BILL_TO">
          <div *ngIf="showSameAsDeliveryAddressCheckbox()" class="form-group">
            <div class="form-check">
              <label>
                <input
                  type="checkbox"
                  class="form-check-input"
                  [checked]="sameAsDeliveryAddressCheckbox"
                  (change)="toggleSameAsDeliveryAddress()"
                />
                <span class="form-check-label-checkbox">
                    {{ 'checkoutAddress.sameAsDeliveryAddress' | cxTranslate }}
                </span>
              </label>
            </div>
          </div>
      </ng-container>

        <form class="form-full-width" name="deliveryAddresses">
          <div formGroupName="deliveryAddressesGroup" class="delivery-address-group" *ngIf="displayAddressCards(cards)">
            <div *ngFor="let card of cards; let i = index">
              <div *ngIf="isInCurrentPage(i)">
                <div>
                  <div *ngIf="addressType === MtAddressType.SHIP_TO">
                  <mt-card [border]="true" [index]="i" [fitToContainer]="true" [content]="card.card"
                    [isDefault]="card.address.defaultAddress ?? false" (sendCard)="selectAddress(card.address)"
                    (editCard)="editAddressButtonHandle(card.address)"
                    (setDefaultCard)="setAddressAsDefault(card.address)"
                    (deleteCard)="deleteAddress(card.address.id ?? '', card.address.addressType)">
                  </mt-card>
                  </div>
                  <div *ngIf="addressType === MtAddressType.BILL_TO">
                    <mt-card [border]="true" [index]="i" [fitToContainer]="true" [content]="card.card"
                             [isDefault]="card.address.defaultAddress ?? false" (sendCard)="selectAddress(card.address)"
                             (editCard)="editAddressButtonHandle(card.address)"
                             (setDefaultCard)="setBillingAddressAsDefault(card.address)"
                             (deleteCard)="deleteAddress(card.address.id ?? '', card.address.addressType)">
                    </mt-card>
                  </div>
                </div>
              </div>
            </div>

            <cx-pagination [pagination]="pagination" (viewPageEvent)="pageChange($event)" queryParam="page" [defaultPage]="0"></cx-pagination>

          </div>
        </form>

        <div class="order-summary-placeholder-mobile"></div>
        <div *ngIf="addressType === MtAddressType.SHIP_TO" class="cx-checkout-btns nav-btns">
          <!--        <div class="col-md-12 col-lg-6">-->
          <div class="form_btn-action-wrapper">
            <button class="cx-btn btn btn-block btn-secondary back-to-cart-btn" (click)="back()">
              {{ 'checkout.backToCart' | cxTranslate }}
            </button>
          </div>
          <!--        <div class="col-md-12 col-lg-6">-->
          <div class="form_btn-action-wrapper">
            <button class="cx-btn btn btn-block btn-primary continue-btn" [disabled]="!(selectedAddress$ | async)?.id"
              (click)="next()">
              {{ 'checkout.continue' | cxTranslate }}
            </button>
          </div>
        </div>
      </ng-template>

      <ng-template #newAddressForm>
        <div class="address-form-wrapper">
          <div class="address-form-wrapper address-form-wrapper--inner">
            <div *ngIf="addressType === MtAddressType.BILL_TO">
              <h2 class="text--blue-color">
                 {{ 'checkoutAddress.newBillingAddress' | cxTranslate }}
              </h2>
            </div>
            <div *ngIf="addressType === MtAddressType.SHIP_TO">
              <h2 class="text--blue-color">
                {{ 'checkoutAddress.newDeliveryAddress' | cxTranslate }}
              </h2>
            </div>
          </div>
        </div>
        <mt-address-form *ngIf="cards.length; else initialAddressForm" [showTitleCode]="true"
          [addressType]="addressType" [addAddressForm]="true" (backToAddress)="hideNewAddressForm(false)"
          (submitAddress)="addAddress($event)">
        </mt-address-form>
        <ng-template #initialAddressForm>
          <mt-address-form [showTitleCode]="true" [addressType]="addressType" [addAddressForm]="true"
            [setAsDefaultField]="!(isGuestCheckoutAsObservable | async)" cancelBtnLabel="{{ backBtnText | cxTranslate }}"
            (backToAddress)="hideNewAddressForm(true)" (submitAddress)="addAddress($event)">
          </mt-address-form>
        </ng-template>
      </ng-template>

      <ng-template #editAddressForm>

        <div class="address-form-wrapper">
          <div *ngIf="addressType === MtAddressType.SHIP_TO" class="address-form-wrapper address-form-wrapper--inner">
            <h2 class="text--blue-color">
             {{ 'checkoutAddress.editDeliveryAddress' | cxTranslate }}
            </h2>
          </div>
          <div *ngIf="addressType === MtAddressType.BILL_TO" class="address-form-wrapper address-form-wrapper--inner">
            <h2 class="text--blue-color">
              {{ 'checkoutAddress.editBillingAddress' | cxTranslate }}
            </h2>
          </div>
        </div>
        <mt-address-form [showTitleCode]="true" actionBtnLabel="{{ 'addressBook.updateAddress' | cxTranslate }}"  [addressType]="addressType"
          cancelBtnLabel="{{ 'addressBook.backToAddressList' | cxTranslate }}" [addressData]="getCurrentAddress()"
          (submitAddress)="editAddressSubmit($event)" (backToAddress)="editAddressCancel()"></mt-address-form>
      </ng-template>

    </ng-container>
  </div>
</ng-container>



<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
