import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { FacetList, FacetListComponent } from '@spartacus/storefront';
import { Observable, BehaviorSubject } from 'rxjs';
import { MtFacetService } from '../../services/mt-facet.service';
import { WindowRef} from '@spartacus/core';
import { MtProductSearchPageModel } from '../mt-product-list/mt-product-search-page.model';
import { MtProductListComponentService } from '../mt-product-list/mt-product-list-component.service';

@Component({
  selector: 'mt-facet-list',
  templateUrl: './mt-facet-list.component.html',
  styleUrls: ['./mt-facet-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MtFacetListComponent extends FacetListComponent implements OnInit {
  public isPopupOpen$: BehaviorSubject<boolean> = this.facetService.isPopupOpen();
  override facetList$: Observable<FacetList> = this.facetService.facetList$;
  model$: Observable<MtProductSearchPageModel | undefined> =
  this.productListComponentService.model$;
  @Input()
  category?: string | undefined;

  constructor(
    protected override facetService: MtFacetService,
    protected override elementRef: ElementRef,
    protected override renderer: Renderer2,
    protected winRef: WindowRef,
    protected productListComponentService: MtProductListComponentService) {
    super(facetService, elementRef, renderer);
  }

  ngOnInit(): void {

  }

  onClearFilters() {
    if (this.winRef.nativeWindow) {
      this.winRef.nativeWindow.location.hash =
        this.facetService.clearAllFacets(this.winRef.nativeWindow.location.href);
    }
  }

  closePopup() {
    this.isPopupOpen$.next(false);
    const headerLayer = document.getElementById('header_layer');
    const headerLayerWrapper = document.getElementById('header_layer_wrapper');
      if (headerLayer) {
        headerLayer.style.display = ''; 
      }
      if (headerLayerWrapper) {
        headerLayerWrapper.style.display = '';
      }

    const bottomLayers = document.getElementsByClassName('bottom');
    for (let i = 0; i < bottomLayers.length; i++) {
      const bottomLayer = bottomLayers[i] as HTMLElement;
      bottomLayer.style.display = '';
    }
  }

}
