/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import {I18nConfig, I18nModule, provideConfig} from '@spartacus/core';
import { MtAddressFormComponent } from './mt-address-form.component';
import {FormErrorsModule, IconModule, KeyboardFocusModule, NgSelectA11yModule} from "@spartacus/storefront";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {
  deTranslationOverwrites,
  enTranslationOverwrites,
  frTranslationOverwrites,
  nlTranslationOverwrites,
  itTranslationOverwrites
} from "../../../../../spartacus-configuration.module";

@NgModule({
  imports: [
    NgSelectA11yModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgSelectModule,
    NgbTooltipModule,
    IconModule,
    I18nModule,
    FormErrorsModule,
    KeyboardFocusModule,
  ],
  declarations: [MtAddressFormComponent],
  exports: [MtAddressFormComponent],
  providers:
  [
    provideConfig(<I18nConfig>{
      i18n: {
        resources: {
          en: enTranslationOverwrites,
          de: deTranslationOverwrites,
          fr: frTranslationOverwrites,
          nl: nlTranslationOverwrites,
          it: itTranslationOverwrites
        },
      },
    }),
  ]
})
export class MtAddressFormModule {}
