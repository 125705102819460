import { NgModule } from "@angular/core";
import { ConfigModule, RoutingConfig } from "@spartacus/core";

@NgModule({
  imports: [
    ConfigModule.withConfig({
      // configure product routes
      routing: {
        routes: {
          product: {
            paths: [
              'product/:productCode/:seoNormalizedName',
            ],
            paramsMapping: {
              productCode: 'code',
            }
          },
        },
      },
    } as RoutingConfig),
  ],
})
export class MtProductRouteModule {}