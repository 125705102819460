
export class ItemPerPageModel {
  constructor(
    private _value: number) { }

  get value() {
    return this._value;
  }

}
