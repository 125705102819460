import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsComponent, Config, provideConfig } from '@spartacus/core';
import { MtCategoryItemComponentModule } from '../mt-category-item-component/mt-category-item-component.module';
import { MtCategoryContentComponent } from './mt-category-content.component';



@NgModule({
  declarations: [MtCategoryContentComponent],
  imports: [
    CommonModule,
    MtCategoryItemComponentModule
  ],
  providers: [
    provideConfig(<CmsComponent>{
      cmsComponents: {
        CategoryContentComponent: {
          component: MtCategoryContentComponent,
        },
      },
    } as Config)
  ],
  exports: [
    MtCategoryContentComponent
  ]
})
export class MtCategoryContentComponentModule { }
