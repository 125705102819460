/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const mtRegistrationConfForm = {

  labels:{
    confirmationTopLabel: 'Bestätigung',

    thankYou: 'Vielen Dank für Ihre Registrierung!',
    emailSent: 'Eine E-Mail zur Bestätigung Ihrer Registrierung wurde verschickt.',
    pleaseClick: 'Bitte klicken Sie innerhalb von 24 Stunden auf den Link: Registrierung bestätigen',
    onceConfirm: 'Nachdem Sie die Registrierung bestätigt haben, wird Ihr User aktiviert und sie können sich einloggen.',
    contact: 'Falls Sie die E-Mail nicht erhalten haben, ',
    contactLink: 'kontatktieren Sie bitte den Kundendienst.'
  },

};

export const mtRegistrationConf = {
  mtRegistrationConfForm
};
