import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsComponent, Config, provideConfig } from '@spartacus/core';
import { BannerModule, MediaModule, SpinnerModule } from '@spartacus/storefront';
import { MtBannerComponent } from './mt-banner.component';



@NgModule({
  declarations: [
    MtBannerComponent
  ],
  imports: [
    CommonModule,
    MediaModule,
    BannerModule,
    SpinnerModule
  ],
  providers: [
    provideConfig(<CmsComponent>{
      cmsComponents: {
        MTBannerComponent: {
          component: MtBannerComponent,
        },
      },
    } as Config)
  ],
  exports: [
    MtBannerComponent
  ]
})
export class MtBannerComponentModule { }
