
export const mtMiniCartMenu = {
  count: '{{count}}',
  labels: {
    goToShopHome: 'Visite la boutique en ligne',
    gotToCart: 'Voir le panier'
  }
}

export const mtMiniCart = {
  mtMiniCartMenu
}
