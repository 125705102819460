import { AuthService } from "@spartacus/core";
import { Observable, Subscription } from "rxjs";
import { MtPriceModel } from "../../model/mt-price.model";
import { MtProduct } from "../../model/mt-product.model";
import { MtPriceService } from "../services/mt-price.service";


export class MtProductPriceComponentAdapter {

  private subscription: Subscription = new Subscription()

  private getFormattedListPrice(): Observable<string | undefined> {
    return this.priceService.getFormattedPrice((this.currentProduct.price as MtPriceModel).listPrice ?? 0, 1);
  }

  private getFormattedListPriceWithVatValue(): Observable<string | undefined> {
    return this.priceService.getFormattedPrice((this.currentProduct.price as MtPriceModel).listPriceWithVatValue ?? 0, 1);
  }

  private getFormattedCustomerPrice(): Observable<string | undefined> {
    return this.priceService.getFormattedPrice((this.currentProduct.price as MtPriceModel).customerPrice, 1);
  }

  private getFormattedCustomerPriceWithVatValue(): Observable<string | undefined> {
    return this.priceService.getFormattedPrice((this.currentProduct.price as MtPriceModel).customerPriceWithVatValue, 1);
  }

  isUserLoggedIn$: Observable<boolean>;
  formattedListPrice?: string;
  formattedListPriceWithVat?: string;
  formattedCustomerPrice?: string;
  formattedCustomerPriceWithVat?: string;
  vatDescription?: string

  constructor(
    protected currentProduct: MtProduct,
    protected authService: AuthService,
    protected priceService: MtPriceService
  ) {
    this.isUserLoggedIn$ = this.authService.isUserLoggedIn();
    this.vatDescription = this.currentProduct.price?.vatDescription
  }

  onDestroy(): void {
    this.subscription.unsubscribe();
  }

  onInit(): void {
    this.subscription.add(
      this.getFormattedListPrice().subscribe((formattedPrice: string | undefined) => this.formattedListPrice = formattedPrice)
    );

    this.subscription.add(
      this.getFormattedListPriceWithVatValue().subscribe((formattedPrice: string | undefined) => this.formattedListPriceWithVat = formattedPrice)
    );

    this.subscription.add(
      this.getFormattedCustomerPrice().subscribe((formattedPrice: string | undefined) => this.formattedCustomerPrice = formattedPrice)
    );

    this.subscription.add(
      this.getFormattedCustomerPriceWithVatValue().subscribe((formattedPrice: string | undefined) => this.formattedCustomerPriceWithVat = formattedPrice)
    );

    this.subscription.add(
      this.getFormattedCustomerPriceWithVatValue().subscribe((formattedPrice: string | undefined) => this.formattedCustomerPriceWithVat = formattedPrice)
    );
  }

}
