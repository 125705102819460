/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, ViewChild, ElementRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { CardWithAddress, CheckoutDeliveryAddressComponent, CheckoutStepService } from "@spartacus/checkout/base/components";
import {
  CheckoutDeliveryAddressAdapter,
  CheckoutDeliveryAddressConnector, CheckoutDeliveryModesService
} from '@spartacus/checkout/base/core';
import { OccCheckoutDeliveryAddressAdapter } from '@spartacus/checkout/base/occ';
import {
  CheckoutDeliveryAddressFacade,
  CheckoutDeliveryModesFacade,
  CheckoutQueryResetEvent
} from '@spartacus/checkout/base/root';
import {
  Address,
  AddUserAddressEvent,
  DeleteUserAddressEvent,
  EventService, getLastValueSync,
  GlobalMessageService,
  GlobalMessageType,
  PaginationModel,
  TranslationService,
  UpdateUserAddressEvent,
  UserAddressEvent
} from '@spartacus/core';
import { AddressBookComponentService, Card, LaunchDialogService, LAUNCH_CALLER } from '@spartacus/storefront';
import { User, UserAccountFacade } from '@spartacus/user/account/root';
import { BehaviorSubject, combineLatest, EMPTY, Observable, Subscription } from 'rxjs';
import {distinctUntilChanged, filter, first, map, switchMap, take} from "rxjs/operators";
import { MtActiveCartService } from 'src/app/spartacus/custom-module/cart/base/core/facade/mt-active-cart.service';
import { MtOrganisationRegistrationStatusEnum } from 'src/app/spartacus/custom-module/user/account/core/mt-sold-to.service';
import { MtAddressType, MtUserAddressService } from "../../../../user/account/services/mt-user-address.service";
import { MtCheckoutBillingAddressService } from "../../../core/facade/mt-checkout-billing-address.service";
import { MtCheckoutDeliveryAddressService } from '../../../core/facade/mt-checkout-delivery-address.service';
import { CheckoutGuestDeliveryAddressFormComponent } from '../checkout-guest-delivery-address-form/checkout-guest-delivery-address-form.component';

@Component({
  selector: 'mt-delivery-address',
  templateUrl: './mt-checkout-delivery-address.component.html',
  styleUrls: ['./mt-checkout-delivery-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  providers: [
    {
      provide: ActiveCartFacade,
      useClass: MtActiveCartService
    },
    {
      provide: CheckoutDeliveryModesFacade,
      useClass: CheckoutDeliveryModesService,
    },

    {
      provide: CheckoutDeliveryAddressAdapter,
      useClass: OccCheckoutDeliveryAddressAdapter,
    },
    CheckoutDeliveryAddressConnector,
    MtCheckoutDeliveryAddressService,
    {
      provide: CheckoutDeliveryAddressFacade,
      useExisting: MtCheckoutDeliveryAddressService,
    },
  ]

})
export class MtCheckoutDeliveryAddressComponent extends CheckoutDeliveryAddressComponent implements OnDestroy{

  @ViewChild('topAnchor') topAnchor!: ElementRef;
  removeAddressDialog: Observable<any> = EMPTY;

  @Input() addressType: MtAddressType = MtAddressType.SHIP_TO;
  @Input() displayNewAddressButton: boolean | undefined;

  addresses$: Observable<Address[]> | undefined;
  addressesStateLoading$: Observable<boolean> | undefined;
  currentAddress: Address | undefined;

  showAddAddressForm = false;
  showEditAddressForm = false;
  editCard: string | null = null;

  guestAddress$: BehaviorSubject<Address | undefined> = new BehaviorSubject<Address | undefined>(undefined);
  isFullyRegisteredUser: boolean = false;
  sameAsDeliveryAddressCheckbox : boolean = true;
  userHasAddresses: boolean | undefined;
  showGuestUserNewAddressForm: boolean = false;

  @Output() hasAddresses = new EventEmitter<boolean>();
  @Output() sameAsDeliveryAddress = new EventEmitter<boolean>();
  @Output() displayPaymentInfo = new EventEmitter<boolean>();

  @ViewChild(CheckoutGuestDeliveryAddressFormComponent) guestAddressForm!: CheckoutGuestDeliveryAddressFormComponent;
  private setAsDefaultLabelTranslated: string = "Set as default";
  private removeLabelTranslated: string = "Remove";
  private editLabelTranslated: string = "Edit";


  protected getCurrentAddress(): Address {
    return <Address>this.currentAddress;
  }

  protected pagination: PaginationModel;

  protected subscription = new Subscription();

  constructor(
    protected override userAddressService: MtUserAddressService,
    protected override checkoutDeliveryAddressFacade: CheckoutDeliveryAddressFacade,
    protected override activatedRoute: ActivatedRoute,
    protected override translationService: TranslationService,
    protected override activeCartFacade: ActiveCartFacade,
    protected override checkoutStepService: CheckoutStepService,
    protected override checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    protected override globalMessageService: GlobalMessageService,
    protected userAccountFacade: UserAccountFacade,
    protected launchDialogService: LaunchDialogService,
    public service: AddressBookComponentService,
    protected translation: TranslationService,
    private eventService: EventService,
    private mtCheckoutBillingAddressService: MtCheckoutBillingAddressService,
    private router: Router
  ) {

    super(userAddressService,
      checkoutDeliveryAddressFacade,
      activatedRoute,
      translationService,
      activeCartFacade,
      checkoutStepService,
      checkoutDeliveryModesFacade,
      globalMessageService);

    this.eventService
      .get(UserAddressEvent)
      .pipe(
        filter(
          (event) =>
            event instanceof AddUserAddressEvent ||
            event instanceof UpdateUserAddressEvent ||
            event instanceof DeleteUserAddressEvent
        )
      ).subscribe(
        userAddressEvent => {
          this.activeCartFacade.takeActiveCartId().pipe(map(cartId => {
            this.userAddressService.loadAddressesWithAddressTypeAndCartId(this.addressType, cartId);
          }))

        }
      );

    this.pagination = {
      currentPage: 0,
      pageSize: 0,
      totalPages: 0,
      totalResults: 0
    };
  }

  ngAfterViewInit() {
    if (this.topAnchor) {
      this.scrollToTop();
    }
  }

  override get selectedAddress$(): Observable<Address | undefined> {
    let selected$: Observable<Address | undefined>  = new BehaviorSubject(undefined);
    if (this.addressType === MtAddressType.BILL_TO) {
      selected$ = this.mtCheckoutBillingAddressService.getBillingAddressState().pipe(
        filter((state) => !state.loading),
        map((state) => state.data),
        distinctUntilChanged((prev, curr) =>
          prev?.id === curr?.id || prev?.businessId === curr?.businessId)
      );
    } else {
      selected$ = this.checkoutDeliveryAddressFacade.getDeliveryAddressState().pipe(
        filter((state) => !state.loading),
        map((state) => state.data),
        distinctUntilChanged((prev, curr) =>
          prev?.id === curr?.id || prev?.businessId === prev?.businessId)
      );
    }
    return selected$;
  }

  override getAddressLoading(): Observable<boolean> {
    if (this.addressType === MtAddressType.BILL_TO) {
      return this.mtCheckoutBillingAddressService.getBillingAddressState().pipe(map((state) => state.loading),
        distinctUntilChanged())
    } else {
      return this.checkoutDeliveryAddressFacade.getDeliveryAddressState().pipe(
        map((state) => state.loading),
        distinctUntilChanged()
      );
    }



  }

  override createIsUpdating(): Observable<boolean> {
    return combineLatest([
      this.busy$,
      this.userAddressService.getAddressesLoading(),
      this.getAddressLoading(),
    ]).pipe(
      map(
        ([busy, userAddressLoading, specificAddressTypeLoading]) =>
          busy || userAddressLoading || specificAddressTypeLoading
      ),
      distinctUntilChanged()
    );
  }

  override setAddress(address: Address): void {
    this.busy$.next(true);
    if (this.addressType === MtAddressType.BILL_TO) {
      this.mtCheckoutBillingAddressService.setBillingAddress(address).subscribe({
        complete: () => {
          this.onSuccess();
          this.eventService.dispatch({}, CheckoutQueryResetEvent);
          this.ngOnInit()
        },
        error: () => {
          this.onError();
        },
      });
    } else {
      this.checkoutDeliveryAddressFacade
        .setDeliveryAddress(address)
        .subscribe({
          complete: () => {
            this.onSuccess();
            this.eventService.dispatch({}, CheckoutQueryResetEvent);
            this.ngOnInit()
          },
          error: () => {
            this.onError();
          },
        });
    }
    this.setDefaultOrSelectedAddressAsFirstAddressCard();
  }

  pageChange(page: number): void {
    this.pagination.currentPage = page;
  }

  isInCurrentPage(i: number): boolean {
    // @ts-ignore
    if ((i >= this.pagination.currentPage * 5) && (i < (this.pagination.currentPage + 1) * 5)) {
      return true;
    }
    else
      return false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  override loadAddresses(): void {
    if (!this.isGuestCheckout) {
      this.activeCartFacade.takeActiveCartId().pipe(map(cartId => {
        this.userAddressService.loadAddressesWithAddressTypeAndCartId(this.addressType, cartId);
      }))
      this.cards$ = this.createCards();
    }
  }

  private initFirstTime = true

  override ngOnInit(): void {
    super.ngOnInit();

    if(this.addressType == MtAddressType.SHIP_TO){
      this.activeCartFacade.reloadActiveCart();
    }

    this.subscription.add(
      combineLatest([this.cards$, this.activatedRoute.queryParamMap]).subscribe(
        (result) => {
          const cards = result[0]
          const paramMap = result[1]
          this.pagination = {
            currentPage: parseInt(paramMap.get("page") ?? "0"),
            pageSize: 5,
            totalPages: Math.ceil(cards.length / 5),
            totalResults: cards.length
          }
        }
      )
    )

    if(this.initFirstTime)
    {
      this.initFirstTime = false
      this.subscription.add(
        this.cards$?.subscribe(
          cardWithAddress => {
            if (this.displayAddressCards(cardWithAddress)) {
              this.hasAddresses.emit(true);
              this.userHasAddresses = true;
            } else {
              this.hasAddresses.emit(false);
              this.userHasAddresses = false;
            }
          }
        )
      );


      this.subscription.add(
        combineLatest([
          this.activeCartFacade.isGuestCart(),
          this.activeCartFacade.getActive()
        ]).subscribe(([isGuestCart, cart]) => {
          if (isGuestCart === true) {
            this.guestAddress$.next(cart.deliveryAddress);
          } else {
            this.addresses$ = this.service.getAddresses();
            this.addressesStateLoading$ = this.service.getAddressesStateLoading();
            this.userAddressService.loadAddressesWithAddressTypeAndCartId(this.addressType, cart.code);
            this.cards$ = this.createCards();
          }
          if(cart.billingAddress && this.addressType == MtAddressType.BILL_TO)
          {
            //don't display if same as delivery address
            if(cart.billingAddress.formattedAddress !== cart.deliveryAddress?.formattedAddress) {
              const billingAddress = cart.billingAddress
              this.guestAddress$.next(billingAddress)
              this.toggleSameAsDeliveryAddress()
            }
          }
        })
      );
    }

    this.userAccountFacade.get().subscribe(currentUser => {
        if (!!currentUser && !this.isGuestCheckout && ((currentUser as User).companyRegistrationStatus === MtOrganisationRegistrationStatusEnum.SINGLE_SOLDTO_ACCOUNT ||
            (currentUser as User).companyRegistrationStatus === MtOrganisationRegistrationStatusEnum.MULTIPLE_SOLDTO_ACCOUNTS)) {
            this.isFullyRegisteredUser = true;
        }

        if(this.addressType === MtAddressType.SHIP_TO || !this.isFullyRegisteredUser) {
          this.displayNewAddressButton = true;
        }

    });


    combineLatest([
      this.translation.translate('cardActions.setAsDefault'),
      this.translation.translate('cardActions.remove'),
      this.translation.translate('cardActions.edit'),]).subscribe(
      it => {
        this.setAsDefaultLabelTranslated = it[0]
        this.removeLabelTranslated = it[1]
        this.editLabelTranslated = it[2]
        this.cards$ = this.createCards()
      }
    )

    this.setDefaultOrSelectedAddressAsFirstAddressCard();

  }

  get isGuestCheckoutAsObservable(): Observable<boolean> {
    return this.activeCartFacade.isGuestCart();
  }

  override getCardContent(
    address: Address,
    selected: any,
    textDefaultDeliveryAddress: string,
    textShipToThisAddress: string,
    textSelected: string
  ): Card {
    let region = '';
    if (address.region && address.region.isocode) {
      region = address.region.isocode + ', ';
    }

    let cardActions: { name: string; event: string }[] =
      [
        { name: this.setAsDefaultLabelTranslated, event: 'default' },
        { name: this.editLabelTranslated, event: 'edit' }
      ];
    if (!address.defaultAddress && !address.business) {
      cardActions.push(
        { name: this.removeLabelTranslated, event: 'delete' },
      )
    }

    let companyName = address.companyName;
    if (address.companyName2 !== undefined && address.companyName2 !== null && address.companyName2.length > 0) {
      companyName = companyName?.concat(', ' + address.companyName2)
    }
    if (address.companyName3 !== undefined && address.companyName3 !== null && address.companyName3.length > 0) {
      companyName = companyName?.concat(', ' + address.companyName3)
    }

    let addressText = address.line1;
    if (address.line2 !== undefined && address.line2 !== null && address.line2.length > 0) {
      addressText = addressText?.concat(', ' + address.line2)
    }
    if (address.line3 !== undefined && address.line3 !== null && address.line3.length > 0) {
      addressText = addressText?.concat(', ' + address.line3)
    }
    if (address.town !== undefined && address.town !== null && address.town.length > 0) {
      addressText = addressText?.concat(', ' + address.town)
    }
    if (address.postalCode !== undefined && address.postalCode !== null && address.postalCode.length > 0) {
      addressText = addressText?.concat(', ' + address.postalCode)
    }
    if (address.region !== undefined && address.region !== null) {
      if (address.region.name !== undefined && address.region.name !== null && address.region.name.length > 0) {
        addressText = addressText?.concat(', ' + address.region.name)
      }
    }
    if (address.country !== undefined && address.country !== null) {
      if (address.country.name !== undefined && address.country.name !== null && address.country.name.length > 0) {
        addressText = addressText?.concat(', ' + address.country.name)
      }
    }

    const businessId : string | undefined = selected?.businessId !== undefined ? '_' +selected?.businessId : undefined

    return {
      role: 'region',
      title: address.defaultAddress ? textDefaultDeliveryAddress : '',
      textBold: (companyName),
      text: [
        addressText
      ],
      actions: cardActions,
      addressId: address.id,
      selectedAddressId: (address.business ? businessId : selected?.id) ?? '',
      // addressType: this.addressType
    } as Card;
  }



  addAddressButtonHandle(): void {
    this.showEditAddressForm = false;
    this.showAddAddressForm = true;
    this.displayPaymentInfo.emit(false);
  }

  editAddressButtonHandle(address: Address): void {
    this.showAddAddressForm = false;
    this.showEditAddressForm = true;
    this.currentAddress = address;
    this.displayPaymentInfo.emit(false);
  }

  addAddressSubmit(address: Address): void {
    this.showAddAddressForm = false;
    this.service.addUserAddress(address);
    this.hasAddresses.emit(true);
    this.displayPaymentInfo.emit(true);
  }

  addAddressCancel(): void {
    this.showAddAddressForm = false;
    this.displayPaymentInfo.emit(true);
  }

  editAddressSubmit(address: Address): void {
    this.showEditAddressForm = false;
    this.displayPaymentInfo.emit(true);
    let id = this.currentAddress?.['id']
    if (id?.match(/^_[0-9]{13}$/gm)) {
      id=id?.slice(1);
    }
    const currentAddress=id;
    if (address && currentAddress) {
      this.userAddressService.updateUserAddress(currentAddress, address);
    }
  }

  editAddressCancel(): void {
    this.showEditAddressForm = false;
    this.displayPaymentInfo.emit(true);
  }

  setAddressAsDefault(address: Address): void {
    this.service.setAddressAsDefault(address.id ?? '');
    this.globalMessageService.add(
      {
        key: 'addressMessages.setAsDefaultSuccessfully',
        params: { streetAddress: address.line1 },
      },
      GlobalMessageType.MSG_TYPE_CONFIRMATION
    );
    this.ngOnInit();
  }

  setBillingAddressAsDefault(address: Address): void {

    this.mtCheckoutBillingAddressService.setBillingAddressAsDefault(address.id);
    this.globalMessageService.add(
      {
        key: 'addressMessages.setAsDefaultSuccessfully',
        params: { streetAddress: address.line1 },
      },
      GlobalMessageType.MSG_TYPE_CONFIRMATION
    );
    this.ngOnInit();
  }

  deleteAddress(addressId: string, addressType: MtAddressType | undefined): void {
    const dialogData = {
      addressId: addressId,
      addressType: addressType?.toString() ?? ''
    }
    this.removeAddressDialog = this.launchDialogService.openDialog(LAUNCH_CALLER.REMOVE_ADDRESS, undefined, undefined, dialogData) || EMPTY;

    if (this.removeAddressDialog) {
      this.removeAddressDialog.pipe(take(1)).subscribe();
    }
  }

  cancelCard(): void {
    this.editCard = null;
    this.displayPaymentInfo.emit(true);
  }


  override addAddress(address: Address | undefined): void {
    if (!address) {
      return;
    }

    this.busy$.next(true);
    this.doneAutoSelect = true;
    this.displayPaymentInfo.emit(true);

    this.checkoutDeliveryAddressFacade
      .createAndSetAddress(address)
      .subscribe({
        complete: () => {
          // we don't call onSuccess here, because it can cause a spinner flickering
          this.busy$.next(false);
          this.activeCartFacade.isGuestCart().subscribe(guestCart => {
            if (guestCart) {
              this.next();
            } else {

              this.hideNewAddressForm();

              this.activeCartFacade
                .takeActiveCartId()
                .pipe(
                  map(cartId => {
                    this.userAddressService.loadAddressesWithAddressTypeAndCartId(this.addressType, cartId);
                    this.ngOnInit()
                  })
                )
            }
          });
        },
        error: (err) => {
          console.log(`error: ${err}`)
          this.onError();
          this.doneAutoSelect = false;
        },
      });
  }

  override showNewAddressForm() {
    super.showNewAddressForm();
    this.displayPaymentInfo.emit(false);
    this.sameAsDeliveryAddressCheckbox = false;
    this.sameAsDeliveryAddress.emit(this.sameAsDeliveryAddressCheckbox);
  }

  override hideNewAddressForm(goPrevious?: boolean) {
    super.hideNewAddressForm(goPrevious);
    this.displayPaymentInfo.emit(true);
  }

  showSameAsDeliveryAddressCheckbox(): boolean {
    return !this.userHasAddresses && !this.isFullyRegisteredUser && !this.addressFormOpened;
  }

  toggleSameAsDeliveryAddress(): void {
    this.sameAsDeliveryAddressCheckbox = !this.sameAsDeliveryAddressCheckbox;
    this.sameAsDeliveryAddress.emit(this.sameAsDeliveryAddressCheckbox);

    if (this.isGuestCheckout) {
      this.showGuestUserNewAddressForm = !this.sameAsDeliveryAddressCheckbox;
    }
  }

  setDefaultOrSelectedAddressAsFirstAddressCard(): void{



    this.cards$ = this.cards$.pipe(map((addresses) => {

      let firstAddressIndex = 0;

      const defaultAddressIndex = addresses.findIndex(address => address.address.defaultAddress);

      const idToFilter = getLastValueSync(this.selectedAddress$)?.businessId != undefined ? "_" + getLastValueSync(this.selectedAddress$)?.businessId : getLastValueSync(this.selectedAddress$)?.id
      const selectedAddressIndex = addresses.findIndex(address => address.address.id == idToFilter);

      if(selectedAddressIndex >= 0){
        firstAddressIndex = selectedAddressIndex;
      }else {
        firstAddressIndex = defaultAddressIndex;
      }

      const firstAddress = addresses.splice(firstAddressIndex, 1)[0];
      addresses.unshift(firstAddress);

      const pageSize = this.pagination?.pageSize || 0;

      if((selectedAddressIndex +1 ) > pageSize){

        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: {}
        });

      }

      if(firstAddress != undefined && firstAddress.address != undefined){
        this.activeCartFacade.getActive()
          .pipe(
            map(cart => cart.deliveryAddress),
            first()
          )
          .subscribe(deliveryAddress => {
            if(deliveryAddress == undefined) {
              this.setAddress(firstAddress.address);
            }
          });
      }

      return addresses;
    }));
  }

  displayAddressCards(cards: CardWithAddress[]): boolean {
    return cards.length > 0 && cards.some((card) => card != null);
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  protected readonly MtAddressType = MtAddressType;
}
