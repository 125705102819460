import {Injectable, NgModule} from "@angular/core";
import {Store} from "@ngrx/store";
import {StateWithMultiCart} from "@spartacus/cart/base/core";
import {MtCartOccService} from "../../cart/services/mt-cart-occ.service";
import {Subject} from "rxjs";


@Injectable({
  providedIn: 'root',
})
@NgModule({})
export class MtCmsUtilService {

  private elementRenderedSource = new Subject<void>();
  elementRendered$ = this.elementRenderedSource.asObservable();

  constructor(
  ) { }

  notifyElementRendered(){
    this.elementRenderedSource.next();
  }
}
