<div class="form_field_wrapper">

  <div class="cx-summary-heading">
    <label *ngIf="isCheckout; else cartTitle">
      <span>
        {{ 'orderCost.orderSummary' | cxTranslate }}
      </span>
    </label>
    <ng-template #cartTitle>
      <label>
        <span>
          {{ 'orderCost.total' | cxTranslate }}
        </span>
      </label>
    </ng-template>
  </div>

  <div class="cx-summary-partials" *ngIf="cart">

    <div class="cx-summary-row" *ngIf="!isCheckout">
      <div class="cx-summary-label">
        <span class="label">{{ 'orderSummary.quantity' | cxTranslate }}</span>
        <!--    </div>-->
        <!--    <div class="col-6 cx-summary-label">-->
        <span class="value">{{ cart.totalUnitCount }}</span>
      </div>
    </div>


    <div class="cx-summary-row">
      <div class="cx-summary-label">
        <span class="label">{{ 'orderSummary.standardPrice' | cxTranslate }}</span>
        <!--    </div>-->
        <!--    <div class="col-6 cx-summary-amount">-->
        <!--
        <span class="value">{{ cart.subTotal?.formattedValue }}</span>
        -->
        <span class="value">{{ getTotalStandardPrice() | async }}</span>
      </div>
    </div>

    <div class="cx-summary-row">
      <div class="cx-summary-label">

        <!--  <div class="cx-summary-row" *ngIf="cart.totalDiscounts?.value > 0">-->

        <span class="label">{{ 'orderSummary.discounts' | cxTranslate }}</span>
        <!--
    <span class="value">{{ cart.totalDiscounts?.formattedValue }}</span>
    -->
        <span class="value">({{ getFormattedProductDiscounts(cart) | async  }})</span>
      </div>
    </div>

    <div class="cx-summary-row" *ngIf="cart.appliedVouchers?.length">
      <div class="cx-summary-label">

        <span class="label">{{ 'orderSummary.voucherBenefit' | cxTranslate }}</span>
        <span class="value">({{ getFormattedVoucherDiscount(cart) | async  }})</span>
      </div>
    </div>

    <div class="cx-summary-row cx-summary-total">
      <div class="cx-summary-label total-price">
        <span class="label">{{ 'orderSummary.yourNetPrice' | cxTranslate }}</span>
        <!--    </div>-->
        <!--    <div class="col-6 cx-summary-amount">-->
        <span class="value">{{ getFormattedTotalPriceWithTax(cart) | async }}</span>
      </div>
    </div>


    <!--  <ng-template #cartWithoutNet>-->
    <!--    <div class="cx-summary-row">-->
    <!--      {{-->
    <!--        cart.totalPriceWithTax?.value !== cart.totalPrice?.value-->
    <!--          ? ('orderCost.grossTax' | cxTranslate)-->
    <!--          : ('orderCost.grossIncludeTax' | cxTranslate)-->
    <!--      }}-->
    <!--      {{ cart.totalTax?.formattedValue }}-->
    <!--    </div>-->
    <!--  </ng-template>-->

    <p *ngIf="isCheckout">{{ 'orderSummary.infoMessage' | cxTranslate }}</p>


  </div>

  <!--<ng-container *cxFeatureLevel="'1.3'">-->
  <!--  <cx-applied-coupons-->
  <!--    [vouchers]="cart.appliedVouchers"-->
  <!--    [isReadOnly]="true"-->
  <!--  ></cx-applied-coupons>-->
  <!--</ng-container>-->
</div>
