import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, map, tap } from "rxjs/operators";
import { ConvertNavigationNodesService } from "../../services/convert-navigation-nodes.service";
import { NavigationActions } from "../actions";

@Injectable()
export class LoadNavigationNodesEffect {

  loadNavigationNodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActions.LOAD_NAVIGATION_NODES),
      tap(() => console.log('LOAD_NAVIGATION_NODES')),
      exhaustMap((action: NavigationActions.LoadNavigationNodes) =>
        this.convertNavigationNodesService.convertNavigationNode(action.payload.cmsNodes).pipe(
          map(mtNavigationNodeMap => new NavigationActions.SuccessLoadNavigationNodes({
            mtNodesMap: mtNavigationNodeMap,
            selectedNodeCode: action.payload.selectedNodeCode
          })),
          catchError((error) => {
            console.error(`error = ${error}`)
            return of(
              new NavigationActions.ErrorNavigationNodes({ error: error })
            )
          }
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private convertNavigationNodesService: ConvertNavigationNodesService
  ) { }

}
