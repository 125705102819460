<div *ngIf="getAtpEnabled | async as atpEnabled">
  <div *ngIf="atpEnabled && product.cpv !== getMtCPVStatusType.QUOTE">
    <section *ngIf="!(isLoading$ | async); else loading">

      <ng-template #popContent>
        <div [innerHtml]="textToDisplay"></div>
      </ng-template>
      <div
        [ngbPopover]="popContent"
        [openDelay]="300"
        [closeDelay]="500"
        triggers="manual"
        #p="ngbPopover"
        (click)="this.triggerPoppver(p)"
        [popoverClass]="'mt-product-tooltip'"
      >

        <div *ngIf="iconClass == 'stock-in'">
          <!--  Type="atp-in-stock";-->
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9091 0L21.8182 4.38961L10.9091 9.45455L0 4.38961L10.9091 0ZM10.9091 24L9.90909 23.4444L0 17.9394V8.02524V5.81818L2 6.75152L10.9091 10.9091L19.8182 6.75152L21.8182 5.81818V8.02524V12.7386C21.2149 12.3347 20.5407 12.0285 19.8182 11.8426V8.95858L11.9091 12.6495V16.3061C11.7317 16.9003 11.6364 17.5299 11.6364 18.1818C11.6364 18.8337 11.7317 19.4633 11.9091 20.0576V21.1565L12.2554 20.9641C12.5435 21.5767 12.9238 22.1373 13.3784 22.6282L11.9091 23.4444L10.9091 24ZM9.90909 21.1565V12.6495L2 8.95857V16.7626L9.90909 21.1565ZM24 18.1818C24 21.3951 21.3951 24 18.1818 24C14.9685 24 12.3636 21.3951 12.3636 18.1818C12.3636 14.9685 14.9685 12.3636 18.1818 12.3636C21.3951 12.3636 24 14.9685 24 18.1818ZM21.7012 15.7087L18.0648 20.7996L17.5622 21.5032L16.9367 20.9062L14.0276 18.1293L15.0633 17.0443L17.3469 19.224L20.4806 14.8368L21.7012 15.7087Z" fill="#6BB847"/>
          </svg>
          <span class="details-click" *ngIf="showClickForDetails">&nbsp;&nbsp;{{ 'productList.details' | cxTranslate }}</span>
        </div>

        <div *ngIf="iconClass == 'stock-in-partially'">
          <!--Type="atp-partially-available";-->
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9091 0L21.8182 4.38961L10.9091 9.45455L0 4.38961L10.9091 0ZM10.9091 24L9.90909 23.4444L0 17.9394V8.02524V5.81818L2 6.75152L10.9091 10.9091L19.8182 6.75152L21.8182 5.81818V8.02524V12.7386C21.2149 12.3347 20.5407 12.0285 19.8182 11.8426V8.95858L11.9091 12.6495V16.3061C11.7317 16.9003 11.6364 17.5299 11.6364 18.1818C11.6364 18.8337 11.7317 19.4633 11.9091 20.0576V21.1565L12.2554 20.9641C12.5435 21.5767 12.9238 22.1373 13.3784 22.6282L11.9091 23.4444L10.9091 24ZM9.90909 21.1565V12.6495L2 8.95857V16.7626L9.90909 21.1565ZM24 18.1818C24 21.3951 21.3951 24 18.1818 24C14.9685 24 12.3636 21.3951 12.3636 18.1818C12.3636 14.9685 14.9685 12.3636 18.1818 12.3636C21.3951 12.3636 24 14.9685 24 18.1818ZM16.4545 14.5455V18.9091V19.9091H17.4545H20.3636V17.9091H18.4545V14.5455H16.4545Z" fill="#F29301"/>
          </svg>
          <span class="details-click"  *ngIf="showClickForDetails">&nbsp;&nbsp;{{ 'productList.details' | cxTranslate }}</span>
        </div>

        <div *ngIf="(iconClass == 'stock-out')">
          <!--Type="atp-is-not-on-stock";-->
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9091 0L21.8182 4.38961L10.9091 9.45455L0 4.38961L10.9091 0ZM10.9091 24L9.90909 23.4444L0 17.9394V8.02524V5.81818L2 6.75152L10.9091 10.9091L19.8182 6.75152L21.8182 5.81818V8.02524V12.7386C21.2149 12.3347 20.5407 12.0285 19.8182 11.8426V8.95858L11.9091 12.6495V16.3061C11.7317 16.9003 11.6364 17.5299 11.6364 18.1818C11.6364 18.8337 11.7317 19.4633 11.9091 20.0576V21.1565L12.2554 20.9641C12.5435 21.5767 12.9238 22.1373 13.3784 22.6282L11.9091 23.4444L10.9091 24ZM9.90909 21.1565V12.6495L2 8.95857V16.7626L9.90909 21.1565ZM24 18.1818C24 21.3951 21.3951 24 18.1818 24C14.9685 24 12.3636 21.3951 12.3636 18.1818C12.3636 14.9685 14.9685 12.3636 18.1818 12.3636C21.3951 12.3636 24 14.9685 24 18.1818ZM21.0909 16.1039L20.2597 15.2727L18.1818 17.3506L16.1039 15.2727L15.2727 16.1039L17.3506 18.1818L15.2727 20.2597L16.1039 21.0909L18.1818 19.013L20.2597 21.0909L21.0909 20.2597L19.013 18.1818L21.0909 16.1039Z" fill="#F29301"/>
          </svg>
          <span class="details-click"  *ngIf="showClickForDetails">&nbsp;&nbsp;{{ 'productList.details' | cxTranslate }}</span>
        </div>

        <div *ngIf="iconClass == 'stock-in-for-lead'">
          <!--Type="atp-materials-w-lead-time";-->
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9091 0L21.8182 4.38961L10.9091 9.45455L0 4.38961L10.9091 0ZM10.9091 24L9.90909 23.4444L0 17.9394V8.02524V5.81818L2 6.75152L10.9091 10.9091L19.8182 6.75152L21.8182 5.81818V8.02524V12.7386C21.2149 12.3347 20.5407 12.0285 19.8182 11.8426V8.95858L11.9091 12.6495V16.3061C11.7317 16.9003 11.6364 17.5299 11.6364 18.1818C11.6364 18.8337 11.7317 19.4633 11.9091 20.0576V21.1565L12.2554 20.9641C12.5435 21.5767 12.9238 22.1373 13.3784 22.6282L11.9091 23.4444L10.9091 24ZM9.90909 21.1565V12.6495L2 8.95857V16.7626L9.90909 21.1565ZM24 18.1818C24 21.3951 21.3951 24 18.1818 24C14.9685 24 12.3636 21.3951 12.3636 18.1818C12.3636 14.9685 14.9685 12.3636 18.1818 12.3636C21.3951 12.3636 24 14.9685 24 18.1818ZM16.4545 14.5455V18.9091V19.9091H17.4545H20.3636V17.9091H18.4545V14.5455H16.4545Z" fill="#888888"/>
          </svg>
          <span class="details-click"  *ngIf="showClickForDetails">&nbsp;&nbsp;{{ 'productList.details' | cxTranslate }}</span>
        </div>

        <div *ngIf="(iconClass == 'stock-out-always') || (iconClass == 'incorrect-atp-response')">
          <!--Type="atp-info";-->
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9091 0L21.8182 4.38961L10.9091 9.45455L0 4.38961L10.9091 0ZM10.9091 24L9.90909 23.4444L0 17.9394V8.02524V5.81818L2 6.75152L10.9091 10.9091L19.8182 6.75152L21.8182 5.81818V8.02524V12.7386C21.2149 12.3347 20.5407 12.0285 19.8182 11.8426V8.95858L11.9091 12.6495V16.3061C11.7317 16.9003 11.6364 17.5299 11.6364 18.1818C11.6364 18.8337 11.7317 19.4633 11.9091 20.0576V21.1565L12.2554 20.9641C12.5435 21.5767 12.9238 22.1373 13.3784 22.6282L11.9091 23.4444L10.9091 24ZM9.90909 21.1565V12.6495L2 8.95857V16.7626L9.90909 21.1565ZM24 18.1818C24 21.3951 21.3951 24 18.1818 24C14.9685 24 12.3636 21.3951 12.3636 18.1818C12.3636 14.9685 14.9685 12.3636 18.1818 12.3636C21.3951 12.3636 24 14.9685 24 18.1818ZM19.1818 15.3287V14.5455H17.1818V15.3287V16H19.1818V15.3287ZM19.1818 19.6364V17.0909H17.1818V19.6364V21.8182H19.1818V19.6364Z" fill="#888888"/>
          </svg>
          <span class="details-click" *ngIf="showClickForDetails">&nbsp;&nbsp;{{ 'productList.details' | cxTranslate }}</span>
        </div>

      </div>

    </section>

    <ng-template #loading>
      <div class="cx-dialog-row">
        <div id="load"></div>
      </div>
    </ng-template>

  </div>
</div>


