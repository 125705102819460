import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CartNotEmptyGuard, CheckoutProgressComponent } from '@spartacus/checkout/base/components';
import { CheckoutAdapter, CheckoutConnector, CheckoutDeliveryAddressAdapter, CheckoutDeliveryAddressConnector, CheckoutQueryService } from '@spartacus/checkout/base/core';
import { OccCheckoutAdapter, OccCheckoutDeliveryAddressAdapter } from '@spartacus/checkout/base/occ';
import { CheckoutDeliveryAddressFacade, CheckoutQueryFacade } from '@spartacus/checkout/base/root';
import { Address, CmsConfig, OccConfig, provideConfig } from '@spartacus/core';
import { MtCheckoutDeliveryAddressModule } from '../base/components/checkout-delivery-address/mt-checkout-delivery-address.module';
import { MtCheckoutPaymentDetailsModule } from '../base/components/checkout-payment-details/mt-checkout-payment-details.module';
import { MtCheckoutProgressMobileBottomModule } from '../base/components/checkout-progress/mt-checkout-progress-mobile-bottom/mt-checkout-progress-mobile-bottom.module';
import { MtCheckoutProgressMobileTopModule } from '../base/components/checkout-progress/mt-checkout-progress-mobile-top/mt-checkout-progress-mobile-top.module';
import { MtCheckoutStepsSetGuard } from '../base/guards/mt-checkout-steps-set.guard';
import { MtCheckoutBillingAddressService } from "../core/facade/mt-checkout-billing-address.service";
import { MtCheckoutDeliveryAddressService } from '../core/facade/mt-checkout-delivery-address.service';
import { mtCheckoutConfig } from './config/mt-checkout.config';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MtCheckoutDeliveryAddressModule,
    MtCheckoutPaymentDetailsModule,
    MtCheckoutProgressMobileTopModule,
    MtCheckoutProgressMobileBottomModule,
    RemoveAddressDialogModule
  ],
  providers: [
    provideConfig(mtCheckoutConfig),

    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutProgress: {
          component: CheckoutProgressComponent,
          guards: [CartNotEmptyGuard, MtCheckoutStepsSetGuard],
        },
      },
    }),

    provideConfig(<OccConfig>{
      backend: {
        occ: {
          endpoints: {
            carts:
              'users/${userId}/carts?fields=carts(BASIC,deliveryAddress(FULL),deliveryMode(FULL),paymentType(FULL),potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user,saveTime,name,description)',
            cart: 'users/${userId}/carts/${cartId}?fields=FULL,deliveryAddress(FULL),deliveryMode(FULL),paymentType(FULL),potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user,saveTime,name,description',
            getCheckoutDetails:
              //'users/${userId}/carts/${cartId}?fields=deliveryAddress(FULL),deliveryMode(FULL),paymentInfo(FULL),costCenter(FULL),purchaseOrderNumber,paymentType(FULL)',
              'users/${userId}/carts/${cartId}?fields=FULL,deliveryAddress(FULL),deliveryMode(FULL),paymentType(FULL),paymentInfo(FULL)',
            setDeliveryAddress:
              'orgUsers/${userId}/carts/${cartId}/addresses?addressType=SHIP_TO',
            setBillingAddress:
              'orgUsers/${userId}/carts/${cartId}/addresses?addressType=BILL_TO',
            editDeliveryAddress:
              'users/${userId}/carts/${cartId}/addresses/${addressId}?addressType=SHIP_TO',
            editBillingAddress:
              'users/${userId}/carts/${cartId}/addresses/${addressId}?addressType=BILL_TO',
            //'users/${userId}/carts/${cartId}/addresses',
            createDeliveryAddress:
              //'orgUsers/${userId}/carts/${cartId}/addresses',
              'users/${userId}/carts/${cartId}/addresses',
            createBillingAddress:
              'users/${userId}/carts/${cartId}/addresses',
            addresses: 'users/${userId}/addresses',//TODO: to be continued
            addressesWithAddressType: 'users/${compUserId}/${compAddressType}/addresses',
            addressesWithAddressTypeAndCartId: 'users/${compUserId}/carts/${cartId}/${compAddressType}/addresses',
            paymentMethod: 'users/${userId}/carts/${cartId}/paymentMethod',
            orderSimulation: 'users/${userId}/carts/${cartId}/simulation',
            deleteDeliveryAddress: 'users/${userId}/addresses/${addressId}?addressType=SHIP_TO',
            deleteBillingAddress: 'users/${userId}/addresses/${addressId}?addressType=BILL_TO',
            setDefaultBillingAddress: 'users/${userId}/addresses/setDefaultAddress/${addressId}?addressType=BILL_TO',
            setDefaultDeliveryAddress: 'users/${userId}/addresses/setDefaultAddress/${addressId}/?addressType=SHIP_TO',
            placeOrderCardPayment: 'users/${userId}/carts/${cartId}/paymetric/perform'
          }
        }
      },
    }),


    CheckoutQueryService,
    {
      provide: CheckoutQueryFacade,
      useClass: CheckoutQueryService,
    },
    CheckoutConnector,
    {
      provide: CheckoutAdapter,
      useClass: OccCheckoutAdapter,
    },
    {
      provide: CheckoutDeliveryAddressAdapter,
      useClass: OccCheckoutDeliveryAddressAdapter,
    },
    CheckoutDeliveryAddressConnector,
    MtCheckoutDeliveryAddressService,
    MtCheckoutBillingAddressService,
    MtOccCheckoutBillingAddressAdapter,
    MtCheckoutPaymentService,
    {
      provide: CheckoutDeliveryAddressFacade,
      useExisting: MtCheckoutDeliveryAddressService,
    },
    MtOccCheckoutPlaceOrderAdapter
  ]
})
export class MtCheckoutRootModule {

}

import { CheckoutState } from "@spartacus/checkout/base/root/model";
import { RemoveAddressDialogModule } from "../../user/account/components/remove-address/mt-remove-address.module";
import { MtOccCheckoutBillingAddressAdapter } from "../base/occ/adapters/mt-occ-checkout-billing-address.adapter";
import { MtOccCheckoutPlaceOrderAdapter } from '../base/occ/adapters/mt-occ-checkout-place-order.adapter';
import { MtCheckoutPaymentService } from '../core/facade/mt-checkout-payment.service';

declare module '@spartacus/checkout/base/root/model' {
  interface CheckoutState {
    billingAddress?: Address;
  }
}
