/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { MtReactivateAccountComponentService } from './mt-reactivate-account-component.service';
import {RoutingService} from "@spartacus/core";
import { MtReactivateAccountService } from '../../core/mt-reactivate-account.service';

@Component({
  selector: 'mt-reactivate-account',
  templateUrl: './mt-reactivate-account.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'user-form' },
  styleUrls: ['./mt-reactivate-account.component.scss']
})
export class MtReactivateAccountComponent {
  form: UntypedFormGroup = this.service.form;
  isUpdating$: Observable<boolean> = this.service.isUpdating$;

  constructor(
    protected service: MtReactivateAccountComponentService,
    protected routingService: RoutingService, 
    protected mtReactivateAccount: MtReactivateAccountService
  ) { }

  onSubmit() {
    this.service.reactivateAccount();
  }

}
