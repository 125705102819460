/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { CheckoutDatePickerComponent } from './checkout-date-picker.component';
import {ReactiveFormsModule} from "@angular/forms";
import {FormErrorsModule} from "@spartacus/storefront";

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    ReactiveFormsModule,
    FormErrorsModule,
  ],
  declarations: [CheckoutDatePickerComponent],
  exports: [CheckoutDatePickerComponent],
})
export class CheckoutDatePickerModule {}
