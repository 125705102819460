<!-- START: login wrapper (Lives in AEM not Spartacus)-->
<!--<div class="top_meta_layer" style="display:flex;justify-content:center;">-->

<ng-container *ngIf="user$ | async as user; else notLoggedIn">
  <!--Logout (Spartacus)-->
  <div class="login_reg_info"
    data-mt-login-href="/content/us/en/home/registered_user_services/visitor_registration/loginsso.html">
    <button id="loginLink" style="" class="loginIcon loggedInUser" style="display:none;" (click)="iconClick()">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 3C11.66 3 13 4.34 13 6C13 7.66 11.66 9 10 9C8.34 9 7 7.66 7 6C7 4.34 8.34 3 10 3ZM10 17.2C7.5 17.2 5.29 15.92 4 13.98C4.03 11.99 8 10.9 10 10.9C11.99 10.9 15.97 11.99 16 13.98C14.71 15.92 12.5 17.2 10 17.2Z"
          fill="#004494"></path>
      </svg>
      <div class="loggedInUserLetter">
        <!-- The span below includes the first character in the user's name -->
        <span>{{ (user.firstName)?.charAt(0)?.toUpperCase() }}</span>
      </div>
    </button>
    <div class="login_reg_info-menu loggedInMenu" *ngIf="iconClicked" tabindex="-1" (focusout)="focusOut($event)"
      #loginmenu [attr.init]="loginmenu.focus()">
      <ul>
        <li>
          <a id="logged-in-account-button" *ngIf="isSpartacusContext" routerLink="/account" (click)="iconClick()">
            {{ 'mtUserAccountAndLogin.login.labels.myAccountLink' | cxTranslate }}
          </a>
          <a id="logged-in-account-button" *ngIf="!isSpartacusContext" href="{{ getURL({ cxRoute: 'account' }) }}"
            (click)="iconClick()">
            {{ 'mtUserAccountAndLogin.login.labels.myAccountLink' | cxTranslate }}
          </a>
        </li>
        <li>
          <a id="logout-button" *ngIf="isSpartacusContext" routerLink="/logout" (click)="clearSoldTo()">
            {{ 'mtUserAccountAndLogin.login.labels.logoutLink' | cxTranslate }}
          </a>
          <a id="logout-button" *ngIf="!isSpartacusContext" href="{{ getURL({ cxRoute: 'logout' }) }}"
            (click)="clearSoldTo()">
            {{ 'mtUserAccountAndLogin.login.labels.logoutLink' | cxTranslate }}
          </a>
        </li>
        <li>
          <a id="customer-portal-button" href="{{getCustomerPortalURL('/login/customerPortal')}}" (click)="iconClick()">
            {{ 'mtUserAccountAndLogin.login.labels.customerPortalLink' | cxTranslate }}
          </a>
        </li>
      </ul>
    </div>
  </div>
  <!--End: Logout (Spartacus)-->
</ng-container>

<ng-template #notLoggedIn>
  <!--Login (Spartacus)-->
  <div class="login_reg_info"
    data-mt-login-href="/content/us/en/home/registered_user_services/visitor_registration/loginsso.html">
    <button id="loginLink" style="" class="loginIcon" style="display:none;" (click)="iconClick()">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 3C11.66 3 13 4.34 13 6C13 7.66 11.66 9 10 9C8.34 9 7 7.66 7 6C7 4.34 8.34 3 10 3ZM10 17.2C7.5 17.2 5.29 15.92 4 13.98C4.03 11.99 8 10.9 10 10.9C11.99 10.9 15.97 11.99 16 13.98C14.71 15.92 12.5 17.2 10 17.2Z"
          fill="#004494"></path>
      </svg>
      <div class="loggedInUserLetter">
        <span></span>
      </div>
    </button>
    <div class="login_reg_info-menu loggedOutMenu" *ngIf="iconClicked" tabindex="-1" (focusout)="focusOut($event)"
      #loginmenu [attr.init]="loginmenu.focus()">
      <ul>
        <li>
          <a *ngIf="isSpartacusContext" id="go-to-login-button" routerLink="/login" (click)="iconClick()">
            {{ 'mtUserAccountAndLogin.login.labels.myMTComLoginLink' | cxTranslate }}
          </a>
          <a *ngIf="!isSpartacusContext" id="go-to-login-button" href="{{ getURL({ cxRoute: 'login' }) }}"
            (click)="iconClick()">
            {{ 'mtUserAccountAndLogin.login.labels.myMTComLoginLink' | cxTranslate }}
          </a>
          <p>{{ 'mtUserAccountAndLogin.login.labels.myMTComLoginDescription' | cxTranslate }}</p>
        </li>
        <li>
          <a id="go-to-login-button" href="{{getCustomerPortalURL('/login/customerPortal')}}" (click)="iconClick()">
            {{ 'mtUserAccountAndLogin.login.labels.customerPortalLoginLink' | cxTranslate }}
          </a>
          <p>{{ 'mtUserAccountAndLogin.login.labels.customerPortalLoginDescription' | cxTranslate }}</p>
        </li>
      </ul>
    </div>
    <!-- Spacer -->
    <span style="margin: 0px 4px"></span>
  </div>
</ng-template>

<!--   END: login wrapper (Lives in AEM not Spartacus)-->
<!--</div>-->
