import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContentPageMetaResolver, PageMetaConfig, ProductPageMetaResolver, provideConfig } from "@spartacus/core";
import { SeoMetaService } from "@spartacus/storefront";
import { MtContentPageMetaResolver } from "./resolvers/mt-content-page-meta-resolver";
import { MtProductPageMetaResolver } from "./resolvers/mt-product-page-meta-resolver";
import { MtSeoMetaService } from "./services/mt-seo-meta.service";


declare module '@spartacus/core' {
  interface PageMeta {
    toppath: string;
    locale: string;
    category: string;
    priority: string;
    matNum: string;
  }
}

export const swiftypePageMetaConfig: PageMetaConfig = {
  pageMeta: {

    enableInDevMode: true,

    resolvers: [
      {
        property: 'title',
        method: 'resolveTitle',
      },
      {
        property: 'heading',
        method: 'resolveHeading',
      },
      {
        property: 'breadcrumbs',
        method: 'resolveBreadcrumbs',
      },
      {
        property: 'description',
        method: 'resolveDescription',
        disabledInCsr: true,
      },
      {
        property: 'image',
        method: 'resolveImage',
        disabledInCsr: true,
      },
      {
        property: 'robots',
        method: 'resolveRobots',
        disabledInCsr: true,
      },
      {
        property: 'canonicalUrl',
        method: 'resolveCanonicalUrl',
        disabledInCsr: true,
      },

      {
        property: 'toppath',
        method: 'revolveToppath',
      },
      {
        property: 'locale',
        method: 'revolveLocale',
      },
      {
        property: 'category',
        method: 'revolveCategory',
      },
      {
        property: 'priority',
        method: 'revolvePriority',
      },
      {
        property: 'matNum',
        method: 'resolveMatNum'
      },
      {
        property: 'reportData',
        method: 'resolveReportData'
      }
    ],
    canonicalUrl: {
      forceHttps: true,
      forceWww: false,
      removeQueryParams: true,
      forceTrailingSlash: true,
    },
  }
}


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    provideConfig(swiftypePageMetaConfig),

    {
      provide: SeoMetaService,
      useClass: MtSeoMetaService
    },
    {
      provide: ProductPageMetaResolver,
      useClass: MtProductPageMetaResolver,
    },
    {
      provide: ContentPageMetaResolver,
      useClass: MtContentPageMetaResolver,
    }


  ],
})
export class MtSeoModule { }
