import {Injectable, OnDestroy} from '@angular/core';
import {filter, shareReplay, switchMap, take, tap, withLatestFrom,} from 'rxjs/operators';
import {
  AuthHttpHeaderService,
  AuthRedirectService,
  AuthStorageService,
  AuthToken,
  GlobalMessageService,
  OAuthLibWrapperService,
  OccEndpointsService,
  RoutingService
} from "@spartacus/core";
import {MtOAuthService} from "../oauth/mt-oauth-service";
import {LAUNCH_CALLER, LaunchDialogService} from "@spartacus/storefront";
import {MtAuthService} from "./mt-auth-service";
import {EMPTY, Observable} from "rxjs";
import {HttpEvent, HttpHandler, HttpRequest} from "@angular/common/http";
import {
  SessionLogoutDialogComponent
} from "./user-auth/components/session-logout/session-logout-dialog/session-logout-dialog.component";
import {MtAuthConfigService} from "./mt-auth-config.service";
import {OAuthSuccessEvent} from "angular-oauth2-oidc";

@Injectable({
  providedIn: 'root',
})
export class MtAuthHttpHeaderService extends AuthHttpHeaderService implements OnDestroy {

  sessionLogoutInMs = 1800000;

  dialog: Observable<any> = EMPTY;

  constructor(
    authStorageService: AuthStorageService,
    oAuthLibWrapperService: OAuthLibWrapperService,
    routingService: RoutingService,
    occEndpoints: OccEndpointsService,
    globalMessageService: GlobalMessageService,
    authRedirectService: AuthRedirectService,
    protected mtauthService: MtAuthService,
    protected oauthService: MtOAuthService,
    protected launchDialogService: LaunchDialogService,
    protected mtAuthConfigService: MtAuthConfigService
  ) {
    super(mtauthService, authStorageService, oAuthLibWrapperService, routingService, occEndpoints, globalMessageService, authRedirectService);
    if (this.mtAuthConfigService.getSessionLogoutInSec()) {
      this.sessionLogoutInMs = this.mtAuthConfigService.getSessionLogoutInSec() * 1000;
    }
  }


  /**
   * Refreshes access_token and then retries the call with the new token.
   */
  public override handleExpiredAccessToken(
    request: HttpRequest<any>,
    next: HttpHandler,
    initialToken: AuthToken | undefined
  ): Observable<HttpEvent<AuthToken>> {
    //check if the dialog is already open and if so, way for next OauthEvent
    if(this.dialog != EMPTY)
    {
      return this.oauthService.events.pipe(switchMap((e) => {
        if(e instanceof OAuthSuccessEvent)
        {
          return this.authStorageService.getToken().pipe(switchMap((token) => {
           return next.handle(this.createNewRequestWithNewToken(request, token));
          }))
        }
        return EMPTY;
      }));
    }
    //otherwise continue with popup or simply refresh
    if (initialToken?.access_token_stored_at && (Date.now() - parseInt(initialToken.access_token_stored_at) > this.sessionLogoutInMs)) {
      this.authRedirectService.saveCurrentNavigationUrl();
      return this.mtauthService.isLoggedInWithRememberMe().pipe(switchMap((rememberMe) => {
        if (rememberMe) {
          const dialogData = {
            rememberMe: true,
            doRefresh: undefined
          }
          this.dialog = this.launchDialogService.openDialog(LAUNCH_CALLER.SESSION_LOGOUT, undefined, undefined, dialogData) || EMPTY;
          if (this.dialog != EMPTY) {
            return this.dialog.pipe(take(1), switchMap((res: SessionLogoutDialogComponent) => {
              return this.launchDialogService.dialogClose.pipe(take(1), switchMap((data: { doRefresh?: boolean }) => {
                this.dialog = EMPTY;
                if (data.doRefresh) {
                  return super.handleExpiredAccessToken(request, next, initialToken);
                } else {
                  this.authService.coreLogout().finally(() => {
                    this.routingService.go({cxRoute: 'login'});
                  });
                  return EMPTY;
                }
              }))
            }))
          } else {
            return EMPTY;
          }
        } else {
          const dialogData = {
            rememberMe: false,
          }
          this.dialog = this.launchDialogService.openDialog(LAUNCH_CALLER.SESSION_LOGOUT, undefined, undefined, dialogData) || EMPTY
          if (this.dialog != EMPTY) {
            this.dialog.pipe(take(1)).subscribe(() => {this.dialog = EMPTY});
          }
          this.authService.coreLogout().finally(() => {
            this.routingService.go({cxRoute: 'login'});
          });
          return EMPTY;
        }
      }));
    } else {
      return super.handleExpiredAccessToken(request, next, initialToken);
    }

  }


  protected override getValidToken(requestToken: AuthToken | undefined): Observable<AuthToken | undefined> {
    return super.getValidToken(requestToken).pipe(shareReplay());
  }

  protected override refreshToken$ = this.refreshTokenTrigger$.pipe(
    withLatestFrom(
      this.authService.refreshInProgress$,
      this.authService.logoutInProgress$
    ),
    filter(
      ([, refreshInProgress, logoutInProgress]) =>
        !refreshInProgress && !logoutInProgress
    ),
    tap(() => {
      this.oauthService.refreshToken().catch(err => {
        this.handleExpiredRefreshToken()
      })
      this.authService.setRefreshProgress(true);
    })
  );


  public override handleExpiredRefreshToken(): void {
    this.authRedirectService.saveCurrentNavigationUrl();
    this.authService.coreLogout().finally(() => {
      this.routingService.go({cxRoute: 'login'});
    });
  }

}
