/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { LoadNavigationNodesEffect } from './load-navigation-nodes.effect';

export const effects: any[] = [
  LoadNavigationNodesEffect
];
