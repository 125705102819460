import {mtProductATP} from "./mt-product-atp.i18n";

export const mtProductItemtList = {
  labels: {
    quotationAction: 'Angebot anfordern',
    orderAction: 'Zum Warenkorb hinzufügen',
    requestProductInfoAction: 'Infos anfordern',
    noResults: 'Ergebnisse ({{noFoundResults}})',
    searchResults: 'Suchergebnisse ({{noFoundResults}})',
    yourPrice: 'Ihr Preis',
    standardPrice: 'Standardpreis',
    infoLoginPrice: '<a style="color: #004494 !important;" href="/shop/{{currentCountry}}/{{currentLanguageIso}}/login">Einloggen</a><span>&nbsp;für alle Vorteile oder um mit unseren Experten zu chatten.</span>',
    filterButton:'Filter',
    filterClear:'Clear Filters',
    showResultsFound: 'Show Results ({{noFoundResults}})',
    displayText:'Als registrierter Benutzer können Sie von folgenden Vorteilen profitieren:',
    spanText:'- Einsehen unternehmensspezifischer Preise',
    spanText2:'- Zahlung auf Rechnung',
    spanText3:'- Zugang zu <a href="/shop/{{currentCountry}}/{{currentLanguageIso}}/login">mymt.com </a> mit nützlichen Funktionen wie eine persönliche Dokumentensammlung, neuste News oder eine Bestellübersicht'
  }
}

export const mtProductNoItemsFound = {
  texts: {
    noResultsLabel: 'Keine Suchergebnisse',
    searchTips: 'Suchtipps',
    hints: {
      hint1: 'Stellen Sie sicher, dass alle Wörter richtig geschrieben sind',
      hint2: 'Probieren Sie verschiedene Keywords aus',
      hint3: 'Versuchen Sie es mit allgemeineren Keywords',
      hint4: 'Versuchen Sie es mit weniger Keywords'
    }
  }
}

export const mtItemsPerPage = {
  items: {
    label: 'Zeige {{pageSize}} pro Seite an'
  }
}

export const mtProductListResource = {
  mtProductItemtList,
  mtProductNoItemsFound,
  mtItemsPerPage,
  mtProductATP
}
