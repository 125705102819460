/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import {ChangeDetectionStrategy, Component} from '@angular/core';
import {CloseAccountComponent} from "@spartacus/user/profile/components";
import {LAUNCH_CALLER} from "@spartacus/storefront";
import {take} from "rxjs/operators";


@Component({
  selector: 'mt-deactivate-account',
  templateUrl: './mt-deactivate-account.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./mt-deactivate-account.component.scss']
})
export class MtDeactivateAccountComponent extends CloseAccountComponent {
  override openModal(): void {
    const dialog = this.launchDialogService.openDialog(
      LAUNCH_CALLER.DEACTIVATE_ACCOUNT,
      this.element,
      this.vcr
    );

    if(dialog) {
      dialog.pipe(take(1)).subscribe();
    }
  }
}
