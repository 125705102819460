import { Injectable } from "@angular/core";
import { BaseSiteService, CurrencyService, LanguageService } from "@spartacus/core";
import { CountryBasesiteConvertor } from "../country-site-context/country-basesite.convertor";
import { AemAdapterSiteContextConfig } from "./aem-adapter-site-context.config";


@Injectable({ providedIn: 'root' })
export class AemAdapterSiteContextService {

  protected buildKey(country: string, language: string): string {
    return `${country}_${language}`;
  }


  constructor(
    protected config: AemAdapterSiteContextConfig,
    protected convertor: CountryBasesiteConvertor,
    protected baseSiteService: BaseSiteService,
    protected currencyService: CurrencyService,
    protected languageService: LanguageService
  ) { }


  setupShopSiteContextFromAEMContext(country: string, language: string) {
    const key =
      this.buildKey(country ?? this.config.defaultCountry, language ?? this.config.defaultLanguage);
    const localeConfig = (this.config.aemSiteContext && this.config.aemSiteContext[key]);

    console.log(`setupShopSiteContextFromAEMContext - key = ${key}`);
    console.log(`setupShopSiteContextFromAEMContext - config = ${JSON.stringify(localeConfig)}`);

    if (localeConfig) {

      // Setup base site using country parameter
      const keyCountry = localeConfig.country ?? (country ?? this.config.defaultCountry);
      const baseSiteId =
        this.convertor.countryToBaseSiteId(keyCountry);
      this.baseSiteService.setActive(baseSiteId);
      console.log(`setupShopSiteContextFromAEMContext - country/baseSiteId = ${keyCountry}/${baseSiteId}`);

      // Setup currency
      this.currencyService.setActive(localeConfig.currency);

      // Setup language
      this.languageService.setActive(localeConfig.language);

    } else {
      console.error(`Context convertion between AEM and Commerce was not posible for key: ${key}`);
    }
  }

  /**
   * Verify if the language code is valid; if not it will be returned the default value.
   *
   * @param country
   * @param languageIso
   * @returns
   */
  checkLanguageIso(country: string, languageIso: string): string {
    let validLanguageIso = languageIso;

    const key =
      this.buildKey(country ?? this.config.defaultCountry, languageIso ?? this.config.defaultLanguage);
    const localeConfig = (this.config.aemSiteContext && this.config.aemSiteContext[key]);

    // check if the combination country/language is valid
    if (localeConfig === undefined) {
      if (this.config.defaultLanguageIso !== undefined && this.config.defaultLanguageIso[country] !== undefined) {
        validLanguageIso = this.config.defaultLanguageIso[country];
      } else {
        validLanguageIso = 'en';
      }
    }

    console.log(`validLanguageIso = ${validLanguageIso} - ${key} - ${localeConfig}`)

    return validLanguageIso;
  }

}
