/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import {CloseAccountModalComponent} from "@spartacus/user/profile/components";

@Component({
  selector: 'mt-deactivate-account-modal',
  templateUrl: './mt-deactivate-account-modal.component.html',
  styleUrls: ['./mt-deactivate-account-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MtDeactivateAccountModalComponent extends CloseAccountModalComponent {

}
