/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const mtOrgRegistrationForm = {

  fields: {
    company: {
      label: 'Bedrijfsnaam*',
      placeholder: 'Verplicht veld',
    },
    company2: {
      label: 'Bedrijfsnaam 2',
      placeholder: '',
    },
    company3: {
      label: 'Bedrijfsnaam 3',
      placeholder: '',
    },
    country: {
      label: 'Land*',
      placeholder: 'Verplicht veld',
      hint: 'Dit is jouw land. Als u uw account voor een ander land wilt registreren, gaat u naar de website voor dat land. U kunt het land wijzigen door op de vlag rechtsboven op de pagina te klikken.',
    },
    addressLine1: {
      label: 'Adres 1*',
      placeholder: 'Verplicht veld',
    },
    addressLine2: {
      label: 'Adres 2',
      placeholder: '',
    },
    addressLine3: {
      label: 'Adres 3',
      placeholder: '',
    },
    postalCode: {
      label: 'Postcode',
      placeholder: 'Verplicht veld',
    },
    state: {
      label: 'Provincie',
      placeholder: 'Verplicht veld',
    },
    city: {
      label: 'Plaats*',
      placeholder: 'Verplicht veld',
    },
    phoneNumber: {
      label: 'Direct telefoonnummer*',
      placeholder: 'Verplicht veld',
    },
    poNumber: {
      label: 'Postbus',
      placeholder: '',
    },
    poCountry: {
      label: 'Land*',
      placeholder: '',
    },
    poState: {
      label: 'Provincie',
      placeholder: '',
    },
    poPostalCode: {
      label: 'Postcode*',
      placeholder: ''
    }
  },

  labels:{
    companyRegistration: 'Bedrijf registratie',
    registerLabel1: 'Gefeliciteerd met het aanmaken van uw gebruiker, u kunt nu uw bedrijf registreren.',
    registerLabel2: 'Registreer uw bedrijf om te profiteren van ons uitgebreide digitale aanbod',
    laterLink: 'Bedrijf later inschrijven',
    saveAsShippingAddress: 'Opslaan als afleveradres',
    saveAsBillingAddress: 'Opslaan als factuuradres',
    addPoBillingAddress: 'Postbusfactuuradres toevoegen',
    saveAsDefault: 'Instellen als standaard',
    cancel: 'Annuleren',
    save: 'Opslaan',
  },

  formSubmitButtonLabel: 'Bedrijf registratie',
};

export const mtOrgRegistration = {
  mtOrgRegistrationForm,
};
