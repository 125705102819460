/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { MtResetPasswordComponentService } from './mt-reset-password-component.service';

@Component({
  selector: 'mt-reset-password',
  templateUrl: './mt-reset-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // host: { class: 'user-form' },
  styleUrls: ['./mt-reset-password.component.scss'],
})
export class MtResetPasswordComponent {
  form: UntypedFormGroup = this.service.form;
  isUpdating$: Observable<boolean> = this.service.isUpdating$;

  token$: Observable<string> = this.service.resetToken$;
  userEMail$: Observable<string> = this.service.resetUserEmail$;

  constructor(protected service: MtResetPasswordComponentService) { }

  onSubmit(token: string) {
    this.service.resetPassword(token);
  }
}
