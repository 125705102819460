/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  AuthGuard,
  CmsConfig, ConfigModule,
  GlobalMessageService,
  I18nModule, OccConfig, provideConfig,
  provideDefaultConfig,
  UrlModule,
} from '@spartacus/core';
import {
  FormErrorsModule,
  SpinnerModule,
  NgSelectA11yModule,
} from '@spartacus/storefront';
import { MtUpdateProfileComponent } from './mt-update-profile.component';
import {MtUpdateProfileComponentService} from "./mt-update-profile-component.service";
import { MtForbiddenCharactersOccService } from 'src/app/spartacus/custom-module/util/core/mt-forbidden-characters-occ.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SpinnerModule,
    I18nModule,
    FormErrorsModule,
    RouterModule,
    UrlModule,
    NgSelectModule,
    NgSelectA11yModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        UpdateProfileComponent: {
          component: MtUpdateProfileComponent,
          guards: [AuthGuard],
        },
      },
    }),
  ],
  providers: [
    provideConfig(<OccConfig>{
      backend: {
        occ: {
          endpoints: {
            userUpdateProfile: 'orgUsers/${userId}/updateProfile',
          }
        }
      },
    }),

    MtUpdateProfileComponentService,
    MtForbiddenCharactersOccService,
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        UpdateProfileComponent: {
          component: MtUpdateProfileComponent,
          guards: [AuthGuard],
        },
      },
    }),
  ],
  declarations: [MtUpdateProfileComponent],
})
export class MtUpdateProfileModule {}
