import { KEY_ROOT, MtNavigationNode } from "../../model/mt-navigation-node.model";
import { NavigationActions } from "../actions";
import { HomepageNavigationState } from "../navigation-state";


export const initialState: HomepageNavigationState = {
};

export function reducer(
  state: HomepageNavigationState = initialState,
  action: NavigationActions.NavigationActions
): HomepageNavigationState {

  switch (action.type) {
    case NavigationActions.SUCCESS_LOAD_NAVIGATION_NODES:
      console.log(`navigation reducer SUCCESS_LOAD_NAVIGATION_NODES`);

      const loadAction: NavigationActions.SuccessLoadNavigationNodes =
        action as NavigationActions.SuccessLoadNavigationNodes;
      const crtRootNode: MtNavigationNode | undefined =
        loadAction.payload.mtNodesMap.get(KEY_ROOT);
      const foundLoadNode: MtNavigationNode | undefined =
        loadAction.payload.mtNodesMap.get(loadAction.payload.selectedNodeCode ?? KEY_ROOT) || crtRootNode;

      console.log(`SUCCESS_LOAD_NAVIGATION_NODES_selectedNode = ${loadAction.payload.selectedNodeCode ?? KEY_ROOT}`);

      return {
        rootNode: crtRootNode,
        selectedNode: foundLoadNode,
        nodes: loadAction.payload.mtNodesMap
      }
      break;

    case NavigationActions.NAVIGAT_TO_NODE:
      console.log(`navigation reducer NAVIGAT_TO_NODE`);

      const navigateTotNodeAction: NavigationActions.NavigateToNode =
        action as NavigationActions.NavigateToNode;
      const foundNode: MtNavigationNode | undefined =
        state?.nodes?.get(navigateTotNodeAction.payload.nodeCode ?? KEY_ROOT) || state?.rootNode;

      //console.log(`foundNode = ${JSON.stringify(foundNode)}`)
      console.log(`NAVIGAT_TO_NODE_selectedNode = ${navigateTotNodeAction.payload.nodeCode ?? KEY_ROOT}`);

      return {
        ...state,
        selectedNode: foundNode
      }
      break;

  }

  return state;
}

export const getSelectedNode = (
  state: HomepageNavigationState
): MtNavigationNode => {
  return ((state?.selectedNode) || state?.rootNode) || {} as MtNavigationNode;
};

export const getChildrenForSelectedNode = (state: HomepageNavigationState): MtNavigationNode[] => {
  const children: string[] =
    (((state?.selectedNode) || state?.rootNode) || {} as MtNavigationNode).children?.filter(child => !!child) ?? [];
  return children.map(child => state?.nodes?.get(child) ?? { isRoot: true, children: [], uid: 'ROOT' }).filter(item => !!item) || [];
};

export const getParentForSelectedNode = (state: HomepageNavigationState): MtNavigationNode => {
  const parentUid =
    state.selectedNode?.parentUid || KEY_ROOT;
  return state?.nodes?.get(parentUid) || { isRoot: true, children: [], uid: 'ROOT' };
}

