import { Component, ElementRef, OnInit } from '@angular/core';
import { MiniCartComponent, MiniCartComponentService } from '@spartacus/cart/base/components/mini-cart';
import { UrlCommands, WindowRef } from '@spartacus/core';
import { MtUrlService } from 'src/app/spartacus/custom-module/core/aem-adapter-site-context/mt-url.service';

@Component({
  selector: 'mt-mini-cart',
  templateUrl: './mt-mini-cart.component.html',
  styleUrls: ['./mt-mini-cart.component.scss']
})
export class MtMiniCartComponent extends MiniCartComponent implements OnInit {

  showMenu: boolean = false;

  constructor(protected override miniCartComponentService: MiniCartComponentService,
    protected el: ElementRef,
    protected mtUrlService: MtUrlService,
    protected windowRef: WindowRef) {
    super(miniCartComponentService);
  }

  ngOnInit(): void {

    this.windowRef.document.body.addEventListener("click", this.handleClick)

  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  focusOut($event: FocusEvent) {
    if (!['mt-minicart-button', 'go-to-cart-button-for-spa', 'go-to-shop-button-for-spa', 'go-to-cart-button-for-aem', 'go-to-shop-button-for-aem'].includes(($event.relatedTarget as Element)?.id)) {
      this.showMenu = false
    }
  }

  //@HostListener('click', ['$event'])
  handleClick(event: UIEvent): void {
    //   console.log(`event: ${JSON.stringify(event)}`)
    //   console.log(`ElementRef: ${this.el}`)

    if ((this.el !== undefined) && (event.target as any).tagName === this.el.nativeElement.tagName) {
      this.showMenu = false;
    }

  }

  getURL(commands: UrlCommands): string {
    return this.mtUrlService.buildURLWithAEMContext(commands);
  }

  get isSpartacusContext(): boolean {
    return this.mtUrlService.isSpartacusContext();
  }

}
