import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActiveCartFacade, CartUiEventAddToCart, OrderEntry } from '@spartacus/cart/base/root';
import {EventService, WindowRef} from '@spartacus/core';
import { Subscription } from 'rxjs';
import { MtProduct } from '../../../model/mt-product.model';
import {AddToCartEnvelope, MtAddToCartEvent, ProductEnvelope} from "../../../../tms/events/mt-add-to-cart-event";

@Component({
  selector: 'mt-added-to-cart-button',
  templateUrl: './mt-added-to-cart-button.component.html',
  styleUrls: ['./mt-added-to-cart-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MtAddedToCartButtonComponent implements OnInit, OnDestroy {

  protected createCartUiEventAddToCart(numberOfEntriesBeforeAdd: number = -1) {
    const newEvent = new CartUiEventAddToCart();
    newEvent.productCode = this.product?.code ?? '';
    newEvent.quantity = this.numberOfEntriesToAdd;
    newEvent.numberOfEntriesBeforeAdd = numberOfEntriesBeforeAdd;
    return newEvent;
  }

  protected populateAddToCartToAA() {
    const mtAddToCartEvent = new MtAddToCartEvent();
    mtAddToCartEvent.product =
        {
          prod_path : this.product?.url,
          prod_currency : this.product.price?.currencyIso,
          prod_matnum : this.product.code,
          prod_name : this.product.name,
          prod_price : this.product.price?.customerPrice,
          pord_bu : this.product?.esbu,
          prod_quantity : this.numberOfEntriesToAdd
        } as ProductEnvelope


    return mtAddToCartEvent;

  }

  protected subscription = new Subscription();
  protected numberOfEntriesBeforeAdd = -1;

  @Input() product: MtProduct = {} as MtProduct;
  @Input() numberOfEntriesToAdd: number = 1;

  constructor(
    private cartFacade: ActiveCartFacade,
    private eventService: EventService,
    private windowRef: WindowRef) { }

  ngOnInit(): void {
    if (this.product.code) {
      this.subscription.add(
        this.cartFacade.getActive().subscribe()
      );

      this.subscription.add(
        this.cartFacade.getLastEntry(this.product.code).subscribe(
          (orderEntry: OrderEntry | undefined) => {
            //console.log(`productCode: ${this.product.code}; numberOfEntriesBeforeAdd = ${orderEntry?.quantity}`);
            this.numberOfEntriesBeforeAdd = orderEntry?.quantity ?? -1;
          }
        )
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  addToCart() {
    console.log(`add to cart=> productCode: ${this.product.code}; numberOfEntriesBeforeAdd: ${this.numberOfEntriesBeforeAdd}`);
    this.eventService.dispatch(
      this.createCartUiEventAddToCart(this.numberOfEntriesBeforeAdd)
    );
    this.eventService.dispatch(
      this.populateAddToCartToAA()
    );
    if(this.windowRef.isBrowser()){
      if(typeof this.windowRef.nativeWindow?._satellite === "object") {
        // @ts-ignore
        this.windowRef.nativeWindow?._satellite.track("add_to_cart_direct_call");
      }
    };
  }

}
