import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, provideConfig, RoutingConfig, UrlModule } from '@spartacus/core';
import { AtMessageModule, CmsPageGuard, LayoutConfig, PageLayoutComponent } from '@spartacus/storefront';
import { MtOrderConfirmationComponent } from './mt-order-confirmation.component';
import { MtOrderConfirmationActivateGuard } from '../../guards/mt-order-confirmation.guard';


export const orderConfirmationPageConfig: LayoutConfig =
{
  layoutSlots: {
    OrderConfirmationPageTemplate: {
      pageFold: 'Section1',
      slots: [
        'Section1'
      ],
    },
  }
}


@NgModule({
  declarations: [
    MtOrderConfirmationComponent
  ],
  imports: [
    AtMessageModule,
    CommonModule,
    I18nModule,
    UrlModule,
    BrowserModule,
    FormsModule,
    RouterModule.forChild([
      {
        // @ts-ignore
        path: null,
        canActivate: [CmsPageGuard, MtOrderConfirmationActivateGuard],
        component: PageLayoutComponent,
        data: { cxRoute: 'mtOrderConfirmation' },
      }
    ])
  ],
  providers: [
    provideConfig(orderConfirmationPageConfig),
    provideConfig(
      <RoutingConfig>{
        routing: {
          routes: {
            mtOrderConfirmation: {
              paths: ['order-confirmation/:orderCode'],
              paramsMapping: { orderCode: 'code' },
            }
          }
        }
      }
    ),
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OrderConfirmationThankYouMessageComponent: {
          component: MtOrderConfirmationComponent,
          disableSSR: true
        },
      },
    })
  ],
  exports: [
    MtOrderConfirmationComponent
  ]
})
export class MtOrderConfirmationModule { }
