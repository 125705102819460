/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Component } from '@angular/core';
import { AuthService, RoutingService, UrlCommands } from '@spartacus/core';
import { User, UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MtUrlService } from 'src/app/spartacus/custom-module/core/aem-adapter-site-context/mt-url.service';
import { CountryContextService } from 'src/app/spartacus/custom-module/core/country-site-context/country-context.service';
import { LocalStorageService } from 'src/app/spartacus/custom-module/util/local-storage/local-storage.service';
import { LanguageisoContextService } from "../../../../core/languageiso-site-context/languageiso-context.service";
import { MtSoldToGuardService } from '../../../auth/user-auth/guards/mt-soldto-guard.service';

@Component({
  selector: 'mt-login-component',
  templateUrl: './mt-login.component.html',
  styleUrls: ['./mt-login.component.scss']
})
export class MtLoginComponent {
  user$: Observable<User | undefined>;
  iconClicked: boolean = false;
  protected currentCountry: string = "us";
  protected currentLanguage: string = "en";
  protected subscriptions: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    protected routingService: RoutingService,
    private userAccountFacade: UserAccountFacade,
    protected countryContextService: CountryContextService,
    protected languageIsoContextService: LanguageisoContextService,
    protected localStorageService: LocalStorageService,
    protected mtUrlService: MtUrlService,
    protected mtSoldToGuardService: MtSoldToGuardService,
  ) {
    this.user$ = this.userAccountFacade.get();
    this.subscriptions.add(
      countryContextService.getActive().subscribe(currentCountry => {
        this.currentCountry = currentCountry
      }))
    this.subscriptions.add(
      languageIsoContextService.getActive().subscribe(currentLanguage => {
        this.currentLanguage = currentLanguage
      }))
      sessionStorage.setItem('poNumber',"");
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  ngOnInit(): void {
    this.mtSoldToGuardService.canActivateOrRedirectToSoldToSelection().subscribe((soldToSetup) => {
      console.log(`SoldTo Setup: ${soldToSetup}`);
    });

    this.user$ = this.authService.isUserLoggedIn().pipe(
      switchMap((isUserLoggedIn) => {
        if (isUserLoggedIn) {
          console.log("Logged in");
          return this.userAccountFacade.get();
        } else {
          console.log("Not logged in");
          return of(undefined);
        }
      })
    );
  }

  getURL(commands: UrlCommands): string {
    return this.mtUrlService.buildURLWithAEMContext(commands);
  }

  get isSpartacusContext(): boolean {
    return this.mtUrlService.isSpartacusContext();
  }

  getCustomerPortalURL(link: any): String {
    return "/shop/" + this.currentCountry + "/" + this.currentLanguage + link;
  }

  iconClick(): void {
    this.iconClicked = !this.iconClicked;
  }

  focusOut($event: FocusEvent) {
    if (!['loginLink', 'go-to-login-button', 'go-to-login-button', 'logged-in-account-button',
      'logout-button', 'customer-portal-button'].includes(($event.relatedTarget as Element)?.id))
      this.iconClicked = false
  }

  clearSoldTo(): void {
    this.localStorageService.removeItem('soldTo');//TODO: temporary solution, will be handled on session management story
    this.iconClick();
  }

}
