import {CxEvent} from "@spartacus/core";

export class MtAddToCartEvent extends CxEvent{
  static override readonly type = "AddToCart";

  product ?: ProductEnvelope;
  event = "AddToCart";

}

export interface AddToCartEnvelope{

  products ?: ProductEnvelope[]

}

export interface ProductEnvelope{

  prod_path ?: string;
  prod_name ?: string;
  prod_quantity ?: number;
  pord_bu ?: string;
  prod_price ?: string;
  prod_currency ?: string;
  prod_matnum ?: string;
  cost ?: number;

}
