<div class="mt-place-order-summary">

  <div class="form_field_wrapper">

    <div class="cx-summary-heading">
      <label>
        <span>
          {{ 'checkoutReview.orderSummary.title' | cxTranslate }}
        </span>
      </label>
    </div>

    <div class="cx-summary-partials" *ngIf="cart$ | async as cart">

      <div class="cx-summary-row">
        <div class="cx-summary-label">
          <span class="label">{{ 'checkoutReview.orderSummary.quantity' | cxTranslate }}</span>
          <span class="value">{{ cart.totalUnitCount }}</span>
        </div>
      </div>

      <div *ngIf="cart.subTotal?.value !== cart.totalPrice?.value" class="cx-summary-row">
        <div class="cx-summary-label">
          <span class="label">{{ 'checkoutReview.orderSummary.total' | cxTranslate }}</span>
          <span class="value">{{ getFormattedPrice(cart.subTotal?.value) | async }}</span>
        </div>
      </div>

      <!--
      <div *ngIf="cart.totalDiscounts?.value !== 0" class="cx-summary-row">
        <div class="cx-summary-label">
          <span class="label">{{ 'checkoutReview.orderSummary.discounts' | cxTranslate }}</span>
          <span class="value">({{ cart.totalDiscounts?.formattedValue }})</span>
        </div>
      </div>
      -->
      <div *ngIf="cart.productDiscounts?.value !== 0" class="cx-summary-row">
        <div class="cx-summary-label">
          <span class="label">{{ 'checkoutReview.orderSummary.discounts' | cxTranslate }}</span>
          <span class="value">(-{{ getFormattedPrice(cart.productDiscounts?.value) | async }})</span>
        </div>
      </div>

      <div *ngIf="cart?.surcharge !== undefined && cart.surcharge?.value !== 0" class="cx-summary-row">
        <div class="cx-summary-label">
          <span class="label">{{ 'checkoutReview.orderSummary.surchargeFreight' | cxTranslate }}</span>
          <span class="value">{{ getFormattedPrice(cart.surcharge?.value) | async}}</span>
        </div>
      </div>

      <!--
      <div class="cx-summary-row cx-summary-total">
        <div class="cx-summary-label">
          <span class="label">{{ 'checkoutReview.orderSummary.yourNetPrice' | cxTranslate }}</span>
          <span class="value">{{ cart.totalPrice?.formattedValue }}</span>
        </div>
      </div>
      -->
      <div class="cx-summary-row cx-summary-total">
        <div class="cx-summary-label">
          <span class="label">{{ 'checkoutReview.orderSummary.yourNetPrice' | cxTranslate }}</span>
          <span class="value">{{ getTotalStandardPrice(cart) | async }}</span>
        </div>
      </div>

      <div *ngIf="cart.totalTax?.value !== 0" class="cx-summary-row cx-summary-total">
        <div class="cx-summary-label">
          <span class="label">{{ 'checkoutReview.orderSummary.salesTax' | cxTranslate }}</span>
          <span class="value">{{ getFormattedPrice(cart.totalTax?.value) | async }}</span>
        </div>
      </div>

      <div *ngIf="cart.deliveryCost?.value !== 0" class="cx-summary-row cx-summary-total">
        <div class="cx-summary-label">
          <span class="label">{{ 'checkoutReview.orderSummary.surchargeFreight' | cxTranslate }}</span>
          <span class="value">{{ getFormattedPrice(cart.deliveryCost?.value) | async }}</span>
        </div>
      </div>

      <div class="cx-summary-row cx-summary-total">
        <div class="cx-summary-label total-price">
          <span class="label">{{ 'checkoutReview.orderSummary.total' | cxTranslate }}</span>
          <span class="value">{{ getFormattedPrice(cart.totalPriceWithTax?.value) | async }}</span>
        </div>
      </div>

      <!--<p class="info-message">{{ 'orderSummary.infoMessage' | cxTranslate }}</p>-->
      <div *ngIf="cart.paymentType?.code === 'CARD';then additionalInfo else infoMessage" >
      </div>
      <ng-template #additionalInfo><p class="info-message">{{ 'orderSummary.additionalInfo' | cxTranslate }}</p></ng-template>
      <ng-template #infoMessage><p class="info-message">{{ 'orderSummary.infoMessage' | cxTranslate }}</p></ng-template>

      <form [formGroup]="checkoutSubmitForm">
        <div class="cx-summary-row">
          <label class="checkmark-container" for="termsAndConditions">
            <input type="checkbox" formControlName="termsAndConditions" id="termsAndConditions" name="termsAndConditions"  value="termsAndConditions"/>
              <span class="checkmark"></span>
            <p>
              {{ 'checkoutReview.confirmThatRead' | cxTranslate }}
              <a [routerLink]="{ cxRoute: 'salesTermsAndConditions', params: {language : (language$ | async)}} | cxUrl"
                class="cx-tc-link" target="_blank" rel="noopener noreferrer">
                {{ 'checkoutReview.termsAndConditions' | cxTranslate }}
              </a>
              {{ 'checkoutReview.confirmThatRead2' | cxTranslate }}
            </p>
          </label>
        </div>

        <button *ngIf="cart.paymentType?.code === 'CARD'" (click)="payPlaceOrder(cart)"
          class="btn btn-primary btn-block" [disabled]="termsAndConditionInvalid"
          [cxAtMessage]="'checkoutReview.payPlaceOrder' | cxTranslate">
          {{ 'checkoutReview.payPlaceOrder' | cxTranslate }}
        </button>

        <button *ngIf="cart.paymentType?.code !== 'CARD'" (click)="placeOrder(cart)" class="btn btn-primary btn-block"
          [disabled]="termsAndConditionInvalid" [cxAtMessage]="'checkoutReview.placeOrder' | cxTranslate">
          {{ 'checkoutReview.placeOrder' | cxTranslate }}
        </button>
      </form>

    </div>
  </div>

</div>
