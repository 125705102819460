/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  AuthConfigService,
  CmsConfig, ConfigModule,
  GlobalMessageService,
  I18nModule,
  NotAuthGuard,
  provideDefaultConfig,
  RoutingService,
  UrlModule,
} from '@spartacus/core';
import { FormErrorsModule, SpinnerModule } from '@spartacus/storefront';
import { UserPasswordFacade } from '@spartacus/user/profile/root';
import { MtForgotPasswordComponentService } from './mt-forgot-password-component.service';
import { MtForgotPasswordComponent } from './mt-forgot-password.component';
import {MtResetPasswordComponent} from "../reset-password";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    FormErrorsModule,
    SpinnerModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        ForgotPasswordComponent: {
          component: MtForgotPasswordComponent,
          guards: [NotAuthGuard],
        },
      },
    }),

  ],
  providers: [
    MtForgotPasswordComponentService,
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        ForgotPasswordComponent: {
          component: MtForgotPasswordComponent,
          guards: [NotAuthGuard],
          providers: [
            {
              provide: MtForgotPasswordComponentService,
              useClass: MtForgotPasswordComponentService,
              deps: [
                UserPasswordFacade,
                RoutingService,
                AuthConfigService,
                GlobalMessageService,
              ],
            },
          ],
        },
      },
    }),
  ],
  declarations: [MtForgotPasswordComponent],
})
export class MtForgotPasswordModule {}
