/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import {Component} from '@angular/core';
import { CountryContextService } from 'src/app/spartacus/custom-module/core/country-site-context/country-context.service';
import {LanguageisoContextService} from "../../../../core/languageiso-site-context/languageiso-context.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'mt-registration-conf',
  templateUrl: './mt-registration-conf.component.html',
  styleUrls: ['./mt-registration-conf.component.scss']
})
export class MtRegistrationConfComponent {

  protected currentCountry : string = "us";
  protected currentLanguage : string = "en";
  protected subscriptions : Subscription = new Subscription();

  constructor(
    protected countryContextService: CountryContextService,
    protected languageIsoContextService: LanguageisoContextService
  ) {
    this.subscriptions.add(
    countryContextService.getActive().subscribe( currentCountry => {
      this.currentCountry = currentCountry
    }))
    this.subscriptions.add(
    languageIsoContextService.getActive().subscribe(currentLanguage =>
    {
      this.currentLanguage = currentLanguage
    }))
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
}
  getURL(link:any): String {

    return "/" + this.currentCountry + "/" + this.currentLanguage + link;
  }
}
