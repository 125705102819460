/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { translations } from "@spartacus/assets";
import {
  CmsConfig, I18nConfig,
  I18nModule, provideConfig,
  provideDefaultConfig,
  RoutingConfig,
  UrlModule
} from '@spartacus/core';
import { PageSlotModule } from '@spartacus/storefront';
import {
  deTranslationOverwrites,
  enTranslationOverwrites,
  frTranslationOverwrites,
  nlTranslationOverwrites,
  itTranslationOverwrites
} from "../../../../../spartacus-configuration.module";
import { mtUserAccountTranslationChunksConfig, mtUserAccountTranslations } from "../../assets/translations/translations";
import { MtLoginComponent } from './mt-login.component';

@NgModule({
  declarations: [MtLoginComponent],
  imports: [CommonModule, RouterModule, UrlModule, PageSlotModule, I18nModule],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        OrganizationRegistrationButtonComponent: {
          component: MtLoginComponent,
        },
      },
    }),

    provideConfig({
      i18n: { resources: translations },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: mtUserAccountTranslations,
        chunks: mtUserAccountTranslationChunksConfig
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: {
          en: enTranslationOverwrites,
          de: deTranslationOverwrites,
          fr: frTranslationOverwrites,
          nl: nlTranslationOverwrites,
          it: itTranslationOverwrites
        },
      },
    }),

    provideConfig(<RoutingConfig>{
      routing: {
        routes: {
          account: {
            paths: ['account']
          }
        }
      }
    }),

  ],
  exports: [MtLoginComponent],
})
export class MtLoginModule { }
