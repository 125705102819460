<div class="mt-account-menu-links">
    <a routerLink="/account" routerLinkActive="router-link" [routerLinkActiveOptions]="{exact:true}">
      {{ 'mtUserAccountAndLogin.myAccount.tiles.myAccount' | cxTranslate }}
    </a>

    <a routerLink="/account/newsFeed" routerLinkActive="router-link" [routerLinkActiveOptions]="{exact:true}">
      {{ 'mtUserAccountAndLogin.myAccount.tiles.newsFeed' | cxTranslate }}
    </a>

    <a routerLink="/account/personalLibrary" routerLinkActive="router-link" [routerLinkActiveOptions]="{exact:true}">
      {{ 'mtUserAccountAndLogin.myAccount.tiles.personalLibrary' | cxTranslate }}
    </a>

    <a routerLink="/account/myProfile" routerLinkActive="router-link" [routerLinkActiveOptions]="{exact:true}">
      {{ 'mtUserAccountAndLogin.myAccount.tiles.myProfile' | cxTranslate }}
    </a>
  <!--
    <a routerLink="/account/billingAddresses" routerLinkActive="router-link" [routerLinkActiveOptions]="{exact:true}">
      {{ 'mtUserAccountAndLogin.myAccount.tiles.billingAddresses' | cxTranslate }}
    </a>

    <a routerLink="/account/shippingAddresses" routerLinkActive="router-link" [routerLinkActiveOptions]="{exact:true}">
      {{ 'mtUserAccountAndLogin.myAccount.tiles.shippingAddresses' | cxTranslate }}
    </a>
  -->
    <a target="_blank" routerLink="/login/customerPortal" (click)="ordersRedirectDialog(); $event.preventDefault()" routerLinkActive="router-link" [routerLinkActiveOptions]="{exact:true}">
      {{ 'mtUserAccountAndLogin.myAccount.tiles.myOrders' | cxTranslate }}
    </a>

    <a routerLink="/account/newsletterSubscriptions" routerLinkActive="router-link" [routerLinkActiveOptions]="{exact:true}">
      {{ 'mtUserAccountAndLogin.myAccount.tiles.newsletterSubscriptions' | cxTranslate }}
    </a>

    <a [href]="registerProductURL" target="_blank" routerLinkActive="router-link" [routerLinkActiveOptions]="{exact:true}">
      {{ 'mtUserAccountAndLogin.myAccount.tiles.productRegister' | cxTranslate }}
    </a>
</div>
