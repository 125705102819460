/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GlobalMessageService, TranslationService, UserAddressService, UserService } from "@spartacus/core";
import { AddressFormComponent, LaunchDialogService } from "@spartacus/storefront";
import { map, switchMap, take } from "rxjs/operators";
import { CountryContextService } from "../../../../core/country-site-context/country-context.service";
import { MtCustomFormValidators } from "../../../../util/validators/mt-custom-form-validators";
import { MtAddressType } from "../../services/mt-user-address.service";
// import { LaunchDialogService, LAUNCH_CALLER } from '../../../../layout';
// import { sortTitles } from '../../../../shared/utils/forms/title-utils';

@Component({
  selector: 'mt-address-form',
  templateUrl: './mt-address-form.component.html',
  styleUrls: ['./mt-address-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MtAddressFormComponent extends AddressFormComponent implements OnInit {

  constructor(fb: UntypedFormBuilder,
    userService: UserService,
    userAddressService: UserAddressService,
    globalMessageService: GlobalMessageService,
    translation: TranslationService,
    launchDialogService: LaunchDialogService,
    private countryContextService: CountryContextService) {
    super(fb, userService, userAddressService, globalMessageService, translation, launchDialogService);
  }

  @Input() addressType?: MtAddressType;
  @Input() addAddressForm: boolean = false;
  @Input() isPo: boolean = false;
  isPostalCodeMandatory = true;

  protected readonly MtAddressType = MtAddressType;

  isAddressFieldReadOnly(): boolean {
    if (this.addressData !== undefined && this.addressData !== null) {
      if (this.addressData.business !== undefined && this.addressData.business !== null) {
        return this.addressData.business;
      }
    }
    return false;
  }

  override addressForm: UntypedFormGroup = this.fb.group({

    titleCode: ['mr'],
    firstName: ['firstName'],
    lastName: ['lastName'],

    companyName: ['', Validators.required],
    companyName2: [''],
    companyName3: [''],
    country: this.fb.group({
      isocode: [null, Validators.required],
    }),
    line1: [''],
    line2: [''],
    line3: [''],
    town: ['', Validators.required],
    region: this.fb.group({
      isocode: [null, Validators.required],
    }),
    postalCode: [''],
    defaultAddress: [false],
    shippingAddress: [false],
    billingAddress: [false],
    addressType: [this.addressType],
    visibleInAddressBook: [true],
    poBillingAddress: [],
    poNumber: ['']
  });


  override ngOnInit() {
    super.ngOnInit();
    this.userAddressService.loadDeliveryCountries();
    this.countries$ = this.userAddressService.getDeliveryCountries().pipe(switchMap(
      (countries) => this.countryContextService.getActive().pipe(
        map(
          (currentCountry) => countries.sort(
            (country) => {
              if (currentCountry.toUpperCase() === country.isocode?.toUpperCase()) return -1;
              else return currentCountry.toUpperCase().localeCompare((country.isocode ?? 'US').toUpperCase());
            }
          )
        ),
      )
    ));

    this.addressForm.get('addressType')?.setValue(this.addressType);

    if (!this.addressForm.value.poBillingAddress) {
      // @ts-ignore
      this.addressForm.get('line1').setValidators(Validators.required);
    }
    this.isPo = this.addressForm.get('poBillingAddress')?.value;
    if (this.addAddressForm) {
      if (this.addressType === MtAddressType.SHIP_TO) {
        this.addressForm.get('shippingAddress')?.setValue(true);
        this.addressForm.get('billingAddress')?.setValue(false);
        this.addressForm.get('poBillingAddress')?.setValue(false);


      } else if (this.addressType === MtAddressType.BILL_TO) {
        this.addressForm.get('shippingAddress')?.setValue(false);
        this.addressForm.get('billingAddress')?.setValue(true);
      }

      this.countries$.subscribe(countries => {
        this.countrySelected(countries[0]);
      });

    }

    if (this.addressForm.get('poBillingAddress')?.value == null) {
      this.addressForm.get('poBillingAddress')?.setValue(false);
    }

    this.setIsPostalCodeMandatory();

    this.addressForm.get('country')?.valueChanges.subscribe(
      it =>{if (it?.isocode === 'IE') {
        this.setIsPostalCodeMandatory();
        this.setPostalCodeValidation(it?.isocode? it?.isocode:'' );
       } else {
         this.setIsPostalCodeMandatory();
         this.setPostalCodeValidation(it?.isocode? it?.isocode:'' );
       }
     }
    )
  }

  toggleShippingAddress(): void {
    this.addressForm['controls']['shippingAddress'].setValue(
      this.addressForm.value.shippingAddress
    );

    this.addressForm['controls']['visibleInAddressBook'].setValue(
      this.addressForm.value.shippingAddress
    );
  }

  toggleBillingAddress(): void {
    this.addressForm['controls']['billingAddress'].setValue(
      this.addressForm.value.billingAddress
    );

    this.addressForm['controls']['visibleInAddressBook'].setValue(
      this.addressForm.value.billingAddress
    );

  }

  togglePOBillingAddress(): void {
    this.addressForm['controls']['poBillingAddress'].setValue(
      this.addressForm.value.poBillingAddress
    );
    console.log(this.addressForm.value.poBillingAddress);
    if (this.addressForm.value.poBillingAddress) {
      // @ts-ignore
      this.addressForm.get('line1')?.setValidators(null);
      this.addressForm.get('line1')?.setErrors(null);
    }else {
      // @ts-ignore
      this.addressForm.get('line1').setValidators(Validators.required);
    }
  }

  isPOBillingAddress() {
    return this.addressForm.get('poBillingAddress')?.value;
  }

  private setIsPostalCodeMandatory() {
    let selectedCountryIsoCode : string  = this.addressForm.get('country')?.value.isocode
    if (selectedCountryIsoCode === 'IE' ) {
      this.isPostalCodeMandatory = false
    }else{
      this.isPostalCodeMandatory = true
    }
  }

  private setPostalCodeValidation(countryIsocode:string){
    if (countryIsocode === 'IE'){
     this.addressForm.get('postalCode')?.setValidators(null);
     this.addressForm.get('postalCode')?.updateValueAndValidity()
    }else{
      this.addressForm.get('postalCode')?.setValidators([
        Validators.required,
        MtCustomFormValidators.postalCodeValidation(countryIsocode)
      ]);
      this.addressForm.get('postalCode')?.updateValueAndValidity()
    }
  }
}
