export const mtProductDetailsTab = {
  texts: {
    browseProductFamily: 'Sfoglia per famiglia di prodotti',
    productSpecificationsTitle: 'Specifiche del prodotto',
    functionalSpecificationsTitle: 'Specifiche logistiche',
    featuresHeadline:'Caratteristiche',
    descHeadline:'Descrizione',
    plantData: {
      countryOfOrigin: 'Paese d\'origine',
      customsTariffNumber: 'Numero tariffa doganale',
      hazardousMaterialNumber: 'Numero di materiale pericoloso'
    }
  }
}

export const mtProductInfo = {
  labels: {
    showMore: 'Visualizza di più'
  }
}

export const mtProductRecommendations = {
  title: 'Acquistato spesso insieme a'
}

export const mtProductDetailsResource = {
  mtProductDetailsTab, mtProductRecommendations, mtProductInfo
}
