import { Injectable } from "@angular/core";
import { ActiveCartFacade, Cart } from "@spartacus/cart/base/root";
import { CheckoutPaymentTypeService } from "@spartacus/checkout/b2b/core";
import { B2BPaymentTypeEnum } from "@spartacus/checkout/b2b/root";
import { CheckoutQueryFacade } from "@spartacus/checkout/base/root";
import { Command, CommandService, CommandStrategy, EventService, QueryService, UserIdService } from "@spartacus/core";
import { Observable } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { MtOrderSimulationResponseError } from "../../model/mt-order-simulation-response-error.model";
import { MtCheckoutPaymentTypeConnector } from "../connectors/checkout-payment-type/mt-checkout-payment-type.connector";
import { MtCheckoutPaymentTypeSetEvent, MtOrderSimulationResponseEvent } from "../events/mt-checkout.events";

@Injectable()
export class MtCheckoutPaymentTypeService extends CheckoutPaymentTypeService {

  constructor(
    protected override activeCartFacade: ActiveCartFacade,
    protected override userIdService: UserIdService,
    protected override queryService: QueryService,
    protected override commandService: CommandService,
    protected override paymentTypeConnector: MtCheckoutPaymentTypeConnector,
    protected override eventService: EventService,
    protected override checkoutQueryFacade: CheckoutQueryFacade
  ) {
    super(activeCartFacade, userIdService,queryService,commandService,paymentTypeConnector,eventService,checkoutQueryFacade);
  }

  protected override setPaymentTypeCommand: Command<
      { paymentTypeCode: string; purchaseOrderNumber?: string; phoneNumber?: string; }, unknown> =
        this.commandService.create<{
          paymentTypeCode: string;
          purchaseOrderNumber?: string;
          phoneNumber?: string;
      }>(
      ({ paymentTypeCode, purchaseOrderNumber, phoneNumber }) =>
        this.checkoutPreconditions().pipe(
          switchMap(([userId, cartId]) =>
            this.paymentTypeConnector
              .setPaymentType(
                userId,
                cartId,
                paymentTypeCode,
                purchaseOrderNumber,
                phoneNumber
              )
              .pipe(
                tap(() =>
                  this.eventService.dispatch(
                    {
                      userId,
                      cartId,
                      paymentTypeCode,
                      purchaseOrderNumber,
                      phoneNumber
                    },
                    MtCheckoutPaymentTypeSetEvent
                  )
                )
              )
          )
        ),
        {
          strategy: CommandStrategy.CancelPrevious,
        }
  );

  protected getOrderSimulationCommand: Command<void, MtOrderSimulationResponseError | Cart> =
        this.commandService.create<any, MtOrderSimulationResponseError | Cart>(
      (payload) =>
        this.checkoutPreconditions().pipe(
          switchMap(([userId, cartId]) =>
            this.paymentTypeConnector
              .getOrderSimulation(
                userId,
                cartId
              )
              .pipe(
                tap(() =>
                  this.eventService.dispatch(
                    {
                      userId,
                      cartId,
                    },
                    MtOrderSimulationResponseEvent
                  )
                )
              )
          )
        ),
        {
          strategy: CommandStrategy.CancelPrevious,
        }
  );

  override setPaymentType(
    paymentTypeCode: B2BPaymentTypeEnum,
    purchaseOrderNumber?: string,
    phoneNumber?: string
  ): Observable<unknown> {
    return this.setPaymentTypeCommand.execute({
      paymentTypeCode,
      purchaseOrderNumber,
      phoneNumber
    });
  }

  getOrderSimulation(): Observable<MtOrderSimulationResponseError | Cart> {
    return this.getOrderSimulationCommand.execute();
  }
}
