import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import {EventService, WindowRef} from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { MtCatalogsConnector } from '../../../product-data-binding/mt-catalogs.connector';
import { MtProductListComponentService } from './mt-product-list-component.service';
import { MtProductSearchPageModel } from './mt-product-search-page.model';
import {MtLangAndCountryLoaded} from "../../../../tms/events/mt-core-events";
import { MtFacetService } from '../../services/mt-facet.service';


@Component({
  selector: 'mt-product-list',
  templateUrl: './mt-product-list.component.html',
  styleUrls: ['./mt-product-list.component.scss']
})
export class MtProductListComponent implements OnInit, OnDestroy {
  readonly SEARCH_COMPLETE: string = "changeNoFoundItems";

  protected subscription: Subscription = new Subscription();

  @Input()
  category?: string | undefined;

  @Input()
  prefilter?: string | undefined;

  model$: Observable<MtProductSearchPageModel | undefined> =
    this.productListComponentService.model$;


  constructor(
    protected router: Router,
    protected productListComponentService: MtProductListComponentService,
    protected el: ElementRef,
    protected catalogsConnector: MtCatalogsConnector,
    protected winRef: WindowRef,
    protected eventService: EventService,
    protected facetService: MtFacetService,
  ) {
  
  }

  ngOnInit(): void {
    this.initPage(this.category);
    this.productListComponentService.ngOnInit();

    let subscriptions = new Subscription();

    subscriptions.add(this.eventService.get(MtLangAndCountryLoaded).subscribe(() => {
      this.initPage(this.category);
      this.productListComponentService.ngOnInit();

    }))
  }


  private initPage(category?: string) {
    this.productListComponentService.setupCategoryPage(category);
    this.subscription.add(
      this.productListComponentService.changeNoFoundItems.subscribe((event) => {
        console.log(`NO. found items: ${JSON.stringify(event)}`);

        if (event.isActive) {
          const noResultsIcon = this.winRef.document.querySelector('.no-results-icon') as HTMLElement;
          if (noResultsIcon) {
            noResultsIcon.style.display = "none";
            console.log('hide: noResultsIcon')
          }
          const noResultsTools = this.winRef.document.querySelector('.no-results-tools') as HTMLElement;
          if (noResultsTools) {
            noResultsTools.style.display = "none";
          }
        }

        if (event.noTotalItemsFound >= 0) {
          (this.el.nativeElement as HTMLElement).dispatchEvent(new CustomEvent(this.SEARCH_COMPLETE, { detail: { noTotalItemsFound: event.noTotalItemsFound, isActive: event.isActive } }));
        }
      }));



    if (this.prefilter && this.winRef.nativeWindow !== undefined && this.winRef.nativeWindow.location.hash == "") {

      this.winRef.nativeWindow.location.hash = this.prefilter;

    }

  }

  ngOnDestroy(): void {
    console.log('exp-mt-product-list-component destroy');
    this.subscription.unsubscribe();
    this.productListComponentService.ngOnDestroy();
  }

  get categoryCode(): string | undefined {
    return this.category;
  }

  get currentPage(): number {
    return this.productListComponentService.getCurrentPage();
  }

  get currentPageSize(): number {
    return this.productListComponentService.getCurrentPageSize();
  }

  get isCategoryPage(): boolean {
    return this.productListComponentService.isCategoryPage;
  }

  onChangePage(pageNumber: number) {
    this.productListComponentService.changePage(pageNumber);
  }

  onChangeItemsPerPageNo(itemsPerPageNo: number) {
    this.productListComponentService.changeItemsPerPage(itemsPerPageNo);
  }

  sortList(sortCode: any) {
    this.productListComponentService.sortBy(sortCode);
  }

  openFilterPopup(){
    this.facetService.togglePopup();
  }
}
