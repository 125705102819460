/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CartNotEmptyGuard, CheckoutAuthGuard } from '@spartacus/checkout/base/components';
import {
  CmsConfig,
  I18nModule,
  provideConfig, UrlModule
} from '@spartacus/core';
import { MtCheckoutStepsSetGuard } from '../../../guards/mt-checkout-steps-set.guard';
import { MtCheckoutProgressMobileBottomComponent } from './mt-checkout-progress-mobile-bottom.component';


@NgModule({
  imports: [CommonModule, UrlModule, I18nModule, RouterModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutProgressMobileBottom: {
          component: MtCheckoutProgressMobileBottomComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard, MtCheckoutStepsSetGuard],
        },
      }
    })
  ],
  declarations: [MtCheckoutProgressMobileBottomComponent],
  exports: [MtCheckoutProgressMobileBottomComponent],
})
export class MtCheckoutProgressMobileBottomModule { }
