import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { PageMeta, PageMetaService, PageRobotsMeta } from "@spartacus/core";
import { PageMetaLinkService, SeoMetaService } from "@spartacus/storefront";

@Injectable({
  providedIn: 'root'
})
export class MtSeoMetaService extends SeoMetaService {

  constructor(protected override ngTitle: Title,
    protected override ngMeta: Meta,
    protected override pageMetaService: PageMetaService,
    protected override pageMetaLinkService?: PageMetaLinkService) {
    super(ngTitle, ngMeta, pageMetaService, pageMetaLinkService);
  }

  protected override set meta(meta: PageMeta) {
    this.title = meta.title;
    this.description = meta.description;
    this.image = meta.image;
    this.robots = meta.robots || [PageRobotsMeta.INDEX, PageRobotsMeta.FOLLOW];
    this.priority = meta.priority;
    this.toppath = meta.toppath;
    this.locale = meta.locale;
    this.category = meta.category;
    this.matNum = meta.matNum;
  }

  protected override set description(value: string | undefined) {
    this.addTag({ class: "swiftype", name: 'description', "data-type": "string", content: value ?? 'N/A' });
  }

  protected override set image(value: string | undefined) {
    super.image = value;
    if (value) {
      this.addTag({ class: "swiftype", name: 'image', "data-type": "enum", content: value });
    }
  }

  protected set priority(value: string) {
    this.addTag({ class: "swiftype", name: 'priority', "data-type": "integer", content: value });
  }

  protected set category(value: string) {
    this.addTag({ class: "swiftype", name: 'category', "data-type": "string", content: value })
  }

  protected set toppath(value: string) {
    this.addTag({ class: "swiftype", name: 'toppath', "data-type": "enum", content: value })
  }

  protected set locale(value: string) {
    this.addTag({ class: "swiftype", name: 'locale', "data-type": "enum", content: value })
  }

  protected set matNum(value: string) {
    this.addTag({ class: "swiftype", name: 'matNum', "data-type": "string", content: value })
  }

}
