/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const mtOrgRegistrationForm = {

  fields: {
    company: {
      label: 'Firmenname*',
      placeholder: 'Pflichtfeld',
    },
    company2: {
      label: 'Firmenname 2',
      placeholder: '',
    },
    company3: {
      label: 'Firmenname 3',
      placeholder: '',
    },
    country: {
      label: 'Land*',
      placeholder: 'Pflichtfeld',
      hint: 'Dies ist Ihr Land. Falls Sie Ihren Account für ein anderes Land registrieren möchten, wechseln Sie bitte zur Website des entsprechenden Landes. Sie können das Land wechseln indem Sie auf die Flagge am rechten oberen Rand der Seite klicken.',
    },
    addressLine1: {
      label: 'Adresse 1*',
      placeholder: 'Pflichtfeld',
    },
    addressLine2: {
      label: 'Adresse 2',
      placeholder: '',
    },
    addressLine3: {
      label: 'Adresse 3',
      placeholder: '',
    },
    postalCode: {
      label: 'Postleitzahl',
      placeholder: 'Pflichtfeld',
    },
    state: {
      label: 'Bundesland',
      placeholder: 'Pflichtfeld',
    },
    city: {
      label: 'Stadt*',
      placeholder: 'Pflichtfeld',
    },
    phoneNumber: {
      label: 'Telefonnummer*',
      placeholder: 'Pflichtfeld',
    },
    poNumber: {
      label: 'PO Nummer',
      placeholder: '',
    },
    poCountry: {
      label: 'PO Land*',
      placeholder: '',
    },
    poState: {
      label: 'PO Bundesland',
      placeholder: '',
    },
    poPostalCode: {
      label: 'PO PO Postleitzahl*',
      placeholder: ''
    }
  },

  labels:{
    companyRegistration: 'Firma registrieren',
    registerLabel1: 'Gratulation zur Registrierung Ihres Benutzers. Sie können nun Ihre Firma registrieren.',
    registerLabel2: 'Registrieren Sie Ihre Firma um von unserem erweiterten digitalen Angebot zu profitieren',
    laterLink: 'Firma später registrieren',
    saveAsShippingAddress: 'Als Lieferadresse speichern',
    saveAsBillingAddress: 'Als Rechnungsadresse speichern',
    addPoBillingAddress: 'Füge eine neue PO Rechnungsadresse hinzu',
    saveAsDefault: 'Als Standard einstellen',
    cancel: 'Abbrechen',
    save: 'Speichern',
  },

  formSubmitButtonLabel: 'Firma registrieren',
};

export const mtOrgRegistration = {
  mtOrgRegistrationForm,
};
