/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Component } from '@angular/core';
import { CardComponent } from "@spartacus/storefront";

@Component({
  selector: 'mt-card',
  templateUrl: './mt-card.component.html',
  styleUrls: ['./mt-card.component.scss']
})
export class MtCardComponent extends CardComponent {

  isChecked(): boolean {
    if ((this.content?.selectedAddressId !== undefined) && (this.content?.selectedAddressId !== null)){
      return this.content?.selectedAddressId === this.content?.addressId
    }
    else
      return false;
  }

  cardAddressId(): string {
    return this.content?.addressId ?? '';
  }

}
