import { APP_BASE_HREF, isPlatformBrowser } from "@angular/common";
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from "@angular/router";
import {
  AuthRedirectService, EventService, LogoutEvent, RoutingService,
  SiteContextUrlSerializer,
  WindowRef
} from "@spartacus/core";
import { LaunchDialogService, LAUNCH_CALLER } from "@spartacus/storefront";
import { EMPTY, Observable, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { PREFIX_APP_BASE_HREF } from './app.provider';
import { MtAuthService } from "./spartacus/custom-module/user/auth/mt-auth-service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'mtstore';
  dialog: Observable<any> = EMPTY;
  rootVisible = false;

  constructor(protected routingService: RoutingService, protected router: Router, protected winRef: WindowRef, protected urlSerializer: SiteContextUrlSerializer,
    @Inject(PREFIX_APP_BASE_HREF) private baseHref: string,
    @Inject(APP_BASE_HREF) private appBaseHref: string,
    @Inject(PLATFORM_ID) protected platformId: any,
    protected authService: MtAuthService,
    protected launchDialogService: LaunchDialogService,
    protected authRedirectService: AuthRedirectService,
    protected eventService: EventService,
  ) {
  }

  ngOnInit(): void {

    // If we have a country that is not configured in system, it will be redirected to AEM home page for that specific country (e.g. https://<host>/de/de/home.html)
    if (!this.appBaseHref.startsWith(this.baseHref)) {

      this.authService.isUserLoggedIn().pipe(take(1)).subscribe((isUserLoggedIn: boolean) => {

        if (isUserLoggedIn) {

          this.authRedirectService.saveCurrentNavigationUrl();

          // Logout customer if the country is changed.
          console.log("BaseSiteChanged_WrongCountryONLY: LOGOUT")
          this.authService.logout();

          const dialogData = {
            rememberMe: true,
            doRefresh: undefined,
            logoutCountryChanged: true
          };

          this.dialog =
            this.launchDialogService.openDialog(LAUNCH_CALLER.SESSION_LOGOUT, undefined, undefined, dialogData) || EMPTY;

          if (this.dialog != EMPTY) {
            this.dialog.pipe(take(1)).subscribe(() => {
              this.dialog = EMPTY
              this.authService.coreLogout().finally(() => {
                if (this.winRef.nativeWindow) {
                  this.winRef.nativeWindow.location.href = this.appBaseHref;
                }
              });
            });
          }
        } else {
          if (this.winRef.nativeWindow) {
            this.winRef.nativeWindow.location.href = this.appBaseHref;
          }
        }
      });

    }

    const baseHref = this.baseHref + '/';

    this.rootVisible = false;
    if (isPlatformBrowser(this.platformId)) {
      this.rootVisible = true;
      const {
        url,
        params
      } = this.urlSerializer.urlExtractContextParameters((this.winRef?.location.pathname?.split(baseHref)[1] || '/') + this.winRef?.location.search);
      this.router.navigateByUrl(url);
    }

    //add logoutSuccessMessage class to logout global message
    let subscriptions = new Subscription();
    subscriptions.add(this.eventService.get(LogoutEvent).subscribe(() => {
      let element = this.winRef.document.getElementsByTagName("cx-global-message")[0]
      element.classList.add("logoutSuccessMessage")
      setTimeout(function () {
        element.classList.remove("logoutSuccessMessage")
      }, 3000);
    }));

  }

}
