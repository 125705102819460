/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import {
  Component,
  OnDestroy
} from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { GlobalMessageService, PaginationModel, RoutingService, WindowRef } from '@spartacus/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { MtSoldToModel } from '../../core/mt-sold-to.model';
import { MtSoldToService } from "../../core/mt-sold-to.service";
import { MtSoldtoSelectionComponentService } from "./mt-soldto-selection-component.service";

@Component({
  selector: 'mt-soldto-selection',
  templateUrl: './mt-soldto-selection.component.html',
  styleUrls: ['./mt-soldto-selection.component.scss']
})
export class MtSoldToSelectionComponent implements OnDestroy {

  protected subscription = new Subscription();
  protected soldToList$: Observable<any> = this.service.getSoldToList();
  protected pagination: PaginationModel;
  private soldToList: any;
  private soldToListSubject = new BehaviorSubject<any>(null);

  constructor(
    protected service: MtSoldtoSelectionComponentService,
    protected globalMessageService: GlobalMessageService,
    protected mtSoldToService: MtSoldToService,
    protected routingService: RoutingService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected winRef: WindowRef,
  ) {

    this.pagination = {
      currentPage: 0,
      pageSize: 0,
      totalPages: 0,
      totalResults: 0
    };

    this.subscription.add(
      combineLatest([this.soldToList$, this.activatedRoute.queryParamMap]).subscribe(
        (result) => {
          this.soldToList = result[0]
          const paramMap = result[1]
          this.soldToList = this.moveCurrentSoldToTop(this.soldToList)
          this.pagination = {
            currentPage: parseInt(paramMap.get("page") ?? "0"),
            pageSize: 5,
            totalPages: Math.ceil(this.soldToList.length / 5),
            totalResults: this.soldToList.length
          }
          this.updateSoldToList(this.soldToList);
        }
      )
    )

    this.soldToList$ = this.getSoldToList();

  }

  getSoldToList(): Observable<any> {
    return this.soldToListSubject.asObservable();
  }

  updateSoldToList(soldToList: any): void {
    this.soldToListSubject.next(soldToList);
  }

  moveCurrentSoldToTop(soldToList: MtSoldToModel[]): MtSoldToModel[] {
    const currentSoldTo = this.mtSoldToService.getCurrentSoldTo();
    const currentIndex = soldToList.findIndex(item => item.businessPartnerId == currentSoldTo);
    if (currentIndex !== -1) {
      const currentSoldToItem = soldToList.splice(currentIndex, 1)[0];
      soldToList.unshift(currentSoldToItem);
    }
    
    return soldToList;
  }

  soldToClick(soldTo: MtSoldToModel): void {
    this.mtSoldToService.setCurrentSoldTo(soldTo);
    /*
    this.globalMessageService.add(
      'Account selected: ' + soldTo.businessPartnerId,
      GlobalMessageType.MSG_TYPE_CONFIRMATION
    );
    */
    if (this.winRef.nativeWindow && !this.winRef.nativeWindow.location.href.includes('account')) {
      this.activatedRoute.queryParams.subscribe(
        (params: Params) => {
          const redirectToRoute = params['redirectToRoute'];

          console.log(`SoldTo selection redirectToRoute: ${redirectToRoute}`);
          console.log(`SoldTo selection getCurrentSoldTo: ${this.mtSoldToService.getCurrentSoldTo()}`)
          console.log(`SoldTo selection isCurrentSoldToSelected: ${this.mtSoldToService.isCurrentSoldToSelected()}`)

          if (redirectToRoute !== undefined) {
            this.routingService.go(['/', redirectToRoute]);
          } else {
            this.routingService.go(['account']);
          }
          console.log('SoldTo selection redirected')
        }
      ).unsubscribe();
    }
  }

  soldToIs(soldTo: string): boolean {
    return soldTo == this.mtSoldToService.getCurrentSoldTo();
  }

  isInCurrentPage(i: number): boolean {
    // @ts-ignore
    if ((i >= this.pagination.currentPage * 5) && (i < (this.pagination.currentPage + 1) * 5)) {
      return true;
    }
    else
      return false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  isCurrentSoldToSelected(): boolean {
    return this.mtSoldToService.isCurrentSoldToSelected();
  }
}
