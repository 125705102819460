import {HttpClient} from '@angular/common/http';
import {Injectable, NgModule} from '@angular/core';
import {Observable} from 'rxjs';
import {Occ, OccEndpointsService} from '@spartacus/core';
import {map, pluck} from "rxjs/operators";
import {CART_NORMALIZER} from "@spartacus/cart/base/root";

@Injectable()
export class MtCartOccService {

  constructor(private http: HttpClient,
              private occEndpoints: OccEndpointsService) {
  }

  loadCart(soldTo: string): Observable<any> {
    return this.http.get(
      this.getLoadCartEndpoint(soldTo)
    );
  }

  protected getLoadCartEndpoint(soldTo: string): string {
    return this.occEndpoints.buildUrl('users/current/carts/current',
      {urlParams: {soldTo}}
    )
  }

}
