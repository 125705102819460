import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MtPriceModel } from "../model/mt-price.model";
import { MtOccPriceRequestModel } from "../model/occ/mt-occ-price.model";
import { PriceAdapter } from "./price.adapter";


@Injectable({ providedIn: 'root' })
export class MtPriceConnector {

  constructor(protected priceAdapter: PriceAdapter) { }

  getPrice(priceRequest: MtOccPriceRequestModel): Observable<MtPriceModel[]> {
    return this.priceAdapter.getPrice(priceRequest);
  }

}