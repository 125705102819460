<a #topAnchor></a>
<h2 class="cx-checkout-payment-details-title" *ngIf="displayPaymentInfo">
  {{ "checkoutPaymentDetails.paymentDetails" | cxTranslate }}
</h2>
<ng-container *ngIf="cart$ | async as cart">
  <ng-container *ngIf="paymentTypes$ | async as paymentTypes">
    <ng-container
      *ngIf="
        !!paymentTypes.length &&
          (typeSelected$ | async) &&
          !(isUpdating$ | async);
        else loading
      "
    >
      <div *ngIf="displayPaymentInfo">
        <div>
          <label class="cx-payment-type-container">
            <div class="form-check" *ngFor="let type of paymentTypes">
              <div
                *ngIf="
                  type.code && (type.code == 'CARD' || isFullyRegisteredUser)
                "
              >
                <input
                  id="paymentType-{{ type.code }}"
                  class="form-check-input"
                  role="radio"
                  type="radio"
                  aria-checked="true"
                  (change)="selectPaymentType(type.code)"
                  [value]="type.code"
                  [checked]="type.code === typeSelected"
                />
                <label
                  class="cx-payment-type-label form-check-label form-radio-label"
                  for="paymentType-{{ type.code }}"
                >
                  <div class="cx-payment-type">
                    {{
                      "checkoutPaymentDetails.paymentType_" + type?.code
                        | cxTranslate
                    }}

                    <ng-template #tooltipContent>{{
                      "checkoutPaymentDetails.cardPaymentInfo" | cxTranslate
                    }}</ng-template>
                    <span class="tooltip-container" *ngIf="type.code == 'CARD'">
                      <span
                        [ngbTooltip]="tooltipContent"
                        placement="top-start"
                        tooltipClass="country-tooltip"
                      >
                        <cx-icon class="cx-icon fas fa-info-circle"></cx-icon>
                      </span>
                    </span>
                  </div>
                </label>
              </div>
            </div>
          </label>
        </div>
      </div>

      <form [formGroup]="checkoutPaymentDetailsForm" *ngIf="displayPaymentInfo">
        <div>
          <div class="form_field_wrapper">
            <label>
              <span class="label-content po-number-label">
                {{ "checkoutPaymentDetails.poNumber" | cxTranslate }}
                <span *ngIf="isPONumberMandatory" class="required">* </span>

                <ng-template #tooltipContent>{{
                  "checkoutPaymentDetails.poNumberInfo" | cxTranslate
                }}</ng-template>
                <span class="tooltip-container">
                  <span
                    [ngbTooltip]="tooltipContent"
                    placement="top-start"
                    tooltipClass="country-tooltip"
                  >
                    <cx-icon class="cx-icon fas fa-info-circle"></cx-icon>
                  </span>
                </span>
              </span>

              <input
                #poNumber
                class="form-control"
                formControlName="poNumber"
                type="text"
                [value]="checkoutPaymentDetailsForm.get('poNumber')?.value"
                [required]="isPONumberMandatory"
                (input)="updatePoNumber(poNumber.value)"
                placeholder="{{
                  isPONumberMandatory
                    ? ('checkoutPaymentDetails.required' | cxTranslate)
                    : ''
                }}"
              />
            </label>

            <span *ngIf="showPoNumberErrorMessage" class="po-number-error">
              <p>
                {{ "checkoutPaymentDetails.requiredPoNumber" | cxTranslate }}
              </p>
            </span>
          </div>
        </div>

        <div class="phone-container" *ngIf="!isFullyRegisteredUser">
          <div class="form_field_wrapper">
            <label>
              <span class="label-content phone-label">
                {{ PHONE_NUMBER_LABEL | cxTranslate }}</span
              >
              <span *ngIf="!isFullyRegisteredUser" class="required"> *</span>
              <input
                #telephone
                class="form-control"
                formControlName="telephone"
                type="text"
                required="{{ !isFullyRegisteredUser }}"
                value="{{ phoneNumber }}"
                (keyup)="updatePhoneNumber(telephone.value)"
                placeholder="{{
                  !isFullyRegisteredUser
                    ? ('checkoutPaymentDetails.required' | cxTranslate)
                    : ''
                }}"
              />
            </label>
            <cx-form-errors
              [control]="checkoutPaymentDetailsForm.get('telephone')"
            ></cx-form-errors>
          </div>
        </div>
      </form>

      <!-- Delivery Address -->
      <mt-delivery-address
        [addressType]="MtAddressType.BILL_TO"
        [displayNewAddressButton]="displayNewAddressButton"
        (hasAddresses)="checkHasAddresses($event)"
        (displayPaymentInfo)="newAddressOpened($event)"
        (sameAsDeliveryAddress)="setSameAsDeliveryAddress($event)"
      ></mt-delivery-address>
      <div class="order-summary-placeholder-mobile"></div>
      <div class="cx-checkout-btns" *ngIf="displayPaymentInfo">
        <button class="btn-action backBtn" (click)="back()">
          {{ "checkoutPaymentDetails.backButton" | cxTranslate }}
        </button>
        <a (click)="scrollToTop()">
          <button
            class="btn-primary validateBtn"
            (click)="validateOrder()"
            [disabled]="checkoutPaymentDetailsForm.invalid || (!hasAddresses && !sameAsDeliveryAddress && !isGuestUser)"
          >
            {{ "checkoutPaymentDetails.validateOrder" | cxTranslate }}
          </button>
        </a>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
