import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { CmsNavigationNode } from "@spartacus/core";
import { Observable } from "rxjs";
import { MtNavigationNode } from "../model/mt-navigation-node.model";
import { NavigationActions } from "../store/actions";
import { StateWithNavigation } from "../store/navigation-state";
import { getChildrenForSelectedNodeSelector, getCurrentNodeSelector, getParentForSelectedNodeSelector } from "../store/selectors/navigation.selectors";


@Injectable({ providedIn: 'root' })
export class MtNavigationService {

  constructor(protected store: Store<StateWithNavigation>) {
  }

  loadNavigationNodes(cmsNavigationNodes?: CmsNavigationNode, selectedNodeCode?: string) {
    if (cmsNavigationNodes) {
      this.store.dispatch(new NavigationActions.LoadNavigationNodes({
        cmsNodes: cmsNavigationNodes,
        selectedNodeCode: selectedNodeCode
      }));
    }
  }

  navigateToNode(nodeCode: string): void {
    if (nodeCode) {
      this.store.dispatch(new NavigationActions.NavigateToNode({
        nodeCode: nodeCode
      }))
    }
  }

  getSelectedNode(): Observable<MtNavigationNode> {
    return this.store.pipe(select(getCurrentNodeSelector));
  }

  getParentForSelectedNode(): Observable<MtNavigationNode> {
    return this.store.pipe(select(getParentForSelectedNodeSelector))
  }

  getChildrenForSelectedNode(): Observable<MtNavigationNode[]> {
    return this.store.pipe(select(getChildrenForSelectedNodeSelector));
  }

}