/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { OccCartAdapter } from "@spartacus/cart/base/occ";
import { Cart } from "@spartacus/cart/base/root";
import { ConverterService, OccEndpointsService, OCC_CART_ID_CURRENT } from "@spartacus/core";
import { Observable } from "rxjs";
import { MtSoldToService } from "../../../../user/account/core/mt-sold-to.service";
import { MtCartOccService } from "../../../services/mt-cart-occ.service";


@Injectable()
export class MtOccCartAdapter extends OccCartAdapter {

  constructor(
    protected override http: HttpClient,
    protected override occEndpointsService: OccEndpointsService,
    protected override converterService: ConverterService,
    protected mtSoldToService: MtSoldToService,
    protected mtCartOccService: MtCartOccService
  ) {
    super(http, occEndpointsService, converterService);
  }


  public override load(userId: string, cartId: string): Observable<Cart | undefined> {
    const currentSoldTo = this.mtSoldToService.getCurrentSoldTo();
    if (!currentSoldTo || cartId !== OCC_CART_ID_CURRENT) {
      return super.load(userId, cartId);
    } else {
      return this.mtCartOccService.loadCart(currentSoldTo);
    }
  }

}
