import { NgModule } from "@angular/core";
import { I18nConfig, OccConfig, provideConfig } from "@spartacus/core";
import { mtProductDetailsTranslationsConfig, mtProductTranslations } from "../assets/translations/translations";
import { MtProductDetailsRootModule, MT_PRODUCT_DETAILS } from "./mt-product-details-root.module";

@NgModule({
  imports: [MtProductDetailsRootModule],
  providers: [
    provideConfig({
      featureModules: {
        [MT_PRODUCT_DETAILS]: {
          module: () => import('./mt-product-details.module').then((m) => m.MtProductDetailsModule)
        }
      }
    }),
    provideConfig(<OccConfig>{
      backend: {
        occ: {
          endpoints: {
            product: {
              default:
                'products/${productCode}?fields=DEFAULT,images(FULL),classifications,manufacturer,categories(FULL),cpv,buOwner,esbu',
              details:
                'products/${productCode}?fields=stock(DEFAULT),description,code,url,price(DEFAULT),categories(FULL),images(FULL),benefits,technicalAttributes,cpv,plantData,buOwner,esbu',
            }
          }
        }
      }
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: mtProductTranslations,
        chunks: mtProductDetailsTranslationsConfig,
      }
    })
  ]
})
export class MtProductDetailsFeatureModule {}
