import {CxEvent} from "@spartacus/core";

export class MtPurchaseEvent extends CxEvent{
  static override readonly type = "ecommerce";

  ecommerce ?: PurchaseEnvelop;
  event ?: string;
  eventAction ?: string;
  eventCategory ?: string;
  eventValue ?: number;

}

export interface ProductFoRGTM{
  price ?: number;
  quantity ?: number;
  id ?: string;
  name ?: string;
}

export interface PurchaseEnvelop{
  purchase : Purchase
}

export interface Purchase {
  actionField : ActionFields;
  products ?: ProductFoRGTM[];
}

export interface ActionFields {
  currencyCode ?: string;
  id ?: string
  affiliation ?: string;
  revenue ?: string;
  tax ?: number;
  shipping ?: number;
}
