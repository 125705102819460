/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const checkout = {
  checkout: {
    backToCart: 'Zurück zum Einkaufswagen',
    continue: 'Weiter'
  },
  checkoutProgress: {
    label: 'Bestellung',
    deliveryAddress: 'Lieferadresse',
    deliveryMode: 'Liefermodus',
    paymentDetails: 'Zahlung',
    reviewOrder: 'Bestellung überprüfen',
  },
  cardActions: {
    setAsDefault: 'Als Standard einstellen',
    remove: 'Entfernen',
    edit: 'Ändern'
  },
  checkoutAddress: {
    newBillingAddress: 'Neue Rechnungsadresse',
    newDeliveryAddress: 'Neue Lieferadresse',
    editDeliveryAddress: 'Lieferadresse ändern',
    editBillingAddress: 'Rechnungsadresse ändern',
    deliveryAddress: 'Lieferadresse',
    billingAddress: 'Rechnungsadresse',
    selectYourDeliveryAddress: 'Select your Delivery Address',
    defaultDeliveryAddress: 'Standardadresse',
    addNewAddress: 'Neue Adresse',
    shipToThisAddress: 'Ship to this address',
    deliveryAddressSelected: 'Delivery address selected',
    sameAsDeliveryAddress: 'Entspricht der Lieferadresse',
    guestDeliveryAddress: {
      title: {
        perosnalInfo: 'Persönliche Angaben',
        address: 'Lieferadresse',
      },
      form: {
        titleCode: {
          label: 'Anrede*',
          placeholder: 'Pflichtfeld',
        },
        firstName: {
          label: 'Vorname*',
          placeholder: 'Pflichtfeld',
        },
        lastName: {
          label: 'Nachname*',
          placeholder: 'Pflichtfeld',
        },
        email: {
          label: 'E-Mail Adresse*',
          placeholder: 'Pflichtfeld',
        },
        company1: {
          label: 'Firmenname*',
          placeholder: 'Pflichtfeld',
        },
        company2: {
          label: 'Firmenname 2',
          placeholder: '',
        },
        company3: {
          label: 'Firmenname 3',
          placeholder: '',
        },
        poNumber:{
          label: "Postfach",
          placeholder: ""
        },
        country: {
          label: 'Land*',
          placeholder: 'Pflichtfeld',
          hint: 'This is your country. If you need to register your account with a different country, please switch to the corresponding METTLER TOLEDO website using the country selection near the top of this page.',
        },
        addressLine1: {
          label: 'Adresse 1*',
          placeholder: 'Pflichtfeld',
        },
        addressLine2: {
          label: 'Adresse 2',
          placeholder: '',
        },
        addressLine3: {
          label: 'Adresse 3',
          placeholder: '',
        },
        postalCode: {
          label: 'Postleitzahl*',
          placeholder: 'Pflichtfeld',
        },
        state: {
          label: 'Bundesland*',
          placeholder: 'Pflichtfeld',
        },
        city: {
          label: 'Stadt*',
          placeholder: 'Pflichtfeld',
        },
      },
      actions: {
        backToCart: 'Zurück zum Einkaufswagen',
        continue: 'Weiter'
      }
    }
  },
  checkoutMode: {
    button: {
      back: 'Zurück',
      continue: 'Weiter'
    },
    deliveryMethod: {
      title: 'Lieferoption',
      controls: {
        label: 'Auswählen'
      }
    },
    requestDeliveryDate: {
      title: 'Gewünschtes Lieferdatum',
      controls: {
        optionDeliveryAsFastAsPosible: 'Zum nächstmöglichen Zeitpunkt',
        optionDeliveryOnSpecificDate: 'Lieferung an einem bestimmten Datum',
        selectDate: 'Wählen Sie ein Datum aus*'
      }
    },
    consolidatedDelivery: {
      title: 'Konsolidierte Lieferung',
      controls: {
        optionYes: 'Ja',
        optionNo: 'Nein',
        infoText: 'Sofern möglich, werden wir ihre Bestellung gerne konsolidieren.',
      }
    },
    deliveryPayment: {
      title: 'Bezahlung der Lieferung',
      controls: {
        optionPrepaid: 'Prepaid',
        collect: 'Vorauskasse',
        collectNumber: 'Collect Number*'
      },
      formErrors: {
        required: 'Required field',
        validate: 'Collect # is not valid'
      }
    }
  },
  checkoutReview: {
    review: {
      title: 'Bestellungsübersicht',
      labels: {
        deliveryAddress: 'Lieferadresse',
        deliveryOption: 'Lieferoption',
        requestedDeliveryDate: 'Gewünschtes Lieferdatum',
        deliverAsFastAsPossible: 'Zum nächstmöglichen Zeitpunkt',
        consolidatedDelivery: {
          title: 'Konsolidierte Lieferung',
          yes: 'Ja',
          no: 'Nein',
        },
        deliveryPayment: {
          title: 'Bezahlung der Lieferung',
          collect: 'Vorauskasse',
          prepaid: 'Prepaid'
        },
        billingAddress: 'Rechnungsadresse',
        paymentType: 'Zahlungsart',
        purchaseOrderNumber: {
          title: 'PO# (Bestellnummer)'
        },
        empty: 'Keine Angabe'
      }
    },
    orderItems: {
      title: 'Produkte',
      labels: {
        materialID: 'Artikelnummer',
        quantity: 'Menge',
        itemStandardPrice: 'Artikel Standardpreis',
        itemNetPrice: 'Artikel Nettopreis',
        totalNetPrice: 'Gesamtpreis netto'
      }
    },
    orderSummary: {
      title: 'Bestellungsübersicht',
      quantity: 'Anzahl:',
      total: 'Gesamtpreis',
      discounts: 'Rabatte:',
      surchargeFreight: 'Lieferkosten:',
      yourNetPrice: 'Ihr Nettopreis:',
      salesTax: 'Steuern:',
      infoMessage: 'Final shipping & handling charges will be available in the order invoice.',
    },
    confirmThatRead: 'Ich habe die allgemeinen',
    termsAndConditions: 'Geschäftsbedingugen',
    confirmThatRead2: 'zur Kenntnis genommen und akzeptiere diese.*',
    placeOrder: 'Bestellung',
    payPlaceOrder: 'Zahlen und bestellen',
    editDeliveryAddressDetails:
      'Edit delivery address details, opens Delivery Address page',
    editPaymentDetails: 'Edit payment details, opens Payment Details page',
    editPaymentType: 'Edit payment method, opens Method of Payment page',
    editDeliveryMode: 'Edit delivery mode, opens Delivery Mode page',
    orderInProcess: 'Order is in process. Please wait.',
    paymentError: 'Es liegt ein Problem mit Ihrer Zahlungsautorisierung vor. Bitte versuchen Sie es erneut oder wenden Sie sich an den Kundendienst',
    back: 'Zurück'
  },
  checkoutOrderConfirmation: {
    title: 'Vielen Dank für Ihre Bestellung!',
    info1: 'Ihre Bestellnummer lautet <b>{{orderCode}}</b>.',
    info2: 'Eine Bestellbestätigung wird Ihnen per E-Mail zugesandt.',
    btnLabel: 'Zurück zum Shop',
    confirmationOfOrder: 'Confirmation of Order:',
    thankYou: 'Vielen Dank für Ihre Bestellung!',
    invoiceHasBeenSentByEmail:
      'An invoice has been sent by email. You should receive it soon.',
    orderItems: 'Order Items',
    orderPlacedSuccessfully: 'Order placed successfully',
    createAccount: 'Create an account?',
    createAccountForNext:
      'Create an account for <{{email}}> for a faster checkout on your next visit.',
  },
  checkoutPaymentDetails: {
    paymentDetails: 'Zahlungsart',
    phoneNumber: 'Telefonnummer',
    poNumber: 'PO# (Bestellnummer)',
    validateOrder: 'Bestellung prüfen',
    backButton: 'Zurück',
    cardPaymentInfo: 'Nach der Bestellung werden Sie zur Zahlungsseite weitergeleitet, um Ihre Kreditkarte zu autorisieren.',
    poNumberInfo: 'Wenn Sie keine PO # (Bestellnummer) haben, können Sie eine Referenz Ihrer Wahl angeben.',
    paymentType_CARD: 'Kartenzahlung',
    paymentType_CREDIT:'Kartenzahlung',
    paymentType_ACCOUNT: 'Rechnungszahlung',
    required: 'Pflichtfeld',
    requiredPoNumber: 'Please enter a purchase order number'
  }
};
