/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const checkout = {
  checkout: {
    backToCart: 'Retour au panier',
    continue: 'Continuer'
  },
  checkoutProgress: {
    label: 'Passer la commande',
    deliveryAddress: 'Adresse de livraison',
    deliveryMode: 'Mode de livraison',
    paymentDetails: 'Paiement',
    reviewOrder: 'Revoir la commande',
  },
  cardActions: {
    setAsDefault: 'Paramétrer par défaut',
    remove: 'Retirer',
    edit: 'Modifier'
  },
  checkoutAddress: {
    newBillingAddress: 'Nouvelle adresse de facturation',
    newDeliveryAddress: 'Nieuw Afleveradres',
    editDeliveryAddress: 'Modifier l\'adresse de livraison',
    editBillingAddress: 'Modifier l\'adresse de facturation',
    deliveryAddress: 'Adresse de livraison',
    billingAddress: 'Adresse de facturation',
    selectYourDeliveryAddress: 'Select your Delivery Address',
    defaultDeliveryAddress: 'Adresse par défaut',
    addNewAddress: 'Nouvelle adresse',
    shipToThisAddress: 'Ship to this address',
    deliveryAddressSelected: 'Delivery address selected',
    sameAsDeliveryAddress: 'Identique à l\'adresse de livraison',
    guestDeliveryAddress: {
      title: {
        perosnalInfo: 'Informations personnelles',
        address: 'Adresse de livraison',
      },
      form: {
        titleCode: {
          label: 'Titre*',
          placeholder: 'Champ obligatoire',
        },
        firstName: {
          label: 'Prénom*',
          placeholder: 'Champ obligatoire',
        },
        lastName: {
          label: 'Nom*',
          placeholder: 'Champ obligatoire',
        },
        email: {
          label: 'E-Mail*',
          placeholder: 'Champ obligatoire',
        },
        company1: {
          label: 'Nom Société*',
          placeholder: 'Champ obligatoire',
        },
        company2: {
          label: 'Nom Société 2',
          placeholder: '',
        },
        company3: {
          label: 'Nom Société 3',
          placeholder: '',
        },
        poNumber:{
          label: "Boîte postale",
          placeholder: ""
        },
        country: {
          label: 'Pays*',
          placeholder: 'Champ obligatoire',
          hint: 'This is your country. If you need to register your account with a different country, please switch to the corresponding METTLER TOLEDO website using the country selection near the top of this page.',
        },
        addressLine1: {
          label: 'Adresse 1*',
          placeholder: 'Champ obligatoire',
        },
        addressLine2: {
          label: 'Adresse 2',
          placeholder: '',
        },
        addressLine3: {
          label: 'Adresse 3',
          placeholder: '',
        },
        postalCode: {
          label: 'Code Postal*',
          placeholder: 'Champ obligatoire',
        },
        state: {
          label: 'État*',
          placeholder: 'Champ obligatoire',
        },
        city: {
          label: 'Ville*',
          placeholder: 'Champ obligatoire',
        },
      },
      actions: {
        backToCart: 'Retour au panier',
        continue: 'Continuer'
      }
    }
  },
  checkoutMode: {
    button: {
      back: 'Retour',
      continue: 'Continuer'
    },
    deliveryMethod: {
      title: 'Mode de livraison',
      controls: {
        label: 'Choisir'
      }
    },
    requestDeliveryDate: {
      title: 'Choisir une date de livraison',
      controls: {
        optionDeliveryAsFastAsPosible: 'Le plus rapidement possible',
        optionDeliveryOnSpecificDate: 'Planifier une date de livraison',
        selectDate: 'Sélectionner und date*'
      }
    },
    consolidatedDelivery: {
      title: 'Livraison consolidée',
      controls: {
        optionYes: 'Oui',
        optionNo: 'Non',
        infoText: 'Nous tenterons de consolider votre livraison, si possible.',
      }
    },
    deliveryPayment: {
      title: 'Paiement de la livraison',
      controls: {
        optionPrepaid: 'Prepaid',
        optionCollect: 'Paiement d\'avance',
        collectNumber: 'Collect Number*'
      },
      formErrors: {
        required: 'Required field',
        validate: 'Collect # is not valid'
      }
    }
  },
  checkoutReview: {
    review: {
      title: 'Résumé de la commande',
      labels: {
        deliveryAddress: 'Adresse de livraison',
        deliveryOption: 'Mode de livraison',
        requestedDeliveryDate: 'Choisir une date de livraison',
        deliverAsFastAsPossible: 'Le plus rapidement possible',
        consolidatedDelivery: {
          title: 'Livraison consolidée',
          yes: 'Oui',
          no: 'Non',
        },
        deliveryPayment: {
          title: 'Paiement de la livraison',
          collect: 'Paiement d\'avance',
          prepaid: 'Prepaid'
        },
        billingAddress: 'Adresse de facturation',
        paymentType: 'Type de payement',
        purchaseOrderNumber: {
          title: 'Référence de commande PO#'
        },
        empty: 'Non spécifié'
      }
    },
    orderItems: {
      title: 'Articles',
      labels: {
        materialID: 'Numéro d\'article',
        quantity: 'Quantité',
        itemStandardPrice: 'Prix standard de l\'article',
        itemNetPrice: 'Prix net de l\'article',
        totalNetPrice: 'Prix net total'
      }
    },
    orderSummary: {
      title: 'Résumé',
      quantity: 'Quantité:',
      total: 'Prix total',
      discounts: 'Rabais:',
      surchargeFreight: 'Expédition:',
      yourNetPrice: 'Votre Prix Net:',
      salesTax: 'Taxes TVA',
      infoMessage: 'Final shipping & handling charges will be available in the order invoice.',
    },
    confirmThatRead: 'J\'ai lu et j\'approve les',
    termsAndConditions: 'Termes et Conditions.*',
    confirmThatRead2: '',
    placeOrder: 'Commander',
    payPlaceOrder: 'Payer et passer commande',
    editDeliveryAddressDetails:
      'Edit delivery address details, opens Delivery Address page',
    editPaymentDetails: 'Edit payment details, opens Payment Details page',
    editPaymentType: 'Edit payment method, opens Method of Payment page',
    editDeliveryMode: 'Edit delivery mode, opens Delivery Mode page',
    orderInProcess: 'Order is in process. Please wait.',
    paymentError: 'Il y a un problème avec votre autorisation de paiement. Veuillez réessayer ou contacter le service client.',
    back: 'Retour'
  },
  checkoutOrderConfirmation: {
    title: 'Nous vous remercions de votre commande!',
    info1: 'Votre numéro de commande est <b>{{orderCode}}</b>.',
    info2: 'Une confirmation de commande vous sera envoyée par e-mail.',
    btnLabel: 'Retour à la boutique',
    confirmationOfOrder: 'Confirmation of Order:',
    thankYou: 'Nous vous remercions de votre commande!',
    invoiceHasBeenSentByEmail:
      'An invoice has been sent by email. You should receive it soon.',
    orderItems: 'Order Items',
    orderPlacedSuccessfully: 'Order placed successfully',
    createAccount: 'Create an account?',
    createAccountForNext:
      'Create an account for <{{email}}> for a faster checkout on your next visit.',
  },
  checkoutPaymentDetails: {
    paymentDetails: 'Type de payement',
    phoneNumber: 'Téléphone',
    poNumber: 'Référence de commande PO#',
    validateOrder: 'Commander',
    backButton: 'Retour',
    cardPaymentInfo: 'Après avoir passé la commande, vous serez redirigé vers le site de paiement pour autoriser votre carte de crédit.',
    poNumberInfo: 'Si vous n\'avez pas de numéro de commande, vous pouvez utiliser ce champ selon vos codifications en vigueur.',
    paymentType_CARD: 'Carte de crédit',
    paymentType_CREDIT:'Carte de crédit',
    paymentType_ACCOUNT: 'Facturation',
    required: 'Champ obligatoire',
    requiredPoNumber: 'Please enter a purchase order number'
  }
};
