<div class="mt-product-card-content-qty-ctrl">
  <div class="mt-product-card-content-qty-left">
    <a (click)="removeItemFromCart()" class="mt-product-card-content-qty-remove">
      <svg width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 2H0V0H14V2Z" fill="#004494" />
      </svg>
    </a>
  </div>

  <div class="mt-product-card-content-qty-input">
    <input #quantityInput type="number" [(ngModel)]="quantity" class="mt-product-card-content-qty-input-text"
      (ngModelChange)="onQuantityChanged($event)" />
  </div>

  <div class="mt-product-card-content-qty-right">
    <a (click)="addItemToCart()" class="mt-product-card-content-qty-add">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="#004494" />
      </svg>
    </a>
  </div>
</div>