import { ChangeDetectionStrategy, Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActiveCartFacade, CartAddEntryFailEvent, CartAddEntrySuccessEvent, CartUiEventAddToCart } from '@spartacus/cart/base/root';
import { AuthService, EventService, ProductService, UrlCommands, WindowRef } from '@spartacus/core';
import { FocusConfig, ICON_TYPE, LaunchDialogService } from '@spartacus/storefront';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { MtUrlService } from 'src/app/spartacus/custom-module/core/aem-adapter-site-context/mt-url.service';
import { MtPriceModel } from '../../../model/mt-price.model';
import { MtProduct } from '../../../model/mt-product.model';
import { MtPriceService } from '../../../product-price';
import { MtQuantityChangedEvent } from '../../../product-quantity-picker/components/quantity-picker-shell/mt-quantity-changed.event';
import { CartUiEventAddToCartMultipleProductsEvent } from '../../events/mt-cart-ui-event-add-to-cart-multiple-products.event';
import { MtCartRowModel } from './mt-cart-row.model';

@Component({
  selector: 'mt-added-to-cart-dialog',
  templateUrl: './mt-added-to-cart-dialog.component.html',
  styleUrls: ['./mt-added-to-cart-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MtAddedToCartDialogComponent implements OnInit, OnDestroy {

  iconTypes = ICON_TYPE;

  multipleProducts: boolean = false;
  cartRows: MtCartRowModel[] = [];
  totalPrice$: BehaviorSubject<Observable<string | undefined>> = new BehaviorSubject(of("-") as Observable<string | undefined>);
  showTotalPrice$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  globalLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  form: UntypedFormGroup = new UntypedFormGroup({});

  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: 'button',
    focusOnEscape: true,
  };

  @HostListener('click', ['$event'])
  handleClick(event: UIEvent): void {
    if ((event.target as any).tagName === this.el.nativeElement.tagName) {
      this.dismissModal('Cross click');
    }
  }


  protected subscription = new Subscription();

  constructor(
    protected launchDialogService: LaunchDialogService,
    protected el: ElementRef,
    private cartFacade: ActiveCartFacade,
    private eventService: EventService,
    private productService: ProductService,
    protected mtUrlService: MtUrlService,
    protected windowRef: WindowRef,
    protected priceService: MtPriceService,
    protected authService: AuthService,
  ) { }


  ngOnInit(): void {

    this.subscription.add(
      this.cartFacade.getActive().subscribe()
    );

    this.subscription.add(
      this.launchDialogService.data$.subscribe(
        (dialogData: CartUiEventAddToCart | CartUiEventAddToCartMultipleProductsEvent) => {

          let productsCodes: string[] = [];
          if (dialogData instanceof CartUiEventAddToCart) {
            this.multipleProducts = false;
            this.globalLoading$.next(true);
            productsCodes.push(dialogData.productCode);
          } else if (dialogData instanceof CartUiEventAddToCartMultipleProductsEvent) {
            this.multipleProducts = true;
            this.globalLoading$.next(false);
            productsCodes = dialogData.products?.map((product) => product.productId) ?? [];
          }

          combineLatest([
            this.authService.isUserLoggedIn(),
            this.priceService.buildProductCodePriceRequest(productsCodes)
          ]).subscribe(([isUserLoggedIn, priceModels]) => {
            if (dialogData instanceof CartUiEventAddToCart) {
              // Initialize UI
              const cartRow: MtCartRowModel =
                this.createCartRow(
                  this.selectPrice(dialogData.productCode, isUserLoggedIn, priceModels),
                  dialogData.productCode,
                  dialogData.quantity,
                  dialogData.numberOfEntriesBeforeAdd
                );
              this.cartRows.push(cartRow);
              this.globalLoading$.next(false);
            } else if (dialogData instanceof CartUiEventAddToCartMultipleProductsEvent) {
              this.showTotalPrice$.next(true);
              this.cartRows =
                dialogData.products?.map(
                  (product) => this.createCartRow(this.selectPrice(product.productId, isUserLoggedIn, priceModels), product.productId, product.quantity, -1)
                ) ?? [];
            } else {
              console.error(`CartUiEventAddToCart - Invalid event type: ${JSON.stringify(dialogData)}`);
            };

            // Compute total price
            this.computeTotalPrice();
          });
        }
      )
    );

    // item added to cart successfully { return this.productCode && cartEvent.productCode === this.productCode; }
    this.subscription.add(
      this.eventService
        .get(CartAddEntrySuccessEvent)
        .pipe(
          tap(entry => console.log(`CartAddEntrySuccessEvent = ${entry.productCode} - ${entry.quantity}`)),
          map((cartEvent: CartAddEntrySuccessEvent) => this.cartRows.filter((data: MtCartRowModel) => data.productCode === cartEvent.productCode)),
          filter((mtCartRows: MtCartRowModel[]) => mtCartRows !== undefined && mtCartRows.length > 0),
          map((mtCartRows: MtCartRowModel[]) => mtCartRows[0])
        ).subscribe((data: MtCartRowModel) => {
          data.isLoading$.next(false);
          data.hasErrors$.next(false);
        })
    );

    // item faild to be added to cart
    this.subscription.add(
      this.eventService
        .get(CartAddEntryFailEvent)
        .pipe(
          tap(entry => console.log(`CartAddEntryFailEvent = ${entry.productCode} - ${entry.quantity} - ${entry.error}`)),
          map(
            (cartEvent: CartAddEntryFailEvent) =>
              this.cartRows
                .filter((data: MtCartRowModel) => data.productCode === cartEvent.productCode)
                .map((mtCartRow: MtCartRowModel) => {
                  mtCartRow.errorMessage = cartEvent.error;
                  return mtCartRow;
                })
          ),
          filter((mtCartRows: MtCartRowModel[]) => mtCartRows !== undefined && mtCartRows.length > 0),
          map((mtCartRows: MtCartRowModel[]) => mtCartRows[0])
        ).subscribe((data: MtCartRowModel) => {
          data.isLoading$.next(false);
          data.hasErrors$.next(true);
        })
    );

  }

  private selectPrice(productCode: string, isUserLoggedIn: boolean, priceModels: MtPriceModel[]): number {
    const priceModel: MtPriceModel | undefined =
      priceModels.find((price: MtPriceModel) => price.productCode === productCode);
    if (!priceModel) return 0;
    return (isUserLoggedIn) ? priceModel.customerPrice : priceModel.listPrice;
  }

  private computeTotalPrice() {
    const totalPriceValue: number =
      this.cartRows
        .map((cartRow) => cartRow.price * cartRow.quantity)
        .reduce((acc, currentPrice) => acc + currentPrice);

    //console.log(`totalPriceValue = ${totalPriceValue}`);

    this.totalPrice$.next(this.priceService.getFormattedPrice(totalPriceValue, 1));
  }

  private createCartRow(
    price: number,
    productCode: string,
    quantity: number,
    numberOfEntriesBeforeAdd: number
  ): MtCartRowModel {
    const cartRow: MtCartRowModel = new MtCartRowModel();

    cartRow.productCode = productCode;
    cartRow.price = price;

    // Add product to cart
    this.cartFacade.addEntry(productCode, quantity);
    cartRow.isLoading$.next(true);

    //this.form.markAsDirty();
    cartRow.product$ =
      this.productService.get(productCode).pipe(map((product) => product as MtProduct));

    cartRow.quantity = quantity;
    cartRow.numberOfEntriesBeforeAdd = numberOfEntriesBeforeAdd;

    return cartRow;
  }

  onQuantityChange(event: MtQuantityChangedEvent) {
    this.cartRows[event.index].quantity = event.quantity;
    if (!this.multipleProducts) {
      this.showTotalPrice$.next(event.quantity > 1);
    }
    this.computeTotalPrice();
  }

  getURL(commands: UrlCommands): string {
    return this.mtUrlService.buildURLWithAEMContext(commands);
  }

  get isSpartacusContext(): boolean {
    return this.mtUrlService.isSpartacusContext();
  }

  dismissModal(reason?: any): void {
    this.launchDialogService.closeDialog(reason);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  goToCart(): void {
    if (this.isSpartacusContext === true) {
      this.dismissModal("close add to cart dialog");
    } else {
      this.windowRef.document.location.href = this.getURL({ cxRoute: 'cart' });
    }
  }

}


