<ng-container *ngIf="user$ | async as user; else notLoggedIn">

  <div *ngIf="user.companyRegistrationStatus === getOrganisationRegistrationStatusType.NOT_REGISTERED">
    <div class="form__highlight org-info my-account global-alert-info">
      <ul class="notification form__info">
        <li class="text-left alert-icon"><span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2552_2294)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM11 7V5H13V7H11ZM11 19V9H13V19H11Z" fill="#004494"/>
            </g>
            <defs>
              <clipPath id="clip0_2552_2294">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </span></li>
        <li class="text-left alert-label"><span>
          <a routerLink="myProfile"> {{ 'mtUserRegistrationForm.labels.registerLabel1' | cxTranslate }}</a>
          <span><span>
            {{ 'mtUserRegistrationForm.labels.registerLabel2' | cxTranslate }}
           </span> </span>
        </span></li>
      </ul>

    </div>
  </div>

  <div *ngIf="user.companyRegistrationStatus === getOrganisationRegistrationStatusType.REGISTRATION_NOT_CONFIRMED" class="registration-org-form__info__alert">
    <div class="alert alert-warning org-info" role="alert">
      <span class="pl-10 icon">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2621_2286)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM11 15V6.00001H13V15H11ZM11 19V17H13V19H11Z" fill="#F29301"/>
        </g>
        <defs>
          <clipPath id="clip0_2621_2286">
            <rect width="24" height="24" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </span>
      <div class="mt-alert">
        <p class="form__info alert" >
          {{ 'mtUserRegistrationForm.labels.registerInProgressLabel' | cxTranslate }}
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="user.companyRegistrationStatus === getOrganisationRegistrationStatusType.SINGLE_SOLDTO_ACCOUNT
             || user.companyRegistrationStatus === getOrganisationRegistrationStatusType.MULTIPLE_SOLDTO_ACCOUNTS
           ; then selectedCompanyInfo">
  </div>

  <ng-template #selectedCompanyInfo>
    <div *ngIf="soldToList$ | async as soldToList">
      <div *ngFor="let soldToItem of soldToList">
        <div *ngIf="soldToIs(soldToItem.businessPartnerId)">
          <div class="form mt-profile-item org-info">

            <div class="form_field_wrapper">
              <label>
                <span>{{ 'mtUserRegistrationForm.labels.selectedBusinessAccount' | cxTranslate }}</span>
              </label>
            </div>

            <p class="form__info">
              <span>{{ soldToItem.companyData.name }}</span>
            </p>

            <p class="form__info">
              <span>
                {{ soldToItem.companyData.address }}
                {{ soldToItem.companyData.address2 }}
                {{ soldToItem.companyData.address3 }}
                {{ soldToItem.companyData.city }}, {{ soldToItem.companyData.region }},
                {{ soldToItem.companyData.country }}
                </span>
            </p>

            <p class="form__info">
              <span>{{ 'mtUserAccountAndLogin.soldtoSelection.mtNumber' | cxTranslate }}</span><span>{{ soldToItem.businessPartnerId }}</span>
            </p>
            <br>

            <div *ngIf="user.companyRegistrationStatus === getOrganisationRegistrationStatusType.MULTIPLE_SOLDTO_ACCOUNTS">
              <p class="form__info">
                <span>{{ 'mtUserRegistrationForm.labels.multipleLinkedProfile' | cxTranslate }}</span>
              </p>
              <p class="form__info">
                <span>{{ 'mtUserRegistrationForm.labels.changeSelection' | cxTranslate }}</span>
              </p>
              <p class="form__info">
                <a routerLink="myProfile"
                > {{ 'mtUserAccountAndLogin.myAccount.tiles.myProfile' | cxTranslate }}
                </a>
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
  </ng-template>

</ng-container>

<ng-template #notLoggedIn>
  Company Info: Not logged in
</ng-template>






