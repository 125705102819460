import { getCurrencySymbol } from "@angular/common";
import { Injectable, InjectionToken } from "@angular/core";
import { Converter } from "@spartacus/core";
import { MtPriceModel } from "../model/mt-price.model";
import { MtOccItemPriceResponseModel, MtOccPriceResponseModel } from "../model/occ/mt-occ-price.model";


export const PRICE_NORMALIZER = new InjectionToken<Converter<any, MtPriceModel[]>>('PriceNormalizer');


/**
 * Convert getprice response to spartacus Price interface.
 */
@Injectable({ providedIn: 'root' })
export class MtPriveConverter implements Converter<MtOccPriceResponseModel, MtPriceModel[]> {

  constructor() { }

  convert(source: MtOccPriceResponseModel, target?: MtPriceModel[] | undefined): MtPriceModel[] {

    const currencyIso = source.currency;
    const currencySymbol = getCurrencySymbol(currencyIso, 'narrow');

    target =
      source?.items?.map((mtPrice: MtOccItemPriceResponseModel) => {
        const currentValue =
          (source.isAuthenticated) ? mtPrice.customerPrice : mtPrice.listPrice;
        const price = {
          productCode: mtPrice.productID,
          currencyIso: currencyIso ?? '',
          formattedListPrice: `${currencySymbol}${mtPrice.listPrice}`,
          formattedListPriceWithVatValue: `${currencySymbol}${mtPrice.standardPriceWithVatValue}`,
          formattedCustomerPrice: `${currencySymbol}${mtPrice.customerPrice}`,
          formattedCustomerPriceWithVatValue: `${currencySymbol}${mtPrice.yourPriceWithVatValue}`,
          listPrice: mtPrice.listPrice,
          listPriceWithVatValue: mtPrice.standardPriceWithVatValue,
          customerPrice: mtPrice.customerPrice,
          customerPriceWithVatValue: mtPrice.yourPriceWithVatValue,
          value: currentValue,
          formattedValue: `${currencySymbol}${currentValue}`,
          vatDescription: mtPrice.vatDescription,
        } as MtPriceModel;
        return price;
      });

    return target ?? [];
  }

}
