<table class="mt-cart-item-list-table" role="table">
  <caption class="cx-visually-hidden">
    {{
      'cartItems.caption' | cxTranslate
    }}
  </caption>
  <tbody class="cx-item-list-items">
    <ng-container *ngFor="let item of items; let i = index">
      <ng-container
        *ngIf="getControl(item) | async as control"
        [class.is-changed]="control.get('quantity')!.disabled"
      >
      <tr
        mt-cart-item-list-row
          role="row"
          class="cx-item-list-row"
          [item]="item"
          [quantityControl]="convertToFormControl(control.get('quantity'))"
      >  </tr>
      </ng-container>
    </ng-container>
  </tbody>
</table>

<ng-template #totalHeader>
  <th role="columnheader" class="cx-item-list-total">
    {{ 'cartItems.total' | cxTranslate }}
  </th>
</ng-template>
