import { Injectable } from "@angular/core";
import { BaseSite, BaseSiteService, BASE_SITE_CONTEXT_ID, JavaRegExpConverter, SiteContextConfig, SiteContextConfigInitializer, WindowRef } from "@spartacus/core";
import { CountryBasesiteConvertor } from "./country-basesite.convertor";


@Injectable({ providedIn: 'root' })
export class CountrySiteContextConfigInitializer extends SiteContextConfigInitializer {

  constructor(
    protected convertor: CountryBasesiteConvertor,
    baseSiteService: BaseSiteService, javaRegExpConverter: JavaRegExpConverter, winRef: WindowRef
  ) {
    super(baseSiteService, javaRegExpConverter, winRef);
  }

  protected override getConfig(source: BaseSite): SiteContextConfig {
    console.log(`CountrySiteContextConfigInitializer`)
    const siteContextConfig = super.getConfig(source);

    const country = (siteContextConfig.context && siteContextConfig.context[BASE_SITE_CONTEXT_ID]?.map(
      site => this.convertor.baseSiteIdToCountry(site)
    )) || '';

    const config = {
      context: { ...siteContextConfig.context, country }
    } as SiteContextConfig;

    return config;
  }

}