
import { Cart, PaymentType } from '@spartacus/cart/base/root';
import { CheckoutPaymentTypeAdapter } from '@spartacus/checkout/b2b/core';
import { Observable } from 'rxjs';
import { MtOrderSimulationResponseError } from '../../../model/mt-order-simulation-response-error.model';

export abstract class MtCheckoutPaymentTypeAdapter extends CheckoutPaymentTypeAdapter {
  /**
   * Abstract method used to get available payment types
   */
  abstract override getPaymentTypes(): Observable<PaymentType[]>;

  /**
   * Abstract method used to set payment type to cart
   *
   * @param userId
   * @param cartId
   * @param typeCode
   * @param purchaseOrderNumber: purchase order number
   * @param phoneNumber
   */
  abstract override setPaymentType(
    userId: string,
    cartId: string,
    typeCode: string,
    purchaseOrderNumber?: string,
    phoneNumber?: string
  ): Observable<unknown>;

  abstract getOrderSimulation(userId: string, cartId: string): Observable<MtOrderSimulationResponseError | Cart>;
}
