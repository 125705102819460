import { Injectable } from '@angular/core';
import { ActiveCartFacade, Cart, OrderEntry } from '@spartacus/cart/base/root';
import { AuthService, Product, UserIdService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { MtPriceModel } from 'src/app/spartacus/custom-module/product/model/mt-price.model';
import { MtPriceService } from 'src/app/spartacus/custom-module/product/product-price';
import { MtCheckoutDeliveryModeService } from '../../../core/facade/mt-checkout-delivery-mode.service';
import { MtDeliveryMethodModel } from '../../../model/mt-delivery-method.model';

@Injectable({
  providedIn: 'root'
})
export class MtCheckoutReviewSubmitComponentService {

  constructor(
    protected authService: AuthService,
    protected priceService: MtPriceService,
    protected userIdService: UserIdService,
    protected activeCartFacade: ActiveCartFacade,
    protected checkoutDeliveryModeService: MtCheckoutDeliveryModeService
  ) { }

  get cart$(): Observable<Cart> {
    return this.activeCartFacade.getActive();
  }

  get entries$(): Observable<OrderEntry[]> {
    return this.activeCartFacade.getEntries().pipe(
      map((entries: OrderEntry[]) => {

        const products: Product[] =
          entries.map(entry => entry.product as {});

        const enhancedEntries: Observable<OrderEntry[]> =
          this.priceService.buildProductPriceRequest(products).pipe(
            map((mtPrices: MtPriceModel[]) => this.priceService.setupPriceForProduct(products, mtPrices)),
            map((enhancedProducts: Product[] | undefined) => {
              return entries.map((entry: OrderEntry) => {
                return {
                  ...entry,
                  product: enhancedProducts?.filter((product: Product) => product.code === entry.product?.code)[0]
                } as OrderEntry
              });
            })
          );

        return enhancedEntries;
      }),
      take(1),
      switchMap((enhancedEntries: Observable<OrderEntry[]>) => enhancedEntries)
    );
  }

  get deliveryMethod$(): Observable<MtDeliveryMethodModel> {
    return this.checkoutDeliveryModeService.getDeliveryMethod().pipe(
      //filter((deliveryMethod) => deliveryMethod !== undefined),
      map((deliveryMethod) => deliveryMethod || ({} as MtDeliveryMethodModel))
    );
  }

  get loggedInUser$(): Observable<boolean> {
    return this.authService.isUserLoggedIn();
  }

  refreshCart():  Observable<Cart>  {
    this.activeCartFacade.reloadActiveCart();
    return this.activeCartFacade.getActive();
  }

}
