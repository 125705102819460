/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Component } from '@angular/core';
import {AuthService, RoutingService} from '@spartacus/core';
import { User, UserAccountFacade } from '@spartacus/user/account/root';
import {Observable, of, Subscription} from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {MtOrganisationRegistrationStatusEnum, MtSoldToService} from "../../core/mt-sold-to.service";

@Component({
  selector: 'mt-org-info',
  templateUrl: './mt-org-info.component.html',
  styleUrls: ['./mt-org-info.component.scss']
})
export class MtOrgInfoComponent {

  user$: Observable<User | undefined>;
  protected subscription = new Subscription();
  protected soldToList$: Observable<any> = this.mtSoldToService.getSoldToList();

  constructor(
    private authService: AuthService,
    protected routingService: RoutingService,
    private userAccountFacade: UserAccountFacade,
    private mtSoldToService : MtSoldToService,
  ) {
    this.user$ = this.userAccountFacade.get();
  }

  ngOnInit(): void {
    this.user$ = this.authService.isUserLoggedIn().pipe(
      switchMap((isUserLoggedIn) => {
        if (isUserLoggedIn) {
          return this.userAccountFacade.get();
        } else {
          return of(undefined);
        }
      })
    );

  }

  public get getOrganisationRegistrationStatusType(): typeof MtOrganisationRegistrationStatusEnum {
    return MtOrganisationRegistrationStatusEnum;
  }

  soldToIs(soldTo: string) : boolean {
    return soldTo == this.mtSoldToService.getCurrentSoldTo();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
