import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { I18nModule } from '@spartacus/core';
import { FormErrorsModule, IconModule, KeyboardFocusModule, NgSelectA11yModule } from '@spartacus/storefront';
import { CheckoutGuestDeliveryAddressFormComponent } from './checkout-guest-delivery-address-form.component';



@NgModule({
  declarations: [
    CheckoutGuestDeliveryAddressFormComponent
  ],
  imports: [
    NgSelectA11yModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgSelectModule,
    NgbTooltipModule,
    IconModule,
    I18nModule,
    FormErrorsModule,
    KeyboardFocusModule,
    RouterModule,
  ],
  exports: [
    CheckoutGuestDeliveryAddressFormComponent
  ]
})
export class CheckoutGuestDeliveryAddressFormModule { }
