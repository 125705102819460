import { Injectable } from "@angular/core";
import {BehaviorSubject, Observable, of, Subject} from "rxjs";
import { MtProductAtpModel } from "../../model/mt-product-atp.model";
import { ProductAtpConnector } from "../../product-data-binding/product-atp.connector";
import {BaseSiteService} from "@spartacus/core";

@Injectable({ providedIn: 'root' })
export class MtProductAtpService {

  private quantitySubject$: Subject<undefined> = new Subject<undefined>();

  constructor(
    protected productAtpConnector: ProductAtpConnector,
    protected baseSiteService: BaseSiteService
  ) { }

  getProductAtp(productCode: string, quantity: number = mtDefaultProductQuantity): Observable<MtProductAtpModel> {
    return this.productAtpConnector.getProductAtp(productCode, quantity);
  }

  qtyChangeEvent(qty: any) {
    this.quantitySubject$.next(qty);
  }

  get getCurrentQty$ () {
    return this.quantitySubject$.asObservable();
  }

  public getATPEnabled(): Observable<boolean> {
    const result = new BehaviorSubject(false);

    this.baseSiteService
      .getActive()
      .subscribe(
        (activeBaseSite) => {
          this.baseSiteService.get(activeBaseSite)
            .subscribe(
              (baseSite) => {
                // result.next(baseSite?.stores?.atpEnabled ?? false );
                result.next( baseSite?.baseStore?.atpEnabled ?? false);
              },
              error => {
                result.next(false);
              });
        }
      );
    return result.asObservable();
  }


}

export const mtDefaultProductQuantity: number = 1;
