
export const mtProductCart = {
  titles: {
    loading: "Aggiornamento del carrello…",
    addcart: "Articolo(s) aggiunto al carrello",
    updatecart: "Questo articolo era già nel tuo carrello La quantità è stata aggiornata.",
    multipleprod: "Gli articoli sono stati aggiunti con successo al tuo carrello."
  },
  labels: {
    price: "Prezzo",
    total: "Totale",
    actions: {
      continueShopping: "Continua ad acquistare",
      goToCart: "Vai al carrello"
    }
  }
}

export const mtProductCartResource = {
  mtProductCart
}

