/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import {
  Command,
  CommandService,
  CommandStrategy,
  EventService,
  OCC_USER_ID_ANONYMOUS,
  UserIdService,
} from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { MtOccCheckoutPlaceOrderAdapter } from '../base/occ/adapters/mt-occ-checkout-place-order.adapter';

@Injectable()
export class MtCheckoutPlaceOrderService {

  constructor(
    protected activeCartFacade: ActiveCartFacade,
    protected userIdService: UserIdService,
    protected eventService: EventService,
    protected commandService: CommandService,
    protected mtOccCheckoutPlaceOrderAdapter: MtOccCheckoutPlaceOrderAdapter
  ) {}

  protected getPaymetricPerformCommand: Command<void> =
  this.commandService.create<any>(
    (payload) =>
      this.checkoutPreconditions().pipe(
        switchMap(([userId, cartId]) =>
          this.mtOccCheckoutPlaceOrderAdapter
            .getPaymetricPerform(userId, cartId)
        )
      ),
      {
        strategy: CommandStrategy.CancelPrevious,
      }
    );


  /**
   * Performs the necessary checkout preconditions.
   */
  protected checkoutPreconditions(): Observable<[string, string]> {
    return combineLatest([
      this.userIdService.takeUserId(),
      this.activeCartFacade.takeActiveCartId(),
      this.activeCartFacade.isGuestCart(),
    ]).pipe(
      take(1),
      map(([userId, cartId, isGuestCart]) => {
        if (
          !userId ||
          !cartId ||
          (userId === OCC_USER_ID_ANONYMOUS && !isGuestCart)
        ) {
          throw new Error('Checkout conditions not met');
        }
        return [userId, cartId];
      })
    );
  }

  getPaymetricPerform(): Observable<any> {
    return this.getPaymetricPerformCommand.execute().pipe(tap());
  }

}
