import { ModuleWithProviders, NgModule } from '@angular/core';
import { BaseSiteService, BASE_SITE_CONTEXT_ID, ContextServiceMap, CurrencyService, CURRENCY_CONTEXT_ID, LanguageService, LANGUAGE_CONTEXT_ID } from '@spartacus/core';
import { CountryContextService } from '../country-site-context/country-context.service';
import { CountrySiteContextModule } from '../country-site-context/country-site-context.module';
import { CurrencySiteContextModule } from '../currency-site-context/currency-site-context.module';
import { LanguageisoContextService } from '../languageiso-site-context/languageiso-context.service';
import { MtCountryChangeListener } from './mt-country-change.listener';


export const COUNTRY_CONTEXT_ID = "country";
export const LANGUAGEISO_CONEXT_ID = "languageIso";

export function mtServiceMapFactory() {
  return {
    [LANGUAGE_CONTEXT_ID]: LanguageService,
    [CURRENCY_CONTEXT_ID]: CurrencyService,
    [BASE_SITE_CONTEXT_ID]: BaseSiteService,
    [COUNTRY_CONTEXT_ID]: CountryContextService,
    [LANGUAGEISO_CONEXT_ID]: LanguageisoContextService
  };
}


@NgModule({
  imports: [
    CountrySiteContextModule.forRoot(),
    CurrencySiteContextModule
  ]
})
export class MtCommonSiteContextModule {
  static forRoot(): ModuleWithProviders<MtCommonSiteContextModule> {
    return {
      ngModule: MtCommonSiteContextModule,
      providers: [
        {
          provide: ContextServiceMap,
          useFactory: mtServiceMapFactory,
        }
      ],
    };
  }

  constructor(_mtCountryChangeListener: MtCountryChangeListener) { }
}
