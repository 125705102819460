import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {CmsConfig, CmsModule, provideConfig} from '@spartacus/core';
import { SupplementHashAnchorsModule } from '@spartacus/storefront';
import { MtParagraphComponent } from './mt-paragraph.component';



@NgModule({
  declarations: [
    MtParagraphComponent
  ],
  exports: [
    MtParagraphComponent
  ],
  imports: [
    CommonModule, RouterModule, SupplementHashAnchorsModule, CmsModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CMSParagraphComponent: {
          component: MtParagraphComponent,
        },/*
        CMSTabParagraphComponent: {
          component: ParagraphComponent,
        },*/
      },
    }),
  ],
})
export class MtParagraphModule { }
