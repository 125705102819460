
export const mtActiveFacets = {
  labels: {
    clearAllActiveFacets: 'Cancella tutto'
  }
}

export const mtFacets = {
  mtActiveFacets
}
