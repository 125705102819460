import {Inject, Injectable, OnDestroy, PLATFORM_ID} from "@angular/core";
import {
  AuthService,
  BasePageMetaResolver, CmsService,
  ContentPageMetaResolver,
  CurrencyService,
  LanguageService,
  Page, SiteContextUrlSerializer, WindowRef
} from "@spartacus/core";
import {combineLatest, Observable, of, Subscription} from "rxjs";
import {map, take, tap} from "rxjs/operators";
import { CountryContextService } from "../../core/country-site-context/country-context.service";
import { SwiftypePageMetaResolver } from "./mt-product-page-meta-resolver";
import {isPlatformBrowser} from "@angular/common";
@Injectable({
  providedIn: 'root'
})
export class MtContentPageMetaResolver extends ContentPageMetaResolver implements SwiftypePageMetaResolver, OnDestroy {

  /*
  If a content page implements its own logic of collecting page data and firing event,
  then add the page id int the array below to avoid double firing of the mt-page-data-event.
  */
  dontFireEventForPages: string[] = [
    'cartPage',
    'orderConfirmationPage',
    'shopHomepage'
  ];

  protected subscription: Subscription = new Subscription();
  constructor(protected override basePageMetaResolver: BasePageMetaResolver,
    protected countryContextService: CountryContextService,
    protected languageActive: LanguageService,
    protected currencyService: CurrencyService,
    protected authService: AuthService,
    protected urlSerializer: SiteContextUrlSerializer,
    protected cmsService : CmsService,
    @Inject(PLATFORM_ID) private platformId: any,

    protected winRef: WindowRef) {

    super(basePageMetaResolver);
    if (isPlatformBrowser(this.platformId) && this.winRef.nativeWindow) {
      this.winRef.nativeWindow.mt_page_data = {} as MtPageData;
    }

    this.resolveReportData();
  }

  resolveReportData(): Observable<string> {
    const reportData: Observable<string> = combineLatest([
      this.countryContextService.getActive(),
      this.languageActive.getActive(),
      this.currencyService.getActive(),
      this.authService.isUserLoggedIn(),
      this.cmsService.getCurrentPage(),
      this.resolveTitle()
    ]).pipe(
      take(1),
      map(([country, language, currency, isLoggedIn, page, title]) => {
        return { country, language, currency, isLoggedIn, page, title };
      }),
      map(data => JSON.stringify({
        country: data.country,
        language: data.language,
        currency: data.currency,
        isLoggedIn: data.isLoggedIn,
        page: data.page,
        title: data.title
      }))
    );

    if (isPlatformBrowser(this.platformId) && typeof MutationObserver !== 'undefined') {
      const observer = new MutationObserver(mutations => {
        const titleElement = document.querySelector('title');
        if (titleElement) {
          const title = titleElement.textContent;
          reportData.subscribe(jsonData => {
            const data = JSON.parse(jsonData);
            if (this.dontFireEventForPages.find((e) => e === data.page.pageId)) {
              return;
            }
            if (title === data.page.name) {
              this.dispatchMtPageDataEvent(data.language, data.country, data.currency, data.isLoggedIn, data.page);
            }
          });
        }
      });

      const headElement = document.querySelector('head') as HTMLHeadElement; // <-- Type assertion
      if (headElement) {
        observer.observe(headElement, {childList: true});
      }
    }
    return reportData;
  }

  private dispatchMtPageDataEvent(language: string, country: string, currency: string, isLoggedIn: boolean, page: Page) {
    if (this.winRef.nativeWindow) {
      const newData = {
        languageCode: language.split('_')[0],
        currentCountry: country.toLowerCase(),
        currency: currency,
        isLoggedin: isLoggedIn ? 'registered' : 'anonymous',
        pageName: page.pageId,
      };
      if (JSON.stringify(newData) !== JSON.stringify(this.winRef.nativeWindow.mt_page_data)) {
        this.winRef.nativeWindow.mt_page_data = newData;
        this.winRef.nativeWindow.dispatchEvent(new CustomEvent("mt-page-data-event",
          {
            bubbles: true,
            detail: {
              source: page.pageId,
              data: this.winRef.nativeWindow.mt_page_data
            }
          }
        ));
      }
    }
  }

  revolveCategory(): Observable<string> {
    return of("content")
  }

  revolveLocale(): Observable<string> {
    const locale: Observable<string> =
      combineLatest([
        this.countryContextService.getActive(),
        this.languageActive.getActive(),
        this.currencyService.getActive(),
        this.authService.isUserLoggedIn(),
      ]).pipe(
        tap(([country, language, currency, isLoggedIn]) => {
        }),
        map(([country, language, currency, isLoggedIn]) => {
          return `${language.split('_')[0]}-${country.toUpperCase()}`;
        })
      );
    return locale;
  }


  revolvePriority(): Observable<string> {
    return of("5")
  }

  revolveToppath(): Observable<string> {
    const fullUrl = this.winRef.location.href || "";
    return of(fullUrl);
  }

  override resolveTitle(): Observable<string | undefined> {
    return this.cmsService.getCurrentPage().pipe(
      map((page) => {
        if (page) {
          return page.title ? page.title : page.name;
        } else {
          return "mt.com"
        }
      })
    );

  }

  resolveMatNum(): Observable<string> {
    return of("");
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
