/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  I18nConfig,
  I18nModule, OccConfig, provideConfig, provideDefaultConfig,
  UrlModule,
} from '@spartacus/core';
import {IconModule, KeyboardFocusModule, PageSlotModule, SpinnerModule} from '@spartacus/storefront';
import {translations} from "@spartacus/assets";
import {mtUserAccountTranslationChunksConfig, mtUserAccountTranslations} from "../../assets/translations/translations";
import {
  deTranslationOverwrites,
  enTranslationOverwrites,
  frTranslationOverwrites,
  nlTranslationOverwrites,
  itTranslationOverwrites
} from "../../../../../spartacus-configuration.module";
import {MtDeactivateAccountModalComponent} from "./mt-deactivate-account-modal.component";

@NgModule({
  declarations: [MtDeactivateAccountModalComponent],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    I18nModule,
    IconModule,
    SpinnerModule,
    KeyboardFocusModule,],
  providers: [

    provideConfig(<OccConfig>{
      backend: {
        occ: {
          endpoints: {
            userCloseAccount: 'orgUsers/${userId}/deactivate',
          }
        }
      },
    }),

    provideConfig({
      i18n: { resources: translations },
    }),
    provideConfig( < I18nConfig > {
      i18n: {
        resources: mtUserAccountTranslations,
        chunks: mtUserAccountTranslationChunksConfig
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: {
          en: enTranslationOverwrites,
          de: deTranslationOverwrites,
          fr: frTranslationOverwrites,
          nl: nlTranslationOverwrites,
          it: itTranslationOverwrites
        },
      },
    }),

  ],
  exports: [MtDeactivateAccountModalComponent],
})
export class MtDeactivateAccountModalModule {}
