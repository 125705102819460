import { isPlatformServer, PlatformLocation } from "@angular/common";
import { InjectionToken } from "@angular/core";
import { SiteContextConfig, SiteContextParamsService, SiteContextUrlSerializer } from "@spartacus/core";
import { AemAdapterSiteContextService } from "./spartacus/custom-module/core/aem-adapter-site-context/aem-adapter-site-context.service";
import { LocalStorageService } from "./spartacus/custom-module/util/local-storage/local-storage.service";

export const PREFIX_APP_BASE_HREF: InjectionToken<string> = new InjectionToken("PREFIX_APP_BASE_HREF");

const COUNTRY_CONTEXT_PARAM = "country";
const LANGUAGEISO_CONTEXT_PARAM = "languageIso";

export const appBaseHrefFactory = (
  prefixAppBaseHref: String,
  siteContextConfig: SiteContextConfig,
  siteContextParamsService: SiteContextParamsService,
  platformLocation: PlatformLocation,
  urlSerializer: SiteContextUrlSerializer,
  aemAdapterSiteContextService: AemAdapterSiteContextService,
  localStorageService: LocalStorageService,
  platformId: any
) => {

  let appBaseHref = prefixAppBaseHref + "/";

  if (platformLocation.pathname?.match("/order-confirmation/.+")) {
    localStorageService.removeItem("spartacus⚿" + localStorageService.getItem("currentBaseSiteUid") + "⚿cart");
  }

  if (platformLocation.pathname?.startsWith(prefixAppBaseHref.toString())) {

    const urlContextParams =
      siteContextConfig.context?.urlParameters ?? [];

    const { url, params } = urlSerializer.urlExtractContextParameters((platformLocation.pathname?.split(prefixAppBaseHref.toString())[1] || '/') + platformLocation.search);

    console.log(`url = ${url}; params=${JSON.stringify(params)}`)

    // Verify if context parameter country/languageIso have allowed values
    // Check if country is defined in system
    let hasCorrectCountry: boolean = true;
    if (params[COUNTRY_CONTEXT_PARAM] === undefined || (
      siteContextConfig.context !== undefined &&
      siteContextConfig.context[COUNTRY_CONTEXT_PARAM] !== undefined &&
      siteContextConfig.context[COUNTRY_CONTEXT_PARAM].indexOf(params[COUNTRY_CONTEXT_PARAM]) < 0)) {
      hasCorrectCountry = false;
    } else {
      // If the country is correct, than check language ISO code
      params[LANGUAGEISO_CONTEXT_PARAM] =
        aemAdapterSiteContextService.checkLanguageIso(params[COUNTRY_CONTEXT_PARAM], params[LANGUAGEISO_CONTEXT_PARAM]);

      console.log(`params[LANGUAGEISO_CONTEXT_PARAM] = ${params[LANGUAGEISO_CONTEXT_PARAM]}`)
    }

    // If the country is correct than assign the correct context parameters
    if (hasCorrectCountry === true) {
      urlContextParams
        .forEach((param) => {
          const contextParam = params[param]
            ? params[param]
            : siteContextParamsService.getValue(param) ?? '';
          siteContextParamsService.getSiteContextService(param)?.setActive(contextParam);
        });
    }

    const urlContextPathString =
      urlContextParams
        .map((param) => params[param]
          ? params[param]
          : siteContextParamsService.getValue(param)).join('/');

    // If we have a country that is not configured in system, it will be redirected to AEM home page for that specific country (e.g. https://<host>/de/de/home.html)
    if (!hasCorrectCountry) {
      const arUrl = url.split("/");
      if (arUrl !== undefined && arUrl.length > 1) {
        const aemHomepageUrl = '/' + arUrl[0] + '/' + arUrl[1] + '/home.html';
        console.log(`AEM HomePage = ${aemHomepageUrl} - ${url} - ${params[COUNTRY_CONTEXT_PARAM]}`)
        return aemHomepageUrl;
      }
    }

    //in SSR we need the plain BASE_HREF in case of missing url context params
    if (isPlatformServer(platformId) && !platformLocation.pathname.includes(urlContextPathString)) {
      return prefixAppBaseHref;
    }

    appBaseHref += urlContextPathString;

  } else {

    // AEM context - we supposed the URL have the following format: .../<country>/<language>/... (e.g. .../us/en/...)

    // remove '/' from first position, if exists
    const contextParh =
      (platformLocation.pathname?.startsWith('/') ? platformLocation.pathname?.substring(1) : platformLocation.pathname);

    const urlComponents = contextParh.split('/');

    if (urlComponents !== undefined && urlComponents.length >= 2) {
      const country = urlComponents[0];
      const language = urlComponents[1];
      aemAdapterSiteContextService.setupShopSiteContextFromAEMContext(country, language);
    }
  }

  console.log(`[BFORE] appBaseHref = ${appBaseHref}`)

  // add context parameters when was not found in URL.
  if (appBaseHref === (prefixAppBaseHref + "/")) {
    const currentCountry = localStorageService.getItem('currentCountry') ?? 'us';
    const currentLanguageIso = localStorageService.getItem('currentLanguageIso') ?? 'en';
    appBaseHref += `${currentCountry}/${currentLanguageIso}/`;
  }

  console.log(`[AFTER] appBaseHref = ${appBaseHref}`)

  return appBaseHref;
}
