import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Cart } from "@spartacus/cart/base/root";
import { Converter } from "@spartacus/core";
import { MtOrderSimulationError } from "../../../../model/mt-order-simulation-error.model";
import { MtOrderSimulationResponseError } from "../../../../model/mt-order-simulation-response-error.model";

@Injectable({
  providedIn: 'root',
})
export class MtOrderSimulationConverter implements Converter<any, MtOrderSimulationResponseError | Cart> {
  constructor(private http: HttpClient) { }

  convert(source: any): MtOrderSimulationResponseError | Cart {
    //console.log("order simulation response");
    //console.log(JSON.stringify(source));

    if (source && source.errors && Array.isArray(source.errors)) {
      const mtOrderSimulationResponse: MtOrderSimulationResponseError = {
        errors: []
      };
      source.errors.forEach((error: any) => {
        const convertedError: MtOrderSimulationError = {
          message: error.message,
          type: error.type,
        };
        mtOrderSimulationResponse.errors.push(convertedError);
      });

      return mtOrderSimulationResponse;
    }
    else {
      return source as Cart;
    }
  }

}
