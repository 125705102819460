import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConverterService, OccEndpointsService } from "@spartacus/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class MtCatalogsAdapter  {

  private buildUrl(categoryCodes: string): string {
    const getEndpointUrl =
      this.occEndpoints.buildUrl('catalogs/categoryNames/', {
        queryParams: {
          codes: categoryCodes
        }
      });
    return getEndpointUrl;
  }

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) {
  }

  getCategoryNames(categoryCodes: string): Observable<Object> {
    const categoryNames$: Observable<string> =
	  this.http.get(this.buildUrl(categoryCodes)).pipe(
        catchError((response: HttpErrorResponse) => {
          console.error(`Category names cannot be obtained: ${JSON.stringify(response)}`)
          return of();
        }),
        map((result: any) => {
          return result;
        }),
      )

    return categoryNames$;
  }

}

