<ng-container *ngIf="state$ | async as state">

  <div class="mt-facet" *ngIf="(facet.values?.length ?? 0) > 0">

    <button class="heading" (click)="toggleGroup($event)" [attr.aria-expanded]="isExpanded" [attr.aria-label]="
      'productFacetNavigation.filterBy.name' | cxTranslate: { name: facet.name }
    ">
      {{ facet.name }}
      <cx-icon *ngIf="isExpanded" class="collapse-icon" [type]="collapseIcon"></cx-icon>
      <cx-icon *ngIf="!isExpanded" class="expand-icon" [type]="expandIcon"></cx-icon>
    </button>

    <div [hidden]="!isExpanded">
      <a *ngFor="let value of filterEmptyValues(facet.values) | slice: 0:state.topVisible" #facetValue (click)="onSelectFacet(value)"
        class="value" [class.selected]="value.selected" [cxFocus]="{ key: value.name }"
        [attr.role]="facet.multiSelect ? 'checkbox' : null"
        [attr.aria-checked]="facet.multiSelect ? value.selected : null" attr.aria-label="{{
        'productFacetNavigation.ariaLabelItemsAvailable'
          | cxTranslate: { name: value.name, count: value.count }
      }}">
      <div class="checkmark-container" for="filterCheckbox">
        <input [hidden]="!facet.multiSelect " role="checkbox" type="checkbox"
          [checked]="value.selected ? 'checked' : ''" value="{{value.selected}}" id="filterCheckbox" name="filterCheckbox"/>
          <span class ="checkmark"></span>
        <span aria-hidden="true">{{ value.name
          }}<span aria-hidden="true" class="count">{{ value.count }}</span>
         </span>
        </div>
      </a>

      <div class="more">
        <a *ngFor="
          let value of filterEmptyValues(facet.values)
            | slice: state.topVisible ?? 0:state.maxVisible
        " #facetValue (click)="onSelectFacet(value)" class="value" [class.selected]="value.selected"
          [cxFocus]="{ key: value.name }">
          <div class="checkmark-container" for="filterBy">
          <input [hidden]="!facet.multiSelect " role="checkbox" type="checkbox"
            [checked]="value.selected ? 'checked' : ''" value="{{value.selected}}" id="filterBy" name="filterBy"/>
            <span class ="checkmark"></span>
          <span>{{ value.name }}<span class="count">{{ value.count }}</span></span>
        </div>
        </a>

        <button *ngIf="
          state.maxVisible &&
          state.topVisible &&
          state.maxVisible > state.topVisible
        " (click)="decreaseVisibleValues()" [cxAtMessage]="
          'productFacetNavigation.decreaseOptionsVisibility' | cxTranslate
        " aria-expanded="true" attr.aria-label="{{
          'productFacetNavigation.ariaLabelShowLess' | cxTranslate
        }}" class="cx-action-link" [cxFocus]="{ key: 'moreorless' }">
          {{ 'productList.showLess' | cxTranslate }}
        </button>

        <button *ngIf="
          state.maxVisible &&
          facet.values &&
          state.maxVisible > 0 &&
          state.maxVisible < facet.values.length
        " (click)="increaseVisibleValues()" [cxAtMessage]="
          'productFacetNavigation.increaseOptionsVisibility' | cxTranslate
        " aria-expanded="false" attr.aria-label="{{
          'productFacetNavigation.ariaLabelShowMore' | cxTranslate
        }}" class="cx-action-link" [cxFocus]="{ key: 'moreorless' }">
          {{ 'productList.showMore' | cxTranslate }}
        </button>
      </div>
    </div>

  </div>

</ng-container>
