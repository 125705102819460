/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {GlobalMessageType, HttpErrorHandler, HttpResponseStatus, Priority, Translatable} from "@spartacus/core";

@Injectable({
  providedIn: 'root',
})
export class MtUnauthorizedRequestHandler extends HttpErrorHandler {

  override responseStatus = HttpResponseStatus.UNAUTHORIZED;

  handleError(request: HttpRequest<any>, response: HttpErrorResponse): void {
    if (response.error?.error === 'unauthorized') {

      if (response.error.error_description === 'NO_SOLD_TO') {
        this.globalMessageService.add(
          {key: 'mtUserRegistrationForm.httpHandlers.errorMessage'},
          GlobalMessageType.MSG_TYPE_ERROR
        );
      } else if (response.error.error_description === 'LOGIN_DISABLE') {
        this.globalMessageService.add(
          { key: 'mtUserRegistrationForm.httpHandlers.errorMessage' },
          GlobalMessageType.MSG_TYPE_ERROR
        );
      } else if (response.error.error_description === 'BAD_BASE_SITE') {
        this.globalMessageService.add(
          { key: 'mtUserRegistrationForm.httpHandlers.wrongCountry' },
          GlobalMessageType.MSG_TYPE_ERROR
        );
      } else if (response.error.error_description === 'Bad credentials' || response.error.error_description === 'NO_SUCH_EMAIL') {
        this.globalMessageService.add(
          { key: 'mtUserRegistrationForm.httpHandlers.invalidUsernameOrPassword' },
          GlobalMessageType.MSG_TYPE_ERROR
        );
      } else {
        this.globalMessageService.add(
          { key: 'mtUserRegistrationForm.httpHandlers.unauthorized' },
          GlobalMessageType.MSG_TYPE_ERROR
        );
      }
    }
  }

  getPriority(): Priority {
    return Priority.HIGH;
  }

}
