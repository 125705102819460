import {provideDefaultConfigFactory} from "@spartacus/core";
import {NgModule} from "@angular/core";

export const MT_USER_REGISTRATION = 'mtUserRegistration';

export function mtUserRegistrationComponentsConfig() {
  const config = {
    featureModules: {
      [MT_USER_REGISTRATION]: {
        cmsComponents: [
          'OrganizationUserRegistrationComponent',
          'OrganizationRegistrationComponent',
          'OrganizationRegistrationConfirmationComponent',
        ]
      },
    },
  };
  return config;
}

@NgModule({
  providers: [
    provideDefaultConfigFactory(mtUserRegistrationComponentsConfig),
  ],
})
export class MtUserRegistrationRootModule {}
