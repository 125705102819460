export const pageNotFoundTranslations = {
    labels: {
        backToShop: "Torna alla home page del negozio online",
        contactUs: 'Contattaci',
        pageNotFound: 'Pagina non trovata',
    }
}

export const mtPageNotFoundTranslations = {
    pageNotFoundTranslations
}