import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AuthRedirectService, RoutingService } from "@spartacus/core";
import { FocusConfig, ICON_TYPE, LaunchDialogService } from "@spartacus/storefront";
import { Subscription } from "rxjs";
import { MtAuthService } from "../../../../mt-auth-service";

@Component({
  selector: 'app-session-logout-dialog',
  templateUrl: './session-logout-dialog.component.html',
  styleUrls: ['./session-logout-dialog.component.scss']
})
export class SessionLogoutDialogComponent implements OnInit, OnDestroy {

  iconTypes = ICON_TYPE;

  clicked = false;

  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: 'button',
    focusOnEscape: true,
  };

  protected subscription = new Subscription();

  rememberMe = false;
  logoutCountryChanged: boolean = false;


  @HostListener('click', ['$event'])
  handleClick(event: UIEvent): void {
    if ((event.target as any).tagName === this.el.nativeElement.tagName) {
      this.dismissModal('Cross click');
    }
  }

  constructor(protected launchDialogService: LaunchDialogService,
    protected el: ElementRef,
    protected authService: MtAuthService,
    protected authRedirectService: AuthRedirectService,
    protected routingService: RoutingService,
  ) {
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  dismissModal(reason?: any): void {
    this.launchDialogService.closeDialog(reason);
  }

  ngOnInit(): void {
    this.subscription.add(
      this.launchDialogService.data$.subscribe(
        (dialogData: { rememberMe: boolean, logoutCountryChanged?: boolean }) => {
          this.rememberMe = dialogData.rememberMe;
          this.logoutCountryChanged = dialogData.logoutCountryChanged ?? false;
        }
      )
    )
  }

  dismissModalAndRefresh(reason: string) {
    this.launchDialogService.closeDialog({ doRefresh: true });
  }
}
