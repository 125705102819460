import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MtZoovuListener } from './mt-zoovu.listener';
import { OutletModule, PageComponentModule } from '@spartacus/storefront';
import { MtBannerComponentModule } from '../../../../homepage/components/mt-banner-component/mt-banner-component.module';
import { CmsConfig, provideConfig } from '@spartacus/core';
import {MtBannerComponent} from "../../../../homepage/components/mt-banner-component/mt-banner.component";

@NgModule({
  imports: [
    CommonModule,
    PageComponentModule,
    OutletModule,
    MtBannerComponentModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ZoovuBannerComponent: {
          component: MtBannerComponent,
        },
      },
    }),
  ],
})
export class MtZoovuModule {
  constructor(_: MtZoovuListener) {}
}
