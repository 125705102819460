/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { NgModule } from '@angular/core';

import { MtCartBaseComponentsModule } from "../components/mt-cart-base-components.module";
import { MtCartBaseOccModule } from "./occ/mt-cart-base-occ.module";
import {MtActiveCartService} from "./core/facade/mt-active-cart.service";
import {ActiveCartFacade} from "@spartacus/cart/base/root";
import {CartBaseModule} from "@spartacus/cart/base";

@NgModule({
  imports: [CartBaseModule, MtCartBaseOccModule, MtCartBaseComponentsModule],

  providers: [
    {provide: ActiveCartFacade, useClass: MtActiveCartService},
  ]
})
export class MtCartBaseModule {
}
