import { MtNavigationNode } from "../model/mt-navigation-node.model";

export const NAVIGATION_FEATURE = "navigation";

export interface StateWithNavigation {
  [NAVIGATION_FEATURE]: NavigationState
}

export interface NavigationState {
  homepageState: HomepageNavigationState
}

export interface HomepageNavigationState {
  rootNode?: MtNavigationNode;
  selectedNode?: MtNavigationNode;
  nodes?: Map<string, MtNavigationNode>;
}
