import { Component } from '@angular/core';
import { CheckoutOrderSummaryComponent } from '@spartacus/checkout/base/components';

@Component({
  selector: 'mt-checkout-order-summary',
  templateUrl: './mt-checkout-order-summary.component.html',
  styleUrls: ['./mt-checkout-order-summary.component.scss']
})
export class MtCheckoutOrderSummaryComponent extends CheckoutOrderSummaryComponent {


  ngOnInit(): void {
  }

}
