export const pageNotFoundTranslations = {
    labels: {
        backToShop: 'Back to Shop Homepage',
        contactUs: 'Contact Us',
        pageNotFound: 'Page not found',
    }
}

export const mtPageNotFoundTranslations = {
    pageNotFoundTranslations
}