import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { CartUiEventAddToCart } from "@spartacus/cart/base/root";
import { EventService, WindowRef } from "@spartacus/core";
import { CartUiEventAddToCartMultipleProductsEvent } from "../../../product-add-to-cart/events/mt-cart-ui-event-add-to-cart-multiple-products.event";


@Injectable({
  providedIn: "root"
})
export class MtZoovuListener implements OnInit, OnDestroy {

  protected createCartUiEventAddToCart(productCode: string, numberOfEntriesToAdd: number, numberOfEntriesBeforeAdd: number = -1) {
    const newEvent = new CartUiEventAddToCart();
    newEvent.productCode = productCode;
    newEvent.quantity = numberOfEntriesToAdd;
    newEvent.numberOfEntriesBeforeAdd = numberOfEntriesBeforeAdd;
    return newEvent;
  }

  protected createCartUiEventAddToCartMultipleProducts(products: [{ productId: string, quantity: number }]) {
    const newEvent = new CartUiEventAddToCartMultipleProductsEvent();
    newEvent.products = products;
    return newEvent;
  }

  constructor(
    private winRef: WindowRef,
    private eventService: EventService
  ) {
    console.log(`MtZoovuListener - load`);
    this.winRef.nativeWindow?.addEventListener('AddToCartType', (event: any) => {
      console.log(`Zoovu -> AddToCartType: ${JSON.stringify(event.detail)}`);

      let cartUiEvent: CartUiEventAddToCart | CartUiEventAddToCartMultipleProductsEvent;
      const data = event.detail;
      if (Array.isArray(data)) {
        cartUiEvent = this.createCartUiEventAddToCartMultipleProducts(data as [{ productId: string, quantity: number }]);
      } else {
        cartUiEvent = this.createCartUiEventAddToCart(data.productId, data.quantity, -1);
      }

      this.eventService.dispatch(cartUiEvent);
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

}
