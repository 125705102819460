/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const mtUserAccountAndLogin = {

  loginForm: {

    labels: {
      loginTitle: 'Login',
      signIn: 'S\'enregistrer',
      register: 'Enregistrer',
      lookingCustomerPortal: 'Vous recherchez une connexion au portail clients?',
      clickHereLink: 'Cliquez ici',
      forgotPassword: 'Réinitialiser le mot de passe',
      keepMeSignedIn: 'Gardez moi connecté',
    },

    fields: {
      emailAddress: {
        label: 'E-mail',
        placeholder: 'Champ obligatoire',
      },
      password: {
        label: 'Mot de passe',
        placeholder: 'Champ obligatoire',
      },
    },

    messages: {
      youCanLoginAccountActivated: "Your account has been activated. Now you can log in.",
      youCanLoginAccountReactivated: "Your account has been re-activated. You can now login"
    }

  },

  login: {
    labels: {
      myMTComLoginLink: 'myMT.com login',
      myMTComLoginDescription: 'Gérez votre profil en ligne et personnalisez votre expérience mt.com',
      customerPortalLoginLink: 'Portail Clients Login',
      customerPortalLoginDescription: 'Votre plateforme transactionnelle avec un portefeuille clients, un accès aux offres et vos appareils installés',

      myAccountLink: 'Mon Compte',
      logoutLink: 'Se déconnecter',
      customerPortalLink: 'Portail Clients'
    }
  },

  logout: {
    sessionDialog: {
      title: 'Déconnexion de la session',
      message: 'Votre session a expiré.',
      logoutCountryChangedMessage: 'Vous êtes déconnecté car le pays a été changé.',
      button: 'D\'ACCORD'
    }
  },

  forgottenPassword: {
    resetPassword: 'Réinitialiser le mot de passe',
    emailAddress: {
      label: 'Adresse Email',
      placeholder: 'Adresse Email',
    },
    chooseNewPassword: {
      label: 'Choisissez un mot de passe',
      placeholder: '',
    },
    confirmNewPassword: {
      label: 'Répéter le mot de passe',
      placeholder: '',
    },
    actions: {
      submit: 'Réinitialiser le mot de passe',
      cancel: 'Retour à la page de connexion',
      resendEmail: 'Ré-envoyer l\'email',
      saveNewPassword: 'Sauvegarder'
    },
    passwordResetEmailSent:
      'Merci pour votre requête! Un e-mail pour la réinitialisation du mot de passe a été envoyé à {{email}}. Le lien dans l\'e-mail est valable 30 minutes. Vous n\'avez pas reçu d\'e-mail? Veuillez vérifier le dossier spam.',

    passwordResetSuccess: 'Votre mot de passe a été réinitialisé. Veuillez vous connecter pour accéder à votre compte.',
  },

  updatePasswordForm: {
    title: 'Changer le mot de passe',
    oldPassword: {
      label: 'Mot de Passe Actuel',
      placeholder: 'Champ obligatoire',
    },
    newPassword: {
      label: 'Nouveau Mot De Passe',
      placeholder: 'Champ obligatoire',
    },
    confirmPassword: {
      label: 'Confirmez Le Nouveau Mot De Passe',
      placeholder: 'Champ obligatoire',
    },
    actions: {
      save: "Sauvegarder"
    },
    passwordUpdateSuccess: 'Votre mot de passe a été changé',
  },

  updateProfileForm: {
    personalData: 'Information profil',
    none: '',
    title: 'Titre*',
    firstName: {
      label: 'Prénom*',
      placeholder: 'Champ obligatoire',
    },
    lastName: {
      label: 'Nom*',
      placeholder: 'Champ obligatoire',
    },
    customerId: 'E-Mail*',
    actions: {
      save: "Sauvegarder",
    },
  },

  deactivateAccount: {
    label: 'Désactiver le compte',
    buttonLabel: 'Désactiver le compte',
    popup: 'Êtes-vous sûr de vouloir désactiver votre compte ? En continuant, vous n\'aurez plus de compte mt.com actif. Votre compte de portail client partagé sera également désactivé.',
    cancel: 'Annuler',
  },

  reactivateAccount: {
    infoBox1: 'Votre compte est inactif. Veuillez ',
    infoBox2: 'cliquer ici',
    infoBox3: ' pour réactiver votre compte.',
    title: 'Réactiver le compte',
    email: {
      label: 'E-Mail',
    },
    submit: 'Soumettre',
    backToLogin: 'Retourner à Login',
    successMessage: 'Nous vous avons envoyé un e-mail avec un lien pour activer votre compte. Vous ne pourrez pas vous connecter tant que vous n\'aurez pas activé votre compte.',
  },

  soldtoSelection: {
    selectAccount: 'Les comptes de votre entreprise',
    mtNumber: 'MT Numéro de compte:',
    accountSelected:'Compte d’entreprise sélectionné',
    otherAccounts:'Autres comptes d’entreprise disponibles',
    pickAccount:'Choisissez un autre compte d’entreprise pour votre session',
    noAccounts:'Aucun autre compte d’entreprise n’est disponible',
  },

  myAccount: {
    tiles: {
      myAccount: 'Mon Compte',
      myProfile: 'Mon Profil',
      newsFeed: 'Flux Personnalisé',
      personalLibrary: 'Bibliothèque personelle',
      billingAddresses: 'Adresse de facturation',
      shippingAddresses: 'Adresse de livraison',
      myOrders: 'Mes commandes',
      newsletterSubscriptions: 'Abonnement à la Newsletter',
      productRegister: 'Enregistrement Produit',
    },

    news: {
      viewMore: 'Voir tout'
    }
  },

  ordersRedirect: {
    confirmation: 'Confirmation',
    dialogInfo: 'Vous serez redirigé vers le portail clients de METTLER TOLEDO.',
    redirect: 'OK',
  },

};

export const mtUserAccount = {
  mtUserAccountAndLogin,
};
