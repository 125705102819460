import { Injectable } from "@angular/core";
import { ActiveCartFacade } from "@spartacus/cart/base/root";
import { CheckoutConnector, CheckoutDeliveryAddressConnector, CheckoutDeliveryAddressService } from "@spartacus/checkout/base/core";
import { CheckoutDeliveryAddressCreatedEvent, CheckoutQueryFacade } from "@spartacus/checkout/base/root";
import { Address, Command, CommandService, CommandStrategy, EventService, QueryState, UserIdService } from "@spartacus/core";
import { Observable } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";


@Injectable()
export class MtCheckoutDeliveryAddressService
  extends CheckoutDeliveryAddressService {

  protected mtCreateDeliveryAddressCommand: Command<Address, unknown> =
    this.commandService.create<Address>(
      (payload) =>
        this.checkoutPreconditions().pipe(
          switchMap(([userId, cartId]) => {

            console.log(`MtCheckoutDeliveryAddressService - [userId, cartId] = ${[userId, cartId]}`);

            return this.checkoutDeliveryAddressConnector
              .createAddress(userId, cartId, payload)
              .pipe(
                map((address) => {

                  //address.titleCode = payload.titleCode;

                  /*
                  if (payload.region?.isocodeShort) {
                    address.region = {
                      ...address.region,
                      isocodeShort: payload.region.isocodeShort,
                    };
                  }
                  return address;
                  */
                  return payload;
                }),
                tap((address) =>
                  /*
                  this.eventService.dispatch(
                    { userId, cartId, address },
                    CheckoutQueryReloadEvent
                  )*/
                  this.eventService.dispatch(
                    { userId, cartId, address },
                    CheckoutDeliveryAddressCreatedEvent
                  )
                )
              );
          })
        ),
      {
        strategy: CommandStrategy.CancelPrevious,
      }
    );

  constructor(
    protected override activeCartFacade: ActiveCartFacade,
    protected override userIdService: UserIdService,
    protected override eventService: EventService,
    protected override commandService: CommandService,
    protected override checkoutDeliveryAddressConnector: CheckoutDeliveryAddressConnector,
    protected override checkoutQueryFacade: CheckoutQueryFacade,

    protected checkoutConnector: CheckoutConnector
  ) {
    super(activeCartFacade, userIdService, eventService, commandService, checkoutDeliveryAddressConnector, checkoutQueryFacade);
  }

  override createAndSetAddress(address: Address): Observable<unknown> {
    return this.mtCreateDeliveryAddressCommand.execute(address);
  }

  override getDeliveryAddressState(): Observable<QueryState<Address | undefined>> {

    return this.checkoutQueryFacade.getCheckoutDetailsState().pipe(
      tap((state) => {
        //console.log(`MtCheckoutDeliveryAddressService - state = ${JSON.stringify(state)}`)
        //console.log(`state.data?.deliveryAddress = ${JSON.stringify(state.data)}`)
      }),
      map((state) => ({
        ...state,
        data: state.data?.deliveryAddress,
      }))
    );

  }

  

}
