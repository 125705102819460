/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import {CartDetailsComponent} from "@spartacus/cart/base/components";
import { CartConfigService } from '@spartacus/cart/base/core';
import { ActiveCartFacade, SelectiveCartFacade } from '@spartacus/cart/base/root';
import { AuthService, RoutingService, WindowRef } from '@spartacus/core';
import { Subject, combineLatest } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { MtPageDataService } from '../../../seo/services/mt-page-data.service';

@Component({
  selector: 'mt-cart-details',
  templateUrl: './mt-cart-details.component.html',
  styleUrls: ['./mt-cart-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MtCartDetailsComponent extends CartDetailsComponent implements OnDestroy {

  CART_PAGE_ID: string = "cartPage";

  notifier = new Subject();
  
  constructor (
          protected override activeCartService: ActiveCartFacade,
          protected override selectiveCartService: SelectiveCartFacade,
          protected override authService: AuthService,
          protected override routingService: RoutingService,
          protected override cartConfig: CartConfigService,
          protected winRef: WindowRef,
          protected mtPageDataService: MtPageDataService) {
      super(activeCartService,selectiveCartService, authService, routingService, cartConfig);
    }

  override ngOnInit(): void {
    super.ngOnInit();
    combineLatest([this.cart$, this.mtPageDataService.getMtPageData()])
    .pipe(
      map(([cart, pageData]) => {
        const mtPageData: MtPageData = {... pageData};
        mtPageData.pageName = this.CART_PAGE_ID;
        mtPageData.products = [];
        cart.entries?.forEach((item) => {
          mtPageData.products?.push({
            id: item.product?.code,
            price: item.basePrice?.value,
            name: item.product?.name,
            quantity: item.quantity
          })
        });
        return mtPageData;
      }),
      takeUntil(this.notifier)).pipe(take(1)).subscribe((mtPageData) => {
      this.mtPageDataService.fireMtDataEvent(this.CART_PAGE_ID, mtPageData);
    });
  }
  
  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }
}
