import {AuthConfigService} from "@spartacus/core";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class MtAuthConfigService extends AuthConfigService {

  public getSessionLogoutInSec() : number {
    return this.authConfig?.sessionLogoutInSec || 180;
  }


}
