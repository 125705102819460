/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import {AbstractControl, FormControl, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {
  ActiveCartFacade,
  CartItemComponentOptions,
  ConsignmentEntry,
  MultiCartFacade,
  OrderEntry,
  PromotionLocation,
  SelectiveCartFacade,
} from '@spartacus/cart/base/root';
import { UserIdService } from '@spartacus/core';
import { OutletContextData } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import {CartItemListComponent} from "@spartacus/cart/base/components";

// interface ItemListContext {
//   readonly?: boolean;
//   hasHeader?: boolean;
//   options?: CartItemComponentOptions;
//   cartId?: string;
//   items?: OrderEntry[];
//   promotionLocation?: PromotionLocation;
//   cartIsLoading?: boolean;
// }

@Component({
  selector: 'mt-cart-item-list',
  templateUrl: './mt-cart-item-list.component.html',
  styleUrls: ['./mt-cart-item-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MtCartItemListComponent extends CartItemListComponent {

  convertToFormControl(absCtrl: AbstractControl | null): FormControl {
    const ctrl = absCtrl as FormControl;
    return ctrl;
  }

}
