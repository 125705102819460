import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CartEventModule } from '@spartacus/cart/base/core';
import { SpinnerModule } from '@spartacus/storefront';
import { QuantityPickerShellComponent } from './components/quantity-picker-shell/quantity-picker-shell.component';
import { QuantityPickerComponent } from './components/quantity-picker/quantity-picker.component';



@NgModule({
  declarations: [
    QuantityPickerComponent,
    QuantityPickerShellComponent
  ],
  imports: [
    CommonModule,
    CartEventModule,
    FormsModule,
    SpinnerModule
  ],
  exports: [
    QuantityPickerComponent,
    QuantityPickerShellComponent
  ]
})
export class ProductQuantityPickerModule { }
