<a #topAnchor></a>
<ng-container *ngIf="
    (supportedDeliveryModes$ | async)?.length && !(isUpdating$ | async);
    else loading
  ">

  <div role="status" [attr.aria-label]="'common.loaded' | cxTranslate"></div>
  <div class="checkout-delivery-method-container" [formGroup]="mode">

    <h2 class="cx-checkout-title">
      {{ 'checkoutMode.deliveryMethod.title' | cxTranslate }}
    </h2>
    <div class="select-delivery-option-container">
      <div class="form_field_wrapper">
        <label>
          <span class="label-content required">{{ 'checkoutMode.deliveryMethod.controls.label' | cxTranslate }}</span>
          <ng-select formControlName="deliveryModeId" [searchable]="false" [clearable]="false"
            [items]="supportedDeliveryModes$ | async" bindLabel="name" bindValue="code"
            placeholder="{{'checkoutMode.deliveryMethod.title' | cxTranslate}}" [cxNgSelectA11y]="{
                  ariaLabel: 'checkoutMode.deliveryMethod.title' | cxTranslate
                }">
          </ng-select>
          <cx-form-errors [control]="mode.get('deliveryModeId')"> </cx-form-errors>
        </label>
      </div>
    </div>

    <h2 class="cx-checkout-title">
      {{ 'checkoutMode.requestDeliveryDate.title' | cxTranslate }}
    </h2>
    <div class="radio-selection-container">
      <div>
        <label>
          <input class="form-control" formControlName="deliverAsFastAsPossible" type="radio" value="true" />
          <span class="label-content">
            {{ "checkoutMode.requestDeliveryDate.controls.optionDeliveryAsFastAsPosible" | cxTranslate }}
          </span>
        </label>
      </div>
    </div>
    <div class="radio-selection-container">
      <div>
        <label>
          <input class="form-control" formControlName="deliverAsFastAsPossible" type="radio" value="false" />
          <span class="label-content">
            {{ "checkoutMode.requestDeliveryDate.controls.optionDeliveryOnSpecificDate" | cxTranslate }}
          </span>
        </label>
      </div>
    </div>
    <div class="radio-selection-container select-delivery" [class.is-hidden]="!showDeliveryDate">
      <div>
        <label>
          <span class="label-content">
            {{ "checkoutMode.requestDeliveryDate.controls.selectDate" | cxTranslate }}
          </span>
          <checkout-date-picker [control]="$any(mode.get('deliveryDate'))" [required]="false"
            [min]="minDeliveryDate"></checkout-date-picker>
        </label>
      </div>
    </div>


    <h2>
      {{ 'checkoutMode.consolidatedDelivery.title' | cxTranslate }}
    </h2>
    <div class="radio-selection-container">
      <div>
        <label>
          <input class="form-control" formControlName="deliveryConsolidated" type="radio" value="true" />
          <span class="label-content">
            {{ "checkoutMode.consolidatedDelivery.controls.optionYes" | cxTranslate }}
          </span>
        </label>
      </div>
    </div>
    <div class="radio-selection-container" [class.is-hidden]="!showconsolidatedDeliveryInfoText">
      <div>
        <label>
          <span class="label-content">
            {{ "checkoutMode.consolidatedDelivery.controls.infoText" | cxTranslate }}
          </span>
        </label>
      </div>
    </div>
    <div class="radio-selection-container">
      <div>
        <label>
          <input class="form-control" formControlName="deliveryConsolidated" type="radio" value="false" />
          <span class="label-content">
            {{ "checkoutMode.consolidatedDelivery.controls.optionNo" | cxTranslate }}
          </span>
        </label>
      </div>
    </div>
    <br />


    <ng-container *ngIf="showDeliveryPayment$ | async">
      <h2>
        {{ 'checkoutMode.deliveryPayment.title' | cxTranslate }}
      </h2>
      <div class="radio-selection-container">
        <div>
          <label>
            <input class="form-control" formControlName="deliveryPaymentId" type="radio" value="prepaid" />
            <span class="label-content">
              {{ "checkoutMode.deliveryPayment.controls.optionPrepaid" | cxTranslate }}
            </span>
          </label>
        </div>
      </div>
      <div class="radio-selection-container">
        <div>
          <label>
            <input class="form-control" formControlName="deliveryPaymentId" type="radio" value="collect" />
            <span class="label-content">
              {{ "checkoutMode.deliveryPayment.controls.optionCollect" | cxTranslate }}
            </span>
          </label>
        </div>
      </div>
      <div class="radio-selection-container collect-number-container" [class.is-hidden]="!showCollectNumber">
        <div>
          <label>
            <span class="label-content">
              {{ "checkoutMode.deliveryPayment.controls.collectNumber" | cxTranslate }}
            </span>
            <input type="text" formControlName="collectNumber" />
            <cx-form-errors [control]="mode.get('collectNumber')" prefix="checkoutMode.deliveryPayment.formErrors">
            </cx-form-errors>
          </label>
        </div>
      </div>
    </ng-container>
    <div class="order-summary-placeholder-mobile"></div>
    <div class="cx-checkout-btns">
      <button class="btn-action backBtn" (click)="back()">
        {{ "checkoutMode.button.back" | cxTranslate }}
      </button>
      <a (click)="scrollToTop()">
        <button class="btn-primary continueBtn" [disabled]="deliveryModeInvalid" (click)="saveDeliveryMethod()">
          {{ "checkoutMode.button.continue" | cxTranslate }}
        </button>
      </a>
    </div>
  </div>

</ng-container>

<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
