<cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>

<form (ngSubmit)="onSubmit()" [formGroup]="form" class="mt-reactivate-account">
  <h1 class="text--blue-color mt-reactivate-account__field">
    {{ 'mtUserAccountAndLogin.reactivateAccount.title' | cxTranslate }}
  </h1>

  <br/>

  <div class="form_field_wrapper mt-reactivate-account__field">
    <label>
      <span class="label-content">
        {{ 'mtUserAccountAndLogin.reactivateAccount.email.label' | cxTranslate }}
        </span>
      <input required="true" class="form-control" type="email"
        placeholder="{{ 'mtUserAccountAndLogin.forgottenPassword.emailAddress.placeholder' | cxTranslate }}"
        [attr.aria-label]="'mtUserAccountAndLogin.forgottenPassword.emailAddress.placeholder' | cxTranslate"
        formControlName="userId" />
      <cx-form-errors [control]="form.get('userId')"></cx-form-errors>
    </label>
  </div>

  <div class="form_btn-action-wrapper">
    <button class="btn btn-block btn-primary mt-reactivate-account__button" type="submit" [disabled]="form.disabled">
      {{ 'mtUserAccountAndLogin.reactivateAccount.submit' | cxTranslate }}
    </button>
  </div>

  <div class="form_btn-action-wrapper">
    <button class="btn btn-block btn-secondary mt-reactivate-account__button" routerLink="/login" >
      <span>
        {{ 'mtUserAccountAndLogin.reactivateAccount.backToLogin' | cxTranslate }}
      </span>
    </button>
  </div>

</form>
