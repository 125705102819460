import { NgModule } from '@angular/core';
import { AuthModule, ExternalRoutesModule, ProductModule, ProductOccModule, UserModule, UserOccModule } from "@spartacus/core";
import {
  BannerCarouselModule,
  BannerModule,
  CmsParagraphModule,
  HomePageEventModule,
  LinkModule,
  LoginRouteModule,
  NavigationEventModule,
  PageTitleModule,
  PaymentMethodsModule,
  ProductCarouselModule,
  ProductDetailsPageModule,
  ProductFacetNavigationModule,
  ProductImagesModule,
  ProductIntroModule,
  ProductListingPageModule,
  ProductListModule,
  ProductPageEventModule,
  ProductReferencesModule,
  ProductSummaryModule,
  ProductTabsModule,
  ScrollToTopModule,
  SiteContextSelectorModule,
  TabParagraphContainerModule
} from "@spartacus/storefront";
import { CartBaseFeatureModule } from './features/cart/cart-base-feature.module';
import { CartSavedCartFeatureModule } from './features/cart/cart-saved-cart-feature.module';
import { CheckoutFeatureModule } from './features/checkout/checkout-feature.module';
import { OrderFeatureModule } from './features/order/order-feature.module';
import {
  OrganizationAccountSummaryFeatureModule
} from './features/organization/organization-account-summary-feature.module';
import {
  OrganizationUserRegistrationFeatureModule
} from './features/organization/organization-user-registration-feature.module';
import { ProductImageZoomFeatureModule } from './features/product/product-image-zoom-feature.module';
import { ProductVariantsFeatureModule } from './features/product/product-variants-feature.module';
import { SmartEditFeatureModule } from './features/smartedit/smart-edit-feature.module';
import { UserFeatureModule } from './features/user/user-feature.module';

@NgModule({
  declarations: [],
  imports: [
    AuthModule.forRoot(),
    LoginRouteModule,
    //HamburgerMenuModule,
    SiteContextSelectorModule,
    LinkModule,
    BannerModule,
    CmsParagraphModule,
    TabParagraphContainerModule,
    BannerCarouselModule,
    //CategoryNavigationModule,
    //NavigationModule,
    //FooterNavigationModule,
    //BreadcrumbModule,
    ScrollToTopModule,
    PageTitleModule,
    UserModule,
    UserOccModule,
    //AddressBookModule,
    PaymentMethodsModule,
    //NotificationPreferenceModule,
    //MyInterestsModule,
    //StockNotificationModule,
    //ConsentManagementModule,
    //MyCouponsModule,
    //AnonymousConsentsModule.forRoot(),
    //AnonymousConsentsDialogModule,
    //AnonymousConsentManagementBannerModule,
    ProductModule.forRoot(),
    ProductOccModule,
    ProductDetailsPageModule,
    ProductListingPageModule,
    ProductListModule,
    //SearchBoxModule,
    ProductFacetNavigationModule,
    ProductTabsModule,
    ProductCarouselModule,
    ProductReferencesModule,
    ProductImagesModule,
    ProductSummaryModule,
    ProductIntroModule,
    //CostCenterOccModule,
    NavigationEventModule,
    HomePageEventModule,
    ProductPageEventModule,
    ExternalRoutesModule.forRoot(),
    UserFeatureModule,
    CartBaseFeatureModule,
    CartSavedCartFeatureModule,
    //WishListFeatureModule,
    //CartQuickOrderFeatureModule,
    //CartImportExportFeatureModule,
    OrderFeatureModule,
    CheckoutFeatureModule,
    //PersonalizationFeatureModule,
    //OrganizationAdministrationFeatureModule,
    OrganizationAccountSummaryFeatureModule,
    //OrganizationUnitOrderFeatureModule,
    OrganizationUserRegistrationFeatureModule,
    //OrganizationOrderApprovalFeatureModule,
    SmartEditFeatureModule,
    ProductVariantsFeatureModule,
    ProductImageZoomFeatureModule,
  ]
})
export class SpartacusFeaturesModule { }
