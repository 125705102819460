export const environment = {
  production: true,
  occBaseUrl: "https://www.mt.com",
  ssrDebug: true,
  ssrCache: false,
  ssrCacheTtl: 300000,
  ssrCacheSize: 20,
  adobeLunchScript: "//assets.adobedtm.com/fe1329cd99402871cb9d0ea6e5c03454e9a9ad1f/satelliteLib-177ebe3a0a33e5e514fcdd988fe563ff58703e89.js",
  gtmId:'GTM-P9BQ9HD',
};
