import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, UrlTree, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { LocalStorageService } from "../../../util/local-storage/local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class MtOrderConfirmationActivateGuard implements CanActivate {
    constructor(protected localStorageService: LocalStorageService) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
      this.localStorageService.removeItem("spartacus⚿" + this.localStorageService.getItem("currentBaseSiteUid") + "⚿cart");
      return true;
    }
}