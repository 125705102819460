/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { mtUserAccount } from './mt-user-account.i18n';

export const fr = {
  mtUserAccount,
};
