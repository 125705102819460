import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OccEndpointsService } from '@spartacus/core';
import { map } from 'rxjs/operators';

@Injectable()
export class MtForbiddenCharactersOccService {

  constructor(private http: HttpClient, private occEndpoints: OccEndpointsService) { }

  getForbiddenCharacters(): Observable<any> {
    return this.http.get(this.getEndpoint());
  }

  protected getEndpoint(): string {
    return this.occEndpoints.buildUrl('/forbiddenCharacters');
  }
}
