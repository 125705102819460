import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CartNotEmptyGuard } from '@spartacus/checkout/base/components';
import {CmsConfig, I18nModule, OccConfig, provideConfig, RoutingConfig, UrlModule} from '@spartacus/core';
import { AtMessageModule } from '@spartacus/storefront';
import { MtCartSharedModule } from 'src/app/spartacus/custom-module/cart/components/cart-shared';
import { MtPlaceOrderComponent } from './mt-place-order.component';



@NgModule({
  declarations: [
    MtPlaceOrderComponent
  ],
  imports: [
    AtMessageModule,
    CommonModule,
    RouterModule,
    UrlModule,
    I18nModule,
    ReactiveFormsModule,
    MtCartSharedModule
  ],
  exports: [
    MtPlaceOrderComponent
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutPlaceOrder: {
          component: MtPlaceOrderComponent,
          guards: [CartNotEmptyGuard],
        },
      },
    }),
    provideConfig(
      <RoutingConfig>{
        routing: {
          routes: {
              salesTermsAndConditions: {
                paths: ['/int/:language/home/site_content/legal/commercial_terms/sales_service_terms_glo.html'],
              },
            }
        }
      }
    ),
    provideConfig(<OccConfig>{
      backend: {
        occ: {
          endpoints: {
            placeOrder: 'orgUsers/${userId}/orders?fields=BASIC'
          }
        }
      }
    })
  ]
})
export class MtPlaceOrderModule { }
