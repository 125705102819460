import { Injectable, InjectionToken } from "@angular/core";
import { Converter } from "@spartacus/core";
import { MtDocumentFileType, MtDocumentType, MtProductDocument } from "../model/mt-product-document.model";
import { MtOccProductDocumentModel } from "../model/occ/mt-occ-product-document.model";


export const PRODUCT_DOCUMENT_NORMALIZER = new InjectionToken<Converter<any, MtProductDocument[]>>('ProductDocumentNormalizer');

@Injectable({ providedIn: 'root' })
export class MtProductDocumentConverter implements Converter<MtOccProductDocumentModel[], MtProductDocument[]> {

  constructor() { }

  convert(source: MtOccProductDocumentModel[], target?: MtProductDocument[] | undefined): MtProductDocument[] {

    target =
      source?.map((mtDocument: MtOccProductDocumentModel) => {
        const documentType = {
          code: mtDocument.documentType.code,
          name: mtDocument.documentType.name
        } as MtDocumentType;

        const documentFileType = {
            code: mtDocument.documentFileType.code,
            icon: mtDocument.documentFileType.icon
        } as MtDocumentFileType;

        const document = {
          url: mtDocument.url,
          urlDescription: mtDocument.urlDescription,
          documentType: documentType,
          documentFileType: documentFileType
        } as MtProductDocument;
        return document;
      });

    return target || {};
  }

}
