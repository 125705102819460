/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const MT_PASSWORD_PATTERN =
  /^.{6,25}$/
  ///^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
  // TODO: to be clarified in ticket ECO-16481, Commerce regexp to be aligned with the portals.
  // /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_]).{8,25}$/

export const POSTAL_CODE_REGEXES : { [key: string]: RegExp} =
  {
    US : /^\d{5}(-\d{4})?$/,
    DE: /^\d{5}$/,
    BE: /^\d{4}$/,
    NL: /^\d{4} [a-zA-Z]{2}$/,
    CH: /^\d{4}$/,
    CA: /^[a-zA-Z][0-9][a-zA-Z] [0-9][a-zA-Z][0-9]$/,
    GB: /^[a-zA-Z][0-9] [0-9][a-zA-Z]{2}|(([a-zA-Z][0-9][a-zA-Z])|([a-zA-Z][a-zA-Z][0-9])|([a-zA-Z][a-zA-Z][a-zA-Z])) [0-9][a-zA-Z]{2}|[a-zA-Z][a-zA-Z][0-9][a-zA-Z0-9] [0-9][a-zA-Z]{2}$/,
    LI: /^\d{4}$/
  }
