<div>
  <!-- ROOT Menu -->
  <ng-container *ngIf="getCurrentNode() | async as node">
    <div *ngIf="!node.isRoot" class="main-menu">
      <a [routerLink]="{ cxRoute: 'homePage' } | cxUrl">{{'main.menu.label' | cxTranslate}}</a>
    </div>
  </ng-container>

  <!-- Parent Node -->
  <ng-container *ngIf="getParentForSelectedNode() | async as parentNode">
    <div *ngIf="!parentNode.isRoot" style="margin-bottom: 8px">
      <a [routerLink]="{ cxRoute: 'categoryPage', params: { selectedNavigationNode: escapeURLParam(parentNode) } } | cxUrl"
        [queryParams]="{code: parentNode.uid}">{{parentNode.title}}</a>
    </div>
  </ng-container>

  <!-- Current Node -->
  <ng-container *ngIf="getCurrentNode() | async as node">
    <div *ngIf="!node.isRoot" style="margin-bottom: 8px">
      <span>{{node.title}}</span>
    </div>
  </ng-container>

  <!-- Children for current node -->
  <ng-container *ngIf="getCurrentNode() | async as node">
    <ul [ngClass]="{'no-padding': node.isRoot}">
      <li *ngFor="let item of model$ | async" class="list-item">

        <a *ngIf="hasChildren(item)"
          [routerLink]="{ cxRoute: 'categoryPage', params: { selectedNavigationNode: escapeURLParam(item) } } | cxUrl"
          [queryParams]="{code: item.uid}">{{item.title}}</a>

        <a *ngIf="!hasChildren(item)" href="{{item.url}}">{{item.title}}</a>

      </li>
    </ul>
  </ng-container>
</div>