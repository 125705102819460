<ng-container *ngIf="isUserLoggedIn$ | async; else notUserLoggedIn">
  <label *ngIf="getFormattedCustomerPrice()">
    <span class="mt-product-price">{{ getFormattedCustomerPrice() }}</span>
  </label>
</ng-container>

<ng-template #notUserLoggedIn>
  <div *ngIf="getFormattedListPrice()">
    <span class="mt-product-price">{{ getFormattedListPrice() }}</span>
  </div>
</ng-template>

