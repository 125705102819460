import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import {
  CartNotEmptyGuard
} from '@spartacus/checkout/base/components';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { FormErrorsModule, IconModule, SpinnerModule } from '@spartacus/storefront';
import { MtCheckoutPaymentTypeAdapter } from '../../../core/connectors/checkout-payment-type/mt-checkout-payment-type.adapter';
import { MtCheckoutPaymentTypeConnector } from '../../../core/connectors/checkout-payment-type/mt-checkout-payment-type.connector';
import { MtCheckoutPaymentTypeService } from '../../../core/facade/mt-checkout-payment-type.service';
import { MtCheckoutPlaceOrderService } from '../../../core/mt-checkout-place-order.service';
import { MtCheckoutPaymentTypeFacade } from '../../../root/facade/mt-checkout-payment.facade';
import { MtCheckoutPlaceOrderFacade } from '../../../root/facade/mt-checkout-place-order.facade';
import { MtOrderSimulationConverter } from '../../occ/adapters/converters/mt-occ-order-simulation.converter';
import { MtOccCheckoutPaymentTypeAdapter, ORDER_SIMULATION_NORMALIZER } from '../../occ/adapters/mt-occ-checkout-payment-type.adapter';
import { MtCheckoutDeliveryAddressModule } from "../checkout-delivery-address/mt-checkout-delivery-address.module";
import { MtCheckoutPaymentDetailsComponent } from './mt-checkout-payment-details.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    SpinnerModule,
    MtCheckoutDeliveryAddressModule,
    NgbTooltipModule,
    IconModule,
    FormsModule,
    ReactiveFormsModule,
    FormErrorsModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutPaymentDetails: {
          component: MtCheckoutPaymentDetailsComponent,
          guards: [CartNotEmptyGuard],
        },
      },
    }),
    {
      provide: ORDER_SIMULATION_NORMALIZER,
      useExisting: MtOrderSimulationConverter,
      multi: true
    },
    {
      provide: MtCheckoutPaymentTypeAdapter,
      useClass: MtOccCheckoutPaymentTypeAdapter
    },
    MtCheckoutPaymentTypeService,
    MtCheckoutPaymentTypeConnector,
    {
      provide: MtCheckoutPaymentTypeFacade,
      useExisting: MtCheckoutPaymentTypeService,
    },
    MtCheckoutPlaceOrderService,
    {
      provide: MtCheckoutPlaceOrderFacade,
      useExisting: MtCheckoutPlaceOrderService
    }
  ],
  declarations: [MtCheckoutPaymentDetailsComponent],
  exports: [MtCheckoutPaymentDetailsComponent],
})
export class MtCheckoutPaymentDetailsModule { }
