/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const mtOrgRegistrationForm = {

  fields: {
    company: {
      label: 'Nome dell\'azienda*',
      placeholder: 'Campo obbligatorio',
    },
    company2: {
      label: 'Nome dell\'azienda 2',
      placeholder: '',
    },
    company3: {
      label: 'Nome dell\'azienda 3',
      placeholder: '',
    },
    country: {
      label: 'Paese*',
      placeholder: 'Campo obbligatorio',
      hint: 'Questo è il vostro paese. Se avete bisogno di registrare il vostro account in un altro paese, visitate il sito Web METTLER TOLEDO corrispondente utilizzando la selezione del paese nella parte superiore di questa pagina.',
    },
    addressLine1: {
      label: 'Indirizzo 1*',
      placeholder: 'Campo obbligatorio',
    },
    addressLine2: {
      label: 'Indirizzo 2',
      placeholder: '',
    },
    addressLine3: {
      label: 'Indirizzo 3',
      placeholder: '',
    },
    postalCode: {
      label: 'Codice Postale',
      placeholder: 'Campo obbligatorio',
    },
    state: {
      label: 'Provincia*',
      placeholder: 'Campo obbligatorio',
    },
    city: {
      label: 'Citta*',
      placeholder: 'Campo obbligatorio',
    },
    phoneNumber: {
      label: 'Numero di telefono*',
      placeholder: 'Campo obbligatorio',
    },
    poNumber: {
      label: 'Ufficio postale numero',
      placeholder: '',
    },
    poCountry: {
      label: 'Ufficio postale del paese*',
      placeholder: '',
    },
    poState: {
      label: 'ufficio postale di stato',
      placeholder: '',
    },
    poPostalCode: {
      label: 'ufficio postale codice postale*',
      placeholder: ''
    }
  },

  labels:{
    companyRegistration: 'Registrazione aziendale',
    registerLabel1: 'Congratulazioni, hai creato con successo il tuo utente. Ora puoi registrare la tua azienda.',
    registerLabel2: 'Registra la tua azienda con noi per usufruire della nostra offerta digitale estesa.',
    laterLink: 'Registrai la mia azienda in seguito.',
    saveAsShippingAddress: 'Salva come indirizzo di spedizione',
    saveAsBillingAddress: 'Salva come indirizzo di fatturazione',
    addPoBillingAddress: 'Aggiungi l\'indirizzo di fatturazione dell\'ufficio postale',
    saveAsDefault: 'Salva come predefinito',
    cancel: 'Annulla',
    save: 'Salvare',
  },

  formSubmitButtonLabel: 'Registra l\'azienda',
};

export const mtOrgRegistration = {
  mtOrgRegistrationForm,
};
