import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { SpinnerModule } from '@spartacus/storefront';
import { LocalStorageModule } from '../../util/local-storage/local-storage.module';
import { MtCustomerPriceComponent } from './components/mt-customer-price/mt-customer-price.component';
import { MtSelectedPriceComponent } from './components/mt-selected-price/mt-selected-price.component';
import { MtStandardPriceComponent } from './components/mt-standard-price/mt-standard-price.component';
import { MtPriceService } from './services/mt-price.service';
import {NgbPopoverModule} from "@ng-bootstrap/ng-bootstrap";
import { MtVatInformationComponent } from './components/mt-vat-information/mt-vat-information.component';



@NgModule({
  declarations: [
    MtStandardPriceComponent,
    MtCustomerPriceComponent,
    MtSelectedPriceComponent,
    MtVatInformationComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    SpinnerModule,
    LocalStorageModule,
    NgbPopoverModule
  ],
  providers: [
    MtPriceService,
  ],
  exports: [
    MtStandardPriceComponent,
    MtCustomerPriceComponent,
    MtSelectedPriceComponent,
    MtVatInformationComponent
  ]
})
export class ProductPriceModule { }
