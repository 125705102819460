/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ModuleWithProviders, NgModule } from '@angular/core';
import { Config } from '@spartacus/core';
import { LocalStorageModule } from '../../util/local-storage/local-storage.module';
import { AemAdapterSiteContextConfig } from './aem-adapter-site-context.config';
import { AemAdapterSiteContextService } from './aem-adapter-site-context.service';
import { MtUrlService } from './mt-url.service';


@NgModule({
  imports: [
    LocalStorageModule
  ]
})
export class AemAdapterSiteContextModule {
  static forRoot(): ModuleWithProviders<AemAdapterSiteContextModule> {
    return {
      ngModule: AemAdapterSiteContextModule,
      providers: [
        AemAdapterSiteContextService,
        MtUrlService,

        {
          provide: AemAdapterSiteContextConfig,
          useExisting: Config
        },
      ],
    };
  }
}
