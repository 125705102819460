/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthRedirectService, AuthService, SemanticPathService, WindowRef } from '@spartacus/core';
import { User, UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MtUrlService } from 'src/app/spartacus/custom-module/core/aem-adapter-site-context/mt-url.service';
import { MtSoldToModel } from '../../../account/core/mt-sold-to.model';
import { MtOrganisationRegistrationStatusEnum, MtSoldToService } from '../../../account/core/mt-sold-to.service';

/**
 * Checks if there is currently SOLDTO selected, when applicable
 * Use to protect pages dedicated only to work with SOLDTO selection.
 */
@Injectable({
  providedIn: 'root',
})
export class MtSoldToGuardService {

  private redirectToSoldToSelect() {
    const soldToSelectURL =
      this.semanticPathService.get('soldToSelect') ?? '';

    if (this.winRef.nativeWindow && !this.winRef.nativeWindow.location.href.includes(soldToSelectURL)) {
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        const redirectToRoute = params['redirectToRoute'];
        console.log(`SoldTo RedirectToRoute: ${redirectToRoute}`);

        if (redirectToRoute !== undefined) {
          if (this.mtUrlService.isSpartacusContext()) {
            this.authRedirectService.setRedirectUrl(`${soldToSelectURL}?redirectToRoute=${redirectToRoute}`);
            this.authRedirectService.redirect();
          } else {
            this.winRef.location.href =
              this.mtUrlService.buildURLWithAEMContext({ cxRoute: 'soldToSelect', params: { redirectToRoute: redirectToRoute } });
          }
        } else {
          if (this.mtUrlService.isSpartacusContext()) {
            this.authRedirectService.setRedirectUrl(soldToSelectURL);
            this.authRedirectService.redirect();
          } else {
            this.winRef.location.href =
              this.mtUrlService.buildURLWithAEMContext({ cxRoute: 'soldToSelect' });
          }
        }
      }).unsubscribe();
    }
  }


  private checkSoldTo(user?: User): boolean {
    if (!user) return true;

    if (((user as User).companyRegistrationStatus === MtOrganisationRegistrationStatusEnum.MULTIPLE_SOLDTO_ACCOUNTS)) {
      if (this.mtSoldToService?.isCurrentSoldToSelected()) {
        let currentSoldToStrValue: string | null = this.mtSoldToService?.getCurrentSoldTo();
        let soldToFound: boolean = false;
        this.mtSoldToService?.getSoldToList().subscribe(
          (soldToList) => {
            soldToList.forEach((soldTo) => {
              if (soldTo.businessPartnerId.toString() === currentSoldToStrValue?.toString()) {
                soldToFound = true;
                this.mtSoldToService?.setCurrentSoldTo(soldTo);
              }
            }
            )
            if (soldToFound) {
              return true;
            } else {
              this.redirectToSoldToSelect();
              return false;
            };
          }
        ).unsubscribe();
      }
      else {
        this.redirectToSoldToSelect();
        return false;
      }
    } else if ((user as User).companyRegistrationStatus === MtOrganisationRegistrationStatusEnum.SINGLE_SOLDTO_ACCOUNT) {

      this.mtSoldToService?.getSoldToList().subscribe(
        (
          soldToList => {
            let soldTo: MtSoldToModel;
            soldTo = soldToList[0];
            if (soldTo) {
              this.mtSoldToService?.setCurrentSoldTo(soldTo);
              return true;
            } else {
              console.error('Unable to determine single SOLDTO account');
              this.authService.logout();
              return false;
            }
          }
        )
      );
    } else if ((user as User).companyRegistrationStatus === MtOrganisationRegistrationStatusEnum.NOT_REGISTERED
      || (user as User).companyRegistrationStatus === MtOrganisationRegistrationStatusEnum.REGISTRATION_NOT_CONFIRMED
    ) {
      this.mtSoldToService?.setCurrentSoldTo({ businessPartnerId: 'default' })
      return true;
    }
    return false;
  }


  constructor(
    protected authService: AuthService,
    protected authRedirectService: AuthRedirectService,
    protected router: Router,
    protected semanticPathService: SemanticPathService,
    protected userAccountFacade: UserAccountFacade,
    protected mtSoldToService: MtSoldToService,
    protected mtUrlService: MtUrlService,
    protected winRef: WindowRef,
    protected activatedRoute: ActivatedRoute,
  ) { }


  canActivateOrRedirectToSoldToSelection(): Observable<boolean> {
    return this.authService.isUserLoggedIn().pipe(
      switchMap((isLoggedIn) => {
        if (!isLoggedIn) {
          return of(true);
        } else {
          return this.userAccountFacade.get().pipe(
            map(
              (user: User | undefined) => this.checkSoldTo(user)
            )
          )
        }
      })
    );
  }

}
