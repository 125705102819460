<form class="address-form" (ngSubmit)="verifyAddress()" [formGroup]="addressForm">
  <div class="row">
    <div class="col-md-12 col-xl-12 address-form-wrapper">

      <div *ngIf="addressType === MtAddressType.BILL_TO" class="form-group">
        <div class="form-check">
          <label>
            <input type="checkbox"
                   [readonly]="isAddressFieldReadOnly()"
                   [disabled]="isAddressFieldReadOnly()"
                   class="form-check-input form-control"
                   formControlName="poBillingAddress"
                   (change)="togglePOBillingAddress()"
                   (click)="isAddressFieldReadOnly() ? $event.preventDefault() : null" />
            <span class="form-check-label" >
      {{'mtOrgRegistrationForm.labels.addPoBillingAddress' | cxTranslate }}
    </span>
          </label>
        </div>
      </div>

      <div class="form_field_wrapper">
        <label>
          <span class="label-content required">
            {{'mtOrgRegistrationForm.fields.company.label' | cxTranslate }}
          </span>
          <input required="true" class="form-control" type="text" name="companyName"
            [readOnly]="isAddressFieldReadOnly()" placeholder="{{
                'mtOrgRegistrationForm.fields.company.placeholder' | cxTranslate
              }}" formControlName="companyName" />
          <cx-form-errors [control]="addressForm.get('companyName')"></cx-form-errors>
        </label>
      </div>

      <div class="form_field_wrapper">
        <label>
          <span class="label-content required">
            {{'mtOrgRegistrationForm.fields.company2.label' | cxTranslate }}
          </span>
          <input required="false" class="form-control" type="text" name="companyName" formControlName="companyName2" />
          <cx-form-errors [control]="addressForm.get('companyName2')"></cx-form-errors>
        </label>
      </div>

      <div class="form_field_wrapper">
        <label>
          <span class="label-content required">
            {{'mtOrgRegistrationForm.fields.company3.label' | cxTranslate }}
          </span>
          <input required="false" class="form-control" type="text" name="companyName" formControlName="companyName3" />
          <cx-form-errors [control]="addressForm.get('companyName3')"></cx-form-errors>
        </label>
      </div>

      <div *ngIf="isPOBillingAddress()" class="form_field_wrapper">
        <label>
          <span>
            {{'mtOrgRegistrationForm.fields.poNumber.label' | cxTranslate }}
          </span>
          <input type="text" class="form-control" formControlName="poNumber" />
          <cx-form-errors [control]="addressForm.get('poNumber')">
          </cx-form-errors>
        </label>
      </div>

      <div class="form-group" formGroupName="country">
        <ng-container *ngIf="countries$ | async as countries">
          <div *ngIf="countries.length !== 0" class="form_field_wrapper">
            <label>
              <div *ngIf="!isPOBillingAddress()">
                <span class="label-content required">
                  {{ 'mtOrgRegistrationForm.fields.country.label' | cxTranslate }}
                  <ng-template #tooltipContent>{{ 'mtOrgRegistrationForm.fields.country.hint' | cxTranslate
                    }}</ng-template>
                  <span class="tooltip-container">
                    <span [ngbTooltip]="tooltipContent" placement="top-start" tooltipClass="country-tooltip">
                      <cx-icon class="cx-icon fas fa-info-circle"></cx-icon>
                    </span>
                  </span>
                </span>
              </div>
              <div *ngIf="isPOBillingAddress()">
                <span class="label-content required">
                  {{ 'mtOrgRegistrationForm.fields.poCountry.label' | cxTranslate }}
                  <ng-template #tooltipContent>{{ 'MtOrgRegistrationForm.fields.country.hint' | cxTranslate
                    }}</ng-template>
                  <span class="tooltip-container">
                    <span [ngbTooltip]="tooltipContent" placement="top-start" tooltipClass="country-tooltip">
                      <cx-icon class="cx-icon fas fa-info-circle"></cx-icon>
                    </span>
                  </span>
                </span>
              </div>
              <ng-select [inputAttrs]="{ required: 'true' }" class="country-select" id="country-select"
                formControlName="isocode" [searchable]="true" [clearable]="false" [items]="countries" bindLabel="name"
                bindValue="isocode" [readonly]="isAddressFieldReadOnly()"
                placeholder="{{ 'mtOrgRegistrationForm.fields.country.placeholder' | cxTranslate }}"
                (change)="countrySelected($event)" [cxNgSelectA11y]="{
                  ariaLabel: 'addressForm.country' | cxTranslate
                }">
              </ng-select>
              <cx-form-errors [control]="addressForm.get('country.isocode')"></cx-form-errors>
            </label>
          </div>
        </ng-container>
      </div>

      <div *ngIf="isPOBillingAddress()" class="form_field_wrapper">
        <label>
          <span>{{
            'mtOrgRegistrationForm.fields.city.label' | cxTranslate
            }}</span>
          <input type="text" class="form-control" placeholder="{{
                'mtOrgRegistrationForm.fields.city.placeholder' | cxTranslate
              }}" formControlName="town" />
          <cx-form-errors [control]="addressForm.get('town')">
          </cx-form-errors>
        </label>
      </div>

      <div *ngIf="!isPOBillingAddress()" class="form_field_wrapper">
        <label>
          <span>
            {{'mtOrgRegistrationForm.fields.addressLine1.label' | cxTranslate}}
          </span>
          <input type="text" class="form-control" [readOnly]="isAddressFieldReadOnly()" placeholder="{{
                'mtOrgRegistrationForm.fields.addressLine1.placeholder' | cxTranslate
              }}" formControlName="line1" />
          <cx-form-errors [control]="addressForm.get('line1')">
          </cx-form-errors>
        </label>
      </div>

      <div *ngIf="!isPOBillingAddress()" class="form_field_wrapper">
        <label>
          <span>
            {{'mtOrgRegistrationForm.fields.addressLine2.label' | cxTranslate}}
          </span>
          <input type="text" class="form-control" formControlName="line2" />
          <cx-form-errors [control]="addressForm.get('line2')">
          </cx-form-errors>
        </label>
      </div>

      <div *ngIf="!isPOBillingAddress()" class="form_field_wrapper">
        <label>
          <span>
            {{'mtOrgRegistrationForm.fields.addressLine3.label' | cxTranslate}}
          </span>
          <input type="text" class="form-control" formControlName="line3" />
          <cx-form-errors [control]="addressForm.get('line3')">
          </cx-form-errors>
        </label>
      </div>

      <div *ngIf="!isPOBillingAddress()" class="form_field_wrapper">
        <label>
           <span>{{
            ('mtOrgRegistrationForm.fields.postalCode.label' | cxTranslate)  + (this.isPostalCodeMandatory?'*':'')
            }}</span>
          <div *ngIf="this.isPostalCodeMandatory;then requiredFieldLabel else noRequiredFieldLabel"></div>
          <ng-template #requiredFieldLabel>
            <input type="text" class="form-control" [readOnly]="isAddressFieldReadOnly()" placeholder="{{
                  'mtOrgRegistrationForm.fields.postalCode.placeholder' | cxTranslate
                }}" formControlName="postalCode" />
          </ng-template>
          <ng-template #noRequiredFieldLabel>
            <input type="text" class="form-control" [readOnly]="isAddressFieldReadOnly()" placeholder="{{''}}" formControlName="postalCode" />
          </ng-template>
          <cx-form-errors [control]="addressForm.get('postalCode')"></cx-form-errors>
        </label>
      </div>

      <ng-container *ngIf="regions$ | async as regions" formGroupName="region">
        <ng-container *ngIf="regions.length !== 0">
          <div class="form_field_wrapper">
            <label>
              <div *ngIf="!isPOBillingAddress()">
                <span class="label-content required">{{
                  'mtOrgRegistrationForm.fields.state.label' | cxTranslate
                  }}</span>
              </div>
              <div *ngIf="isPOBillingAddress()">
                <span class="label-content required">{{
                  'mtOrgRegistrationForm.fields.poState.label' | cxTranslate
                  }}</span>
              </div>
              <ng-select [inputAttrs]="{ required: 'true' }" class="region-select mt-select" formControlName="isocode"
                [searchable]="true" [clearable]="false" [items]="regions"
                bindLabel="{{ regions[0].name ? 'name' : 'isocode' }}"
                bindValue="{{ regions[0].name ? 'isocode' : 'region' }}"
                placeholder="{{ 'mtOrgRegistrationForm.fields.state.placeholder' | cxTranslate }}" id="region-select"
                [cxNgSelectA11y]="{
                  ariaLabel: 'mtOrgRegistrationForm.fields.state.label' | cxTranslate
                }">
              </ng-select>
              <cx-form-errors [control]="addressForm.get('region.isocode')"></cx-form-errors>
            </label>
          </div>
        </ng-container>
      </ng-container>

      <div *ngIf="!isPOBillingAddress()" class="form_field_wrapper">
        <label>
          <span>{{
            'mtOrgRegistrationForm.fields.city.label' | cxTranslate
            }}</span>
          <input type="text" class="form-control" placeholder="{{
                'mtOrgRegistrationForm.fields.city.placeholder' | cxTranslate
              }}" formControlName="town" [readOnly]="isAddressFieldReadOnly()" />
          <cx-form-errors [control]="addressForm.get('town')">
          </cx-form-errors>
        </label>
      </div>

      <div *ngIf="isPOBillingAddress()" class="form_field_wrapper">
        <label>
          <span>{{
            'mtOrgRegistrationForm.fields.poPostalCode.label' | cxTranslate
            }}</span>
          <input type="text" class="form-control" placeholder="{{
                'mtOrgRegistrationForm.fields.postalCode.placeholder' | cxTranslate
              }}" formControlName="postalCode" />
          <cx-form-errors [control]="addressForm.get('postalCode')"></cx-form-errors>
        </label>
      </div>

      <!--      TODO: to be replaced with MT Checkbox:-->
      <div *ngIf="addressType === MtAddressType.SHIP_TO" class="form-group">
        <div class="form-check">
          <label>
            <input type="checkbox" class="form-check-input" formControlName="shippingAddress"
              (change)="toggleShippingAddress()" />
            <span class="form-check-label">
              {{'mtOrgRegistrationForm.labels.saveAsShippingAddress' | cxTranslate }}
            </span>
          </label>
        </div>
      </div>

      <div *ngIf="addressType === MtAddressType.BILL_TO" class="form-group">
        <div class="form-check">
          <label>
            <input type="checkbox" class="form-check-input" formControlName="billingAddress"
              (change)="toggleBillingAddress()" />
            <span class="form-check-label">
              {{'mtOrgRegistrationForm.labels.saveAsBillingAddress' | cxTranslate }}
            </span>
          </label>
        </div>
      </div>

      <div class="form-group" *ngIf="(addresses$ | async)?.length && setAsDefaultField">
        <div class="form-check">
          <label>
            <input type="checkbox" class="form-check-input" formControlName="defaultAddress"
              (change)="toggleDefaultAddress()" />
            <span class="form-check-label">
              {{'mtOrgRegistrationForm.labels.saveAsDefault' | cxTranslate }}
            </span>
          </label>
        </div>
      </div>

      <!--      <div class="cx-address-form-btns">-->
      <div class="buttons-wrapper" *ngIf="showCancelBtn">
        <div class="form_btn-action-wrapper">
          <button class="btn btn-secondary btn-action btn-large" (click)="back()">
            {{'mtOrgRegistrationForm.labels.cancel' | cxTranslate }}
            <!--              {{ cancelBtnLabel || ('addressForm.chooseAddress' | cxTranslate) }}-->
          </button>
        </div>
        <div class="form_btn-action-wrapper">
          <button #submit class="btn btn-primary btn-action btn-large" type="submit">
            {{'mtOrgRegistrationForm.labels.save' | cxTranslate }}
            <!--              {{ actionBtnLabel || ('common.continue' | cxTranslate) }}-->
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
