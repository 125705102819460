/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ActiveCartOrderEntriesContextToken } from '@spartacus/cart/base/root';
import { OutletModule, PAGE_LAYOUT_HANDLER } from '@spartacus/storefront';
import { MtCartDetailsModule } from './cart-details/mt-cart-details.module';
import { MtCartProceedToCheckoutModule } from './cart-proceed-to-checkout/mt-cart-proceed-to-checkout.module';
import { MtCartSharedModule } from './cart-shared/mt-cart-shared.module';
import {
  ActiveCartOrderEntriesContext,
  AddedToCartDialogModule,
  ClearCartModule,
  SaveForLaterModule
} from "@spartacus/cart/base/components";
import {MtCartPageLayoutHandler} from "./mt-cart-page-layout-handler.service";
import {OccConfig, provideConfig} from "@spartacus/core";
import { MtCartVoucherModule } from './cart-voucher/mt-cart-voucher.module';

@NgModule({
  imports: [
    CommonModule,
    MtCartDetailsModule,
    MtCartProceedToCheckoutModule,
    MtCartSharedModule,
    SaveForLaterModule,
    ClearCartModule,
    MtCartVoucherModule,
    OutletModule.forChild(),
  ],
  exports: [
    MtCartDetailsModule,
    MtCartProceedToCheckoutModule,
    MtCartSharedModule,
    ClearCartModule,
    AddedToCartDialogModule,
    SaveForLaterModule,
    MtCartVoucherModule,
  ],
  providers: [
    {
      provide: PAGE_LAYOUT_HANDLER,
      useExisting: MtCartPageLayoutHandler,
      multi: true,
    },
    {
      provide: ActiveCartOrderEntriesContextToken,
      useExisting: ActiveCartOrderEntriesContext,
    },

    provideConfig(<OccConfig>{
      backend: {
        occ: {
          endpoints: {
            cart: 'users/${userId}/carts/${cartId}?fields=DEFAULT,potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user,saveTime,name,description',
            createCart:
              'users/${userId}/carts?fields=DEFAULT,potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user',
            carts:
              'users/${userId}/carts/current?',
          }
        }
      },
    }),


  ],
})
export class MtCartBaseComponentsModule {}
