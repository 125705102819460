/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import {
  CheckoutQueryFacade
} from '@spartacus/checkout/base/root';
import {
  Address,
  Command,
  CommandService,
  CommandStrategy,
  EventService,
  OCC_USER_ID_ANONYMOUS,
  QueryState,
  UserIdService
} from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { MtOccCheckoutBillingAddressAdapter } from '../../base/occ/adapters/mt-occ-checkout-billing-address.adapter';

@Injectable()
export class MtCheckoutBillingAddressService
{

  protected setBillingAddressCommand: Command<Address, unknown> =
    this.commandService.create<Address>(
      (address) =>
        this.checkoutPreconditions().pipe(
          switchMap(([userId, cartId]) => {
            const addressId = address.id;
            if (!addressId) {
              throw new Error('Checkout conditions not met');
            }
            return this.mtOccCheckoutBillingAddressAdapter
              .setAddress(userId, cartId, addressId)
          })
        ),
      {
        strategy: CommandStrategy.CancelPrevious,
      }
    );

    protected createBillingAddressCommand: Command<Address, unknown> =
    this.commandService.create<Address>(
      (address) =>
        this.checkoutPreconditions().pipe(
          switchMap(([userId, cartId]) => {
            return this.mtOccCheckoutBillingAddressAdapter.createAddress(userId, cartId, address)
          })
        ),
      {
        strategy: CommandStrategy.CancelPrevious,
      }
    );


  constructor(
    protected activeCartFacade: ActiveCartFacade,
    protected userIdService: UserIdService,
    protected eventService: EventService,
    protected commandService: CommandService,
    protected mtOccCheckoutBillingAddressAdapter: MtOccCheckoutBillingAddressAdapter,
    protected checkoutQueryFacade: CheckoutQueryFacade
  ) {}

  /**
   * Performs the necessary checkout preconditions.
   */
  protected checkoutPreconditions(): Observable<[string, string]> {
    return combineLatest([
      this.userIdService.takeUserId(),
      this.activeCartFacade.takeActiveCartId(),
      this.activeCartFacade.isGuestCart(),
    ]).pipe(
      take(1),
      map(([userId, cartId, isGuestCart]) => {
        if (
          !userId ||
          !cartId ||
          (userId === OCC_USER_ID_ANONYMOUS && !isGuestCart)
        ) {
          throw new Error('Checkout conditions not met');
        }
        return [userId, cartId];
      })
    );
  }

  setBillingAddress(address: Address): Observable<unknown> {
    return this.setBillingAddressCommand.execute(address).pipe(tap());
  }

  createBillingAddress(address: Address): Observable<unknown> {
    return this.createBillingAddressCommand.execute(address).pipe(tap());
  }


  public getBillingAddressState(): Observable<QueryState<Address | undefined>> {
    return this.checkoutQueryFacade.getCheckoutDetailsState().pipe(
      tap((state) => console.log(state)),
      map((state) => ({
        ...state,
        data: state.data?.billingAddress,
      }))
    );
  }


  setBillingAddressAsDefault(addressId: string | undefined): void {
    this.userIdService.takeUserId().subscribe(userId => {
      this.mtOccCheckoutBillingAddressAdapter.setBillingAddressAsDefault(userId, addressId);
    });
  }

}
