
import {HttpErrorResponse, HttpRequest} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BadRequestHandler, GlobalMessageService, GlobalMessageType, HttpResponseStatus} from "@spartacus/core";
import {ActivatedRoute, Router} from "@angular/router";
import {MtLoginFormComponentService} from "../../../user/account/components/login-form";

@Injectable({
  providedIn: 'root',
})
export class MtBadRequestHandler extends BadRequestHandler {

  override responseStatus = HttpResponseStatus.BAD_REQUEST;

  constructor(
    protected override globalMessageService: GlobalMessageService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private mtLoginFormComponentService: MtLoginFormComponentService
  ) {
    super(globalMessageService);
  }

  override handleError(request: HttpRequest<any>, response: HttpErrorResponse): void {
    if (response.error?.error === 'invalid_grant') {
      if (response.error?.error_description === 'User is disabled') {
        this.mtLoginFormComponentService.isAccountInactive = true;
      } else {
        this.globalMessageService.add(
          { key: 'mtUserRegistrationForm.httpHandlers.invalidUsernameOrPassword' },
          GlobalMessageType.MSG_TYPE_ERROR
          )
      }
    } else {
      super.handleError(request, response);
    }
    this.router.navigate(['/pagenotfound']);
  }

}
