import {NgModule} from "@angular/core";
import { provideConfig} from "@spartacus/core";
import {MT_USER_REGISTRATION, MtUserRegistrationRootModule} from "./mt-user-registration-root.module";
import {MtUserProfileCoreModule} from "./core/mt-user-profile-core.module";
import {MtRegistrationConfModule} from "./components/confirmation";

@NgModule({
  imports: [MtUserRegistrationRootModule, MtUserProfileCoreModule, MtRegistrationConfModule],
  providers: [
    provideConfig({
      featureModules: {
        [MT_USER_REGISTRATION]: {
          module: () => import('./mt-user-registation.module').then((m) => m.MtUserRegistationModule)
        }
      }
    })
  ]
})
export class MtUserRegistrationFeatureModule {}
