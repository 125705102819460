
export const mtActiveFacets = {
  labels: {
    clearAllActiveFacets: 'Wis alles'
  }
}

export const mtFacets = {
  mtActiveFacets
}
