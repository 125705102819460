import {AuthService, BaseSiteService} from '@spartacus/core';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import { MtProduct, MT_CPV_STATUS } from '../../model/mt-product.model';
import { MtPriceService } from '../services/mt-price.service';
import { MtProductPriceComponentAdapter } from './mt-product-price-component.adapter';
import {OnDestroy, OnInit} from "@angular/core";



export abstract class MtAbstratPriceComponent {

  protected priceComponentAdapter?: MtProductPriceComponentAdapter;
  protected _showPrice: boolean = true;
  protected vatDescription: BehaviorSubject<String | undefined> = new BehaviorSubject<String | undefined>(undefined);
  protected vatRate: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);


  isUserLoggedIn$: Observable<boolean> = of(false);

  protected subscriptions: Subscription = new Subscription();

  constructor(
    protected authService: AuthService,
    protected priceService: MtPriceService,
    protected baseSiteService: BaseSiteService
  ) {

  }

  onDestroy(): void {
    this.priceComponentAdapter?.onDestroy();
    this.subscriptions.unsubscribe();
  }

  protected showPrice(product: MtProduct): boolean {
    return product.cpv === MT_CPV_STATUS.SALES || product.cpv === MT_CPV_STATUS.SALESANDQUOTE;
  }

  protected onInit(product: MtProduct): void {
    this.priceComponentAdapter = new MtProductPriceComponentAdapter(product, this.authService, this.priceService);
    this.priceComponentAdapter?.onInit();
    this.isUserLoggedIn$ = this.priceComponentAdapter.isUserLoggedIn$;
    this._showPrice = this.showPrice(product);

    this.subscriptions.add(this.baseSiteService
      .getActive()
      .subscribe(
        (activeBaseSite) => {
          this.subscriptions.add(
            this.baseSiteService.get(activeBaseSite)
              .subscribe(
                (baseSite) => {
                  this.vatRate.next( baseSite?.baseStore?.vatRate ?? undefined);
                },
                error => {
                  this.vatRate.next(undefined);
                }));
        }
      ));
  }

  getFormattedListPrice(): string | undefined {
    return (this._showPrice === true) ? this.priceComponentAdapter?.formattedListPrice : undefined;
  }

  getFormattedListPriceWithVat(): string | undefined {
    return (this._showPrice === true) ? this.priceComponentAdapter?.formattedListPriceWithVat : undefined;
  }

  getFormattedCustomerPrice(): string | undefined {
    return (this._showPrice === true) ? this.priceComponentAdapter?.formattedCustomerPrice : undefined;
  }

  getFormattedCustomerPriceWithVat(): string | undefined {
    return (this._showPrice === true) ? this.priceComponentAdapter?.formattedCustomerPriceWithVat : undefined;
  }

  getVatDescription(): string | undefined {
    return (this._showPrice === true) ? this.priceComponentAdapter?.vatDescription : undefined;
  }

  pricesMatch() : boolean
  {
    const listPrice = this.getFormattedListPrice()
    const customerPrice = this.getFormattedCustomerPrice()
    return listPrice === customerPrice
  }



}
