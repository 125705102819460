import { Cart } from "@spartacus/cart/base/root";
import { CheckoutPaymentTypeEvent } from "@spartacus/checkout/b2b/root";
import { CheckoutEvent } from "@spartacus/checkout/base/root";
import { CxEvent } from "@spartacus/core";
import { MtOrderSimulationResponseError } from "../../model/mt-order-simulation-response-error.model";

export class MtCheckoutPaymentTypeSetEvent extends CheckoutPaymentTypeEvent {
  /**
 * Event's type
 */
  static override readonly type = 'MtCheckoutPaymentTypeSetEvent';

  /**
   * Payment type code
   */
  paymentTypeCode?: string;

  /**
   * Purchase order number
   */
  purchaseOrderNumber?: string;

  phoneNumber?: string;
}

export class MtOrderSimulationResponseEvent extends CheckoutEvent {

  orderSimulationResponse?: MtOrderSimulationResponseError | Cart;

}

/**
 * Emit this event to force checkout delivery method details reload
 */
export class CheckoutDeliveryMethodQueryReloadEvent extends CxEvent {
  /**
   * Event's type
   */
  static override readonly type = 'CheckoutDeliveryMethodQueryReloadEvent';
}

/**
 * Emit this event to force checkout delivery method details reset
 */
export class CheckoutDeliveryMethodQueryResetEvent extends CxEvent {
  /**
   * Event's type
   */
  static override readonly type = 'CheckoutDeliveryMethodQueryResetEvent';
}

