<div class="cx-dialog-popover-center-backdrop">
  <div class="cx-modal-container" [cxFocus]="focusConfig" (esc)="dismissModal('Escape pressed')">
    <div class="cx-modal-content">

      <!-- Modal Header -->
      <div class="cx-dialog-header modal-header" *ngIf="!(globalLoading$ | async); else loading">
        <div class="cx-dialog-title modal-title" *ngIf="!multipleProducts">
          <span *ngIf="cartRows[0].numberOfEntriesBeforeAdd <= 0">{{ 'mtProductCart.titles.addcart' | cxTranslate
            }}</span>
          <span *ngIf="cartRows[0].numberOfEntriesBeforeAdd > 0">{{ 'mtProductCart.titles.updatecart' | cxTranslate
            }}</span>
        </div>
        <div class="cx-dialog-title modal-title" *ngIf="multipleProducts">
          <span>{{ 'mtProductCart.titles.multipleprod' | cxTranslate }}</span>
        </div>
        <button type="button" class="close" attr.aria-label="{{ 'addToCart.closeModal' | cxTranslate }}"
          (click)="dismissModal('Cross click')">
          <span aria-hidden="true">
            <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
          </span>
        </button>
      </div>

      <!-- Modal Body -->
      <div class="cx-dialog-body modal-body">
        <div class="cx-dialog-row">

          <div *ngFor="let row of cartRows; index as rowIndex" class="mt-row-dialog-container">
            <ng-container *ngIf="!(row.isLoading$ | async) && (row.product$ | async) as product; else loading">
              <div class="cx-dialog-item-container">
                <div class="cx-dialog-item image-wrapper">
                  <a *ngIf="product.images && product.images?.hasOwnProperty('GALLERY')"
                    [routerLink]="{ cxRoute: 'product', params: product } | cxUrl" class="mt-product-image-container">
                    <cx-media class="mt-product-image" [container]="product.images['GALLERY']" format="thumbnail"
                      [alt]="product.name || product.summary || ''"></cx-media>
                  </a>
                  <a *ngIf="!product.images?.hasOwnProperty('GALLERY')"
                    [routerLink]="{ cxRoute: 'product', params: product } | cxUrl" class="mt-product-image-container">
                    <span class="mt-product-no-image">
                      <svg viewBox="0 0 515 515">
                        <style type="text/css">
                          .st0 {
                            fill: #FFFFFF;
                          }

                          .st1 {
                            fill: #D3D6DB;
                          }
                        </style>
                        <rect y="0" class="st0" width="515" height="515" />
                        <path class="st1"
                          d="M352.6,198.7h-31.9v15.6h31.9V198.7z M279.3,293.6c-5.9,5.2-14.8,8.1-23,8.1c-22.2,0-39.3-17.8-39.3-40 c0-1.5,0-3.7,0-5.2L279.3,293.6z M296.3,261.7c0,2.2,0,5.2,0,5.9l-64.4-37.8c7.4-5.2,15.6-8.9,24.4-8.9 C278.6,221.7,296.3,240.2,296.3,261.7z M129.7,204.7v120c0,8.9,5.9,15.6,15.6,15.6h214.1l-65.9-37.8c-9.6,8.9-23,14.8-37.8,14.8 c-30.4,0-54.8-25.9-54.8-55.6c0-5.2,0-9.6,1.5-13.3L129.7,204.7z M115.6,170.6L406,341.7l5.2,2.2l-2.2,5.9l-2.2,2.2l-2.2,5.9 l-5.9-3.7L108.9,183.2l-5.9-3.7l3.7-5.2l1.5-2.2l3.7-5.9L115.6,170.6z M206.7,181.7l-1.5-9.6c-1.5-2.2-2.2-3.7-5.2-3.7h-15.6h-14.8 c-2.2,0-3.7,1.5-5.2,3.7l-2.2,9.6h-1.5h-11.9l68.1,40c9.6-9.6,24.4-15.6,39.3-15.6c31.9,0,55.6,25.2,55.6,55.6 c0,5.2-1.5,9.6-2.2,14.8l74.1,43.7V198.7c0-9.6-7.4-17-15.6-17h-15.6c-5.2-1.5-8.9-5.2-11.1-8.9l-2.2-3.7 c-3.7-5.9-11.9-11.1-18.5-11.1h-45.2c-7.4,0-15.6,5.2-19.3,11.1l-3.7,5.2c-2.2,2.2-5.9,5.9-9.6,7.4h-23h-11.1H206.7L206.7,181.7z" />
                      </svg>
                    </span>
                  </a>
                </div>

                <div class="cx-dialog-item">
                  <div class="mt-product-card-content-info-container">
                    <div class="mt-product-card-content-info-text">
                      <a [attr.aria-label]="
                    !product.images?.hasOwnProperty('PRIMARY')
                      ? ('productDetails.noProductImage'
                        | cxTranslate: { product: product.name })
                      : undefined
                  " [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
                        class="mt-product-card-content-info-text-name">
                        {{product.name}}
                      </a>
                      <div class="mt-product-card-content-info-text-code">
                        <span>{{product.code}}</span>
                      </div>
                      <div class="mt-dialog-product-price">
                        <span>{{'mtProductCart.labels.price' | cxTranslate }}: </span>
                        <span><mt-selected-price [product]="product"></mt-selected-price></span>
                      </div>
                    </div>
                  </div>
                  <mt-quantity-picker-shell [productCode]="row.productCode" [index]="rowIndex"
                    (quantityChange)="onQuantityChange($event)"></mt-quantity-picker-shell>
                </div>
              </div>
              <!-- Separator -->
              <div class="
              cx-dialog-separator
              col-sm-12
              d-xs-block d-sm-block d-md-none
            "></div>

              <!-- Separator -->
              <div class="
              cx-dialog-separator
              col-sm-12
              d-xs-block d-sm-block d-md-none
            "></div>

            </ng-container>
          </div>

          <!-- Actions -->
          <div class="cx-dialog-buttons">
            <div class="cx-dialog-row mt-product-total" *ngIf="(showTotalPrice$ | async) as showTotalPrice">
              <span class="mt-product-total-label">{{ 'mtProductCart.labels.total' | cxTranslate }}:</span>
              <span class="mt-product-total-value">{{ (totalPrice$ | async) | async }}</span>
            </div>
            <div class="cx-dialog-row">
              <button [class.disabled]="form.dirty" (click)="dismissModal('View Cart click')"
                class="mt-product-continue-shopping" autofocus>{{ 'mtProductCart.labels.actions.continueShopping' |
                cxTranslate }}</button>
              <button *ngIf="isSpartacusContext" [class.disabled]="form.dirty" routerLink="/cart" (click)="goToCart()"
                class="mt-product-go-to-cart">{{
                'mtProductCart.labels.actions.goToCart' | cxTranslate }}</button>
              <button *ngIf="!isSpartacusContext" [class.disabled]="form.dirty" (click)="goToCart()"
                class="mt-product-go-to-cart">{{
                'mtProductCart.labels.actions.goToCart' | cxTranslate }}</button>
            </div>
          </div>

        </div>
      </div>

      <ng-template #loading>
        <!-- Modal Header -->
        <div class="cx-dialog-header modal-header" *ngIf="!multipleProducts">
          <div class="cx-dialog-title modal-title">
            <span>{{ 'mtProductCart.titles.loading' | cxTranslate }}</span>
          </div>
          <button type="button" class="close" attr.aria-label="{{ 'addToCart.closeModal' | cxTranslate }}"
            (click)="dismissModal('Cross click')">
            <span aria-hidden="true">
              <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
            </span>
          </button>
        </div>

        <!-- Modal Body -->
        <div class="cx-dialog-body modal-body loading">
          <div class="cx-dialog-row">
            <div class="cx-dialog-item col-sm-12 col-md-6 cx-spinner">
              <div id="load"></div>
            </div>
          </div>
        </div>
      </ng-template>

    </div>
  </div>
</div>