<div class="cx-modal-container" [cxFocus]="focusConfig" (esc)="dismissModal('Escape pressed')">

  <div class="cx-modal-content">
    <div class="cx-dialog-header modal-header">
      <div class="cx-dialog-title modal-title">
        {{ 'mtUserAccountAndLogin.logout.sessionDialog.title' | cxTranslate }}
      </div>
      <button type="button" class="close" aria-label="Close modal" (click)="dismissModal('Cross click')">
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </button>
    </div>

    <div class="cx-dialog-body modal-body">
      <div class="cx-dialog-row" *ngIf="!logoutCountryChanged; else logoutCountryChangedTemplate">
        {{ 'mtUserAccountAndLogin.logout.sessionDialog.message' | cxTranslate }}
      </div>
      <ng-template #logoutCountryChangedTemplate>
        <div class="cx-dialog-row">
          {{ 'mtUserAccountAndLogin.logout.sessionDialog.logoutCountryChangedMessage' | cxTranslate }}
        </div>
      </ng-template>
      <div class="cx-dialog-row">

        <div *ngIf="logoutCountryChanged; else sessionExpired" class="cx-dialog-buttons">
          <button class="btn btn-primary btn-block btn-large" type="button" autofocus
            (click)="dismissModal('Proceed to login click')">
            <span>{{ 'mtUserAccountAndLogin.logout.sessionDialog.button' | cxTranslate }}</span>
          </button>
        </div>
        <ng-template #sessionExpired>
          <div *ngIf="!rememberMe; else rememberMe" class="cx-dialog-buttons">
            <button class="btn btn-primary btn-block btn-large" type="button"
              [routerLink]="{ cxRoute: 'login' } | cxUrl" autofocus (click)="dismissModal('Proceed to login click')">
              <span>{{ 'mtUserAccountAndLogin.logout.sessionDialog.button' | cxTranslate }}</span>
            </button>
          </div>
          <ng-template #rememberMe>
            <div class="cx-dialog-buttons">
              <button class="btn btn-primary btn-block btn-large" type="button" autofocus
                (click)="dismissModalAndRefresh('Close only modal click')">
                <span>{{ 'mtUserAccountAndLogin.logout.sessionDialog.button' | cxTranslate }}</span>
              </button>
            </div>
          </ng-template>
        </ng-template>

      </div>


    </div>

  </div>

</div>
