import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MtDeliveryMethodModel } from "../model/mt-delivery-method.model";
import { MtDeliveryMethodAdapter } from "./mt-delivery-method.adapter";


@Injectable({ providedIn: 'root' })
export class MtDeliveryMethodConnector {

  constructor(
    protected mtDeliveryMethodAdapter: MtDeliveryMethodAdapter
  ) { }

  getDeliveryMethod(
    userId: string,
    cartId: string): Observable<MtDeliveryMethodModel> {
    return this.mtDeliveryMethodAdapter.getDeliveryMethod(userId, cartId);
  }


  saveDeliveryMethod(
    userId: string,
    cartId: string,
    deliveryMethod: MtDeliveryMethodModel
  ): Observable<MtDeliveryMethodModel> {
    return this.mtDeliveryMethodAdapter.saveDeliveryMethod(userId, cartId, deliveryMethod);
  }


}
