
export const mtProductATP = {
  labels: {
    quoteonly: 'Nessuna informazione di disponibilità disponibile.',
    availability: '<b>Disponibilità:</b><br>',
    contactUsLastRowMessage: '<a href="{{link}}" target="_blank">Contattateci</a>  per la quantità rimanente',
    contactUsMessage: '<a href="{{link}}" target="_blank">Contattateci</a> per disponibilità',
  }
}

