import { Injectable } from '@angular/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { CheckoutDeliveryModesFacade } from '@spartacus/checkout/base/root';
import { UserIdService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { MtCheckoutDeliveryModeService } from '../../../core/facade/mt-checkout-delivery-mode.service';
import { MtDeliveryMethodModel } from '../../../model/mt-delivery-method.model';

@Injectable({
  providedIn: 'root'
})
export class CheckoutDeliveryMethodComponentService {

  deliveryMethod$: Observable<MtDeliveryMethodModel> =
    this.checkoutDeliveryModeService.getDeliveryMethod().pipe(
      filter((deliveryMethod) => deliveryMethod !== undefined),
      map((deliveryMethod) => deliveryMethod || ({} as MtDeliveryMethodModel))
    );


  constructor(
    protected activeCartFacade: ActiveCartFacade,
    protected userIdService: UserIdService,
    protected checkoutDeliveryModeService: MtCheckoutDeliveryModeService,
    protected checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade
  ) { }

  saveDeliveryMethod(deliveryMethod: MtDeliveryMethodModel): Observable<any> {
    const savedDeliveryMethod$ =
      this.checkoutDeliveryModeService.saveDeliveryMethod(deliveryMethod).pipe(
        tap((_) => this.checkoutDeliveryModesFacade.setDeliveryMode(deliveryMethod.deliveryModeCode))
      );
    return savedDeliveryMethod$
  }

}
