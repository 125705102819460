/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const cart = {
  cartDetails: {
    id: 'ID',
    proceedToCheckout: 'Kassa',
    cartName: 'Cart #{{code}}',
    cartPageTitle: 'Uw Winkelwagen',
    loginForCheckout: 'Log in om te bestellen',
    registerForCheckout: 'Register',
    checkoutAsAGuest: 'Uitchecken als gast',
    invoice: 'Factuur (alleen voor gebruikers met een geregistreerd zakelijk account)',
  },
  cartItems: {
    id: 'Materiaal nummer',
    description: 'Description',
    item: 'Item',
    itemPrice: 'Item price',
    quantity: 'Hoeveelheid',
    quantityTitle:
      'The quantity represents the total number of this item in your cart.',
    total: 'Totaal',
    actions: 'Actions',
    cartTotal: 'Cart total ({{count}} item)',
    cartTotal_other: 'Cart total ({{count}} items)',
    itemRemoved: 'Selected item has been removed. Cart total has been updated.',
    caption: 'Shopping cart contents.',
    itemStandardPrice: 'Artikel standaard prijs',
    itemNetPrice: 'Artikel netto prijs',
  },
  orderCost: {
    orderSummary: 'Samenvatting',
    subtotal: 'Subtotal after discounts:',
    shipping: 'Shipping:',
    estimatedShipping: 'Estimated shipping:',
    discount: 'You saved:',
    salesTax: 'Sales Tax:',
    grossTax: 'The order total does not include tax of',
    grossIncludeTax: 'The order total includes tax of',
    total: 'Totaal',
    toBeDetermined: 'TBD',
  },
  voucher: {
    coupon: 'Have a coupon?',
    coupon_other: 'Coupon codes',
    couponLabel: 'Enter a promo code here',
    apply: 'Verifiëren',
    placeholder: 'Promo code',
    applyVoucherSuccess: '{{voucherCode}} has been applied.',
    removeVoucherSuccess: '{{voucherCode}} has been removed.',
    anchorLabel: 'Enter or remove your coupon code',
    vouchersApplied: 'Applied coupons',
    availableCoupons: 'Available coupons',
    availableCouponsLabel: 'You can add these coupons to this order.',
    enterVoucherCode: 'Voer uw couponcode in',
    lessThanTreshold: 'De toepasselijke minimale bestelwaarde is {{value}}.',
    valueDeducted: 'De korting is toegepast. Het totale bedrag wordt weergegeven in uw orderoverzicht',
    voucherCode: 'Uw couponcode',
    voucherCodeNotecognized: 'Couponcode niet herkend. Controleer en probeer het opnieuw.',
    anotherCampaign: 'Je kunt de vouchercode niet gebruiken wanneer je deelneemt aan een andere actie.',
    voucherAlreadyUsed: 'De couponcode is al gebruikt.',
    voucherGreater: 'De waarde van uw bestelling is lager dan de waarde van uw voucher. Het verschil ben je kwijt.',
    productSpecificCampaign: 'Er zijn geen producten van de productspecifieke couponactie.',
    voucherBenefit: 'Voucherkorting',
  },
  saveForLaterItems: {
    itemTotal: 'Saved for later ({{count}} item)',
    itemTotal_other: 'Saved for later ({{count}} items)',
    cartTitle: 'Cart',
    saveForLater: 'Save For Later',
    moveToCart: 'Move To Cart',
    stock: 'Stock',
    forceInStock: 'In Stock',
  },
  clearCart: {
    clearCart: 'Clear Cart',
    clearingCart: 'Clearing Cart...',
    cartClearedSuccessfully: 'Active cart cleared successfully.',
    areYouSureToClearCart: 'Are you sure you want to clear this cart?',
    allItemsWillBeRemoved: 'All items in your active cart will be removed.',
  },
  validation: {
    cartEntriesChangeDuringCheckout:
      'During checkout we found problems with your entries. Please review your cart.',
    cartEntryRemoved:
      '{{name}} was removed from the cart due to being out of stock.',
    productOutOfStock:
      '{{name}} has been removed from the cart due to insufficient stock.',
    lowStock: 'Quantity has reduced to {{quantity}} due to insufficient stock.',
    reviewConfiguration:
      'Resolve the issues in the configuration for cart entry first.',
    pricingError:
      'Configurator pricing is currently not available. Checkout/quote submission is not possible. Please try again later.',
    unresolvableIssues:
      'The product configuration requires additional entries in the back end. As a result, you cannot proceed. Please contact support.',
    inProgress: 'En cours de traitement',
  },
  orderSummary: {
    infoMessage: 'De uiteindelijke verzend- en administratiekosten staan ​​vermeld op de factuur van de bestelling.',
    standardPrice: 'Standaardprijs:',
    discounts: 'Korting:',
    yourNetPrice: 'Uw Nettoprijs:',
    quantity: 'Aantal:',
    voucherBenefit: 'Voucherkorting:',
    additionalInfo: 'In de volgende stap wordt u doorgestuurd naar de Paymetric site om uw credit card te autoriseren',
  },
  guestMessages: {
    pleaseLogin: 'Log in als u een online account bij ons heeft om toegang te krijgen tot aanvullende diensten.',
    createAccount: 'Maak een online account aan en registreer uw bedrijf om zakelijke accountspecifieke prijzen te zien en per factuur te betalen.',
    guestCreditCardInfo: 'Als gast kunt u met creditcard betalen. Houd er rekening mee dat belastingvrijstelling niet van toepassing is als u als gast bestelt.'
  }
};
