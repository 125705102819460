import { InjectionToken, Provider } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";
import { NavigationState } from "../navigation-state";
import * as fromNavigationState from "./navigation.reducer";


export function getReducers(): ActionReducerMap<NavigationState, any> {
  return {
    homepageState: fromNavigationState.reducer,
  };
}

export const reducerToken: InjectionToken<ActionReducerMap<NavigationState>> =
  new InjectionToken<ActionReducerMap<NavigationState>>('NavigationReducers');

export const reducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getReducers,
};
