import { TranslationChunksConfig, TranslationResources } from "@spartacus/core";
import { en } from './en/index';
import { de } from './de/index';
import { fr } from './fr/index';
import { nl } from './nl/index';
import { it } from './it/index';
import {translationChunksConfig} from "@spartacus/assets";


export const mtPageNotFoundTranslations: TranslationResources = {
    en, de, fr, nl, it
};

export const mtPageNotFoundTranslationChunksConfig: TranslationChunksConfig =
{
    ...translationChunksConfig,
    mtPageNotFoundTranslations: ['pageNotFoundTranslations'],
};
