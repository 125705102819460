import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule, provideDefaultConfig, UrlModule } from '@spartacus/core';
import {
  IconModule,
  KeyboardFocusModule,
} from '@spartacus/storefront';


import {sessionLogoutLayoutConfig} from "./session-logout-layout.config";
import {SessionLogoutDialogComponent} from "./session-logout-dialog.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    IconModule,
    I18nModule,
    KeyboardFocusModule,
  ],
  providers: [provideDefaultConfig(sessionLogoutLayoutConfig)],
  declarations: [SessionLogoutDialogComponent],
  exports: [SessionLogoutDialogComponent],
})
export class SessionLogoutDialogModule {
}
