/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ActiveCartFacade,
  CartModification,
  CartValidationFacade,
  CartValidationStatusCode,
} from '@spartacus/cart/base/root';
import { ICON_TYPE } from '@spartacus/storefront';
import { map } from 'rxjs/operators';

@Component({
  selector: 'mt-cart-validation-warnings',
  templateUrl: './mt-cart-validation-warnings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MtCartValidationWarningsComponent {
  iconTypes = ICON_TYPE;
  visibleWarnings: Record<string, boolean> = {};
  doReloadActiveCart: boolean = false;

  cartModifications$ = this.cartValidationFacade
    .validateCart()
    .pipe(
      map((modificationList) => {
        modificationList.cartModifications?.forEach((modification, i: number) => {
          modification.index = i;
          this.visibleWarnings[i] = true;
          this.doReloadActiveCart = true;
        });
        if (this.doReloadActiveCart = true) {
          this.activeCartFacade.reloadActiveCart();
        }
        return modificationList.cartModifications;
      })
  );

  constructor(
    protected cartValidationFacade: CartValidationFacade,
    protected activeCartFacade: ActiveCartFacade
  ) {}

  removeMessage(cartModification: CartModification) {
    if (cartModification.index != null) {
      this.visibleWarnings[cartModification.index] = false;
    }
  }
}
