import { TranslationChunksConfig, TranslationResources } from "@spartacus/core";
import { de } from "./de";
import { en } from "./en";
import { fr } from "./fr";
import { nl } from "./nl";
import { it } from "./it";


export const mtProductTranslations: TranslationResources = {
  en, de, fr, nl, it
}


export const mtProductChunkConfig: TranslationChunksConfig = {
  mtFacets: ['mtActiveFacets'],
  mtProductListResource: ['mtProductItemtList', 'mtProductNoItemsFound', 'mtItemsPerPage', 'mtProductATP'],
  mtProductCartResource: ['mtProductCart']
}

export const mtProductDetailsTranslationsConfig: TranslationChunksConfig = {
  mtProductDetailsResource: ['mtProductDetailsTab', 'mtProductRecommendations','mtProductInfo']
}
