import { Injectable, InjectionToken } from "@angular/core";
import { Converter } from "@spartacus/core";
import { MtProductAtpModel } from "../model/mt-product-atp.model";
import { MtOccProductAtpModel } from "../model/occ/mt-occ-product-atp.model";


export const PRODUCT_ATP_NORMALIZER = new InjectionToken<Converter<any, MtProductAtpModel>>('ProductAtpNormalizer');


/**
 * Convert getprice response to spartacus Price interface. 
 */
@Injectable({ providedIn: 'root' })
export class ProductAtpConverter implements Converter<MtOccProductAtpModel, MtProductAtpModel> {

  constructor() { }

  convert(source: MtOccProductAtpModel, target?: MtProductAtpModel | undefined): MtProductAtpModel {

    target =
      source;

    // TODO:custom mappings

    return target || {};
  }

}