/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ModuleWithProviders, NgModule } from '@angular/core';
import { Config, SiteContextConfigInitializer } from '@spartacus/core';
import { CountryMapBaseSiteIdContextConfig } from './country-map-basesiteid-context.config';
import { CountrySiteContextConfigInitializer } from './country-site-context-config.initializer';


@NgModule({})
export class CountrySiteContextModule {
  static forRoot(): ModuleWithProviders<CountrySiteContextModule> {
    return {
      ngModule: CountrySiteContextModule,
      providers: [
        {
          provide: SiteContextConfigInitializer,
          useClass: CountrySiteContextConfigInitializer,
        },
        {
          provide: CountryMapBaseSiteIdContextConfig,
          useExisting: Config
        }
      ],
    };
  }
}
