import { Injectable } from "@angular/core";
import { ActivatedRouterStateSnapshot, CmsNavigationNode, RouterState, RoutingService } from "@spartacus/core";
import { Observable, using } from "rxjs";
import { distinctUntilChanged, map, shareReplay, tap } from "rxjs/operators";
import { KEY_ROOT, MtNavigationNode } from "../../model/mt-navigation-node.model";
import { MtNavigationService } from "../../services/mt-navigation.service";


@Injectable({ providedIn: 'root' })
export class MtCategoriesNavigationComponentService {

  protected selectNavNodeByrouting$: Observable<ActivatedRouterStateSnapshot> =
    this.routingService.getRouterState().pipe(
      distinctUntilChanged((x, y) => {
        return x.state.url === y.state.url;
      })
    ).pipe(
      map((routerState: RouterState) => routerState.state),
      tap((state: ActivatedRouterStateSnapshot) => {
        if (state.queryParams && state.queryParams["code"]) {
          this.navigationService.navigateToNode(state.queryParams["code"]);
        } else {
          this.navigationService.navigateToNode(KEY_ROOT);
        }
      })
    );

  protected currectSelectedNode$: Observable<MtNavigationNode[]> =
    this.navigationService.getChildrenForSelectedNode();

  constructor(
    protected routingService: RoutingService,
    protected navigationService: MtNavigationService
  ) { }

  readonly model$: Observable<MtNavigationNode[]> = using(
    () => this.selectNavNodeByrouting$.subscribe(),
    () => this.currectSelectedNode$
  ).pipe(shareReplay({ bufferSize: 1, refCount: true }))

  loadNavigationNodes(data?: CmsNavigationNode, selectedNodeCode?: string) {
    this.navigationService.loadNavigationNodes(data, selectedNodeCode);
  }

  getCurrentNode(): Observable<MtNavigationNode> {
    return this.navigationService.getSelectedNode();
  }

  getParentForSelectedNode(): Observable<MtNavigationNode> {
    return this.navigationService.getParentForSelectedNode()
  }

  getChildrenForSelectedNode(): Observable<MtNavigationNode[]> {
    return this.navigationService.getChildrenForSelectedNode();
  }

}

