import { Injectable } from '@angular/core';
import { CountryContextService } from '../../core/country-site-context/country-context.service';
import { AuthService, CurrencyService, LanguageService, WindowRef } from '@spartacus/core';
import { Observable, combineLatest, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MtPageDataService {

  constructor(
    protected countryContextService: CountryContextService,
    protected languageActive: LanguageService,
    protected currencyService: CurrencyService,
    protected authService: AuthService,
    protected winRef: WindowRef,
  ) { }

  getMtPageData(): Observable<MtPageData> {
    const pageData: Observable<MtPageData> =
      combineLatest([
        this.countryContextService.getActive(),
        this.languageActive.getActive(),
        this.currencyService.getActive(),
        this.authService.isUserLoggedIn(),
      ]).pipe(
        map(([country, language, currency, isLoggedIn]) => {
          const newData: MtPageData = {
            languageCode: language.split('_')[0],
            currentCountry: country.toLowerCase(),
            currency: currency,
            isLoggedin: isLoggedIn ? 'registered' : 'anonymous'
          };
          return newData;
        })
      );
    return pageData;
  }

  fireMtDataEvent(page: string, data: MtPageData) {
    if (this.winRef.nativeWindow) {
      this.winRef.nativeWindow.mt_page_data = JSON.parse(JSON.stringify(data));
      this.winRef.nativeWindow.dispatchEvent(new CustomEvent("mt-page-data-event",
        {
          bubbles: true,
          detail: {
            source: page,
            data: this.winRef.nativeWindow.mt_page_data
          }
        }
      ));
    }
  }
  
  fireMtDataEventIfNotEqual(page: string, data: MtPageData) {
    if (this.winRef.nativeWindow
          && JSON.stringify(data) != JSON.stringify(this.winRef.nativeWindow.mt_page_data)) {
            this.fireMtDataEvent(page, data);
    }
  }
}
