/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const checkout = {
  checkout: {
    backToCart: 'Back to cart',
    continue: 'Continue'
  },
  checkoutProgress: {
    label: 'Checkout Progress',
    deliveryAddress: 'Delivery Address',
    deliveryMode: 'Delivery Mode',
    paymentDetails: 'Payment Details',
    reviewOrder: 'Review Order',
  },
  cardActions: {
    setAsDefault: 'Set as default',
    remove: 'Remove',
    edit: 'Edit'
  },
  checkoutAddress: {
    newBillingAddress: 'New Billing Address',
    newDeliveryAddress: 'New Delivery Address',
    editDeliveryAddress: 'Edit Delivery Address',
    editBillingAddress: 'Edit Billing Address',
    deliveryAddress: 'Delivery Address',
    billingAddress: 'Billing Address',
    selectYourDeliveryAddress: 'Select your Delivery Address',
    defaultDeliveryAddress: 'Default Address',
    addNewAddress: 'New Address',
    shipToThisAddress: 'Ship to this address',
    deliveryAddressSelected: 'Delivery address selected',
    sameAsDeliveryAddress: 'Same as delivery address',
    guestDeliveryAddress: {
      title: {
        perosnalInfo: 'Personal Information',
        address: 'Delivery Address',
      },
      form: {
        titleCode: {
          label: 'Title*',
          placeholder: 'Required field',
        },
        firstName: {
          label: 'First Name*',
          placeholder: 'Required field',
        },
        lastName: {
          label: 'Last Name*',
          placeholder: 'Required field',
        },
        email: {
          label: 'Email Address*',
          placeholder: 'Required field',
        },
        company1: {
          label: 'Company name*',
          placeholder: 'Required field',
        },
        company2: {
          label: 'Company name 2',
          placeholder: '',
        },
        company3: {
          label: 'Company name 3',
          placeholder: '',
        },
        poNumber:{
          label: "PO Number",
          placeholder: ""
        },
        country: {
          label: 'Country*',
          placeholder: 'Required field',
          hint: 'This is your country. If you need to register your account with a different country, please switch to the corresponding METTLER TOLEDO website using the country selection near the top of this page.',
        },
        addressLine1: {
          label: 'Address 1*',
          placeholder: 'Required field',
        },
        addressLine2: {
          label: 'Address 2',
          placeholder: '',
        },
        addressLine3: {
          label: 'Address 3',
          placeholder: '',
        },
        postalCode: {
          label: 'Postal code*',
          placeholder: 'Required field',
        },
        state: {
          label: 'State*',
          placeholder: 'Required field',
        },
        city: {
          label: 'City*',
          placeholder: 'Required field',
        },
      },
      actions: {
        backToCart: 'Back To Cart',
        continue: 'Continue'
      }
    }
  },
  checkoutMode: {
    button: {
      back: 'Back',
      continue: 'Continue'
    },
    deliveryMethod: {
      title: 'Delivery Option',
      controls: {
        label: 'Select'
      }
    },
    requestDeliveryDate: {
      title: 'Request Delivery Date',
      controls: {
        optionDeliveryAsFastAsPosible: 'Delivery as fast as posible',
        optionDeliveryOnSpecificDate: 'Delivery on specific date',
        selectDate: 'Select a date*'
      }
    },
    consolidatedDelivery: {
      title: 'Consolidated Delivery',
      controls: {
        optionYes: 'Yes',
        optionNo: 'No',
        infoText: 'We will make every attempt to consolidate your delivery if possible.',
      }
    },
    deliveryPayment: {
      title: 'Delivery Payment',
      controls: {
        optionPrepaid: 'Prepaid',
        optionCollect: 'Collect',
        collectNumber: 'Collect Number*'
      },
      formErrors: {
        required: 'Required field',
        validate: 'Collect # is not valid'
      }
    }
  },
  checkoutReview: {
    review: {
      title: 'Order Summary',
      labels: {
        deliveryAddress: 'Delivery Address',
        deliveryOption: 'Delivery Option',
        requestedDeliveryDate: 'Requested Delivery Date',
        deliverAsFastAsPossible: 'Deliver as fast as possible',
        consolidatedDelivery: {
          title: 'Consolidated Delivery',
          yes: 'Yes',
          no: 'No',
        },
        deliveryPayment: {
          title: 'Delivery Payment',
          collect: 'Collect',
          prepaid: 'Prepaid'
        },
        billingAddress: 'Billing Address',
        paymentType: 'Payment Type',
        purchaseOrderNumber: {
          title: 'Purchase Order Number'
        },
        empty: 'Not Specified'
      }
    },
    orderItems: {
      title: 'Products',
      labels: {
        materialID: 'Material #',
        quantity: 'Qty',
        itemStandardPrice: 'Item Standard Price',
        itemNetPrice: 'Item Net Price',
        totalNetPrice: 'Total Net Price'
      }
    },
    orderSummary: {
      title: 'Order Summary',
      quantity: 'Quantity:',
      total: 'Total',
      discounts: 'Discounts:',
      surchargeFreight: 'Surcharge & Freight:',
      yourNetPrice: 'Your Net Price:',
      salesTax: 'Sales Tax',
      infoMessage: 'Final shipping & handling charges will be available in the order invoice.',
    },
    confirmThatRead: 'I have read and agree with the',
    termsAndConditions: 'Terms and Conditions.*',
    confirmThatRead2: '',
    placeOrder: 'Place Order',
    payPlaceOrder: 'Pay and Place Order',
    editDeliveryAddressDetails:
      'Edit delivery address details, opens Delivery Address page',
    editPaymentDetails: 'Edit payment details, opens Payment Details page',
    editPaymentType: 'Edit payment method, opens Method of Payment page',
    editDeliveryMode: 'Edit delivery mode, opens Delivery Mode page',
    orderInProcess: 'Order is in process. Please wait.',
    paymentError: 'There is a problem with your payment authorization. Please try again or contact customer service.',
    back: 'Back'
  },
  checkoutOrderConfirmation: {
    title: 'Thank you for your order!',
    info1: 'Your order number is <b>{{orderCode}}</b>.',
    info2: 'An order confirmation will be emailed to you.',
    btnLabel: 'Back to Shop',
    confirmationOfOrder: 'Confirmation of Order:',
    thankYou: 'Thank you for your order!',
    invoiceHasBeenSentByEmail:
      'An invoice has been sent by email. You should receive it soon.',
    orderItems: 'Order Items',
    orderPlacedSuccessfully: 'Order placed successfully',
    createAccount: 'Create an account?',
    createAccountForNext:
      'Create an account for <{{email}}> for a faster checkout on your next visit.',
  },
  checkoutPaymentDetails: {
    paymentDetails: 'Payment Details',
    phoneNumber: 'Phone Number',
    poNumber: 'Purchase Order Number',
    validateOrder: 'Validate Order',
    backButton: 'Back',
    cardPaymentInfo: 'After placing the order you will be redirected to the payment site to authorize your credit card.',
    poNumberInfo: 'If you do not have a purchase order number, you may use this field as a label for the order for your own reference purposes.',
    paymentType_CARD: 'Card Payment',
    paymentType_CREDIT:'Card Payment',
    paymentType_ACCOUNT: 'Invoice Payment',
    required: 'Required field',
    requiredPoNumber: 'Please enter a purchase order number'
  }
};
