<cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>

<form (ngSubmit)="onSubmit()" [formGroup]="form" class="mt-update-password-form">

  <h2 class="text--blue-color">
    {{ 'mtUserAccountAndLogin.updatePasswordForm.title' | cxTranslate }}
  </h2>

  <br/>
    
  <div class="form_field_wrapper">
    <label>
      <span class="label-content">{{
        'mtUserAccountAndLogin.updatePasswordForm.oldPassword.label' | cxTranslate
        }}</span>
      <input required="true" class="form-control" type="password" name="oldPassword" placeholder="{{
          'mtUserAccountAndLogin.updatePasswordForm.oldPassword.placeholder' | cxTranslate
        }}" formControlName="oldPassword" [attr.aria-label]="
          'mtUserAccountAndLogin.updatePasswordForm.oldPassword.placeholder' | cxTranslate
        " cxPasswordVisibilitySwitch />
      <cx-form-errors [control]="form.get('oldPassword')"></cx-form-errors>
    </label>
  </div>
    
  <div class="form_field_wrapper">
    <label>
      <span class="label-content">{{
        'mtUserAccountAndLogin.updatePasswordForm.newPassword.label' | cxTranslate
        }}</span>
      <input required="true" class="form-control" type="password" name="newPassword" placeholder="{{
          'mtUserAccountAndLogin.updatePasswordForm.newPassword.placeholder' | cxTranslate
        }}" formControlName="newPassword" [attr.aria-label]="
          'mtUserAccountAndLogin.updatePasswordForm.newPassword.placeholder' | cxTranslate
        " cxPasswordVisibilitySwitch />
      <cx-form-errors [control]="form.get('newPassword')"></cx-form-errors>
    </label>
  </div>
    
  <div class="form_field_wrapper">
    <label>
      <span class="label-content">{{
        'mtUserAccountAndLogin.updatePasswordForm.confirmPassword.label' | cxTranslate
        }}</span>
      <input required="true" class="form-control" type="password" name="newPasswordConfirm" placeholder="{{
          'mtUserAccountAndLogin.updatePasswordForm.confirmPassword.placeholder' | cxTranslate
        }}" formControlName="newPasswordConfirm" [attr.aria-label]="
          'mtUserAccountAndLogin.updatePasswordForm.confirmPassword.placeholder' | cxTranslate
        " cxPasswordVisibilitySwitch />
      <cx-form-errors [control]="form.get('newPasswordConfirm')"></cx-form-errors>
    </label>
  </div>

  <div class="form_btn-action-wrapper">
    <button class="btn btn-primary btn-block btn-large update-password-btn" type="submit" [disabled]="form.disabled">
      <span>{{ 'mtUserAccountAndLogin.updatePasswordForm.actions.save' | cxTranslate }}</span>
    </button>
  </div>

  <hr class="mt-separator update-password-separator">
</form>
