import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../../util/local-storage/local-storage.service';


@Component({
  selector: 'app-page-not-found-error-page',
  templateUrl: './page-not-found-error-page.component.html',
  styleUrls: ['./page-not-found-error-page.component.scss']
})
export class PageNotFoundErrorPageComponent implements OnInit {

  constructor(private router: Router, private localStorageService: LocalStorageService) {}

  ngOnInit(): void {
  }

  homePage() {
    this.router.navigate(['/home']);
  }

  contactUs() {
    const baseUrl = this.getBaseUrl();
    window.location.href = `${baseUrl}/home/site_content/contact_us.html`;
  }

  private getBaseUrl(): string {
    const { protocol, host } = window.location;
    const currentCountry = this.localStorageService.getItem('currentCountry') ?? 'us';
    const currentLanguageIso = this.localStorageService.getItem('currentLanguageIso') ?? 'en';
    return `${protocol}//${host}/${currentCountry}/${currentLanguageIso}`;
  }

}

