/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const cart = {
  cartDetails: {
    id: 'ID',
    proceedToCheckout: 'Passer la commande',
    cartName: 'Cart #{{code}}',
    cartPageTitle: 'Votre Panier',
    loginForCheckout: 'Connectez-vous pour commander',
    registerForCheckout: 'Enregistrer',
    checkoutAsAGuest: 'Commander en tant qu\'invité',
    invoice: 'Facture (uniquement pour les utilisateurs avec un compte professionnel enregistré)',
  },
  cartItems: {
    id: 'Numéro d\'article',
    description: 'Description',
    item: 'Item',
    itemPrice: 'Item price',
    quantity: 'Quantité',
    quantityTitle:
      'The quantity represents the total number of this item in your cart.',
    total: 'Total',
    actions: 'Actions',
    cartTotal: 'Cart total ({{count}} item)',
    cartTotal_other: 'Cart total ({{count}} items)',
    itemRemoved: 'Selected item has been removed. Cart total has been updated.',
    caption: 'Shopping cart contents.',
    itemStandardPrice: 'Prix standard de l\'article',
    itemNetPrice: 'Prix net de l\'article',
  },
  orderCost: {
    orderSummary: 'Résumé',
    subtotal: 'Subtotal after discounts:',
    shipping: 'Shipping:',
    estimatedShipping: 'Estimated shipping:',
    discount: 'You saved:',
    salesTax: 'Sales Tax:',
    grossTax: 'The order total does not include tax of',
    grossIncludeTax: 'The order total includes tax of',
    total: 'Total',
    toBeDetermined: 'TBD',
  },
  voucher: {
    coupon: 'Have a coupon?',
    coupon_other: 'Coupon codes',
    couponLabel: 'Enter a promo code here',
    apply: 'Vérifier',
    placeholder: 'Promo code',
    applyVoucherSuccess: '{{voucherCode}} has been applied.',
    removeVoucherSuccess: '{{voucherCode}} has been removed.',
    anchorLabel: 'Enter or remove your coupon code',
    vouchersApplied: 'Applied coupons',
    availableCoupons: 'Available coupons',
    availableCouponsLabel: 'You can add these coupons to this order.',
    enterVoucherCode: 'Entrez votre code de bon d\'achat',
    lessThanTreshold: 'La valeur minimale de commande applicable est de {{value}}.',
    valueDeducted: 'La réduction du bon a été déduite. Le montant final sera présenté dans le récapitulatif de la commande',
    voucherCode: 'Votre code de bon d\'achat',
    voucherCodeNotecognized: 'Code de bon d\'achat non reconnu. S\'il vous plaît, vérifiez et essayez à nouveau.',
    anotherCampaign: 'Vous ne pouvez pas utiliser le code promotionnel lorsque vous participez à une autre campagne.',
    voucherAlreadyUsed: 'Le code de bon a déjà été utilisé.',
    voucherGreater: 'La valeur de votre commande est inférieure à l\'avantage de votre bon. Vous perdrez la différence.',
    productSpecificCampaign: 'Aucun produit n\'est issu de la campagne de coupons spécifique au produit.',
    voucherBenefit: 'Bon de réduction',
  },
  saveForLaterItems: {
    itemTotal: 'Saved for later ({{count}} item)',
    itemTotal_other: 'Saved for later ({{count}} items)',
    cartTitle: 'Cart',
    saveForLater: 'Save For Later',
    moveToCart: 'Move To Cart',
    stock: 'Stock',
    forceInStock: 'In Stock',
  },
  clearCart: {
    clearCart: 'Clear Cart',
    clearingCart: 'Clearing Cart...',
    cartClearedSuccessfully: 'Active cart cleared successfully.',
    areYouSureToClearCart: 'Are you sure you want to clear this cart?',
    allItemsWillBeRemoved: 'All items in your active cart will be removed.',
  },
  validation: {
    cartEntriesChangeDuringCheckout:
      'During checkout we found problems with your entries. Please review your cart.',
    cartEntryRemoved:
      '{{name}} was removed from the cart due to being out of stock.',
    productOutOfStock:
      '{{name}} has been removed from the cart due to insufficient stock.',
    lowStock: 'Quantity has reduced to {{quantity}} due to insufficient stock.',
    reviewConfiguration:
      'Resolve the issues in the configuration for cart entry first.',
    pricingError:
      'Configurator pricing is currently not available. Checkout/quote submission is not possible. Please try again later.',
    unresolvableIssues:
      'The product configuration requires additional entries in the back end. As a result, you cannot proceed. Please contact support.',
    inProgress: 'In bewerking',
  },
  orderSummary: {
    infoMessage: 'Les frais d\'expédition et de manutention finaux seront disponibles sur la facture de la commande.',
    standardPrice: 'Prix standard:',
    discounts: 'Rabais:',
    yourNetPrice: 'Votre Prix Net:',
    quantity: 'Quantité:',
    voucherBenefit: 'Bon de réduction:',
    additionalInfo: 'Vous allez maintenant être redirigé vers le site de Paymetric afin dautoriser votre carte de crédit',
  },
  guestMessages: {
    pleaseLogin: 'Veuillez vous connecter si vous avez un compte en ligne avec nous pour accéder à des services supplémentaires.',
    createAccount: 'Créez un compte en ligne et enregistrez votre entreprise pour voir les tarifs spécifiques au compte professionnel et payer par facture.',
    guestCreditCardInfo: 'En tant qu\'invité, vous pouvez payer par carte de crédit. Veuillez noter que l\'exonération fiscale ne s\'appliquera pas lors de la commande en tant qu\'invité.'
  }
};
