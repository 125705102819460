/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const mtUserAccountAndLogin = {

  loginForm: {

    labels: {
      loginTitle: 'Log in',
      signIn: 'Inloggen',
      register: 'Registreren',
      lookingCustomerPortal: 'Op zoek naar Customer Portal login?',
      clickHereLink: 'Klik hier',
      forgotPassword: 'Reset wachtwoord',
      keepMeSignedIn: 'Blijf ingelogd',
    },

    fields: {
      emailAddress: {
        label: 'E-mailadres',
        placeholder: 'Verplicht veld',
      },
      password: {
        label: 'Wachtwoord',
        placeholder: 'Verplicht veld',
      },
    },

    messages: {
      youCanLoginAccountActivated: "Your account has been activated. Now you can log in.",
      youCanLoginAccountReactivated: "Your account has been re-activated. You can now login"
    }

  },

  login: {
    labels: {
      myMTComLoginLink: 'myMT.com login',
      myMTComLoginDescription: 'Beheer uw online profiel en pas uw mt.com ervaring aan',
      customerPortalLoginLink: 'Customer Portal Login',
      customerPortalLoginDescription: 'Uw transactieplatform met een klantenportefeuille, toegang tot offertes en uw geïnstalleerde toestellen',

      myAccountLink: 'Mijn Account',
      logoutLink: 'Afmelden',
      customerPortalLink: 'Customer Portal'
    }
  },

  logout: {
    sessionDialog: {
      title: 'Sessie uitloggen',
      message: 'Je sessie is verlopen.',
      logoutCountryChangedMessage: 'U bent afgemeld omdat het land is gewijzigd.',
      button: 'OK'
    }
  },

  forgottenPassword: {
    resetPassword: 'Reset wachtwoord',
    emailAddress: {
      label: 'E-mailadres',
      placeholder: 'E-mailadres',
    },
    chooseNewPassword: {
      label: 'Kies wachtwoord',
      placeholder: '',
    },
    confirmNewPassword: {
      label: 'Geef nogmaals het wachtwoord',
      placeholder: '',
    },
    actions: {
      submit: 'Reset wachtwoord',
      cancel: 'Terug naar Inloggen',
      resendEmail: 'Email opnieuw verzenden',
      saveNewPassword: 'Opslaan'
    },
    passwordResetEmailSent:
      'Dankjewel voor je aanvraag! Er is een e-mail voor het opnieuw instellen van het wachtwoord verzonden naar [e-mailadres]. De link in de e-mail is {{email}}. De link in de e-mail is 30 minuten geldig. Geen e-mail ontvangen? Controleer de spammap.',

    passwordResetSuccess: 'Je wachtwoord is gereset. Log in om toegang te krijgen tot uw account.',
  },

  updatePasswordForm: {
    title: 'Verander wachtwoord',
    oldPassword: {
      label: 'Oud Wachtwoord',
      placeholder: 'Verplicht veld',
    },
    newPassword: {
      label: 'Nieuw paswoord',
      placeholder: 'Verplicht veld',
    },
    confirmPassword: {
      label: 'Bevestig nieuw wachtwoord',
      placeholder: 'Verplicht veld',
    },
    actions: {
      save: "Opslaan"
    },
    passwordUpdateSuccess: 'Je wachtwoord is gewijzigd',
  },

  updateProfileForm: {
    personalData: 'Profiel Informatie',
    none: '',
    title: 'Titel*',
    firstName: {
      label: 'Voornaam*',
      placeholder: 'Verplicht veld',
    },
    lastName: {
      label: 'Achternaam*',
      placeholder: 'Verplicht veld',
    },
    customerId: 'E-mailadres*',
    actions: {
      save: "Opslaan",
    },
  },

  deactivateAccount: {
    label: 'Account deactiveren',
    buttonLabel: 'Account deactiveren',
    popup: 'Weet je zeker dat je je account wilt deactiveren? Als je doorgaat, heb je niet langer een actief mt.com-account. Uw gedeelde Customer Portal-account wordt ook gedeactiveerd.',
    cancel: 'Annuleren',
  },

  reactivateAccount: {
    infoBox1: 'Je account is inactief. ',
    infoBox2: 'Klik hier',
    infoBox3: ' om uw account opnieuw te activeren.',
    title: 'Account opnieuw activeren',
    email: {
      label: 'E-mailadres',
    },
    submit: 'Bevestigen',
    backToLogin: 'Terug naar Inloggen',
    successMessage: 'We hebben je een e-mail gestuurd met een link om je account te activeren. U kunt pas inloggen als u uw account he',
  },

  soldtoSelection: {
    selectAccount: 'Uw bedrijfsaccounts',
    mtNumber: 'MT Rekeningnummer:',
    accountSelected:'Geselecteerd bedrijfsaccount',
    otherAccounts:'Andere beschikbare bedrijfsaccounts',
    pickAccount:'Kies een ander bedrijfsaccount voor uw sessie.',
    noAccounts:'Er zijn geen andere bedrijfsaccounts beschikbaar.',
  },

  myAccount: {
    tiles: {
      myAccount: 'Mijn Account',
      myProfile: 'Mijn profiel',
      newsFeed: 'Persoonlijk overzicht',
      personalLibrary: 'Persoonlijke bibliotheek',
      billingAddresses: 'Facturering adressen',
      shippingAddresses: 'Verzendadressen',
      myOrders: 'Mijn bestellingen',
      newsletterSubscriptions: 'Inschrijvingen nieuwsbrief',
      productRegister: 'Productregistratie',
    },

    news: {
      viewMore: 'Bekijk alles'
    }
  },

  ordersRedirect: {
    confirmation: 'Bevestiging',
    dialogInfo: 'U wordt doorgestuurd naar het Customer Portal van METTLER TOLEDO.',
    redirect: 'OK',
  },

};

export const mtUserAccount = {
  mtUserAccountAndLogin,
};
