export const pageNotFoundTranslations = {
    labels: {
        backToShop: 'Terug naar de startpagina van de winkel',
        contactUs: 'Neem contact op',
        pageNotFound: 'Pagina niet gevonden',
    }
}

export const mtPageNotFoundTranslations = {
    pageNotFoundTranslations
}