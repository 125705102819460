import { Component, Input, OnInit } from '@angular/core';
import { CmsParagraphComponent, CmsService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'mt-product-list-no-results',
  templateUrl: './mt-product-list-no-results.component.html',
  styleUrls: ['./mt-product-list-no-results.component.scss']
})
export class MtProductListNoResultsComponent implements OnInit {

  @Input()
  cmsNoSearchResultsComponentUid: string | undefined;

  component$: Observable<CmsParagraphComponent> = new Observable();

  constructor(
    private cmsService: CmsService
  ) {
  }

  ngOnInit(): void {
    this.component$ =
      this.cmsService.getComponentData(this.cmsNoSearchResultsComponentUid ?? 'mtNoSearchResultsParagraph');
  }

}
