export const mtProductDetailsTab = {
  texts: {
    browseProductFamily: 'Parcourir par famille de produits',
    productSpecificationsTitle: 'Spécifications du produit',
    functionalSpecificationsTitle: 'Spécifications logistiques',
    featuresHeadline:'Caractéristiques',
    descHeadline:'Description',
    plantData: {
      countryOfOrigin: 'Country of origin',
      customsTariffNumber: 'Customs tariff number',
      hazardousMaterialNumber: 'Hazardous material number'
    }
  }
}

export const mtProductInfo = {
  labels: {
    showMore: 'Voir plus'
  }
}


export const mtProductRecommendations = {
  title: 'Souvent acheté avec'
}

export const mtProductDetailsResource = {
  mtProductDetailsTab, mtProductRecommendations, mtProductInfo
}
