import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MtProductAtpService } from '../../../product-availability/services/mt-product-atp.service';

@Component({
  selector: 'mt-quantity-picker',
  templateUrl: './quantity-picker.component.html',
  styleUrls: ['./quantity-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuantityPickerComponent {

  @Input() quantity: number = 1;
  @Output() quantityChange: EventEmitter<number> = new EventEmitter();

  @ViewChild('quantityInput') quantityInput?: ElementRef<HTMLInputElement>;

  constructor(private mtProductAtpService:MtProductAtpService) { }

  addItemToCart() {
    if (this.quantity < 999) {
      this.onQuantityChanged(this.quantity + 1);
    }
  }

  removeItemFromCart() {
    if (this.quantity > 1) {
      this.onQuantityChanged(this.quantity - 1);
    }
  }

  onQuantityChanged(newQuatity: number) {
    console.log(`onQuantityChanged = ${this.quantity} - ${newQuatity}`)
    if (newQuatity < 0) {
      newQuatity = 0;
    } else if (newQuatity >= 999) {
      newQuatity = 999;
    }
    if (this.quantityInput) {
      this.quantityInput.nativeElement.value = String(newQuatity);
    }
    this.quantity = newQuatity;
    this.quantityChange.emit(this.quantity);
    this.mtProductAtpService.qtyChangeEvent(this.quantity);
  }


}
