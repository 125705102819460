import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { ActiveCartFacade, Cart, CartRemoveEntrySuccessEvent, CartUpdateEntrySuccessEvent } from '@spartacus/cart/base/root';
import { Observable, Subject, combineLatest, forkJoin, of } from 'rxjs';
import { MtVoucherConnector } from '../../../checkout/occ/mt-voucher.connector';
import { CurrencyService, EventService, OCC_USER_ID_ANONYMOUS, TranslationService, UserIdService } from '@spartacus/core';
import { filter, map, mapTo, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { MtSoldToService } from '../../../user/account/core/mt-sold-to.service';
import { ICON_TYPE } from '@spartacus/storefront';
import { MtPriceService } from '../../../product/product-price';
import { CartVoucherService } from '@spartacus/cart/base/core';
import { LocalStorageService } from '../../../util/local-storage/local-storage.service';

@Component({
  selector: 'mt-cart-voucher',
  templateUrl: './mt-cart-voucher.component.html',
  styleUrls: ['./mt-cart-voucher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MtCartApplyVoucherComponent implements OnDestroy {
  CAMPAIGN_ALREADY_EXISTS = "V1_CAMPAIGN_EXISTS";
  VOUCHER_INVALID = "V2_VOUCHER_INVALID";
  VOUCHER_VALUE_GREATER = "V3_VOUCHER_GREATER";
  VOUCHER_DATE_RANGE_ISSUE = "V4_VOUCHER_INVALID_DATE";
  PRODUCT_VOUCHER_NOT_IN_CART = "V5_NO_PRODUCT_FROM_PRODUCT_SEPCIFIC_VOUCHER_CAMPAIGN";
  VOUCHER_DOES_NOT_EXIST_ERROR_CODE = "080";
  VOUCHER_ALREADY_USED_ERROR_CODE = "083";
  VOUCHER_VALID = "081";


  iconTypes = ICON_TYPE;
  cart$: Observable<Cart> | undefined;
  voucherCode: string | undefined;
  alertType$: Observable<string> | undefined = of('');
  cart: Cart | undefined = undefined;
  userId: string = "";
  guestCart: boolean = true;
  message$: Observable<string> | undefined;
  currencyIso: string = "USD";

  private ngUnsubscribe = new Subject<void>();

  constructor (protected activeCartFacade: ActiveCartFacade,
              protected userIdService: UserIdService,
              protected translation: TranslationService,
              protected mtVoucherConnector: MtVoucherConnector,
              protected soldToService: MtSoldToService,
              protected cartVoucherService: CartVoucherService,
              protected localStorageService: LocalStorageService,
              protected currencyService: CurrencyService,
              private cdr: ChangeDetectorRef,
              private events: EventService
              )
  {
    this.cart$ = this.activeCartFacade.getActive();
    this.cart$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(val => {
      this.cart = val;
    });
    this.activeCartFacade.isGuestCart().pipe(takeUntil(this.ngUnsubscribe)).subscribe(val => {this.guestCart = val});
    this.userIdService.getUserId().pipe(takeUntil(this.ngUnsubscribe)).subscribe(val => {this.userId = val});
    this.currencyService.getActive().pipe(takeUntil(this.ngUnsubscribe)).subscribe(val => {this.currencyIso = val});
    this.events.get(CartRemoveEntrySuccessEvent).pipe(
      takeUntil(this.ngUnsubscribe),
      switchMap((event) =>
          this.activeCartFacade.isStable().pipe(filter(Boolean), mapTo(event))
      ),
      withLatestFrom(this.activeCartFacade.getActive()),
      map(([event, cart]) => ({...event, cart}))).subscribe((event) =>
      {
        if ((event.cart?.totalUnitCount ?? 0) == 0) {
          this.mtVoucherConnector.removeVoucher(this.guestCart ? (this.cart?.guid ?? '') : (this.cart?.code ?? ''), this.userId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result) => {
              console.log("Voucher deleted", result);
            });
        }
      });
  }

  validateVoucher() {
    this.alertType$ = of('');
    this.message$ = of("");
    this.cdr.markForCheck();
    this.mtVoucherConnector.validateVoucher(this.voucherCode ?? '', this.guestCart ? (this.cart?.guid ?? '') : (this.cart?.code ?? ''), this.userId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        if (result != null && result != undefined) {
          switch (result.resultCode)
          {
            case this.VOUCHER_VALUE_GREATER:
              this.message$ = this.translation.translate("voucher.lessThanTreshold", { value: this.formatPrice(result.minimumThresholdValue ?? 0)});
              this.alertType$ = of('error');
              this.cdr.markForCheck();
              break;
            case this.CAMPAIGN_ALREADY_EXISTS:
              this.message$ = this.translation.translate("voucher.anotherCampaign");
              this.alertType$ = of('error');
              this.cdr.markForCheck();
              break;
            case this.PRODUCT_VOUCHER_NOT_IN_CART:
              this.message$ = this.translation.translate("voucher.productSpecificCampaign");
              this.alertType$ = of('error');
              this.cdr.markForCheck();
              break;
            case this.VOUCHER_INVALID:
            case this.VOUCHER_DOES_NOT_EXIST_ERROR_CODE:
              this.message$ = this.translation.translate("voucher.voucherCodeNotecognized");
              this.alertType$ = of('error');
              this.cdr.markForCheck();
              break;
            case this.VOUCHER_DATE_RANGE_ISSUE:
              this.message$ = of(this.VOUCHER_DATE_RANGE_ISSUE);
              this.alertType$ = of('error');
              this.cdr.markForCheck();
              break;
            case this.VOUCHER_ALREADY_USED_ERROR_CODE:
              this.message$ = this.translation.translate("voucher.voucherAlreadyUsed");
              this.alertType$ = of('error');
              this.cdr.markForCheck();
              break;
            case this.VOUCHER_VALID:
            case "":
              this.voucherCode = "";

              this.message$ = this.translation.translate("voucher.valueDeducted");
              this.alertType$ = of('success');

              this.activeCartFacade.reloadActiveCart();
              this.cdr.markForCheck();
              break;
          }
        } else {
          console.log("Voucher validation unknown error");
        }
    });
  }

  removeVoucher() {
    this.mtVoucherConnector.removeVoucher(this.guestCart ? (this.cart?.guid ?? '') : (this.cart?.code ?? ''), this.userId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.activeCartFacade.reloadActiveCart();
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private formatPrice(price: number): string {
    let currentCountry = this.localStorageService.getItem('currentCountry') ?? 'us'; // Default to 'en-US' if not available
    if (currentCountry === "be") currentCountry='fr';
    else if (currentCountry === "nl") currentCountry='en';
    if(currentCountry === "ca"){
      const formattedPrice = Number(price).toLocaleString('en', {style: 'currency', currency: 'CAD'}).replace(/^(\D+)/, 'CAD ');
      return `${formattedPrice}`;
    }else{
      const formattedPrice = Number(price).toLocaleString(currentCountry, { style: "currency", currency: this.currencyIso });
      return `${formattedPrice}`;
    }
  }

}
