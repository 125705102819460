import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideConfig } from '@spartacus/core';
import { AepModule } from '@spartacus/tracking/tms/aep';
import { BaseTmsModule, TmsConfig } from '@spartacus/tracking/tms/core';
import { GtmModule } from '@spartacus/tracking/tms/gtm';
import { environment } from 'src/environments/environment';
import { MtAddToCartEvent } from './events/mt-add-to-cart-event';
import { MtPurchaseEvent } from './events/mt-purchase-event';
import {MtProceedToCheckoutEvent} from "./events/mt-proceed-to-checkout-event";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BaseTmsModule.forRoot(),
    GtmModule,
    AepModule,
  ],
  providers: [
    provideConfig({
      tagManager: {
        gtm: {
          gtmId: environment.gtmId,
          events: [MtPurchaseEvent, MtAddToCartEvent],
        },
        aep: {
          scriptUrl: environment.adobeLunchScript, // TODO: validation of script on integrated env
          events: [MtPurchaseEvent, MtAddToCartEvent, MtProceedToCheckoutEvent]
        }
      }
    } as TmsConfig),
  ],
})
export class MtTmsModule { }
