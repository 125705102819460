import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import { Injectable, InjectionToken } from "@angular/core";
import { Cart, CART_NORMALIZER } from "@spartacus/cart/base/root";
import { OccCheckoutPaymentTypeAdapter } from "@spartacus/checkout/b2b/occ";
import { backOff, Converter, ConverterService, isJaloError, normalizeHttpError, OccEndpointsService } from "@spartacus/core";
import {Observable, of, throwError} from "rxjs";
import { catchError } from "rxjs/operators";
import { MtOrderSimulationResponseError } from "../../../model/mt-order-simulation-response-error.model";
import {MtOrderSimulationError} from "../../../model/mt-order-simulation-error.model";

export const ORDER_SIMULATION_NORMALIZER = new InjectionToken<Converter<any, MtOrderSimulationResponseError | Cart>>('OrderSimulationNormalizer');

@Injectable()
export class MtOccCheckoutPaymentTypeAdapter extends OccCheckoutPaymentTypeAdapter {

  constructor(
    protected override http: HttpClient,
    protected override occEndpoints: OccEndpointsService,
    protected override converter: ConverterService
  ) {
    super(http,occEndpoints,converter);
  }

  override setPaymentType(
    userId: string,
    cartId: string,
    paymentType: string,
    purchaseOrderNumber?: string,
    phoneNumber?: string
  ): Observable<Cart> {
    return this.http
      .put(
        this.getSetCartPaymentTypeEndpoint(
          userId,
          cartId,
          paymentType,
          purchaseOrderNumber,
          phoneNumber
        ),
        {}
      )
      .pipe(
        catchError((error) => throwError(normalizeHttpError(error))),
        backOff({ shouldRetry: isJaloError }),
        this.converter.pipeable(CART_NORMALIZER)
      );
  }

  private onErrorSimulationResponse(error: any) {
    return this.mapHttpErrorResponseToMtOrderSimulationResponseError(error);
  }

  override getSetCartPaymentTypeEndpoint(
    userId: string,
    cartId: string,
    paymentType: string,
    purchaseOrderNumber?: string,
    phoneNumber?: string
  ): string {
    const queryParams = { paymentType, purchaseOrderNumber, phoneNumber };
    return this.occEndpoints.buildUrl('setCartPaymentType', {
      urlParams: { userId, cartId },
      queryParams,
    });
  }

  getOrderSimulation(userId: string, cartId: string): Observable<MtOrderSimulationResponseError | Cart> {
    return this.http.get(
      this.setupOrderSimulationCall(
        userId,
        cartId
      ),
      {}
    )
    .pipe(
      catchError((error) => this.onErrorSimulationResponse(error)),
      backOff({ shouldRetry: isJaloError }),
      this.converter.pipeable(ORDER_SIMULATION_NORMALIZER)
    );
  }

  setupOrderSimulationCall(userId: string, cartId: string): string {
      return this.occEndpoints.buildUrl('orderSimulation', {
        urlParams: {userId, cartId}
      });
    }

  mapHttpErrorResponseToMtOrderSimulationResponseError(errorResponse: { error: { errors: { message: string; type: string }[] } }): Observable<MtOrderSimulationResponseError> {
    const { errors } = errorResponse.error;
    const simulationErrors: MtOrderSimulationError[] = errors.map(({ message, type }) => ({ message, type }));
    const error: MtOrderSimulationResponseError = { errors: simulationErrors };
    return of(error);
  }
}
