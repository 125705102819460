<input
  class="form-control"
  type="date"
  [formControl]="control"
  [attr.min]="min"
  [attr.max]="max"
  [attr.required]="required === false ? undefined : required"
  (change)="change()"
  [placeholder]="placeholder"
  [pattern]="pattern"
  onkeydown="return false;"
/>
<cx-form-errors
  [control]="control"
  prefix="formErrors.date"
  [translationParams]="{
    max: getDate(max) | cxDate,
    min: getDate(min) | cxDate
  }"
></cx-form-errors>
