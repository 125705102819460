import { Injectable, NgModule } from '@angular/core';
import { Store } from "@ngrx/store";
import { CartActions, StateWithMultiCart } from "@spartacus/cart/base/core";
import { MtCartOccService } from "./mt-cart-occ.service";

@Injectable({
  providedIn: 'root',
})
@NgModule({})
export class MtCartService {

  constructor(
    protected store: Store<StateWithMultiCart>,
    protected mtCartOccService: MtCartOccService,
  ) { }

  public loadCartFromBackend(soldTo: string) {
    this.mtCartOccService.loadCart(soldTo).subscribe(
      response => {
        console.log(`loadCartFromBackend - SoldTo: ${response.code}`)
        let payload = new CartActions.SetActiveCartId(response.code);
        this.store.dispatch(payload);
      },
      error => {
        console.error(`Unable to extract active cart for account: ${soldTo}; Error: ${JSON.stringify(error)}`);
        let payload = new CartActions.SetActiveCartId('');
        this.store.dispatch(payload);
      }
    )
  }
}
