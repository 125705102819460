/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  CartAdapter, CartEntryAdapter, CartValidationAdapter, CartVoucherAdapter
} from '@spartacus/cart/base/core';
import {
  OccCartEntryAdapter,
  OccCartNormalizer, OccCartValidationAdapter,
  OccCartVoucherAdapter,
  OrderEntryPromotionsNormalizer
} from "@spartacus/cart/base/occ";
import { CART_NORMALIZER, ORDER_ENTRY_PROMOTIONS_NORMALIZER } from "@spartacus/cart/base/root";
import { MtOccCartAdapter } from "./adapters/mt-occ-cart.adapter";

@NgModule({
  imports: [CommonModule],

  providers: [

    {
      provide: CartAdapter,
      useClass: MtOccCartAdapter
    },

    {
      provide: CART_NORMALIZER,
      useExisting: OccCartNormalizer,
      multi: true,
    },

    {
      provide: ORDER_ENTRY_PROMOTIONS_NORMALIZER,
      useExisting: OrderEntryPromotionsNormalizer,
      multi: true,
    },

    {
      provide: CartAdapter,
      useClass: MtOccCartAdapter,
    },

    {
      provide: CartEntryAdapter,
      useClass: OccCartEntryAdapter,
    },

    {
      provide: CartVoucherAdapter,
      useClass: OccCartVoucherAdapter,
    },

    {
      provide: CartValidationAdapter,
      useClass: OccCartValidationAdapter,
    },

  ],

})
export class MtCartBaseOccModule { }
