import { NgModule } from "@angular/core";
import { I18nConfig, OccConfig, provideConfig, provideDefaultConfigFactory } from "@spartacus/core";
import { mtProductDetailsTranslationsConfig, mtProductTranslations } from "../assets/translations/translations";

export const MT_PRODUCT_DETAILS = 'mtProductDetails';

export function defaultMtProductDetailsComponentsConfig() {
  const config = {
    featureModules: {
      [MT_PRODUCT_DETAILS]: {
        cmsComponents: [
          'ProductBreadcrumbComponent',
          'ProductIntroComponent',
          'ProductSummaryComponent',
          'ProductOrderComponent',
          'ZoovuComponent',
          'BottomActionsComponent',
          'ProductDetailsTabComponent',
          'ProductDocumentsTabComponent',
          'MtProductReferencesComponent',
          'ProductBadgesComponent',
          'ZoovuBannerComponent'
        ]
      },
    },
  };
  return config;
}

@NgModule({
  providers: [
    provideDefaultConfigFactory(defaultMtProductDetailsComponentsConfig),
    provideConfig(<OccConfig>{
      backend: {
        occ: {
          endpoints: {
            product: {
              default:
                'products/${productCode}?fields=DEFAULT,images(FULL),classifications,manufacturer,categories(FULL),cpv',
              details:
                'products/${productCode}?fields=stock(DEFAULT),description,code,url,price(DEFAULT),categories(FULL),images(FULL),benefits,technicalAttributes,cpv,plantData',
            },
            productReferences:
              'products/${productCode}/references?fields=DEFAULT,references(target(price(DEFAULT),images(FULL),cpv))&pageSize=10'
          }
        }
      }
    }),

    provideConfig(<I18nConfig>{
      i18n: {
        resources: mtProductTranslations,
        chunks: mtProductDetailsTranslationsConfig,
      }
    })],
})
export class MtProductDetailsRootModule { }
