import { Component, OnDestroy, OnInit } from '@angular/core';
import { CmsNavigationComponent, RoutingService } from '@spartacus/core';
import { CmsComponentData, NavigationService } from '@spartacus/storefront';
import { combineLatest, merge, Observable, Subject } from 'rxjs';
import { MtNavigationNode } from '../../model/mt-navigation-node.model';
import { MtCategoriesNavigationComponentService } from './mt-categories-navigation-component.service';
import { MtPageDataService } from '../../../seo/services/mt-page-data.service';
import { take, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'mt-categories-navigation',
  templateUrl: './mt-categories-navigation.component.html',
  styleUrls: ['./mt-categories-navigation.component.scss']
})
export class MtCategoriesNavigationComponent implements OnInit, OnDestroy {

  readonly model$ = this.mtCategoryNavigationComponentService.model$;

  notifier = new Subject();

  constructor(
    protected mtCategoryNavigationComponentService: MtCategoriesNavigationComponentService,
    protected routingService: RoutingService,
    protected componentData: CmsComponentData<CmsNavigationComponent>,
    protected service: NavigationService,
    protected mtPageDataService: MtPageDataService,
    protected route: ActivatedRoute
    ) {
  }

  ngOnInit(): void {

    combineLatest([
      this.componentData.data$,
      this.route.queryParams,
      this.routingService.getRouterState(),
      this.mtPageDataService.getMtPageData()
    ])
    .pipe(takeUntil(this.notifier))
    .subscribe(([data, params, state, mtPageData]) => {
      if (state.nextState == undefined) {
        mtPageData.pageName = "shopHomepage" + (state.state.queryParams?.["code"] ? ":" + state.state.queryParams?.["code"] : "");
        this.mtPageDataService.fireMtDataEventIfNotEqual("shopHomepage", mtPageData);
      }
    });

    combineLatest([
      this.componentData.data$,
      this.routingService.getRouterState()
    ]).pipe(takeUntil(this.notifier)).subscribe(
      ([data, routerState]) => {
        let nodeCode = undefined;
        if (routerState.state.queryParams?.["code"]) {
          nodeCode = routerState.state.queryParams["code"];
        }
        this.mtCategoryNavigationComponentService.loadNavigationNodes(data.navigationNode, nodeCode);
      }
    );
  }

  getCurrentNode(): Observable<MtNavigationNode> {
    return this.mtCategoryNavigationComponentService.getCurrentNode();
  }

  getParentForSelectedNode(): Observable<MtNavigationNode> {
    return this.mtCategoryNavigationComponentService.getParentForSelectedNode();
  }

  getChildrenForSelectedNode(): Observable<MtNavigationNode[]> {
    return this.mtCategoryNavigationComponentService.getChildrenForSelectedNode();
  }

  hasChildren(item: MtNavigationNode): boolean {
    return item.children.length > 0;
  }

  escapeURLParam(item: MtNavigationNode) {
    return item.title?.toLowerCase().trim().replace(/\s+/gi, '-')
  }

  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }

}
