import { Inject, Injectable } from "@angular/core";
import { Breadcrumb, FacetValue, resolveApplicable } from "@spartacus/core";
import { FacetService, ProductFacetService } from "@spartacus/storefront";
import { SearchParamsParserStrategy } from "../strategies/search-params-parser.strategy";
import { MtProductFacetService } from "./mt-product-facet.service";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MtFacetService extends FacetService {
  private isPopupOpenSubject = new BehaviorSubject<boolean>(false);
  private categoriesFilter: string = "BUY_ONLINE#PAGE_CATEGORY";
  private _categoryCode: string | undefined;

  protected getStrategy(): SearchParamsParserStrategy | undefined {
    return resolveApplicable(this.searchParamsParserStrategies, []);
  }

  constructor(
    protected override productFacetService: ProductFacetService,

    @Inject(SearchParamsParserStrategy)
    protected searchParamsParserStrategies: SearchParamsParserStrategy[]

  ) {
    super(productFacetService);
  }

  public get categoryCode(): string | undefined {
    return this._categoryCode;
  }

  setupCategoryPage(categoryCode: string | undefined) {
    this._categoryCode = categoryCode;
    (this.productFacetService as MtProductFacetService).setupCategoryPageCode(categoryCode);
  }

  selectFacet(facetValue: FacetValue, href: string): string {
    //console.log(`facetValue = ${facetValue.name} - ${facetValue.selected} = ${JSON.stringify(facetValue.query)}`)
    return this.getStrategy()?.buildHashtagForQueryAndFacet(facetValue.query?.query?.value ?? "", this.categoriesFilter, href, this.categoryCode) ?? "";
  }

  clearFacet(breadcrumb: Breadcrumb, href: string): string {
    //console.log(`Clear facetValue = ${breadcrumb.facetName} - ${breadcrumb.facetCode} = ${JSON.stringify(breadcrumb.removeQuery)}`)
    return this.getStrategy()?.buildHashtagForQueryAndFacet(breadcrumb.removeQuery?.query?.value ?? "", this.categoriesFilter, href, this.categoryCode) ?? "";
  }

  clearAllFacets(href: string): string {
    return this.getStrategy()?.buildHashtagForClearAllFacets(this.categoriesFilter, href, this.categoryCode) ?? "";
  }

  togglePopup(): void {
    const currentState = this.isPopupOpenSubject.getValue();
    this.isPopupOpenSubject.next(!currentState);
    if (!currentState) {
      const headerLayer = document.getElementById('header_layer');
      const headerLayerWrapper = document.getElementById('header_layer_wrapper');
      if (headerLayer) {
        headerLayer.style.display = 'none';
      }
      if (headerLayerWrapper) {
        headerLayerWrapper.style.display = 'none';
      }
      
    const bottomLayers = document.getElementsByClassName('bottom');
    for (let i = 0; i < bottomLayers.length; i++) {
      const bottomLayer = bottomLayers[i] as HTMLElement;
      bottomLayer.style.display = 'none';
    }
    } 
  }

  isPopupOpen(): BehaviorSubject<boolean> {
    return this.isPopupOpenSubject;
  }
  
}
