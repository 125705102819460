/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GlobalMessageType, Priority
} from '@spartacus/core';
import { OrganizationUserRegistrationConflictHandler } from '@spartacus/organization/user-registration/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class MtOrganizationUserRegistrationConflictHandler extends OrganizationUserRegistrationConflictHandler {

  alreadyExistsError$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  override handleError(request: HttpRequest<any>, response: HttpErrorResponse) {
    if (request && this.getErrors(response)?.length && this.getErrors(response)[0].type != "AlreadyExistsError") {
      this.alreadyExistsError$.next(true);
      this.globalMessageService.add(
        { key: 'mtUserRegistrationForm.httpHandlers.conflict' },
        GlobalMessageType.MSG_TYPE_ERROR
      );

    }else {
      this.alreadyExistsError$.next(false);
    }
  }

  override getPriority(): Priority {
    return Priority.HIGH;
  }
}

