import {DIALOG_TYPE, LayoutConfig} from "@spartacus/storefront";
import {SessionLogoutDialogComponent} from "./session-logout-dialog.component";

export const sessionLogoutLayoutConfig: LayoutConfig = {
  launch: {
    SESSION_LOGOUT: {
      inlineRoot: true,
      component: SessionLogoutDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};
