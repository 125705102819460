<ng-container *ngIf="cartModifications$ | async as cartModifications">
  <ng-container *ngFor="let cartModification of cartModifications; let i=index">
    <div
      class="alert alert-danger"
      *ngIf="visibleWarnings[i] && cartModification.statusMessage !== 'Cart not found.'"
    >
      <span class="alert-icon">
        <cx-icon [type]="iconTypes.ERROR"></cx-icon>
      </span>
      <span>
        {{ cartModification.statusMessage }}
      </span>

      <button
        class="close"
        type="button"
        (click)="removeMessage(cartModification)"
      >
        <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
      </button>
    </div>
  </ng-container>
</ng-container>
