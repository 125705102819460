import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OccEndpointsService } from '@spartacus/core';

@Injectable()
export class MtSoldToSelectionOccService {

  constructor(private http: HttpClient,
            private occEndpoints: OccEndpointsService) { }

  loadSoldToList(): Observable<any> {
    return this.http.get(this.getEndpoint());
  }

  protected getEndpoint(): string {
    return this.occEndpoints.buildUrl('/orgUsers/current/soldTo');
  }
}
