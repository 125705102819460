/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { MtForgotPasswordComponentService } from './mt-forgot-password-component.service';

@Component({
  selector: 'mt-forgot-password',
  templateUrl: './mt-forgot-password.component.html',
  styleUrls: ['./mt-forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MtForgotPasswordComponent {

  constructor(protected service: MtForgotPasswordComponentService) { }


  form: UntypedFormGroup = this.service.form;
  isUpdating$: Observable<boolean> = this.service.isUpdating$;

  userEMail$: Observable<string> =
    this.service.userEMail$.asObservable();

  showResetPasswordForm$: Observable<boolean> =
    this.service.showResetPasswordForm$.asObservable();

  onSubmit(): void {
    this.service.requestEmail();
  }

  onResendEmail(): void {
    this.service.resendEmail();
  }
}
