import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConverterService, OccEndpointsService } from "@spartacus/core";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { MtVoucherModel } from "../model/mt-voucher.model";

@Injectable({ providedIn: 'root' })
export class MtVoucherAdapter  {

  private buildUrl(voucherCode: string, cartId: string, userId: string): string {
    const getEndpointUrl =
      this.occEndpoints.buildUrl('validateVoucher', {
        urlParams: {
          userId: userId,
          cartId: cartId
        },
        queryParams: {
          code: voucherCode
        }
      });
    console.log(getEndpointUrl);
    return getEndpointUrl;
  }

  private buildRemoveUrl(cartId: string, userId: string): string {
    const getEndpointUrl =
      this.occEndpoints.buildUrl('removeVoucher', {
        urlParams: {
          userId: userId,
          cartId: cartId
        }
      });
    console.log(getEndpointUrl);
    return getEndpointUrl;
  }

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) {
  }

  validateVoucher(voucherCode: string, cartId: string, userId: string): Observable<MtVoucherModel> {
    return this.http.get(this.buildUrl(voucherCode, cartId, userId)).pipe(
        catchError((response: HttpErrorResponse) => {
          console.error(`Voucher could not be validated: ${JSON.stringify(response)}`)
          return of();
        }),
        map((result: any) => {
          return result;
        }),
      );
  }

  removeVoucher(cartId: string, userId: string): Observable<any> {
    return this.http.delete(this.buildRemoveUrl(cartId, userId)).pipe(
        catchError((response: HttpErrorResponse) => {
          console.error(`Voucher could not be deleted: ${cartId}, ${userId}`)
          return of();
        }),
        map((result: any) => {
          return result;
        }),
      );
  }
}

