<div class="mt-product-card-container" *ngIf="product$ | async as currentProduct; loading">
  <div>
    <a *ngIf="product.images?.hasOwnProperty('PRIMARY')" [attr.aria-label]="
        !currentProduct.images?.hasOwnProperty('PRIMARY')
          ? ('productDetails.noProductImage'
            | cxTranslate: { product: currentProduct.name })
          : undefined
      " href="{{ getProductDetailsURL({ cxRoute: 'product', params: currentProduct }) }}"
      class="mt-product-image-container">
      <cx-media class="mt-product-image" [container]="getImage(currentProduct).PRIMARY" format="product"
        [alt]="(currentProduct.name || currentProduct.summary) ?? ''"></cx-media>
    </a>
    <a *ngIf="!currentProduct.images?.hasOwnProperty('PRIMARY')"
      href="{{ getProductDetailsURL({ cxRoute: 'product', params: currentProduct }) }}"
      class="mt-product-image-container">
      <span class="mt-product-no-image" style="display: flex; width: 200px;">
        <svg viewBox="0 0 515 515">
          <style type="text/css">
            .st0 {
              fill: #FFFFFF;
            }

            .st1 {
              fill: #D3D6DB;
            }
          </style>
          <rect y="0" class="st0" width="515" height="515" />
          <path class="st1"
            d="M352.6,198.7h-31.9v15.6h31.9V198.7z M279.3,293.6c-5.9,5.2-14.8,8.1-23,8.1c-22.2,0-39.3-17.8-39.3-40 c0-1.5,0-3.7,0-5.2L279.3,293.6z M296.3,261.7c0,2.2,0,5.2,0,5.9l-64.4-37.8c7.4-5.2,15.6-8.9,24.4-8.9 C278.6,221.7,296.3,240.2,296.3,261.7z M129.7,204.7v120c0,8.9,5.9,15.6,15.6,15.6h214.1l-65.9-37.8c-9.6,8.9-23,14.8-37.8,14.8 c-30.4,0-54.8-25.9-54.8-55.6c0-5.2,0-9.6,1.5-13.3L129.7,204.7z M115.6,170.6L406,341.7l5.2,2.2l-2.2,5.9l-2.2,2.2l-2.2,5.9 l-5.9-3.7L108.9,183.2l-5.9-3.7l3.7-5.2l1.5-2.2l3.7-5.9L115.6,170.6z M206.7,181.7l-1.5-9.6c-1.5-2.2-2.2-3.7-5.2-3.7h-15.6h-14.8 c-2.2,0-3.7,1.5-5.2,3.7l-2.2,9.6h-1.5h-11.9l68.1,40c9.6-9.6,24.4-15.6,39.3-15.6c31.9,0,55.6,25.2,55.6,55.6 c0,5.2-1.5,9.6-2.2,14.8l74.1,43.7V198.7c0-9.6-7.4-17-15.6-17h-15.6c-5.2-1.5-8.9-5.2-11.1-8.9l-2.2-3.7 c-3.7-5.9-11.9-11.1-18.5-11.1h-45.2c-7.4,0-15.6,5.2-19.3,11.1l-3.7,5.2c-2.2,2.2-5.9,5.9-9.6,7.4h-23h-11.1H206.7L206.7,181.7z" />
        </svg>
      </span>
    </a>
  </div>


  <div class="mt-product-card-content">

    <div class="mt-product-card-content-info-container">
      <div class="mt-product-card-content-info-text">
        <a [attr.aria-label]="
              !currentProduct.images?.hasOwnProperty('PRIMARY')
                ? ('productDetails.noProductImage'
                  | cxTranslate: { product: currentProduct.name })
                : undefined
            " href="{{ getProductDetailsURL({ cxRoute: 'product', params: currentProduct }) }}"
          class="mt-product-card-content-info-text-name">
          {{currentProduct.name}}
        </a>
        <span class="mt-product-card-content-info-text-summary" [innerHtml]="currentProduct.description">
          {{ currentProduct.description }}
        </span>
        <span class="mt-product-card-content-info-text-code">
          <span>Material #: </span>
          <span>{{currentProduct.code}}</span>
        </span>
      </div>
      <div class="mt-product-card-content-info-specs">
        <div class="mt-product-card-content-info-specs-row" *ngFor="let attr of getTechnicalAttributes()">
          <span class="mt-product-card-content-info-specs-tech-chr">{{attr.name}}</span>
          <span class="mt-product-card-content-info-specs-value">{{attr.values.join(',')}}</span>
        </div>
      </div>

    </div>

    <ng-container *ngIf="prices$ | async as prices; else loading">

      <div class="mt-product-card-content-login-container">
        <mt-standard-price [product]="currentProduct"></mt-standard-price>
      </div>

      <!-- Price, availability and buttons -->
      <div class="mt-product-card-content-total-container">

        <mt-customer-price [product]="currentProduct"></mt-customer-price>


        <mt-product-atp *ngIf="currentProduct.cpv !== getMtCPVStatusType.QUOTE" [product]="currentProduct"
          [quantity]="mtDefaultProductQuantity"></mt-product-atp>

        <div class="mt-product-total-actions">
          <div class="mt-product-actions-container">
            <div *ngIf="currentProduct.cpv === getMtCPVStatusType.QUOTE" class="mt-product-quote-wrapper-mobile">
              <mt-request-quotation-button [product]="currentProduct"></mt-request-quotation-button>
            </div>
            <div *ngIf="currentProduct.cpv === getMtCPVStatusType.SALESANDQUOTE"
              class="mt-product-quote-wrapper-mobile">
              <mt-request-quotation-button [product]="currentProduct"
                [isSecondaryDesign]="Boolean(currentProduct.price?.listPrice || currentProduct.price?.customerPrice)"></mt-request-quotation-button>
            </div>
            <div
              *ngIf="(currentProduct.price?.listPrice || currentProduct.price?.customerPrice) && (currentProduct.cpv === getMtCPVStatusType.SALES || currentProduct.cpv === getMtCPVStatusType.SALESANDQUOTE)"
              class="mt-product-add-to-cart-mobile">
              <mt-added-to-cart-button [product]="currentProduct"></mt-added-to-cart-button>
            </div>
          </div>
        </div>

      </div>
    </ng-container>

    <ng-template #loading>
      <div class="cx-dialog-row">
        <div class="col-sm-12">
          <div id="load"></div>
        </div>
      </div>
    </ng-template>

  </div>

</div>
