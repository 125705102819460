/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { mtUserRegistration } from './mt-user-registration.i18n';
import { mtOrgRegistration } from './mt-org-registration.i18n';
import {mtRegistrationConf} from "./mt-registration-conf.i18n";

export const en = {
  mtUserRegistration,
  mtOrgRegistration,
  mtRegistrationConf,
};
