<cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>

<form (ngSubmit)="onSubmit()" [formGroup]="form" class="mt-update-profile-form">

  <h2 class="text--blue-color">
      {{ 'mtUserAccountAndLogin.updateProfileForm.personalData' | cxTranslate }}
  </h2>

  <br/>

  <div class="form_field_wrapper">
    <label>
      <span class="label-content">{{
        'mtUserAccountAndLogin.updateProfileForm.title' | cxTranslate
      }}</span>
      <ng-select
        [clearable]="false"
        [searchable]="false"
        formControlName="titleCode"
        id="title-select"
        [cxNgSelectA11y]="{
          ariaLabel: 'mtUserAccountAndLogin.updateProfileForm.title' | cxTranslate
        }"
      >
        <ng-option *ngFor="let title of titles$ | async" [value]="title.code">{{
          title.name
        }}</ng-option>
      </ng-select>
    </label>
  </div>

  <div class="form_field_wrapper">
    <label>
      <span class="label-content">{{
        'mtUserAccountAndLogin.updateProfileForm.firstName.label' | cxTranslate
      }}</span>
      <input
        required="true"
        type="text"
        class="form-control"
        name="firstName"
        placeholder="{{
          'mtUserAccountAndLogin.updateProfileForm.firstName.placeholder' | cxTranslate
        }}"
        formControlName="firstName"
      />
      <cx-form-errors [control]="form.get('firstName')"></cx-form-errors>
    </label>
  </div>

  <div class="form_field_wrapper">
    <label>
      <span class="label-content">{{
        'mtUserAccountAndLogin.updateProfileForm.lastName.label' | cxTranslate
      }}</span>
      <input
        required="true"
        type="text"
        class="form-control"
        name="lastName"
        placeholder="{{ 'mtUserAccountAndLogin.updateProfileForm.lastName.placeholder' | cxTranslate }}"
        formControlName="lastName"
      />
      <cx-form-errors [control]="form.get('lastName')"></cx-form-errors>
    </label>
  </div>

  <div class="form_field_wrapper">
    <label>
      <span class="label-content">{{
        'mtUserAccountAndLogin.updateProfileForm.customerId' | cxTranslate
      }}</span>
      <input
        required="true"
        type="text"
        class="form-control"
        name="customerId"
        formControlName="customerId"
        readonly
      />
      <cx-form-errors [control]="form.get('customerId')"></cx-form-errors>
    </label>
  </div>

  <div class="form_btn-action-wrapper">
    <button class="btn btn-primary btn-block btn-large update-profile-btn" type="submit" [disabled]="form.disabled">
      <span>{{ 'mtUserAccountAndLogin.updateProfileForm.actions.save' | cxTranslate }}</span>
    </button>
  </div>

  <hr class="mt-separator update-profile-separator">
</form>
