import { Injectable, InjectionToken } from "@angular/core";
import { Converter } from "@spartacus/core";
import { MtDeliveryMethodModel } from "../model/mt-delivery-method.model";
import { MtOccDeliveryMethodModel } from "./mt-occ-delivery-method.model";


export const DELIVERY_METHOD_NORMALIZER = new InjectionToken<Converter<any, MtDeliveryMethodModel>>('MtDeliveryMethodNormalizer');


@Injectable({ providedIn: 'root' })
export class MtProductDocumentConverter implements Converter<MtOccDeliveryMethodModel, MtDeliveryMethodModel> {

  constructor() { }

  convert(source: MtOccDeliveryMethodModel, target?: MtDeliveryMethodModel | undefined): MtDeliveryMethodModel {

    target = {
      deliveryModeCode: source.deliveryMode.code,
      isDeliverAsFastAsPossible: source.deliverAsFastAsPossible,
      isDeliveryConsolidated: source.deliveryConsolidated,
      deliveryDate: source.deliveryDate,
      deliveryPaymentCode: source.deliveryPayment.code,
      collectNumber: source.collectNumber
    } as MtDeliveryMethodModel;

    return target || {} as MtDeliveryMethodModel;
  }


}

