/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CartValidationGuard } from '@spartacus/cart/base/core';
import { CartNotEmptyGuard, CheckoutAuthGuard } from '@spartacus/checkout/base/components';
import { CmsConfig, ConfigModule, I18nConfig, I18nModule, provideConfig, UrlModule } from '@spartacus/core';
import {
  AddressFormModule,
  CardModule, ListNavigationModule, PaginationModule,
  SpinnerModule
} from '@spartacus/storefront';
import { MtCartBaseModule } from 'src/app/spartacus/custom-module/cart/base/mt-cart-base.module';
import { MtAddressFormModule } from "../../../../user/account/components/address-form/mt-address-form.module";
import { mtCheckoutTranslationChunksConfig, mtCheckoutTranslations } from '../../assets/translations/translations';
import { MtCardModule } from "../card";
import { CheckoutGuestDeliveryAddressFormModule } from '../checkout-guest-delivery-address-form/checkout-guest-delivery-address-form.module';
import { MtCheckoutDeliveryAddressComponent } from './mt-checkout-delivery-address.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AddressFormModule,
    CardModule,
    SpinnerModule,
    I18nModule,
    UrlModule,
    ListNavigationModule,
    PaginationModule,
    ConfigModule.withConfig({
      pagination: {
        rangeCount: 5,
        addStart: true,
        addEnd: true,
        addNext: true,
        addPrevious: true
      }
    }),
    MtCardModule,
    MtAddressFormModule,
    CheckoutGuestDeliveryAddressFormModule,
    MtCartBaseModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutDeliveryAddress: {
          component: MtCheckoutDeliveryAddressComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard, CartValidationGuard],
        },
      },
    }),

    provideConfig(<I18nConfig>{
      i18n: {
        resources: mtCheckoutTranslations,
        chunks: mtCheckoutTranslationChunksConfig,
      }
    }),
  ],
  declarations: [MtCheckoutDeliveryAddressComponent],
  exports: [MtCheckoutDeliveryAddressComponent],
})
export class MtCheckoutDeliveryAddressModule { }
