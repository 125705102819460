import { Injectable } from "@angular/core";
import { LocalStorageService } from "../../util/local-storage/local-storage.service";
import { CountryMapBaseSiteIdContextConfig } from "./country-map-basesiteid-context.config";


@Injectable({ providedIn: "root" })
export class CountryBasesiteConvertor {

  constructor(
    protected config: CountryMapBaseSiteIdContextConfig,
    protected localStorageService: LocalStorageService
  ) { }

  countryToBaseSiteId(country: string): string {
    //console.log(`CountryBasesiteConvertor - country = ${country}`)
    return (this.config.countries && this.config.countries[country.toLocaleLowerCase()]) || (this.localStorageService.getItem("currentBaseSiteUid") || this.config.defaultSite);
  };

  baseSiteIdToCountry(baseSiteId: string): string {
    //console.log(`CountryBasesiteConvertor - baseSiteId = ${baseSiteId}`)
    return (this.config.sites && this.config.sites[baseSiteId]) || (this.localStorageService.getItem("currentCountry") || this.config.defaultCountry);
  }

}
