/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { NgModule } from '@angular/core';
import { provideConfig, RoutesConfig, RoutingConfig } from '@spartacus/core';
import { MtReactivateAccountService } from './account/core/mt-reactivate-account.service';
import { MtSoldToService } from "./account/core/mt-sold-to.service";
import {
  SessionLogoutDialogModule
} from "./auth/user-auth/components/session-logout/session-logout-dialog/session-logout.module";


@NgModule({
  exports: [
    MtSoldToService,
    MtReactivateAccountService
  ],
  imports: [
    SessionLogoutDialogModule,
  ],

  providers: [
    provideConfig(<RoutingConfig>{
      routing: {
        routes: {
          soldToSelect: { paths: ['login/soldToSelect'], },
        } as RoutesConfig
      }
    }),
  ]

})
export class MtUserModule {
}


