<section class="inner" *ngIf="(facetList$ | async)?.facets as facets else loading" 
  [attr.aria-label]="'productFacetNavigation.filterBy.facet' | cxTranslate"
  [cxFocus]="isDialog ? dialogFocusConfig : {}" [tabindex]="-1" (esc)="close($event)" (click)="block($event)">
  <div class="mt-facet-list" [ngClass]="{'hideFromMobile': (isPopupOpen$ | async)}">
    <div class="mid-block-content">
      <div class="content-top notOnScreen">
          <div class="mobile-filter">
            <div class="showTitle"> 
              <span>{{'mtProductItemtList.labels.filterButton' | cxTranslate}}</span></div> 
            <div (click)="closePopup()">
            <span aria-hidden="true">
              <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
            </span>
            </div>
          </div>
      </div>
      <div>
        <mt-active-facets [category]="category" class="displayFacetsInMobile"></mt-active-facets>
      </div>
      <div class = "content-mid">
        <mt-facet *ngFor="let facet of facets" #facetRef [category]="category" [facet]="facet"
        [class.expanded]="isExpanded(facet) | async" [class.collapsed]="isCollapsed(facet) | async"  role="group"
        attr.aria-label="{{
        'productFacetNavigation.ariaLabelItemsAvailable'
          | cxTranslate
            : {
                name: facet.name,
                count: facet?.values?.length
              }
      }}"></mt-facet>
      </div>
      <ng-container *ngIf="model$ | async as model; else loading">
      <div class="content-bottom notOnScreen">
        <span class="productfiltering_filters-clear" (click)="onClearFilters()">{{'mtProductItemtList.labels.filterClear' | cxTranslate}}</span>
        <span class="mobile-view-results-btn" (click)="closePopup()">{{'mtProductItemtList.labels.showResultsFound' | cxTranslate: {noFoundResults:
          model.productSearchPage?.pagination?.totalResults ?? 0} }}</span>
    </div>
      </ng-container>
    </div>
  </div>
</section>

<ng-template #loading>
  <div class="skeleton skeleton__productFilter">
    <div class="skeleton__productFilter__filterHeading" *ngFor="let i of [1, 2]">
      <div class="skeleton__productFilter__text"></div>
      <div class="skeleton__productFilter__arrow"><i class="ppUX2020_arrow"></i></div>
    </div>
  </div>
</ng-template>

