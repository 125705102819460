import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { OrderEntry } from '@spartacus/cart/base/root';
import { UserIdService } from "@spartacus/core";
import { Observable, of, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MtActiveCartService } from "../../../../cart/base/core/facade/mt-active-cart.service";
import { MtQuantityChangedEvent } from './mt-quantity-changed.event';

@Component({
  selector: 'mt-quantity-picker-shell',
  templateUrl: './quantity-picker-shell.component.html',
  styleUrls: ['./quantity-picker-shell.component.scss']
})
export class QuantityPickerShellComponent implements OnInit, OnDestroy {

  quantity: number = 0;
  @Input() index: number = -1;
  @Input() productCode: string = "";
  @Output() quantityChange: EventEmitter<MtQuantityChangedEvent> = new EventEmitter();

  isLoading$: Observable<boolean> =
    this.activeCartService.isStable().pipe(map((loaded: boolean) => !loaded));

  subscription: Subscription = new Subscription();

  constructor(
    private userIDService: UserIdService,
    private activeCartService: MtActiveCartService) { }

  ngOnInit(): void {

    // get entry quantity
    this.subscription.add(
      this.activeCartService.getLastEntry(this.productCode).pipe(
        catchError((err: any, caught: Observable<OrderEntry | undefined>) => {
          console.error(`getLastEntry Error: ${err}`)
          return of({
            quantity: 0
          });
        })
      ).subscribe((orderEntry: OrderEntry | undefined) => {
        this.quantity = orderEntry?.quantity ?? 0;
        this.quantityChange.emit({
          index: this.index,
          productCode: this.productCode,
          quantity: this.quantity
        } as MtQuantityChangedEvent);
      })
    );

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private updateEntry(entryNumber: number | undefined, quantity: number): void {
    this.userIDService.getUserId().subscribe(userId => {
      this.activeCartService.getActiveCartId().subscribe(cartId => {
        if (entryNumber !== undefined) {
          this.activeCartService.updateEntry(entryNumber, quantity);
        } else {
          if (this.productCode && this.quantity > 0) {
            this.activeCartService.addEntry(this.productCode, this.quantity);
          }
        }
      }).unsubscribe()
    }).unsubscribe()
  }

  onQuantityChanged(noUpdatedItems: number) {
    console.log(`noUpdatedItems = ${noUpdatedItems} - ${this.productCode}`)
    this.activeCartService.getLastEntry(this.productCode).pipe(
      tap((entry: OrderEntry | undefined) => {
        this.updateEntry(entry?.entryNumber, noUpdatedItems);
      })
    ).subscribe((_) => {
      this.quantityChange.emit({
        index: this.index,
        productCode: this.productCode,
        quantity: noUpdatedItems
      } as MtQuantityChangedEvent);
    }).unsubscribe();
  }

}
