<ng-container *ngIf="cart$ | async as cart">
  <div class="cart-voucher-component mt-global-message">
    <ng-container 
      *ngIf="alertType$ | async as alertType"
    >
      <div 
          *ngIf="alertType != 'success' || (cart.appliedVouchers?.[0]?.value ?? 0) > 0"
          class="alert voucher-alert d-table mb-3"
          [ngClass]="{ 'alert-warning': alertType === 'alert', 'alert-danger': alertType === 'error', 'alert-success': alertType === 'success' }"
          >
        <div class="d-table-row">
          <div class="d-table-cell alert-cell-left">
            <span class="alert-icon">
              <cx-icon [type]="alertType === 'alert' ? iconTypes.WARNING : (alertType === 'error' ? iconTypes.ERROR : iconTypes.SUCCESS)"></cx-icon>
            </span>
          </div>
          <div class="d-table-cell alert-cell-left pl-3">
            <span class="alert-label">
                <span *ngIf="message$ | async as message">{{ message }}</span>
              </span>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="cart.appliedVouchers?.length">
      <div 
          *ngIf="(cart.appliedVouchers?.[0]?.value ?? 0) > (cart.totalPriceWithTax?.value ?? 0)"
          class="alert alert-warning voucher-alert d-table">
        <div class="d-table-row">
          <div class="d-table-cell alert-cell-left">
            <cx-icon [type]="iconTypes.WARNING"></cx-icon>
          </div>
          <div class="d-table-cell alert-cell-left pl-3">{{
                "voucher.voucherGreater"
                | cxTranslate
            }}
          </div>
        </div>
      </div>
      <div class="alert alert-smoke-grey voucher-alert grey d-table">
        <div class="d-table-row">
          <div class="d-table-cell alert-cell-left">
            <div class="voucher-code-label">{{
                "voucher.voucherCode"
                | cxTranslate
                }}</div>
            <div>{{ cart.appliedVouchers?.[0]?.code }}</div>
          </div>
          <div class="d-table-cell alert-cell-right alert-icon">
            <cx-icon [type]="iconTypes.TRASH" (click)="removeVoucher()" class=""></cx-icon>
          </div>
        </div>
      </div>
    </ng-container>

    <form class="cart-voucher-form"
        *ngIf="!(cart.appliedVouchers?.length)">
      <div class="row">
        <div class="col-md-12 col-xl-12">
          <div class="form_field_wrapper">
            <div class="label">{{
              "voucher.enterVoucherCode"
              | cxTranslate
              }}</div>
            <div class="voucher-input-row">
              <div class="voucher-input-cell">
                <input required="false" class="form-control" type="text" name="voucherCode" [(ngModel)]="voucherCode" />
              </div>
              <button
                  id="validateVoucher"
                  class="btn-secondary btn-action btn-large"
                  (click)="validateVoucher()"
                  type="button"
                  >
                <span>
                  {{
                  "voucher.apply"
                  | cxTranslate
                  }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-container>
