import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import { Address, Price, Product, TranslationService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { MtPriceModel } from 'src/app/spartacus/custom-module/product/model/mt-price.model';
import { MtProduct } from 'src/app/spartacus/custom-module/product/model/mt-product.model';
import { MtCheckoutPaymentService } from '../../../core/facade/mt-checkout-payment.service';
import { MtDeliveryMethodModel } from '../../../model/mt-delivery-method.model';
import { MtCheckoutReviewSubmitComponentService } from './mt-checkout-review-submit-component.service';
import {ActiveCartFacade, Cart} from "@spartacus/cart/base/root";
import {CountryContextService} from "../../../../core/country-site-context/country-context.service";

@Component({
  selector: 'app-mt-checkout-review-submit',
  templateUrl: './mt-checkout-review-submit.component.html',
  styleUrls: ['./mt-checkout-review-submit.component.scss']
})
export class MtCheckoutReviewSubmitComponent implements OnInit {

  @ViewChild('topAnchor') topAnchor!: ElementRef;

  cart$ =
    this.checkoutReviewSubmitComponentService.cart$;

  entries$ =
    this.checkoutReviewSubmitComponentService.entries$;

  deliveryMethod$ =
    this.checkoutReviewSubmitComponentService.deliveryMethod$;

  loggedInUser$ =
    this.checkoutReviewSubmitComponentService.loggedInUser$;

  isRedirectToPaymetricError = this.mtCheckoutPaymentService.getPaymentError();

  isPaymentError: boolean = false;

  activeCart$ = this.activeCartFacade.getActive()

  country$ = this.countryService.getActive();

  constructor(
    private translationService: TranslationService,
    private activatedRoute: ActivatedRoute,
    private checkoutStepService: CheckoutStepService,
    private checkoutReviewSubmitComponentService: MtCheckoutReviewSubmitComponentService,
    private activeCartFacade : ActiveCartFacade,
    protected mtCheckoutPaymentService: MtCheckoutPaymentService,
    protected countryService: CountryContextService,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.isPaymentError = params['paymentError'];
  });
  }

  ngAfterViewInit() {
    if (this.topAnchor) {
      this.scrollToTop();
    }
  }

  ngOnInit(): void {
    this.cart$ = this.checkoutReviewSubmitComponentService.refreshCart();
  }

  back(): void {
    this.checkoutStepService.back(this.activatedRoute);
  }

  getImage(product?: Product) {
    return (product?.images !== undefined) ? product?.images['PRIMARY'] : undefined;
  }

  convertToMtProduct(product?: Product): MtProduct {
    return (product ?? {}) as MtProduct;
  }

  getPrice(product: MtProduct): MtPriceModel {
    return product.price as MtPriceModel;
  }

  getTotalPrice(price: Price): string {
    return price?.formattedValue ?? 'N/A';
  }
  formatAddress(address?: Address): string {
    return (address?.formattedAddress ?? '').replace(/(<br>)/gi, ',');
  }

  formatDeliveryDate(deliveryMethod: MtDeliveryMethodModel): Observable<string> {
    if (deliveryMethod.isDeliverAsFastAsPossible === false && deliveryMethod.deliveryDate !== undefined) {
      return of(deliveryMethod.deliveryDate.split('T')[0]);
    } else {
      return this.translationService.translate('checkoutReview.review.labels.deliverAsFastAsPossible');
    }
  }

  formatPurchaseOrderNumber(cart: Cart | null): Observable<string> {
    const collectNumber = cart?.purchaseOrderNumber
    if (collectNumber !== undefined && collectNumber !== "") {
      return of(collectNumber);
    } else {
      return this.translationService.translate('checkoutReview.review.labels.empty');
    }
  }

  formatConsolidatedDelivery(deliveryMethod: MtDeliveryMethodModel): Observable<string> {
    //console.log(`deliveryMethod = ${JSON.stringify(deliveryMethod)}`)
    if (deliveryMethod.isDeliveryConsolidated === true) {
      return this.translationService.translate('checkoutReview.review.labels.consolidatedDelivery.yes');
    } else {
      return this.translationService.translate('checkoutReview.review.labels.consolidatedDelivery.no');
    }
  }

  formatDeliveryPayment(deliveryMethod: MtDeliveryMethodModel): Observable<string> {
    return this.translationService.translate('checkoutReview.review.labels.deliveryPayment.' + deliveryMethod.deliveryPaymentCode);
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

}
