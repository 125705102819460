import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CurrencyContextService } from './currency-context.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    CurrencyContextService
  ]
})
export class CurrencySiteContextModule { }
