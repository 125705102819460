/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { translations } from "@spartacus/assets";
import {
  AuthGuard,
  CmsConfig, ConfigModule, I18nConfig,
  I18nModule, provideConfig,
  provideDefaultConfig,
  UrlModule
} from '@spartacus/core';
import { DIALOG_TYPE, PageSlotModule } from '@spartacus/storefront';
import {
  deTranslationOverwrites,
  enTranslationOverwrites,
  frTranslationOverwrites,
  nlTranslationOverwrites,
  itTranslationOverwrites
} from "../../../../../spartacus-configuration.module";
import { mtUserAccountTranslationChunksConfig, mtUserAccountTranslations } from "../../assets/translations/translations";
import { MtDeactivateAccountModalComponent } from "../deactivate-account-modal/mt-deactivate-account-modal.component";
import { MtDeactivateAccountComponent } from "./mt-deactivate-account.component";

@NgModule({
  declarations: [MtDeactivateAccountComponent],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    PageSlotModule,
    I18nModule,
    ConfigModule.withConfig({
      launch: {
        DEACTIVATE_ACCOUNT: {
          inline: true,
          component: MtDeactivateAccountModalComponent,
          dialogType: DIALOG_TYPE.POPOVER_CENTER_BACKDROP,
        }
      }
    })
  ],
  providers: [

    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        DeactivateAccountComponent: {
          component: MtDeactivateAccountComponent,
        },
        guards: [AuthGuard],
      },
    }),

    provideConfig({
      i18n: { resources: translations },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: mtUserAccountTranslations,
        chunks: mtUserAccountTranslationChunksConfig
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: {
          en: enTranslationOverwrites,
          de: deTranslationOverwrites,
          fr: frTranslationOverwrites,
          nl: nlTranslationOverwrites,
          it: itTranslationOverwrites
        },
      },
    }),


  ],
  exports: [MtDeactivateAccountComponent],
})
export class MtDeactivateAccountModule { }
