/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {GlobalMessageService, TranslationService} from '@spartacus/core';
import { User } from '@spartacus/user/account/root';
import {Title, UserProfileFacade} from '@spartacus/user/profile/root';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {MtCustomFormValidators} from "../../../../util/validators/mt-custom-form-validators";
import { MtForbiddenCharactersOccService } from 'src/app/spartacus/custom-module/util/core/mt-forbidden-characters-occ.service';


@Injectable()
export class MtUpdateProfileComponentService {
  //use default values in case translation service doesn't work
  private firstNameFieldTranslated : string = "First Name"
  private lastNameFieldTranslated : string = "Last Name"

  private ignoreLastAsteriskIfExists(input : string): string
  {
    return input[input.length - 1] === '*' ? input.slice(0, input.length - 1) : input
  }

  constructor(
    protected userProfile: UserProfileFacade,
    protected globalMessageService: GlobalMessageService,
    protected translationService: TranslationService,
    protected forbiddenCharactersService: MtForbiddenCharactersOccService
  ) {
    let firstNameFieldLabel$ = this.translationService.translate('mtUserAccountAndLogin.updateProfileForm.firstName.label')
    let lastNameFieldLabel$ = this.translationService.translate('mtUserAccountAndLogin.updateProfileForm.lastName.label')
    combineLatest(firstNameFieldLabel$,lastNameFieldLabel$).subscribe(
      it =>
      {
        this.firstNameFieldTranslated=this.ignoreLastAsteriskIfExists(it[0])
        this.lastNameFieldTranslated=this.ignoreLastAsteriskIfExists(it[1])
        this.form = this.createForm()
      }
    )
  }

  protected user$ = this.userProfile
    .get()
    .pipe(filter((user): user is User => Boolean(user)));

  protected busy$ = new BehaviorSubject(false);

  isUpdating$: Observable<boolean> = this.user$.pipe(
    tap((user) => this.form.patchValue(user)),
    switchMap((_user: User) => this.busy$),
    tap((state) => (state === true ? this.form.disable() : this.form.enable()))
  );

  titles$: Observable<Title[]> = this.userProfile.getTitles().pipe(
    map(titles =>
      titles.filter(title =>
        (title.code === 'mr' || title.code === 'ms')
      )
    )
  );

  private createForm() : UntypedFormGroup
  {
    return new UntypedFormGroup({
      customerId: new UntypedFormControl(''),
      titleCode: new UntypedFormControl('', Validators.required),
      firstName: new UntypedFormControl('', [Validators.required,
        MtCustomFormValidators.noSpecialCharactersRegPage(this.firstNameFieldTranslated, 40, this.forbiddenCharactersService),
        MtCustomFormValidators.maxLength(40, this.firstNameFieldTranslated)]),
      lastName: new UntypedFormControl('', [Validators.required,
        MtCustomFormValidators.noSpecialCharactersRegPage(this.lastNameFieldTranslated, 40, this.forbiddenCharactersService),
        MtCustomFormValidators.maxLength(40, this.firstNameFieldTranslated)]),
    });
  }

  form: UntypedFormGroup = this.createForm()

  /**
   * Updates the user's details and handles the UI.
   */
  updateProfile(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.busy$.next(true);

    this.userProfile.update(this.form.value).subscribe({
      next: () => this.onSuccess(),
      error: (error: Error) => this.onError(error),
    });
  }

  protected onSuccess(): void {
    console.log('Personal details successfully updated');
    this.busy$.next(false);
    this.form.reset();
  }

  protected onError(_error: Error): void {
    this.busy$.next(false);
  }
}
