import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { DIALOG_TYPE, IconModule, KeyboardFocusModule, SpinnerModule } from '@spartacus/storefront';
import { AemExpMtFormModule } from '../aem-exp-mt-form';
import { MtRequestQuotationButtonComponent } from './components/mt-request-quotation-button/mt-request-quotation-button.component';
import { MtRequestQuotationListener } from './listeners/mt-request-quotation.listener';


declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    REQUEST_QUOTATION = "REQUEST_QUOTATION"
  }
}

@NgModule({
  declarations: [
    MtRequestQuotationButtonComponent,
  ],
  imports: [
    CommonModule,
    SpinnerModule,
    IconModule,
    I18nModule,
    UrlModule,
    KeyboardFocusModule,

    AemExpMtFormModule,

  ],
  exports: [
    MtRequestQuotationButtonComponent,
  ]
})
export class ProductRequestQuoteModule { constructor(_mtRequestQuotationListener: MtRequestQuotationListener) { } }
