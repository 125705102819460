/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const mtUserRegistrationForm = {

  fields: {

    titleCode: {
      label: 'Titel*',
      placeholder: 'Verplicht veld',
    },
    firstName: {
      label: 'Voornaam*',
      placeholder: 'Verplicht veld',
    },
    lastName: {
      label: 'Achternaam*',
      placeholder: 'Verplicht veld',
    },
    email: {
      label: 'E-mailadres*',
      placeholder: 'Verplicht veld',
    },
    country: {
      label: 'Land*',
      placeholder: 'Verplicht veld',
      hint: 'Dit is jouw land. Als u uw account voor een ander land wilt registreren, gaat u naar de website voor dat land. U kunt het land wijzigen door op de vlag rechtsboven op de pagina te klikken.',
    },
    password: {
      label: 'Kies een wachtwoord*',
      placeholder: 'Verplicht veld',
    },
    confirmPassword: {
      label: 'Vul nogmaals uw wachtwoord in*',
      placeholder: 'Verplicht veld',
    }
  },

  labels:{
    createAccount: 'Nieuwe gebruiker registreren',

    goToLoginLabel: 'Heeft u al een account bij ons?',
    goToLoginLink: 'Inloggen',

    registerLabel: 'Registreer uw bedrijf om te profiteren van ons uitgebreide digitale aanbod',
    registerUser: 'Ik wil alleen een gebruikersaccount aanmaken',
    registerCompany: 'Ik wil een gebruikersaccount aanmaken en mijn bedrijf registreren',

    confirm1: 'Ik bevestig dat ik de ',
    confirm2: '  van \'My mt.com\' en de ',
    confirm3: ' heb gelezen en begrepen en ga akkoord met de voorwaarden.*',
    termsOfUse: 'Gebruiksvoorwaarden',
    privacyStatement: 'Privacybeleid',

    registerLabel1: 'Registreer uw bedrijf',
    registerLabel2: ' om te profiteren van ons uitgebreide digitale aanbod',
    registerInProgressLabel: 'We werken eraan om uw gebruiker aan uw bedrijfsaccount te koppelen. U wordt op de hoogte gebracht zodra het proces is voltooid.',

    selectedBusinessAccount: 'Geselecteerde zakelijke rekening',

    multipleLinkedProfile: 'Uw gebruikersprofiel is gekoppeld aan meerdere bedrijfsaccount.',
    changeSelection: 'U kunt uw selectie op elk moment wijzigen voor uw volgende transactie in "Mijn profiel"',
  },


  formSubmitButtonLabel: 'Account aanmaken',
  registerButtonLabel: 'Login/Register',
  httpHandlers: {
    conflict: '<div class="email-conflict-message"> Uw emailadres is al gelinkt aan een bestaand account. Probeer in te loggen via <a href="/shop/{{currentCountry}}/{{currentLanguageIso}}/login">login</a> of <a href="/shop/{{currentCountry}}/{{currentLanguageIso}}/login/forgot-password">reset</a> uw wachtwoord.</div>',
    wrongCountry: 'Het lijkt erop dat je een account hebt in een ander land. Wijzig het land om in te loggen of maak een account aan voor dit land.',
    invalidUsernameOrPassword: 'Uw gebruikersnaam of wachtwoord is onjuist, of uw account is nog niet geactiveerd. Controleer uw inbox of probeer het opnieuw.',
    unauthorized: 'U bent niet bevoegd om deze actie uit te voeren. Neem contact op met uw beheerder als u denkt dat dit een fout is.', //@todo missed translation
    errorMessage: 'Aanmelden mislukt. Neem dan contact op met de Klantenservice',
  },
};

export const mtUserRegistration = {
  mtUserRegistrationForm,
};
