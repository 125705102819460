import { HttpClient, HttpContext, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConverterService, OccEndpointsService, OCC_HTTP_TOKEN } from "@spartacus/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { MtProductAtpModel } from "../model/mt-product-atp.model";
import { PRODUCT_ATP_NORMALIZER } from "./product-atp.converter";


@Injectable()
export class ProductAtpAdapter {

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) {
  }

  getProductAtp(productCode: string, quantity: number): Observable<MtProductAtpModel> {
    const context = new HttpContext().set(OCC_HTTP_TOKEN, {
      sendUserIdAsHeader: true,
    });

    const url =
      this.occEndpoints.buildUrl('productAtp', {
        urlParams: {
          productCode: productCode
        },
        queryParams: {
          qty: quantity
        }
      });

    //return this.http.get(url, { context }).pipe(
    return this.http.get(url).pipe(
      catchError((response: HttpErrorResponse) => {
        console.error(
          `Product Atp cannot be obtained: ${JSON.stringify(response)}`)
        return of({});
      }),
      this.converter.pipeable(PRODUCT_ATP_NORMALIZER)
    )
  }

}