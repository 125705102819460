import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AuthRedirectService, RoutingService } from "@spartacus/core";
import {AddressBookComponentService, FocusConfig, ICON_TYPE, LaunchDialogService} from "@spartacus/storefront";
import { Subscription } from "rxjs";

@Component({
  selector: 'mt-remove-address',
  templateUrl: './mt-remove-address.component.html',
  styleUrls: ['./mt-remove-address.component.scss']
})
export class MtRemoveAddressComponent implements OnInit, OnDestroy {



  addressId: string  = '' ;
  addressType: string  = '' ;

  iconTypes = ICON_TYPE;

  clicked = false;

  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: 'button',
    focusOnEscape: true,
  };

  protected subscription = new Subscription();


  @HostListener('click', ['$event'])
  handleClick(event: UIEvent): void {
    if ((event.target as any).tagName === this.el.nativeElement.tagName) {
      this.dismissModal('Cross click');
    }
  }

  constructor(
    protected launchDialogService: LaunchDialogService,
    protected service: AddressBookComponentService,
    protected el: ElementRef
  ) {
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  dismissModal(reason?: any): void {
    this.launchDialogService.closeDialog(reason);
  }

  ngOnInit(): void {
    this.subscription.add(
      this.launchDialogService.data$.subscribe(
        (dialogData: { addressId: string, addressType: string}) => {
          this.addressId = dialogData.addressId;
          this.addressType = dialogData.addressType;
        }
      )
    )
  }

  confirmAddressRemoval() {
    console.log('remove address with id: ' + this.addressId, this.addressType)
    this.service.deleteUserAddress(this.addressId+'|'+this.addressType);
    this.launchDialogService.closeDialog('removed address with id: ' + this.addressId);
  }
}
