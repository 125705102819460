import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CartNotEmptyGuard, CheckoutAuthGuard } from '@spartacus/checkout/base/components';
import {
  CmsConfig,
  I18nModule, provideConfig, UrlModule
} from '@spartacus/core';
import { MtCheckoutStepsSetGuard } from '../../../guards/mt-checkout-steps-set.guard';

import { MtCheckoutProgressMobileTopComponent } from './mt-checkout-progress-mobile-top.component';

@NgModule({
  imports: [CommonModule, UrlModule, I18nModule, RouterModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutProgressMobileTop: {
          component: MtCheckoutProgressMobileTopComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard, MtCheckoutStepsSetGuard],
        },
      }
    })
  ],
  declarations: [MtCheckoutProgressMobileTopComponent],
  exports: [MtCheckoutProgressMobileTopComponent],
})
export class MtCheckoutProgressMobileTopModule { }
