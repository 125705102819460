/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CartCouponModule } from "@spartacus/cart/base/components";
import { AddToCartModule } from "@spartacus/cart/base/components/add-to-cart";
import { CartOutlets } from '@spartacus/cart/base/root';
import { CmsConfig, FeaturesConfigModule, I18nModule, provideConfig, UrlModule } from '@spartacus/core';
import {
  AtMessageModule,
  IconModule,
  ItemCounterModule,
  MediaModule,
  OutletModule,
  OutletPosition,
  PromotionsModule,
  provideOutlet
} from '@spartacus/storefront';
import { MtProductAtpModule } from "../../../product/product-availability/components/mt-product-atp";
import { ProductPriceModule } from "../../../product/product-price";
import { ProductQuantityPickerModule } from "../../../product/product-quantity-picker";
import { CartItemValidationWarningModule } from "../validation/cart-item-warning/cart-item-validation-warning.module";
import { MtCartValidationWarningsModule } from "../validation/cart-warnings/mt-cart-validation-warnings.module";
import { MtCartItemListRowComponent } from './cart-item-list-row/mt-cart-item-list-row.component';
import { MtCartItemListComponent } from './cart-item-list/mt-cart-item-list.component';
import { MtCheckoutOrderSummaryComponent } from './mt-checkout-order-summary/mt-checkout-order-summary.component';
import { MtOrderSummaryComponent } from './order-summary/mt-order-summary.component';

@NgModule({
  imports: [
    AtMessageModule,
    CartCouponModule,
    CartItemValidationWarningModule,
    MtCartValidationWarningsModule,
    CommonModule,
    FeaturesConfigModule,
    I18nModule,
    IconModule,
    ItemCounterModule,
    MediaModule,
    OutletModule,
    PromotionsModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    AddToCartModule,
    MtProductAtpModule,
    ProductQuantityPickerModule,
    ProductPriceModule,
  ],
  providers: [
    provideOutlet({
      id: CartOutlets.ORDER_SUMMARY,
      component: MtOrderSummaryComponent,
      position: OutletPosition.REPLACE
    }),
    provideOutlet({
      id: CartOutlets.CART_ITEM_LIST,
      component: MtCartItemListComponent,
    }),
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutOrderSummary: {
          component: MtCheckoutOrderSummaryComponent,
        },
      }
    })
  ],
  declarations: [
    MtOrderSummaryComponent,
    MtCartItemListComponent,
    MtCartItemListRowComponent,
    MtCheckoutOrderSummaryComponent,
  ],
  exports: [
    MtCartItemListRowComponent,
    MtCartItemListComponent,
    MtOrderSummaryComponent,
  ],
})
export class MtCartSharedModule { }
