<a #topAnchor></a>
<form class="guest-address-form" (ngSubmit)="verifyAddress()" [formGroup]="addressForm">
  <div class="row">
    <div class="col-md-12 col-xl-12">
      <h2 class="text--blue-color" *ngIf="!isBillingAddress">
        {{
        "checkoutAddress.guestDeliveryAddress.title.perosnalInfo"
        | cxTranslate
        }}
      </h2>

      <div class="form_field_wrapper" *ngIf="!isBillingAddress">
        <label>
          <span class="label-content required">{{
            "checkoutAddress.guestDeliveryAddress.form.titleCode.label"
            | cxTranslate
            }}</span>
          <ng-select formControlName="titleCode" [searchable]="false" [clearable]="false" [items]="titles$ | async"
            bindLabel="name" bindValue="code" placeholder="{{
              'checkoutAddress.guestDeliveryAddress.form.titleCode.placeholder'
                | cxTranslate
            }}" [cxNgSelectA11y]="{
              ariaLabel:
                'checkoutAddress.guestDeliveryAddress.form.titleCode.label'
                | cxTranslate
            }">
          </ng-select>
          <cx-form-errors [control]="addressForm.get('titleCode')"></cx-form-errors>
        </label>
      </div>

      <div class="form_field_wrapper" *ngIf="!isBillingAddress">
        <label>
          <span class="required">{{
            "checkoutAddress.guestDeliveryAddress.form.firstName.label"
            | cxTranslate
            }}</span>
          <input required="true" class="form-control" type="text" name="firstname" placeholder="{{
              'checkoutAddress.guestDeliveryAddress.form.firstName.placeholder'
                | cxTranslate
            }}" formControlName="firstName" />
          <cx-form-errors [control]="addressForm.get('firstName')"></cx-form-errors>
        </label>
      </div>

      <div class="form_field_wrapper" *ngIf="!isBillingAddress">
        <label>
          <span class="required">{{
            "checkoutAddress.guestDeliveryAddress.form.lastName.label"
            | cxTranslate
            }}</span>
          <input required="true" class="form-control" type="text" name="lastname" placeholder="{{
              'checkoutAddress.guestDeliveryAddress.form.lastName.placeholder'
                | cxTranslate
            }}" formControlName="lastName" />
          <cx-form-errors [control]="addressForm.get('lastName')">
          </cx-form-errors>
        </label>
      </div>

      <div class="form_field_wrapper" *ngIf="!isBillingAddress">
        <label>
          <span>{{
            "checkoutAddress.guestDeliveryAddress.form.email.label"
            | cxTranslate
            }}</span>
          <input required="true" class="form-control" type="email" name="email" placeholder="{{
              'checkoutAddress.guestDeliveryAddress.form.email.placeholder'
                | cxTranslate
            }}" formControlName="email" />
          <cx-form-errors [control]="addressForm.get('email')"></cx-form-errors>
        </label>
      </div>

      <h2 class="text--blue-color" *ngIf="!isBillingAddress">
        {{ "checkoutAddress.guestDeliveryAddress.title.address" | cxTranslate }}
      </h2>

      <div *ngIf="isBillingAddress" class="form-group">
        <div class="form-check">
          <label>
            <input type="checkbox" class="form-check-input" (change)="togglePOBillingAddress()" />
            <span class="form-check-label-checkbox">
              {{'mtOrgRegistrationForm.labels.addPoBillingAddress' | cxTranslate }}
            </span>
          </label>
        </div>
      </div>

      <div class="form_field_wrapper">
        <label>
          <span class="label-content required">
            {{
            "checkoutAddress.guestDeliveryAddress.form.company1.label"
            | cxTranslate
            }}
          </span>
          <input required="true" class="form-control" type="text" name="companyName" placeholder="{{
              'checkoutAddress.guestDeliveryAddress.form.company1.placeholder'
                | cxTranslate
            }}" formControlName="companyName" />
          <cx-form-errors [control]="addressForm.get('companyName')"></cx-form-errors>
        </label>
      </div>

      <div class="form_field_wrapper">
        <label>
          <span class="label-content required">
            {{
            "checkoutAddress.guestDeliveryAddress.form.company2.label"
            | cxTranslate
            }}
          </span>
          <input required="false" class="form-control" type="text" name="companyName" placeholder="{{
              'checkoutAddress.guestDeliveryAddress.form.company2.placeholder'
                | cxTranslate
            }}" formControlName="companyName2" />
          <cx-form-errors [control]="addressForm.get('companyName2')"></cx-form-errors>
        </label>
      </div>

      <div class="form_field_wrapper">
        <label>
          <span class="label-content required">
            {{
            "checkoutAddress.guestDeliveryAddress.form.company3.label"
            | cxTranslate
            }}
          </span>
          <input required="false" class="form-control" type="text" name="companyName" placeholder="{{
              'checkoutAddress.guestDeliveryAddress.form.company3.placeholder'
                | cxTranslate
            }}" formControlName="companyName3" />
          <cx-form-errors [control]="addressForm.get('companyName3')"></cx-form-errors>
        </label>
      </div>

      <div *ngIf="isPOBillingAddress()" class="form_field_wrapper">
        <label>
          <span>{{
            "checkoutAddress.guestDeliveryAddress.form.poNumber.label"| cxTranslate
            }}</span>
          <input type="text" class="form-control" placeholder="{{
              'checkoutAddress.guestDeliveryAddress.form.poNumber.placeholder'| cxTranslate
          }}" formControlName="poNumber" />
          <cx-form-errors [control]="addressForm.get('poNumber')">
          </cx-form-errors>
        </label>
      </div>


      <!--      TODO: Registration Countries logic & tooltip?-->
      <div class="form-group" formGroupName="country">
        <ng-container *ngIf="countries$ | async as countries">
          <div *ngIf="countries.length !== 0">
            <div class="form_field_wrapper">
              <label>
                <span class="label-content required" *ngIf="!isPOBillingAddress()">
                  {{
                  "checkoutAddress.guestDeliveryAddress.form.country.label"
                  | cxTranslate
                  }}
                </span>
                <span class="label-content required" *ngIf="isPOBillingAddress()">
                  PO Country*
                  <!--                {{ 'addressForm.country' | cxTranslate }}-->
                  <ng-template #tooltipContent>{{ 'mtUserRegistrationForm.fields.country.hint' | cxTranslate
                    }}</ng-template>
                  <span class="tooltip-container">
                    <span [ngbTooltip]="tooltipContent" placement="top-start" tooltipClass="country-tooltip">
                      <cx-icon class="cx-icon fas fa-info-circle"></cx-icon>
                    </span>
                  </span>
                </span>
                <ng-select [inputAttrs]="{ required: 'true' }" class="country-select" id="country-select"
                  formControlName="isocode" [searchable]="true" [clearable]="false" [items]="countries" bindLabel="name"
                  bindValue="isocode" placeholder="{{
                    'checkoutAddress.guestDeliveryAddress.form.country.placeholder'
                      | cxTranslate
                  }}" (change)="countrySelected($event)" [cxNgSelectA11y]="{
                    ariaLabel: 'addressForm.country' | cxTranslate
                  }">
                </ng-select>
                <cx-form-errors [control]="addressForm.get('country.isocode')"></cx-form-errors>
              </label>
            </div>
          </div>
        </ng-container>
      </div>


      <div *ngIf="isPOBillingAddress()" class="form_field_wrapper">
        <label>
          <span>{{
            'mtOrgRegistrationForm.fields.city.label' | cxTranslate
            }}</span>
          <input type="text" class="form-control" placeholder="{{
                'mtOrgRegistrationForm.fields.city.placeholder' | cxTranslate
              }}" formControlName="town" />
          <cx-form-errors [control]="addressForm.get('town')">
          </cx-form-errors>
        </label>
      </div>

      <div *ngIf="!isPOBillingAddress()" class="form_field_wrapper">
        <label>
          <span>
            {{'mtOrgRegistrationForm.fields.addressLine1.label' | cxTranslate}}
          </span>
          <input type="text" class="form-control" [readOnly]="isAddressFieldReadOnly()" placeholder="{{
                'mtOrgRegistrationForm.fields.addressLine1.placeholder' | cxTranslate
              }}" formControlName="line1" />
          <cx-form-errors [control]="addressForm.get('line1')">
          </cx-form-errors>
        </label>
      </div>

      <div *ngIf="!isPOBillingAddress()" class="form_field_wrapper">
        <label>
          <span>
            {{'mtOrgRegistrationForm.fields.addressLine2.label' | cxTranslate}}
          </span>
          <input type="text" class="form-control" formControlName="line2" />
          <cx-form-errors [control]="addressForm.get('line2')">
          </cx-form-errors>
        </label>
      </div>

      <div *ngIf="!isPOBillingAddress()" class="form_field_wrapper">
        <label>
          <span>
            {{'mtOrgRegistrationForm.fields.addressLine3.label' | cxTranslate}}
          </span>
          <input type="text" class="form-control" formControlName="line3" />
          <cx-form-errors [control]="addressForm.get('line3')">
          </cx-form-errors>
        </label>
      </div>
      <div *ngIf="!isPOBillingAddress()" class="form_field_wrapper">
        <label>
          <span>{{
            ('mtOrgRegistrationForm.fields.postalCode.label' | cxTranslate)  + (this.isPostalCodeMandatory?'*':'')
            }}</span>
          <div *ngIf="this.isPostalCodeMandatory;then requiredFieldLabel else noRequiredFieldLabel"></div>
          <ng-template #requiredFieldLabel>
            <input type="text" class="form-control" placeholder="{{
                'mtOrgRegistrationForm.fields.postalCode.placeholder' | cxTranslate
              }}" formControlName="postalCode" />
          </ng-template>
          <ng-template #noRequiredFieldLabel>
            <input type="text" class="form-control" placeholder="{{''}}" formControlName="postalCode" />
          </ng-template>
          <cx-form-errors [control]="addressForm.get('postalCode')"></cx-form-errors>
        </label>
      </div>

      <ng-container *ngIf="regions$ | async as regions" formGroupName="region">
        <ng-container *ngIf="regions.length !== 0">
          <div class="form_field_wrapper">
            <label>
              <div *ngIf="!isPOBillingAddress()">
                <span class="label-content required">{{
                  ('mtOrgRegistrationForm.fields.state.label' | cxTranslate ) + (this.isStateMandatory?'*':'')
                  }}</span>
              </div>
              <div *ngIf="isPOBillingAddress()">
                <span class="label-content required">PO State*</span>
                <!-- TODO: to be translated -->
              </div>
              <ng-select [inputAttrs]="{ required: 'true' }" class="region-select mt-select" formControlName="isocode"
                [searchable]="true" [clearable]="false" [items]="regions"
                bindLabel="{{ regions[0].name ? 'name' : 'isocode' }}"
                bindValue="{{ regions[0].name ? 'isocode' : 'region' }}"
                placeholder="{{this.isStateMandatory?('mtOrgRegistrationForm.fields.state.placeholder' | cxTranslate):''}}"
                id="region-select" [cxNgSelectA11y]="{
                  ariaLabel: 'mtOrgRegistrationForm.fields.state.label' | cxTranslate
                }">
              </ng-select>
              <cx-form-errors [control]="addressForm.get('region.isocode')"></cx-form-errors>
            </label>
          </div>
        </ng-container>
      </ng-container>

      <div *ngIf="!isPOBillingAddress()" class="form_field_wrapper">
        <label>
          <span>{{
            'mtOrgRegistrationForm.fields.city.label' | cxTranslate
            }}</span>
          <input type="text" class="form-control" placeholder="{{
                'mtOrgRegistrationForm.fields.city.placeholder' | cxTranslate
              }}" formControlName="town" />
          <cx-form-errors [control]="addressForm.get('town')">
          </cx-form-errors>
        </label>
      </div>

      <div *ngIf="isPOBillingAddress()" class="form_field_wrapper">
        <label>
          <span>PO Postal Code*</span>
          <input type="text" class="form-control" placeholder="{{
                'mtOrgRegistrationForm.fields.postalCode.placeholder' | cxTranslate
              }}" formControlName="postalCode" />
          <cx-form-errors [control]="addressForm.get('postalCode')"></cx-form-errors>
        </label>
      </div>


      <div class="order-summary-placeholder-mobile"></div>
      <div class="buttons-wrapper" *ngIf="!isBillingAddress">
        <ng-container class="buttons-wrapper" *ngIf="showCancelBtn">
          <div class="form_btn-action-wrapper">
            <button class="btn btn-secondary btn-action btn-large" (click)="back()">
              {{
              "checkoutAddress.guestDeliveryAddress.actions.backToCart"
              | cxTranslate
              }}
            </button>
          </div>
        </ng-container>
        <div class="form_btn-action-wrapper">
          <a (click)="scrollToTop()">
            <button #submit class="btn btn-primary btn-action btn-large" type="submit">
              {{
              "checkoutAddress.guestDeliveryAddress.actions.continue"
              | cxTranslate
              }}
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</form>
