import {mtProductATP} from "./mt-product-atp.i18n";

export const mtProductItemtList = {
  labels: {
    quotationAction: 'Obtenir un devis',
    orderAction: 'Ajouter au panier',
    requestProductInfoAction: "Demande d'Information",
    noResults: 'Articles trouvés ({{noFoundResults}})',
    searchResults: 'Résultats de recherche ({{noFoundResults}})',
    yourPrice: 'Votre prix',
    standardPrice: 'Prix standard',
    infoLoginPrice: '<a style="color: #004494 !important;" href="/shop/{{currentCountry}}/{{currentLanguageIso}}/login">Connectez-vous</a><span>&nbsp;pour avoir plus d’avantages ou discutez avec nos experts.</span>',
    filterButton:'Filter',
    filterClear:'Clear Filters',
    showResultsFound: 'Show Results ({{noFoundResults}})',
    displayText:'En tant que utilisateur inscrit, vous pouvez bénéficier des avantages suivants :',
    spanText:'- des tarifs spécifiques de votre entreprise',
    spanText2:'- le paiement par facture',
    spanText3:'- un accès à votre compte <a href="/shop/{{currentCountry}}/{{currentLanguageIso}}/login">mymt.com</a> avec des fonctionnalités utiles, telles que votre bibliothèque personnelle, votre fil d’actualité ou la vue d’ensemble des commandes'
  }
}

export const mtProductNoItemsFound = {
  texts: {
    noResultsLabel: 'Aucun résultat trouvé',
    searchTips: 'Conseils de recherche',
    hints: {
      hint1: 'Assurez-vous que tous les mots sont correctement orthographiés',
      hint2: 'Essayez différents mots clés',
      hint3: 'Essayez des mots clés plus généraux',
      hint4: 'Essayez moins de mots clés'
    }
  }
}

export const mtItemsPerPage = {
  items: {
    label: 'Afficher {{pageSize}} par page'
  }
}

export const mtProductListResource = {
  mtProductItemtList,
  mtProductNoItemsFound,
  mtItemsPerPage,
  mtProductATP
}
