import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AemExpMtFormComponent } from './aem-exp-mt-form.component';



@NgModule({
  declarations: [
    AemExpMtFormComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AemExpMtFormComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AemExpMtFormModule { }
