/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Component } from '@angular/core';
import {DatePickerComponent} from "@spartacus/storefront";

@Component({
  selector: 'checkout-date-picker',
  templateUrl: './checkout-date-picker.component.html',
  styleUrls: ['./checkout-date-picker.scss']
})
export class CheckoutDatePickerComponent extends DatePickerComponent {
}
