/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { NgModule } from '@angular/core';
import {UserProfileAdapter, UserProfileConnector} from "@spartacus/user/profile/core";
import {OccUserProfileAdapter} from "@spartacus/user/profile/occ";


@NgModule({
  providers: [UserProfileConnector,
    { provide: UserProfileAdapter, useClass: OccUserProfileAdapter },
  ],
})
export class MtUserProfileCoreModule {}
