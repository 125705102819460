import { AemAdapterSiteContextConfig } from "../aem-adapter-site-context/aem-adapter-site-context.config";
import { CountryMapBaseSiteIdContextConfig } from "../country-site-context/country-map-basesiteid-context.config";

export const aemSiteContextConfig: AemAdapterSiteContextConfig = {
  aemSiteContext: {
    'us_en': {
      language: 'en_US',
      currency: 'USD'
    },
    'de_en': {
      language: 'en_US',
      currency: 'EUR'
    },
    'de_de': {
      language: 'de_DE',
      currency: 'EUR'
    },
    'be_en': {
      language: 'en_GB',
      currency: 'EUR'
    },
    'be_fr': {
      language: 'fr_FR',
      currency: 'EUR'
    },
    'be_nl': {
      language: 'nl_NL',
      currency: 'EUR'
    },
    'nl_en': {
      language: 'en_GB',
      currency: 'EUR'
    },
    'nl_fr': {
      language: 'fr_FR',
      currency: 'EUR'
    },
    'nl_nl': {
      language: 'nl_NL',
      currency: 'EUR'
    },
    'ca_en': {
      language: 'en_US',
      currency: 'CAD'
    },
    'ca_fr': {
      language: 'fr_FR',
      currency: 'CAD'
    },
    'gb_en': {
      language: 'en_GB',
      currency: 'GBP'
    },
    'ch_en': {
      language: 'en_GB',
      currency: 'CHF'
    },
    'ch_de': {
      language: 'de_DE',
      currency: 'CHF'
    },
    'ch_fr': {
      language: 'fr_FR',
      currency: 'CHF'
    },
    'ch_it': {
      language: 'it_IT',
      currency: 'CHF'
    }
  },
  defaultLanguageIso: {
    'us': 'en',
    'de': 'de',
    'be': 'fr',
    'nl': 'nl',
    'ca': 'en',
    'gb': 'en',
    'ch': 'de'
  },
  defaultCountry: 'us',
  defaultCurrency: 'USD',
  defaultLanguage: 'en_US'
};

export const countryMapBaseSiteIdContextConfig: CountryMapBaseSiteIdContextConfig = {
  countries: {
    'us': 'SPARTACUS_MTMO_US_CP_Site',
    'de': 'SPARTACUS_MT_D_Site',
    'be': 'SPARTACUS_MT_BE_Site',
    'nl': 'SPARTACUS_MT_NL_Site',
    'ca': 'SPARTACUS_MT_CA_Site',
    'gb': 'SPARTACUS_MT_UK_Site',
    'ch': 'SPARTACUS_MT_CH_Site'
  },
  sites: {
    'SPARTACUS_MTMO_US_CP_Site': 'us',
    'SPARTACUS_MT_D_Site': 'de',
    'SPARTACUS_MT_BE_Site': 'be',
    'SPARTACUS_MT_NL_Site': 'nl',
    'SPARTACUS_MT_CA_Site': 'ca',
    'SPARTACUS_MT_UK_Site': 'gb',
    'SPARTACUS_MT_CH_Site': 'ch'
  },
  defaultCountry: 'us',
  defaultSite: 'SPARTACUS_MTMO_US_CP_Site'
};
