/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

export const mtOrgRegistrationForm = {

  fields: {
    company: {
      label: 'Nom Société*',
      placeholder: 'Champ obligatoire',
    },
    company2: {
      label: 'Nom Société 2',
      placeholder: '',
    },
    company3: {
      label: 'Nom Société 3',
      placeholder: '',
    },
    country: {
      label: 'Pays*',
      placeholder: 'Champ obligatoire',
      hint: 'C\'est votre pays. Si vous souhaitez enregistrer votre compte pour un autre pays, veuillez vous rendre sur le site Web de ce pays. Vous pouvez changer de pays en cliquant sur le drapeau en haut à droite de la page.',
    },
    addressLine1: {
      label: 'Adresse 1*',
      placeholder: 'Champ obligatoire',
    },
    addressLine2: {
      label: 'Adresse 2',
      placeholder: '',
    },
    addressLine3: {
      label: 'Adresse 3',
      placeholder: '',
    },
    postalCode: {
      label: 'Code Postal',
      placeholder: 'Champ obligatoire',
    },
    state: {
      label: 'Etat',
      placeholder: 'Champ obligatoire',
    },
    city: {
      label: 'Ville*',
      placeholder: 'Champ obligatoire',
    },
    phoneNumber: {
      label: 'Téléphone*',
      placeholder: 'Champ obligatoire',
    },
    poNumber: {
      label: 'Boîte postale',
      placeholder: '',
    },
    poCountry: {
      label: 'Pays*',
      placeholder: '',
    },
    poState: {
      label: 'État',
      placeholder: '',
    },
    poPostalCode: {
      label: 'Code Postal*',
      placeholder: ''
    }
  },

  labels:{
    companyRegistration: 'Enregistrer l\'entreprise',
    registerLabel1: 'Félicitations pour la création de votre utilisateur. Vous pouvez maintenant enregistrer votre entreprise.',
    registerLabel2: 'Enregistrez votre entreprise pour profiter de notre offre digitale élargie',
    laterLink: 'Enregistrer la société plus tard',
    saveAsShippingAddress: 'Enregistrer comme adresse de livraison',
    saveAsBillingAddress: 'Enregistrer comme adresse de facturation',
    addPoBillingAddress: 'Ajouter l\'adresse de facturation de la boîte postale',
    saveAsDefault: 'Paramétrer par défaut',
    cancel: 'Annuler',
    save: 'Sauvegarder',
  },

  formSubmitButtonLabel: 'Enregistrer l\'entreprise',
};

export const mtOrgRegistration = {
  mtOrgRegistrationForm,
};
