/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import {
  ChangeDetectionStrategy,
  Component,
  ViewChild,
  ElementRef
} from '@angular/core';
import {CartProceedToCheckoutComponent} from "@spartacus/cart/base/components";
import {Observable, Subscription} from "rxjs";
import {User, UserAccountFacade} from "@spartacus/user/account/root";
import {Router} from "@angular/router";
import {CountryContextService} from "../../../core/country-site-context/country-context.service";
import {MtCartService} from "../../services/mt-cart.service";
import { ActiveCartFacade, Cart } from '@spartacus/cart/base/root';

import {filter, map, tap} from "rxjs/operators";
import {EventService, isNotUndefined, WindowRef} from "@spartacus/core";
import {MtProceedToCheckoutEvent} from "../../../tms/events/mt-proceed-to-checkout-event";
import {ProductEnvelope} from "../../../tms/events/mt-add-to-cart-event";



@Component({
  selector: 'mt-cart-proceed-to-checkout',
  templateUrl: './mt-cart-proceed-to-checkout.component.html',
  styleUrls: ['./mt-cart-proceed-to-checkout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MtCartProceedToCheckoutComponent extends CartProceedToCheckoutComponent {

  @ViewChild('topAnchor') topAnchor!: ElementRef;
  user$: Observable<User | undefined>;
  country$ = this.countryService.getActive();
  cart$: Observable<Cart>;

  constructor(protected override router: Router,
              private userAccountFacade: UserAccountFacade,
              private countryService : CountryContextService,
              protected activeCartService: ActiveCartFacade,
              protected eventService: EventService,
              protected windowRef: WindowRef)
  {
    super(router);
    this.user$ = userAccountFacade.get();
    this.cart$ = this.activeCartService.getActive();
  }

  ngAfterViewInit() {
    if (this.topAnchor) {
      this.scrollToTop();
    }
  }

  protected prepareData(){
      const mtProceedToCheckoutEvent = new MtProceedToCheckoutEvent();
      const listOfProductsEnvelop : ProductEnvelope[] = [];
      let currencyCode : string|undefined;
      const subscription = this.cart$.pipe(filter((x)=> isNotUndefined(x)))
        .subscribe(
        (cart) =>{
          if(cart != undefined){
            cart.entries?.forEach((orderEntry)=>{
              const productEnvelope = {
                prod_name : orderEntry.product?.name,
                prod_quantity : orderEntry.quantity,
                cost : orderEntry.totalPrice?.value
              };
              listOfProductsEnvelop.push(productEnvelope);
            });
            currencyCode = cart.totalPrice?.currencyIso;
          }
        }
      )
    mtProceedToCheckoutEvent.checkout= {
        cc : currencyCode,
        products : listOfProductsEnvelop
    }
    subscription.unsubscribe();
    return mtProceedToCheckoutEvent;
  }

  triggerProceedToCheckoutEvent() {
    this.eventService.dispatch(this.prepareData())
  }

  trackCheckout(){
    if(this.windowRef.isBrowser()){
     if(typeof this.windowRef.nativeWindow?._satellite === "object") {
       console.log("adding to satelite");
       // @ts-ignore
       this.windowRef.nativeWindow?._satellite.track("ds_checkout");
     }
   };
 }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
