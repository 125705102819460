/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { NgModule } from '@angular/core';
import { CartConfig } from "@spartacus/cart/base/root";
import { OccConfig, provideConfig } from "@spartacus/core";
import { MtCartBaseModule } from './base/mt-cart-base.module';
import { MtCartOccService } from './services/mt-cart-occ.service';
import { MtCartService } from './services/mt-cart.service';
import { LayoutConfig } from '@spartacus/storefront';

export const cartPageLayoutConfig: LayoutConfig = {
  layoutSlots: {
    header: {
      lg: {
        slots: [],
      },
    },
    navigation: {
      lg: {
        slots: []
      },
    },
    cartPageTemplate: {
      pageFold:'Top',
      slots: [
        'Top',
        'Side',
        'Middle',
        'TopContent',
        'CenterLeftContentSlot',
        'CenterRightContentSlot',
        'EmptyCartMiddleContent',
        'BottomContentSlot',
        'NavigationBar',
        'HeaderLinks',
        'MiniCart',
        'TopHeaderSlot',
        'SiteLogo',
        'Footer',
        'CookiePolicy',
        'SearchBox',
        'HomepageNavLink',
        'PreHeader',
        'SiteLogin'
      ],
    },
  }
}


@NgModule({
  imports: [
    MtCartBaseModule
  ],
  exports: [
  ],
  providers: [
    MtCartService,
    MtCartOccService,
    provideConfig(cartPageLayoutConfig),
    provideConfig(<CartConfig>{
      cart: {
        validation: {
          enabled: true,
        }
      }
    }),
    provideConfig(<OccConfig>{
      backend: {
        occ: {
          endpoints:
          {
            //updateEntry: 'orgUsers/${userId}/carts/${cartId}/entries/${entryNumber}',
            updateEntries: 'users/${userId}/carts/${cartId}/entries/${entryNumber}',
          }
        }
      }
    },
    )
  ],

})
export class MtCartModule { }
