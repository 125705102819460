import { APP_BASE_HREF } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import {
  AuthService,
  BasePageMetaResolver, CurrencyService, LanguageService, PageLinkService,
  ProductPageMetaResolver,
  ProductService,
  RoutingService, SiteContextUrlSerializer,
  TranslationService, WindowRef
} from "@spartacus/core";
import { combineLatest, Observable, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { CountryContextService } from "../../core/country-site-context/country-context.service";

declare global {
  interface Window {
    mt_page_data: MtPageData;
    _satellite?: Object;
  }

  interface MtPageData {
    title?: string;
    pageName?: string;
    languageCode?: string;
    isLoggedin?: string;
    businessOwner?: string;
    rootBusinessOwner?: string;
    currency?: string;
    products?: any[];
    currentCountry?: string;
    leadProductType ?: string;
  }

}

export interface SwiftypePageMetaResolver {
  revolveToppath(): Observable<string>;
  revolveLocale(): Observable<string>;
  revolveCategory(): Observable<string>;
  revolvePriority(): Observable<string>;
  resolveMatNum(): Observable<string>;
  resolveReportData(): Observable<string>;
}


@Injectable({
  providedIn: 'root'
})
export class MtProductPageMetaResolver extends ProductPageMetaResolver implements SwiftypePageMetaResolver {
  constructor(protected override routingService: RoutingService,
    protected override productService: ProductService,
    protected override translation: TranslationService,
    protected override basePageMetaResolver: BasePageMetaResolver,
    protected override pageLinkService: PageLinkService,
    @Inject(APP_BASE_HREF) protected baseHref: string,
    protected countryContextService: CountryContextService,
    protected languageActive: LanguageService,
    protected currencyService: CurrencyService,
    protected authService: AuthService,
    protected urlSerializer: SiteContextUrlSerializer,
    protected winRef: WindowRef,) {
    super(routingService, productService, translation, basePageMetaResolver, pageLinkService);
    if (this.winRef.nativeWindow) {
      this.winRef.nativeWindow.mt_page_data = {} as MtPageData;
    }
  }

  override resolveDescription(): Observable<string> {
    return this.product$.pipe(
      map((product) => product.description ?? (product.name ?? 'N/A'))
    )
  }

  revolveToppath(): Observable<string> {
    return this.product$.pipe(
      tap((product) =>
        console.log("SWIFTYPE RESOLVETOPPATH")
      ),
      switchMap((product) => this.findBaseProduct(product)),
      tap((product) => {
        console.log(product.name);
        if (product.esbu)
          console.log(product.esbu);
        if (product.buOwner)
          console.log(product.buOwner)
      }),
      map((product) => {
        const url = this.routingService.getUrl({
          cxRoute: 'product',
          params: product,
        });
        return this.pageLinkService.getCanonicalUrl({}, this.baseHref + url);
      })
    );
  }

  override resolveTitle(): Observable<string> {
    return this.product$.pipe(
      tap((product) => {
        let title = product.name;
        title += this.resolveFirstCategory(product);
        title += this.resolveManufacturer(product);
      }),
      switchMap((product) => {
        let title = product.name;
        title += this.resolveFirstCategory(product);
        title += this.resolveManufacturer(product);
        return this.translation.translate('productSummary.title', {
          title: title,
        });
      })
    );
  }

  revolveLocale(): Observable<string> {
    const locale: Observable<string> =
      combineLatest([
        this.countryContextService.getActive(),
        this.languageActive.getActive(),
        this.currencyService.getActive(),
        this.authService.isUserLoggedIn(),
      ]).pipe(
        tap(([country, language, currency, isLoggedIn]) => {
        }),
        map(([country, language, currency, isLoggedIn]) => {
          return `${language.split('_')[0]}-${country.toUpperCase()}`;
        })
      );
    return locale;
  }

  revolveCategory(): Observable<string> {
    return of("PRODUCTS");
  }

  revolvePriority(): Observable<string> {
    return of("5");
  }

  resolveMatNum(): Observable<string> {
    return this.product$.pipe(
      map((product) => product.code ?? ('N/A'))
    )
  }

  resolveReportData(): Observable<string> {
    const reportData: Observable<string> =
      combineLatest([
        this.resolveTitle(),
        this.product$,
        this.countryContextService.getActive(),
        this.languageActive.getActive(),
        this.currencyService.getActive(),
        this.authService.isUserLoggedIn()
      ]).pipe(
        tap(([title, product, country, language, currency, isLoggedIn]) => {
          if (this.winRef.nativeWindow) {
            const newData = {
              title: title,
              products: [ {
                category: product.categories?.[0].name,
                name: product.name,
                quantity: 1
                }],
              businessOwner: product.buOwner ? product.buOwner : 'OTHER',
              rootBusinessOwner: product.esbu ? product.esbu : 'OTHER',
              leadProductType: product.generalItemCategoryGroup ? product.generalItemCategoryGroup : 'not-available',
              languageCode: language.split('_')[0],
              currentCountry: country.toLowerCase(),
              currency: currency,
              isLoggedin: isLoggedIn ? 'registered' : 'anonymous'
            };
            if (JSON.stringify(newData) !== JSON.stringify(this.winRef.nativeWindow.mt_page_data)) {
              this.winRef.nativeWindow.mt_page_data = newData;
              this.winRef.nativeWindow.dispatchEvent(new CustomEvent("mt-page-data-event",
                {
                  bubbles: true,
                  detail: {
                      source: "product-page",
                      data: this.winRef.nativeWindow.mt_page_data
                    }
                }
              ));
            }
          }
        }),
        map(() => {
          return "";
        })
      );
    return reportData;
  }


}
